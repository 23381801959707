var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1250px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "", tabs: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Administrar recepción")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.vHeaders,
                  items: _vm.requisicion.materiales_requisicion,
                  "hide-actions": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              props.item.proveedor
                                ? props.item.proveedor.nombre_completo
                                : "Aún sin proveedor"
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(_vm._s(props.item.material.categoria_asociada))
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(props.item.material.nombre))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(props.item.cantidad))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticStyle: { width: "10px" } },
                          [
                            _c("v-text-field", {
                              attrs: {
                                type: "number",
                                min: "1",
                                "full-width": "",
                                disabled:
                                  props.item.estado_surtido !=
                                  "Pendiente de surtir",
                                max: props.item.cantidad
                              },
                              model: {
                                value: props.item.cantidad_a_surtir,
                                callback: function($$v) {
                                  _vm.$set(props.item, "cantidad_a_surtir", $$v)
                                },
                                expression: "props.item.cantidad_a_surtir"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(props.item.material.precio)
                            )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(props.item.estado_surtido))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "v-layout",
                              [
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    props.item.estado_surtido ==
                                    "Surtido parcialmente"
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "verdeFayal"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openAddMissingMaterial(
                                                  props.item.material.id,
                                                  props.item.requisicion_id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("add")])],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v("Recibir material faltante")
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          icon: "",
                                          flat: "",
                                          dark: "",
                                          small: "",
                                          disabled:
                                            props.item.estado_surtido !=
                                              "Pendiente de surtir" ||
                                            _vm.requisicion.estado.information
                                              .estado == "Espera de aprobacion",
                                          color: "verdeFayal"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.surtirMaterial(
                                              props.item.id,
                                              props.item.cantidad_a_surtir
                                            )
                                          }
                                        },
                                        slot: "activator"
                                      },
                                      [
                                        _c("v-icon", [_vm._v("compare_arrows")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Surtir material")])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("v-card-actions")
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n      " + _vm._s(_vm.vSnackBar.text) + "\n      "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("add-missing-material-modal", {
        attrs: {
          show: _vm.showAddMissingMaterial,
          "requisicion-material-id": _vm.currentRequisicionMaterial,
          "requisicion-id": _vm.currentRequisicionId
        },
        on: {
          "update:show": function($event) {
            _vm.showAddMissingMaterial = $event
          },
          "update:requisicionMaterialId": function($event) {
            _vm.currentRequisicionMaterial = $event
          },
          "update:requisicion-material-id": function($event) {
            _vm.currentRequisicionMaterial = $event
          },
          "update:requisicionId": function($event) {
            _vm.currentRequisicionId = $event
          },
          "update:requisicion-id": function($event) {
            _vm.currentRequisicionId = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }