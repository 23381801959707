var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: !_vm.showFiltersForm, xs9: _vm.showFiltersForm } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Pruebas de laboratorio")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      (_vm.$root.can("new", _vm.permissions)
                      ? true
                      : false)
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "fyl-btnCreate",
                              on: {
                                click: function($event) {
                                  _vm.showCreatePrueba = true
                                }
                              }
                            },
                            [_vm._v("Crear")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", outline: "" },
                          on: {
                            click: function($event) {
                              _vm.showFiltersForm = !_vm.showFiltersForm
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("filter_alt")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", outline: "" },
                          on: { click: _vm.exportToExcel }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("import_export")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      "disable-initial-sort": "",
                      items: _vm.pruebas.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", [
                              _vm._v(_vm._s(props.item.nombre_prueba))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(props.item.laboratorio_encargado))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(props.item.fecha_respuesta))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(props.item.fecha_de_obtencion_de_muestra)
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(props.item.departamento_interesado))
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.distribucion))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              flat: "",
                                              icon: "",
                                              color: "warning"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.elementId = props.item.id
                                                _vm.dialogFile = true
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("folder")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Archivos")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: !_vm.$root.can(
                                                "update",
                                                _vm.permissions
                                              ),
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "info"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.editPruebasLab(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1ABA24" } },
                                              [_vm._v("edit")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Editar")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: !_vm.$root.can(
                                                "remove",
                                                _vm.permissions
                                              ),
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "error"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deletePrueba(
                                                  props.item
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("delete")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Eliminar")])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.pruebas
                        },
                        on: { "pagination-change-page": _vm.getPruebas }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.showFiltersForm
            ? _c(
                "v-flex",
                { attrs: { xs3: "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { color: "white", flat: "" } },
                        [
                          _c("v-toolbar-title", [_vm._v("Filtros")]),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                icon: "",
                                color: "verdeFayal",
                                dark: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.showFiltersForm = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          color: "verdeFayal",
                                          items: _vm.itemsDepartamento,
                                          label: "Departamento interesado",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.filters.filterDepartamento,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "filterDepartamento",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.filterDepartamento"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-select", {
                                        attrs: {
                                          color: "verdeFayal",
                                          items: _vm.proyectoItems,
                                          "item-value": "id",
                                          "item-text": "nombre_proyecto",
                                          label: "Proyecto al que pertenece",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.filters.filterProyecto,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "filterProyecto",
                                              $$v
                                            )
                                          },
                                          expression: "filters.filterProyecto"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          color: "verdeFayal",
                                          items: _vm.itemsDistribucion,
                                          label: "Distribucion",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.filters.filterDistribucion,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "filterDistribucion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.filterDistribucion"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                color: "verdeFayal",
                                small: "",
                                flat: "",
                                outline: ""
                              },
                              on: { click: _vm.limpiar }
                            },
                            [_vm._v("Limpiar")]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                color: "verdeFayal",
                                small: ""
                              },
                              on: { click: _vm.filterPruebas }
                            },
                            [_vm._v("Filtrar")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("upload-files-modal", {
            attrs: {
              element_type: "App\\PruebaLaboratorio",
              element_id: _vm.elementId,
              dialog: _vm.dialogFile
            },
            on: {
              close: function($event) {
                _vm.dialogFile = false
              }
            }
          }),
          _vm._v(" "),
          _c("pruebas-laboratorio-form", {
            attrs: { show: _vm.showCreatePrueba },
            on: {
              "update:show": function($event) {
                _vm.showCreatePrueba = $event
              }
            }
          }),
          _vm._v(" "),
          _c("edit-pruebas-modal", {
            attrs: {
              show: _vm.showEditPruebaModal,
              "prueba-id": _vm.currentPruebaId
            },
            on: {
              "update:show": function($event) {
                _vm.showEditPruebaModal = $event
              },
              "update:pruebaId": function($event) {
                _vm.currentPruebaId = $event
              },
              "update:prueba-id": function($event) {
                _vm.currentPruebaId = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }