<template>
    <div>
        <v-card flat>
            <v-toolbar color="verdeFayal" dark extended flat>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn flat icon :href="`/fraccionamientos`">
                            <v-icon>keyboard_arrow_left</v-icon>
                        </v-btn>
                    </template>
                    <span>Regresar</span>
                </v-tooltip>
            </v-toolbar>

            <v-layout row pb-2>
                <v-flex xs10 offset-xs1>
                    <v-card class="card--flex-toolbar">
                        <v-toolbar card prominent>
                            <v-spacer></v-spacer>
                            <v-toolbar-title class="body-2 grey--text">Ventas de lotes de {{ fraccionamiento.nombre_proyecto }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>

                        <v-card-text>
                            <v-container grid-list-md>
                                <v-card-text>
                                    <v-data-table
                                        hide-actions
                                        :items="manzanas.data"
                                        :expand="expandManzana"
                                        ref="manzanasTable"
                                    >
                                        <template v-slot:headers="props">
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Total de lotes</th>
                                                <th class="text-xs-right"></th>
                                            </tr>
                                        </template>
                                        <template v-slot:items="props">
                                            <tr>
                                                <td>Manzana {{props.item.nombre}}</td>
                                                <td>
                                                    <span style="background-color: #ff5617;" class="badge badge-secondary">
                                                        {{ props.item.lotes_count }}
                                                    </span>
                                                </td>
                                                <td class="text-xs-right">
                                                    <v-btn flat icon @click="openLotesExpand(props.item.id, props.index)">
                                                        <v-icon>{{(props.expanded) ? 'expand_less' : 'expand_more'}}</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-slot:expand="props">
                                            <table class="table table-striped table-hover table-sm">
                                                <thead>
                                                    <tr>
                                                        <th>Lote</th>
                                                        <th>Estado</th>
                                                        <th>Porcentaje de avance</th>
                                                        <th>Estado de construcción</th>
                                                        <th>¿Hay trabajos externos?</th>
                                                        <th>Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(lote, index) in manzanas.data[props.index].lotes" :key="index">
                                                        <td>{{ lote.nombre }}</td>
                                                        <td>{{ (lote.estado) ? lote.estado.information.estado : 'Sin promocionar'}}</td>
                                                        <td>
                                                            <div class="progress">
                                                                <div 
                                                                    class="progress-bar progress-bar-striped progress-bar-animated bg-success" 
                                                                    role="progressbar" 
                                                                    :style="`width: ${lote.porcentaje_de_avance}%;`" 
                                                                    :aria-valuenow="lote.porcentaje_de_avance" 
                                                                    aria-valuemin="0" 
                                                                    aria-valuemax="100"
                                                                >
                                                                    {{ lote.porcentaje_de_avance.toFixed(2) }} %
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{{ lote.estado_de_construccion }}</td>
                                                        <td> {{ lote.trabajos_externos ? "Si" : "No" }} </td>
                                                        <td>
                                                            <v-layout>
                                                                <v-tooltip bottom>
                                                                    <v-btn
                                                                        :disabled="(!lote.estado || lote.estado.information.id == 20) ? false : true"
                                                                        @click="promocionarLote(lote.id, props.item.id, props.index)"
                                                                        color="verdeFayal"
                                                                        slot="activator"
                                                                        flat
                                                                        icon
                                                                    >
                                                                        <v-icon>near_me</v-icon>
                                                                    </v-btn>
                                                                    <span>Promocionar</span>
                                                                </v-tooltip>
                                                                <v-tooltip bottom>
                                                                    <v-btn
                                                                        :disabled="(lote.estado && lote.estado.information.id == 21) ? false : true"
                                                                        @click="despromocionarLote(lote.id, props.item.id, props.index)"
                                                                        slot="activator"
                                                                        color="error"
                                                                        icon
                                                                        flat
                                                                    >
                                                                        <v-icon>near_me</v-icon>
                                                                    </v-btn>
                                                                    <span>Retirar promocion</span>
                                                                </v-tooltip>
                                                                <v-tooltip bottom v-if="lote.estado">
                                                                    <v-btn
                                                                        :disabled="(!lote.estado || lote.estado.information.id == 21) ? false : true"
                                                                        @click="addPricesToLotes(lote.id, props.item.id, props.index)"
                                                                        color="verdeFayal"
                                                                        slot="activator"
                                                                        flat
                                                                        icon
                                                                    >
                                                                        <v-icon>price_check</v-icon>
                                                                    </v-btn>
                                                                    <span>Precios de lote</span>
                                                                </v-tooltip>
                                                                <v-tooltip bottom v-if="!lote.estado">
                                                                    <v-btn
                                                                        :disabled="(!lote) ? false : true"
                                                                        @click="addPricesToLotes(lote.id, props.item.id, props.index)"
                                                                        color="verdeFayal"
                                                                        slot="activator"
                                                                        flat
                                                                        icon
                                                                    >
                                                                        <v-icon>price_check</v-icon>
                                                                    </v-btn>
                                                                    <span>Añadir precios de lote</span>
                                                                </v-tooltip>
                                                            </v-layout>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <pagination 
                                        :limit="10" 
                                        :data="manzanas" 
                                        @pagination-change-page="getManzanas"
                                    ></pagination>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-card>

        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>

        <add-prices-to-lotes-modal :show.sync="showAddPricesToLotes" :lote-id="currentLoteId"/>
    </div>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    data() {
      return {  
        fraccionamiento: {},
        manzanas: {
            data:[]
        },
        expandManzana: false,
        currentLoteId: null,
        showAddPricesToLotes: false,
        vSnackBar: {
            visible: false,
            text: null
        }
      }
    },
    props: {
        fraccionamientoId: String
    },
    methods: {
      getFraccionamiento: function() {
        axios.get(laravelRoute('fraccionamientos.show.refactor', this.fraccionamientoId))
        .then(response => this.fraccionamiento = response.data)
        .catch(error => errorHandling(error));
      },
      getManzanas: function(page = 1){
        axios.get(`/fraccionamientos/${this.fraccionamientoId}/manzanas?page=${page}`)
        .then(response => this.manzanas = response.data)
        .catch(error => errorHandling(error));
      },
      openLotesExpand: function(id, index, page = 1) {
        let expand = !this.$refs.manzanasTable.expanded[id];
        axios.get(`/fraccionamientos/${this.fraccionamientoId}/manzanas/${id}/lotes?page=${page}`)
        .then(response => {
            this.manzanas.data[index].lotes = response.data;
        })
        .catch(error => errorHandling(error))
        .finally(() => this.$set(this.$refs.manzanasTable.expanded, id, expand));
      },
      promocionarLote: function(loteId, manzanaId, manzanaKey) {
        axios.patch(route("promociones.update", loteId))
        .then(response => {
            this.reloadManzana(loteId, manzanaId, manzanaKey);
            this.vSnackBar.text = response.data.message_text;
            this.vSnackBar.visible = true;
        })
        .catch(error => errorHandling(error));
      },
      despromocionarLote: function(loteId, manzanaId, manzanaKey) {
        axios.patch(route("promociones.despromocionar", loteId))
        .then(response => {
            this.reloadManzana(loteId, manzanaId, manzanaKey);
            this.vSnackBar.text = response.data.message_text;
            this.vSnackBar.visible = true;
        })
        .catch(error => errorHandling(error));
      },
      finalizarLote: function(loteId, manzanaId, manzanaKey) {
        swal({
            title: "¿Estás seguro?",
            text: "Una vez finzalizado no se podrá cambiar.",
            icon: "warning",
            buttons: true,
            dangerMode: true
        }).then(willDelete => {
            if (willDelete) {
                axios.post(`/manzanas/lote/${loteId}/finalizar`)
                .then(response => {
                    this.openLotesExpand(manzanaId, manzanaKey);
                    this.vSnackBar.text = response.data.message_text;
                    this.vSnackBar.visible = true;
                })
            }
        });
      },
      addPricesToLotes: function(loteId, manzanaId, manzanaKey) {
        this.currentLoteId = loteId;
        this.showAddPricesToLotes = true;
      },
      reloadManzana: function(loteId, manzanaId, index, page = 1) {
        axios.get(`/fraccionamientos/${this.fraccionamientoId}/manzanas/${manzanaId}/lotes?page=${page}`)
        .then(response => {
            let oldManzana = this.manzanas.data[index];
            this.manzanas.data.splice(index, 1, {
                ...oldManzana,
                lotes: response.data
            });
        })
        .catch(error => errorHandling(error))
      },
    },
    created() {
        this.getFraccionamiento();
        this.getManzanas();
    }
  }
</script>
