<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="1350px" persistent>
      <v-card>
        <v-toolbar color="white" flat tabs>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Administrar materiales</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-tabs slot="extension" v-model="vTabs" centered color="white" slider-color="verdeFayal">
            <!-- <v-tab href="#tab-index-material">Lista de materiales</v-tab> -->
            <!--  v-if="$root.can('valores-materiales-index', permissions) ? true : false" -->
            <v-tab href="#tab-add-material">Añadir materiales</v-tab>
            <!--  v-if="$root.can('valores-materiales-new', permissions) ? true : false" -->
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="vTabs">
          <!-- <v-tab-item value="tab-index-material">
            <index-material-salidas-almacen
              :item-id.sync="localItemId"
              :reload.sync="reload"
              :model-type="modelType"
            ></index-material-salidas-almacen>
          </v-tab-item> -->
          <v-tab-item value="tab-add-material">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-container grid-list-md >
                <v-layout wrap>
                  <v-flex md6>
                    <v-text-field
                      disabled
                      readonly
                      v-model="solicitud.nombre_requisicion"
                      required
                      :rules="rules"
                      label="Nombre de requisición"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md6>
                    <v-select :items="solicitante" v-model="solicitud.solicitante" label="Tipo de categoría" required></v-select>
                  </v-flex>
                  <!-- <v-flex md12>
                    <v-textarea
                      color="#1ABA24"
                      label="Descripcion"
                      v-model="solicitud.descripcion"
                      height="32px"
                      rows="3"
                      counter="250"
                      maxlength="250"
                    ></v-textarea>
                  </v-flex> -->
                  <v-spacer></v-spacer>
                  <v-divider></v-divider>
                  <v-spacer></v-spacer>
                </v-layout>


                <div v-for="(material, index) in materiales" :key="index">
                  <v-layout wrap> <!-- v-for="(propuesta, index) in fraccionamientosSelect" :key="index" -->
                    <v-flex md2>
                      <v-autocomplete
                        :items="categories"
                        :rules="rules"
                        @change="getCurrentMateriales(material.categoria_id, index)"
                        v-model="material.categoria_id"
                        item-value="id"
                        item-text="nombre"
                        label="Categorias"
                        single-line
                        style
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex md2>
                      <v-autocomplete
                        :items="material.materiales"
                        v-model="material.material"
                        :rules="rules"
                        return-object
                        item-text="nombre"
                        label="Material"
                        required
                        single-line
                      ></v-autocomplete>
                    </v-flex>
                    <v-flex md2>
                      <v-text-field
                        :value="material.material.unidad"
                        readonly
                        required
                        label="Unidades"
                        disabled
                      ></v-text-field>
                    </v-flex>
                    <v-flex md1>
                      <v-text-field
                        v-model="material.cantidad"
                        type="number"
                        min="0"
                        required
                        :rules="rules"
                        label="Cantidad"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md2>
                      <v-text-field
                        prepend-icon="attach_money"
                        :value="material.material.precio"
                        readonly
                        disabled
                        required
                        label="Precio unitario"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md2>
                      <v-text-field
                        prepend-icon="attach_money"
                        :value="parseInt(material.material.precio) * parseInt(material.cantidad)"
                        readonly
                        disabled
                        label="Precio total"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md1>
                      <v-btn
                        v-if="index != 0"
                        @click="materiales.splice(index, 1)"
                        fab
                        flat
                        dark
                        small
                        color="verdeFayal"
                      >
                        <v-icon dark>remove</v-icon>
                      </v-btn>
                    </v-flex>

                    <v-flex md3>
                        <!-- @change="getCurrentMateriales(material.categoria_id, index)" -->
                        <!-- :items="fraccionamientosSelect.prospecto" -->
                        <!-- v-model="fraccionamientos.prospecto.id" -->
                      <v-autocomplete
                        :rules="rules"
                        :items="[fraccionamiento]"
                        readonly
                        disabled
                        v-model="material.proyecto_id"
                        item-value="id"
                        item-text="nombre_proyecto"
                        label="Proyecto"
                        single-line
                        style
                      ></v-autocomplete>
                    </v-flex>
                      <v-flex md2 v-if="computedPropuesta">
                        <v-autocomplete
                          v-model="material.manzana_id"
                          :items="getManzanasItems"
                          :rules="rules"
                          item-value="id"
                          item-text="nombre"
                          label="Manzana"
                          single-line
                          style
                          @change="currentManzana = index;"
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex md2 v-if="computedPropuesta">
                          <!-- :items="fraccionamientosSelect.prospecto.propuesta_lotificacion[0].manzanas[0].lotes[0]" -->
                        <v-autocomplete
                          v-model="material.lote_id"
                          :rules="rules"
                          :items="getLotesItems(material.manzana_id)"
                          item-value="id"
                          item-text="nombre"
                          label="Lote"
                          single-line
                          style
                        ></v-autocomplete>
                      </v-flex>
                  </v-layout>
                  <v-spacer></v-spacer>
                  <v-divider></v-divider>
                  <v-spacer></v-spacer>
                </div>
              </v-container>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark v-if="vTabs == 'tab-add-material'" color="verdeFayal" @click="storeMateriales" flat :loading="vBtnSave.loading"
          >Guardar</v-btn>
          <v-btn dark v-if="vTabs == 'tab-add-material'" color="verdeFayal" @click="addMateriales">
            <v-icon>add</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { errorHandling, laravelRoute } from "../../../lib/utilities";
import moment from "moment";

export default {
  data(){
    return{
      currentManzana: null,
      solicitud:{
        nombre_requisicion:null,
        solicitante:null,
        descripcion:null
      },
      solicitante:['Obra','Urbanización'],
      categories: [],
      done: false,
      localShow: false,
      localItemId: null,
      fraccionamientosSelect: [{
        prospecto:{
          nombre_proyecto: null,
          propuesta_lotificacion: [{
            manzanas:[{
              nombre: null,
              lotes:[{
                nombre: null
              }]
            }]
          }]
        }
      }],
      fraccionamientos: {
        prospecto:{
          nombre_proyecto: null,
          propuesta_lotificacion: [{
            manzanas:[{
              nombre: null,
              lotes:[{
                nombre: null
              }]
            }]
          }]
        }
      },
      materiales: [
        {
          categoria_id: null,
          material: {
            precio: 0
          },
          cantidad: 0,
          materiales: [],
        }
      ],
      localShow: false,
      vTabs: "tab-add-material",
      reload: false,
      valid: false,
      rules: [v => !!v || "Este campo es requerido"],
      vBtnSave: {
        loading: false
      },
      vSnackBar: {
        visible: false,
        text: null
      },
    }
  },
  props: {
    show: Boolean,
    itemId: Number,
    modelType: String,
    fraccionamiento: Object,
  },
  watch:{
    show: function(value) {
      this.localShow = value;
      this.solicitud.nombre_requisicion = `Orden de salida de almacen ${moment().format('DD/MM/YYYY HH:mm:ss')}`;
      this.materiales[0].proyecto_id = this.fraccionamiento.id;
    },
    localShow: function(value) {
      this.$emit("update:show", value);
    },
    itemId: function(value) {
      this.localItemId = value;
    },
    vTabs: function(value) {
      this.reload = value == "tab-add-material" ? true : false;
    },
  },
  computed:{
    computedPropuesta: function(){
      var convert = this.fraccionamientosSelect.map(element => element.id ? element.prospecto : 'Hola');
      return convert;
      // return convert.filter(obj => obj);
      // let array = Object.entries(this.fraccionamientosSelect);
      // return console.log('conosle ',array.filter(element => element !== ""));
    },
    getManzanasItems: function () {
      return this.fraccionamiento.prospecto.propuesta_lotificacion[0].manzanas
    },
  },
  methods:{
    getLotesItems: function (id) {
      if(!id) return;
      return this.fraccionamiento.prospecto.propuesta_lotificacion[0].manzanas.find(element => element.id == id).lotes || [];
    },
    getCategories: function() {
      axios
      .get(laravelRoute("categorias.index", {
        query: "Materiales de maquinaría"
      }))
      .then(response => {
        this.categories = response.data.data;
      })
      .catch(error => errorHandling(error));
    },
    getFraccionamientos: function() {
      axios
        .get("/fraccionamientos?paginated=0&relationships[]=estado")
        .then(response => {
          this.fraccionamientosSelect = response.data;
        })
        .catch(error => errorHandling(error));
    },
    getCurrentMateriales: function(category_id, index) {
      var categories = [];
      this.categories.map(function(category) {
        categories.push(category);
      });
      this.materiales[index].materiales = categories.find(function(category) {
        return category.id == category_id;
      }).materiales;
    },
    addMateriales: function() {
      this.materiales.push({
          categoria_id: null,
          material: {
            precio: 0
          },
          cantidad: 0,
          materiales: [],
          proyecto_id: this.fraccionamiento.id,
        });
    },
    storeMateriales: function() {
      if (this.$refs.form.validate()) {
        this.vBtnSave.loading = true;
        axios
          .post(laravelRoute("salidas_materiales.save"), {
            materiales: this.materiales,
            model_type: this.modelType,
            proyecto_id: this.fraccionamiento.id,
            manzana_id: this.fraccionamientos.prospecto.propuesta_lotificacion[0].manzanas[0].id,
            lote_id: this.fraccionamientos.prospecto.propuesta_lotificacion[0].manzanas[0].lotes[0].id,
            solicitud: this.solicitud
            // solicitud: this.solicitud.filter(solicitud => solicitud.nombre_requisicion &&  solicitud.solicitante && solicitud.descripcion)
          })
          .then(response => {
            this.done = true,
            this.materiales = [
              {
                categoria_id: null,
                material: {
                  precio: 0
                },
                cantidad: 0,
                materiales: []
              },
            ];
            alertify.success(response.data.message_text);
            this.localShow = false;
            this.fraccionamientos = {
              prospecto:{
                nombre_proyecto: null,
                propuesta_lotificacion: [{
                  manzanas:[{
                    nombre: null,
                    lotes:[{
                      nombre: null
                    }]
                  }]
                }]
              }
            };
            this.solicitud = [{
              nombre_requisicion:null,
              solicitante:null,
              descripcion:null
            }]
          })
          .catch(error => errorHandling(error))
          .finally(() => (this.vBtnSave.loading = false));
      }
      }
    },
    mounted() {
      this.getCategories();
      this.getFraccionamientos();
  }
}
</script>