var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "650px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v(
                      "Agregar clientes al lote " + _vm._s(_vm.lote.nombre)
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _vm.lote.estado.information.id == 21
                            ? _c(
                                "v-flex",
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      hint: "Buscar cliente",
                                      "prepend-inner-icon": "search",
                                      "search-input": _vm.query,
                                      items: _vm.customers.data,
                                      "item-text": "nombre_completo",
                                      "persistent-hint": "",
                                      "item-value": "id",
                                      label: "Buscar",
                                      "return-object": "",
                                      "hide-selected": "",
                                      "hide-no-data": ""
                                    },
                                    on: {
                                      "update:searchInput": function($event) {
                                        _vm.query = $event
                                      },
                                      "update:search-input": function($event) {
                                        _vm.query = $event
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.addCustomer($event)
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append-outer",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-slide-x-reverse-transition",
                                                { attrs: { mode: "out-in" } },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "success",
                                                        disabled: !_vm.selected
                                                      },
                                                      on: {
                                                        click: _vm.addCustomer
                                                      }
                                                    },
                                                    [_vm._v("add")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3552969770
                                    ),
                                    model: {
                                      value: _vm.selected,
                                      callback: function($$v) {
                                        _vm.selected = $$v
                                      },
                                      expression: "selected"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c(
                              "table",
                              {
                                staticClass: "table table-striped table-hover"
                              },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [_vm._v("Nombre del cliente")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("Correo electrónico")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("Télefono")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("Acciones")])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.localSelected, function(
                                    customer,
                                    index
                                  ) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _vm._v(_vm._s(customer.nombre_completo))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(customer.email))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(customer.telefono))
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-layout",
                                            [
                                              _vm.lote.estado.information.id ==
                                              21
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        flat: "",
                                                        icon: "",
                                                        color: "red"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteCustomer(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("delete")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  }),
                                  0
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.lote.estado.information.id == 21
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            outline: "",
                            dark: "",
                            color: "verdeFayal",
                            flat: "",
                            loading: _vm.vBtnSave.loading
                          },
                          on: { click: _vm.storeCustomers }
                        },
                        [_vm._v(" Guardar ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }