var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c("v-card", [
            _c(
              "form",
              {
                attrs: { action: "" },
                on: {
                  "~submit": function($event) {
                    $event.preventDefault()
                    return _vm.createStatus()
                  }
                }
              },
              [
                _c(
                  "v-card-title",
                  {
                    staticClass: "headline grey lighten-2",
                    attrs: { "primary-title": "" }
                  },
                  [
                    _c("v-flex", { attrs: { md8: "" } }, [
                      _c("p", [_vm._v("Seleccione el estado")])
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  [
                    _c("v-flex", [
                      _c("div", { staticClass: "wrapper-table" }, [
                        _c("span", { staticClass: "table-title" }, [
                          _vm._v("Historial")
                        ]),
                        _vm._v(" "),
                        _c("table", { staticClass: "table-history" }, [
                          _c("thead", { staticClass: "table-head-history" }, [
                            _c("tr", [
                              _c("th", [_vm._v("Estado")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Usuario")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Fecha")])
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            { staticClass: "table-body-history" },
                            _vm._l(_vm.localEstados, function(item, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      item.information
                                        ? item.information.estado
                                        : "No disponible"
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(item.user.name))]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(item.created_at))])
                              ])
                            }),
                            0
                          )
                        ])
                      ])
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm6: "", md6: "" } },
                      [
                        _c("v-select", {
                          attrs: {
                            color: "verdeFayal",
                            items: _vm.status,
                            label: "Seleccione status",
                            "item-text": "estado",
                            "item-value": "id"
                          },
                          model: {
                            value: _vm.estado.estado,
                            callback: function($$v) {
                              _vm.$set(_vm.estado, "estado", $$v)
                            },
                            expression: "estado.estado"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-divider"),
                _vm._v(" "),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: { color: "primary", flat: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("close")
                          }
                        }
                      },
                      [_vm._v("Cerrar")]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "primary",
                          type: "submit",
                          disabled: _vm.$store.state.saveDisabled,
                          flat: ""
                        }
                      },
                      [_vm._v("Guardar")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }