<template>
  <div>
    <v-dialog v-model="localShow" max-width="290" persistent>
      <v-card>
        <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Crear requisición</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md class="text-xs-center">
          <v-layout wrap>
            <v-flex md12 class="text-xs-center">
              <v-radio-group v-model="choosen" :mandatory="false">
                <v-radio color="verdeFayal" label="Asegurar el mínimo." :value="1"></v-radio>
                <v-radio color="orange" label="Solo faltante." :value="2"></v-radio>
                <v-radio color="red" label="Todo." :value="3"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          :loading="vBtnSave.loading"
          @click="storeRequisiciones" 
          color="verdeFayal" 
          outline
          dark 
          flat 
        >
          Guardar 
        </v-btn> 
      </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data(){
    return{
      value: null,
      comparacion: false,
      localShow: false,
      choosen: 0,
      materiales: [],
      vBtnSave: {
        loading: false
      },
      vSnackBar: {
        visible: false,
        text: null
      }
    }
  },
  props:{
    show: Boolean,
    requisicion_id: Number
  },
  methods:{
    storeRequisiciones: function() {
      this.vBtnSave.loading = true;
      axios.post(laravelRoute("requisiciones.save.salidasalmacen"), {
        nombre_requisicion: `Orden de compra ${moment().format('DD/MM/YYYY HH:mm:ss')}`,
        solicitante: "Almacén",
        descripcion: "Requisición creada desde salidas de almacén.",
        salida_almacen_id: this.requisicion_id,
        choosen: this.choosen,
      })
      .then(response => {
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;
        this.localShow = false;
      })
      .catch(error => errorHandling(error))
      .finally(() => this.vBtnSave.loading = false);
    },
    getMateriales: function (page = 1) {
        axios.get(laravelRoute('materiales.index'))
        .then(response => {
          this.materiales = response.data.data;
        })
        .catch(error => errorHandling(error));
    },
    updateMaterial: function () {
        this.vBtnSave.loading = true;
        axios.patch('/materiales/' + this.requisicion_id, {
          nombre: this.materiales.map(element => element.nombre),
          unidad: this.materiales.map(element => element.unidad),
          categoria: this.materiales.map(element => element.categoria_id),
          precio: this.materiales.filter(element => element.precio),
          radiobuttons: this.radiobuttons
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.vBtnSave.loading = false;
          this.localShow = false;
        })
        .catch(error => {
          this.vBtnSave.loading = false;
          errorHandling(error)
        });
    },
  },
  // computed:{
  //   below: function(){
  //     return this.materiales.filter(element => element.existencia < element.minimo);
  //   },
  //   missing: function(){
  //     return this.materiales.filter(element => element.existencia)
  //   },
  //   all: function(){
  //     var newComparacion = this.comparacion === true;
  //     if(newComparacion) {return this.materiales.map(materiales => materiales.existencia += materiales.minimo)};
  //   },
  // },
  watch:{
    show: function (value) {
      this.localShow = value;
      this.choosen = 0;
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    }
  },
  mounted(){
    this.getMateriales();
  }
}
</script>