var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: !_vm.showFiltersForm, xs9: _vm.showFiltersForm } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Fraccionamientos")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", outline: "" },
                          on: {
                            click: function($event) {
                              _vm.showFiltersForm = !_vm.showFiltersForm
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("filter_alt")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", outline: "" },
                          on: { click: _vm.exportToExcel }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("import_export")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      "disable-initial-sort": "",
                      items: _vm.fraccionamientos.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(concretadosProps) {
                          return [
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(
                                _vm._s(
                                  concretadosProps.item.prospecto
                                    .nombre_proyecto
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(
                                _vm._s(
                                  concretadosProps.item.prospecto.direccion
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "text-xs-left fyl-fraccionamientos-td"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    concretadosProps.item.prospecto.descripcion
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              {
                                staticClass:
                                  "text-xs-left fyl-fraccionamientos-td"
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    concretadosProps.item.estado.information
                                      .estado || "No disponible"
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "div",
                                { staticClass: "text-xs-center" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { "justify-space-around": "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            flat: "",
                                            color: "black"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.expandOptions(
                                                concretadosProps.index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { dark: "" } },
                                            [_vm._v("more_horiz")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-expand-x-transition", [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  concretadosProps.item.expand,
                                                expression:
                                                  "concretadosProps.item.expand"
                                              }
                                            ]
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      disabled: !_vm.$root.can(
                                                        "files",
                                                        _vm.permissions
                                                      ),
                                                      flat: "",
                                                      icon: "",
                                                      color: "verdeFayal"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.elementId =
                                                          concretadosProps.item.prospecto.id
                                                        _vm.dialogFile = true
                                                      }
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("folder")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("Archivos")])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      disabled: !_vm.$root.can(
                                                        "status",
                                                        _vm.permissions
                                                      ),
                                                      flat: "",
                                                      icon: "",
                                                      color: "verdeFayal"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.dad_id =
                                                          concretadosProps.item.id
                                                        _vm.itemSelected =
                                                          concretadosProps.item
                                                        _vm.dialogStatus = true
                                                      }
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("low_priority")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("Estados")])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      disabled: !_vm.$root.can(
                                                        "lotification",
                                                        _vm.permissions
                                                      ),
                                                      flat: "",
                                                      icon: "",
                                                      color: "verdeFayal",
                                                      href:
                                                        "/proyectos/lotificaciones/" +
                                                        concretadosProps.item
                                                          .prospecto.id
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("launch")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "Propuestas de Lotificación"
                                                  )
                                                ])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      flat: "",
                                                      icon: "",
                                                      color: "verdeFayal",
                                                      href:
                                                        "/viviendas/fraccionamiento/" +
                                                        concretadosProps.item.id
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("house")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v("Viviendas")
                                                ])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      flat: "",
                                                      icon: "",
                                                      color: "verdeFayal",
                                                      href:
                                                        "/pruebas_laboratorio?projectId=" +
                                                        concretadosProps.item.id
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("fas fa-flask")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "Pruebas de laboratorio"
                                                  )
                                                ])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      flat: "",
                                                      icon: "",
                                                      color: "verdeFayal",
                                                      href:
                                                        "/tramites_dependencias?fraccionamiento_id=" +
                                                        concretadosProps.item.id
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("inventory")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "Trámites de dependencias"
                                                  )
                                                ])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      disabled: !_vm.$root.can(
                                                        "obra",
                                                        _vm.permissions
                                                      ),
                                                      flat: "",
                                                      icon: "",
                                                      color: "verdeFayal",
                                                      href:
                                                        "/etapas/" +
                                                        concretadosProps.item
                                                          .id +
                                                        "/create?tipo=obra"
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("house")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v("Etapas de Obra")
                                                ])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      disabled: !_vm.$root.can(
                                                        "urbanizacion",
                                                        _vm.permissions
                                                      ),
                                                      flat: "",
                                                      icon: "",
                                                      color: "verdeFayal",
                                                      href:
                                                        "/etapas/" +
                                                        concretadosProps.item
                                                          .id +
                                                        "/create?tipo=urbanizacion"
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("location_city")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    "Etapas de Urbanización"
                                                  )
                                                ])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      disabled: !_vm.$root.can(
                                                        "advances",
                                                        _vm.permissions
                                                      ),
                                                      flat: "",
                                                      icon: "",
                                                      color: "verdeFayal",
                                                      href:
                                                        "/etapas/fraccionamiento/" +
                                                        concretadosProps.item.id
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("date_range")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("Avances")])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      flat: "",
                                                      icon: "",
                                                      color: "verdeFayal",
                                                      href:
                                                        "/fraccionamientos/" +
                                                        concretadosProps.item
                                                          .id +
                                                        "/ventas"
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("point_of_sale")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v("Venta de lotes")
                                                ])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      href:
                                                        "/fraccionamientos/" +
                                                        concretadosProps.item
                                                          .id +
                                                        "/promocion",
                                                      color: "verdeFayal",
                                                      icon: "",
                                                      flat: ""
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("local_offer")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v("Lotes en promoción")
                                                ])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      href:
                                                        "/fraccionamientos/" +
                                                        concretadosProps.item
                                                          .id +
                                                        "/viviendas",
                                                      color: "verdeFayal",
                                                      icon: "",
                                                      flat: ""
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("villa")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v("Viviendas")
                                                ])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      flat: "",
                                                      icon: "",
                                                      color: "verdeFayal",
                                                      href:
                                                        "/fraccionamientos/" +
                                                        concretadosProps.item
                                                          .id +
                                                        "/reporte"
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("backup_table")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("Reporte")])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.fraccionamientos
                        },
                        on: {
                          "pagination-change-page": _vm.getFraccionamientos
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.showFiltersForm
            ? _c(
                "v-flex",
                { attrs: { xs3: "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { color: "white", flat: "" } },
                        [
                          _c("v-toolbar-title", [_vm._v("Filtros")]),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                icon: "",
                                color: "verdeFayal",
                                dark: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.showFiltersForm = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          color: "verdeFayal",
                                          items: _vm.estados,
                                          "item-value": "id",
                                          "item-text": "estado",
                                          label: "Status actual",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.filters.filterEstado,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "filterEstado",
                                              $$v
                                            )
                                          },
                                          expression: "filters.filterEstado"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                color: "verdeFayal",
                                small: "",
                                flat: "",
                                outline: ""
                              },
                              on: { click: _vm.limpiar }
                            },
                            [_vm._v("Limpiar")]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                color: "verdeFayal",
                                small: ""
                              },
                              on: { click: _vm.filterMaquinaria }
                            },
                            [_vm._v("Filtrar")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("upload-files-modal", {
            attrs: {
              element_type: "App\\Prospecto",
              element_id: _vm.elementId,
              dialog: _vm.dialogFile,
              edit_disabled: true
            },
            on: {
              close: function($event) {
                _vm.dialogFile = false
              }
            }
          }),
          _vm._v(" "),
          _c("fraccionamientos-show-status", {
            attrs: {
              dialog: _vm.dialogStatus,
              estado: _vm.itemSelected ? _vm.itemSelected.estado : null,
              estados: _vm.itemSelected ? _vm.itemSelected.all_estados : null,
              dad_id: _vm.dad_id
            },
            on: {
              close: function($event) {
                _vm.dialogStatus = false
              }
            }
          }),
          _vm._v(" "),
          _c("etapas-modal", {
            attrs: {
              propuestaLotificacion: _vm.itemSelected,
              dad: "Obra",
              dialog: _vm.dialogEtapasObras
            },
            on: {
              close: function($event) {
                _vm.dialogEtapasObras = false
              }
            }
          }),
          _vm._v(" "),
          _c("etapas-modal", {
            attrs: {
              propuestaLotificacion: _vm.itemSelected,
              dad: "Urbanización",
              dialog: _vm.dialogEtapasUrbanizacion
            },
            on: {
              close: function($event) {
                _vm.dialogEtapasUrbanizacion = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }