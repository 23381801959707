var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.dialog,
            persistent: "",
            "max-width": "700px",
            "hide-overlay": ""
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "verdeFayal" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-toolbar-title", [
                    _vm._v(
                      "Avances de trabajo para la etapa " + _vm._s(_vm.idEtapa)
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: {
                      label: "Nombre de la etapa",
                      disabled: "",
                      loading: _vm.loading
                    },
                    model: {
                      value: _vm.etapaNombre,
                      callback: function($$v) {
                        _vm.etapaNombre = $$v
                      },
                      expression: "etapaNombre"
                    }
                  }),
                  _vm._v(" "),
                  _vm.getLotes.length > 0
                    ? _c("etapas-card", {
                        attrs: {
                          ownerName: "Lotes",
                          headers: _vm.lotes.headers,
                          items: _vm.getLotes,
                          startFunction: _vm.iniciarLote,
                          stopFunction: _vm.detenerLote,
                          type: "obra"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-text",
                        [
                          _c("h4", [_vm._v("Manzanas Obra")]),
                          _vm._v(" "),
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-expansion-panel",
                                    { staticClass: "pt-1" },
                                    _vm._l(_vm.getManzanasObra, function(
                                      manzana,
                                      index
                                    ) {
                                      return _c(
                                        "v-expansion-panel-content",
                                        {
                                          key: index,
                                          attrs: { expand: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "actions",
                                                fn: function() {
                                                  return [
                                                    _c("v-icon", [
                                                      _vm._v("expand_more")
                                                    ])
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "header",
                                                fn: function() {
                                                  return [
                                                    _c("div", [
                                                      _vm._v(
                                                        "Manzana " +
                                                          _vm._s(manzana.nombre)
                                                      )
                                                    ])
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _vm._v(" "),
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "v-expansion-panel",
                                                    _vm._l(
                                                      manzana.lotes,
                                                      function(lote, index) {
                                                        return _c(
                                                          "v-expansion-panel-content",
                                                          {
                                                            key: index,
                                                            attrs: {
                                                              expand: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "actions",
                                                                  fn: function() {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "expand_more"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                },
                                                                {
                                                                  key: "header",
                                                                  fn: function() {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "Lote " +
                                                                              _vm._s(
                                                                                lote.nombre
                                                                              )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  },
                                                                  proxy: true
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _vm._v(" "),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-card",
                                                              [
                                                                _vm.etapa.lotes.find(
                                                                  function(
                                                                    oLote
                                                                  ) {
                                                                    return (
                                                                      lote.id ===
                                                                      oLote.id
                                                                    )
                                                                  }
                                                                )
                                                                  ? _c(
                                                                      "v-card-text",
                                                                      [
                                                                        _c(
                                                                          "v-expansion-panel",
                                                                          _vm._l(
                                                                            _vm.getConceptosArrayFromLote(
                                                                              lote
                                                                            ),
                                                                            function(
                                                                              concepto,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "v-expansion-panel-content",
                                                                                {
                                                                                  key: index,
                                                                                  scopedSlots: _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key:
                                                                                          "actions",
                                                                                        fn: function() {
                                                                                          return [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "expand_more"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ]
                                                                                        },
                                                                                        proxy: true
                                                                                      },
                                                                                      {
                                                                                        key:
                                                                                          "header",
                                                                                        fn: function() {
                                                                                          return [
                                                                                            _c(
                                                                                              "div",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    concepto.nombre
                                                                                                  )
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ]
                                                                                        },
                                                                                        proxy: true
                                                                                      }
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  )
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "v-card",
                                                                                    [
                                                                                      _c(
                                                                                        "v-divider"
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _vm._l(
                                                                                        _vm.getRubrosArrayFromConcepto(
                                                                                          lote,
                                                                                          concepto.id
                                                                                        ),
                                                                                        function(
                                                                                          rubro,
                                                                                          index
                                                                                        ) {
                                                                                          return _c(
                                                                                            "v-card-actions",
                                                                                            {
                                                                                              key: index,
                                                                                              staticClass:
                                                                                                "pl-4 pr-4"
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      rubro.nombre
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "v-spacer"
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "v-tooltip",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    bottom:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-btn",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        slot:
                                                                                                          "activator",
                                                                                                        icon:
                                                                                                          "",
                                                                                                        flat:
                                                                                                          "",
                                                                                                        dark:
                                                                                                          "",
                                                                                                        small:
                                                                                                          "",
                                                                                                        color:
                                                                                                          "info"
                                                                                                      },
                                                                                                      slot:
                                                                                                        "activator"
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "play_arrow"
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Iniciar"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "v-tooltip",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    bottom:
                                                                                                      ""
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-btn",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        slot:
                                                                                                          "activator",
                                                                                                        icon:
                                                                                                          "",
                                                                                                        flat:
                                                                                                          "",
                                                                                                        dark:
                                                                                                          "",
                                                                                                        small:
                                                                                                          "",
                                                                                                        color:
                                                                                                          "red"
                                                                                                      },
                                                                                                      slot:
                                                                                                        "activator"
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "stop"
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "span",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "Finalizar"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "v-divider"
                                                                                      )
                                                                                    ],
                                                                                    2
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            }
                                                                          ),
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _vm.getLotes.length < 1
                    ? _c(
                        "div",
                        [
                          _c("etapas-card", {
                            attrs: {
                              ownerName: "'Manzanas",
                              headers: _vm.manzanas_urbanizacion.headers,
                              items: _vm.getManzanasUrbanizacion,
                              startFunction: _vm.iniciarManzanaUrbanizacion,
                              stopFunction: _vm.detenerManzanaUrbanizacion,
                              type: "urbanizacion"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-divider")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getLotes.length < 1
                    ? _c(
                        "div",
                        [
                          _c("etapas-card", {
                            attrs: {
                              ownerName: "'Áreas verdes",
                              headers: _vm.manzanas_urbanizacion.headers,
                              items: _vm.getAreasVerdes,
                              startFunction: _vm.iniciarAreaVerde,
                              stopFunction: _vm.detenerAreaVerde,
                              type: "urbanizacion"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-divider")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getLotes.length < 1
                    ? _c(
                        "div",
                        [
                          _c("etapas-card", {
                            attrs: {
                              ownerName: "'Calles",
                              headers: _vm.manzanas_urbanizacion.headers,
                              items: _vm.getCalles,
                              startFunction: _vm.iniciarCalle,
                              stopFunction: _vm.detenerCalle,
                              type: "urbanizacion"
                            }
                          }),
                          _vm._v(" "),
                          _c("v-divider")
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }