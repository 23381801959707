var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            small: "",
                            flat: "",
                            href: "/fraccionamientos"
                          }
                        },
                        [_c("v-icon", [_vm._v("chevron_left")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-toolbar-title", [
                        _vm._v("Etapas de fraccionamiento")
                      ]),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      "disable-initial-sort": "",
                      items: _vm.etapas.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(etapasProps) {
                          return [
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(_vm._s(etapasProps.item.nombre))
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-xs-left capitalize" },
                              [_vm._v(_vm._s(etapasProps.item.tipo))]
                            ),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(
                                _vm._s(
                                  etapasProps.item.estado.information
                                    ? etapasProps.item.estado.information.estado
                                    : "No disponible"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-xs-left" },
                              [
                                _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "verdeFayal"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openDatosModal(
                                                  etapasProps.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("watch_later")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("Ver cálculos de tiempo")
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "info",
                                              href: _vm.laravelRoute(
                                                "etapas.edit",
                                                etapasProps.item.id
                                              )
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { color: "verdeFayal" }
                                              },
                                              [_vm._v("remove_red_eye")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Ver")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled:
                                                etapasProps.item.estado
                                                  .estado_id != 25,
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "info"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.startEtapa(
                                                  etapasProps.item
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c("v-icon", [_vm._v("play_arrow")])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Iniciar")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled:
                                                etapasProps.item.estado
                                                  .estado_id != 26,
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "red"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.stopEtapa(
                                                  etapasProps.item
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("stop")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Finalizar")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "#d74d5a",
                                              href:
                                                "/incidencias/owner/" +
                                                etapasProps.item.id,
                                              disabled:
                                                etapasProps.item.estado
                                                  .estado_id != 26
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("alarm_add")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Incidencias")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "#a7b14a",
                                              disabled:
                                                etapasProps.item.estado
                                                  .estado_id != 26,
                                              href:
                                                "/etapas/" +
                                                etapasProps.item.id +
                                                "/actividades"
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c("v-icon", [_vm._v("date_range")])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("Iniciar actividades")
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "red",
                                              disabled:
                                                etapasProps.item.estado
                                                  .estado_id == 26
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteEtapa(
                                                  etapasProps.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("delete")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Eliminar etapa")])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "no-results",
                        fn: function() {
                          return [
                            _c(
                              "v-alert",
                              {
                                attrs: {
                                  value: true,
                                  color: "error",
                                  icon: "warning"
                                }
                              },
                              [
                                _vm._v(
                                  'Your search for "' +
                                    _vm._s(_vm.search) +
                                    '" found no results.'
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.etapas
                        },
                        on: { "pagination-change-page": _vm.getEtapas }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-snackbar",
            {
              attrs: { bottom: "bottom", right: "right" },
              model: {
                value: _vm.vSnackBar.visible,
                callback: function($$v) {
                  _vm.$set(_vm.vSnackBar, "visible", $$v)
                },
                expression: "vSnackBar.visible"
              }
            },
            [
              _vm._v("\n      " + _vm._s(_vm.vSnackBar.text) + "\n      "),
              _c(
                "v-btn",
                {
                  attrs: { color: "pink", flat: "" },
                  on: {
                    click: function($event) {
                      _vm.vSnackBar.visible = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("etapas-iniciar", {
            attrs: {
              dialog: _vm.dialogEtapasIniciar,
              idEtapa: _vm.idEtapaToFind
            },
            on: {
              close: function($event) {
                _vm.dialogEtapasIniciar = false
              }
            }
          }),
          _vm._v(" "),
          _c("etapas-edit", {
            attrs: {
              propuestaLotificacion: _vm.etapaSelected,
              dad: _vm.etapaSelected.tipo,
              dialog: _vm.dialog
            },
            on: {
              close: function($event) {
                _vm.dialog = false
              }
            }
          }),
          _vm._v(" "),
          _c("etapa-hours-calculation-modal", {
            attrs: { show: _vm.showDatosModal, "etapa-id": _vm.currentEtapaId },
            on: {
              "update:show": function($event) {
                _vm.showDatosModal = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }