<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Prima vacacional</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider />

      <v-card-text>
        <v-container grid-list-md>
          <v-select
            :items="getVacationsFiltered"
            :item-text="vacacion => vacacion.antiguedad_years + ' años'"
            :item-value="item => item"
            v-model="selected_vacation"
            label="Seleccione año de antiguedad"
          />
          <v-layout wrap>
            <v-flex md4>
              <v-text-field v-model="selected_vacation.dias_totales" label="Dias totales" readonly/>
            </v-flex>
            <v-flex md4>
              <v-text-field v-model="selected_vacation.pivot.dias_tomados" label="Dias tomados" readonly/>
            </v-flex>
            <v-flex md4>
              <v-text-field v-model="selected_vacation.pivot.dias_restantes" label="Dias restantes" readonly/>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text> 
    
    <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="calculatePrimaVacacional()" flat :loading="vBtnSave.loading">Calcular</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data() {
    return {
      area_nomina_trabajador: {
        id: null,
        trabajador: {vacaciones: []},
        pagos: [],
        descripcion: null,
      },
      selected_vacation: {
        dias_totales: 0,
        pivot: {dias_restantes: 0, dias_tomados: 0}
      },
      localShow: false,
      vBtnSave: {
        loading: false
      },
    }
  },
  props: {
    show: Boolean,
    selected_area_nomina_trabajador_id: Number,
  },
  watch: {
    show: function (value) {
      this.getAreaNominaTrabajador();
      this.localShow = value;
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
  },
  computed: {
    getVacationsFiltered: function () {
      let years = this.getYears(this.area_nomina_trabajador.trabajador.fecha_de_contratacion);
      const vacations = [];
      this.area_nomina_trabajador.trabajador.vacaciones.forEach(vacation => {
        if (vacation.id <= years && vacation.pivot.prima_pagada == 0) 
          vacations.push(vacation);
      });
      return vacations;
    }
  },
  methods: {
    getAreaNominaTrabajador: function () {
      axios.get(laravelRoute('areas_nominas.getWorker', this.selected_area_nomina_trabajador_id))
      .then(response => this.area_nomina_trabajador = response.data)
      .catch(error => errorHandling(error));
    },
    calculatePrimaVacacional: function () {
      axios.patch(
        laravelRoute('areas_nominas.trabajador.prima_vacacional', this.area_nomina_trabajador.id), 
        {trabajador_vacacion_id: this.selected_vacation.pivot.id}
      )
      .then(response => {
        if (response.data.message_type === 'success') {
          alertify.success(response.data.message_text);
          this.localShow = false;
        } else alertify.error(response.data.message_text)
      })                
      .catch(error => errorHandling(error));
    },
    getYears: function (dateString) {
      let today = new Date()
      let date = new Date(dateString)
      let edad = today.getFullYear() - date.getFullYear()
      let diferenciaMeses = today.getMonth() - date.getMonth()
      if ( diferenciaMeses < 0 || 
          (diferenciaMeses === 0 && today.getDate() < date.getDate()))
        edad--

      return edad
    }
  },
}
</script>