var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.vHeaders,
                  items: _vm.materials.data,
                  "hide-actions": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c("td", [
                          _vm._v(_vm._s(props.item.material.categoria.nombre))
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(props.item.material.nombre))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(props.item.material.unidad))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(props.item.cantidad))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(props.item.distribucion))]),
                        _vm._v(" "),
                        props.item.distribucion == "Áreas verdes"
                          ? _c("td", [
                              _vm._v(_vm._s(props.item.area_verde.nombre))
                            ])
                          : props.item.distribucion == "Calles"
                          ? _c("td", [_vm._v(_vm._s(props.item.calle.nombre))])
                          : props.item.distribucion == "Manzanas"
                          ? _c("td", [
                              _vm._v(_vm._s(props.item.manzana.nombre))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              props.item.distribucion == "Manzanas"
                                ? props.item.lote
                                  ? props.item.lote.nombre
                                  : "Sin lote seleccionado"
                                : "No aplica"
                            )
                          )
                        ])
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("pagination", {
                    attrs: { limit: _vm.vPaginator.limit, data: _vm.materials },
                    on: { "pagination-change-page": _vm.getMaterialsValue }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }