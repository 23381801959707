var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.dialog,
            persistent: "",
            "max-width": "700px",
            "hide-overlay": ""
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Incidencia para " + _vm._s(_vm.etapa.nombre))
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", flat: "", color: "verdeFayal" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: !!_vm.incidenciaId,
                      label: "Nombre de la incidencia"
                    },
                    model: {
                      value: _vm.incidencia.nombre,
                      callback: function($$v) {
                        _vm.$set(_vm.incidencia, "nombre", $$v)
                      },
                      expression: "incidencia.nombre"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-textarea", {
                    attrs: {
                      disabled: !!_vm.incidenciaId,
                      "auto-grow": "",
                      color: "verdeFayal",
                      label: "Descripción de la incidencia",
                      rows: "3",
                      max: "250",
                      counter: "250"
                    },
                    model: {
                      value: _vm.incidencia.descripcion,
                      callback: function($$v) {
                        _vm.$set(_vm.incidencia, "descripcion", $$v)
                      },
                      expression: "incidencia.descripcion"
                    }
                  }),
                  _vm._v(" "),
                  !!_vm.manzanas.items.length
                    ? _c(
                        "div",
                        [
                          _c("h4", [_vm._v("Manzanas")]),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  disabled: !!_vm.incidenciaId,
                                  attach: "",
                                  "browser-autocomplete": "off",
                                  "hide-selected": "",
                                  "return-object": "",
                                  placeholder:
                                    "Seleccione los elementos a incluir en la incidencia",
                                  chips: true,
                                  multiple: true,
                                  "deletable-chips": true,
                                  "item-text": "manzana_lotificación.nombre",
                                  "item-value": "manzana_lotificación.id",
                                  items: _vm.manzanas.items
                                },
                                model: {
                                  value: _vm.manzanas.selected,
                                  callback: function($$v) {
                                    _vm.$set(_vm.manzanas, "selected", $$v)
                                  },
                                  expression: "manzanas.selected"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              "hide-actions": true,
                              "disable-initial-sort": true,
                              headers: _vm.manzanas.headers,
                              items: _vm.manzanas.selected
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "items",
                                  fn: function(props) {
                                    return [
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                color: "verdeFayal",
                                                outline: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " Manzana: " +
                                                  _vm._s(
                                                    props.item
                                                      .manzana_lotificación
                                                      .nombre
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              disabled: !!_vm.incidenciaId,
                                              type: "Number",
                                              label: "Horas de retraso",
                                              min: "1"
                                            },
                                            on: { input: _vm.calculate },
                                            model: {
                                              value:
                                                _vm.manzanas.selected[
                                                  _vm.manzanas.selected.indexOf(
                                                    props.item
                                                  )
                                                ].horas_incidencia,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.manzanas.selected[
                                                    _vm.manzanas.selected.indexOf(
                                                      props.item
                                                    )
                                                  ],
                                                  "horas_incidencia",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "manzanas.selected[manzanas.selected.indexOf(props.item)].horas_incidencia"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1525752377
                            )
                          }),
                          _vm._v(" "),
                          _c("v-divider")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !!_vm.lotes.items.length
                    ? _c(
                        "div",
                        [
                          _c("h4", [_vm._v("Lotes")]),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  disabled: !!_vm.incidenciaId,
                                  attach: "",
                                  "browser-autocomplete": "off",
                                  "hide-selected": "",
                                  "return-object": "",
                                  placeholder:
                                    "Seleccione los elementos a incluir en la incidencia",
                                  chips: true,
                                  multiple: true,
                                  "deletable-chips": true,
                                  "item-text": "lote_lotificacion.nombre",
                                  "item-value": "lote_lotificacion.id",
                                  items: _vm.lotes.items
                                },
                                model: {
                                  value: _vm.lotes.selected,
                                  callback: function($$v) {
                                    _vm.$set(_vm.lotes, "selected", $$v)
                                  },
                                  expression: "lotes.selected"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              "hide-actions": true,
                              "disable-initial-sort": true,
                              headers: _vm.lotes.headers,
                              items: _vm.lotes.selected
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "items",
                                  fn: function(props) {
                                    return [
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                color: "verdeFayal",
                                                outline: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " Lote: " +
                                                  _vm._s(
                                                    props.item.lote_lotificacion
                                                      .nombre
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              disabled: !!_vm.incidenciaId,
                                              type: "Number",
                                              label: "Horas de retraso",
                                              min: "1"
                                            },
                                            on: { input: _vm.calculate },
                                            model: {
                                              value:
                                                _vm.lotes.selected[
                                                  _vm.lotes.selected.indexOf(
                                                    props.item
                                                  )
                                                ].horas_incidencia,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.lotes.selected[
                                                    _vm.lotes.selected.indexOf(
                                                      props.item
                                                    )
                                                  ],
                                                  "horas_incidencia",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "lotes.selected[lotes.selected.indexOf(props.item)].horas_incidencia"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2157860165
                            )
                          }),
                          _vm._v(" "),
                          _c("v-divider")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !!_vm.areas_verdes.items.length
                    ? _c(
                        "div",
                        [
                          _c("h4", [_vm._v("Äreas Verdes")]),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  disabled: !!_vm.incidenciaId,
                                  attach: "",
                                  "browser-autocomplete": "off",
                                  "hide-selected": "",
                                  "return-object": "",
                                  placeholder:
                                    "Seleccione los elementos a incluir en la incidencia",
                                  chips: true,
                                  multiple: true,
                                  "deletable-chips": true,
                                  "item-text": "area_verde_lotificación.nombre",
                                  "item-value": "area_verde_lotificación.id",
                                  items: _vm.areas_verdes.items
                                },
                                model: {
                                  value: _vm.areas_verdes.selected,
                                  callback: function($$v) {
                                    _vm.$set(_vm.areas_verdes, "selected", $$v)
                                  },
                                  expression: "areas_verdes.selected"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              disabled: !!_vm.incidenciaId,
                              "hide-actions": true,
                              "disable-initial-sort": true,
                              headers: _vm.areas_verdes.headers,
                              items: _vm.areas_verdes.selected
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "items",
                                  fn: function(props) {
                                    return [
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                color: "verdeFayal",
                                                outline: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.item
                                                      .area_verde_lotificación
                                                      .nombre
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              disabled: !!_vm.incidenciaId,
                                              type: "Number",
                                              label: "Horas de retraso",
                                              min: "1"
                                            },
                                            on: { input: _vm.calculate },
                                            model: {
                                              value:
                                                _vm.areas_verdes.selected[
                                                  _vm.areas_verdes.selected.indexOf(
                                                    props.item
                                                  )
                                                ].horas_incidencia,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.areas_verdes.selected[
                                                    _vm.areas_verdes.selected.indexOf(
                                                      props.item
                                                    )
                                                  ],
                                                  "horas_incidencia",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "areas_verdes.selected[areas_verdes.selected.indexOf(props.item)].horas_incidencia"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3827655595
                            )
                          }),
                          _vm._v(" "),
                          _c("v-divider")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !!_vm.calles.items.length
                    ? _c(
                        "div",
                        [
                          _c("h4", [_vm._v("Calles")]),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  disabled: !!_vm.incidenciaId,
                                  attach: "",
                                  "browser-autocomplete": "off",
                                  "hide-selected": "",
                                  "return-object": "",
                                  placeholder:
                                    "Seleccione los elementos a incluir en la incidencia",
                                  chips: true,
                                  multiple: true,
                                  "deletable-chips": true,
                                  "item-text": "calles_lotificación.nombre",
                                  "item-value": "calles_lotificación.id",
                                  items: _vm.calles.items
                                },
                                model: {
                                  value: _vm.calles.selected,
                                  callback: function($$v) {
                                    _vm.$set(_vm.calles, "selected", $$v)
                                  },
                                  expression: "calles.selected"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              "hide-actions": true,
                              "disable-initial-sort": true,
                              headers: _vm.calles.headers,
                              items: _vm.calles.selected
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "items",
                                  fn: function(props) {
                                    return [
                                      _c(
                                        "td",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2",
                                              attrs: {
                                                color: "verdeFayal",
                                                outline: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " Calle: " +
                                                  _vm._s(
                                                    props.item
                                                      .calles_lotificación
                                                      .nombre
                                                  ) +
                                                  "\n                "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              disabled: !!_vm.incidenciaId,
                                              type: "Number",
                                              label: "Horas de retraso",
                                              min: "1"
                                            },
                                            on: { input: _vm.calculate },
                                            model: {
                                              value:
                                                _vm.calles.selected[
                                                  _vm.calles.selected.indexOf(
                                                    props.item
                                                  )
                                                ].horas_incidencia,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.calles.selected[
                                                    _vm.calles.selected.indexOf(
                                                      props.item
                                                    )
                                                  ],
                                                  "horas_incidencia",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "calles.selected[calles.selected.indexOf(props.item)].horas_incidencia"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3917724106
                            )
                          }),
                          _vm._v(" "),
                          _c("v-divider")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", [
                    _c("h3", [_vm._v("Horas: " + _vm._s(_vm.incidencia.horas))])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !!_vm.incidenciaId,
                        color: "verdeFayal",
                        flat: ""
                      },
                      on: { click: _vm.makeBody }
                    },
                    [_vm._v("\n          Guardar\n        ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }