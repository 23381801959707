<template>
  <v-autocomplete
    v-model="model"
    browser-autocomplete="off"
    hide-selected
    return-object
    :items="items"
    :loading="isLoading"
    :search-input.sync="search"
    :hide-no-data="!search"
    :no-data-text="configuration.noDataText"
    :item-text="configuration.itemText"
    :item-value="configuration.itemValue"
    :placeholder="configuration.placeholder"
    :prepend-icon="configuration.prependIcon"
    :chips="configuration.chips"
    :multiple="configuration.multiple"
    :deletable-chips="configuration.deleteChips"
    :disabled="configuration.disabled"
    @change="selected"
  >
    <template slot="no-data">
      <v-list-tile style="cursor: pointer;">
        <v-list-tile-content>
          <v-list-tile-title>No se encontró ningún registro.</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </template>
  </v-autocomplete>
</template>

<script>
import { errorHandling, laravelRoute } from "../lib/utilities";
  export default {
    data: () => ({
      entries: [],
      isLoading: false,
      model: null,
      search: null
    }),
    props:{
      /**
       *All configuration to Searcher
       * `small, normal, large`
       */
      configuration: { 
        type: Object,
        default: ()=>({
          url: "https://api.kanye.rest",
          useHeaders: false,
          paginatedContainer: 'data',
          noDataText: "No se encontraron resultados.",
          itemText: "nombre",
          itemValue: "API",
          descriptionLimit: 60,
          placeholder: "Buscar",
          prependIcon: "mdi-database-search",
          chips: true,
          multiple: true,
          deleteChips: true,
          disabled: false,
        })
      },
      itemsShared:{
        type: Array,
        default: ()=>([])
      }
    },
    computed: {
      fields () {
        if (!this.model) return []
        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a'
          }
        })
      },
      items () {
        return this.computedEntries.map(entry => {
          const Description = entry[this.configuration.itemText].length > this.configuration.descriptionLimit 
            ? entry[this.configuration.itemText].slice(0, this.configuration.descriptionLimit ) + '...'
            : entry[this.configuration.itemText]

          return Object.assign({}, entry, { Description })
        })
      },
      computedEntries: function(){
        return this.entries;
      }
    },

    watch: {
      search (val) {
        // Items have already been loaded

        /**
         * No sé si la siguiente linea sea importante
         * pero la eliminé para que pudiera seguir buscando
         * elementos inluso si le pasan objetos como parametros
         * esto sucede generalmente al utilizarlo como componente de 
         * edición y no de creación.
         */
        // if (this.itemsShared.length > 0) return;

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true

        // Lazily load input items
        axios.get(this.configuration.url, this.configuration.useHeaders ? {
          'headers': { 
            'Accept': 'application/json', 
            'Content-Type': 'application/json',
          },
          'params': {
            'paginated':0
          }
        } : null )
        .then(response => {
          this.entries = Array.isArray(response.data) ? response.data : response.data[this.configuration.paginatedContainer];
        })
        .catch(error => {
          errorHandling(error);
        })
        .finally(() => (this.isLoading = false));
        
      },

      itemsShared(value) {
        this.entries = value;
        this.model = this.items;
        this.selected();
      },
    },

    methods: {
      selected: function(){
        this.$emit('itemSelected', this.model)
        this.search = null;
      },
    }

  }
</script>
