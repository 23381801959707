var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Nueva máquina")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: "Nombre de la máquina"
                                },
                                model: {
                                  value: _vm.maquinaria.nombre,
                                  callback: function($$v) {
                                    _vm.$set(_vm.maquinaria, "nombre", $$v)
                                  },
                                  expression: "maquinaria.nombre"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", md6: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  color: "verdeFayal",
                                  items: _vm.estados,
                                  "item-value": "id",
                                  "item-text": "estado",
                                  label: "Seleccione status"
                                },
                                model: {
                                  value: _vm.maquinaria.estado,
                                  callback: function($$v) {
                                    _vm.$set(_vm.maquinaria, "estado", $$v)
                                  },
                                  expression: "maquinaria.estado"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Descripción",
                                  counter: "250",
                                  type: "text",
                                  min: "0",
                                  max: "250"
                                },
                                model: {
                                  value: _vm.maquinaria.descripcion,
                                  callback: function($$v) {
                                    _vm.$set(_vm.maquinaria, "descripcion", $$v)
                                  },
                                  expression: "maquinaria.descripcion"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm12: "", md12: "" } },
                            [
                              _c("v-switch", {
                                staticStyle: { "margin-top": "0" },
                                attrs: {
                                  label: "Agregar mantenimiento",
                                  color: "verdeFayal"
                                },
                                model: {
                                  value: _vm.createMantenimiento,
                                  callback: function($$v) {
                                    _vm.createMantenimiento = $$v
                                  },
                                  expression: "createMantenimiento"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-form",
                            { ref: "form", attrs: { "lazy-validation": "" } },
                            _vm._l(_vm.mantenimientos, function(
                              mantenimiento,
                              index
                            ) {
                              return _c(
                                "v-container",
                                { key: index, attrs: { "grid-list-md": "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { sm12: "", xs12: "", md12: "" } },
                                    [
                                      _vm.createMantenimiento
                                        ? _c(
                                            "v-layout",
                                            { attrs: { wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs12: "",
                                                    sm4: "",
                                                    md4: ""
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      color: "verdeFayal",
                                                      items:
                                                        _vm.mantenimientoslistProps,
                                                      "item-value": "id",
                                                      "item-text": "nombre",
                                                      label:
                                                        "Seleccione mantenimiento"
                                                    },
                                                    model: {
                                                      value:
                                                        mantenimiento.mantenimiento_id,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          mantenimiento,
                                                          "mantenimiento_id",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mantenimiento.mantenimiento_id"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs12: "",
                                                    sm3: "",
                                                    md3: ""
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      color: "verdeFayal",
                                                      items:
                                                        _vm.statusMantenimiento,
                                                      "item-value": "id",
                                                      "item-text": "estado",
                                                      label: "Seleccione status"
                                                    },
                                                    model: {
                                                      value:
                                                        mantenimiento.estado,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          mantenimiento,
                                                          "estado",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mantenimiento.estado"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs10: "",
                                                    sm3: "",
                                                    md3: ""
                                                  }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      color: "#1ABA24",
                                                      label:
                                                        "Fecha de inicio de mantenimiento",
                                                      type: "date"
                                                    },
                                                    model: {
                                                      value:
                                                        mantenimiento.fecha,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          mantenimiento,
                                                          "fecha",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "mantenimiento.fecha"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs1: "",
                                                    sm1: "",
                                                    md1: ""
                                                  }
                                                },
                                                [
                                                  index != 0
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            fab: "",
                                                            flat: "",
                                                            dark: "",
                                                            small: "",
                                                            color: "verdeFayal"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.mantenimientos.splice(
                                                                index,
                                                                1
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                dark: ""
                                                              }
                                                            },
                                                            [_vm._v("remove")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.vBtnSave.loading,
                        dark: "",
                        color: "verdeFayal",
                        flat: ""
                      },
                      on: { click: _vm.storeMaquinaria }
                    },
                    [_vm._v(" Guardar ")]
                  ),
                  _vm._v(" "),
                  _vm.createMantenimiento
                    ? _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal" },
                          on: { click: _vm.addMateriales }
                        },
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }