var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "980" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Ver listado de materiales")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-sm": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _vm.loadingMaterials
                                ? _c("v-progress-linear", {
                                    attrs: {
                                      color: "verdeFayal",
                                      indeterminate: true
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-data-table", {
                                attrs: {
                                  "hide-actions": "",
                                  items: _vm.materiales.data
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "headers",
                                    fn: function(props) {
                                      return [
                                        _c("tr", [
                                          _c("th", [_vm._v("Categoria")]),
                                          _vm._v(" "),
                                          _c("th", [_vm._v("Nombre")]),
                                          _vm._v(" "),
                                          _c("th", [_vm._v("Unidad")]),
                                          _vm._v(" "),
                                          _c("th", [_vm._v("Proveedor")]),
                                          _vm._v(" "),
                                          _c("th", [_vm._v("Precio original")]),
                                          _vm._v(" "),
                                          _c("th", [
                                            _vm._v("Precio de compra")
                                          ]),
                                          _vm._v(" "),
                                          _c("th", [
                                            _vm._v("Cantidad solicitada")
                                          ]),
                                          _vm._v(" "),
                                          _c("th", [_vm._v("Total esperado")]),
                                          _vm._v(" "),
                                          _c("th", [
                                            _vm._v("Cantidad surtida")
                                          ]),
                                          _vm._v(" "),
                                          _c("th", [_vm._v("Total real")])
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "items",
                                    fn: function(props) {
                                      return [
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.material
                                                  .categoria_asociada
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(props.item.material.nombre)
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(props.item.material.unidad)
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.material_requisicion
                                                  .proveedor.nombre_completo
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "$ " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    props.item.material.precio
                                                  )
                                                )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "$ " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    props.item.precio_compra
                                                  )
                                                )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(_vm._s(props.item.cantidad))
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "$ " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    props.item.total
                                                  )
                                                )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.material_requisicion
                                                  .cantidad_a_surtir
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "$ " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    props.item.precio_compra *
                                                      props.item
                                                        .material_requisicion
                                                        .cantidad_a_surtir
                                                  )
                                                )
                                            )
                                          ])
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "no-data",
                                    fn: function() {
                                      return [
                                        _c("p", [
                                          _vm._v("Sin datos encontrados")
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("pagination", {
                    attrs: { limit: 10, data: _vm.materiales },
                    on: { "pagination-change-page": _vm.getMaterials }
                  }),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }