<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="500px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Seleccione el proveedor</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container grid-list-md>
            <v-flex xs12>
              <searcher
                :configuration="searchProvider"
                :itemSelected.sync="proveedor"
              ></searcher>
            </v-flex>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="verdeFayal" type="submit" :loading="vBtnSave.loading" flat @click="storeRequisicion">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>

export default {
  data: () => ({
    pago: {
      all_estados: []
    },
    proveedores: [],
    proveedor: {},
    searchProvider: {
      url: "/proveedores/por_categoria",
      paginatedContainer: 'data',
      noDataText: "No se encontraron resultados.",
      label: "Seleccione el proveedor",
      itemText: "nombre_completo",
      itemValue: "id",
      descriptionLimit: 60,
      disabled: false,
      useHeaders: true,
    },
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    requisicionMaterial: Number,
    materialCategory: String
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    requisicionMaterial: function(value) {
      this.getRequisicionMaterial();
    }
  },
  methods: {
    getProveedores: function(){
      axios.get('/proveedores', this.materialCategory).then(response => {
        this.proveedores = response.data;
      });
    },
    storeRequisicion: function () {
      this.vBtnSave.loading = true;
      axios.patch(laravelRoute('materiales_requisicion.update', this.requisicionMaterial), {
        proveedor: this.proveedor.id
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.proveedor = null;
        this.localShow = false; 
      })
      .catch(error => {
        errorHandling(error)
      })
      .finally(()=>this.vBtnSave.loading = false);
    },
  },
  created() {
    this.getProveedores();
  }
}
</script>
