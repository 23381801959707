<template>
  <v-layout>
    <v-dialog :value="localShow" persistent max-width="700px">
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Crear trámite</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-select
                    :items="dependencias"
                    item-text="nombre_dependencia"
                    item-value="id"
                    label="Dependencias"
                    v-model="tramite.dependencia_id"
                    @change="getDepartamentos"
                    :rules="rules"
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-if="tramite.dependencia_id && departamentos.length"
                    :items="departamentos"
                    item-text="nombre_departamento"
                    item-value="id"
                    label="Departamento"
                    v-model="tramite.departamento_id"
                    @change="getTramites"
                    :rules="rules"
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-if="tramite.dependencia_id && tramites.length"
                    :items="tramites"
                    item-text="nombre_tramite"
                    item-value="id"
                    label="Trámites"
                    v-model="tramite.tramite"
                    return-object
                    :rules="rules"
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <searcher                      
                    :configuration="searchComponent"
                    :itemSelected.sync="tramite.proyecto_perteneciente"                                            
                  ></searcher>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    color="verdeFayal"
                    type="date"
                    label="Fecha de resolución"
                    v-model="tramite.fecha_resolucion"
                    :rules="rules"
                  ></v-text-field>
                </v-flex>
                <v-flex v-if="!this.tramite.id" xs12 sm6 md6>
                  <v-select
                    color="verdeFayal"
                    :items="estados"
                    label="Seleccione el estado"
                    v-model="tramite.estado"
                    item-text="estado"
                    item-value="id"
                    :rules="rules"
                  ></v-select>         
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    color="verdeFayal"
                    label="Descripción"
                    type="textarea"
                    v-model="tramite.descripcion"
                    counter="250"
                    :maxlength="max"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="verdeFayal"
            flat
            dark
            @click="storeTramite"
            outline
          >
            Guardar
          </v-btn>          
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-layout>
</template>

<script>
import { errorHandling, laravelRoute } from "../../lib/utilities";
export default {
  props: ["show"],

  data() {
    return {
      tramite: {},
      localShow: false,
      dependencias: [],
      departamentos: [],
      tramites: [],
      estados: [],
      max: 250,
      valid: false,
      searchComponent: {
        url: "/prospectos",
        paginatedContainer: 'data',
        noDataText: "No se encontraron resultados.",
        label: "Proyecto al que pertenece",
        itemText: "nombre_proyecto",
        itemValue: "id",
        descriptionLimit: 60,
        disabled: false,
        useHeaders: true,
      },
        vBtnSave: {
        loading: false
      },
      vSnackBar: {
        visible: false,
        text: null
      },
      rules: [v => !!v || "Este campo es requerido"],
    };
  },

  mounted() {
    this.getDependencias();
    this.getEstados();
  },

  methods: {
    getDependencias: function() {
      axios.get("/get_dependencias")
      .then(response => this.dependencias = response.data)
      .catch(error => errorHandling(error));
    },

    getEstados: function() {
      axios.get("/tramites_dependencias/estados")
      .then(response => this.estados = response.data)
      .catch(error => errorHandling(error));
    },

    getDepartamentos: function() {
      this.tramites = [];
      axios.get(laravelRoute('tramites_dependencias.departamentos', this.tramite.dependencia_id))
      .then(response => { 
        this.departamentos = response.data;
        if(!this.departamentos.length) this.getTramites();
      })
      .catch(error => errorHandling(error));
    },

    getTramites: function(){
      if(!this.departamentos.length) this.tramite.departamento_id = null;
      axios.get(laravelRoute('tramites_dependencias.tramites', {
        dependencia_id: this.tramite.dependencia_id,
        departamento_id: this.tramite.departamento_id 
      }))
      .then(response => {
        this.tramites = response.data
      })
      .catch(error => errorHandling(error));
    },

    storeTramite() {
      if (this.$refs.form.validate()) {
        this.vBtnSave.loading = true;
        axios.post("/tramites_dependencias", this.tramite)
        .then(response => {
          this.vSnackBar.text = response.data.message_text;
          this.vSnackBar.visible = true;
          this.tramite = {};
          this.show = false;
        })
        .catch(error => errorHandling(error))
        .finally(() => this.vBtnSave.loading = false);
      }
    }
  },

  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    }
  }
};
</script>