var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-container",
            { attrs: { "grid-list-md": "" } },
            [
              _c(
                "v-data-table",
                {
                  attrs: {
                    "disable-initial-sort": "",
                    items: _vm.payments.data,
                    "hide-actions": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "items",
                      fn: function(props) {
                        return [
                          _c("td", [_vm._v(_vm._s(props.item.created_at))]),
                          _vm._v(" "),
                          _vm.puesto == "Raya"
                            ? _c("td", [
                                _vm._v(
                                  "$ " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        props.item.prima_vacacional
                                      )
                                    )
                                )
                              ])
                            : _c("td", [_vm._v("No aplica")]),
                          _vm._v(" "),
                          _vm.puesto == "Raya"
                            ? _c("td", [
                                _vm._v(
                                  "$ " +
                                    _vm._s(_vm._f("currency")(props.item.monto))
                                )
                              ])
                            : _c("td", [_vm._v("No aplica")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "$ " +
                                _vm._s(_vm._f("currency")(props.item.total))
                            )
                          ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("template", { slot: "headers" }, [
                    _c("tr", [
                      _c("td", [_vm._v("Fecha de pago")]),
                      _vm._v(" "),
                      _c("td", [_vm._v("Prima vacacional")]),
                      _vm._v(" "),
                      _c("td", [_vm._v("Monto")]),
                      _vm._v(" "),
                      _c("td", [_vm._v("Total")])
                    ])
                  ])
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("pagination", {
                    attrs: { limit: _vm.vPaginator.limit, data: _vm.payments },
                    on: { "pagination-change-page": _vm.getPayments }
                  }),
                  _vm._v(" "),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n      " + _vm._s(_vm.vSnackBar.text) + "\n      "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }