var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.localShow, persistent: "", "max-width": "700px" }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Editar trámite")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "", md12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "verdeFayal",
                                      label: "Dependencia",
                                      disabled: ""
                                    },
                                    model: {
                                      value:
                                        _vm.tramite.dependencia_information
                                          .dependencia.nombre_dependencia,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.tramite.dependencia_information
                                            .dependencia,
                                          "nombre_dependencia",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "tramite.dependencia_information.dependencia.nombre_dependencia"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "", md12: "" } },
                                [
                                  _vm.tramite.dependencia_information
                                    .departamento
                                    ? _c("v-text-field", {
                                        attrs: {
                                          color: "verdeFayal",
                                          label: "Departamento",
                                          disabled: ""
                                        },
                                        model: {
                                          value:
                                            _vm.tramite.dependencia_information
                                              .departamento.nombre_departamento,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tramite
                                                .dependencia_information
                                                .departamento,
                                              "nombre_departamento",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "tramite.dependencia_information.departamento.nombre_departamento"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "", md12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "verdeFayal",
                                      label: "Trámites",
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.tramite.nombre_tramite,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.tramite,
                                          "nombre_tramite",
                                          $$v
                                        )
                                      },
                                      expression: "tramite.nombre_tramite"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "", md12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "verdeFayal",
                                      label: "Proyecto al que pertenece",
                                      disabled: ""
                                    },
                                    model: {
                                      value:
                                        _vm.tramite.fraccionamiento
                                          .nombre_proyecto,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.tramite.fraccionamiento,
                                          "nombre_proyecto",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "tramite.fraccionamiento.nombre_proyecto"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm12: "", md12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "verdeFayal",
                                      label: "Descripción",
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.tramite.descripcion,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.tramite,
                                          "descripcion",
                                          $$v
                                        )
                                      },
                                      expression: "tramite.descripcion"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "verdeFayal",
                                      type: "date",
                                      label: "Fecha de resolución",
                                      rules: _vm.rules
                                    },
                                    model: {
                                      value: _vm.tramite.fecha_resolucion,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.tramite,
                                          "fecha_resolucion",
                                          $$v
                                        )
                                      },
                                      expression: "tramite.fecha_resolucion"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "verdeFayal",
                                      label: "Trámites",
                                      disabled: ""
                                    },
                                    model: {
                                      value:
                                        _vm.tramite.estado.information.estado,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.tramite.estado.information,
                                          "estado",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "tramite.estado.information.estado"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.$store.state.saveDisabled,
                        color: "verdeFayal",
                        flat: "",
                        dark: "",
                        outline: ""
                      },
                      on: { click: _vm.tramiteEdit }
                    },
                    [_vm._v("\n          Guardar\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }