var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: { color: "white", flat: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "extension",
                      fn: function() {
                        return [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                centered: "",
                                color: "white",
                                "slider-color": "verdeFayal"
                              },
                              model: {
                                value: _vm.model,
                                callback: function($$v) {
                                  _vm.model = $$v
                                },
                                expression: "model"
                              }
                            },
                            [
                              _c(
                                "v-tab",
                                { attrs: { href: "#tab-datos-generales" } },
                                [_vm._v("Datos generales del prototipo")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab",
                                { attrs: { href: "#tab-conceptos" } },
                                [_vm._v("Conceptos asociados")]
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Nuevo prototipo de vivienda")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.model,
                    callback: function($$v) {
                      _vm.model = $$v
                    },
                    expression: "model"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "tab-datos-generales" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md12: "" } },
                                    [
                                      _c("v-subheader", [
                                        _vm._v("Datos del prototipo")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider"),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md12: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          label: "Nombre"
                                        },
                                        model: {
                                          value: _vm.nombre,
                                          callback: function($$v) {
                                            _vm.nombre = $$v
                                          },
                                          expression: "nombre"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md12: "" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          required: "",
                                          label: "Descripción",
                                          counter: "250",
                                          maxlength: _vm.max
                                        },
                                        model: {
                                          value: _vm.descripcion,
                                          callback: function($$v) {
                                            _vm.descripcion = $$v
                                          },
                                          expression: "descripcion"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("hr"),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md12: "" } },
                                    [
                                      _c("v-subheader", [
                                        _vm._v("Composición del prototipo")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider"),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          min: "1",
                                          max: "4",
                                          required: "",
                                          label: "Plantas"
                                        },
                                        model: {
                                          value: _vm.plantas,
                                          callback: function($$v) {
                                            _vm.plantas = $$v
                                          },
                                          expression: "plantas"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          min: "1",
                                          max: "4",
                                          required: "",
                                          label: "Recámaras"
                                        },
                                        model: {
                                          value: _vm.recamaras,
                                          callback: function($$v) {
                                            _vm.recamaras = $$v
                                          },
                                          expression: "recamaras"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          min: "1",
                                          max: "4",
                                          step: "0.5",
                                          required: "",
                                          label: "Baños"
                                        },
                                        model: {
                                          value: _vm.baños,
                                          callback: function($$v) {
                                            _vm.baños = $$v
                                          },
                                          expression: "baños"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.tiposItems,
                                          required: "",
                                          label: "Tipo"
                                        },
                                        model: {
                                          value: _vm.tipo,
                                          callback: function($$v) {
                                            _vm.tipo = $$v
                                          },
                                          expression: "tipo"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "tab-conceptos" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: { md12: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "append-outer",
                                          fn: function() {
                                            return [
                                              _c(
                                                "v-slide-x-reverse-transition",
                                                { attrs: { mode: "out-in" } },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "success"
                                                      },
                                                      on: {
                                                        click: _vm.addRubro
                                                      }
                                                    },
                                                    [_vm._v("add")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ])
                                    },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          hint: "Buscar lote a trabajar",
                                          "prepend-inner-icon": "search",
                                          "search-input": _vm.query,
                                          items: _vm.searchConceptos.data,
                                          loading: _vm.searchLoading,
                                          "item-text": "nombre",
                                          "persistent-hint": "",
                                          "item-value": "id",
                                          label: "Buscar",
                                          "return-object": "",
                                          "hide-selected": "",
                                          "hide-no-data": ""
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.query = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.query = $event
                                          }
                                        },
                                        nativeOn: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.addConcepto($event)
                                          }
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append-outer",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-slide-x-reverse-transition",
                                                  { attrs: { mode: "out-in" } },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "success",
                                                          disabled: !_vm.selectedOne
                                                        },
                                                        on: {
                                                          click: _vm.addConcepto
                                                        }
                                                      },
                                                      [_vm._v("add")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ]),
                                        model: {
                                          value: _vm.selectedOne,
                                          callback: function($$v) {
                                            _vm.selectedOne = $$v
                                          },
                                          expression: "selectedOne"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md12: "" } },
                                    [
                                      _c("v-data-table", {
                                        staticClass: "elevation-1",
                                        attrs: {
                                          items: _vm.selectedConcepts,
                                          expand: _vm.expand,
                                          "hide-actions": "",
                                          flat: ""
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "headers",
                                            fn: function(props) {
                                              return [
                                                _c("tr", [
                                                  _c("th", [_vm._v("Nombre")]),
                                                  _vm._v(" "),
                                                  _c("th")
                                                ])
                                              ]
                                            }
                                          },
                                          {
                                            key: "items",
                                            fn: function(props) {
                                              return [
                                                _c("tr", [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(props.item.nombre)
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-xs-right"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                            icon:
                                                                              "",
                                                                            color:
                                                                              "red"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.selectedConcepts.splice(
                                                                                props.index,
                                                                                1
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "delete"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "Eliminar rubro"
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                            icon:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              props.expanded = !props.expanded
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "expand_more"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "Expandir rubros"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ])
                                              ]
                                            }
                                          },
                                          {
                                            key: "expand",
                                            fn: function(props) {
                                              return [
                                                _c(
                                                  "v-card",
                                                  { attrs: { flat: "" } },
                                                  [
                                                    _c("v-data-table", {
                                                      attrs: {
                                                        items:
                                                          props.item.rubros,
                                                        "hide-actions": "",
                                                        "item-key": "id",
                                                        "select-all": ""
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "headers",
                                                            fn: function() {
                                                              return [
                                                                _c("tr", [
                                                                  _c("th"),
                                                                  _vm._v(" "),
                                                                  _c("th", [
                                                                    _vm._v(
                                                                      "Lote"
                                                                    )
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("th", [
                                                                    _vm._v(
                                                                      "Acciones"
                                                                    )
                                                                  ])
                                                                ])
                                                              ]
                                                            },
                                                            proxy: true
                                                          },
                                                          {
                                                            key: "items",
                                                            fn: function(
                                                              propsRubros
                                                            ) {
                                                              return [
                                                                _c("tr", [
                                                                  _c("td"),
                                                                  _vm._v(" "),
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        propsRubros
                                                                          .item
                                                                          .nombre
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-xs-right"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-checkbox",
                                                                        {
                                                                          attrs: {
                                                                            value:
                                                                              propsRubros
                                                                                .item
                                                                                .id
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm.selectedRubros,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.selectedRubros = $$v
                                                                            },
                                                                            expression:
                                                                              "selectedRubros"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: { click: _vm.storeHousehold }
                    },
                    [_vm._v(" Guardar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }