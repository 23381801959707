var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v(
                      "Añadir áreas verdes a " + _vm._s(_vm.lotificacion.nombre)
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.addArea($event)
                        }
                      }
                    },
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("span", { staticClass: "grey--text" }, [
                                  _vm._v(
                                    "Añade áreas verdes a la lotificación actual"
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs5: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "verdeFayal",
                                      label: "Nombre de la calle",
                                      readonly: "",
                                      value: _vm.nameArea
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs5: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "verdeFayal",
                                      label: "Superficie",
                                      type: "number",
                                      min: "1",
                                      rules: [
                                        function(v) {
                                          return !!v || "Campo requerido"
                                        }
                                      ]
                                    },
                                    model: {
                                      value: _vm.addingArea.superficie,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addingArea,
                                          "superficie",
                                          $$v
                                        )
                                      },
                                      expression: "addingArea.superficie"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs2: "" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    { attrs: { bottom: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            slot: "activator",
                                            color: "verdeFayal",
                                            fab: "",
                                            dark: "",
                                            small: ""
                                          },
                                          on: { click: _vm.addArea },
                                          slot: "activator"
                                        },
                                        [_c("v-icon", [_vm._v("add")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Añadir calle")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.areas.length
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          "hide-actions": "",
                                          items: _vm.areas
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "headers",
                                              fn: function(props) {
                                                return [
                                                  _c("tr", [
                                                    _c("th", [
                                                      _vm._v("Nombre")
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("th", [
                                                      _vm._v("Superficie (M2)")
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "text-xs-right"
                                                      },
                                                      [_vm._v("Acciones")]
                                                    )
                                                  ])
                                                ]
                                              }
                                            },
                                            {
                                              key: "items",
                                              fn: function(props) {
                                                return [
                                                  _c("tr", [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.item.nombre
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.item.superficie
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "text-xs-right"
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: ""
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  slot:
                                                                    "activator",
                                                                  icon: "",
                                                                  color: "red",
                                                                  flat: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteItem(
                                                                      props.index
                                                                    )
                                                                  }
                                                                },
                                                                slot:
                                                                  "activator"
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "delete"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                "Eliminar calle"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2714266817
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        outline: "",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: { click: _vm.storeAreas }
                    },
                    [_vm._v(" Guardar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }