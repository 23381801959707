var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Administrar cliente del lote")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-flex",
                    { attrs: { md12: "" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: { centered: "", "slider-color": "verdeFayal" },
                          model: {
                            value: _vm.vTabs,
                            callback: function($$v) {
                              _vm.vTabs = $$v
                            },
                            expression: "vTabs"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            { attrs: { href: "#datos-cliente-tab" } },
                            [_vm._v("Datos cliente y vendedor")]
                          ),
                          _vm._v(" "),
                          _vm.ventas
                            ? _c(
                                "v-tab",
                                { attrs: { href: "#datos-compra-tab" } },
                                [_vm._v("Datos de venta")]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.vTabs,
                            callback: function($$v) {
                              _vm.vTabs = $$v
                            },
                            expression: "vTabs"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            { attrs: { value: "datos-cliente-tab" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { "grid-list-md": "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items:
                                                _vm.vAutocompleteCustomer
                                                  .customers,
                                              loading:
                                                _vm.vAutocompleteCustomer
                                                  .isLoading,
                                              "search-input":
                                                _vm.vAutocompleteCustomer
                                                  .search,
                                              label: "Buscar cliente",
                                              "item-text": "nombre_completo",
                                              "item-value": "id",
                                              "browser-autocomplete": "off",
                                              hint:
                                                "Ingresa por lo menos 3 caracteres",
                                              "return-object": ""
                                            },
                                            on: {
                                              "update:searchInput": function(
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.vAutocompleteCustomer,
                                                  "search",
                                                  $event
                                                )
                                              },
                                              "update:search-input": function(
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.vAutocompleteCustomer,
                                                  "search",
                                                  $event
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.vAutocompleteCustomer
                                                  .select,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.vAutocompleteCustomer,
                                                  "select",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "vAutocompleteCustomer.select"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.vAutocompleteCustomer.select
                                        .nombre_completo
                                        ? _c(
                                            "v-layout",
                                            { attrs: { wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { md6: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      required: "",
                                                      label: "Nombre",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .vAutocompleteCustomer
                                                          .select
                                                          .nombre_completo,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .vAutocompleteCustomer
                                                            .select,
                                                          "nombre_completo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "vAutocompleteCustomer.select.nombre_completo"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                { attrs: { md6: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      required: "",
                                                      label: "Teléfono",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .vAutocompleteCustomer
                                                          .select.telefono,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .vAutocompleteCustomer
                                                            .select,
                                                          "telefono",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "vAutocompleteCustomer.select.telefono"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                { attrs: { md6: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      required: "",
                                                      label:
                                                        "Correo electrónico",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .vAutocompleteCustomer
                                                          .select.email,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .vAutocompleteCustomer
                                                            .select,
                                                          "email",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "vAutocompleteCustomer.select.email"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                { attrs: { md6: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      required: "",
                                                      label: "Método de pago",
                                                      readonly: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .vAutocompleteCustomer
                                                          .select.credito,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm
                                                            .vAutocompleteCustomer
                                                            .select,
                                                          "credito",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "vAutocompleteCustomer.select.credito"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { md6: "" } },
                                            [
                                              _c("v-switch", {
                                                attrs: {
                                                  label: "¿Añadir vendedor?"
                                                },
                                                model: {
                                                  value: _vm.isThereAnSalesman,
                                                  callback: function($$v) {
                                                    _vm.isThereAnSalesman = $$v
                                                  },
                                                  expression:
                                                    "isThereAnSalesman"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.isThereAnSalesman
                                        ? _c(
                                            "v-layout",
                                            { attrs: { wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { md12: "" } },
                                                [
                                                  _c("v-autocomplete", {
                                                    attrs: {
                                                      items:
                                                        _vm
                                                          .vAutocompleteSalesman
                                                          .salesman,
                                                      loading:
                                                        _vm
                                                          .vAutocompleteSalesman
                                                          .isLoading,
                                                      "search-input":
                                                        _vm
                                                          .vAutocompleteSalesman
                                                          .search,
                                                      label: "Buscar vendedor",
                                                      "item-text":
                                                        "nombre_completo",
                                                      "item-value": "id",
                                                      "browser-autocomplete":
                                                        "off",
                                                      hint:
                                                        "Ingresa por lo menos 3 caracteres",
                                                      "return-object": ""
                                                    },
                                                    on: {
                                                      "update:searchInput": function(
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.vAutocompleteSalesman,
                                                          "search",
                                                          $event
                                                        )
                                                      },
                                                      "update:search-input": function(
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          _vm.vAutocompleteSalesman,
                                                          "search",
                                                          $event
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .vAutocompleteSalesman
                                                          .select,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.vAutocompleteSalesman,
                                                          "select",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "vAutocompleteSalesman.select"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.vAutocompleteSalesman.select
                                                ? [
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { md6: "" } },
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            required: "",
                                                            label: "Nombre",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .vAutocompleteSalesman
                                                                .select
                                                                .nombre_completo,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .vAutocompleteSalesman
                                                                  .select,
                                                                "nombre_completo",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "vAutocompleteSalesman.select.nombre_completo"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { md6: "" } },
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            required: "",
                                                            label: "Teléfono",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .vAutocompleteSalesman
                                                                .select
                                                                .telefono,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .vAutocompleteSalesman
                                                                  .select,
                                                                "telefono",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "vAutocompleteSalesman.select.telefono"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-flex",
                                                      { attrs: { md6: "" } },
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            required: "",
                                                            label:
                                                              "Correo electrónico",
                                                            readonly: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm
                                                                .vAutocompleteSalesman
                                                                .select.email,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm
                                                                  .vAutocompleteSalesman
                                                                  .select,
                                                                "email",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "vAutocompleteSalesman.select.email"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.ventas
                            ? _c(
                                "v-tab-item",
                                { attrs: { value: "datos-compra-tab" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-container",
                                        { attrs: { "grid-list-md": "" } },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { md6: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      type: "number",
                                                      min: "0",
                                                      label: "Valor del avaluo"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ventas
                                                          .valor_de_avaluo,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.ventas,
                                                          "valor_de_avaluo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ventas.valor_de_avaluo"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                { attrs: { md6: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      type: "number",
                                                      min: "0",
                                                      label: "Valor de saldo"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ventas
                                                          .valor_de_saldo,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.ventas,
                                                          "valor_de_saldo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ventas.valor_de_saldo"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                { attrs: { md6: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      type: "number",
                                                      min: "0",
                                                      label: "Anticipo"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ventas.anticipo,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.ventas,
                                                          "anticipo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ventas.anticipo"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-layout",
                                            { attrs: { wrap: "" } },
                                            [
                                              _vm.diferencia > 0
                                                ? _c(
                                                    "v-flex",
                                                    { attrs: { md6: "" } },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label: "Diferencia",
                                                          readonly: ""
                                                        },
                                                        model: {
                                                          value: _vm.diferencia,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.diferencia = $$v
                                                          },
                                                          expression:
                                                            "diferencia"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.monto_a_devolver > 0
                                                ? _c(
                                                    "v-flex",
                                                    { attrs: { md6: "" } },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            "Monto a devolver",
                                                          readonly: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.monto_a_devolver,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.monto_a_devolver = $$v
                                                          },
                                                          expression:
                                                            "monto_a_devolver"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-layout",
                                            { attrs: { wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { md6: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      type: "date",
                                                      label:
                                                        "Fecha màxima a pagar"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ventas
                                                          .fecha_maxima_a_pagar,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.ventas,
                                                          "fecha_maxima_a_pagar",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ventas.fecha_maxima_a_pagar"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                { attrs: { md6: "" } },
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      disabled: !_vm.esEntregable,
                                                      label: "Pagado"
                                                    },
                                                    model: {
                                                      value: _vm.ventas.pagado,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.ventas,
                                                          "pagado",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ventas.pagado"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.esEntregable
                                            ? _c(
                                                "v-layout",
                                                { attrs: { wrap: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { md4: "" } },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          type: "date",
                                                          label:
                                                            "Fecha de firma"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ventas
                                                              .fecha_de_firma,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ventas,
                                                              "fecha_de_firma",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ventas.fecha_de_firma"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { md4: "" } },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          type: "date",
                                                          label:
                                                            "Fecha de entrega"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ventas
                                                              .fecha_de_entrega,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ventas,
                                                              "fecha_de_entrega",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ventas.fecha_de_entrega"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { md4: "" } },
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          label:
                                                            "Cliente conforme"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ventas
                                                              .cliente_conforme,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ventas,
                                                              "cliente_conforme",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ventas.cliente_conforme"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-layout",
                                            { attrs: { wrap: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { md6: "" } },
                                                [
                                                  _vm.ventas.liberar_lote ==
                                                  true
                                                    ? _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            dark: "",
                                                            color: "warning",
                                                            loading:
                                                              _vm.vBtnSave
                                                                .loading
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.realeseLot(
                                                                _vm.ventas.id
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v("Liberar lote")]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: { click: _vm.clickingButton }
                    },
                    [_vm._v(" Guardar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }