var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        "v-card",
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("div", {}, [
              _c(
                "a",
                { attrs: { href: "../proyectos" } },
                [
                  _c(
                    "v-btn",
                    { staticClass: "white--text", attrs: { color: "#1ABA24" } },
                    [_vm._v("Ir a propuestas de lotificacion")]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [
              _vm._v("Ver Propuesta de lotificación")
            ])
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { attrs: { "grid-list-md": "" } },
                _vm._l(this.proyecto, function(project, index) {
                  return _c(
                    "v-layout",
                    { key: index, attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Nombre del Proyecto",
                              disabled: ""
                            },
                            model: {
                              value: project.prospecto.nombre_proyecto,
                              callback: function($$v) {
                                _vm.$set(
                                  project.prospecto,
                                  "nombre_proyecto",
                                  $$v
                                )
                              },
                              expression: "project.prospecto.nombre_proyecto"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Descripción",
                              disabled: ""
                            },
                            model: {
                              value: project.descripcion,
                              callback: function($$v) {
                                _vm.$set(project, "descripcion", $$v)
                              },
                              expression: "project.descripcion"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Fecha",
                              type: "date",
                              disabled: ""
                            },
                            model: {
                              value: project.fecha,
                              callback: function($$v) {
                                _vm.$set(project, "fecha", $$v)
                              },
                              expression: "project.fecha"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "", md12: "" } },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: project.planos, target: "_blank" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  color: "#1ABA24",
                                  label: "Ver planos",
                                  disabled: ""
                                },
                                model: {
                                  value: project.planos,
                                  callback: function($$v) {
                                    _vm.$set(project, "planos", $$v)
                                  },
                                  expression: "project.planos"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }