<template>
  <v-card>
    <v-card-title>
      <v-flex>
        <h2>Viviendas</h2>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex>
        <v-text-field
          color="verdeFayal"
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-flex>
    </v-card-title>
    <v-data-table no-no-data-text="No se encontraron fraccionamientos" no-results-text="No se encontraron fraccionamientos" :headers="headers" :items="viviendasProps" :search="search" :pagination.sync="pagination">
      <template v-slot:items="viviendasProps">
        <td class="text-xs-left">{{ viviendasProps.item.lote.manzana.lotificacion.prospecto.nombre_proyecto }}</td>
        <td class="text-xs-left">{{ viviendasProps.item.lote.nombre }}</td>
        <td class="text-xs-left">{{ viviendasProps.item.lote.modelo }}</td>
        <td class="text-xs-left fyl-fraccionamientos-td">{{ viviendasProps.item.venta.cliente.nombre_completo }}</td>
        <td class="text-xs-left fyl-fraccionamientos-td">{{ viviendasProps.item.venta.fecha_de_entrega || 'No disponible' }}</td>
        <td class="text-xs-left">
          <v-tooltip bottom>
            <v-btn flat icon slot="activator" color="verdeFayal" @click="dialog = true; vivienda = viviendasProps.item" >
              <v-icon>create_new_folder</v-icon>
            </v-btn>
            <span>Editar Garantias</span>
          </v-tooltip>
          <v-tooltip bottom>
            <v-btn flat icon slot="activator" color="red" @click="dialogAdministrar = true; vivienda = viviendasProps.item" >
              <v-icon>emoji_people</v-icon>
            </v-btn>
            <span>Aplicar Garantias</span>
          </v-tooltip>
        </td>
      </template>
    </v-data-table>
    <viviendas-garantias-modal 
      :dialog="dialog"
      :vivienda="vivienda"
      @close="dialog = false" 
    />
    <viviendas-garantias-modal-estados 
      :dialog="dialogAdministrar"
      :vivienda="vivienda"
      @close="dialogAdministrar = false" 
    />
  </v-card>
</template>

<script>
  export default {
    props: ['viviendas', 'permissions'],
    data(){
      return{
        search: '',
        headers:[
          { 
            sortable: false,
            text: 'Fraccionamiento', 
          },
          { 
            sortable: false,
            text: 'Lote', 
          },
          { 
            sortable: false,
            text: 'Modelo', 
          },
          { 
            sortable: false,
            text: 'Propietario', 
          },
          { 
            sortable: false,
            text: 'Fecha de entrega', 
          },
          { 
            sortable: false,
            text: 'Acciones', 
          }
        ],
        pagination: {
          sortBy: 'created_at',
          descending: true
        },
        dialog: false,
        dialogAdministrar: false,
        vivienda: {},
      }
    },
    methods:{
    },
    created(){
        this.viviendasProps = this.viviendas;
    }
  }
</script>

<style>
  .comprado{
    position: relative;
    top: 5px;
  }
  .fyl-fraccionamientos-td{
    max-width: 166px;
  }
</style>