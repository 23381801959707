var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-toolbar",
            {
              attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" }
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                icon: "",
                                onclick: "history.back()"
                              }
                            },
                            [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_vm._v(" "), _c("span", [_vm._v("Regresar")])]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", "pb-2": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs10: "", "offset-xs1": "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card--flex-toolbar" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { card: "", prominent: "" } },
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-toolbar-title",
                            { staticClass: "body-2 grey--text" },
                            [
                              _vm._v(
                                "Lote en promoción " +
                                  _vm._s(_vm.current_lote.nombre)
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-spacer")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-container",
                                {
                                  attrs: {
                                    "grid-list-md": "",
                                    "text-xs-left": ""
                                  }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", md3: "" } },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              attrs: {
                                                dark: "",
                                                color: "#75d57b"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs4: "" } },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                "align-center":
                                                                  "",
                                                                "justify-center":
                                                                  "",
                                                                row: "",
                                                                "fill-height":
                                                                  ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                attrs: {
                                                                  src:
                                                                    "/fonts/local/total.svg",
                                                                  height:
                                                                    "50px",
                                                                  contain: ""
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs8: "" } },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              attrs: {
                                                                "primary-title":
                                                                  ""
                                                              }
                                                            },
                                                            [
                                                              _c("div", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "headline total-title"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "$ " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "currency"
                                                                          )(
                                                                            _vm
                                                                              .ventas
                                                                              .valor_de_saldo
                                                                          )
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "Valor Comercial"
                                                                  )
                                                                ])
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", md3: "" } },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              attrs: {
                                                dark: "",
                                                color: "#75d57b"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs12: "" } },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs4: "" } },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                "align-center":
                                                                  "",
                                                                "justify-center":
                                                                  "",
                                                                row: "",
                                                                "fill-height":
                                                                  ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                attrs: {
                                                                  src:
                                                                    "/fonts/local/house-price.png",
                                                                  height:
                                                                    "50px",
                                                                  contain: ""
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs8: "" } },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              attrs: {
                                                                "primary-title":
                                                                  ""
                                                              }
                                                            },
                                                            [
                                                              _c("div", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "headline total-title"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "$ " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "currency"
                                                                          )(
                                                                            _vm
                                                                              .ventas
                                                                              .valor_de_avaluo
                                                                          )
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "Valor del avalúo"
                                                                  )
                                                                ])
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", md3: "" } },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              attrs: {
                                                dark: "",
                                                color: "#75d57b"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                [
                                                  _c(
                                                    "v-layout",
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs4: "" } },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                "align-center":
                                                                  "",
                                                                "justify-center":
                                                                  "",
                                                                row: "",
                                                                "fill-height":
                                                                  ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                attrs: {
                                                                  src:
                                                                    "/fonts/local/payment.png",
                                                                  height:
                                                                    "50px",
                                                                  contain: ""
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs8: "" } },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              attrs: {
                                                                "primary-title":
                                                                  ""
                                                              }
                                                            },
                                                            [
                                                              _c("div", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "headline total-title"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "$ " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "currency"
                                                                          )(
                                                                            _vm
                                                                              .ventas
                                                                              .pagos_asociados
                                                                          )
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "Pagos asociados"
                                                                  )
                                                                ])
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", md3: "" } },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              attrs: {
                                                dark: "",
                                                color: "#75d57b"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-flex",
                                                [
                                                  _c(
                                                    "v-layout",
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs4: "" } },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                "align-center":
                                                                  "",
                                                                "justify-center":
                                                                  "",
                                                                row: "",
                                                                "fill-height":
                                                                  ""
                                                              }
                                                            },
                                                            [
                                                              _c("v-img", {
                                                                attrs: {
                                                                  src:
                                                                    "/fonts/local/balance.png",
                                                                  height:
                                                                    "50px",
                                                                  contain: ""
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs8: "" } },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              attrs: {
                                                                "primary-title":
                                                                  ""
                                                              }
                                                            },
                                                            [
                                                              _c("div", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "headline total-title"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "$ " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "currency"
                                                                          )(
                                                                            _vm
                                                                              .ventas
                                                                              .saldo_a_pagar
                                                                          )
                                                                        )
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    "Saldo"
                                                                  )
                                                                ])
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider"),
                                  _vm._v(" "),
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        [
                                          _c(
                                            "v-tabs",
                                            {
                                              attrs: {
                                                "fixed-tabs": "",
                                                centered: "",
                                                color: "white",
                                                "slider-color": "#1aba24"
                                              },
                                              model: {
                                                value: _vm.vTabs,
                                                callback: function($$v) {
                                                  _vm.vTabs = $$v
                                                },
                                                expression: "vTabs"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-tab",
                                                {
                                                  attrs: {
                                                    href: "#tab-alta_pagos"
                                                  }
                                                },
                                                [_vm._v("Venta")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-tab",
                                                {
                                                  attrs: {
                                                    href: "#tab-clientes"
                                                  }
                                                },
                                                [_vm._v("Clientes")]
                                              ),
                                              _vm._v(" "),
                                              _vm.ventas.valor_de_saldo
                                                ? _c(
                                                    "v-tab",
                                                    {
                                                      attrs: {
                                                        href: "#tab-pagos"
                                                      }
                                                    },
                                                    [_vm._v("Pagos")]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-tabs-items",
                                            {
                                              model: {
                                                value: _vm.vTabs,
                                                callback: function($$v) {
                                                  _vm.vTabs = $$v
                                                },
                                                expression: "vTabs"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-tab-item",
                                                {
                                                  attrs: {
                                                    value: "tab-alta_pagos"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    { attrs: { flat: "" } },
                                                    [
                                                      _c(
                                                        "v-container",
                                                        [
                                                          _c(
                                                            "v-card-actions",
                                                            [
                                                              _c("v-spacer"),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    disabled: !_vm
                                                                      .ventas
                                                                      .valor_de_saldo,
                                                                    color:
                                                                      "verdeFayal",
                                                                    dark: ""
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.openAddAvaluo
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Añadir valor de avalúo"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    xs12: "",
                                                                    md6: ""
                                                                  }
                                                                },
                                                                [
                                                                  !_vm.ventas
                                                                    .valor_de_saldo
                                                                    ? _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            label:
                                                                              "Valor comercial",
                                                                            "item-value":
                                                                              "precio",
                                                                            items:
                                                                              _vm.precios,
                                                                            "item-text":
                                                                              "tipo",
                                                                            required:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "selection",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var item =
                                                                                    ref.item
                                                                                  return [
                                                                                    _vm._v(
                                                                                      "\n                                                                        $" +
                                                                                        _vm._s(
                                                                                          _vm._f(
                                                                                            "currency"
                                                                                          )(
                                                                                            item.precio
                                                                                          )
                                                                                        ) +
                                                                                        " (" +
                                                                                        _vm._s(
                                                                                          item.tipo
                                                                                        ) +
                                                                                        ")\n                                                                    "
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              },
                                                                              {
                                                                                key:
                                                                                  "item",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var item =
                                                                                    ref.item
                                                                                  return [
                                                                                    _vm._v(
                                                                                      "\n                                                                        $" +
                                                                                        _vm._s(
                                                                                          _vm._f(
                                                                                            "currency"
                                                                                          )(
                                                                                            item.precio
                                                                                          )
                                                                                        ) +
                                                                                        " (" +
                                                                                        _vm._s(
                                                                                          item.tipo
                                                                                        ) +
                                                                                        ")\n                                                                    "
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            909935100
                                                                          ),
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .ventas
                                                                                .costo,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.ventas,
                                                                                "costo",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "ventas.costo"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "input-currency-mask",
                                                                        {
                                                                          attrs: {
                                                                            readonly:
                                                                              "",
                                                                            disabled:
                                                                              "",
                                                                            label:
                                                                              "Valor comercial"
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .ventas
                                                                                .costo,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.ventas,
                                                                                "costo",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "ventas.costo"
                                                                          }
                                                                        }
                                                                      )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    md6: "",
                                                                    xs12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        disabled:
                                                                          _vm
                                                                            .ventas
                                                                            .valor_de_saldo >
                                                                          0,
                                                                        type:
                                                                          "date",
                                                                        label:
                                                                          "Fecha máxima a pagar"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .ventas
                                                                            .fecha_maxima_a_pagar,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.ventas,
                                                                            "fecha_maxima_a_pagar",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "ventas.fecha_maxima_a_pagar"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          !_vm.ventas
                                                            .valor_de_saldo
                                                            ? _c(
                                                                "v-layout",
                                                                {
                                                                  attrs: {
                                                                    row: "",
                                                                    wrap: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        md6: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "searcher",
                                                                        {
                                                                          attrs: {
                                                                            configuration:
                                                                              _vm.searchMethodPayment,
                                                                            itemSelected:
                                                                              _vm
                                                                                .ventas
                                                                                .metodo_de_pago
                                                                          },
                                                                          on: {
                                                                            "update:itemSelected": function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                _vm.ventas,
                                                                                "metodo_de_pago",
                                                                                $event
                                                                              )
                                                                            },
                                                                            "update:item-selected": function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.$set(
                                                                                _vm.ventas,
                                                                                "metodo_de_pago",
                                                                                $event
                                                                              )
                                                                            }
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        md6: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-switch",
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "verdeFayal",
                                                                            hint:
                                                                              "Tipo de venta",
                                                                            "persistent-hint": true,
                                                                            label: _vm
                                                                              .lote
                                                                              .tipo_venta
                                                                              ? "Externo"
                                                                              : "Fayal"
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .lote
                                                                                .tipo_venta,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.lote,
                                                                                "tipo_venta",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "lote.tipo_venta"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm.lote
                                                                    .tipo_venta ==
                                                                  true
                                                                    ? _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs: {
                                                                            m12:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-layout",
                                                                            {
                                                                              attrs: {
                                                                                wrap:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "body-2 black--text"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Seleccionar Vendedor"
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-divider",
                                                                                {
                                                                                  attrs: {
                                                                                    inset:
                                                                                      ""
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    md12:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-autocomplete",
                                                                                    {
                                                                                      attrs: {
                                                                                        items:
                                                                                          _vm
                                                                                            .vAutocompleteSalesman
                                                                                            .salesman,
                                                                                        loading:
                                                                                          _vm
                                                                                            .vAutocompleteSalesman
                                                                                            .isLoading,
                                                                                        "search-input":
                                                                                          _vm
                                                                                            .vAutocompleteSalesman
                                                                                            .search,
                                                                                        label:
                                                                                          "Buscar vendedor",
                                                                                        "item-text":
                                                                                          "nombre_completo",
                                                                                        "item-value":
                                                                                          "id",
                                                                                        "browser-autocomplete":
                                                                                          "off",
                                                                                        hint:
                                                                                          "Ingresa por lo menos 3 caracteres",
                                                                                        "return-object":
                                                                                          ""
                                                                                      },
                                                                                      on: {
                                                                                        "update:searchInput": function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm.vAutocompleteSalesman,
                                                                                            "search",
                                                                                            $event
                                                                                          )
                                                                                        },
                                                                                        "update:search-input": function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.$set(
                                                                                            _vm.vAutocompleteSalesman,
                                                                                            "search",
                                                                                            $event
                                                                                          )
                                                                                        }
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .vAutocompleteSalesman
                                                                                            .select,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.vAutocompleteSalesman,
                                                                                            "select",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "vAutocompleteSalesman.select"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _vm
                                                                                .vAutocompleteSalesman
                                                                                .select
                                                                                ? [
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        attrs: {
                                                                                          md6:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-text-field",
                                                                                          {
                                                                                            attrs: {
                                                                                              required:
                                                                                                "",
                                                                                              label:
                                                                                                "Nombre",
                                                                                              readonly:
                                                                                                ""
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                _vm
                                                                                                  .vAutocompleteSalesman
                                                                                                  .select
                                                                                                  .nombre_completo,
                                                                                              callback: function(
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm
                                                                                                    .vAutocompleteSalesman
                                                                                                    .select,
                                                                                                  "nombre_completo",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                              expression:
                                                                                                "vAutocompleteSalesman.select.nombre_completo"
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        attrs: {
                                                                                          md6:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-text-field",
                                                                                          {
                                                                                            attrs: {
                                                                                              required:
                                                                                                "",
                                                                                              label:
                                                                                                "Teléfono",
                                                                                              readonly:
                                                                                                ""
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                _vm
                                                                                                  .vAutocompleteSalesman
                                                                                                  .select
                                                                                                  .telefono,
                                                                                              callback: function(
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm
                                                                                                    .vAutocompleteSalesman
                                                                                                    .select,
                                                                                                  "telefono",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                              expression:
                                                                                                "vAutocompleteSalesman.select.telefono"
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        attrs: {
                                                                                          md6:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-text-field",
                                                                                          {
                                                                                            attrs: {
                                                                                              required:
                                                                                                "",
                                                                                              label:
                                                                                                "Correo electrónico",
                                                                                              readonly:
                                                                                                ""
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                _vm
                                                                                                  .vAutocompleteSalesman
                                                                                                  .select
                                                                                                  .email,
                                                                                              callback: function(
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm
                                                                                                    .vAutocompleteSalesman
                                                                                                    .select,
                                                                                                  "email",
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                              expression:
                                                                                                "vAutocompleteSalesman.select.email"
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _vm
                                                                                      .vAutocompleteSalesman
                                                                                      .select
                                                                                      .inmobiliaria
                                                                                      ? _c(
                                                                                          "v-flex",
                                                                                          {
                                                                                            attrs: {
                                                                                              md6:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-text-field",
                                                                                              {
                                                                                                attrs: {
                                                                                                  required:
                                                                                                    "",
                                                                                                  label:
                                                                                                    "Inmobiliaria",
                                                                                                  readonly:
                                                                                                    ""
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    _vm
                                                                                                      .vAutocompleteSalesman
                                                                                                      .select
                                                                                                      .inmobiliaria
                                                                                                      .inmobiliaria,
                                                                                                  callback: function(
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      _vm
                                                                                                        .vAutocompleteSalesman
                                                                                                        .select
                                                                                                        .inmobiliaria,
                                                                                                      "inmobiliaria",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "vAutocompleteSalesman.select.inmobiliaria.inmobiliaria"
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ]
                                                                                : _vm._e()
                                                                            ],
                                                                            2
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                "v-layout",
                                                                [
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            disabled:
                                                                              "",
                                                                            label:
                                                                              "Método de compra"
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .ventas
                                                                                .metodo_de_pago
                                                                                .nombre,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .ventas
                                                                                  .metodo_de_pago,
                                                                                "nombre",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "ventas.metodo_de_pago.nombre"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        xs6: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            disabled:
                                                                              "",
                                                                            label:
                                                                              "Tipo de venta",
                                                                            value: _vm
                                                                              .ventas
                                                                              .vendedor_id
                                                                              ? "Externa"
                                                                              : "Fayal"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm.ventas
                                                                    .vendedor_id
                                                                    ? _c(
                                                                        "v-flex",
                                                                        {
                                                                          attrs: {
                                                                            xs6:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-text-field",
                                                                            {
                                                                              attrs: {
                                                                                disabled:
                                                                                  "",
                                                                                label:
                                                                                  "Nombre completo del vendedor",
                                                                                value:
                                                                                  _vm
                                                                                    .ventas
                                                                                    .vendedor
                                                                                    .nombre_completo
                                                                              }
                                                                            }
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("v-divider"),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c("v-spacer"),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                outline: "",
                                                                dark: "",
                                                                color:
                                                                  "verdeFayal",
                                                                flat: "",
                                                                loading:
                                                                  _vm.vBtnSave
                                                                    .loading
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.updateSale()
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " \n                                                            Guardar \n                                                        "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-tab-item",
                                                {
                                                  attrs: {
                                                    value: "tab-clientes"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    { attrs: { flat: "" } },
                                                    [
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c("v-spacer"),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "verdeFayal",
                                                                dark: ""
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.addCustomerToSale
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Añadir cliente"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-container",
                                                        [
                                                          _c("v-data-table", {
                                                            attrs: {
                                                              items:
                                                                _vm
                                                                  .vAutocompleteCustomer
                                                                  .customers,
                                                              headers:
                                                                _vm.headers_pagos,
                                                              "disable-initial-sort":
                                                                "",
                                                              "hide-actions": ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "items",
                                                                  fn: function(
                                                                    props
                                                                  ) {
                                                                    return [
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            props
                                                                              .item
                                                                              .cliente
                                                                              .nombre_completo
                                                                          )
                                                                        )
                                                                      ]),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.paymentDate(
                                                                              props
                                                                                .item
                                                                                .cliente
                                                                                .fecha_aprobacion
                                                                            )
                                                                          )
                                                                        )
                                                                      ]),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            props
                                                                              .item
                                                                              .cliente
                                                                              .telefono
                                                                          )
                                                                        )
                                                                      ]),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            props
                                                                              .item
                                                                              .cliente
                                                                              .email
                                                                          )
                                                                        )
                                                                      ]),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "td",
                                                                        [
                                                                          _c(
                                                                            "v-layout",
                                                                            [
                                                                              _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs: {
                                                                                    bottom:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        slot:
                                                                                          "activator",
                                                                                        flat:
                                                                                          "",
                                                                                        icon:
                                                                                          "",
                                                                                        small:
                                                                                          "",
                                                                                        color:
                                                                                          "warning"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.openFilesModal(
                                                                                            props
                                                                                              .item
                                                                                              .id
                                                                                          )
                                                                                        }
                                                                                      },
                                                                                      slot:
                                                                                        "activator"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "folder"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Archivos"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ]
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm.ventas
                                                ? _c(
                                                    "v-tab-item",
                                                    {
                                                      attrs: {
                                                        value: "tab-pagos"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        { attrs: { flat: "" } },
                                                        [
                                                          _c(
                                                            "v-container",
                                                            [
                                                              _c(
                                                                "v-card-actions",
                                                                [
                                                                  _c(
                                                                    "v-spacer"
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        flat:
                                                                          "",
                                                                        color:
                                                                          "verdeFayal",
                                                                        outline:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.openRefundPagos
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Generar devolución"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "verdeFayal",
                                                                        dark: ""
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          _vm.openAddPagos
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Añadir pagos"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-data-table",
                                                                {
                                                                  attrs: {
                                                                    items:
                                                                      _vm.pagos
                                                                        .data,
                                                                    "disable-initial-sort":
                                                                      "",
                                                                    "hide-actions":
                                                                      ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "headers",
                                                                        fn: function(
                                                                          props
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "tr",
                                                                              [
                                                                                _c(
                                                                                  "th",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Concepto"
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "th",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Descripción"
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "th",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Monto"
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "th",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Fecha de pago"
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "th"
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "th"
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      },
                                                                      {
                                                                        key:
                                                                          "items",
                                                                        fn: function(
                                                                          props
                                                                        ) {
                                                                          return [
                                                                            _c(
                                                                              "tr",
                                                                              [
                                                                                _c(
                                                                                  "td",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        props
                                                                                          .item
                                                                                          .concepto
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  {
                                                                                    staticClass:
                                                                                      "td-ellipsis"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        props
                                                                                          .item
                                                                                          .descripcion
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "$ " +
                                                                                        _vm._s(
                                                                                          _vm._f(
                                                                                            "currency"
                                                                                          )(
                                                                                            props
                                                                                              .item
                                                                                              .monto
                                                                                          )
                                                                                        )
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.paymentDate(
                                                                                          props
                                                                                            .item
                                                                                            .fecha_de_pago
                                                                                        )
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  [
                                                                                    props
                                                                                      .item
                                                                                      .monto <
                                                                                    0
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "badge badge-primary badge-lotes"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Devolución"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  [
                                                                                    _c(
                                                                                      "v-tooltip",
                                                                                      {
                                                                                        attrs: {
                                                                                          bottom:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            attrs: {
                                                                                              slot:
                                                                                                "activator",
                                                                                              icon:
                                                                                                "",
                                                                                              flat:
                                                                                                "",
                                                                                              dark:
                                                                                                "",
                                                                                              small:
                                                                                                "",
                                                                                              color:
                                                                                                "verdeFayal"
                                                                                            },
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.editPayment(
                                                                                                  props
                                                                                                    .item
                                                                                                    .id
                                                                                                )
                                                                                              }
                                                                                            },
                                                                                            slot:
                                                                                              "activator"
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "edit"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Editar"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "v-tooltip",
                                                                                      {
                                                                                        attrs: {
                                                                                          bottom:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            attrs: {
                                                                                              slot:
                                                                                                "activator",
                                                                                              icon:
                                                                                                "",
                                                                                              flat:
                                                                                                "",
                                                                                              dark:
                                                                                                "",
                                                                                              small:
                                                                                                "",
                                                                                              color:
                                                                                                "error"
                                                                                            },
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.deletePayment(
                                                                                                  props
                                                                                                    .item
                                                                                                    .id
                                                                                                )
                                                                                              }
                                                                                            },
                                                                                            slot:
                                                                                              "activator"
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "delete"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Eliminar"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    1479446227
                                                                  )
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-card-actions",
                                                                [
                                                                  _c(
                                                                    "v-spacer"
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "pagination",
                                                                    {
                                                                      attrs: {
                                                                        limit: 10,
                                                                        data:
                                                                          _vm.pagos
                                                                      },
                                                                      on: {
                                                                        "pagination-change-page":
                                                                          _vm.getPagos
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("add-customers-to-lote-promocion-modal", {
        attrs: { show: _vm.showCustomersModal, "lote-id": _vm.currentLoteId },
        on: {
          "update:show": function($event) {
            _vm.showCustomersModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("add-pagos-to-lote-modal", {
        attrs: { show: _vm.showAddPagos, "venta-id": _vm.ventas.id },
        on: {
          "update:show": function($event) {
            _vm.showAddPagos = $event
          }
        }
      }),
      _vm._v(" "),
      _c("edit-pago-to-lote-modal", {
        attrs: {
          show: _vm.showEditPaymentModal,
          "pago-id": _vm.currentPaymentId
        },
        on: {
          "update:show": function($event) {
            _vm.showEditPaymentModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("refund-pagos-to-lote-modal", {
        attrs: { show: _vm.showRefundPaymentModal, "venta-id": _vm.ventas.id },
        on: {
          "update:show": function($event) {
            _vm.showRefundPaymentModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("add-avaluo-to-venta-modal", {
        attrs: { show: _vm.showAddAvaluoModal, "venta-id": _vm.ventas.id },
        on: {
          "update:show": function($event) {
            _vm.showAddAvaluoModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("add-customers-to-sale-modal", {
        attrs: {
          show: _vm.showAddCustomerToSaleModal,
          "venta-id": _vm.ventas.id
        },
        on: {
          "update:show": function($event) {
            _vm.showAddCustomerToSaleModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("archivos-lotes-modal", {
        attrs: {
          element_type: "App\\LoteManzanaLotificacion",
          element_id: _vm.elementId,
          dialog: _vm.dialogFile,
          lotes_files: _vm.lotesFiles ? _vm.lotesFiles : []
        },
        on: {
          close: function($event) {
            _vm.dialogFile = false
          }
        }
      }),
      _vm._v(" "),
      _c("client-files-modal", {
        attrs: {
          element_type: "App\\Cliente",
          element_id: _vm.currentClienteId,
          permissions: [],
          show: _vm.dialogFile,
          personal_files: []
        },
        on: {
          "update:show": function($event) {
            _vm.dialogFile = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.vSnackBar.text) + "\n        "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }