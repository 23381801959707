<template>
  <v-layout>
    <v-dialog :value="dialog" persistent max-width="700px" hide-overlay>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Crear nueva etapa para {{dad}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            flat 
            icon 
            color="verdeFayal" 
            dark
            @click="$emit('close');"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-progress-linear
          :active="loadingToolbar"
          :indeterminate="loadingToolbar"
          striped
          absolute
          top
          color="verdeFayal"
        ></v-progress-linear>
        <v-card-text>
          <v-text-field
            v-model="etapaNombre"
            label="Nombre de la etapa"
          />

          <h4>Manzanas</h4>
          <v-layout wrap>
            <v-autocomplete
              v-model="manzanas.selected"
              browser-autocomplete="off"
              hide-selected
              return-object
              no-data-text="No hay manzanas disponibles"
              placeholder="Seleccione las manzanas a incluir en la etapa"
              :chips="true"
              :multiple="true"
              :deletable-chips="true"
              item-text="nombre"
              item-value="id"
              :items="manzanas.items"
            >
            </v-autocomplete>
          </v-layout>

          <v-data-table
              :hide-actions="true"
              :disable-initial-sort="true"
              :headers="dad == 'Obra' ? manzanas.headers : manzanas.headersUrbanizacion"
              :items="manzanas.selected"
              class="elevation-1"
            > 
              <template slot="items" slot-scope="props">
                <td>Manzana: {{ props.item.nombre }}</td>
                <td v-if="dad == 'Obra'">
                  <v-chip v-for="lote in props.item.lotes" :key="lote.id" class="ma-2" color="verdeFayal" @click="addLote(props.item.id, lote)" outline>
                    {{ lote.nombre }}
                  </v-chip> 
                </td>
                <td v-if="dad == 'Obra'">
                  <v-chip v-for="loteData in manzanas.lotes.filter(lote => lote.manzanaId == props.item.id )" :key="loteData.lote.id" class="ma-2" color="verdeFayal" @input="removeLote(props.item.id, loteData)" outline close @click="sendConcepts(loteData)">
                    {{ loteData.lote.nombre }}
                  </v-chip>
                </td>
                <td v-if="dad != 'Obra'">
                  <v-tooltip bottom>
                    <v-btn
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="verdeFayal"
                      @click="addConceptosUrbanizacion(props.item.id, props.item);im_working_on='manzanas'"
                    >
                      <v-icon>assignment</v-icon>
                    </v-btn>
                    <span>Conceptos Y Rubros</span>
                  </v-tooltip>
                </td>
              </template>
            </v-data-table>
          <v-divider></v-divider>

          <h4 v-if="dad != 'Obra'">Áreas Verdes</h4>
          <v-layout v-if="dad != 'Obra'" wrap>
            <v-autocomplete
              v-model="areasVerdes.selected"
              browser-autocomplete="off"
              hide-selected
              return-object
              no-data-text="No hay áreas verdes disponibles"
              placeholder="Seleccione las áreas verdes a incluir en la etapa"
              :chips="true"
              :multiple="true"
              :deletable-chips="true"
              item-text="nombre"
              item-value="id"
              :items="areasVerdes.items"
            >
            </v-autocomplete>
          </v-layout>

          <v-data-table
              v-if="dad != 'Obra'"
              :hide-actions="true"
              :disable-initial-sort="true"
              :headers="areasVerdes.headers"
              :items="areasVerdes.selected"
              class="elevation-1"
            > 
            <template slot="items" slot-scope="props">
                <td>{{ props.item.nombre }}</td>
                <td>
                  <v-tooltip bottom>
                    <v-btn
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="verdeFayal"
                      @click="addConceptosUrbanizacion(props.item.id, props.item);im_working_on='areasVerdes'"
                    >
                      <v-icon>assignment</v-icon>
                    </v-btn>
                    <span>Conceptos Y Rubros</span>
                  </v-tooltip>
                </td>
            </template>
          </v-data-table>
          <v-divider v-if="dad != 'Obra'"></v-divider>

          <h4 v-if="dad != 'Obra'">Calles</h4>
          <v-layout v-if="dad != 'Obra'" wrap>
            <v-autocomplete
              v-model="calles.selected"
              browser-autocomplete="off"
              hide-selected
              return-object
              no-data-text="No hay calles disponibles"
              placeholder="Seleccione las calles a incluir en la etapa"
              :chips="true"
              :multiple="true"
              :deletable-chips="true"
              item-text="nombre"
              item-value="id"
              :items="calles.items"
            >
            </v-autocomplete>
          </v-layout>

          <v-data-table
            v-if="dad != 'Obra'"
            :hide-actions="true"
            :disable-initial-sort="true"
            :headers="calles.headers"
            :items="calles.selected"
            class="elevation-1"
          > 
            <template slot="items" slot-scope="props">
              <td>{{ props.item.nombre }}</td>
              <td>
                  <v-tooltip bottom>
                    <v-btn
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="verdeFayal"
                      @click="addConceptosUrbanizacion(props.item.id, props.item);im_working_on='calles'"
                    >
                      <v-icon>assignment</v-icon>
                    </v-btn>
                    <span>Conceptos Y Rubros</span>
                  </v-tooltip>
                </td>
            </template>
          </v-data-table>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="dad == 'Obra'"
            color="verdeFayal"
            flat
            @click="createStoreBody"
          >
            Guardar
          </v-btn>
          <v-btn
            v-if="dad != 'Obra'"
            color="verdeFayal"
            flat
            @click="createStoreUrbanizacionBody"
          >
            Guardar
          </v-btn>
        </v-card-actions>
        <conseptos-rubros-portatil
          @close="dialogConceptosRubros = false"
          :dialog="dialogConceptosRubros"
          :conceptos="conceptosToSend"
          :conceptosDad="conceptosDad"
          :loteData="loteData"
          :conceptosAutofill="conceptosAutofill"
          :rubrossAutofill="rubrossAutofill"
          :dad="dad"
          :dadName="dadName"
          :searchConcepts="false"
          :dadId="selectedId"
          v-on:dataSended="associateRubros"
        />
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { errorHandling } from "../../lib/utilities";
export default {
  props: ["dialog", 'fraccionamiento', 'propuestaLotificacion', 'showAreasVerdes', 'showCalles', 'dad'],

  data() {
    return {
      etapaNombre: "",
      im_working_on: '',
      dadName:'',
      selectedId : null,
      conceptosAutofill: [],
      rubrossAutofill: [],
      loteData: {
        lote: {},
        manzanaId: null,
        rubros: []
      },
      manzanas: {
        items:[],
        selected:[],
        lotes:[],
        headers:[
          { 
            sortable: false,
            text: 'Distribución' 
          },
          { 
            sortable: false,
            text: 'Lotes' 
          },
          { 
            sortable: false,
            text: 'Lotes seleccionados' 
          },
        ],
        headersUrbanizacion:[
          { 
            sortable: false,
            text: 'Distribución' 
          },
          { 
            sortable: false,
            text: 'Acciones' 
          },
        ]
      },
      areasVerdes: {
        items:[],
        selected:[],
        headers:[
          { 
            sortable: false,
            text: 'Distribución' 
          },
          { 
            sortable: false,
            text: 'Acciones' 
          },
        ]
      },
      calles: {
        items:[],
        selected:[],
        headers:[
          { 
            sortable: false,
            text: 'Distribución' 
          },
          { 
            sortable: false,
            text: 'Acciones' 
          },
        ]
      },
      loadingToolbar: false,
      localFraccionamiento: {},
      dialogConceptosRubros:false,
      conceptosToSend: [],
      conceptosDad: '',
    };
  },
  watch:{
    dialog: function(value){
      if(!value){
        this.manzanas.selected = [];
        this.manzanas.lotes = [];
        this.areasVerdes.selected = [];
        this.calles.selected = [];
      }else{
        Object.assign(this.localFraccionamiento, this.fraccionamiento);
      }
    },
    propuestaLotificacion: function(value){
      this.getFraccionamiento();
    },
  },
  methods: {
    addConceptosUrbanizacion: function(areaVerdeId, item){
      this.conceptosAutofill = item.conceptos ? item.conceptos : []; 
      this.rubrossAutofill = item.rubros ? item.rubros : [];
      this.selectedId = areaVerdeId;
      this.dadName = item.nombre;
      this.dialogConceptosRubros = true;
    },
    addLote: function(manzanaId, lote){
      if(this.manzanas.lotes.filter(loteData => loteData.lote.id == lote.id).length > 0) return;
      this.manzanas.lotes.push({manzanaId: manzanaId, lote: lote});
    },
    removeLote: function(manzanaId, lote){
      this.manzanas.lotes.splice(this.manzanas.lotes.indexOf(lote), 1);
    },
    sendConcepts: function(loteData){
      this.conceptosToSend = loteData.lote.prototipo.custom_conceptos;
      this.dialogConceptosRubros = true;
      this.conceptosDad = loteData.lote
      this.loteData = loteData;
    },
    associateRubros: function(rubros){
      if(this.dad == 'Obra'){
        this.manzanas.lotes[this.manzanas.lotes.indexOf(this.manzanas.lotes.find(element => element.lote.id == rubros.loteId))].rubros = rubros.rubros;
      }else{
        if(this.im_working_on == 'areasVerdes'){
          const rubrosMapped = [ ... new Set( rubros.rubros.map(rubroData => rubroData.rubro.id) ) ];
          const conceptosMapped = [ ... new Set( rubros.rubros.map(rubroData => rubroData.conceptoId) )];
          this.areasVerdes.selected[this.areasVerdes.selected.indexOf(this.areasVerdes.selected.find(element => element.id == rubros.urbanizacionId))].rubros = rubrosMapped;
          this.areasVerdes.selected[this.areasVerdes.selected.indexOf(this.areasVerdes.selected.find(element => element.id == rubros.urbanizacionId))].conceptos = conceptosMapped;
        }else if(this.im_working_on == 'manzanas'){
          const rubrosMapped = [ ... new Set( rubros.rubros.map(rubroData => rubroData.rubro.id) )];
          const conceptosMapped = [ ... new Set( rubros.rubros.map(rubroData => rubroData.conceptoId) )];
          this.manzanas.selected[this.manzanas.selected.indexOf(this.manzanas.selected.find(element => element.id == rubros.urbanizacionId))].rubros = rubrosMapped;
          this.manzanas.selected[this.manzanas.selected.indexOf(this.manzanas.selected.find(element => element.id == rubros.urbanizacionId))].conceptos = conceptosMapped;
        }else{
          const rubrosMapped = [ ... new Set( rubros.rubros.map(rubroData => rubroData.rubro.id) )];
          const conceptosMapped = [ ... new Set( rubros.rubros.map(rubroData => rubroData.conceptoId) )];
          this.calles.selected[this.calles.selected.indexOf(this.calles.selected.find(element => element.id == rubros.urbanizacionId))].rubros = rubrosMapped;
          this.calles.selected[this.calles.selected.indexOf(this.calles.selected.find(element => element.id == rubros.urbanizacionId))].conceptos = conceptosMapped;

        }
      }
    },
    createStoreBody: function(){
      let manzanasMapped = this.manzanas.selected.map(manzana => manzana.id);
      let body = {
        fraccionamiento_id: this.propuestaLotificacion.id,
        nombre: this.etapaNombre,
        manzanas: manzanasMapped,
        lotes: this.manzanas.lotes.filter(lote => manzanasMapped.includes(lote.manzanaId)),
      };
      if(!!body.lotes.length){
        let someError = false;
        body.lotes.forEach((lote, index )=> {
          if(!!lote.rubros){
            body.lotes[index].conceptosMapped = lote.rubros.map(rubro => rubro.conceptoId);
            body.lotes[index].rubrosMapped = lote.rubros.map(rubro => rubro.rubro.id);
            someError = !body.lotes[index].rubrosMapped.length || !body.lotes[index].conceptosMapped.length ? true : false;
          }else{
            someError = true;
          }
        })
        if (someError) return alertify.error('algunos lotes no tienen rubros asignados');
        this.etapaCreate(body);
      }else alertify.error('No hay lotes seleccionados');
    },
    etapaCreate: function (body){
      axios.post(route("etapas.store"), body)
        .then(response => {
          alertify.success(response.data.message_text);
          this.$emit('close');
        })
        .catch(error => errorHandling(error));
    },
    createStoreUrbanizacionBody: function(){
      let manzanas_without_errors = true;
      let areas_verdes_without_errors = true;
      let calles_without_errors = true;
      let body = {
        fraccionamiento_id: this.propuestaLotificacion.id,
        nombre: this.etapaNombre,
        manzanas: this.manzanas.selected,
        areasVerdes: this.areasVerdes.selected,
        calles: this.calles.selected,
        lotes: this.manzanas.lotes.filter(lote => manzanasMapped.includes(lote.manzanaId)),
      };
      console.log('el choriso: ', body.manzanas.reduce( (previousValue, currentValue) => previousValue + currentValue ? 1 : 0, 0) +
        body.areasVerdes.reduce( (previousValue, currentValue) => previousValue + currentValue ? 1 : 0, 0) +
        body.calles.reduce( (previousValue, currentValue) => previousValue + currentValue ? 1 : 0, 0));

      if((
        body.manzanas.reduce( (previousValue, currentValue) => previousValue + currentValue ? 1 : 0, 0) +
        body.areasVerdes.reduce( (previousValue, currentValue) => previousValue + currentValue ? 1 : 0, 0) +
        body.calles.reduce( (previousValue, currentValue) => previousValue + currentValue ? 1 : 0, 0)
      ) < 1) return alertify.error('Seleccione una Manzana, Área verde o Calle');
      if(!!body.manzanas.length){
        let someError = false;
        body.manzanas.forEach((manzana, index )=> {
          someError = !body.manzanas[index].rubros || !body.manzanas[index].conceptos ? true : false;
        })
        if (someError) {
          manzanas_without_errors = false;
          return alertify.error('algunas manzanas no tienen rubros asignados');
        }
      }
      
      if(!!body.areasVerdes.length){
        let someError = false;
        body.areasVerdes.forEach((areaVerde, index )=> {
          someError = !body.areasVerdes[index].rubros || !body.areasVerdes[index].conceptos ? true : false;
        })
        if (someError) {
          areas_verdes_without_errors = false;
          return alertify.error('algunas áreas verdes no tienen rubros asignados');
        }
      }
      
      if(!!body.calles.length){
        let someError = false;
        body.calles.forEach((manzana, index )=> {
          someError = !body.calles[index].rubros || !body.calles[index].conceptos ? true : false;
        })
        if (someError) {
          calles_without_errors = false;
          return alertify.error('algunas calles no tienen rubros asignados');
        }
      }
      
      if(manzanas_without_errors && areas_verdes_without_errors && calles_without_errors) return this.etapaUrbanizacionCreate(body);
    },
    etapaUrbanizacionCreate: function (body){
      axios.post(route("etapas.urbanizacion.store"), body)
        .then(response => {
          alertify.success(response.data.message_text);
          this.$emit('close');
        })
        .catch(error => errorHandling(error));
    },
    getFraccionamiento: function() {
      this.loadingToolbar = true;
      axios.get("fraccionamientos/"+this.propuestaLotificacion.id)
      .then(response => {
        this.manzanas.items =  response.data.prospecto.propuesta[0].only_manzanas;
        this.areasVerdes.items =  response.data.prospecto.propuesta[0].areas_verdes;
        this.calles.items =  response.data.prospecto.propuesta[0].calles;
        this.loadingToolbar = false;
      })
      .catch(error => errorHandling(error));
    }
  }
};
</script>