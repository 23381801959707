<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" width="450" persistent>
      <v-card>
        <v-toolbar color="white" flat>
            <v-spacer></v-spacer>
            <v-toolbar-title class="primary-title">Añadir valor del avalúo</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card-text>
            <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex md12>
                      <input-currency-mask v-model="venta.valor_de_avaluo" label="Valor de avalúo"/>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateSale" dark color="verdeFayal" outline flat :loading="vBtnSave.loading"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
        {{ vSnackBar.text }}
        <v-btn color="pink" flat @click="vSnackBar.visible = false">
            <v-icon>close</v-icon>
        </v-btn>
    </v-snackbar>
  </div>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../../lib/utilities";

  export default {
    mounted() {
    },
    data() {
      return {
        venta: {},
        localShow: false,
        vBtnSave: {
            loading: false
        },
        vSnackBar: {
            visible: false,
            text: null
        }
      }
    },
    props: {
      show : Boolean,
      ventaId: Number
    },
    watch: {
      show: function (value) {
        this.localShow = value;
      },
      localShow: function (value) {
        this.$emit('update:show', value)
      },
      ventaId: function() {
        this.getSale();
      }
    },
    methods: {
        getSale: function() {
            axios.get(laravelRoute('ventas.show.refactor', this.ventaId))
            .then(response => this.venta = response.data)
            .catch(error => errorHandling(error))
        },
        updateSale: function() {
            this.vBtnSave.loading = true;
            axios.patch(laravelRoute('ventas.actualizar_avaluo', this.ventaId), {
              'valor_de_avaluo': this.venta.valor_de_avaluo 
            })
            .then(response => {
                this.vSnackBar.text = response.data.message_text;
                this.vSnackBar.visible = true;
            })
            .catch(error => errorHandling(error))
            .finally(() => this.vBtnSave.loading = false);
        }
    }
  }
</script>
