var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Clientes")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "verdeFayal",
                          attrs: { dark: "" },
                          on: {
                            click: function($event) {
                              _vm.showCreateCustomerModal = true
                            }
                          }
                        },
                        [_vm._v(" Crear ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      "disable-initial-sort": "",
                      items: _vm.clientes.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", [
                              _vm._v(
                                _vm._s(props.item.nombre) +
                                  " " +
                                  _vm._s(props.item.apellido_paterno) +
                                  " " +
                                  _vm._s(props.item.apellido_materno)
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(props.item.fecha_aprobacion))
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.telefono))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.email))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "info",
                                              disabled: !_vm.$root.can(
                                                "update",
                                                _vm.permissions
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.EditCliente(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { color: "verdeFayal" }
                                              },
                                              [_vm._v("edit")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Editar")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: !_vm.$root.can(
                                                "files",
                                                _vm.permissions
                                              ),
                                              flat: "",
                                              icon: "",
                                              small: "",
                                              color: "warning"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openFilesModal(
                                                  props.item.id,
                                                  props.item.archivos
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("folder")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Archivos")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "error",
                                              disabled: !_vm.$root.can(
                                                "remove",
                                                _vm.permissions
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteCliente(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("delete")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Eliminar")])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.clientes
                        },
                        on: { "pagination-change-page": _vm.getClientes }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("edit-customer-modal", {
        attrs: {
          show: _vm.showEditCustomerModal,
          "cliente-id": _vm.currentClienteId
        },
        on: {
          "update:show": function($event) {
            _vm.showEditCustomerModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("client-files-modal", {
        attrs: {
          element_type: "App\\Cliente",
          element_id: _vm.elementId,
          show: _vm.dialogFile,
          permissions: _vm.permissions,
          personal_files: _vm.personalFiles.length ? _vm.personalFiles : []
        },
        on: {
          "update:show": function($event) {
            _vm.dialogFile = $event
          }
        }
      }),
      _vm._v(" "),
      (_vm.$root.can("new", _vm.permissions)
      ? true
      : false)
        ? _c("cliente-modal", {
            attrs: { show: _vm.showCreateCustomerModal },
            on: {
              "update:show": function($event) {
                _vm.showCreateCustomerModal = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }