<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="450px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Cálculo de horas de la etapa</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear
        :active="loadingToolbar"
        :indeterminate="loadingToolbar"
        striped
        absolute
        top
        color="verdeFayal"
      ></v-progress-linear>
      <v-card-text>
        <v-container grid-list-md>
          <v-list two-line subheader>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon class="green darken-1 white--text">play_arrow</v-icon>
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title>Fecha de inicio</v-list-tile-title>
                <v-list-tile-sub-title>{{etapa.fecha_inicio}}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>

          <v-list two-line subheader>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon class="blue darken-2 white--text">watch_later</v-icon>
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title>Horas de etapa</v-list-tile-title>
                <v-list-tile-sub-title>{{ etapa.horas_totales ? etapa.horas_totales : etapa.horas_por_etapa }} horas</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>

          <v-list two-line subheader>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon class="blue darken-2 white--text">calendar_today</v-icon>
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title>Dias de etapa</v-list-tile-title>
                <v-list-tile-sub-title>{{ etapa.horas_totales ? Math.ceil(etapa.horas_totales / 8) : etapa.dias_por_etapa }} dias</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>

          <v-list two-line subheader>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon class="blue darken-2 white--text">watch_later</v-icon>
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title>Horas de incidencia</v-list-tile-title>
                <v-list-tile-sub-title>{{ etapa.horas_incidencias }} horas</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>

          <v-list two-line subheader>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon class="blue darken-2 white--text">calendar_today</v-icon>
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title>Dias de incidencia</v-list-tile-title>
                <v-list-tile-sub-title>{{ etapa.dias_incidencias }} dias</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>

          <v-list two-line subheader>
            <v-list-tile avatar>
              <v-list-tile-avatar>
                <v-icon class="red white--text">stop</v-icon>
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title>Fecha de finalización</v-list-tile-title>
                <v-list-tile-sub-title>{{ etapa.fecha_fin }}</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>

        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    usuario: {
      email: null,
      contraseña: null
    },
    etapa: {},
    loadingToolbar: false,
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    etapaId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    etapaId: function(value) {
      this.getCalculoEtapa();
    }
  },
  methods: {
    getCalculoEtapa: function() {
      this.etapa = {};
      this.loadingToolbar = true;
      axios.get(`/etapas/${this.etapaId}/calculo`)
      .then( response => this.etapa = response.data )
      .catch( error =>  errorHandling(error))
      .finally(() => this.loadingToolbar = false);
    },
  },
}
</script>
