<template>
  <v-container>
    <v-layout row wrap>
      <v-flex :xs12="!showFiltersForm" :xs9="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Ordenes de salida</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <v-btn class="fyl-btnCreate" href="/orden_de_salida/create">Crear</v-btn>
            <v-btn dark color="verdeFayal" outline @click="showFiltersForm = !showFiltersForm">
              <v-icon dark>filter_alt</v-icon>
            </v-btn>
            <v-btn dark color="verdeFayal" outline  @click="exportToExcel">
              <v-icon dark>import_export</v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table :headers="vHeaders" disable-initial-sort :items="ordenes.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td>{{ props.item.folio }}</td>
              <td>{{ props.item.nombre }}</td>
              <td>{{ props.item.solicitud.solicitante }}</td>
              <td>{{ props.item.trabajador.nombre_completo }}</td>
              <td>{{ props.item.estado.information.estado }}</td>
              <td>
                <v-tooltip bottom>
                  <v-btn @click="openMaterialModal(props.item.id)" icon flat dark small slot="activator" color="verdeFayal">
                      <v-icon>remove_red_eye</v-icon>
                  </v-btn>
                  <span>Ver orden de salida</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="ordenes" @pagination-change-page="getOrdenes"></pagination>
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex xs3 v-if="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat>
            <v-toolbar-title>Filtros</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="showFiltersForm = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout row wrap>                                                                
                <v-flex>
                  <v-select 
                    v-model="filters.filterSolicitante" 
                    :items="itemsSolicitante" 
                    label="Solicitante"
                    clearable                                 
                  />
                </v-flex>
                <v-flex>
                  <v-select 
                    v-model="filters.filterEstado" 
                    :items="this.itemsEstados"
                    item-value="id"
                    item-text="estado"
                    label="Estado"
                    clearable                                 
                  />
                </v-flex>                
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn dark color="verdeFayal" small flat outline @click="limpiar">Limpiar</v-btn>
            <v-spacer></v-spacer>
            <v-btn dark color="verdeFayal" small @click="filterOrdenes">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- Modals -->
    <show-orden-modal :show.sync="showMaterialModal" :orden-id.sync="currentOrdenId"></show-orden-modal>  
  </v-container>
</template>

<script>
import swal from "sweetalert";

export default {
  props: ["permissions"],
  data: () => ({
    search: null,
    ordenes: {
      data: []
    },
    vPaginator: {
      limit: 10
    },
    showMaterialModal: false,
    currentOrdenId: null,
    vHeaders: [
      {
        text: 'Folio',
        value: 'folio'
      },
      {
        text: 'Nombre',
        value: 'nombre'
      },
      {
        text: 'Solicitante',
        value: 'solicitante'
      },
      {
        text: 'Entrega',
        value: 'entrega'
      },
      {
        text: 'Estado',
        value: 'estado'
      },
      {
        text: 'Acciones',
        value: 'acciones',
        sortable: false
      }
    ],

    showFiltersForm: false,
    filters: {        
      filterSolicitante: null,
      filterEstado: null,        
    },
    itemsEstados: [],
    itemsSolicitante: [
      'Obra',
      'Urbanización',
      'Maquinaría',
    ],
  }),
  watch: {
    search: function (value) {
      this.getOrdenes();
    },
    showCreateModal: function (value) {
      if (!value) {
        this.getOrdenes();
      }
    },
    showEditModal: function (value) {
      if (!value) {
        this.getOrdenes();
      }
    }
  },
  methods: {
    getOrdenes: function (page = 1) {
      axios.get(`/orden_de_salida?page=${page}&query=${this.search}`)
        .then(response => {
          this.ordenes = response.data;
        })
        .catch(error => errorHandling(error));
    },
    openMaterialModal: function(ordenId) {
      this.showMaterialModal = true;
      this.currentOrdenId = ordenId;
    },

    getEstados: function() {
      axios.post('/orden_de_salida/estados')
      .then(response => this.itemsEstados = response.data)
      .catch(error => errorHandling(error));
    },    
    filterOrdenes: function() {
      axios.post('/orden_de_salida/filterOrdenes',this.filters)
      .then(response => this.ordenes = response.data)
      .catch(error => errorHandling(error));
    },
    limpiar: function () {             
        this.filters.filterSolicitante = null;
        this.filters.filterEstado = null;
        this.getOrdenes();
    },
    exportToExcel: function() {
      axios.post('/orden_de_salida/filterOrdenes', {
        ...this.filters,
        to_excel: true
      }, {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'ordenSalida.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
    },
  },
  created() {
    this.getEstados();
    this.getOrdenes();
  }
}
</script>
