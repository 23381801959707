<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="850px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Editar: {{salidas.nombre_de_orden}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md6>
              <v-text-field v-model="salidas.nombre_de_orden" required label="Nombre de la orden" readonly></v-text-field>
            </v-flex>
            <v-flex md6>
              <v-text-field v-model="salidas.fecha_solicitud" required label="Fecha de solicitud" type="date"></v-text-field>
            </v-flex>
            <v-flex md6>
              <v-select v-model="salidas.solicitante" :items="solicitante" required label="Seleccione solicitante"></v-select>
            </v-flex>
            <v-flex md6>
              <v-select
                v-model="salidas.estado.information.id"
                :items="estado"
                item-value="id"
                item-text="estado"
                required
                label="Seleccione estado"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="verdeFayal" @click="updateSalidasAlmacen" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { errorHandling, laravelRoute } from '../../../lib/utilities';
  export default {
    data(){
      return{
        salidas:{},
        solicitante: [
          'Obra',
          'Urbanización',
          'Maquinaría',
        ],
        estado: [],
        localShow: false,
        vBtnSave: {
          loading: false
        },
        vSnackBar: {
          visible: false,
          text: null
        }
      }
    },
    props: {
      show: Boolean,
      materialId: Number
    },
    watch:{
      show: function (value) {
        this.localShow = value
      },
      materialId: function (value) {
        this.getSalidasAlmacen();
        this.getStatus();
      },
      localShow: function (value) {
        this.$emit('update:show', value)
      }
    },
    methods:{
      updateSalidasAlmacen: function(){
        this.vBtnSave.loading = true;
        axios.patch('/salidas_de_almacen/'+this.materialId,{
          nombre_de_orden: this.salidas.nombre_de_orden,
          solicitante: this.salidas.solicitante,
          fecha_solicitud: this.salidas.fecha_solicitud,
          estado: this.salidas.estado.information.id
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.vBtnSave.loading = false;
          this.localShow = false;
        })
        .catch(error => {
          this.vBtnSave.loading = false;
          errorHandling(error)
        });
      },
      getSalidasAlmacen: function(){
        axios.get(laravelRoute('salidas_de_almacen.show', this.materialId))
        .then(response => this.salidas = response.data)
        .catch(error => errorHandling(error));
      },
      getStatus: function(){
        axios.get("/salidas_de_almacen/estados").then(response => {
            this.estado = response.data;
          });
      },
    },
    created(){
      this.getSalidasAlmacen();
    }
  }
</script>