<template>
    <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn flat icon :href="`/etapas/fraccionamiento/${etapa.fraccionamiento_id}`">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>

        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-toolbar card prominent>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="body-2 grey--text">Iniciar actividades para la etapa {{etapa.nombre}} </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                        <v-layout>
                            <v-flex xs5>
                                <v-card dark color='#75d57b'>
                                    <v-layout>
                                        <v-flex xs8>
                                            <v-card-title primary-title>
                                                <div>
                                                    <div class="headline total-title">Iniciadas</div>
                                                    <div> Progreso de actividades Iniciadas</div>
                                                </div>
                                            </v-card-title>
                                        </v-flex>
                                        <v-flex xs4>
                                            <v-layout  align-center justify-center row fill-height>
                                                <v-container>
                                                    <v-progress-circular
                                                        :rotate="180"
                                                        :size="75"
                                                        :width="10"
                                                        :value="localEtapa.porcentaje_de_avance.iniciado"
                                                        color="white"
                                                    >
                                                        {{ localEtapa.porcentaje_de_avance.iniciado.toFixed(1) == '100.0' ? '100' : localEtapa.porcentaje_de_avance.iniciado.toFixed(1)}}%
                                                    </v-progress-circular>
                                                </v-container>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </v-flex>
                            <v-flex xs2></v-flex>
                            <v-flex xs5>
                                <v-card dark color='#75d57b'>
                                    <v-layout>
                                        <v-flex xs8>
                                            <v-card-title primary-title>
                                                <div>
                                                    <div class="headline total-title">Finalizadas</div>
                                                    <div> Progreso de actividades finalizadas</div>
                                                </div>
                                            </v-card-title>
                                        </v-flex>
                                        <v-flex xs4>
                                            <v-layout  align-center justify-center row fill-height>
                                                <v-container>
                                                    <v-progress-circular
                                                        :rotate="180"
                                                        :size="75"
                                                        :width="10"
                                                        :value="localEtapa.porcentaje_de_avance.finalizado"
                                                        color="white"
                                                    >
                                                        {{ localEtapa.porcentaje_de_avance.finalizado.toFixed(1) == '100.0' ? '100' : localEtapa.porcentaje_de_avance.finalizado.toFixed(1)}}%
                                                    </v-progress-circular>
                                                </v-container>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </v-flex>
                        </v-layout>

                        <v-divider></v-divider>

                        <v-layout row wrap>
                            <v-flex>
                                <v-card-text>
                                    <v-data-table
                                        :items="manzanas.data"
                                        :expand="expandManzana"
                                        ref="manzanasTable"
                                        hide-actions
                                        item-key="id"
                                    >
                                        <template v-slot:headers="props">
                                            <tr>
                                                <th>Manzana</th>
                                                <th class="text-xs-right">Acciones</th>
                                            </tr>
                                        </template>
                                        <template v-slot:items="props">
                                            <tr>
                                                <td>{{ props.item.nombre }}</td>
                                                <td class="text-xs-right">
                                                    <v-btn flat icon :href="`/etapas/${etapa.id}/actividades/manzana/${props.item.id}`">
                                                        <v-icon>chevron_right</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-slot:expand="props">
                                            <v-card flat>
                                                <v-data-table
                                                    :items="props.item.lotes_de_manzanas"
                                                    hide-actions
                                                    :expand="expandLotes = false"
                                                    item-key="id"
                                                >
                                                    <template v-slot:headers>
                                                        <tr>
                                                            <th></th>
                                                            <th>Lote</th>
                                                            <th>Porcentaje de avance</th>
                                                            <th class="text-xs-right">Acciones</th>
                                                        </tr>
                                                    </template>
                                                    <template v-slot:items="propsLotes">
                                                        <tr>
                                                            <td></td>
                                                            <td>{{ propsLotes.item.lote.nombre }}</td>
                                                            <td>
                                                                <div class="progress">
                                                                    <div 
                                                                        class="progress-bar progress-bar-striped progress-bar-animated bg-success" 
                                                                        role="progressbar" 
                                                                        :style="`width: ${propsLotes.item.porcentaje_de_avance}%;`" 
                                                                        :aria-valuenow="propsLotes.item.porcentaje_de_avance" 
                                                                        aria-valuemin="0" 
                                                                        aria-valuemax="100"
                                                                    >
                                                                        {{ propsLotes.item.porcentaje_de_avance.toFixed(2) }}%
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="text-xs-right">
                                                                <v-btn flat icon @click="propsLotes.expanded = !propsLotes.expanded">
                                                                    <v-icon>expand_more</v-icon>
                                                                </v-btn>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <template v-slot:expand="propsLotes">
                                                        <v-data-table
                                                            :items="propsLotes.item.actividad"
                                                            hide-actions
                                                            item-key="id"
                                                            select-all
                                                        >
                                                            <template v-slot:headers="rubrosProps">
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Rubro</th>
                                                                    <th>Concepto</th>
                                                                    <th>Tiempo esperado</th>
                                                                    <th>Fecha de inicio</th>
                                                                    <th>Fecha de finalización</th>
                                                                    <th class="text-xs-right">Acciones</th>
                                                                </tr>
                                                            </template>
                                                            <template v-slot:items="rubrosProps">
                                                                <td></td>
                                                                <td>{{ rubrosProps.item.rubro.nombre }}</td>
                                                                <td>{{ rubrosProps.item.rubro.conceptos.nombre }}</td>
                                                                <td>{{ rubrosProps.item.tiempo_estimado }} horas</td>
                                                                <td>{{ formatDate(rubrosProps.item.fecha_de_inicio) }}</td>
                                                                <td>{{ formatDate(rubrosProps.item.fecha_de_finalizacion) }}</td>
                                                                <td>
                                                                    <v-layout>
                                                                        <v-tooltip bottom>
                                                                            <v-btn
                                                                                icon
                                                                                flat
                                                                                dark
                                                                                small
                                                                                slot="activator"
                                                                                color="verdeFayal"
                                                                                :disabled="(rubrosProps.item.iniciado) ? true : false"
                                                                                @click="initDistribucionById(rubrosProps.item.id, props.item.id, props.index)"
                                                                            >
                                                                                <v-icon>play_arrow</v-icon>
                                                                            </v-btn>
                                                                            <span>Iniciar</span>
                                                                        </v-tooltip>
                                                                        <v-tooltip bottom>
                                                                            <v-btn
                                                                                icon
                                                                                flat
                                                                                dark
                                                                                small
                                                                                slot="activator"
                                                                                color="red"
                                                                                :disabled="(!rubrosProps.item.iniciado || rubrosProps.item.finalizado) ? true : false"
                                                                                @click="finishDistribucionById(rubrosProps.item.id, props.item.id, props.index)"
                                                                            >
                                                                                <v-icon>stop</v-icon>
                                                                            </v-btn>
                                                                            <span>Finalizar</span>
                                                                        </v-tooltip>
                                                                    </v-layout>
                                                                </td>
                                                            </template>
                                                        </v-data-table>
                                                    </template>
                                                </v-data-table>
                                            </v-card>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <pagination :limit="10" :data="manzanas" @pagination-change-page="getManzanas"></pagination>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import moment from "moment";

  export default {
    data() {
        return {
            manzanas: {
                data: []
            },
            expandManzana: false,
            expandCalles: false,
            expandAreas: false,
            localEtapa: {
                porcentaje_de_avance: {"finalizado":0,"iniciado":0}
            },
            vTabs: 'tab-manzanas',
            vBtnSave: {
                loading: false
            },
            vSnackBar: {
                visible: false,
                text: null
            },
        }
    },
    props: {
      etapa : Object 
    },
    computed: {
        iniciado: function() {
            return 
        }
    },
    watch: {
      etapa: function(value) {
        this.localEtapa = value;
      }
    },
    methods: {
        getEtapa: function() {
            axios.get(`/etapas/refactor/${this.etapa.id}`)
            .then(response => this.localEtapa = response.data)
            .catch(error => errorHandling(error));
        },
        getManzanas: function(page = 1) {
            axios.get(`/etapas/${this.etapa.id}/manzanas_refactor?page=${page}&withConceptos=true`)
            .then(response => this.manzanas = response.data)
            .catch(error => errorHandling(error));
        },
        initLotesByManzanaEtapa: function(manzanaEtapaId) {
            swal({
                title: "¿Estás seguro?",
                text: "Al aceptar iniciarás todas las actividades referentes a esta manzana",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: "#1aba24"
            }).then(result => {
                if (result) {
                    axios.post("/actividades/iniciar_lotes_por_manzana", {
                        manzana_etapa_id: manzanaEtapaId,
                        etapa_id: this.etapa.id
                    })
                    .then(response => {
                        this.vSnackBar.text = response.data.message_text;
                        this.vSnackBar.visible = true;
                        this.getManzanas();
                        this.getEtapa();
                    })
                    .catch(error => errorHandling(error))
                }
            });    
        },
        finishedLotesByManzanaEtapa: function(manzanaEtapaId) {
            swal({
                title: "¿Estás seguro?",
                text: "Al aceptar finalizarás todas las actividades referentes a esta manzana",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: "#1aba24"
            }).then(result => {
                if (result) {
                    axios.post("/actividades/finalizar_lotes_por_manzana", {
                        manzana_etapa_id: manzanaEtapaId,
                        etapa_id: this.etapa.id
                    })
                    .then(response => {
                        this.vSnackBar.text = response.data.message_text;
                        this.vSnackBar.visible = true;
                        this.getManzanas();
                        this.getEtapa();
                    })
                    .catch(error => errorHandling(error))
                }
            });    
        },
        initLote: function(loteEtapaId) {
            swal({
                title: "¿Estás seguro?",
                text: "Al aceptar iniciarás todas las actividades referentes a este lote",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: "#1aba24"
            }).then(result => {
                if (result) {
                    axios.post("/actividades/iniciar_lote", {
                        lote_etapa_id: loteEtapaId,
                        etapa_id: this.etapa.id
                    })
                    .then(response => {
                        this.vSnackBar.text = response.data.message_text;
                        this.vSnackBar.visible = true;
                        this.getManzanas();
                        this.getEtapa();
                    })
                    .catch(error => errorHandling(error))
                }
            });    
        },
        finishLote: function(loteEtapaId) {
            swal({
                title: "¿Estás seguro?",
                text: "Al aceptar finalizarás todas las actividades referentes a este lote",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: "#1aba24"
            }).then(result => {
                if (result) {
                    axios.post("/actividades/finalizar_lote", {
                        lote_etapa_id: loteEtapaId,
                        etapa_id: this.etapa.id
                    })
                    .then(response => {
                        this.vSnackBar.text = response.data.message_text;
                        this.vSnackBar.visible = true;
                        this.getManzanas();
                        this.getEtapa();
                    })
                    .catch(error => errorHandling(error))
                }
            });    
        },
        initDistribucionById: function(activityId, manzanaId, index) {
            swal({
                title: "¿Estás seguro?",
                text: "Al aceptar iniciarás esta actividad",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: "#1aba24"
            }).then(result => {
                if (result) {
                    axios.post(`/etapas/${this.etapa.id}/distribucion/iniciar_por_id`, {
                        actividad_id: activityId
                    })
                    .then(response => {
                        this.vSnackBar.text = response.data.message_text;
                        this.vSnackBar.visible = true;
                        this.reloadManzana(manzanaId, index);
                        this.getEtapa();
                    })
                    .catch(error => errorHandling(error));
                }
            });
        },
        finishDistribucionById: function(activityId, manzanaId, index) {
            swal({
                title: "¿Estás seguro?",
                text: "Al aceptar finalizarás esta actividad",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: "#1aba24"
            }).then(result => {
                if (result) {
                    axios.post(`/etapas/${this.etapa.id}/distribucion/finalizar_por_id`, {
                        actividad_id: activityId
                    })
                    .then(response => {
                        this.vSnackBar.text = response.data.message_text;
                        this.vSnackBar.visible = true;
                        this.reloadManzana(manzanaId, index);
                        this.getEtapa();
                    })
                    .catch(error => errorHandling(error));
                }
            });
        },
        formatDate: function(date) {
            if(date) {
                var localLocale = moment(date);
                moment.locale('es');
                localLocale.locale(false)
                return localLocale.format('LLLL')
            }
        },
        openLotesExpand: function(id, index) {
            let expand = !this.$refs.manzanasTable.expanded[id];
            axios.get(`/etapas/${this.etapa.id}/manzanas/${id}/lotes`)
            .then(response => this.manzanas.data[index].lotes_de_manzanas = response.data)
            .catch(error => errorHandling(error))
            .finally(() => this.$set(this.$refs.manzanasTable.expanded, id, expand));
        },
        reloadManzana: function( manzanaId, index) {
            axios.get(`/etapas/${this.etapa.id}/manzanas/${manzanaId}/lotes`)
            .then(response => {
                let oldManzana = this.manzanas.data[index];
                this.manzanas.data.splice(index, 1, {
                    ...oldManzana,
                    lotes_de_manzanas: response.data
                });
            })
            .catch(error => errorHandling(error))
        },
    },
    created(){
        this.getManzanas();
        this.getEtapa();
    }
  }
</script>
