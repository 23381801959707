var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline grey lighten-2",
                  attrs: { "primary-title": "" }
                },
                [
                  _c("v-flex", { attrs: { md8: "" } }, [
                    _c("p", [_vm._v(_vm._s(_vm.type))])
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { md4: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "warning",
                            flat: "",
                            disabled:
                              _vm.$store.state.saveDisabled || _vm.edit_disabled
                          },
                          on: { click: _vm.pickFile }
                        },
                        [
                          _vm._v("\n            Adjuntar\n            "),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("attach_file")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("input", {
                        ref: "file",
                        staticStyle: { display: "none" },
                        attrs: { type: "file" },
                        on: { change: _vm.fileSelected }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.Uploading > 0 && _vm.Uploading < 100,
                      expression: "Uploading > 0 && Uploading < 100"
                    }
                  ],
                  attrs: { md12: "" }
                },
                [
                  _c("v-progress-linear", {
                    model: {
                      value: _vm.Uploading,
                      callback: function($$v) {
                        _vm.Uploading = $$v
                      },
                      expression: "Uploading"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", [
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("th", [_vm._v("Nombre del archivo")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Acciones")])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.archivos, function(archivo) {
                      return _c("tr", { key: archivo.id }, [
                        _c("td", [_vm._v(_vm._s(archivo.filename))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "v-tooltip",
                              { attrs: { bottom: "" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      slot: "activator",
                                      icon: "",
                                      flat: "",
                                      dark: "",
                                      small: "",
                                      color: "success",
                                      href: archivo.url,
                                      target: "_blank"
                                    },
                                    slot: "activator"
                                  },
                                  [_c("v-icon", [_vm._v("launch")])],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Ver")])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-tooltip",
                              { attrs: { bottom: "" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      slot: "activator",
                                      icon: "",
                                      flat: "",
                                      dark: "",
                                      color: "error",
                                      disabled: _vm.edit_disabled
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteFile(archivo)
                                      }
                                    },
                                    slot: "activator"
                                  },
                                  [_c("v-icon", [_vm._v("delete")])],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Eliminar")])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    }),
                    0
                  )
                ])
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        flat: "",
                        disabled: _vm.closeDisabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_vm._v("Cerrar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        flat: "",
                        disabled:
                          _vm.$store.state.saveDisabled || _vm.saveDisabled
                      },
                      on: { click: _vm.storeFiles }
                    },
                    [_vm._v("Guardar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }