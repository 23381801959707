<template>  
    <v-dialog v-model="localShow" persistent max-width="550">
      <v-card>
        <v-toolbar color="white" flat>
            <v-spacer></v-spacer>
                <v-toolbar-title class="primary-title">Rubros asociados a {{ concepto.nombre }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text>
            <table class="table table-striped table-hover table-sm">
                <tbody>
                    <tr v-for="rubro in concepto.rubros" :key="rubro.id">
                        <td></td>
                        <td>{{ rubro.nombre }}</td>
                    </tr>
                </tbody>
            </table>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import swal from "sweetalert";

  export default {
    data() {
      return {
        localShow: false,
        vBtnSave: {
            loading: false
        },
        vSnackBar: {
            visible: false,
            text: null
        }
      }
    },
    props: {
        show: Boolean,
        concepto: Object
    },
    watch: {
        show: function (value) {
            this.localShow = value
        },
        localShow: function (value) {
            this.$emit('update:show', value);
        }
    },
    methods: {
      
    },
    computed: {

    },
  }
</script>
