<template>
  <v-layout>
    <v-container>
      <v-card>
        <v-flex xs12>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Prospectos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <prospectos-create-modal
              v-if="$root.can('new', permissions) ? true : false"
              :prospectos="prospectos.data"
              :prospecto="prospecto"
              :dialog="dialog"
              @open="dialog = true"
              @close="cleanForm()"
            ></prospectos-create-modal>
          </v-toolbar>
          <v-data-table :headers="vHeaders" disable-initial-sort :items="prospectos.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td>{{ props.item.nombre_proyecto }}</td>
              <td class="td-ellipsis">{{ props.item.direccion }}</td>
              <td>{{ props.item.fecha }}</td>
              <td>{{ props.item.descripcion }}</td>
              <td>
                <v-layout>
                  <v-tooltip bottom>
                    <v-btn flat icon slot="activator" color="warning" @click="elementId = props.item.id; dialogFile = true;">
                      <v-icon>folder</v-icon>
                    </v-btn>
                    <span>Archivos</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      :disabled="!$root.can('update', permissions)"
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="info"
                      @click="editprospecto(props.item.id)"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-checkbox
                      flat
                      :disabled="!$root.can('accept', permissions) ? true : (props.item.aprobado ? true : false)"
                      v-model="props.item.aprobado"
                      slot="activator"
                      color="indigo"
                      @click="sweetAlertAprovadoGerencia(props.item)"
                    ></v-checkbox>
                    <span>{{ props.item.aprobado ? 'Prospecto aprobado - gerencia' : 'Aprobar Prospecto - gerencia'}}</span>
                  </v-tooltip>
                  <v-tooltip class="fyl-check-prospectos2" bottom>
                    <v-checkbox
                      :disabled="!$root.can('buy', permissions) ? true : (props.item.comprado ? true : false)"
                      v-model="props.item.comprado"
                      slot="activator"
                      color="orange darken-3"
                      @click="sweetAlertAprovadoPlaneacion(props.item)"
                    ></v-checkbox>
                    <span>{{ props.item.comprado ? 'Prospecto aprobado - planeación' : 'Aprobar Prospecto - planeación' }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      :disabled="!$root.can('remove', permissions) ? true : (props.item.comprado ? true : false)"
                      :color="props.item.comprado ? '' : color='error'"
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      @click="sweetAlert(props.item)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <span>{{ props.item.comprado ? 'No se puede eliminar' : 'Eliminar' }}</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="prospectos" @pagination-change-page="getProspectos"></pagination>
          </v-card-actions>
        </v-flex>
      </v-card>
    </v-container>
    <!-- Modals -->
    <upload-files-modal @close="dialogFile = false" element_type="App\Prospecto" :element_id="elementId" :dialog="dialogFile"></upload-files-modal>

  </v-layout>
</template>
<script>
import { errorHandling, laravelRoute } from "../../lib/utilities";
import axios from "axios";
import swal from "sweetalert";

export default {
  props: ["permissions"],

  data() {
    return {
      search: "",
      dialogFile: false,
      elementId: '',
      dialog: false,
      prospectos: {
        data: []
      },
      prospecto: {
        coordenadas: "312323123 23231223123"
      },
      vPaginator: {
        limit: 10
      },
      vHeaders: [
        { text: "Nombre de Proyecto", value: "nombre_proyecto" },
        { text: "Dirección", value: "direccion" },
        { text: "Fecha", value: "fecha" },
        { text: "Descripción", value: "descripcion" },
        { text: "Acciones", value: "", sortable: false }
      ],
      pagination: {
        sortBy: "id",
        descending: true
      }
    };
  },
  watch: {
    dialog: function(value){
      if(!value){
        this.getProspectos();
      }
    },
    search: function (value) {
      this.getProspectos();
    },
  },
  methods: {
    cleanForm() {
      this.prospecto = {
        coordenadas: "312323123 23231223123"
      };
      this.dialog = false;
    },

    getProspectos: function (page = 1) {
      axios.get(`/prospectos?page=${page}&query=${this.search}`)
        .then(response => {
          this.prospectos = response.data;
        })
        .catch(error => errorHandling(error));
    },

    sweetAlert: function(prospecto) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
          this.eliminar(prospecto.id);
          this.getProspectos();
        }
      });
    },

    eliminar: prospectoId => {
      var IDprospecto = prospectoId;
      axios
        .delete(route("prospectos.destroy", IDprospecto))
        .then(response => {
          if (response.data.message_text) {
            alertify.success(response.data.message_text);
          }
        })
        .catch(error => errorHandling(error));
    },

    editprospecto: function(prospectoId) {
      axios.get(route("prospectos.edit", prospectoId)).then(response => {
        this.prospecto = response.data;
        this.dialog = true;
      });
    },

    aprovadoComprado: function(prospecto) {
      axios
        .put(route("prospectos.aceptar", prospecto.id))
        .then(response => {
          if (response.data.message_text)
            alertify.success(response.data.message_text);
        })
        .catch(error => errorHandling(error));
    },
    prospectoComprado: function(prospecto) {
      axios
        .put(route("prospectos.comprar", prospecto.id))
        .then(response => {
          if (response.data.message_text)
            alertify.success(response.data.message_text);
        })
        .catch(error => errorHandling(error));
    },
    sweetAlertAprovadoGerencia: function(prospectoAprobado){
      swal({
        title: "¿Está seguro que quiere dar visto bueno a este prospecto?",
        text: "Tenga en cuenta que, por el momento, esta acción será irrevocable",
        icon: "warning",
        buttons: true
      }).then(response => {
        if(response){
          this.aprovadoComprado(prospectoAprobado);
          swal("El prospecto se aprobó satisfactoriamente", {
            icon: "success"
        }).then(response => {
          this.getProspectos();
        });
        }else{
          swal("El prospecto no se aprobó");
        }
      });
    },
    sweetAlertAprovadoPlaneacion: function(prospectoAprobado){
      swal({
        title: "¿Está seguro que quiere dar visto bueno a este prospecto?",
        text: "Tenga en cuenta que, por el momento, esta acción será irrevocable",
        icon: "warning",
        buttons: true
      }).then(response => {
        if(response){
          this.prospectoComprado(prospectoAprobado);
          swal("El prospecto se aprobó satisfactoriamente", {
            icon: "success"
          }).then(response => {
            this.getProspectos();
          });
        }else{
          swal("El prospecto no se aprobó");
        }
      });
    }
  },
  created() {
    this.getProspectos();
  }
};
</script>

<style>
.fyl-headerTable {
  color: #000;
  background: #fcfcfc;
}

.v-datatable .v-input--selection-controls {
  position: relative;
  top: 8px;
  left: 6px;
}

.green--text.text--darken-2 {
  position: relative;
  top: 8px;
  left: 4px;
}
.theme--dark.v-btn.v-btn--disabled,
.theme--dark.v-btn.v-btn--disabled .v-btn__loading,
.theme--dark.v-btn.v-btn--disabled .v-icon {
  color: #bdbdbd !important;
}
.fyl-check-prospectos {
  padding: 0 10px 0 0 !important;
}
.fyl-check-prospectos2 {
  padding: 0 0px 0 0 !important;
}
</style>