<template>
    <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat></v-toolbar>
        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-card-text>
                        <div class="text-xs-center">
                            <v-container grid-list-md>
                                <v-layout column>
                                    <v-flex xs12>
                                        <v-avatar
                                            :tile="false"
                                            :size="100"
                                            color="grey lighten-4"
                                        >
                                            <img src="/img/avatar.svg" alt="avatar">
                                        </v-avatar>
                                        <h3 class="headline mb-0">{{ user.name }}</h3>
                                        <span style="background-color: #ff5617;" class="badge badge-secondary">
                                            {{ user.roles[0].name }}
                                        </span>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-text-field v-model="user.name" label="Nombre completo"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-text-field type="emaill" v-model="user.email" label="Correo electrónico"></v-text-field>
                                    </v-flex>
                                    <v-flex md12>
                                        <v-text-field type="password" v-model="user.contraseña" required label="Contraseña"></v-text-field>
                                    </v-flex>
                                    <v-flex md12>
                                        <v-text-field type="password" v-model="user.confirmar_contraseña" required label="Confirmar contraseña"></v-text-field>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn dark outline color="verdeFayal" @click="updateUser" flat :loading="vBtnSave.loading"> Guardar </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    data() {
      return {
        vSnackBar: {
            visible: false,
            text: null
        },
        vBtnSave: {
            loading: false
        },
      }
    },
    props: {
        user: Object
    },
    watch: {

    },
    methods: {
        updateUser: function() {
            this.vBtnSave.loading = true;
            axios.patch(laravelRoute('perfil.update', this.user.id), {
                name: this.user.name,
                email: this.user.email,
                password: this.user.contraseña,
                password_confirmation : this.user.confirmar_contraseña,
            })
            .then(response => {
                this.vSnackBar.text = response.data.message_text;
                this.vSnackBar.visible = true;
            })
            .catch(error => errorHandling(error))
            .finally(() => this.vBtnSave.loading = false)
        }
    }
  }
</script>

<style>
    .card--flex-toolbar {
        margin-top: -64px;
    }
</style>
