<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" width="700">
        <v-card>
            <v-toolbar color="white" flat>
                <v-spacer></v-spacer>
                <v-toolbar-title class="primary-title">Duplicar {{ household.nombre }}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
                <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-container grid-list-md>
                    <v-layout wrap>
                        <v-flex md12>
                            <v-text-field v-model="household.nombre" required label="Nombre"></v-text-field>
                        </v-flex>
                        <v-flex md12>
                            <v-textarea v-model="household.descripcion" required label="Descripción" counter="250" :maxlength="max"></v-textarea>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark color="verdeFayal" outline @click="duplicateHousehold" flat :loading="vBtnSave.loading"> Duplicar </v-btn>
            </v-card-actions>
        </v-card>    
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
        {{ vSnackBar.text }}
        <v-btn color="pink" flat @click="vSnackBar.visible = false">
            <v-icon>close</v-icon>
        </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";

  export default {
    data() {
      return {
        household: {},
        localShow: false,
        vBtnSave: {
            loading: false
        },
        vSnackBar: {
            visible: false,
            text: null
        }
      }
    },
    props: {
      show : Boolean,
      householdId: Number
    },
    watch: {
      show: function (value) {
        this.localShow = value;
      },
      localShow: function (value) {
        this.$emit('update:show', value)
      },
      householdId: function() {
          this.getHousehold();
      }
    },
    methods: {
        getHousehold: function() {
            axios.get(`/prototipos_vivienda/${this.householdId}`)
            .then(response => this.household = response.data)
            .catch(error => errorHandling(error))
        },
        duplicateHousehold: function() {
            this.vBtnSave.loading = true;
            axios.post(`/prototipos_vivienda/${this.householdId}/duplicar`, {
                nombre: this.household.nombre,
                descripcion: this.household.descripcion
            })
            .then(response => {
              this.vSnackBar.text = response.data.message_text;
              this.vSnackBar.visible = true;
            })
            .catch(error => errorHandling(error))
            .finally(() => this.vBtnSave.loading = false)
        }
    }
  }
</script>
