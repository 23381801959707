var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "", id: "container-supplier" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            {
              attrs: { xs12: !_vm.showFiltersModal, xs9: _vm.showFiltersModal }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v("Trámites en dependencias")
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      (_vm.$root.can("new", _vm.permissions)
                      ? true
                      : false)
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "fyl-btnCreate",
                              on: { click: _vm.openCreateModal }
                            },
                            [_vm._v("\n            Crear")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", outline: "" },
                          on: {
                            click: function($event) {
                              _vm.showFiltersModal = !_vm.showFiltersModal
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("filter_alt")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", outline: "" },
                          on: { click: _vm.exportToExcel }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("import_export")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      "disable-initial-sort": "",
                      items: _vm.tramites.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", [
                              _vm._v(_vm._s(props.item.nombre_tramite))
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.descripcion))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  props.item.fraccionamiento.nombre_proyecto
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(props.item.fecha_resolucion))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  props.item.estado
                                    ? props.item.estado.information.estado
                                    : "No disponible"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: !_vm.$root.can(
                                                "update",
                                                _vm.permissions
                                              ),
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              "x-small": "",
                                              color: "info"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.editTramite(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  "x-small": "",
                                                  color: "#1ABA24"
                                                }
                                              },
                                              [_vm._v("edit")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Editar")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: !_vm.$root.can(
                                                "files",
                                                _vm.permissions
                                              ),
                                              flat: "",
                                              icon: "",
                                              color: "warning"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.OpenFilesModal(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-small": "" } },
                                              [_vm._v("folder")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Archivos")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: !_vm.$root.can(
                                                "states",
                                                _vm.permissions
                                              ),
                                              icon: "",
                                              flat: "",
                                              color: "verdeFayal"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openStatusModal(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-small": "" } },
                                              [_vm._v("low_priority")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Ver estados")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: !_vm.$root.can(
                                                "comments",
                                                _vm.permissions
                                              ),
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              "x-small": "",
                                              color: "success"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openComment(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-small": "" } },
                                              [_vm._v("chat")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("Hacer comentarios")
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: !_vm.$root.can(
                                                "remove",
                                                _vm.permissions
                                              ),
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              "x-small": "",
                                              color: "error"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteTramite(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-small": "" } },
                                              [_vm._v("delete")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Eliminar")])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.tramites
                        },
                        on: { "pagination-change-page": _vm.getTramites }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.showFiltersModal
            ? _c(
                "v-flex",
                { attrs: { xs3: "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { color: "white", flat: "" } },
                        [
                          _c("v-toolbar-title", [_vm._v("Filtros")]),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                icon: "",
                                color: "verdeFayal",
                                dark: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.showFiltersModal = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          color: "verdeFayal",
                                          items: _vm.proyectoItems,
                                          "item-value": "id",
                                          "item-text": "nombre_proyecto",
                                          label: "Proyecto al que pertenece",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.filters.filterProyecto,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "filterProyecto",
                                              $$v
                                            )
                                          },
                                          expression: "filters.filterProyecto"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          color: "verdeFayal",
                                          items: _vm.estadosTramite,
                                          "item-value": "id",
                                          "item-text": "estado",
                                          label: "Estado",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.filters.filterEstado,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "filterEstado",
                                              $$v
                                            )
                                          },
                                          expression: "filters.filterEstado"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                color: "verdeFayal",
                                small: "",
                                flat: "",
                                outline: ""
                              },
                              on: { click: _vm.limpiar }
                            },
                            [_vm._v("Limpiar")]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                color: "verdeFayal",
                                small: ""
                              },
                              on: { click: _vm.filterTramites }
                            },
                            [_vm._v("Filtrar")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("upload-files-modal", {
        attrs: {
          element_type: "App\\Tramite",
          element_id: _vm.currentElementId,
          dialog: _vm.dialogFile
        },
        on: {
          close: function($event) {
            _vm.dialogFile = false
          }
        }
      }),
      _vm._v(" "),
      _c("manage-estados-modal-component", {
        attrs: {
          show: _vm.showStatusModal,
          "model-id": _vm.currentTramiteId,
          "model-type": "App\\Tramite"
        },
        on: {
          "update:show": function($event) {
            _vm.showStatusModal = $event
          },
          "update:modelId": function($event) {
            _vm.currentTramiteId = $event
          },
          "update:model-id": function($event) {
            _vm.currentTramiteId = $event
          }
        }
      }),
      _vm._v(" "),
      _c("tramites-dependencias-form", {
        attrs: {
          show: _vm.showCreateTramiteModal,
          dependencia: _vm.dependencia
        },
        on: {
          "update:show": function($event) {
            _vm.showCreateTramiteModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("manage-comments-modal", {
        attrs: {
          show: _vm.showComentariosModal,
          "modelable-type": "App\\Tramite",
          "modelable-id": _vm.currentTramiteId
        },
        on: {
          "update:show": function($event) {
            _vm.showComentariosModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("edit-tramites-dependencias-modal", {
        attrs: {
          show: _vm.showEditTramiteModal,
          "tramite-id": _vm.currentTramiteId
        },
        on: {
          "update:show": function($event) {
            _vm.showEditTramiteModal = $event
          },
          "update:tramiteId": function($event) {
            _vm.currentTramiteId = $event
          },
          "update:tramite-id": function($event) {
            _vm.currentTramiteId = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }