var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-text",
        [
          _c("h4", [_vm._v(_vm._s(_vm.ownerName))]),
          _vm._v(" "),
          _vm.type == "obra"
            ? _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  "hide-actions": true,
                  "disable-initial-sort": true,
                  headers: _vm.headers,
                  items: _vm.items
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "items",
                      fn: function(props) {
                        return [
                          _c("td", [
                            _vm._v(_vm._s(props.item.lote_lotificacion.nombre))
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(props.item.horas))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(Math.ceil(props.item.horas / 8)))
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                props.item.estado
                                  ? props.item.estado.information.estado
                                  : "No disponible"
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(props.item.manzana.nombre))]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c(
                                "v-tooltip",
                                { attrs: { bottom: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        slot: "activator",
                                        disabled:
                                          props.item.estado.information.id !=
                                          28,
                                        icon: "",
                                        flat: "",
                                        dark: "",
                                        small: "",
                                        color: "info"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.startFunction(
                                            props.item.id
                                          )
                                        }
                                      },
                                      slot: "activator"
                                    },
                                    [_c("v-icon", [_vm._v("play_arrow")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Iniciar")])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tooltip",
                                { attrs: { bottom: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        slot: "activator",
                                        disabled: !(
                                          props.item.estado.information.id >=
                                            28 &&
                                          props.item.estado.information.id < 30
                                        ),
                                        icon: "",
                                        flat: "",
                                        dark: "",
                                        small: "",
                                        color: "red"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.stopFunction(props.item.id)
                                        }
                                      },
                                      slot: "activator"
                                    },
                                    [_c("v-icon", [_vm._v("stop")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Finalizar")])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1876610458
                )
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.type != "obra"
            ? _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  "hide-actions": true,
                  "disable-initial-sort": true,
                  headers: _vm.headers,
                  items: _vm.items
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "items",
                      fn: function(props) {
                        return [
                          _c("td", [_vm._v(_vm._s(props.item.nombre))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(props.item.horas))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(_vm._s(Math.ceil(props.item.horas / 8)))
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                props.item.estado
                                  ? props.item.estado.information.estado
                                  : "No disponible"
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c(
                                "v-tooltip",
                                { attrs: { bottom: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        slot: "activator",
                                        disabled: [
                                          32,
                                          33,
                                          35,
                                          36,
                                          38,
                                          39
                                        ].includes(
                                          props.item.estado.information.id
                                        ),
                                        icon: "",
                                        flat: "",
                                        dark: "",
                                        small: "",
                                        color: "info"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.startFunction(
                                            props.item.id
                                          )
                                        }
                                      },
                                      slot: "activator"
                                    },
                                    [_c("v-icon", [_vm._v("play_arrow")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Iniciar")])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tooltip",
                                { attrs: { bottom: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        slot: "activator",
                                        disabled: [33, 36, 39].includes(
                                          props.item.estado.information.id
                                        ),
                                        icon: "",
                                        flat: "",
                                        dark: "",
                                        small: "",
                                        color: "red"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.stopFunction(props.item.id)
                                        }
                                      },
                                      slot: "activator"
                                    },
                                    [_c("v-icon", [_vm._v("stop")])],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Finalizar")])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2792992130
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }