/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

// import entire SDK
var AWS = require('aws-sdk');
var uuid = require('uuid');

Vue.component('alert-success', require('./components/AlertSuccess.vue').default)
Vue.component('build-information', require('./components/BuildInformation.vue').default)
Vue.component('form-previous-url', require('./components/FormPreviousUrl.vue').default)
Vue.component('upload-file-field', require('./components/UploadFileFieldComponent.vue').default)
Vue.component('upload-file-field-refactor', require('./components/UploadFileFieldRefactorComponent.vue').default)
Vue.component('upload-files-modal', require('./components/UploadFilesModalComponent.vue').default)
Vue.component('navbar-fyl', require('./components/Home/navbar.vue').default)

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('example-modal-component', require('./components/ExampleModalComponent.vue').default);
Vue.component('example-component-vuetify', require('./components/ExampleComponentVuetify.vue').default);

// Home Component
Vue.component('home', require('./components/Home/HomePage.vue').default);
Vue.component('sidebar-dash', window.ComponentSidebar)

// Usuarios Components
Vue.component('users-index', require('./components/Usuarios/UsersIndex.vue').default);
Vue.component('create-users-modal', require('./components/Usuarios/CreateUsersModal.vue').default);
Vue.component('edit-user-modal', require('./components/Usuarios/EditUserModal.vue').default);

// Roles Components
Vue.component('roles-create-form', require('./components/Roles/RolesCreateForm.vue').default);
Vue.component('roles-index-list', require('./components/Roles/RolesIndexList.vue').default);
Vue.component('edit-role', require('./components/Roles/EditRole.vue').default);

// Planeacion Components
Vue.component('urbanizacion-index', require('./components/planeacion/UrbanizacionIndex.vue').default);
Vue.component('create-housing-development-modal', require('./components/planeacion/UrbanizacionCreateForm.vue').default);
Vue.component('edit-housing-development-modal', require('./components/planeacion/UrbanizacionEditModal.vue').default);
Vue.component('prospectos-index-list', require('./components/planeacion/ProspectosIndexList.vue').default);
Vue.component('prospectos-create-modal', require('./components/planeacion/ProspectosCreateForm.vue').default);
Vue.component('obra-index-list', require('./components/planeacion/ObraIndexList.vue').default);
Vue.component('obra-create-form', require('./components/planeacion/ObraCreateForm.vue').default);
Vue.component('prospecto-show-notification', require('./components/planeacion/ProspectoShowNotification.vue').default);
Vue.component('values-housing-development-index', require('./components/planeacion/ValuesHousingDevelopmentIndex.vue').default);

// Proyectos components
Vue.component('proyectos-lotification-index', require('./components/Proyectos/ProyectosLotificationIndex.vue').default);
Vue.component('proyectos-modal-form', require('./components/Proyectos/ProyectosModalLotification.vue').default);
Vue.component('create-prospectos-modal-form', require('./components/Proyectos/CreateProspectosModal.vue').default);
Vue.component('edit-proyectos-modal-form', require('./components/Proyectos/EditProyectosLotificationForm.vue').default);
Vue.component('edit-calle-lotificacion-modal-form', require('./components/Proyectos/EditCalleLotificacionModal.vue').default);
Vue.component('edit-area-lotificacion-modal', require('./components/Proyectos/EditAreaLotificacionModal.vue').default);
Vue.component('edit-lote-lotificacion-modal', require('./components/Proyectos/EditLoteModal.vue').default);
Vue.component('add-calles-to-lotificacion-modal', require('./components/Proyectos/AddCallesToLotificacionModal.vue').default);
Vue.component('add-areas-to-lotificacion-modal', require('./components/Proyectos/AddAreasToLotificacionModal.vue').default);
Vue.component('add-lotes-manzanas-modal', require('./components/Proyectos/AddLotesToManzanaModal.vue').default);
Vue.component('lotificacion-show-notification', require('./components/Proyectos/LotificationShowNotification.vue').default);
Vue.component('prototipo-vivienda-list', require('./components/Proyectos/PrototipoViviendaIndex.vue').default);
Vue.component('prototipo-vivienda-modal', require('./components/Proyectos/PrototipoViviendaModal.vue').default);
Vue.component('tramites-dependencias-index', require('./components/Proyectos/TramitesDependenciasIndex.vue').default);
Vue.component('tramites-dependencias-form', require('./components/Proyectos/TramitesDependenciasForm.vue').default);
Vue.component('tramites-dependencias-comments', require('./components/Proyectos/TramitesDependenciasComments.vue').default);
Vue.component('tramites-show-notification', require('./components/Proyectos/TramitesShowNotification.vue').default);
Vue.component('tramites-show-status', require('./components/Proyectos/TramitesDependenciasStatus.vue').default);
Vue.component('edit-tramites-dependencias-modal', require('./components/Proyectos/EditTramitesDependenciasModal.vue').default);

// Fraccionamientos components
Vue.component('fraccionamientos-show-status', require('./components/Proyectos/FraccionamientosStatus.vue').default);
Vue.component('proyectos-concretados-index', require('./components/Proyectos/ProyectosConcretadosIndex.vue').default);
Vue.component('etapas-card', require('./components/Proyectos/EtapasCard.vue').default);
Vue.component('etapas-iniciar', require('./components/Proyectos/EtapasInitializate.vue').default);
Vue.component('etapas-modal', require('./components/Proyectos/EtapasCreate.vue').default);
Vue.component('etapas-edit', require('./components/Proyectos/EtapasEdit.vue').default);
Vue.component('etapas-index', require('./components/Proyectos/EtapasIndex.vue').default);
Vue.component('actividades-urbanizacion-etapa-form', require('./components/Etapas/ActividadesEtapaForm.vue').default);
Vue.component('actividades-obra-etapa-form', require('./components/Etapas/ActividadesEtapaObraForm.vue').default);
Vue.component('activities-per-block-component', require('./components/Etapas/ActivitiesPerBlockComponent.vue').default);
Vue.component('activities-per-lote-component', require('./components/Etapas/ActivitiesPerLoteComponent.vue').default);
Vue.component('activities-per-concepto-component', require('./components/Etapas/ActivitiesPerConceptoComponent.vue').default);
Vue.component('conseptos-rubros-portatil', require('./components/Proyectos/ConceptosRubrosPortatil.vue').default);
Vue.component('manage-materials-to-fraccionamientos-modal', require('./components/Proyectos/Fraccionamientos/ManageMaterialsToFraccionamientosModal.vue').default); // Request Materials
Vue.component('etapa-hours-calculation-modal', require('./components/Etapas/EtapaHoursCalculationModal.vue').default);
Vue.component('propuestas-proyectos-index', require('./components/Proyectos/Fraccionamientos/PropuestasDeFraccionamientoIndex.vue').default);
Vue.component('fraccionamiento-report-form', require('./components/Proyectos/Fraccionamientos/FraccionamientoReportForm.vue').default);
Vue.component('fraccionamiento-desglose-report', require('./components/Proyectos/Fraccionamientos/FraccionamientoReporteDesglose.vue').default);
Vue.component('show-materials-by-distribucion-modal', require('./components/Proyectos/Fraccionamientos/MaterialsOfDistribucionModal.vue').default);
Vue.component('fraccionamiento-ventas-form', require('./components/Proyectos/Fraccionamientos/VentasFraccionamientoIndex.vue').default);
Vue.component('add-prices-to-lotes-modal', require('./components/Proyectos/Fraccionamientos/AddPricesToLotesModal.vue').default);
Vue.component('archivos-lotes-modal', require('./components/Proyectos/Fraccionamientos/ArchivosLotesModal.vue').default);
Vue.component('upload-lotes-files', require('./components/Proyectos/Fraccionamientos/UploadLotesFilesModalComponent.vue').default);


// Incidencias components
Vue.component('incidencias-index', require('./components/Proyectos/IncidenciasIndex.vue').default);
Vue.component('incidencias-modal', require('./components/Proyectos/IncidenciasCreate.vue').default);

// Pruebas de Laboratorio components
Vue.component('pruebas-laboratorio-index', require('./components/Proyectos/PruebasLaboratorioIndex.vue').default);
Vue.component('pruebas-laboratorio-form', require('./components/Proyectos/PruebasLaboratorioForm.vue').default);
Vue.component('edit-pruebas-modal', require('./components/Proyectos/EditPruebasModal.vue').default);

// Facturas components
Vue.component('contability-list-invoice', require('./components/Facturas/InvoiceList.vue').default);
Vue.component('contability-create-invoice', require('./components/Facturas/InvoiceCreate.vue').default);

Vue.component('invoice-list', require('./components/Facturas/InvoiceList.vue').default);
Vue.component('invoice-create', require('./components/Facturas/InvoiceCreate.vue').default);
Vue.component('invoice-show-notification', require('./components/Facturas/InvoiceShowNotification.vue').default);
Vue.component('proveedores-index', require('./components/Facturas/ProveedoresIndex.vue').default);
Vue.component('proveedores-create', require('./components/Facturas/ProveedoresCreate.vue').default);

// Viviendas & valores components
Vue.component('households-manage', require('./components/Viviendas/HouseholdsManage.vue').default);
Vue.component('households-index', require('./components/Viviendas/HouseholdsIndex.vue').default);
Vue.component('create-households-modal', require('./components/Viviendas/CreateHouseholdModal.vue').default);
Vue.component('edit-households-modal', require('./components/Viviendas/EditHouseholdModal.vue').default);
Vue.component('values-household-index', require('./components/Viviendas/ValuesHouseholdIndex.vue').default);
Vue.component('set-values-modal', require('./components/Valores/SetValuesModal.vue').default);
Vue.component('manage-materials-to-value-modal', require('./components/Valores/ManageMaterialsToValueModal.vue').default);
Vue.component('index-material-value', require('./components/Valores/IndexMaterialValue.vue').default);
Vue.component('rubros-per-conceptos-modal', require('./components/Viviendas/RubrosPerConceptos.vue').default);
Vue.component('add-execution-time-modal', require('./components/Valores/AddExecutionTimeModal.vue').default);
Vue.component('add-execution-time-urbanizacion-modal', require('./components/Valores/AddExecutionTimeUrbanizacionModal.vue').default);
Vue.component('duplicate-household-modal', require('./components/Viviendas/DuplicateHouseholdModal.vue').default);
Vue.component('items-per-household', require('./components/Viviendas/ItemsPerHousehold.vue').default);
Vue.component('materials-per-household', require('./components/Viviendas/MaterialsPerPrototypeComponent.vue').default);

// Viviendas
Vue.component('houses-per-subdivisions-form', require('./components/Viviendas/housesPerSubdivisionsForm.vue').default);
Vue.component('manage-guarantees-to-houses-form', require('./components/Viviendas/ManageGuaranteesForm.vue').default);
Vue.component('add-guarantee-to-house-modal', require('./components/Viviendas/AddGuaranteeToHouseModal.vue').default);
Vue.component('edit-guarantee-to-house-modal', require('./components/Viviendas/EditGuaranteeToHouseModal.vue').default);

//Conceptos & Rubros Components
Vue.component('create-concepts-modal', require('./components/Viviendas/CreateConceptsModal.vue').default);
Vue.component('edit-concepts-modal', require('./components/Viviendas/EditConceptoModal.vue').default);
Vue.component('concepts-index', require('./components/Viviendas/ConceptsIndex.vue').default);

// Materials Components
Vue.component('materials-index', require('./components/Materiales/MaterialsIndex.vue').default);
Vue.component('create-material-modal', require('./components/Materiales/CreateMaterialsModal.vue').default);
Vue.component('edit-material-modal', require('./components/Materiales/EditMaterialModal.vue').default);
Vue.component('manage-material-modal', require('./components/Materiales/ManageMaterialModal.vue').default);

// Salidas de almacen components (DEPRECATED)
Vue.component('salidas-almacen-index', require('./components/Materiales/Salidas/SalidasAlmacenIndex.vue').default);
Vue.component('salidas-almacen-create', require('./components/Materiales/Salidas/SalidasAlmacenCreate.vue').default);
Vue.component('salidas-almacen-update', require('./components/Materiales/Salidas/SalidasAlmacenUpdate.vue').default);
Vue.component('manage-materials-to-salidas-almacen-modal', require('./components/Materiales/Salidas/ManageMaterialsToSalidasAlmacenModal.vue').default);
Vue.component('index-material-salidas-almacen', require('./components/Materiales/Salidas/IndexMaterialSalidasAlmacen.vue').default);
Vue.component('requisiciones-create', require('./components/Materiales/Salidas/RequisicionesCreate.vue').default);
Vue.component('requisiciones-asociadas-modal', require('./components/Materiales/Salidas/RequisicionesAsociadas.vue').default);

// Solicitud de material
Vue.component('solicitud-de-material-create-modal', require('./components/Solicitudes/CreateSolicitudForm.vue').default);

// Requisiciones Compnents
Vue.component('requisiciones-index', require('./components/Materiales/Requisiciones/RequisicionesIndex.vue').default);
Vue.component('requisiciones-modal-create', require('./components/Materiales/Requisiciones/RequisicionesModalCreate.vue').default);
Vue.component('requisiciones-modal-update', require('./components/Materiales/Requisiciones/RequisicionesModalUpdate.vue').default);
Vue.component('requisiciones-edit-form', require('./components/Materiales/Requisiciones/RequisicionesEditForm.vue').default);
Vue.component('manage-requisitions-to-requisitions-modal', require('./components/Materiales/Requisiciones/ManageRequisitionsToRequisitionsModal.vue').default);
Vue.component('index-requisitions', require('./components/Materiales/Requisiciones/IndexRequisitions.vue').default);
Vue.component('add-provider-to-requisitions', require('./components/Materiales/Requisiciones/AddProviderToRequisicion.vue').default);
Vue.component('shopping-requisiciones-modal', require('./components/Materiales/Requisiciones/ShoppingRequisicionesModal.vue').default);

// Categorias components
Vue.component('categories-index', require('./components/Categorias/CategoriesIndex.vue').default);
Vue.component('create-category-modal', require('./components/Categorias/CreateCategoryModal.vue').default);
Vue.component('edit-category-modal', require('./components/Categorias/EditCategoryModal.vue').default);

// Trabajadores component
Vue.component('workers-index', require('./components/Trabajadores/TrabajadoresIndex.vue').default);
Vue.component('create-workers-modal', require('./components/Trabajadores/CreateTrabajadoresModal.vue').default);
Vue.component('edit-worker', require('./components/Trabajadores/EditTrabajadores.vue').default);
Vue.component('payment-worker-modal', require('./components/Trabajadores/PaymentWorkerModal.vue').default);
Vue.component('payments-index', require('./components/Trabajadores/PagosIndex.vue').default);
Vue.component('save-contracts-workers-modal', require('./components/Trabajadores/SaveContratosTrabajadoresModal.vue').default);
Vue.component('active-worker-modal', require('./components/Trabajadores/ActiveWorkerModal.vue').default);
Vue.component('renovate-worker-modal', require('./components/Trabajadores/RenovateModal.vue').default);

// Pagos component
Vue.component('add-payment-modal', require('./components/Pagos/CreatePaymentModal.vue').default);
Vue.component('edit-payment-modal', require('./components/Pagos/EditPaymentModal.vue').default);

// Planes components
Vue.component('viviendas-container', require('./components/Planes/ViviendasContainer.vue').default);
Vue.component('plan-obras-container', require('./components/Planes/PlanObraContainer.vue').default);
Vue.component('impuestos-container', require('./components/Planes/ImpuestosContainer.vue').default);

// Maquinaria components
Vue.component('maquinaria-index', require('./components/Maquinaria/Maquinas/MaquinariaIndex.vue').default);
Vue.component('maquinaria-modal-create', require('./components/Maquinaria/Maquinas/MaquinariaModalCreate.vue').default);
Vue.component('maquinaria-modal-update', require('./components/Maquinaria/Maquinas/MaquinariaModalUpdate.vue').default);
Vue.component('manage-project-maquinaria-modal', require('./components/Maquinaria/Maquinas/ManageProjectMaquinariaModal.vue').default);

// Catalogo Mantenimientos components
Vue.component('catalogo-mantenimientos-index', require('./components/Maquinaria/CatalogoMantenimientos/CatalogoMantenimientosIndex.vue').default);
Vue.component('catalogo-mantenimientos-modal-create', require('./components/Maquinaria/CatalogoMantenimientos/CatalogoMantenimientosModalCreate.vue').default);
Vue.component('catalogo-mantenimientos-modal-update', require('./components/Maquinaria/CatalogoMantenimientos/CatalogoMantenimientosModalUpdate.vue').default);
Vue.component('manage-materials-to-mantenimiento-modal', require('./components/Maquinaria/CatalogoMantenimientos/ManageMaterialsToMantenimientoModal.vue').default);
Vue.component('index-material-mantenimiento', require('./components/Maquinaria/CatalogoMantenimientos/IndexMaterialMantenimiento.vue').default);

// Mantenimientos components
Vue.component('mantenimientos-index', require('./components/Maquinaria/Mantenimientos/MantenimientosIndex.vue').default);
Vue.component('mantenimientos-modal-create', require('./components/Maquinaria/Mantenimientos/MantenimientosModalCreate.vue').default);
Vue.component('mantenimientos-modal-update', require('./components/Maquinaria/Mantenimientos/MantenimientosModalUpdate.vue').default);
Vue.component('mantenimientos-show-notification', require('./components/Maquinaria/Mantenimientos/MantenimientosShowNotification.vue').default);
Vue.component('manage-requisitions-to-mantenimiento-modal', require('./components/Maquinaria/Mantenimientos/ManageRequisitionsToMantenimientoModal.vue').default);
Vue.component('index-requisitions-mantenimiento', require('./components/Maquinaria/Mantenimientos/IndexRequisitionsMantenimiento.vue').default);

// Clientes components
Vue.component('clientes-index', require('./components/Clientes/ClientesIndex.vue').default);
Vue.component('cliente-modal', require('./components/Clientes/ClientesModal.vue').default);
Vue.component('client-files-modal', require('./components/Clientes/ArchivosClientesModal.vue').default);
Vue.component('edit-customer-modal', require('./components/Clientes/EditCustomerModal.vue').default);
Vue.component('upload-client-files-modal', require('./components/Clientes/UploadClientesFilesModalComponent.vue').default);

// Inmobiliarias components
Vue.component('inmobiliarias-index', require('./components/Inmobiliarias/InmobiliariasIndex.vue').default);
Vue.component('inmobiliaria-modal', require('./components/Inmobiliarias/InmobiliariasModal.vue').default);

// Comentarios components
Vue.component('manage-comments-modal', require('./components/Comentarios/ManageComments.vue').default);

//Ventas components
Vue.component('ventas-promociones-fraccionamientos-index', require('./components/Ventas/Promociones/PromocionFraccionamientoIndex.vue').default);
Vue.component('ventas-promociones-fraccionamientos-modal', require('./components/Ventas/Promociones/PromocionFraccionamientoModal.vue').default);
Vue.component('cliente-promocion-modal', require('./components/Ventas/Promociones/ClientePromocion.vue').default);
Vue.component('promocion-fraccionamiento-form', require('./components/Ventas/Promociones/PromocionFraccionamientoForm.vue').default);
Vue.component('add-customers-to-lote-promocion-modal', require('./components/Ventas/Promociones/AddCustomersToLote.vue').default);
Vue.component('promocion-fraccionamiento-pagos', require('./components/Ventas/Promociones/PromocionFraccionamientoPagos.vue').default);
Vue.component('add-pagos-to-lote-modal', require('./components/Ventas/Promociones/AddPagosToLoteModal.vue').default);
Vue.component('edit-pago-to-lote-modal', require('./components/Ventas/Promociones/EditPaymentModal.vue').default);
Vue.component('finish-purchase-modal', require('./components/Ventas/Promociones/FinishPurchaseModal.vue').default);
Vue.component('refund-pagos-to-lote-modal', require('./components/Ventas/Promociones/RefundPaymentModal.vue').default);
Vue.component('add-avaluo-to-venta-modal', require('./components/Ventas/Promociones/AddAvaluoValueModal.vue').default);
Vue.component('deliver-lote-modal', require('./components/Ventas/Promociones/DeliverLotModal.vue').default);
Vue.component('add-customers-to-sale-modal', require('./components/Ventas/Promociones/AddCustomersToSale.vue').default);

//Vendedores components
Vue.component('vendedores-index', require('./components/Vendedores/VendedoresIndex.vue').default);
Vue.component('vendedor-modal', require('./components/Vendedores/VendedoresModal.vue').default);

//Ventas component
Vue.component('ventas-modal', require('./components/Ventas/VentasIndex.vue').default);

// Orden de material components
Vue.component('orden-index', require('./components/Ordenes/OrdenIndex.vue').default);
Vue.component('create-orden-form', require('./components/Ordenes/CreateOrdenForm.vue').default);
Vue.component('show-orden-modal', require('./components/Ordenes/ShowOrdenModal.vue').default);

//multifunctional components
Vue.component('searcher-component', require('./components/Searcher.vue').default);
Vue.component('searcher', require('./components/NewSearcher.vue').default);
Vue.component('input-currency-mask', require('./components/InputCurrencyMask.vue').default);

// Show All notifications Components
Vue.component('show-all-notifications', require('./components/Notificaciones/ShowAllNotifications.vue').default);

// Viviendas Components
Vue.component('viviendas-index', require('./components/Proyectos/ViviendasIndex.vue').default);
Vue.component('viviendas-garantias-modal', require('./components/Proyectos/ViviendasGaratias.vue').default);
Vue.component('viviendas-garantias-modal-estados', require('./components/Proyectos/ViviendasGaratiasEstados.vue').default);

// Recepcion Components
Vue.component('recepcion-index', require('./components/Recepcion/RecepcionIndex.vue').default);
Vue.component('supply-recepcion-material-modal', require('./components/Recepcion/SupplyMaterialModal.vue').default);
Vue.component('add-missing-material-modal', require('./components/Recepcion/AddMissingMaterialModal.vue').default);

// Estados Components
Vue.component('manage-estados-modal-component', require('./components/Proyectos/ManageEstadosModal.vue').default);

// Nominas Components
Vue.component('nominas-index', require('./components/Nominas/NominaIndex.vue').default);
Vue.component("save-nominas-modal", require("./components/Nominas/SaveNominaModal.vue").default);
Vue.component("show-areas-nomina", require("./components/Nominas/ShowAreasNomina.vue").default);
Vue.component("prima-vacacional-modal", require("./components/Nominas/PrimaVacacionalModal.vue").default);
Vue.component("areas-nominas-index-component", require("./components/Nominas/AreasNominasIndex.vue").default);

Vue.component("edit-area-nomina-trabajadores", require("./components/Nominas/EditAreaNominaTrabajadores.vue").default);
Vue.component("trabajadores-modal", require("./components/Nominas/TrabajadoresNominaModal.vue").default);
Vue.component("save-nomina-trabajador-modal", require("./components/Nominas/SavePagoNominaTrabajadorModal.vue").default);

// Etapas Components
Vue.component("create-etapa-urbanizacion-form", require("./components/Etapas/CreateEtapaForm.vue").default);
Vue.component("create-etapa-obra-form", require("./components/Etapas/CreateObraEtapaForm.vue").default);
Vue.component("add-lotes-to-etapa-modal", require("./components/Etapas/addLotesToEtapasModal.vue").default);
Vue.component("show-etapa-form", require("./components/Etapas/ShowEtapaForm.vue").default);
Vue.component("show-etapa-urbanizacion-form", require("./components/Etapas/ShowEtapaUrbanizacionForm.vue").default);
Vue.component("etapa-distribucion-concepto-form", require("./components/Etapas/EtapasDistribucionConcepto.vue").default);
Vue.component("detalles-etapa-form", require("./components/Etapas/DetallesEtapaForm.vue").default);
Vue.component("manage-lotes-to-etapas-form", require("./components/Etapas/ManageLotesToEtapaForm.vue").default);
Vue.component("etapa-distribucion-materiales-form", require("./components/Etapas/EtapaDistribucionMateriales.vue").default);

// Perfil Components
Vue.component("perfil-manage-form", require("./components/Perfil/PerfilManage.vue").default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.prototype.$bus = new Vue({})

import Vuetify from 'vuetify';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import VueCurrencyFilter from 'vue-currency-filter';
import '@fortawesome/fontawesome-free/css/all.css'


let configurationVuetify = {
  theme: {
    verdeFayal: "#1ABA24",
  },
  iconfont: 'fa'
};

Vue.use(Vuetify, configurationVuetify)
Vue.use(Vuex)
Vue.use(VueRouter);

Vue.component('pagination', require('laravel-vue-pagination'));
Vue.use(VueCurrencyFilter, {
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: true
})

function initialState() {
  return {
    urlFileUpload: '',
    loaded: 0,
    fileName: '',
    uuidFile: '',
    mini: true,
    saveDisabled: false,
    urlFileUploaded: null,
  }
}

const store = new Vuex.Store({
  state: initialState,

  mutations: {
    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    returnUrl(state, url) {
      state.urlFileUpload = url;
    },
    returnUrlField(state, url) {
      state.urlFileUploaded = url;
    },
    uploaded(state, loaded) {
      state.loaded = loaded;
    },
    file(state, fileName) {
      state.fileName = fileName;
    },
    uuidFile(state, uuidFile) {
      state.uuidFile = uuidFile;
    },
    disabled(state, bool) {
      state.saveDisabled = bool;
    },
    showOrHiddenSidebar(state) {
      state.mini = !state.mini
    }
  }
});

const router = new VueRouter({
  routes: [],
  mode: 'history'
})

const app = new Vue({
  el: '#app',
  store,
  router,
  data() {
    return {
      bucket: new AWS.S3({
        accessKeyId: process.env.MIX_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.MIX_AWS_SECRET_ACCESS_KEY,
        region: process.env.MIX_AWS_DEFAULT_REGION,
        params: { Bucket: `${process.env.MIX_AWS_BUCKET}` }
      })
    }
  },

  methods: {
    can: function (permissionName, permissions) {
      return permissions[permissionName] ? true : false;
    },

    canAtLeast: function (permissionsArray, permissions) {
      return permissionsArray.some(permission => this.can(permission, permissions));
    },

    cleanStore: function () {
      store.commit('reset');
    },

    //Script aws subida de archivos
    awsuploader: function (file) {
      var upFile = file;
      if (upFile) {
        let idFile = uuid.v4();

        store.commit('reset');
        store.commit('disabled', true);
        store.commit('file', upFile.name);
        store.commit('uuidFile', idFile);

        var uploadParams = { Key: `${idFile}-${upFile.name}`, ContentType: upFile.type, Body: upFile };
        this.bucket.upload(uploadParams, function (err, data) {
          store.commit('returnUrl', data.Location);
        }).on('httpUploadProgress', function (evt) {
          store.commit('uploaded', parseInt((evt.loaded * 100) / evt.total));
        });
      }
    },

    awsUploaderByInputFile: function (file) {
      var upFile = file;
      if (upFile) {
        let idFile = uuid.v4();

        store.commit('disabled', true);
        store.commit('file', upFile.name);
        store.commit('uuidFile', idFile);

        var uploadParams = { Key: `${idFile}-${upFile.name}`, ContentType: upFile.type, Body: upFile };
        
        this.bucket.upload(uploadParams, function (err, data) {
          store.commit('returnUrlField', data.Location);
        }).on('httpUploadProgress', function (evt) {
          store.commit('uploaded', parseInt((evt.loaded * 100) / evt.total));
        });
      }
    },


    awseraser: function (file) {
      var deleteFile = file;
      if (deleteFile) {
        var eraseParams = { Key: `${deleteFile.aws_uuid}-${deleteFile.filename}` };
        this.bucket.deleteObject(eraseParams, function (err, data) {
          if (err) console.log(err, err.stack); // an error occurred
        });
      }
    }
  },

  computed: {
    urlFileCheck() {
      return this.$store.state.urlFileUpload;
    }
  },

  watch: {
    urlFileCheck() {
      if (this.urlFileCheck != '') {
        store.commit('disabled', false);
      }
    }
  },
});


// Register a global custom directive called `v-focus`
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
})

// Register a global custom directive called `v-init`
Vue.directive('init', {
  bind: function (el, binding, vnode) {
    vnode.context[binding.arg] = binding.value
  }
})