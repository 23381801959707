var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { flat: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "", icon: "", href: "/requisiciones" }
                        },
                        [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_vm._v(" "), _c("span", [_vm._v("Regresar")])]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "pb-2": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs10: "", "offset-xs1": "" } },
            [
              _c(
                "v-card",
                { staticClass: "card--flex-toolbar" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-toolbar-title", { staticClass: "primary-title" }, [
                        _vm._v("Administrar requisición")
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            slot: "extension",
                            centered: "",
                            color: "white",
                            "slider-color": "verdeFayal"
                          },
                          slot: "extension",
                          model: {
                            value: _vm.vTabs,
                            callback: function($$v) {
                              _vm.vTabs = $$v
                            },
                            expression: "vTabs"
                          }
                        },
                        [
                          _c(
                            "v-tab",
                            { attrs: { href: "#tab-edit-requisicion" } },
                            [_vm._v("Datos de la requisición")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab",
                            { attrs: { href: "#tab-index-material" } },
                            [_vm._v("Lista de materiales")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.vTabs,
                            callback: function($$v) {
                              _vm.vTabs = $$v
                            },
                            expression: "vTabs"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            { attrs: { value: "tab-edit-requisicion" } },
                            [
                              _c(
                                "v-container",
                                { attrs: { "grid-list-md": "" } },
                                [
                                  _c(
                                    "v-layout",
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              required: "",
                                              label: "Nombre de la orden"
                                            },
                                            model: {
                                              value:
                                                _vm.localRequisicion
                                                  .nombre_requisicion,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.localRequisicion,
                                                  "nombre_requisicion",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localRequisicion.nombre_requisicion"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.solicitante,
                                              required: "",
                                              label: "Seleccione solicitante"
                                            },
                                            model: {
                                              value:
                                                _vm.localRequisicion
                                                  .solicitante,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.localRequisicion,
                                                  "solicitante",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localRequisicion.solicitante"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              disabled: "",
                                              required: "",
                                              label: "Estado"
                                            },
                                            model: {
                                              value:
                                                _vm.localRequisicion.estado
                                                  .information.estado,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.localRequisicion.estado
                                                    .information,
                                                  "estado",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localRequisicion.estado.information.estado"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.localRequisicion.estado.information
                                        .estado == "Aprobada"
                                        ? _c(
                                            "v-flex",
                                            { attrs: { md6: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  type: "date",
                                                  required: "",
                                                  label: "Fecha de llegada"
                                                },
                                                model: {
                                                  value:
                                                    _vm.localRequisicion
                                                      .fecha_de_llegada,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.localRequisicion,
                                                      "fecha_de_llegada",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "localRequisicion.fecha_de_llegada"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              required: "",
                                              label: "Descripcion"
                                            },
                                            model: {
                                              value:
                                                _vm.localRequisicion
                                                  .descripcion,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.localRequisicion,
                                                  "descripcion",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "localRequisicion.descripcion"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            { attrs: { value: "tab-index-material" } },
                            [
                              _c(
                                "v-container",
                                { attrs: { "grid-list-md": "" } },
                                [
                                  _c(
                                    "v-layout",
                                    [
                                      _c(
                                        "v-data-table",
                                        {
                                          attrs: {
                                            items: _vm.materiales,
                                            "hide-actions": ""
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "items",
                                              fn: function(props) {
                                                return [
                                                  _c("tr", [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.item.proveedor
                                                            ? props.item
                                                                .proveedor
                                                                .nombre_completo
                                                            : "Aún sin proveedor"
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getStatus(
                                                            props.item
                                                          )
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.item.material
                                                            .categoria_asociada
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.item.material
                                                            .nombre
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.item.cantidad
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm._v(
                                                        "$ " +
                                                          _vm._s(
                                                            _vm._f("currency")(
                                                              props.item
                                                                .material.precio
                                                            )
                                                          )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm._v(
                                                        "$ " +
                                                          _vm._s(
                                                            _vm._f("currency")(
                                                              props.item
                                                                .material
                                                                .precio *
                                                                props.item
                                                                  .cantidad
                                                            )
                                                          )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      [
                                                        _c(
                                                          "v-layout",
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      slot:
                                                                        "activator",
                                                                      icon: "",
                                                                      flat: "",
                                                                      dark: "",
                                                                      small: "",
                                                                      color:
                                                                        "verdeFayal"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.openShoppingModal(
                                                                          props
                                                                            .item
                                                                            .material
                                                                            .id
                                                                        )
                                                                      }
                                                                    },
                                                                    slot:
                                                                      "activator"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "shopping_cart"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Añadir datos de venta"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      slot:
                                                                        "activator",
                                                                      icon: "",
                                                                      flat: "",
                                                                      dark: "",
                                                                      small: "",
                                                                      disabled:
                                                                        _vm.getStatus(
                                                                          props.item
                                                                        ) !=
                                                                        "Pendiente de surtir",
                                                                      color:
                                                                        "error"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteMaterialValue(
                                                                          props
                                                                            .item
                                                                            .material
                                                                            .id
                                                                        )
                                                                      }
                                                                    },
                                                                    slot:
                                                                      "activator"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "delete"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Eliminar"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c("template", { slot: "headers" }, [
                                            _c("td", [_vm._v("Proveedor")]),
                                            _vm._v(" "),
                                            _c("td", [_vm._v("Estado")]),
                                            _vm._v(" "),
                                            _c("td", [_vm._v("Categoria")]),
                                            _vm._v(" "),
                                            _c("td", [_vm._v("Material")]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v("Cantidad requerida")
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v("Precio unitario")
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [_vm._v("Total")]),
                                            _vm._v(" "),
                                            _c("td", [_vm._v("Acciones")])
                                          ])
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _vm.vTabs == "tab-edit-requisicion"
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                color: "verdeFayal",
                                outline: "",
                                flat: "",
                                loading: _vm.vBtnSave.loading
                              },
                              on: { click: _vm.updateRequisicion }
                            },
                            [_vm._v(" Guardar ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.vSnackBar.text) + "\n        "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("shopping-requisiciones-modal", {
        attrs: {
          show: _vm.showShoppingModal,
          "material-id": _vm.currentMaterialId,
          "requisicion-id": _vm.requisicion.id
        },
        on: {
          "update:show": function($event) {
            _vm.showShoppingModal = $event
          },
          "update:materialId": function($event) {
            _vm.currentMaterialId = $event
          },
          "update:material-id": function($event) {
            _vm.currentMaterialId = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }