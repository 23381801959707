var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      [
        _c(
          "v-btn",
          {
            staticClass: "fyl-btnCreate",
            attrs: { id: "provider-modal-button" },
            on: {
              click: function($event) {
                return _vm.$emit("open")
              }
            }
          },
          [_vm._v("Crear")]
        )
      ],
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "justify-center": "" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { value: _vm.dialog, persistent: "", "max-width": "700px" }
            },
            [
              _c("v-card", [
                _c(
                  "form",
                  [
                    _c(
                      "v-toolbar",
                      { attrs: { color: "white", flat: "" } },
                      [
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-toolbar-title",
                          { staticClass: "primary-title" },
                          [
                            _vm._v(
                              _vm._s(this.proveedor.id ? "Editar" : "Crear") +
                                " proveedor\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              flat: "",
                              icon: "",
                              color: "verdeFayal",
                              dark: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.$emit("close")
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v("close")])],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-container",
                          { attrs: { "grid-list-md": "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { wrap: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm6: "", md6: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        color: "#1ABA24",
                                        label: "Nombre completo del proveedor"
                                      },
                                      model: {
                                        value: _vm.proveedor.nombre_completo,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.proveedor,
                                            "nombre_completo",
                                            $$v
                                          )
                                        },
                                        expression: "proveedor.nombre_completo"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm6: "", md6: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        color: "#1ABA24",
                                        label: "Acrónimo"
                                      },
                                      model: {
                                        value: _vm.proveedor.acronimo,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.proveedor,
                                            "acronimo",
                                            $$v
                                          )
                                        },
                                        expression: "proveedor.acronimo"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm6: "", md6: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        color: "#1ABA24",
                                        label: "Nombre vendedor"
                                      },
                                      model: {
                                        value: _vm.proveedor.nombre_vendedor,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.proveedor,
                                            "nombre_vendedor",
                                            $$v
                                          )
                                        },
                                        expression: "proveedor.nombre_vendedor"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm6: "", md6: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        color: "#1ABA24",
                                        label: "Teléfono de oficina"
                                      },
                                      model: {
                                        value: _vm.proveedor.telefono_oficina,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.proveedor,
                                            "telefono_oficina",
                                            $$v
                                          )
                                        },
                                        expression: "proveedor.telefono_oficina"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { md4: "", sm4: "" } },
                                  [
                                    _c("v-switch", {
                                      staticStyle: { "margin-top": "0" },
                                      attrs: {
                                        label: "Cambiar RFC",
                                        color: "verdeFayal"
                                      },
                                      model: {
                                        value: _vm.changeRFC,
                                        callback: function($$v) {
                                          _vm.changeRFC = $$v
                                        },
                                        expression: "changeRFC"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { md8: "", sm8: "" } },
                                  [
                                    _vm.changeRFC == true
                                      ? _c(
                                          "v-layout",
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  xs12: "",
                                                  sm12: "",
                                                  md12: ""
                                                }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "rfc-input",
                                                  attrs: {
                                                    color: _vm.getRfcPassed
                                                      ? "#1ABA24"
                                                      : "red",
                                                    label:
                                                      "RFC de persona fisica"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.proveedor.rfc_fisicas,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.proveedor,
                                                        "rfc_fisicas",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "proveedor.rfc_fisicas"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _c(
                                          "v-layout",
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  xs12: "",
                                                  sm12: "",
                                                  md12: ""
                                                }
                                              },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "rfc-input",
                                                  attrs: {
                                                    color: _vm.getRfcPassed2
                                                      ? "#1ABA24"
                                                      : "red",
                                                    label:
                                                      "RFC de persona moral"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.proveedor.rfc_morales,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.proveedor,
                                                        "rfc_morales",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "proveedor.rfc_morales"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm12: "", md12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        color: "#1ABA24",
                                        label: "Telefono de vendedor"
                                      },
                                      model: {
                                        value: _vm.proveedor.telefono_vendedor,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.proveedor,
                                            "telefono_vendedor",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "proveedor.telefono_vendedor"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm12: "", md12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        color: "#1ABA24",
                                        label: "Correo electrónico"
                                      },
                                      model: {
                                        value: _vm.proveedor.correo,
                                        callback: function($$v) {
                                          _vm.$set(_vm.proveedor, "correo", $$v)
                                        },
                                        expression: "proveedor.correo"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm12: "", md12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        color: "#1ABA24",
                                        label: "Dirección"
                                      },
                                      model: {
                                        value: _vm.proveedor.direccion,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.proveedor,
                                            "direccion",
                                            $$v
                                          )
                                        },
                                        expression: "proveedor.direccion"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "", sm6: "", md6: "" } },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        color: "#1ABA24",
                                        items: _vm.items,
                                        "item-text": "nombre",
                                        "item-value": "dias",
                                        label: "Seleccione tipo de crédito"
                                      },
                                      model: {
                                        value: _vm.proveedor.tipo_credito,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.proveedor,
                                            "tipo_credito",
                                            $$v
                                          )
                                        },
                                        expression: "proveedor.tipo_credito"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs6: "", md6: "", sm6: "" } },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        items: _vm.categories,
                                        "item-value": "id",
                                        "item-text": "nombre",
                                        label: "Categoría",
                                        "single-line": "",
                                        multiple: ""
                                      },
                                      model: {
                                        value: _vm.proveedor.categorias,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.proveedor,
                                            "categorias",
                                            $$v
                                          )
                                        },
                                        expression: "proveedor.categorias"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _vm._v(" "),
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              dark: "",
                              color: "verdeFayal",
                              flat: "",
                              disabled: _vm.$store.state.saveDisabled
                            },
                            on: { click: _vm.formatProveedor }
                          },
                          [_vm._v("\n              Guardar ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }