<template>
  <v-layout>
    <v-container>
      <v-card>
        <v-flex xs12>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Clientes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              color="verdeFayal"
              single-line
              label="Buscar"
              append-icon="search"
              v-model="search"
            ></v-text-field>
            <v-btn class="verdeFayal" @click="showCreateCustomerModal =  true" dark> Crear </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            disable-initial-sort
            :items="clientes.data"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.nombre }} {{ props.item.apellido_paterno }} {{ props.item.apellido_materno }}</td>
              <td>{{ props.item.fecha_aprobacion }}</td>
              <td>{{ props.item.telefono }}</td>
              <td>{{ props.item.email }}</td>
              <td>
                <v-layout>
                  <v-tooltip bottom>
                    <v-btn
                      @click="EditCliente(props.item.id)"
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="info"
                      :disabled="!$root.can('update', permissions)"
                    >
                      <v-icon color="verdeFayal">edit</v-icon>
                    </v-btn>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      :disabled="!$root.can('files', permissions)"
                      flat
                      icon
                      small
                      slot="activator"
                      color="warning"
                      @click="openFilesModal(props.item.id, props.item.archivos)"
                    >
                      <v-icon>folder</v-icon>
                    </v-btn>
                    <span>Archivos</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      @click="deleteCliente(props.item.id)"
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="error"
                      :disabled="!$root.can('remove', permissions)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <span>Eliminar</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination
              :limit="vPaginator.limit"
              :data="clientes"
              @pagination-change-page="getClientes"
            ></pagination>
          </v-card-actions>
        </v-flex>
      </v-card>
      <!-- modals -->
    </v-container>
    <edit-customer-modal :show.sync="showEditCustomerModal"  :cliente-id="currentClienteId"/>
    <client-files-modal
      element_type="App\Cliente"
      :element_id="elementId"
      :show.sync="dialogFile"
      :permissions="permissions"
      :personal_files="(personalFiles.length) ? personalFiles : []"
    ></client-files-modal>
    <cliente-modal 
      v-if="$root.can('new', permissions) ? true : false"
      :show.sync="showCreateCustomerModal"
    />
  </v-layout>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import { errorHandling, laravelRoute } from "../../lib/utilities";

export default {
  props: ["permissions"],
  data() {
    return {
      search: null,
      dialogFile: false,
      dialog: false,
      elementId: "",
      personalFiles: "",
      estados: [],
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Fecha de aprobación", value: "fecha_aprobacion" },
        { text: "Teléfono", value: "telefono" },
        { text: "Correo", value: "email" },
        { text: "Acciones" }
      ],
      currentClienteId: null,
      showCreateCustomerModal: false,
      showEditCustomerModal: false,
      vPaginator: {
        limit: 10
      },
      cliente: {},
      clientes: {
        data: []
      }
    };
  },

  mounted() {
    this.getClientes();
  },

  watch: {
    showCreateCustomerModal: function(value) {
      this.getClientes();
    },
    showEditCustomerModal: function(value) {
      this.getClientes();
    },
    search: function(value) {
      this.getClientes();
    },
  },

  methods: {
    cleanForm() {
      this.cliente = {};
      this.dialog = false;
    },
    getClientes: function(page = 1) {
      axios
        .get(`/clientes?page=${page}&query=${this.search}`)
        .then(response => {
          this.clientes = response.data;
        })
        .catch(error => errorHandling(error));
    },
    deleteCliente: function(clientesId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios
            .delete(laravelRoute("clientes.destroy", clientesId))
            .then(response => {
              this.getClientes();
              alertify.success(response.data.message_text);
              setTimeout(() => {
                window.location.href = "/clientes";
              }, 300);
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      });
    },
    EditCliente: function(clienteId) {
      this.currentClienteId = clienteId;
      this.showEditCustomerModal = true;
    },
    openFilesModal: function(clienteId, personalFiles) {
      this.currentClienteId = clienteId;
      this.personalFiles = personalFiles;
      this.dialogFile = true;
    }
  }
};
</script>