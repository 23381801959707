var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Nuevo plan de trabajo de urbanización")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-subheader", [
                                _vm._v(
                                  "Datos del plan de trabajo de urbanización"
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { required: "", label: "Nombre" },
                                model: {
                                  value: _vm.nombre,
                                  callback: function($$v) {
                                    _vm.nombre = $$v
                                  },
                                  expression: "nombre"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  required: "",
                                  label: "Descripción",
                                  counter: "250",
                                  maxlength: _vm.max
                                },
                                model: {
                                  value: _vm.descripcion,
                                  callback: function($$v) {
                                    _vm.descripcion = $$v
                                  },
                                  expression: "descripcion"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-subheader", [_vm._v("Conceptos asociados")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("searcher-component", {
                                attrs: { configuration: _vm.searchConfig },
                                on: { itemSelected: _vm.selected }
                              }),
                              _vm._v(" "),
                              _c("v-data-table", {
                                staticClass: "elevation-1",
                                attrs: {
                                  headers: _vm.vDataTable.headers,
                                  items: _vm.items,
                                  "item-key": "name",
                                  "disable-initial-sort": "",
                                  "no-data-text": "Sin rubros a asignar"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "items",
                                    fn: function(props) {
                                      return [
                                        _c(
                                          "tr",
                                          {
                                            attrs: { active: props.selected },
                                            on: {
                                              click: function($event) {
                                                props.selected = !props.selected
                                              }
                                            }
                                          },
                                          [
                                            _c("td", [
                                              _vm._v(_vm._s(props.item.nombre))
                                            ]),
                                            _vm._v(" "),
                                            !!props.item.rubros.length
                                              ? _c(
                                                  "td",
                                                  _vm._l(
                                                    _vm.getRubros.filter(
                                                      function(rubro) {
                                                        return (
                                                          rubro.concepto_id ==
                                                          props.item.id
                                                        )
                                                      }
                                                    ),
                                                    function(rubro) {
                                                      return _c(
                                                        "v-chip",
                                                        {
                                                          key: rubro.id,
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            color: "verdeFayal",
                                                            outline: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.manageRubro(
                                                                rubro,
                                                                true
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-avatar",
                                                            [
                                                              _c("v-icon", [
                                                                _vm._v(
                                                                  "check_circle"
                                                                )
                                                              ])
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                rubro.nombre
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "ma-2",
                                                        attrs: {
                                                          color: "error",
                                                          outline: "",
                                                          small: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      Concepto sin rubros.\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                            _vm._v(" "),
                                            !!_vm.getRubros.filter(function(
                                              rubro
                                            ) {
                                              return (
                                                rubro.concepto_id ==
                                                  props.item.id &&
                                                rubro.selected == true
                                              )
                                            }).length
                                              ? _c(
                                                  "td",
                                                  {
                                                    staticClass: "text-xs-right"
                                                  },
                                                  _vm._l(
                                                    _vm.getRubros.filter(
                                                      function(rubro) {
                                                        return (
                                                          rubro.concepto_id ==
                                                            props.item.id &&
                                                          rubro.selected == true
                                                        )
                                                      }
                                                    ),
                                                    function(rubro) {
                                                      return _c(
                                                        "v-chip",
                                                        {
                                                          key: rubro.id,
                                                          staticClass: "ma-2",
                                                          attrs: {
                                                            color: "verdeFayal",
                                                            outline: "",
                                                            close: ""
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              return _vm.manageRubro(
                                                                rubro,
                                                                false
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                rubro.nombre
                                                              ) +
                                                              "\n                    "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                )
                                              : _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        staticClass: "ma-2",
                                                        attrs: {
                                                          color: "error",
                                                          outline: "",
                                                          small: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      Sin rubros seleccionados.\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: { click: _vm.storeHousingDeleopment }
                    },
                    [_vm._v(" Guardar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }