<template>
  <v-layout>
    <v-btn class="fyl-btnCreate" v-on:click="$emit('open')">Crear</v-btn>
    <v-dialog :value="dialog" persistent max-width="700px">
      <v-card>
        <form action v-on:submit.prevent.once="vendedorCreate()">
          <v-card-title>
            <h3 class="card-title">{{ this.vendedor.id ? "Editar" : "Crear" }} Vendedor</h3>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex md4>
                  <v-text-field label="Nombre" v-model="vendedor.nombre" required></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field label="Apellido paterno" v-model="vendedor.apellido_paterno" required></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field label="Apellido materno" v-model="vendedor.apellido_materno"></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field label="Teléfono" v-model="vendedor.telefono" required></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field
                    label="Correo"
                    v-model="vendedor.email"
                    :rules="[v => /.+@.+\..+/.test(v) || 'Ingresar correo valido']"
                  ></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-autocomplete :items="inmobiliarias.data" item-value="id" item-text="inmobiliaria" v-model="vendedor.inmobiliaria_id" label="Inmobiliaria" single-line></v-autocomplete>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="fyl-btnClose" flat @click="$emit('close')">Cerrar</v-btn>
            <v-btn
              type="submit"
              class="fyl-btnSave"
              :disabled="$store.state.saveDisabled"
              flat
            >Guardar</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { errorHandling } from "../../lib/utilities";
export default {
  props: ["vendedor", "vendedores", "dialog"],

  data() {
    return {
      inmobiliarias: [],
    };
  },
  methods: {
    vendedorCreate() {
      if (this.vendedor.id) {
        axios
          .patch(route("vendedores.update", this.vendedor.id), this.vendedor)
          .then(response => {
            if (response.data.message_text)
              alertify.success(response.data.message_text);
            this.$emit("close");
          })
          .catch(error => errorHandling(error))
          .finally(() => (window.location.href = "/vendedores"));
      } else {
        axios
          .post("/vendedores", this.vendedor)
          .then(response => {
            if (response.data.message_text)
              alertify.success(response.data.message_text);
            this.$emit("close");
          })
          .catch(error => errorHandling(error))
          .finally(() => (window.location.href = "/vendedores"));
      }
    },
    getInmobiliarias: function () {
      axios.get(laravelRoute('inmobiliarias.index'))
      .then(response => this.inmobiliarias = response.data)
      .catch(error => errorHandling(error));
    }
  },
  mounted() {
    this.getInmobiliarias();
  },
};
</script>