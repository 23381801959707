<template>
  <v-layout>
    <v-container>
      <v-flex xs12>
        <v-data-table :headers="vHeaders" :items="materials.data" hide-actions class="elevation-1">
          <template slot="items" slot-scope="props">
            <td>{{ props.item.material.categoria.nombre }}</td>
            <td>{{ props.item.material.nombre }}</td>
            <td>{{ props.item.material.unidad }}</td>
            <td>{{ props.item.cantidad }}</td>
            <td>{{ props.item.distribucion }}</td>
            <td v-if="props.item.distribucion == 'Áreas verdes'">{{props.item.area_verde.nombre}}</td>
            <td v-else-if="props.item.distribucion == 'Calles'">{{props.item.calle.nombre}}</td>
            <td v-else-if="props.item.distribucion == 'Manzanas'">{{props.item.manzana.nombre}}</td>
            <td>{{(props.item.distribucion == 'Manzanas') ? (props.item.lote) ? props.item.lote.nombre : 'Sin lote seleccionado' : 'No aplica'}}</td>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <pagination
            :limit="vPaginator.limit"
            :data="materials"
            @pagination-change-page="getMaterialsValue"
          ></pagination>
        </v-card-actions>
      </v-flex>
    </v-container>
  </v-layout>
</template>

<script>
import swal from "sweetalert";
import { laravelRoute } from '../../../lib/utilities';

export default {
  data: () => ({
    materials: {
      data: []
    },
    salida: {},
    vPaginator: {
      limit: 10
    },
    vHeaders: [
      { 
        sortable: false,
        text: 'Categoría', 
      },
      { 
        sortable: false,
        text: 'Material', 
      },
      { 
        sortable: false,
        text: 'Unidad', 
      },
      { 
        sortable: false,
        text: 'Cantidad', 
      },
      { 
        sortable: false,
        text: 'Distribución', 
      },
      { 
        sortable: false,
        text: 'Nombre de distribución seleccionada', 
      },
      { 
        sortable: false,
        text: 'Lote', 
      },
    ],
    localSearch: null,
    showEditHouseholdModal: false,
    editHouseoldId: null
  }),
  props: {
    itemId: Number,
    reload: Boolean,
    householdId: String,
    permissions: Object,
    modelType: String,
    modelTypeOther: String,
  },
  watch: {
    itemId: function() {
      this.getMaterialsValue();
    },
    reload: function(value) {
      if (value == true) {
        this.getMaterialsValue();
      }
    }
  },
  methods: {
    getMaterialsValue: function(page = 1) {
      axios.get(`/ruta_index_materials_salidas?page=${page}&query=${this.localSearch}&itemId=${this.itemId}&model=App\\SolicitudDeMaterial`)
      .then(response => {
        this.materials = response.data;
      })
      .catch(error => errorHandling(error));
    },
    getSalida: function() {
      axios.get('/salidas_de_almacen/'+this.itemId)
      .then(response => {
        this.salida = response.data;
      })
      .catch(error => errorHandling(error));
    },
    deleteMaterialValue: function(materialId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios
            .delete(laravelRoute("salidas_materials.delete", materialId))
            .then(response => {
              this.getMaterialsValue();
              alertify.success(response.data.message_text);
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      });
    },
    despacharMaterial: function(materialSalidaId) {
        axios.patch(laravelRoute("materiales.despachar",materialSalidaId ))
        .then(response => {
            this.getMaterialsValue();
            alertify.success(response.data.message_text);
        })
        .catch(error => errorHandling(error));
    }
  }
};
</script>