var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        "v-card",
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("div", {}, [
              _c(
                "a",
                { attrs: { href: "../tramites_dependencias" } },
                [
                  _c(
                    "v-btn",
                    { staticClass: "white--text", attrs: { color: "#1ABA24" } },
                    [_vm._v("Ir a tramites")]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [_vm._v("Ver tramite")])
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { attrs: { "grid-list-md": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Nombre del Proyecto",
                              disabled: ""
                            },
                            model: {
                              value: _vm.tramite.nombre_tramite,
                              callback: function($$v) {
                                _vm.$set(_vm.tramite, "nombre_tramite", $$v)
                              },
                              expression: "tramite.nombre_tramite"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Descripción",
                              disabled: ""
                            },
                            model: {
                              value: _vm.tramite.descripcion,
                              callback: function($$v) {
                                _vm.$set(_vm.tramite, "descripcion", $$v)
                              },
                              expression: "tramite.descripcion"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Proyecto al que pertenece",
                              disabled: ""
                            },
                            model: {
                              value: _vm.tramite.proyecto_perteneciente,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.tramite,
                                  "proyecto_perteneciente",
                                  $$v
                                )
                              },
                              expression: "tramite.proyecto_perteneciente"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Fecha de resolución aproximada",
                              disabled: ""
                            },
                            model: {
                              value: _vm.tramite.fecha_resolucion,
                              callback: function($$v) {
                                _vm.$set(_vm.tramite, "fecha_resolucion", $$v)
                              },
                              expression: "tramite.fecha_resolucion"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Fecha de resolución aproximada",
                              disabled: ""
                            },
                            model: {
                              value: _vm.tramite.estado,
                              callback: function($$v) {
                                _vm.$set(_vm.tramite, "estado", $$v)
                              },
                              expression: "tramite.estado"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-flex", { attrs: { md12: "" } }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.tramite.planos,
                              target: "_blank"
                            }
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                color: "#1ABA24",
                                label: "Ver documento",
                                disabled: ""
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }