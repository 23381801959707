<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Añadir áreas verdes a {{ lotificacion.nombre }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form @keyup.enter.native="addArea">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>                               
                <span class="grey--text">Añade áreas verdes a la lotificación actual</span>
              </v-flex>
              <v-flex xs5>                               
                <v-text-field
                  color="verdeFayal"
                  label="Nombre de la calle"
                  readonly
                  :value="nameArea"
                ></v-text-field>
              </v-flex>
              <v-flex xs5>                               
                <v-text-field
                  v-model="addingArea.superficie"
                  color="verdeFayal"
                  label="Superficie"
                  type="number"
                  min="1"
                  :rules="[(v) => !!v || 'Campo requerido']"
                ></v-text-field>
              </v-flex>
              <v-flex xs2>
                <v-tooltip bottom>
                  <v-btn
                    slot="activator"
                    color="verdeFayal"
                    fab 
                    dark 
                    small
                    @click="addArea"
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                  <span>Añadir calle</span>
                </v-tooltip>
              </v-flex>
            </v-layout>

            <v-layout wrap
              v-if="areas.length"
            >
              <v-flex xs12>
                <v-data-table
                  hide-actions
                  :items="areas"
                >
                  <template v-slot:headers="props">
                    <tr>
                      <th>Nombre</th>
                      <th>Superficie (M2)</th>
                      <th class="text-xs-right">Acciones</th>
                    </tr>
                  </template>
                  <template v-slot:items="props">
                    <tr>
                      <td>{{props.item.nombre}}</td>
                      <td>{{props.item.superficie}}</td>
                      <td class="text-xs-right">
                        <v-tooltip bottom>
                          <v-btn
                            slot="activator"
                            icon
                            color="red"
                            flat
                            @click="deleteItem(props.index)"
                          >
                            <v-icon>delete</v-icon>
                          </v-btn>
                          <span>Eliminar calle</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="storeAreas" outline flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    areas: [],
    addingArea: {
      nombre: null,
      superficie: 1
    },
    localShow: false,
    localTotal: 0,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    lotificacion: Object,
    total: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    total: function(value) {
      this.localTotal = value;
    }
  },
  computed: {
    nameArea: function() {
      var count = this.localTotal + 1;
      return `Área verde ${count}`;
    }
  },
  methods: {
    addArea: function() {
      if(this.addingArea.superficie) {
        this.localTotal++;
        this.areas.push({
          ...this.addingArea,
          nombre: `Área verde ${this.localTotal}`,
        });
        this.addingArea = {
          nombre: null,
          superficie: 1
        };
      }
      else {
        alertify.error('Faltan campos por llenar');
      }
    },
    deleteItem: function(index) {
      this.areas.splice(index, 1);
    },
    storeAreas: function() {
        this.vBtnSave.loading = true;
        axios.post(laravelRoute('proyectos.areas_verdes.store', this.lotificacion.id), {
          "areas": this.areas,
        })
        .then(response => {
          this.vSnackBar.text = response.data.message_text;
          this.vSnackBar.visible = true;
          this.localShow = false;
          this.areas = [];
        })
        .catch(error => errorHandling(error))
        .finally(() => this.vBtnSave.loading = false);
      }
  }
}
</script>
