var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    [
      _c(
        "v-toolbar",
        {
          attrs: {
            color: "grey lighten-3",
            app: "",
            "clipped-left": _vm.$vuetify.breakpoint.mdAndUp,
            fixed: ""
          }
        },
        [
          _c("v-toolbar-side-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.$store.commit("showOrHiddenSidebar")
              }
            }
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-badge",
                        _vm._g(
                          {
                            attrs: { left: "", color: "#ff5617" },
                            scopedSlots: _vm._u(
                              [
                                _vm.notifications.length
                                  ? {
                                      key: "badge",
                                      fn: function() {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.notifications.length)
                                            )
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  : null
                              ],
                              null,
                              true
                            )
                          },
                          on
                        ),
                        [
                          _vm._v(" "),
                          _c(
                            "v-icon",
                            {
                              on: {
                                click: function($event) {
                                  _vm.drawer = true
                                }
                              }
                            },
                            [_vm._v("notifications")]
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_vm._v(" "), _c("span", [_vm._v("Notificaciones")])]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g({ attrs: { icon: "", href: "/perfil" } }, on),
                        [_c("v-icon", [_vm._v("person")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_vm._v(" "), _c("span", [_vm._v("Administrar perfil")])]
          ),
          _vm._v(" "),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          { attrs: { icon: "" }, on: { click: _vm.logout } },
                          on
                        ),
                        [_c("v-icon", [_vm._v("exit_to_app")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_vm._v(" "), _c("span", [_vm._v("Cerrar sesión")])]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { display: "none" } }, [
            _c(
              "form",
              {
                attrs: { id: "logout-form", action: "/logout", method: "POST" }
              },
              [
                _c("input", {
                  attrs: { type: "hidden", name: "_token" },
                  domProps: { value: _vm.csrf }
                })
              ]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          attrs: { right: "", temporary: "", absolute: "" },
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _c(
            "v-list",
            { staticClass: "pa-1" },
            [
              _c(
                "v-list-tile",
                { attrs: { avatar: "" } },
                [
                  _c(
                    "v-list-tile-avatar",
                    [
                      _c("v-icon", { attrs: { color: "#ff5617" } }, [
                        _vm._v("notifications")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-tile-content",
                    [_c("v-list-tile-title", [_vm._v("Notificaciones")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-tile-avatar",
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        href: "/notificaciones"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          {
                                            attrs: {
                                              color: "#1aba24",
                                              dark: ""
                                            }
                                          },
                                          on
                                        ),
                                        [_vm._v("all_inbox")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c("span", [_vm._v("Ver todas las notificaciones")])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { "two-line": "" } },
            [
              _vm._l(_vm.notifications, function(item) {
                return [
                  _c(
                    "v-list-tile",
                    {
                      key: item.data.id,
                      attrs: { avatar: "" },
                      on: {
                        click: function($event) {
                          return _vm.markAsRead(item)
                        }
                      }
                    },
                    [
                      _c("v-list-tile-content", [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.data.msg) +
                            "\n          "
                        )
                      ])
                    ],
                    1
                  )
                ]
              })
            ],
            2
          ),
          _vm._v(" "),
          _vm.notifications.length
            ? _c(
                "v-list",
                { staticClass: "pa-1" },
                [
                  _c(
                    "v-list-tile",
                    {
                      staticStyle: {
                        "background-color": "#1aba24",
                        color: "#fff"
                      },
                      attrs: { avatar: "" },
                      on: { click: _vm.markAllAsRead }
                    },
                    [
                      _c(
                        "v-list-tile-avatar",
                        [
                          _c("v-icon", { attrs: { color: "#fff" } }, [
                            _vm._v("mark_chat_read")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-tile-content",
                        [
                          _c("v-list-tile-title", [
                            _vm._v("Marcar todas como vistas")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }