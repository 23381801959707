<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="1350px" persistent>
      <v-card>
        <v-toolbar color="white" flat tabs>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Administrar materiales</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-tabs slot="extension" v-model="vTabs" centered color="white" slider-color="verdeFayal">
            <v-tab href="#tab-index-material">Lista de materiales</v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="vTabs">
          <v-tab-item value="tab-index-material">
            <index-material-salidas-almacen
              :item-id.sync="localItemId"
              :reload.sync="reload"
              :model-type="modelType"
              :model-type-other="modelTypeOther"
            ></index-material-salidas-almacen>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            v-if="vTabs == 'tab-add-material'"
            color="verdeFayal"
            @click="storeMateriales"
            flat
            :loading="vBtnSave.loading"
          >Guardar</v-btn>
          <v-btn dark v-if="vTabs == 'tab-add-material'" color="verdeFayal" @click="addMateriales">
            <v-icon>add</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { errorHandling, laravelRoute } from "../../../lib/utilities";
export default {
  data() {
    return {
      categories: [],
      done: false,
      fraccionamiento: {},
      fraccionamientosSelect: [{
        prospecto:{
          nombre_proyecto: null,
          propuesta_lotificacion: [{
            manzanas:[{
              nombre: null,
              lotes:[{
                nombre: null
              }]
            }]
          }]
        }
      }],
      fraccionamientos: {
        prospecto:{
          nombre_proyecto: null,
          propuesta_lotificacion: [{
            manzanas:[{
              nombre: null,
              lotes:[{
                nombre: null
              }]
            }]
          }]
        }
      },
      materiales: [
        {
          categoria_id: null,
          material: {
            precio: 0
          },
          cantidad: 0,
          materiales: [],
          proyecto_id: null,
        }
      ],
      localShow: false,
      vTabs: "tab-index-material",
      reload: false,
      localItemId: null,
      valid: false,
      rules: [v => !!v || "Este campo es requerido"],
      vBtnSave: {
        loading: false
      },
      vSnackBar: {
        visible: false,
        text: null
      },
      compMaterial: true
    };
  },
  props: {
    show: Boolean,
    itemId: Number,
    modelType: String,
    modelTypeOther: String
  },
  watch: {
    show: function(value) {
      this.localShow = value;
      this.getCategories();
    },
    localShow: function(value) {
      this.$emit("update:show", value);
    },
    itemId: function(value) {
      this.localItemId = value;
    },
    vTabs: function(value) {
      this.reload = value == "tab-index-material" ? true : false;
    },
    compMaterial: function(value){
      this.material.material == value;
    },
  },
  methods: {
    findFraccionamiento(id){
      let proyectoId = this.materiales[id].proyecto_id;
      if(!proyectoId) return [];
      return this.fraccionamientosSelect.find(element => element.id == proyectoId);
    },
    getManzanasItems: function (id) {
      let fraccionamiento = this.findFraccionamiento(id);
      if (fraccionamiento.length < 1) return [];
      return fraccionamiento.prospecto.propuesta_lotificacion[0].manzanas;
    },
    getLotesItems: function (id) {
      let manzanaId = this.materiales[id].manzana_id;
      let fraccionamiento = this.findFraccionamiento(id);
      if (fraccionamiento.length < 1) return [];
      let manzana = fraccionamiento.prospecto.propuesta_lotificacion[0].manzanas.find(manzana => manzana.id == manzanaId);
      if (!!!manzana) return [];
      return manzana.lotes;
    },
    getCategories: function() {
      axios
      .get(laravelRoute("categorias.index", {
        query: "Materiales de maquinaría"
      }))
      .then(response => {
        this.categories = response.data.data;
      })
      .catch(error => errorHandling(error));
    },
    getCurrentMateriales: function(category_id, index) {
      var categories = [];
      this.categories.map(function(category) {
        categories.push(category);
      });
      this.materiales[index].materiales = categories.find(function(category) {
        return category.id == category_id;
      }).materiales;
    },
    addMateriales: function() {
      this.materiales.push({
        categoria_id: null,
        material: {
          precio: 0
        },
        cantidad: 0,
        materiales: [],
      });
    },
    storeMateriales: function() {
      if (this.$refs.form.validate()) {
        this.vBtnSave.loading = true;
        axios
          .post(laravelRoute("salidas_materiales.save"), {
            materiales: this.materiales,
            salida_id: this.itemId,
            model_type: this.modelType,
            proyecto_id: this.fraccionamientos.prospecto.id,
            manzana_id: this.fraccionamientos.prospecto.propuesta_lotificacion[0].manzanas[0].id,
            lote_id: this.fraccionamientos.prospecto.propuesta_lotificacion[0].manzanas[0].lotes[0].id
          })
          .then(response => {
            this.materiales = [
              {
                categoria_id: null,
                material: {
                  precio: 0
                },
                cantidad: 0,
                materiales: []
              },
            ];
            alertify.success(response.data.message_text);
            this.localShow = false;
            this.fraccionamientos = {
              prospecto:{
                nombre_proyecto: null,
                propuesta_lotificacion: [{
                  manzanas:[{
                    nombre: null,
                    lotes:[{
                      nombre: null
                    }]
                  }]
                }]
              }
            };
          })
          .catch(error => errorHandling(error))
          .finally(() => (this.vBtnSave.loading = false));
      }
    }
  },
  mounted() {
    this.getCategories();
  }
};
</script>