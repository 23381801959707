var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: { color: "white", flat: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "extension",
                      fn: function() {
                        return [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                centered: "",
                                "slider-color": "verdeFayal"
                              },
                              model: {
                                value: _vm.vTabs,
                                callback: function($$v) {
                                  _vm.vTabs = $$v
                                },
                                expression: "vTabs"
                              }
                            },
                            [
                              _c(
                                "v-tab",
                                { attrs: { href: "#datos-maquinaria-tab" } },
                                [_vm._v("Datos del proyecto")]
                              ),
                              _vm._v(" "),
                              _vm.maquinaria.proyectos.length
                                ? _c(
                                    "v-tab",
                                    { attrs: { href: "#datos-proyectos-tab" } },
                                    [_vm._v("Proyectos")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Administrar proyectos")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.vTabs,
                    callback: function($$v) {
                      _vm.vTabs = $$v
                    },
                    expression: "vTabs"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "datos-maquinaria-tab" } },
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "", md6: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      color: "verdeFayal",
                                      items: _vm.prospectos,
                                      "item-value": "id",
                                      "item-text": "nombre_proyecto",
                                      label:
                                        "Proyecto en que se utiliza la máquina",
                                      "return-object": ""
                                    },
                                    model: {
                                      value: _vm.proyecto_perteneciente,
                                      callback: function($$v) {
                                        _vm.proyecto_perteneciente = $$v
                                      },
                                      expression: "proyecto_perteneciente"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.maquinaria.estado.information.estado ==
                              "Ocupada"
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "" } },
                                    [
                                      _c("v-checkbox", {
                                        attrs: {
                                          label: "Liberar del proyecto"
                                        },
                                        model: {
                                          value: _vm.liberar,
                                          callback: function($$v) {
                                            _vm.liberar = $$v
                                          },
                                          expression: "liberar"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.proyecto_perteneciente
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("searcher", {
                                        attrs: {
                                          configuration: _vm.searchOperador,
                                          itemSelected:
                                            _vm.proyecto_perteneciente
                                              .trabajador
                                        },
                                        on: {
                                          "update:itemSelected": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.proyecto_perteneciente,
                                              "trabajador",
                                              $event
                                            )
                                          },
                                          "update:item-selected": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              _vm.proyecto_perteneciente,
                                              "trabajador",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          min: "0",
                                          required: "",
                                          label: "Horas trabajadas"
                                        },
                                        model: {
                                          value:
                                            _vm.proyecto_perteneciente
                                              .horas_trabajadas,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.proyecto_perteneciente,
                                              "horas_trabajadas",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "proyecto_perteneciente.horas_trabajadas"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          min: "0",
                                          required: "",
                                          label: "Costo de operador por hora"
                                        },
                                        model: {
                                          value:
                                            _vm.proyecto_perteneciente
                                              .costo_operador,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.proyecto_perteneciente,
                                              "costo_operador",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "proyecto_perteneciente.costo_operador"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          min: "0",
                                          required: "",
                                          label:
                                            "Gasto de diesel por hora (litros)"
                                        },
                                        model: {
                                          value:
                                            _vm.proyecto_perteneciente
                                              .gasto_diesel,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.proyecto_perteneciente,
                                              "gasto_diesel",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "proyecto_perteneciente.gasto_diesel"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "date",
                                          required: "",
                                          label: "Fecha de término"
                                        },
                                        model: {
                                          value:
                                            _vm.proyecto_perteneciente
                                              .fecha_termino,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.proyecto_perteneciente,
                                              "fecha_termino",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "proyecto_perteneciente.fecha_termino"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.maquinaria.proyectos.length
                    ? _c(
                        "v-tab-item",
                        { attrs: { value: "datos-proyectos-tab" } },
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md12: "" } },
                                    [
                                      _c("v-data-table", {
                                        staticClass: "elevation-1",
                                        attrs: {
                                          headers: _vm.headers,
                                          items: _vm.maquinaria.proyectos
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "items",
                                              fn: function(props) {
                                                return [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.item.proyecto
                                                          .nombre_proyecto
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.item.trabajador
                                                          .nombre_completo
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.item
                                                          .horas_trabajadas
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      "$ " +
                                                        _vm._s(
                                                          _vm._f("currency")(
                                                            props.item
                                                              .costo_operador
                                                          )
                                                        )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.item.gasto_diesel
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.item.fecha_termino
                                                      )
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.item
                                                          .horas_trabajadas *
                                                          props.item
                                                            .gasto_diesel
                                                      )
                                                    )
                                                  ])
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3462733254
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.vTabs != "datos-proyectos-tab"
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            dark: "",
                            color: "verdeFayal",
                            flat: "",
                            loading: _vm.vBtnSave.loading
                          },
                          on: { click: _vm.updateMaquinaria }
                        },
                        [_vm._v("\n        Guardar\n      ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n  " + _vm._s(_vm.vSnackBar.text) + "\n  "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }