var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-toolbar",
            {
              attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" }
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                icon: "",
                                href: "/fraccionamientos"
                              }
                            },
                            [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_vm._v(" "), _c("span", [_vm._v("Regresar")])]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", "pb-2": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs10: "", "offset-xs1": "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card--flex-toolbar" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { card: "", prominent: "" } },
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-toolbar-title",
                            { staticClass: "body-2 grey--text" },
                            [
                              _vm._v(
                                "Viviendas de " +
                                  _vm._s(_vm.fraccionamiento.nombre_proyecto)
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-spacer")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("v-data-table", {
                                ref: "manzanasTable",
                                attrs: {
                                  "hide-actions": "",
                                  items: _vm.manzanas.data,
                                  expand: _vm.expandManzana
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "headers",
                                    fn: function(props) {
                                      return [
                                        _c("tr", [
                                          _c("th", [_vm._v("Nombre")]),
                                          _vm._v(" "),
                                          _c("th", {
                                            staticClass: "text-xs-right"
                                          })
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "items",
                                    fn: function(props) {
                                      return [
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              "Manzana " +
                                                _vm._s(props.item.nombre)
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-right" },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { flat: "", icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openLotesExpand(
                                                        props.item.id,
                                                        props.index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      _vm._s(
                                                        props.expanded
                                                          ? "expand_less"
                                                          : "expand_more"
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "expand",
                                    fn: function(props) {
                                      return [
                                        _c(
                                          "table",
                                          {
                                            staticClass:
                                              "table table-striped table-hover table-sm"
                                          },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c("th", [_vm._v("Lote")]),
                                                _vm._v(" "),
                                                _c("th", [_vm._v("Modelo")]),
                                                _vm._v(" "),
                                                _c("th", [_vm._v("Acciones")])
                                              ])
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.manzanas.data[props.index]
                                                  .lotes,
                                                function(lote, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: index },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(lote.nombre)
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            lote.prototipo
                                                              .nombre
                                                          )
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        slot:
                                                                          "activator",
                                                                        href:
                                                                          "/viviendas/" +
                                                                          lote
                                                                            .vivienda
                                                                            .id +
                                                                          "/administrar_garantias",
                                                                        color:
                                                                          "verdeFayal",
                                                                        flat:
                                                                          "",
                                                                        icon: ""
                                                                      },
                                                                      slot:
                                                                        "activator"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "policy"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Administrar garantias"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c("pagination", {
                                attrs: { limit: 10, data: _vm.manzanas },
                                on: {
                                  "pagination-change-page": _vm.getManzanas
                                }
                              }),
                              _vm._v(" "),
                              _c("v-spacer")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.vSnackBar.text) + "\n        "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }