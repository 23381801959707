var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Mantenimientos")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      (_vm.$root.can("new", _vm.permissions)
                      ? true
                      : false)
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "fyl-btnCreate",
                              on: { click: _vm.openCreateModal }
                            },
                            [_vm._v("Crear")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      "disable-initial-sort": "",
                      items: _vm.mantenimiento.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  props.item.maquinaria
                                    ? props.item.maquinaria.nombre
                                    : "Sin nombre"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  props.item.mantenimiento
                                    ? props.item.mantenimiento.nombre
                                    : "No tiene mantenimiento"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  props.item.estado
                                    ? props.item.estado.information.estado
                                    : "No tiene estado"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.fecha))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          disabled: !_vm.$root.can(
                                            "update",
                                            _vm.permissions
                                          ),
                                          icon: "",
                                          flat: "",
                                          dark: "",
                                          small: "",
                                          color: "info"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.EditMantenimientoModal(
                                              props.item.id
                                            )
                                          }
                                        },
                                        slot: "activator"
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "verdeFayal" } },
                                          [_vm._v("edit")]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Editar")])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          disabled: !_vm.$root.can(
                                            "remove",
                                            _vm.permissions
                                          ),
                                          icon: "",
                                          flat: "",
                                          dark: "",
                                          small: "",
                                          color: "error"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteMantenimiento(
                                              props.item.id
                                            )
                                          }
                                        },
                                        slot: "activator"
                                      },
                                      [_c("v-icon", [_vm._v("delete")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Eliminar")])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.mantenimiento
                        },
                        on: { "pagination-change-page": _vm.getMantenimientos }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("mantenimientos-modal-create", {
            attrs: {
              show: _vm.showCreateModal,
              maquinas: _vm.mantenimiento.data,
              maquinariaslist: _vm.maquinariaslist,
              mantenimientoslist: _vm.mantenimientoslist
            },
            on: {
              "update:show": function($event) {
                _vm.showCreateModal = $event
              }
            }
          }),
          _vm._v(" "),
          _c("mantenimientos-modal-update", {
            attrs: {
              show: _vm.showEditModal,
              "mantenimiento-id": _vm.currentMantenimientoId,
              mantenimientoslist: _vm.mantenimientoslist
            },
            on: {
              "update:show": function($event) {
                _vm.showEditModal = $event
              },
              "update:mantenimientoId": function($event) {
                _vm.currentMantenimientoId = $event
              },
              "update:mantenimiento-id": function($event) {
                _vm.currentMantenimientoId = $event
              }
            }
          }),
          _vm._v(" "),
          _c("manage-requisitions-to-mantenimiento-modal", {
            attrs: {
              show: _vm.showMaterialsToValuesModal,
              "item-id": _vm.currentItemId,
              modelType: _vm.currentModel,
              name: _vm.currentItemName
            },
            on: {
              "update:show": function($event) {
                _vm.showMaterialsToValuesModal = $event
              },
              "update:itemId": function($event) {
                _vm.currentItemId = $event
              },
              "update:item-id": function($event) {
                _vm.currentItemId = $event
              },
              "update:modelType": function($event) {
                _vm.currentModel = $event
              },
              "update:model-type": function($event) {
                _vm.currentModel = $event
              },
              "update:name": function($event) {
                _vm.currentItemName = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }