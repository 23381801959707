<template>
    <div id='da'>
        <v-card flat>
            <v-toolbar color="verdeFayal" dark extended flat>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn flat icon href="/fraccionamientos" v-on="on">
                            <v-icon>keyboard_arrow_left</v-icon>
                        </v-btn>
                    </template>
                    <span>Regresar</span>
                </v-tooltip>
            </v-toolbar>

            <v-layout row pb-2>
                <v-flex xs10 offset-xs1>
                    <v-card class="card--flex-toolbar">
                        <v-toolbar card prominent>
                            <v-spacer></v-spacer>
                            <v-toolbar-title class="body-2 grey--text">Crear etapa de obra para el fraccionamiento {{fraccionamiento.nombre_proyecto}} </v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>

                        <v-card-text>
                            <v-container grid-list-md>
                                <v-layout row wrap>
                                    <v-flex md6>
                                        <v-text-field
                                            label="Nombre de la etapa"
                                            v-model="etapa.nombre"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex md6>
                                        <v-autocomplete
                                            @keyup.enter.native="addDistribucion"
                                            hint="Buscar manzana a trabajar"
                                            prepend-inner-icon="search"
                                            :search-input.sync="search"
                                            :items="distribuciones"
                                            item-text="nombre"
                                            v-model="selected"
                                            persistent-hint
                                            item-value="id"
                                            :loading="searchLoading"
                                            label="Buscar"
                                            return-object
                                            hide-selected
                                            hide-no-data
                                        >
                                            <template v-slot:append-outer>
                                                <v-slide-x-reverse-transition mode="out-in">
                                                    <v-icon color="success" :disabled="!selected" @click="addDistribucion">add</v-icon>
                                                </v-slide-x-reverse-transition>
                                            </template>
                                        </v-autocomplete>
                                    </v-flex>
                                </v-layout>
                                <v-divider></v-divider>
                                <v-layout row wrap>
                                    <v-flex>
                                        <table class="table table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Manzana</th>
                                                    <th scope="col">Total de lotes seleccionados</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(manzana, index) in selectManzanas" :key="index">
                                                    <td>Manzana {{ manzana.nombre }}</td>
                                                    <td>
                                                        <span class="badge badge-primary badge-lotes">{{ manzana.lotes.length }}</span>
                                                    </td>
                                                    <td>
                                                        <v-layout>
                                                            <v-btn flat icon color="verdeFayal" @click="openAddLotesModal(manzana)">
                                                                <v-icon>add</v-icon>
                                                            </v-btn>
                                                            <v-btn flat icon color="red" @click="selectManzanas.splice(index, 1)">
                                                                <v-icon>delete</v-icon>
                                                            </v-btn>
                                                        </v-layout>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="storeEtapa" outline dark color="verdeFayal" flat :loading="vBtnSave.loading"> Guardar y continuar </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
            <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
                {{ vSnackBar.text }}
                <v-btn color="pink" flat @click="vSnackBar.visible = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-snackbar>
        </v-card>
        <!-- Modals -->
        <add-lotes-to-etapa-modal :show.sync="showAddLotesModal" :manzana="currentManzana" :lotesManzanas.sync='lotesSelected'></add-lotes-to-etapa-modal>
    </div>
</template>

<script>
    import moment from "moment";
    import { laravelRoute } from '../../lib/utilities';

    export default {
        data: () => ({
            fraccionamiento: {},
            search: null,
            etapa: {
                nombre: null,
            },
            selected: null,
            distribuciones: [],
            selectManzanas: [],
            currentManzana: {},
            lotesSelected: {},
            searchLoading: false,
            showAddLotesModal: false,
            vBtnSave: {
                loading: false
            },
            vSnackBar: {
                visible: false,
                text: null
            },
        }),
        props: {
            fraccionamientoId: String
        },
        watch: {
            search: function(){
                this.getDistribuciones();
            },
            lotesSelected: function(value) {
                if (typeof value !== 'undefined') {
                    this.selectManzanas.find(item => item.id == value.id).lotes = value.lotes;
                }
            }
        },
        methods: {
            getFraccionamiento: function() {
                axios.get(`/fraccionamientos/${this.fraccionamientoId}/refactor`)
                .then(response => this.fraccionamiento = response.data)
                .catch(error => errorHandling(error));
            },
            getDistribuciones: function() {
                this.searchLoading = true;
                axios.get(`/etapas/${this.fraccionamientoId}/distribuciones?tipo_distribucion=manzanas&search=${this.search}`)
                .then(response => this.distribuciones = response.data.data)
                .catch(error => errorHandling(error))
                .finally(() => this.searchLoading = false);
            },
            addDistribucion: function() {
                if(!this.selectManzanas.some(e => e.id === this.selected.id)) {
                    this.selectManzanas.push({
                        ...this.selected,
                        lotes: []
                    });
                    this.selected = null;
                }
                else {
                    alertify.error('Este elemento ya se habia agregado anteriormente');
                }
                
            }, 
            openAddLotesModal: function(manzana) {
                this.currentManzana = manzana;
                this.showAddLotesModal = true;
            },
            storeEtapa: function() {
                this.vBtnSave.loading = true;
                axios.post(laravelRoute('etapas.store'), {
                    nombre: this.etapa.nombre,
                    manzanas: this.selectManzanas,
                    fraccionamiento_id: this.fraccionamientoId
                })
                .then(response => {
                    this.vSnackBar.text = response.data.message_text;
                    this.vSnackBar.visible = true;
                    setTimeout(() => window.location.href = `/etapas/${response.data.etapa_id}/edit`, 1500);
                })
                .catch(error => errorHandling(error))
                .finally(() => this.vBtnSave.loading = false);
            }
        },
        created() {
            this.getFraccionamiento();
        }
    }
</script>
<style>
    .card--flex-toolbar {
        margin-top: -64px;
    }

    .badge-lotes {
        background-color: #ff5617;
    }
</style>