<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" width="980">
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Ver listado de materiales</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container fluid grid-list-sm>
            <v-layout wrap>
              <v-flex md12>
                <v-progress-linear color="verdeFayal" v-if="loadingMaterials" :indeterminate="true"></v-progress-linear>
                <v-data-table
                  hide-actions
                  :items="materiales.data"
                >
                  <template v-slot:headers="props">
                    <tr>
                      <th>Categoria</th>
                      <th>Nombre</th>
                      <th>Unidad</th>
                      <th>Proveedor</th>
                      <th>Precio original</th>
                      <th>Precio de compra</th>
                      <th>Cantidad solicitada</th>
                      <th>Total esperado</th>
                      <th>Cantidad surtida</th>
                      <th>Total real</th>
                    </tr>
                  </template>
                  <template v-slot:items="props">
                    <tr>
                      <td>{{ props.item.material.categoria_asociada }}</td>
                      <td>{{ props.item.material.nombre }}</td>
                      <td>{{ props.item.material.unidad}}</td>
                      <td>{{ props.item.material_requisicion.proveedor.nombre_completo }}</td>
                      <td>$ {{ props.item.material.precio | currency }}</td>
                      <td>$ {{ props.item.precio_compra | currency }}</td>
                      <td>{{ props.item.cantidad }}</td>
                      <td>$ {{ props.item.total | currency }}</td>
                      <td>{{ props.item.material_requisicion.cantidad_a_surtir }}</td>
                      <td>$ {{ props.item.precio_compra * props.item.material_requisicion.cantidad_a_surtir | currency }}</td>
                    </tr>
                  </template>
                  <template v-slot:no-data>
                    <p>Sin datos encontrados</p>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <pagination 
            :limit="10" 
            :data="materiales" 
            @pagination-change-page="getMaterials"
          ></pagination>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
  </v-dialog>
</div>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../../lib/utilities";

  export default {
    mounted() {
    },
    data() {
      return {
        materiales: {
          data: []
        },
        localShow: false,
        loadingMaterials: false
      }
    },
    props: {
      show : Boolean,
      distribucionId: Number,
      distribucionType: String,
      conceptoId: Number
    },
    watch: {
      show: function (value) {
        this.localShow = value; 
        if(value == true) {
          this.getMaterials();
        }
      },
      localShow: function (value) {
        this.$emit('update:show', value)
      }
    },
    methods: {
      getMaterials: function(page = 1) {
        this.loadingMaterials = true;
        axios.get(`/compras/por_distribucion?conceptoId=${this.conceptoId}&distribucionId=${this.distribucionId}&distribucionType=${this.distribucionType}&?page=${page}`)
        .then(response => this.materiales = response.data)
        .catch(error => errorHandling(error))
        .finally(() => this.loadingMaterials = false);
      }
    }
  }
</script>
