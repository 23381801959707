var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c("v-toolbar", {
            attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" }
          }),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", "pb-2": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs10: "", "offset-xs1": "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card--flex-toolbar" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { card: "", prominent: "" } },
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-toolbar-title",
                            { staticClass: "body-2 grey--text" },
                            [
                              _vm._v(
                                " Administrar garantía de " +
                                  _vm._s(_vm.vivienda.lote.nombre)
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: { dark: "", color: "verdeFayal" },
                              on: { click: _vm.openCreateModal }
                            },
                            [_vm._v("Añadir garantía")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c("v-data-table", {
                            attrs: {
                              items: _vm.garantias.data,
                              "hide-actions": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "headers",
                                fn: function(props) {
                                  return [
                                    _c("tr", [
                                      _c("th", [_vm._v("Tipo de garantía")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Estado")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Fecha de solicitud")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Acciones")])
                                    ])
                                  ]
                                }
                              },
                              {
                                key: "items",
                                fn: function(props) {
                                  return [
                                    _c("td", [
                                      _vm._v(_vm._s(props.item.tipo.nombre))
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "badge badge-secondary",
                                          style:
                                            "background-color: " +
                                            _vm.getTrafficLightColor(
                                              props.item.estado.estado_id
                                            ) +
                                            " ;"
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                props.item.estado.information
                                                  .estado
                                              ) +
                                              "\n                                    "
                                          )
                                        ]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("td", { staticClass: "date-td" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.translateDate(
                                            props.item.fecha_de_solicitud
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "v-layout",
                                          [
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      disabled:
                                                        props.item.estado
                                                          .estado_id != 40,
                                                      color: "verdeFayal",
                                                      "x-small": "",
                                                      icon: "",
                                                      flat: "",
                                                      dark: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openStatusModal(
                                                          props.item.id
                                                        )
                                                      }
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { "x-small": "" }
                                                      },
                                                      [_vm._v("low_priority")]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("Estados")])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      color: "verdeFayal",
                                                      "x-small": "",
                                                      icon: "",
                                                      flat: "",
                                                      dark: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openEditModal(
                                                          props.item.id
                                                        )
                                                      }
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { "x-small": "" }
                                                      },
                                                      [_vm._v("edit")]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("Editar")])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      color: "verdeFayal",
                                                      icon: "",
                                                      flat: "",
                                                      dark: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.openCommentsModal(
                                                          props.item.id
                                                        )
                                                      }
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { "x-small": "" }
                                                      },
                                                      [_vm._v("chat")]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v("Comentarios")
                                                ])
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-tooltip",
                                              { attrs: { bottom: "" } },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      slot: "activator",
                                                      color: "warning",
                                                      "x-small": "",
                                                      icon: "",
                                                      flat: "",
                                                      dark: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        _vm.currentHouseId =
                                                          props.item.id
                                                        _vm.showFilesModal = true
                                                      }
                                                    },
                                                    slot: "activator"
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: { "x-small": "" }
                                                      },
                                                      [_vm._v("folder")]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("span", [_vm._v("Archivos")])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c("pagination", {
                            attrs: { limit: 10, data: _vm.garantias },
                            on: { "pagination-change-page": _vm.getGarantias }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("add-guarantee-to-house-modal", {
        attrs: { show: _vm.showCreateModal, "vivienda-id": _vm.viviendaId },
        on: {
          "update:show": function($event) {
            _vm.showCreateModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("manage-comments-modal", {
        attrs: {
          show: _vm.showCommentsModal,
          "modelable-type": "App\\Garantia",
          "modelable-id": _vm.currentGuaranteeId
        },
        on: {
          "update:show": function($event) {
            _vm.showCommentsModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("edit-guarantee-to-house-modal", {
        attrs: {
          show: _vm.showEditModal,
          "vivienda-id": _vm.viviendaId,
          "garantia-id": _vm.currentGuaranteeId
        },
        on: {
          "update:show": function($event) {
            _vm.showEditModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("manage-estados-modal-component", {
        attrs: {
          show: _vm.showStatusModal,
          "model-id": _vm.currentHouseId,
          "model-type": "App\\Garantia"
        },
        on: {
          "update:show": function($event) {
            _vm.showStatusModal = $event
          },
          "update:modelId": function($event) {
            _vm.currentHouseId = $event
          },
          "update:model-id": function($event) {
            _vm.currentHouseId = $event
          }
        }
      }),
      _vm._v(" "),
      _c("upload-files-modal", {
        attrs: {
          element_type: "App\\Garantia",
          element_id: _vm.currentHouseId,
          dialog: _vm.showFilesModal
        },
        on: {
          close: function($event) {
            _vm.showFilesModal = false
          }
        }
      }),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.vSnackBar.text) + "\n        "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }