<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Editar prueba de laboratorio</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md12>
              <v-text-field
                color="verdeFayal"
                label="Nombre de la prueba"
                v-model="pruebasLab.nombre_prueba"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <searcher
                :configuration="searchComponent"
                :itemSelected.sync="pruebasLab.proyecto"
              ></searcher>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field 
                color="verdeFayal"
                label="Laboratorio encargado"
                v-model="pruebasLab.laboratorio_encargado"></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field 
                color="verdeFayal"
                type="date"
                label="Fecha tentativa de respuesta"
                v-model="pruebasLab.fecha_respuesta"></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-select color="verdeFayal"
                :items="['Urbanización', 'Edificación - Obra']"
                label="Departamento interesado"
                v-model="pruebasLab.departamento_interesado">
              </v-select>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field 
                color="verdeFayal"
                type="date"
                label="Fecha de obtención de muestra"
                v-model="pruebasLab.fecha_de_obtencion_de_muestra"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
          <hr>
          <v-layout wrap v-if="pruebasLab.proyecto.id">
              <v-flex md4>
                <v-select
                  :items="['Manzanas', 'Calles', 'Áreas verdes']"
                  v-model="pruebasLab.distribucion"
                  label="Distribución"
                  single-line
                ></v-select>
              </v-flex>
              <v-flex md4>
                <v-select
                  v-if="pruebasLab.distribucion == 'Manzanas'"
                  :items="pruebasLab.proyecto.prospecto.propuesta[0].only_manzanas"
                  item-text="nombre"
                  item-value="id"
                  v-model="pruebasLab.manzana"
                  label="Manzanas"
                  return-object
                  single-line
                ></v-select>
                <v-select
                  v-else-if="pruebasLab.distribucion == 'Calles'"
                  :items="pruebasLab.proyecto.prospecto.propuesta[0].calles"
                  item-text="nombre"
                  item-value="id"
                  v-model="pruebasLab.calle"
                  label="Calles"
                  single-line
                ></v-select>
                <v-select
                  v-else-if="pruebasLab.distribucion == 'Áreas verdes'"
                  :items="pruebasLab.proyecto.prospecto.propuesta[0].areas_verdes"
                  item-text="nombre"
                  item-value="id"
                  v-model="pruebasLab.area_verde"
                  label="Áreas verdes"
                  single-line
                ></v-select>
              </v-flex>
              <v-flex md4> 
                <v-select
                  v-if="pruebasLab.distribucion == 'Manzanas'"
                  :items="getLotes()"
                  object-selected
                  v-model="pruebasLab.lote"
                  item-text="nombre"
                  item-value="id"
                  label="Lotes"
                  single-line
                ></v-select>
              </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="updatePruebas" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    pruebasLab: {
      proyecto: {},
    },
    searchComponent: {
      url: "/fraccionamientos/con_propuestas",
      paginatedContainer: 'data',
      noDataText: "No se encontraron resultados.",
      label: "Proyecto al que pertenece",
      itemText: "nombre_proyecto",
      itemValue: "id",
      descriptionLimit: 60,
      disabled: false,
      useHeaders: true,
    },
    rules: [
      v => !!v || 'Este campo es requerido',
    ],
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    pruebaId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    pruebaId: function(value) {
      this.getPrueba();
    }
  },
  methods: {
    getPrueba: function() {
      axios.get("/pruebas_laboratorio/"+this.pruebaId)
      .then(response => { 
        this.pruebasLab = response.data; 
        this.pruebasLab.manzana = (!this.pruebasLab.manzana) ? {} : this.pruebasLab.manzana; 
      })
      .catch(error => errorHandling(error))
    },
    getLotes: function() {
      if(this.pruebasLab.manzana.id) {
        return this.pruebasLab.proyecto.prospecto.propuesta[0].only_manzanas.find(item => item.id == this.pruebasLab.manzana.id).lotes
      } 
      else {
        return []
      }
    },
    updatePruebas: function() {
      this.vBtnSave.loading = true;
      axios.patch('/pruebas_laboratorio/'+this.pruebaId, this.pruebasLab)
      .then(response => {
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;
      })
      .catch(error => errorHandling(error))
      .finally(() => (this.vBtnSave.loading = false));
    }
  }
}
</script>
