<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="550px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Añadir precios de venta al lote {{ lote.nombre }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout column wrap>
            <v-flex 
                :xs12="isEditing ? false : true"
                :xs6="isEditing ? true : false"
            >
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                          <th>Tipo</th>
                          <th>Precio</th>
                          <th>Observaciones</th>
                          <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in prices" :key="index">
                            <td>{{ item.tipo }}</td>
                            <td>{{ item.precio | currency}}</td>
                            <td>{{ item.observaciones }}</td>
                            <td>
                                <v-layout>
                                    <v-btn @click="editingPrice(index)" flat icon color="verdeFayal">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </v-layout>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-flex>
            <v-divider></v-divider>

            <v-flex xs6 v-if="isEditing">
                {{currentPrice.tipo}}
                <v-flex md6>
                  <input-currency-mask v-model="currentPrice.precio" label="Precio"/>
                </v-flex>
                <v-flex md12>
                  <v-textarea
                    outline
                    name="input-7-4"
                    label="Observaciones"
                    v-model="currentPrice.observaciones"
                  ></v-textarea>
                </v-flex> 
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="storePrices" outline dark color="verdeFayal" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../../lib/utilities";
export default {
  data: () => ({
    lote: {},
    prices: [],
    isEditing: false,
    currentPrice: {},
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    loteId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    loteId: function() {
        this.getLote();
        this.getPrices();
    }
  },
  methods: {
    getLote: function() {
        axios.get(`/manzanas/lote/${this.loteId}`)
        .then(response => this.lote = response.data)
        .catch(error => errorHandling(error))
    },
    getPrices: function() {
        axios.get(`/lotes/${this.loteId}/precios`)
        .then(response => this.prices = response.data.map(i => {return {...i, editing: false}}))
        .catch(error => errorHandling(error))
    },
    storePrices: function() {
        this.vBtnSave.loading = true;   
        let presale = this.prices.find(contado => contado.tipo == 'Contado')
        let presaleId = this.prices.find(contado => contado.tipo == 'Contado').id
        let credit = this.prices.find(contado => contado.tipo == 'Preventa')
        let creditId = this.prices.find(contado => contado.tipo == 'Preventa').id
        let cash = this.prices.find(contado => contado.tipo == 'Crédito')
        let cashId = this.prices.find(contado => contado.tipo == 'Crédito').id     
        axios.patch(`/lotes/precio/preventa/${presaleId}`, {
            preventa: presale.precio,
            preventa_observaciones: presale.observaciones
        })
        .then(response => {
            this.vSnackBar.text = response.data.message_text;
            this.vSnackBar.visible = true;
        })
        .catch(error => errorHandling(error))
        axios.patch(`/lotes/precio/credito/${creditId}`, {
            credito: credit.precio,
            credito_observaciones: credit.observaciones
        })
        .then(response => {
            this.vSnackBar.text = response.data.message_text;
            this.vSnackBar.visible = true;
        })
        .catch(error => errorHandling(error))
        axios.patch(`/lotes/precio/contado/${cashId}`, {
            contado: cash.precio,
            contado_observaciones: cash.observaciones
        })
        .then(response => {
            this.vSnackBar.text = response.data.message_text;
            this.vSnackBar.visible = true;
        })
        .catch(error => errorHandling(error))
        .finally(() => this.vBtnSave.loading = false);
    },
    editingPrice: function(index) {
        this.isEditing = true;
        this.currentPrice = this.prices[index];
    }
  }
}
</script>
