<template>
  <div class="text-xs-center">
    <v-btn class="fyl-btnCreate" @click="$emit('open')">Crear</v-btn>
    <v-dialog v-model="dialog" max-width="850px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">{{ this.inmobiliaria.id ? "Editar" : "Crear" }} Inmobiliaria</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex md4>
                  <v-text-field label="Inmobiliaria" v-model="inmobiliaria.inmobiliaria" required :rules="[ v => !!v || 'Campo Requerido']"></v-text-field>
                </v-flex>

                <v-flex md4>
                  <v-text-field label="Telefono" mask="(###)-###-##-##" v-model="inmobiliaria.telefono" required :rules="[ v => !!v || 'Campo Requerido']"></v-text-field>
                </v-flex>

                <v-flex md4>
                  <v-text-field label="Dirección" v-model="inmobiliaria.direccion" required :rules="[ v => !!v || 'Campo Requerido']"></v-text-field>
                </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!inmobiliaria.inmobiliaria || !inmobiliaria.telefono || !inmobiliaria.direccion" disabled dark color="verdeFayal" @click="inmobiliariaCreate" flat :loading="vBtnSave.loading"> Guardar </v-btn>
          <v-btn v-else dark color="verdeFayal" @click="inmobiliariaCreate" flat :loading="vBtnSave.loading"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { errorHandling } from "../../lib/utilities";
export default {
  props: ["inmobiliaria", "dialog"],

  data() {
    return {

      vBtnSave: {
        loading: false
      },
      vSnackBar: {
        visible: false,
        text: null
      }
    };
  },

  methods: {
    inmobiliariaCreate() {
      this.vBtnSave.loading = true;
      if (this.inmobiliaria.id) {
        axios
          .patch(route("inmobiliarias.update", this.inmobiliaria.id), this.inmobiliaria)
          .then(response => {
            if (response.data.message_text)
              alertify.success(response.data.message_text);
              this.$emit("close");
          })
          .catch(error => errorHandling(error))
          .finally(() => (window.location.href = "/inmobiliarias"));
      } else {
        axios
          .post("/inmobiliarias", this.inmobiliaria)
          .then(response => {
            this.inmobiliarias.push(response.data.data);
            if (response.data.message_text)
              alertify.success(response.data.message_text);
              this.$emit("close");
          })
          .catch(error => errorHandling(error))
          .finally(() => (window.location.href = "/inmobiliarias"));
      }
    }
  }
};
</script>