var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            small: "",
                            flat: "",
                            href:
                              "/etapas/fraccionamiento/" +
                              _vm.etapa.fraccionamiento_id
                          }
                        },
                        [_c("v-icon", [_vm._v("chevron_left")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-toolbar-title", [_vm._v("Incidencias")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "verdeFayal", dark: "" },
                          on: {
                            click: function($event) {
                              _vm.dialogIncidencia = true
                              _vm.incidenciaId = null
                            }
                          }
                        },
                        [_vm._v("\n            Crear\n          ")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      "disable-initial-sort": "",
                      items: _vm.incidencias.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", [_vm._v(_vm._s(props.item.nombre))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.descripcion))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.horas))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.creador.name))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "info"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.dialogIncidencia = true
                                                _vm.incidenciaId = props.item.id
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { color: "verdeFayal" }
                                              },
                                              [_vm._v("remove_red_eye")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Ver")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "red"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteIncidencia(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "red" } },
                                              [_vm._v("delete")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Eliminar")])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.incidencias
                        },
                        on: { "pagination-change-page": _vm.getIncidencias }
                      }),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("div", [
                        _c("span", [
                          _vm._v("\n              Total de horas: "),
                          _c("span", { staticClass: "grey--text" }, [
                            _vm._v(_vm._s(_vm.etapa.horas_incidencias))
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-snackbar",
            {
              attrs: { bottom: "bottom", right: "right" },
              model: {
                value: _vm.vSnackBar.visible,
                callback: function($$v) {
                  _vm.$set(_vm.vSnackBar, "visible", $$v)
                },
                expression: "vSnackBar.visible"
              }
            },
            [
              _vm._v("\n      " + _vm._s(_vm.vSnackBar.text) + "\n      "),
              _c(
                "v-btn",
                {
                  attrs: { color: "pink", flat: "" },
                  on: {
                    click: function($event) {
                      _vm.vSnackBar.visible = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("incidencias-modal", {
            attrs: {
              dialog: _vm.dialogIncidencia,
              incidenciaId: _vm.incidenciaId,
              etapaId: _vm.etapaId
            },
            on: {
              close: function($event) {
                _vm.dialogIncidencia = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }