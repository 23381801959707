<template>
  <v-container grid-list-md id="container-workers">
    <v-layout row wrap>
      <v-flex :xs12="!showFiltersModal" :xs9="showFiltersModal">
        <v-card>  
          <v-toolbar color="white" flat>
            <v-toolbar-title>Materiales</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <v-btn class="fyl-btnCreate" @click="openCreateModal" v-if="$root.can('new', permissions) ? true : false">Crear</v-btn>
            <v-btn dark color="verdeFayal" outline @click="showFiltersModal = !showFiltersModal">
              <v-icon dark>filter_alt</v-icon>
            </v-btn>
            <v-btn dark color="verdeFayal" outline @click="exportToExcel">
              <v-icon dark>import_export</v-icon>
            </v-btn>
          </v-toolbar>

          <v-data-table :headers="vHeaders" disable-initial-sort :items="materials.data" hide-actions>
            <template slot="items" slot-scope="props">
              <td>{{ props.item.nombre }}</td>
              <td class="td-ellipsis">{{ props.item.categoria_asociada }}</td>
              <td class="td-ellipsis">{{ props.item.unidad }}</td>
              <td class="td-ellipsis">${{ props.item.precio | currency }}</td>
              <td class="td-ellipsis">{{ props.item.existencia }}</td>
              <td class="td-ellipsis">{{ props.item.minimo }}</td>
              <td class="text-xs-left">
                <v-layout>
                  <v-tooltip bottom>
                    <v-btn icon flat dark small slot="activator" color="verdeFayal" @click="openEditMaterialModal(props.item.id)" :disabled="!$root.can('update', permissions)">
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn icon flat dark small slot="activator" color="error" @click="deleteMaterial(props.item.id)"> <!-- Cambiar 'removex' a 'remove' para cumplir con el permiso -->
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <span>Eliminar</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>

          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="materials" @pagination-change-page="getMaterials"></pagination>
            <v-spacer></v-spacer>
          </v-card-actions>   
        </v-card>
      </v-flex>

      <v-flex xs3 v-if="showFiltersModal">
        <v-card>
          <v-toolbar color="white" flat>
            <v-toolbar-title>Filtros</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="showFiltersModal = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex>
                  <v-select 
                    v-model="filters.filterCategoria"                           
                    :items="categorias" 
                    label="Categoria"
                    item-text="nombre"
                    item-value="id"        
                    clearable            
                  />
                </v-flex>
                <v-flex>
                  <v-select 
                    v-model="filters.filterExistencias"                    
                    :items="['Debajo del minimo', 'Sin existencias']" 
                    label="Existencias disponibles"                    
                    clearable
                  />
                </v-flex>                
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn dark color="verdeFayal" small flat outline @click="limpiar">Limpiar</v-btn>
            <v-spacer></v-spacer>
            <v-btn dark color="verdeFayal" small @click="filterMaterials">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import swal from "sweetalert";

export default {
  data: () => ({
    search: null,
    materials: {
      current_page: 1,
      data: []
    },
    showCreateModal: false,
    showEditModal: false,
    currentMaterialId: null,
    showManageMaterialModal: false,
    vPaginator: {
      limit: 10
    },
    vHeaders: [{
        text: 'Nombre',
        value: 'nombre'
      },
      {
        text: 'Categoria',
        value: 'categoria_asociada'
      },
      {
        text: 'Unidad',
        value: 'unidad'
      },
      {
        text: 'Precio unitario sin IVA',
        value: 'precio'
      },
      {
        text: 'Existencia',
        value: 'existencia'
      },
      {
        text: 'Mìnimo',
        value: 'minimo'
      },
      {
        text: 'Acciones',
        value: 'acciones',
        sortable: false
      }
    ],

    showFiltersModal: false,

    filters: {
      filterCategoria: null,      
      filterExistencias: null,      
    },    
    categorias: [], 


  }),
  props: ['permissions'],
  watch: {
    search: function (value) {
      this.getMaterials();
    },
    showCreateModal: function (value) {
      if (!value) {
        this.getMaterials();
      }
    },
    showEditModal: function (value) {
      if (!value) {
        this.getMaterials(this.materials.current_page);
      }
    },
    showManageMaterialModal: function (value) {
      if (!value) {
        this.getMaterials(this.materials.current_page);
      }
    }
  },
  methods: {
    getMaterials: function (page = 1) {
      axios.get(`/materiales?page=${page}&query=${this.search}`)
        .then(response => {
          this.materials = response.data;
        })
        .catch(error => errorHandling(error));
    },
    deleteMaterial: function (materialId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('materiales.destroy', materialId))
            .then(response => {
              this.getMaterials();
              alertify.success(response.data.message_text);
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      })
    },
    openEditMaterialModal: function (materialId) {
      this.showEditModal = true;
      this.currentMaterialId = materialId;
    },
    openCreateModal: function () {
      this.showCreateModal = true;
    },
    manageMaterial: function(materialId) {
      this.showManageMaterialModal = true;
      this.currentMaterialId = materialId;
    },

    getCategorias: function () {      
      axios.get(laravelRoute('categorias.index.without_paginate'))
      .then(response => this.categorias = response.data)
      .catch(error => errorHandling(error));
    },
    filterMaterials: function() {
      axios.post('/materiales/filterMaterials',this.filters)
      .then(response => this.materials = response.data)
      .catch(error => errorHandling(error));
    },
    limpiar: function () {             
        this.filters.filterCategoria = null;
        this.filters.filterExistencias = null;
        this.getMaterials(this.materials.current_page);
    },

    exportToExcel: function() {
      axios.post('/materiales/filterMaterials', {
        ...this.filters,
        to_excel: true
      }, {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'materiales.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
    },

  },
  created() {
    this.getMaterials(this.materials.current_page);

    this.getCategorias();
  }
}
</script>
