import { render, staticRenderFns } from "./housesPerSubdivisionsForm.vue?vue&type=template&id=48015836&"
import script from "./housesPerSubdivisionsForm.vue?vue&type=script&lang=js&"
export * from "./housesPerSubdivisionsForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('48015836', component.options)
    } else {
      api.reload('48015836', component.options)
    }
    module.hot.accept("./housesPerSubdivisionsForm.vue?vue&type=template&id=48015836&", function () {
      api.rerender('48015836', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/Viviendas/housesPerSubdivisionsForm.vue"
export default component.exports