<template>
<v-layout>
  <v-container>
    <v-card>
      <v-flex xs12>
        <v-toolbar color="white" flat tabs>
          <v-toolbar-title>Ventas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
        </v-toolbar>
            <v-data-table :headers="vHeaders" disable-initial-sort :items="lots.data" hide-actions class="elevation-1">
                <template slot="items" slot-scope="props">
                    <td class="td-ellipsis">{{ props.item.manzana.lotificacion.prospecto.nombre_proyecto }}</td>
                    <td class="td-ellipsis">{{ props.item.nombre }}</td>
                    <td class="td-ellipsis">$ {{ props.item.precio | currency}}</td>
                    <td class="td-ellipsis">{{ props.item.modelo }}</td>
                    <td class="td-ellipsis">{{ (props.item.cliente) ? props.item.cliente : 'Sin cliente aún'}}</td>
                    <td class="td-ellipsis">{{ (props.item.vendedor) ? props.item.vendedor : 'Sin vendedor aún' }}</td>
                    <td>
                    </td>
                </template>
            </v-data-table>
            <v-card-actions>
            <v-spacer></v-spacer>
                <pagination :limit="vPaginator.limit" :data="lots" @pagination-change-page="getLots"></pagination>
            </v-card-actions>
      </v-flex>
    </v-card>
  </v-container>
</v-layout>
</template>

<script>
import swal from "sweetalert";

export default {
  data: () => ({
    search: null,
    lots: {
      current_page: 1,
      data: []
    },
    showCreateModal: false,
    showEditModal: false,
    currentMaterialId: null,
    showManageMaterialModal: false,
    vPaginator: {
      limit: 10
    },
    vHeaders: [
      {
        text: 'Nombre del fraccionamiento',
        value: 'fraccionamiento',
        sortable: false
      },
      {
        text: 'Lote',
        value: 'nombre',
        sortable: false
      },
      {
        text: 'Precio',
        value: 'precio_total',
        sortable: false
      },
      {
        text: 'Modelo',
        value: 'modelo',
        sortable: false
      },
      {
        text: 'Cliente',
        value: 'cliente',
        sortable: false
      },
      {
        text: 'Vendedor',
        value: 'vendedor',
        sortable: false
      },
      {
        text: 'Acciones',
        value: 'acciones',
        sortable: false
      }
    ],
  }),
  props: ['permissions'],
  watch: {
    search: function (value) {
      this.getLots(this.lots.current_page);
    }
  },
  methods: {
    getLots: function (page = 1) {
      axios.get(`/ventas?page=${page}&query=${this.search}`)
        .then(response => {
          this.lots = response.data;
        })
        .catch(error => errorHandling(error));
    },
  },
  created() {
    this.getLots(this.lots.current_page);
  }
}
</script>
