var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-flex",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Maquinaria")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      (_vm.$root.can("new", _vm.permissions)
                      ? true
                      : false)
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "fyl-btnCreate",
                              on: { click: _vm.openCreateModal }
                            },
                            [_vm._v("Crear")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", outline: "" },
                          on: {
                            click: function($event) {
                              _vm.showFiltersModal = !_vm.showFiltersModal
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("filter_alt")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", outline: "" },
                          on: { click: _vm.exportToExcel }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("import_export")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      "disable-initial-sort": "",
                      items: _vm.maquinaria.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", [_vm._v(_vm._s(props.item.nombre))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  !!props.item.descripcion &&
                                    props.item.descripcion.length > 15
                                    ? props.item.descripcion.substring(0, 15) +
                                        "..."
                                    : props.item.descripcion
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  props.item.estado
                                    ? props.item.estado.information.estado
                                    : "No tiene estado"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  props.item.proyecto_actual
                                    ? props.item.proyecto_actual.proyecto
                                        .nombre_proyecto
                                    : "Sin proyecto actual"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.created_at))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "info",
                                              disabled: !_vm.$root.can(
                                                "update",
                                                _vm.permissions
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.ManageProjectMaquinariaModal(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { color: "verdeFayal" }
                                              },
                                              [_vm._v("book")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Proyecto")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "info",
                                              disabled: !_vm.$root.can(
                                                "update",
                                                _vm.permissions
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.EditMaquinariaModal(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: { color: "verdeFayal" }
                                              },
                                              [_vm._v("edit")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Editar")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "error",
                                              disabled: !_vm.$root.can(
                                                "remove",
                                                _vm.permissions
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteMaquinaria(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("delete")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Eliminar")])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.maquinaria
                        },
                        on: { "pagination-change-page": _vm.getMaquinaria }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("maquinaria-modal-create", {
            attrs: {
              show: _vm.showCreateModal,
              prospectos: this.prospectos,
              mantenimientoslist: _vm.mantenimientoslist
            },
            on: {
              "update:show": function($event) {
                _vm.showCreateModal = $event
              }
            }
          }),
          _vm._v(" "),
          _c("maquinaria-modal-update", {
            attrs: {
              show: _vm.showEditModal,
              prospectos: _vm.prospectos,
              "maquinaria-id": _vm.currentMaquinariaId,
              mantenimientoslist: _vm.mantenimientoslist
            },
            on: {
              "update:show": function($event) {
                _vm.showEditModal = $event
              },
              "update:maquinariaId": function($event) {
                _vm.currentMaquinariaId = $event
              },
              "update:maquinaria-id": function($event) {
                _vm.currentMaquinariaId = $event
              }
            }
          }),
          _vm._v(" "),
          _c("manage-project-maquinaria-modal", {
            attrs: {
              show: _vm.showManageProjectModal,
              prospectos: _vm.prospectos,
              "maquinaria-id": _vm.currentMaquinariaId,
              mantenimientoslist: _vm.mantenimientoslist
            },
            on: {
              "update:show": function($event) {
                _vm.showManageProjectModal = $event
              },
              "update:maquinariaId": function($event) {
                _vm.currentMaquinariaId = $event
              },
              "update:maquinaria-id": function($event) {
                _vm.currentMaquinariaId = $event
              }
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.showFiltersModal
        ? _c(
            "v-flex",
            { attrs: { xs3: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Filtros")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            flat: "",
                            icon: "",
                            color: "verdeFayal",
                            dark: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.showFiltersModal = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      color: "verdeFayal",
                                      items: _vm.estados,
                                      "item-value": "id",
                                      "item-text": "estado",
                                      label: "Status actual",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.filters.filterEstado,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "filterEstado",
                                          $$v
                                        )
                                      },
                                      expression: "filters.filterEstado"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      color: "verdeFayal",
                                      items: _vm.proyectoItems,
                                      "item-value": "id",
                                      "item-text": "nombre_proyecto",
                                      label: "Proyecto asignado",
                                      clearable: ""
                                    },
                                    model: {
                                      value: _vm.filters.filterProyecto,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "filterProyecto",
                                          $$v
                                        )
                                      },
                                      expression: "filters.filterProyecto"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            dark: "",
                            color: "verdeFayal",
                            small: "",
                            flat: "",
                            outline: ""
                          },
                          on: { click: _vm.limpiar }
                        },
                        [_vm._v("Limpiar")]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", small: "" },
                          on: { click: _vm.filterMaquinaria }
                        },
                        [_vm._v("Filtrar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }