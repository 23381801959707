<template>
  <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn flat icon :href="`/etapas/fraccionamiento/${etapa.fraccionamiento_id}`">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>

        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-toolbar card prominent>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="body-2 grey--text">Administrar datos de la etapa {{etapa.nombre}} </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout>
                                <v-flex xs3>
                                    <v-card dark color='#75d57b'>
                                        <v-layout>
                                            <v-flex xs5>
                                                <v-layout  align-center justify-center row fill-height>
                                                    <v-img
                                                        src="/fonts/local/watch.svg"
                                                        height="50px"
                                                        contain
                                                    ></v-img>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs7>
                                                <v-card-title primary-title>
                                                    <div>
                                                        <div class="headline total-title">{{ resumen.horas }}</div>
                                                        <div>Horas</div>
                                                    </div>
                                                </v-card-title>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex xs3>
                                    <v-card dark color='#75d57b'>
                                        <v-layout>
                                            <v-flex xs5>
                                                <v-layout  align-center justify-center row fill-height>
                                                    <v-img
                                                        src="/fonts/local/construction.svg"
                                                        height="50px"
                                                        contain
                                                    ></v-img>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs7>
                                                <v-card-title primary-title>
                                                    <div>
                                                        <div class="headline total-title">$ {{ resumen.total_materiales | currency}}</div>
                                                        <div>Material</div>
                                                    </div>
                                                </v-card-title>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex xs3>
                                    <v-card dark color='#75d57b'>
                                        <v-layout>
                                            <v-flex xs5>
                                                <v-layout  align-center justify-center row fill-height>
                                                    <v-img
                                                        src="/fonts/local/manpower.svg"
                                                        height="50px"
                                                        contain
                                                    ></v-img>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs7>
                                                <v-card-title primary-title>
                                                    <div>
                                                        <div class="headline total-title">$ {{ resumen.total_valores | currency}}</div>
                                                        <div>Mano de obra</div>
                                                    </div>
                                                </v-card-title>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex xs3>
                                    <v-card dark color='#75d57b'>
                                        <v-layout>
                                            <v-flex xs5>
                                                <v-layout  align-center justify-center row fill-height>
                                                    <v-img
                                                        src="/fonts/local/total.svg"
                                                        height="50px"
                                                        contain
                                                    ></v-img>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs7>
                                                <v-card-title primary-title>
                                                    <div>
                                                        <div class="headline total-title">$ {{ resumen.total_materiales + resumen.total_valores | currency}}</div>
                                                        <div>Total</div>
                                                    </div>
                                                </v-card-title>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                            </v-layout>

                            <v-divider></v-divider>
                            
                            <v-layout row wrap>
                                <v-flex md6>
                                    <v-text-field
                                        label="Nombre de la etapa"
                                        v-model="etapa.nombre"
                                        readonly
                                    ></v-text-field>
                                </v-flex>
                                <v-flex md6>
                                    <v-autocomplete
                                        hint="Buscar manzana a trabajar"
                                        @keyup.enter.native="storeDistribucion"
                                        :search-input.sync="search"
                                        prepend-inner-icon="search"
                                        :items="distribuciones"
                                        label="Buscar"
                                        :loading="isLoading"
                                        item-text="nombre"
                                        v-model="selected"
                                        item-value="id"
                                        persistent-hint
                                        return-object
                                        hide-selected
                                        hide-no-data
                                    >
                                        <template v-slot:append-outer>
                                            <v-slide-x-reverse-transition mode="out-in">
                                                <v-icon color="success" :disabled="!selected" @click="storeDistribucion">add</v-icon>
                                            </v-slide-x-reverse-transition>
                                        </template>
                                    </v-autocomplete>
                                </v-flex>
                            </v-layout>

                            <v-divider></v-divider>
                            
                            <v-layout row wrap>
                                <v-flex>
                                    <v-card flat>
                                        <v-container>
                                            <v-card-text>
                                                <table class="table table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Manzana</th>
                                                            <th scope="col">Total de lotes seleccionados</th>
                                                            <th>Acciones</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(manzana, index) in manzanas.data" :key="index">
                                                            <td>{{ manzana.nombre }}</td>
                                                            <td>
                                                                <span class="badge badge-primary badge-lotes">{{ manzana.lotes_de_manzanas_count }}</span>
                                                            </td>
                                                            <td class="text-right">
                                                                <v-layout>
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn flat icon color="verdeFayal" :href="`/etapas/${etapa.id}/edit/lotes?manzana_etapa_id=${manzana.id}`" v-on="on">
                                                                                <v-icon>add</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Administrar lotes</span>
                                                                    </v-tooltip>
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn v-on="on" flat icon color="red" @click="deleteDistribucion(manzana.id, 'manzana')">
                                                                                <v-icon>delete</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Eliminar manzana</span>
                                                                    </v-tooltip>
                                                                </v-layout>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <pagination :limit="10" :data="manzanas" @pagination-change-page="getManzanas"></pagination>
                                            </v-card-actions>
                                        </v-container>
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    data() {
        return {
            fraccionamiento: {},
            search: null,
            selected: null,
            isLoading: false,
            manzanas: {},
            calles: {},
            areas: {},
            distribuciones: [],
            selectManzanas: [],
            resumen: {
                total_materiales: 0,
                total_valores: 0,
                horas: 0
            },
            vBtnSave: {
                loading: false
            },
            vSnackBar: {
                visible: false,
                text: null
            },
        }
    },
    props: {
            etapa: Object
        },
        watch: {
            search: function(){
                this.getDistribuciones();
            },
            vTabs: function(){
                this.search = null;
                this.selected = null;
            }
        },
        methods: {
            getDistribuciones: function() {
                this.isLoading = true;
                axios.get(`/etapas/${this.etapa.fraccionamiento_id}/distribuciones?tipo_distribucion=manzanas&search=${this.search}`)
                .then(response => this.distribuciones = response.data.data)
                .catch(error => errorHandling(error))
                .finally(() => this.isLoading = false);
            },
            storeDistribucion: function() {
                this.vBtnSave.loading = true;
                axios.post(laravelRoute('etapas.urbanizacion.distribucion.store', this.etapa.id),{
                    etapa_id: this.etapa.id,
                    selected: this.selected,
                    current_distribucion: 'manzanas'
                })
                .then(response => {
                    this.getManzanas();
                    this.vSnackBar.text = response.data.message_text;
                    this.vSnackBar.visible = true;
                })
                .finally(() => this.vBtnSave.loading = false)
                .catch(error => errorHandling(error));
            },
            getManzanas: function(page = 1) {
                axios.get(`/etapas/${this.etapa.id}/manzanas?page=${page}`)
                .then(response => this.manzanas = response.data)
                .catch(error => errorHandling(error));
            },
            deleteDistribucion: function(distribucionId, tipoDistribucion) {
                swal({
                    title: "¿Estás seguro?",
                    text: "Una vez eliminado no se podrá recuperar",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true
                }).then(result => {
                    if (result) {
                        axios.delete(`/etapas/${this.etapa.id}/distribucion?distribucion_id=${distribucionId}&tipo=${tipoDistribucion}`)
                        .then(response => {
                            this.getManzanas();
                            this.vSnackBar.text = response.data.message_text;
                            this.vSnackBar.visible = true;
                        })
                        .catch(error => errorHandling(error));
                    }
                })
            },
            getTotales: function() {
                axios.get(`/etapas/${this.etapa.id}/totales_obra`)
                .then(response => this.resumen = response.data)
                .catch(error => errorHandling(error));
            }
        },
        created() {
            this.getTotales();
            this.getManzanas();
        }
  }
</script>

<style>
    .total-title {
        font-size: 20px !important;
        line-height: 18px !important;
    }
</style>