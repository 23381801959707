var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            flat: "",
                            icon: "",
                            href: "/solicitud_de_material"
                          }
                        },
                        [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_vm._v(" "), _c("span", [_vm._v("Regresar")])]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "pb-2": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs10: "", "offset-xs1": "" } },
            [
              _c(
                "v-card",
                { staticClass: "card--flex-toolbar" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", prominent: "" } },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-title",
                        { staticClass: "body-2 grey--text" },
                        [_vm._v("Crear solicitud de materiales")]
                      ),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: _vm.rules,
                                          value: _vm.nombre,
                                          required: "",
                                          label: "Nombre de la orden",
                                          readonly: ""
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: _vm.rules,
                                          required: "",
                                          label: "Fecha de solicitud",
                                          type: "date"
                                        },
                                        model: {
                                          value: _vm.solicitud.fecha_solicitud,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.solicitud,
                                              "fecha_solicitud",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "solicitud.fecha_solicitud"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        md6:
                                          _vm.solicitud.solicitante ==
                                          "Maquinaría"
                                            ? false
                                            : true,
                                        md4:
                                          _vm.solicitud.solicitante ==
                                          "Maquinaría"
                                            ? true
                                            : false
                                      }
                                    },
                                    [
                                      _vm.rol == "Administrador" ||
                                      _vm.rol == "Almacen"
                                        ? _c("v-select", {
                                            attrs: {
                                              rules: _vm.rules,
                                              items: _vm.solicitante,
                                              required: "",
                                              label: "Solicitante"
                                            },
                                            model: {
                                              value: _vm.solicitud.solicitante,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.solicitud,
                                                  "solicitante",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "solicitud.solicitante"
                                            }
                                          })
                                        : _c("v-text-field", {
                                            attrs: {
                                              value: _vm.rol,
                                              required: "",
                                              label: "Solicitante",
                                              readonly: ""
                                            }
                                          })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.solicitud.solicitante == "Maquinaría"
                                    ? _c(
                                        "v-flex",
                                        { attrs: { md4: "" } },
                                        [
                                          _c("searcher", {
                                            attrs: {
                                              configuration:
                                                _vm.searchMaquinaria,
                                              itemSelected:
                                                _vm.solicitud.maquinaria
                                            },
                                            on: {
                                              "update:itemSelected": function(
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.solicitud,
                                                  "maquinaria",
                                                  $event
                                                )
                                              },
                                              "update:item-selected": function(
                                                $event
                                              ) {
                                                return _vm.$set(
                                                  _vm.solicitud,
                                                  "maquinaria",
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        md6:
                                          _vm.solicitud.solicitante ==
                                          "Maquinaría"
                                            ? false
                                            : true,
                                        md4:
                                          _vm.solicitud.solicitante ==
                                          "Maquinaría"
                                            ? true
                                            : false
                                      }
                                    },
                                    [
                                      _c("searcher", {
                                        attrs: {
                                          configuration: _vm.searchConfig,
                                          itemSelected: _vm.fraccionamiento
                                        },
                                        on: {
                                          "update:itemSelected": function(
                                            $event
                                          ) {
                                            _vm.fraccionamiento = $event
                                          },
                                          "update:item-selected": function(
                                            $event
                                          ) {
                                            _vm.fraccionamiento = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider"),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _vm._l(_vm.materiales, function(
                                    material,
                                    index
                                  ) {
                                    return [
                                      _c(
                                        "v-layout",
                                        { key: index, attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { md3: "" } },
                                            [
                                              _c("searcher", {
                                                attrs: {
                                                  configuration:
                                                    _vm.searchCategories,
                                                  itemSelected:
                                                    material.categoria
                                                },
                                                on: {
                                                  "update:itemSelected": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      material,
                                                      "categoria",
                                                      $event
                                                    )
                                                  },
                                                  "update:item-selected": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      material,
                                                      "categoria",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md3: "" } },
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  items: material.categoria
                                                    ? material.categoria
                                                        .materiales
                                                    : [],
                                                  rules: _vm.rules,
                                                  "return-object": "",
                                                  "item-text": "nombre",
                                                  label: "Material",
                                                  "single-line": ""
                                                },
                                                model: {
                                                  value: material.material,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      material,
                                                      "material",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "material.material"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md3: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    material.material.unidad,
                                                  readonly: "",
                                                  required: "",
                                                  label: "Unidades",
                                                  disabled: ""
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md3: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  type: "number",
                                                  min: "0",
                                                  required: "",
                                                  rules: _vm.rules,
                                                  label: "Cantidad"
                                                },
                                                model: {
                                                  value: material.cantidad,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      material,
                                                      "cantidad",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "material.cantidad"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm.fraccionamiento.id
                                            ? [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { md3: "" } },
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        items: [
                                                          "Manzanas",
                                                          "Calles",
                                                          "Áreas verdes"
                                                        ],
                                                        rules: _vm.rules,
                                                        label: "Distribución",
                                                        "single-line": ""
                                                      },
                                                      model: {
                                                        value:
                                                          material.distribucion,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            material,
                                                            "distribucion",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "material.distribucion"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { md3: "" } },
                                                  [
                                                    material.distribucion ==
                                                    "Manzanas"
                                                      ? _c("v-select", {
                                                          attrs: {
                                                            items:
                                                              _vm
                                                                .fraccionamiento
                                                                .prospecto
                                                                .propuesta[0]
                                                                .only_manzanas,
                                                            "item-text":
                                                              "nombre",
                                                            "item-value": "id",
                                                            rules: _vm.rules,
                                                            label: "Manzanas",
                                                            "return-object": "",
                                                            "single-line": ""
                                                          },
                                                          model: {
                                                            value:
                                                              material.manzana,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                material,
                                                                "manzana",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "material.manzana"
                                                          }
                                                        })
                                                      : material.distribucion ==
                                                        "Calles"
                                                      ? _c("v-select", {
                                                          attrs: {
                                                            items:
                                                              _vm
                                                                .fraccionamiento
                                                                .prospecto
                                                                .propuesta[0]
                                                                .calles,
                                                            "item-text":
                                                              "nombre",
                                                            "item-value": "id",
                                                            rules: _vm.rules,
                                                            label: "Calles",
                                                            "single-line": ""
                                                          },
                                                          model: {
                                                            value:
                                                              material.calle,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                material,
                                                                "calle",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "material.calle"
                                                          }
                                                        })
                                                      : material.distribucion ==
                                                        "Áreas verdes"
                                                      ? _c("v-select", {
                                                          attrs: {
                                                            items:
                                                              _vm
                                                                .fraccionamiento
                                                                .prospecto
                                                                .propuesta[0]
                                                                .areas_verdes,
                                                            "item-text":
                                                              "nombre",
                                                            "item-value": "id",
                                                            rules: _vm.rules,
                                                            label:
                                                              "Áreas verdes",
                                                            "single-line": ""
                                                          },
                                                          model: {
                                                            value:
                                                              material.area_verde,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                material,
                                                                "area_verde",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "material.area_verde"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                material.manzana &&
                                                material.distribucion ==
                                                  "Manzanas"
                                                  ? _c(
                                                      "v-flex",
                                                      { attrs: { md3: "" } },
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            items:
                                                              material.manzana
                                                                .lotes,
                                                            "item-text":
                                                              "nombre",
                                                            "item-value": "id",
                                                            label: "Lotes",
                                                            "single-line": ""
                                                          },
                                                          model: {
                                                            value:
                                                              material.lote,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                material,
                                                                "lote",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "material.lote"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { md3: "" } },
                                                  [
                                                    material.manzana &&
                                                    material.distribucion ==
                                                      "Manzanas" &&
                                                    material.lote
                                                      ? _c("searcher", {
                                                          attrs: {
                                                            configuration: _vm.searchConcepto(
                                                              material.lote
                                                            ),
                                                            itemSelected:
                                                              material.concepto
                                                          },
                                                          on: {
                                                            "update:itemSelected": function(
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                material,
                                                                "concepto",
                                                                $event
                                                              )
                                                            },
                                                            "update:item-selected": function(
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                material,
                                                                "concepto",
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    (material.calle ||
                                                      material.area_verde) &&
                                                    material.distribucion !=
                                                      "Manzanas"
                                                      ? _c("searcher", {
                                                          attrs: {
                                                            configuration:
                                                              _vm.searchConceptosUrbanizacion,
                                                            itemSelected:
                                                              material.concepto
                                                          },
                                                          on: {
                                                            "update:itemSelected": function(
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                material,
                                                                "concepto",
                                                                $event
                                                              )
                                                            },
                                                            "update:item-selected": function(
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                material,
                                                                "concepto",
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { md3: "" } },
                                                  [
                                                    material.manzana &&
                                                    material.distribucion ==
                                                      "Manzanas" &&
                                                    material.lote &&
                                                    material.concepto
                                                      ? _c("searcher", {
                                                          attrs: {
                                                            configuration: _vm.searchRubro(
                                                              material.concepto
                                                                .id,
                                                              material.lote
                                                            ),
                                                            itemSelected:
                                                              material.rubro
                                                          },
                                                          on: {
                                                            "update:itemSelected": function(
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                material,
                                                                "rubro",
                                                                $event
                                                              )
                                                            },
                                                            "update:item-selected": function(
                                                              $event
                                                            ) {
                                                              return _vm.$set(
                                                                material,
                                                                "rubro",
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    (material.calle ||
                                                      material.area_verde) &&
                                                    material.concepto &&
                                                    material.distribucion !=
                                                      "Manzanas"
                                                      ? _c("v-select", {
                                                          attrs: {
                                                            items:
                                                              material.concepto
                                                                .rubros,
                                                            "item-text":
                                                              "nombre",
                                                            "item-value": "id",
                                                            label: "Rubro",
                                                            "return-object": "",
                                                            "single-line": ""
                                                          },
                                                          model: {
                                                            value:
                                                              material.rubro,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                material,
                                                                "rubro",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "material.rubro"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md1: "" } },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            index != 0
                                                              ? _c(
                                                                  "v-btn",
                                                                  _vm._g(
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          "red",
                                                                        small:
                                                                          "",
                                                                        flat:
                                                                          "",
                                                                        dark:
                                                                          "",
                                                                        fab: ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.materiales.splice(
                                                                            index,
                                                                            1
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          dark:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "delete"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v("Eliminar")
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider", { key: index })
                                    ]
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            loading: _vm.vBtnSave.loading,
                            color: "verdeFayal",
                            outline: "",
                            dark: "",
                            flat: ""
                          },
                          on: { click: _vm.storeMateriales }
                        },
                        [
                          _vm._v(
                            "\n                        Guardar\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal" },
                          on: { click: _vm.addMateriales }
                        },
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.vSnackBar.text) + "\n        "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }