var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-data-table", {
                attrs: {
                  flat: "",
                  headers: _vm.vHeaders,
                  "disable-initial-sort": "",
                  items: _vm.conceptos.data,
                  "hide-actions": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c("td", [_vm._v(_vm._s(props.item.nombre))]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass: "badge badge-primary badge-lotes",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  return _vm.openRubrosModal(props.item)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(props.item.rubros.length) +
                                  "\n            "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "v-layout",
                              [
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          icon: "",
                                          flat: "",
                                          dark: "",
                                          small: "",
                                          color: "info",
                                          disabled: !_vm.$root.can(
                                            "update",
                                            _vm.permissions
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openEditConceptoModal(
                                              props.item.id
                                            )
                                          }
                                        },
                                        slot: "activator"
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "verdeFayal" } },
                                          [_vm._v("edit")]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Editar")])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          icon: "",
                                          flat: "",
                                          dark: "",
                                          small: "",
                                          color: "error",
                                          disabled: !_vm.$root.can(
                                            "remove",
                                            _vm.permissions
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteConcept(
                                              props.item.id
                                            )
                                          }
                                        },
                                        slot: "activator"
                                      },
                                      [_c("v-icon", [_vm._v("delete")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Eliminar")])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("pagination", {
                    attrs: { limit: _vm.vPaginator.limit, data: _vm.conceptos },
                    on: { "pagination-change-page": _vm.getConceptos }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("edit-concepts-modal", {
        attrs: {
          type: "obra",
          show: _vm.showEditConceptModal,
          conceptId: _vm.editConceptId,
          permissions: _vm.permissions_rubro
        },
        on: {
          "update:show": function($event) {
            _vm.showEditConceptModal = $event
          },
          "update:conceptId": function($event) {
            _vm.editConceptId = $event
          },
          "update:concept-id": function($event) {
            _vm.editConceptId = $event
          }
        }
      }),
      _vm._v(" "),
      _c("rubros-per-conceptos-modal", {
        attrs: {
          show: _vm.showSeeRubrosPerConceptos,
          concepto: _vm.currentConcepto
        },
        on: {
          "update:show": function($event) {
            _vm.showSeeRubrosPerConceptos = $event
          },
          "update:concepto": function($event) {
            _vm.currentConcepto = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }