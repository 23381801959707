var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.dialog,
            persistent: "",
            "max-width": "700px",
            fullscreen: "",
            "hide-overlay": ""
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "verdeFayal" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-toolbar-title", [
                    _vm._v(
                      "Fraccionamiento: " +
                        _vm._s(
                          _vm.localFraccionamiento.prospecto.nombre_proyecto
                        )
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-expansion-panel",
                        { attrs: { focusable: "" } },
                        _vm._l(
                          _vm.localFraccionamiento.prospecto
                            .propuestas_lotificacion[0].manzanas,
                          function(manzana, manzanasIndex) {
                            return _c(
                              "v-expansion-panel-content",
                              {
                                key: manzanasIndex,
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [
                                          _c("div", [
                                            _vm._v(
                                              "Manzana: " +
                                                _vm._s(manzana.nombre)
                                            )
                                          ])
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "v-card",
                                  [
                                    _c(
                                      "v-card-text",
                                      [
                                        _c("v-data-table", {
                                          staticClass: "elevation-1",
                                          attrs: {
                                            headers: _vm.headers,
                                            items: manzana.lotes
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "items",
                                                fn: function(props) {
                                                  return [
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.item.nombre
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.item.modelo
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.item
                                                            .prototipo_vivienda
                                                            .plantas
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.item
                                                            .prototipo_vivienda
                                                            .recamaras
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("td", [
                                                      _vm._v(
                                                        _vm._s(
                                                          props.item
                                                            .prototipo_vivienda
                                                            .baños
                                                        )
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      [
                                                        _c("v-switch", {
                                                          attrs: {
                                                            color: "verdeFayal",
                                                            label: props.item
                                                              .promocion_tipo
                                                              ? "Externo"
                                                              : "Fayal"
                                                          },
                                                          model: {
                                                            value:
                                                              props.item
                                                                .promocion_tipo,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                props.item,
                                                                "promocion_tipo",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "props.item.promocion_tipo"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "td",
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            color: "verdeFayal",
                                                            label: "Precio",
                                                            type: "number",
                                                            min: "1"
                                                          },
                                                          model: {
                                                            value:
                                                              props.item
                                                                .precio_total,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                props.item,
                                                                "precio_total",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "props.item.precio_total"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    props.item.estado
                                                      ? _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.item.estado
                                                                .information
                                                                .estado
                                                            )
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    props.item.estado
                                                      ? _c(
                                                          "td",
                                                          [
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: ""
                                                                }
                                                              },
                                                              [
                                                                props.item
                                                                  .estado
                                                                  .information
                                                                  .estado !=
                                                                "Por promocionar"
                                                                  ? _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          slot:
                                                                            "activator",
                                                                          flat:
                                                                            "",
                                                                          icon:
                                                                            "",
                                                                          color:
                                                                            "verdeFayal"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.openClienteModal(
                                                                              props
                                                                                .item
                                                                                .id
                                                                            )
                                                                          }
                                                                        },
                                                                        slot:
                                                                          "activator"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "local_atm"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Manejar datos de venta"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      slot:
                                                                        "activator",
                                                                      icon: "",
                                                                      flat: "",
                                                                      dark: "",
                                                                      small: "",
                                                                      color:
                                                                        "warning"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.openFilesModal(
                                                                          props
                                                                            .item
                                                                            .id
                                                                        )
                                                                      }
                                                                    },
                                                                    slot:
                                                                      "activator"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "folder"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Archivos"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      slot:
                                                                        "activator",
                                                                      flat: "",
                                                                      icon: "",
                                                                      color:
                                                                        "warning"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.promocionarCreate(
                                                                          props.item
                                                                        )
                                                                      }
                                                                    },
                                                                    slot:
                                                                      "activator"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "near_me"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Promocionar"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-tooltip",
                                                              {
                                                                attrs: {
                                                                  bottom: ""
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      slot:
                                                                        "activator",
                                                                      flat: "",
                                                                      icon: "",
                                                                      color:
                                                                        "error"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.despromocionar(
                                                                          props.item
                                                                        )
                                                                      }
                                                                    },
                                                                    slot:
                                                                      "activator"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "near_me"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._v(" "),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    "Retirar promocion"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }
                        ),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("cliente-promocion-modal", {
        attrs: { show: _vm.showClienteModal, "lote-id": _vm.currentLoteId },
        on: {
          "update:show": function($event) {
            _vm.showClienteModal = $event
          },
          "update:loteId": function($event) {
            _vm.currentLoteId = $event
          },
          "update:lote-id": function($event) {
            _vm.currentLoteId = $event
          }
        }
      }),
      _vm._v(" "),
      _c("upload-files-modal", {
        attrs: {
          element_type: "App\\LoteManzanaLotificacion",
          element_id: _vm.currentLoteId,
          dialog: _vm.showFilesModal
        },
        on: {
          close: function($event) {
            _vm.showFilesModal = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }