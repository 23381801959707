var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1350px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "", tabs: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Administrar requisición")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        slot: "extension",
                        centered: "",
                        color: "white",
                        "slider-color": "verdeFayal"
                      },
                      slot: "extension",
                      model: {
                        value: _vm.vTabs,
                        callback: function($$v) {
                          _vm.vTabs = $$v
                        },
                        expression: "vTabs"
                      }
                    },
                    [
                      _c(
                        "v-tab",
                        { attrs: { href: "#tab-edit-requisicion" } },
                        [_vm._v("Datos de la requisición")]
                      ),
                      _vm._v(" "),
                      _c("v-tab", { attrs: { href: "#tab-index-material" } }, [
                        _vm._v("Lista de materiales")
                      ]),
                      _vm._v(" "),
                      _vm.requisicion.visto_bueno != true ||
                      _vm.requisicion.visto_bueno_operaciones != true
                        ? _c(
                            "v-tab",
                            { attrs: { href: "#tab-add-material" } },
                            [_vm._v("Añadir materiales")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.vTabs,
                    callback: function($$v) {
                      _vm.vTabs = $$v
                    },
                    expression: "vTabs"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "tab-edit-requisicion" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md4: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          label: "Nombre de la orden"
                                        },
                                        model: {
                                          value:
                                            _vm.requisicion.nombre_requisicion,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.requisicion,
                                              "nombre_requisicion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "requisicion.nombre_requisicion"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md4: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.solicitante,
                                          required: "",
                                          label: "Seleccione solicitante"
                                        },
                                        model: {
                                          value: _vm.requisicion.solicitante,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.requisicion,
                                              "solicitante",
                                              $$v
                                            )
                                          },
                                          expression: "requisicion.solicitante"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md4: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          disabled: "",
                                          required: "",
                                          label: "Estado"
                                        },
                                        model: {
                                          value:
                                            _vm.requisicion.estado.information
                                              .estado,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.requisicion.estado
                                                .information,
                                              "estado",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "requisicion.estado.information.estado"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.requisicion.estado.information.estado ==
                                  "Aprobada"
                                    ? _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "date",
                                              required: "",
                                              label: "Fecha de llegada"
                                            },
                                            model: {
                                              value:
                                                _vm.requisicion
                                                  .fecha_de_llegada,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.requisicion,
                                                  "fecha_de_llegada",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "requisicion.fecha_de_llegada"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          label: "Descripcion"
                                        },
                                        model: {
                                          value: _vm.requisicion.descripcion,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.requisicion,
                                              "descripcion",
                                              $$v
                                            )
                                          },
                                          expression: "requisicion.descripcion"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "tab-index-material" } },
                    [
                      _c(
                        "v-layout",
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-data-table", {
                                    attrs: {
                                      headers: _vm.vHeaders,
                                      items: _vm.materials_getted,
                                      "hide-actions": ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "items",
                                        fn: function(props) {
                                          return [
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  props.item.proveedor
                                                    ? props.item.proveedor
                                                        .nombre_completo
                                                    : "Aún sin proveedor"
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  props.item.material
                                                    .categoria_asociada
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  props.item.material.nombre
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(props.item.cantidad)
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    props.item.material.precio
                                                  )
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getStatus(props.item)
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              [
                                                _c(
                                                  "v-layout",
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      { attrs: { bottom: "" } },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              slot: "activator",
                                                              icon: "",
                                                              flat: "",
                                                              dark: "",
                                                              small: "",
                                                              color:
                                                                "verdeFayal"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openShoppingModal(
                                                                  props.item.id
                                                                )
                                                              }
                                                            },
                                                            slot: "activator"
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "shopping_cart"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            "Añadir datos de venta"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-tooltip",
                                                      { attrs: { bottom: "" } },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              slot: "activator",
                                                              icon: "",
                                                              flat: "",
                                                              dark: "",
                                                              small: "",
                                                              disabled:
                                                                props.item
                                                                  .surtido ==
                                                                true,
                                                              color: "error"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.deleteMaterialValue(
                                                                  props.item.id
                                                                )
                                                              }
                                                            },
                                                            slot: "activator"
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("delete")
                                                            ])
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v("Eliminar")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "tab-add-material" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { "lazy-validation": "" },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        _vm._l(_vm.materiales, function(material, index) {
                          return _c(
                            "v-container",
                            { key: index, attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md3: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.proveedores,
                                          required: "",
                                          label: "Proveedor",
                                          "item-value": "id",
                                          "item-text": "nombre_completo"
                                        },
                                        model: {
                                          value: material.proveedor_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              material,
                                              "proveedor_id",
                                              $$v
                                            )
                                          },
                                          expression: "material.proveedor_id"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md3: "" } },
                                    [
                                      _c("searcher", {
                                        attrs: {
                                          configuration: _vm.searchCategories,
                                          itemSelected: material.categoria
                                        },
                                        on: {
                                          "update:itemSelected": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              material,
                                              "categoria",
                                              $event
                                            )
                                          },
                                          "update:item-selected": function(
                                            $event
                                          ) {
                                            return _vm.$set(
                                              material,
                                              "categoria",
                                              $event
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md3: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: material.categoria
                                            ? material.categoria.materiales
                                            : [],
                                          rules: _vm.rules,
                                          "return-object": "",
                                          "item-text": "nombre",
                                          label: "Material",
                                          "single-line": ""
                                        },
                                        model: {
                                          value: material.material,
                                          callback: function($$v) {
                                            _vm.$set(material, "material", $$v)
                                          },
                                          expression: "material.material"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md3: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          value: material.material.unidad,
                                          readonly: "",
                                          required: "",
                                          label: "Unidades",
                                          disabled: ""
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md3: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          min: "0",
                                          required: "",
                                          rules: _vm.rules,
                                          label: "Cantidad"
                                        },
                                        model: {
                                          value: material.cantidad,
                                          callback: function($$v) {
                                            _vm.$set(material, "cantidad", $$v)
                                          },
                                          expression: "material.cantidad"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md3: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-icon": "attach_money",
                                          value: material.material.precio,
                                          readonly: "",
                                          disabled: "",
                                          required: "",
                                          label: "Precio unitario"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md3: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-icon": "attach_money",
                                          value:
                                            parseInt(material.material.precio) *
                                            parseInt(material.cantidad),
                                          readonly: "",
                                          disabled: "",
                                          label: "Precio total"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md3: "" } },
                                    [
                                      index != 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                fab: "",
                                                flat: "",
                                                dark: "",
                                                small: "",
                                                color: "verdeFayal"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.materiales.splice(
                                                    index,
                                                    1
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { dark: "" } },
                                                [_vm._v("remove")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider")
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.vTabs == "tab-edit-requisicion"
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            dark: "",
                            color: "verdeFayal",
                            flat: "",
                            loading: _vm.vBtnSave.loading
                          },
                          on: { click: _vm.updateRequisicion }
                        },
                        [_vm._v(" Guardar ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.vTabs == "tab-add-material"
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            dark: "",
                            color: "verdeFayal",
                            flat: "",
                            loading: _vm.vBtnSave.loading
                          },
                          on: { click: _vm.storeAddMaterial }
                        },
                        [_vm._v(" Guardar ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.vTabs == "tab-add-material"
                    ? _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal" },
                          on: { click: _vm.addMateriales }
                        },
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n      " + _vm._s(_vm.vSnackBar.text) + "\n      "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("add-provider-to-requisitions", {
        attrs: {
          show: _vm.showAddProvideModal,
          "requisicion-material": _vm.currentRequisicionMaterial,
          "material-category": _vm.currentMaterialCategory
        },
        on: {
          "update:show": function($event) {
            _vm.showAddProvideModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("shopping-requisiciones-modal", {
        attrs: {
          show: _vm.showShoppingModal,
          "material-requisicion-id": _vm.currentMaterialRequisicionId
        },
        on: {
          "update:show": function($event) {
            _vm.showShoppingModal = $event
          },
          "update:materialRequisicionId": function($event) {
            _vm.currentMaterialRequisicionId = $event
          },
          "update:material-requisicion-id": function($event) {
            _vm.currentMaterialRequisicionId = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }