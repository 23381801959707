<template>
<div class="text-xs-center">
    <v-card flat>
      <v-container grid-list-md>
        <v-data-table disable-initial-sort :items="payments.data" hide-actions>
          <template slot="headers">
            <tr>
              <td>Fecha de pago</td>
              <td>Prima vacacional</td>
              <td>Monto</td>
              <td>Total</td>
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <td>{{ props.item.created_at }}</td>
            <td v-if="puesto == 'Raya'">$ {{ props.item.prima_vacacional | currency}}</td>
            <td v-else>No aplica</td>
            <td v-if="puesto == 'Raya'">$ {{ props.item.monto | currency}}</td>
            <td v-else>No aplica</td>
            <td>$ {{ props.item.total | currency}}</td>
            <!--<td>
              <v-tooltip bottom>
                <v-btn flat icon slot="activator" color="warning" @click="openStatusModal(props.item.id)">
                  <v-icon>low_priority</v-icon>
                </v-btn>
                <span>Estados</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn icon flat dark small slot="activator" color="error" @click="deletePayment(props.item.id)">
                  <v-icon>delete</v-icon>
                </v-btn>
                <span>Eliminar</span>
              </v-tooltip>
            </td>-->
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <pagination :limit="vPaginator.limit" :data="payments" @pagination-change-page="getPayments"></pagination>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-container>
    </v-card>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    payments: {
      current_page: 1,
      data: []
    },
    vHeaders: [{
        text: 'Fecha de pago',
        value: 'fecha_de_pago'
      },
      {
        text: 'Prima vacacional',
        value: 'prima_vacacional'
      },
      {
        text: 'Monto',
        value: 'monto'
      },
      {
        text: 'Total',
        value: 'total'
      },
      {
        text: 'Acciones',
        value: 'acciones',
        sortable: false
      }
    ],
    showCreateModal: false,
    showEditModal: false,
    currentWorkerId: null,
    currentPaymentId: null,
    showStatusModal: false,
    vPaginator: {
      limit: 10
    },
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    workerId: String,
    puesto: String
  },
  watch: {
    showCreateModal: function() {
      this.getPayments(this.payments.current_page);
    },
    showStatusModal: function() {
      this.getPayments(this.payments.current_page);
    }
  },
  methods: {
    getPayments: function(page = 1) {
      axios.get(`/trabajadores/${this.workerId}/pagos?page=${page}`)
      .then( response => this.payments = response.data )
      .catch( error =>  errorHandling(error));
    },
    deletePayment: function (paymentId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('pagos.destroy', paymentId))
          .then(response => {
            this.getPayments(this.payments.current_page);
            alertify.success(response.data.message_text);
          })
          .catch(error => {
            errorHandling(error);
          });
        }
      })
    },
    openCreateModal: function(paymentId) {
      this.showCreateModal = true;
      this.currentWorkerId = this.workerId;
    },
    editPayment: function(paymentId) {
      this.currentWorkerId = this.workerId;
      this.currentPaymentId = paymentId;
      this.showEditModal = true;
    },
    openStatusModal: function(paymentId) {
      this.showStatusModal = true;
      this.currentPaymentId = paymentId;
    },
  },
  mounted: function () {
    this.getPayments(this.payments.current_page); 
  }
}
</script>
