<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Editar pago</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout v-if="pago.trabajador.puesto == 'Destajo'">
            <v-flex md3>
              <v-autocomplete 
                v-model="pago.fraccionamiento_id" 
                item-value="id" 
                item-text="nombre_proyecto" 
                :items="fraccionamientos" 
                label="Proyecto"
                @change="getFraccionamiento"
              ></v-autocomplete>
            </v-flex>
            <v-flex md3>
              <v-select 
                :disabled="(pago.trabajador.fraccionamiento_id) ? false : true" 
                :items="['Obra', 'Urbanizacion']" 
                @change="getEtapas"
                label="Tipo" 
                v-model="pago.trabajador.etapa.tipo"
              ></v-select>
            </v-flex>
            <!-- <v-flex md3>
              <v-autocomplete :disabled="(tipo) ? false : true" v-model="trabajador.etapa" return-object item-value="id" item-text="nombre" :items="filterEtapas" label="Etapas"></v-autocomplete>
            </v-flex>
            <v-flex md3 v-if="trabajador.etapa">
              <v-text-field readonly v-model="trabajador.etapa.fecha_fin" required label="Fecha final de etapa"></v-text-field>
            </v-flex> -->
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="updateCategory" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    pago: {
      trabajador: {
        etapa: {
          tipo: null
        }
      }
    },
    estados: [],
    fraccionamientos: [],
    etapas: [],
    localShow: false,
    filterEtapas: [],
    vBtnSave: {
      loading: false
    },
    desglose: [
      {
        distribucion: null,
        itemsLote: [],
        lote: {},
        itemsConceptos: [],
        concepto: {},
        itemsRubros: [],
        rubro: {},
        pago: 0
      }
    ],
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    paymentId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    paymentId: function (value) {
      this.getPayment();
    },
    localShow: function (value) {
      this.$emit('update:show', value)
    }
  },
  methods: {
    getPayment: function () {
      axios.get(laravelRoute('pagos.show', this.paymentId))
        .then(response => this.pago = response.data)
        .catch(error => errorHandling(error));
    },
    getStatus: function() {
      axios.get('/estatus/pago')
      .then(response => this.estados = response.data)
      .catch(error => errorHandling(error));
    },
    getProspectos: function () {
        axios.get(laravelRoute('fraccionamientos.index'))
        .then(response => this.fraccionamientos = response.data)
        .catch(error => errorHandling(error));
    },
    getFraccionamiento: function() {
        axios.get(laravelRoute('fraccionamientos.show', this.trabajador.fraccionamiento_id))
        .then(response => {
          this.fraccionamiento = response.data;
          this.etapas = response.data.etapas;
          this.desglose = [{}]
          this.pago.trabajador.etapa = {}
        })
        .catch(error => errorHandling(error));
    },
    getEtapas: function() {
      this.filterEtapas = [];
      this.etapas.forEach(etapa => {
        if(etapa.tipo == this.tipo.toLowerCase()) {
          this.filterEtapas.push(etapa);
        }
      });
    },
    updateCategory: function () {
      this.vBtnSave.loading = true;
      axios.patch('/categorias/' + this.paymentId, {
          nombre: this.category.nombre,
          descripcion: this.category.descripcion,
          tipo: this.category.tipo
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.vBtnSave.loading = false;
          this.localShow = false;
        })
        .catch(error => {
          this.vBtnSave.loading = false;
          errorHandling(error)
        });
    }
  },
  created: function() {
    this.getProspectos();
    this.getStatus();
  }
}
</script>
