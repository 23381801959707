var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        "v-card",
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("div", {}, [
              _c(
                "a",
                { attrs: { href: "../facturas" } },
                [
                  _c(
                    "v-btn",
                    { staticClass: "white--text", attrs: { color: "#1ABA24" } },
                    [_vm._v("Ir a facturas")]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [_vm._v("Ver factura")])
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { attrs: { "grid-list-md": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Proveedor",
                              disabled: ""
                            },
                            model: {
                              value: _vm.factura.proveedor,
                              callback: function($$v) {
                                _vm.$set(_vm.factura, "proveedor", $$v)
                              },
                              expression: "factura.proveedor"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: "",
                              color: "#1ABA24",
                              label: "Numero de factura"
                            },
                            model: {
                              value: _vm.factura.numero_factura,
                              callback: function($$v) {
                                _vm.$set(_vm.factura, "numero_factura", $$v)
                              },
                              expression: "factura.numero_factura"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: "",
                              color: "#1ABA24",
                              label: "Monto",
                              type: "date"
                            },
                            model: {
                              value: _vm.factura.monto,
                              callback: function($$v) {
                                _vm.$set(_vm.factura, "monto", $$v)
                              },
                              expression: "factura.monto"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: "",
                              color: "#1ABA24",
                              label: "Fecha de emision"
                            },
                            model: {
                              value: _vm.factura.fecha_emision,
                              callback: function($$v) {
                                _vm.$set(_vm.factura, "fecha_emision", $$v)
                              },
                              expression: "factura.fecha_emision"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: "",
                              color: "#1ABA24",
                              label: "Fecha de vigencia"
                            },
                            model: {
                              value: _vm.factura.fecha_vigencia,
                              callback: function($$v) {
                                _vm.$set(_vm.factura, "fecha_vigencia", $$v)
                              },
                              expression: "factura.fecha_vigencia"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: "",
                              color: "#1ABA24",
                              label: "Fecha de pago"
                            },
                            model: {
                              value: _vm.factura.pagada_at,
                              callback: function($$v) {
                                _vm.$set(_vm.factura, "pagada_at", $$v)
                              },
                              expression: "factura.pagada_at"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "", md12: "" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              disabled: "",
                              color: "#1ABA24",
                              label: "Comentarios"
                            },
                            model: {
                              value: _vm.factura.comentario,
                              callback: function($$v) {
                                _vm.$set(_vm.factura, "comentario", $$v)
                              },
                              expression: "factura.comentario"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }