<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Nuevo usuario</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md12>
              <v-autocomplete v-model="vAutocompleteWorker.select" chips :items="vAutocompleteWorker.workers" :loading="vAutocompleteWorker.isLoading" v-bind:search-input.sync="vAutocompleteWorker.search" label="Trabajador" item-text="nombre_completo" item-value="id" browser-autocomplete="off" hint="Ingresa por lo menos 3 caracteres" return-object></v-autocomplete>
            </v-flex>
            <v-flex md12>
              <v-select :items="roles" item-text="name" item-value="name" chips v-model="rolesSelected" label="Rol" single-line ></v-select>
            </v-flex>
            <v-flex md12>
              <v-text-field type="email" v-model="usuario.email" required label="Correo electrónico"></v-text-field>
            </v-flex>
            <v-flex md12>
                <v-text-field type="password" v-model="usuario.contraseña" required label="Contraseña"></v-text-field>
            </v-flex>
            <v-flex md12>
              <v-text-field type="password" v-model="usuario.confirmar_contraseña" required label="Confirmar contraseña"></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-switch color="verdeFayal" label="Seguir registrando" v-model="keepStoring"></v-switch>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="storeUser" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    usuario: {
      email: null,
      contraseña: null
    },
    vAutocompleteWorker: {
      isLoading: false,
      workers: [],
      search: null,
      select: {},
    },
    roles: [],
    rolesSelected: [],
    keepStoring: true,
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    searchWorker(input) {
      this.debouncedGetWorkers()
    }
  },
  computed: {
    searchWorker: function () {
      return this.vAutocompleteWorker.search
    }
  },
  methods: {
    storeUser: function () {
      this.vBtnSave.loading = true;
      axios.post(laravelRoute('usuarios.store'), {
        name: this.vAutocompleteWorker.select.nombre_completo,
        worker_id: this.vAutocompleteWorker.select.id,
        email: this.usuario.email,
        password: this.usuario.contraseña,
        password_confirmation : this.usuario.confirmar_contraseña,
        roles: this.rolesSelected
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.usuario = {};
        this.vAutocompleteWorker.select = {};
        this.vAutocompleteWorker.workers = [];
        this.rolesSelected = null;  
        this.localShow = (!this.keepStoring) ? false : true;
      })
      .catch(error => {
        errorHandling(error)
      })
      .finally(()=>this.vBtnSave.loading = false);
    },
    getWorkers: function() {
      if (!this.vAutocompleteWorker.search || this.vAutocompleteWorker.search.length < 3) return
      this.vAutocompleteWorker.isLoading = true
      let queryParam = {
        query: this.vAutocompleteWorker.search
      }
      return axios.get(laravelRoute('trabajadores.index', queryParam))
      .then(response => {
        this.vAutocompleteWorker.workers = response.data.data ? response.data.data : response.data
        this.vAutocompleteWorker.isLoading = false
      })
      .catch(error => errorHandling(error))
    },
    getRoles: function() {
      axios.get('/roles')
      .then( response => this.roles = response.data )
      .catch( error =>  errorHandling(error));
    },
  },
  mounted: function () {
    this.getRoles(); 
  },
  created() {
    this.debouncedGetWorkers = _.debounce(() => this.getWorkers(), 500)
  }
}
</script>
