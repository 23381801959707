<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="550px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Añadir material faltante</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
            <div>
                <span class="text-center">Añade la cantidad faltante a surtir al material {{ material.material.nombre }}, la cantidad agregada se sumará al stock</span>
            </div>
        </v-card-title>
        <v-card-text>
            <v-container grid-list-md>
                <v-layout row>
                    <v-flex xs6>
                        <div class="text-center">
                            <h5>Cantidad surtida</h5>
                            <span style="background-color: #1d7423;" class="badge badge-secondary">
                                {{ material.cantidad_a_surtir }}
                            </span>
                        </div>
                    </v-flex>
                    <v-flex xs6>
                        <div class="text-center">
                            <h5>Cantidad faltante</h5>
                            <span style="background-color: #1d7423;" class="badge badge-secondary">
                                {{ material.cantidad - material.cantidad_a_surtir }}
                            </span>
                        </div>
                    </v-flex>
                    <v-flex xs6>
                        <v-text-field
                            :rules="rulesForMaterial()"
                            v-model="cantidad"
                            label="Cantidad a surtir"
                        ></v-text-field>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="verdeFayal" :loading="vBtnSave.loading" flat @click="addMaterial">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { laravelRoute } from '../../lib/utilities';

export default {
  data: () => ({
    material: {
        material: {},
        cantidad: 0,
        cantidad_a_surtir: 0
    },
    cantidad: 1,
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    requisicionMaterialId: Number,
    requisicionId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    requisicionMaterialId: function(value) {
      this.getRequisicionMaterial();
    }
  },
  methods: {
        /*getRequisicionMaterial: function() {
            axios.get(`/requisiciones/materiales/${this.requisicionMaterialId}`)
            .then(response => this.material = response.data) 
            .catch( error => errorHandling(error));
        },
        */
       getRequisicionMaterial: function() {
            axios.post('/requisiciones/materiales/consultar',{
              id_material: this.requisicionMaterialId,
              id_requisicion: this.requisicionId,
            })
            .then(response => this.material = response.data) 
            .catch( error => errorHandling(error));
        },
       

        rulesForMaterial: function() {
            return [
                (v) => v && v > 0 || 'La cantidad debe ser mayor a 0',
                (v) => v && !isNaN(v) || 'La cantidad debe ser un número',
                (v) => {
                    return this.material.cantidad - this.material.cantidad_a_surtir >= v || 'Límite excedido';
                },
            ];
        },
        addMaterial: function() {
            this.vBtnSave.loading = true;
            axios.post(`/requisiciones/surtir_faltante/${this.material.id}`, {
                cantidad: this.cantidad,
                requisicion_id: this.material.requisicion_id
            })
            .then(response => {
                this.getRequisicionMaterial();
                this.vSnackBar.text = response.data.message_text;
                this.vSnackBar.visible = true;
            }) 
            .catch( error => errorHandling(error))
            .finally(() => this.vBtnSave.loading = false);;
        }
  }
}
</script>
