<template>
    <div class="text-xs-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <form action v-on:submit.prevent.once="createStatus()">
          <v-card-title class="headline grey lighten-2" primary-title>
            <v-flex md8>
              <p>Seleccione el estado</p>
            </v-flex>
          </v-card-title>
          <v-card-text>
            <v-flex>
              <div class="wrapper-table">
                <span class="table-title">Historial</span>
                <table class="table-history">
                  <thead class="table-head-history">
                    <tr>
                      <th>Estado</th>
                      <th>Usuario</th>
                      <th>Fecha</th>
                    </tr>
                  </thead>
                  <tbody class="table-body-history">
                    <tr v-for="(item, index) in localEstados" :key="index">
                      <td>{{item.information ? item.information.estado : 'No disponible'}}</td>
                      <td>{{item.user.name}}</td>
                      <td>{{item.created_at}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-flex>
          </v-card-text>
          <v-card-text>
            <v-flex xs12 sm6 md6>
              <v-select
                color="verdeFayal"
                :items="status"
                label="Seleccione status"
                v-model="estado.estado"
                item-text="estado"
                item-value="id"
              ></v-select>
            </v-flex>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="$emit('close')">Cerrar</v-btn>
            <v-btn color="primary" type="submit" :disabled="$store.state.saveDisabled" flat>Guardar</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import axios from "axios";
  import swal from "sweetalert";
  import { errorHandling, laravelRoute } from "../../lib/utilities";

  export default {
    props: ['dialog', 'estados', 'estado', 'dad_id'],
    data(){
      return{
        status: [],
        localEstados: [],
      }
    },
    watch:{
      dialog: function(value){
        if(value){
          axios.get("/tramites_dependencias/estados").then(response => {
            this.status = response.data;
          });
          axios.get(`/estados/tramites_dependencias/${this.dad_id}`).then(response => {
            this.localEstados = response.data;
          });
        }
      },
    },

    methods:{
      createStatus: function(){
       axios
        .post("/estados/tramites_dependencias", {
          estado: this.estado.estado,
          tramite_id: this.dad_id
        })
        .then(response => {
          this.estados.push(response.data.data);
          if (response.data.message_text)
          {
            alertify.success(response.data.message_text);
            this.$root.cleanStore();
            this.$emit("close");
          }
        })
        .catch(error => errorHandling(error))
        .finally(() => window.location.href = "/tramites_dependencias");
      }
    },
  }
</script>

<style>
  .wrapper-table{
    display: grid;
  }
  .table-history{
    width: 100%;
    display: grid;
  }
  .table-title{
    display: grid;
    margin: auto;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 3px;
  }
  .table-head-history tr{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .table-body-history tr{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .without-status{
    display: grid;
    margin: 0 auto;
  }
</style>