<template>
  <v-layout>
    <v-container>
      <v-card>
        <v-flex xs12>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Facturas</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <v-btn class="fyl-btnCreate" @click="openCreateModal">Crear</v-btn>
          </v-toolbar>
          <v-data-table :headers="headers" disable-initial-sort :items="facturas.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td class="td-ellipsis">{{ props.item.proveedor }}</td>
              <td class="td-ellipsis">{{ props.item.numero_factura }}</td>
              <td class="td-ellipsis">{{ props.item.monto }}</td>
              <td class="td-ellipsis">{{ props.item.fecha_vigencia }}</td>
              <td class="td-ellipsis">{{ props.item.plazo }}</td>
              <td class="td-ellipsis">{{ props.item.pagada_at }}</td>
              <td>
                <v-tooltip bottom>
                  <v-checkbox
                    flat
                    :disabled="($root.can('update', permisos) ? false : true)"
                    v-model="props.item.estado"
                    slot="activator"
                    color="indigo"
                    @change="pagarFactura(props.item)"
                  >
                    <v-icon>launch</v-icon>
                  </v-checkbox>
                  <span v-if="props.item.estado == 0 || props.item.estado == 3">Marcar como pagada</span>
                  <span v-else>Factura Pagada</span>
                </v-tooltip>
              </td>
              <td>
                <v-tooltip bottom>
                  <v-btn
                    :disabled="$root.can('update', permisos) ? false : true"
                    icon
                    flat
                    dark
                    small
                    slot="activator"
                    color="info"
                    @click="editFactura(props.item.id)"
                  >
                    <v-icon>edit</v-icon>
                  </v-btn>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn
                    :disabled="$root.can('remove', permisos) ? false : true"
                    icon
                    flat
                    dark
                    small
                    slot="activator"
                    color="error"
                    @click="eliminar(props.item)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="facturas" @pagination-change-page="getFacturas"></pagination>
          </v-card-actions>
        </v-flex>
      </v-card>
    </v-container>
    <contability-create-invoice
      v-if="$root.can('new', permisos) ? true : false"
      :factura="factura"
      :facturas="facturas"
      :prov="prov"
      :show.sync="showCreateModal"
      @close="cleanForm()"
      :mode.sync="mode"
    ></contability-create-invoice>
  </v-layout>
</template>

<script>
import { errorHandling, laravelRoute } from "../../lib/utilities";
import axios from 'axios';
import swal from 'sweetalert';

export default {
  props: ["permisos", "prov"],

  data() {
    return {
      search: "",
      dialog: false,
      factura: {},
      mode: null,
      facturas: {
        data: []
      },
      showCreateModal: false,
      creadosProps: this.prov,
      vPaginator: {
        limit: 10
      },
      headers: [
        // { text: "ID", value: "id", sortable: true },
        { text: "Proveedor", value: "proveedor", sortable: true },
        { text: "Numero de factura", value: "numero_factura", sortable: true },
        { text: "Monto", value: "monto", sortable: true },
        { text: "Fecha pago", value: "created_at", sortable: true },
        { text: "Plazo", value: "plazo", sortable: true },
        { text: "Fecha de pago", value: "pagada_at", sortable: true },
        { text: "Pagada", value: "estado", sortable: false }
      ],
      pagination: {
        sortBy: 'id',
        descending: true
      },
    };
  },
  watch: {
    showCreateModal: function (value) {
      if (!value) {
        this.getFacturas();
      }
    }
  },
  computed:{
    getFacturasProps: function(){
      return this.facturasProps;
    }
  },
  methods: {
    getFacturas: function (page = 1) {
      axios.get(`/facturas?page=${page}&query=${this.search}`)
      .then(response => {
        this.facturas = response.data;
      })
      .catch(error => errorHandling(error));
    },
    pagarFactura: function(factura){
      swal({
        title: "¿Estás seguro?",
        text: factura.estado == true ? "Una vez marcado como pagado no se podrá revertir" : "Cuidado, está desmarcando como pagado",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then( async willPay => {
        if (willPay) {
          await this.storePago(factura);
          if (factura.estado == true) {
            swal("Se registró el pago correctamente", {
              icon: "success"
            }); 
          } else {
            swal("Se desmarcó como pagado correctamente", {
              icon: "success"
            }); 
          }
          this.getFacturas();
        } else {
          if (factura.estado == false) {
            swal("No se desmarcó como pagado");
            this.facturas[this.facturas.indexOf(factura)].estado = true;
          } else {
            swal("No se marcó pagado");
            this.facturas[this.facturas.indexOf(factura)].estado = false;
          }
        }
      });
    },
    storePago: function(factura){
      axios
        .patch(route('facturas.pagar', factura.id),)
        .then(response => {
          if (response.data.message_text)
            alertify.success(response.data.message_text);
        })
        .catch(error => errorHandling(error));
    },
    eliminar: function(facturaId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('facturas.destroy', facturaId))
            .then(response => {
              this.getFacturas();
              alertify.success(response.data.message_text);
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      })
    },
    editFactura: function(facturaId) {
      axios.get(route("facturas.edit", facturaId)).then(response => {
        this.factura = response.data;
        this.showCreateModal = true;
        this.mode = "Editar factura";
      });
    },
    cleanForm() {
      this.factura = {};
      this.dialog = false;
    },
    openCreateModal: function() {
      this.factura = {};
      this.showCreateModal = true;
      this.mode = "Crear factura";
    }
  },
  created() {
    this.getFacturas();
  }
};
</script>

<style>
  .tdfyl th {
    padding: 0px !important;
  }
  .tdfyl td {
    padding: 0px !important;
  }
</style>
