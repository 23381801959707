<template>
    <div class="text-xs-center">
        <v-dialog v-model="localShow" width="550" persistent>
            <v-card>
                <v-toolbar color="white" flat>
                    <v-spacer></v-spacer>
                    <v-toolbar-title class="primary-title">Agregar comentarios</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-flex xs12>
                        <v-list>
                            <template v-for="(item) in comments">
                                <v-divider :key="item.id"></v-divider>
                                <v-container :key="item.id">
                                    <v-layout>
                                        <v-flex md2>
                                            <v-avatar
                                                size="36px"
                                            >
                                                <img src="/img/avatar.svg">
                                            </v-avatar>
                                        </v-flex>
                                        <v-flex md5>
                                            <p align="left" style="font-weight: bold;">{{ item.created_by.name }}</p>
                                        </v-flex>
                                        <v-flex md5>
                                            <p class="grey--text">- {{ getDateFormat(item.created_at) }}</p>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout>
                                        <v-flex xs8 offset-xs2>
                                            <p class="text-justify">{{ item.comentario }}</p>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </template>
                        </v-list>
                    </v-flex>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                   <v-layout>
                        <v-flex xs12>
                            <v-text-field
                                placeholder="Escribe el comentario aquí..."
                                @click:append-outer="storeComment"
                                @keyup.enter.native="storeComment"
                                append-outer-icon="send"
                                color="verdeFayal"
                                v-model="comment"
                                required
                            ></v-text-field>
                        </v-flex>
                   </v-layout>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import moment from "moment";

  export default {
    data() {
      return {
        comments: [],
        comment: null,
        localShow: false,
        vSnackBar: {
            visible: false,
            text: null
        }
      }
    },
    props: {
      show : Boolean,
      modelableId: Number,
      modelableType: String
    },
    watch: {
      show: function (value) {
        this.localShow = value;
      },
      localShow: function (value) {
        this.$emit('update:show', value)
      },
      modelableId: function() {
        this.getComments();
      }
    },
    methods: {
        getComments: function() {
            axios.get(`/comentarios?modelable_id=${this.modelableId}&modelable_type=${this.modelableType}`)
            .then(response => this.comments = response.data)
            .catch(error => errorHandling(error));
        },
        getDateFormat: function(date) {
            return moment(date).fromNow().replace('en', 'Hace')
        },
        storeComment: function() {
            axios.post(laravelRoute('comentarios.store'), {
                comentario: this.comment,
                modelable_id: this.modelableId,
                modelable_type: this.modelableType
            })
            .then(response => {
                this.comment = null;
                this.getComments();
                this.vSnackBar.text = response.data.message_text;
                this.vSnackBar.visible = true;
            })
            .catch(error => errorHandling(error));
        }
    }
    
  }
</script>

<style>
    .dot {
        height: 8px;
        width: 8px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
    }
    .created_at {
        font-size: 12px;
        color: #bbb;
    }
</style>