var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        [
          _c(
            "v-container",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { color: "white", flat: "", tabs: "" } },
                        [_c("v-toolbar-title", [_vm._v("Nóminas")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-data-table",
                        {
                          attrs: {
                            "disable-initial-sort": "",
                            items: _vm.nominas.data,
                            "hide-actions": ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "items",
                              fn: function(props) {
                                return [
                                  _c("td", [
                                    _vm._v(_vm._s(props.item.nombre_nomina))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(props.item.nomina.estatus))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(props.item.nomina.total))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(props.item.nomina.fecha_creacion)
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-tooltip",
                                            { attrs: { bottom: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    slot: "activator",
                                                    icon: "",
                                                    flat: "",
                                                    dark: "",
                                                    small: "",
                                                    color: "verdeFayal",
                                                    href:
                                                      "/areas_nominas/" +
                                                      props.item.id +
                                                      "/edit"
                                                  },
                                                  slot: "activator"
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("visibility")
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v("Ver/editar datos")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("template", { slot: "headers" }, [
                            _c("th", [_vm._v("Nombre de nómina")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Estado")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Total de nómina")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Fecha de creación")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Acciones")])
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }