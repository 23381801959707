var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: { color: "white", flat: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "extension",
                      fn: function() {
                        return [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                centered: "",
                                "slider-color": "verdeFayal"
                              },
                              model: {
                                value: _vm.vTabs,
                                callback: function($$v) {
                                  _vm.vTabs = $$v
                                },
                                expression: "vTabs"
                              }
                            },
                            [
                              _c(
                                "v-tab",
                                { attrs: { href: "#datos-maquinaria-tab" } },
                                [_vm._v("Maquinaria")]
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Editar maquinaria")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.vTabs,
                    callback: function($$v) {
                      _vm.vTabs = $$v
                    },
                    expression: "vTabs"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "datos-maquinaria-tab" } },
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { md6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Nombre de la máquina"
                                    },
                                    model: {
                                      value: _vm.maquinaria.nombre,
                                      callback: function($$v) {
                                        _vm.$set(_vm.maquinaria, "nombre", $$v)
                                      },
                                      expression: "maquinaria.nombre"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "", md6: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      disabled: _vm.maquinaria.proyecto_actual
                                        ? true
                                        : false,
                                      color: "verdeFayal",
                                      items: _vm.estados,
                                      "item-value": "id",
                                      "item-text": "estado",
                                      label: "Seleccione status"
                                    },
                                    model: {
                                      value:
                                        _vm.maquinaria.estado.information.id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.maquinaria.estado.information,
                                          "id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "maquinaria.estado.information.id"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { md12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Descripción",
                                      counter: "250",
                                      type: "text",
                                      min: "0",
                                      max: "250"
                                    },
                                    model: {
                                      value: _vm.maquinaria.descripcion,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.maquinaria,
                                          "descripcion",
                                          $$v
                                        )
                                      },
                                      expression: "maquinaria.descripcion"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _vm.maquinaria.mantenimientos
                                ? _c(
                                    "v-form",
                                    {
                                      ref: "form",
                                      attrs: { "lazy-validation": "" }
                                    },
                                    _vm._l(
                                      _vm.maquinaria.mantenimientos,
                                      function(mantenimiento, index) {
                                        return _c(
                                          "v-container",
                                          {
                                            key: index,
                                            attrs: { "grid-list-md": "" }
                                          },
                                          [
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  sm12: "",
                                                  xs12: "",
                                                  md12: ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-layout",
                                                  { attrs: { wrap: "" } },
                                                  [
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs12: "",
                                                          sm4: "",
                                                          md4: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            items:
                                                              _vm.mantenimientoslistProps,
                                                            color: "verdeFayal",
                                                            "item-value": "id",
                                                            "item-text":
                                                              "nombre",
                                                            label:
                                                              "Seleccione mantenimiento"
                                                          },
                                                          model: {
                                                            value:
                                                              mantenimiento.mantenimiento_id,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                mantenimiento,
                                                                "mantenimiento_id",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "mantenimiento.mantenimiento_id"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs12: "",
                                                          sm3: "",
                                                          md3: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-select", {
                                                          attrs: {
                                                            items:
                                                              _vm.statusMantenimiento,
                                                            color: "verdeFayal",
                                                            "item-value": "id",
                                                            "item-text":
                                                              "estado",
                                                            label:
                                                              "Seleccione status"
                                                          },
                                                          model: {
                                                            value:
                                                              mantenimiento
                                                                .estado
                                                                .information.id,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                mantenimiento
                                                                  .estado
                                                                  .information,
                                                                "id",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "mantenimiento.estado.information.id"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs10: "",
                                                          sm3: "",
                                                          md3: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            color: "#1ABA24",
                                                            label:
                                                              "Fecha de inicio de mantenimiento",
                                                            type: "date"
                                                          },
                                                          model: {
                                                            value:
                                                              mantenimiento.fecha,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                mantenimiento,
                                                                "fecha",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "mantenimiento.fecha"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "v-flex",
                                                      {
                                                        attrs: {
                                                          xs1: "",
                                                          sm1: "",
                                                          md1: ""
                                                        }
                                                      },
                                                      [
                                                        index != 0
                                                          ? _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  fab: "",
                                                                  flat: "",
                                                                  dark: "",
                                                                  small: "",
                                                                  color:
                                                                    "verdeFayal"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.maquinaria.mantenimientos.splice(
                                                                      index,
                                                                      1
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  {
                                                                    attrs: {
                                                                      dark: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "remove"
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: { click: _vm.updateMaquinaria }
                    },
                    [_vm._v(" Guardar ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "", color: "verdeFayal" },
                      on: { click: _vm.addMateriales }
                    },
                    [_c("v-icon", [_vm._v("add")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n  " + _vm._s(_vm.vSnackBar.text) + "\n  "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }