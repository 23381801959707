var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            flat: "",
                            icon: "",
                            href:
                              "/prototipos_vivienda/" +
                              _vm.householdId +
                              "/valores"
                          }
                        },
                        [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_vm._v(" "), _c("span", [_vm._v("Regresar")])]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "pb-2": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs10: "", "offset-xs1": "" } },
            [
              _c(
                "v-card",
                { staticClass: "card--flex-toolbar" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", prominent: "" } },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-title",
                        { staticClass: "body-2 grey--text" },
                        [
                          _vm._v(
                            "Rubros asociados a " + _vm._s(_vm.concepto.nombre)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs3: "" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { dark: "", color: "#75d57b" } },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs5: "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    "align-center": "",
                                                    "justify-center": "",
                                                    row: "",
                                                    "fill-height": ""
                                                  }
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src:
                                                        "/fonts/local/watch.svg",
                                                      height: "50px",
                                                      contain: ""
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs7: "" } },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  attrs: { "primary-title": "" }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "headline total-title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.statics.horas
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        "Horas de ejecución"
                                                      )
                                                    ])
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { dark: "", color: "#75d57b" } },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs5: "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    "align-center": "",
                                                    "justify-center": "",
                                                    row: "",
                                                    "fill-height": ""
                                                  }
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src:
                                                        "/fonts/local/construction.svg",
                                                      height: "50px",
                                                      contain: ""
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs7: "" } },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  attrs: { "primary-title": "" }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "headline total-title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "$ " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "currency"
                                                              )(
                                                                _vm.statics
                                                                  .materiales
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v("Materiales")
                                                    ])
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { dark: "", color: "#75d57b" } },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs5: "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    "align-center": "",
                                                    "justify-center": "",
                                                    row: "",
                                                    "fill-height": ""
                                                  }
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src:
                                                        "/fonts/local/manpower.svg",
                                                      height: "50px",
                                                      contain: ""
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs7: "" } },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  attrs: { "primary-title": "" }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "headline total-title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "$ " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "currency"
                                                              )(
                                                                _vm.statics.obra
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v("Mano de obra")
                                                    ])
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { dark: "", color: "#75d57b" } },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs5: "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    "align-center": "",
                                                    "justify-center": "",
                                                    row: "",
                                                    "fill-height": ""
                                                  }
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src:
                                                        "/fonts/local/total.svg",
                                                      height: "50px",
                                                      contain: ""
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs7: "" } },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  attrs: { "primary-title": "" }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "headline total-title"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "$ " +
                                                            _vm._s(
                                                              _vm._f(
                                                                "currency"
                                                              )(
                                                                _vm.statics
                                                                  .total
                                                              )
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [_vm._v("Total")])
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c("v-data-table", {
                                attrs: {
                                  items: _vm.items.data,
                                  "hide-actions": "",
                                  "item-key": "id"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "headers",
                                    fn: function(props) {
                                      return [
                                        _c("tr", [
                                          _c("th", [_vm._v("Rubro")]),
                                          _vm._v(" "),
                                          _c("th", [
                                            _vm._v("Tiempo de ejecución")
                                          ]),
                                          _vm._v(" "),
                                          _c("th", [
                                            _vm._v("Subtotal de material")
                                          ]),
                                          _vm._v(" "),
                                          _c("th", [
                                            _vm._v("Subtotal de mano de obra")
                                          ]),
                                          _vm._v(" "),
                                          _c("th", [_vm._v("Total")]),
                                          _vm._v(" "),
                                          _c("th", [_vm._v("Acciones")])
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "items",
                                    fn: function(props) {
                                      return [
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(props.item.rubro.nombre)
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(_vm._s(props.item.horas))
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "$ " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    props.item.total_materiales
                                                  )
                                                )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "$ " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    props.item.total_valores
                                                  )
                                                )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "$ " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    props.item.total_valores +
                                                      props.item
                                                        .total_materiales
                                                  )
                                                )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "v-layout",
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    { attrs: { bottom: "" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            slot: "activator",
                                                            icon: "",
                                                            flat: "",
                                                            dark: "",
                                                            small: "",
                                                            color: "verdeFayal"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.openExecutionTimeModal(
                                                                props.item
                                                                  .rubro_id,
                                                                props.item.rubro
                                                                  .nombre,
                                                                props.index
                                                              )
                                                            }
                                                          },
                                                          slot: "activator"
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("timer")
                                                          ])
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "Tiempo de ejecución"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    { attrs: { bottom: "" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            slot: "activator",
                                                            icon: "",
                                                            flat: "",
                                                            dark: "",
                                                            small: "",
                                                            color: "verdeFayal"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.openAddMaterialsValuesModal(
                                                                props.item
                                                                  .rubro_id,
                                                                props.item.rubro
                                                                  .nombre,
                                                                props.index
                                                              )
                                                            }
                                                          },
                                                          slot: "activator"
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "customIcon",
                                                            attrs: {
                                                              src:
                                                                "/fonts/local/construction_icon.svg"
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v("Materiales")
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-tooltip",
                                                    { attrs: { bottom: "" } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            slot: "activator",
                                                            color: "verdeFayal",
                                                            small: "",
                                                            dark: "",
                                                            icon: "",
                                                            flat: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.setValues(
                                                                (_vm.valueID = null),
                                                                props.item
                                                                  .rubro_id,
                                                                props.item.rubro
                                                                  .nombre,
                                                                "App\\PrototipoVivienda",
                                                                props.index
                                                              )
                                                            }
                                                          },
                                                          slot: "activator"
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "customIcon",
                                                            attrs: {
                                                              src:
                                                                "/fonts/local/manpower_icon.svg"
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          "Costos de mano de obra"
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c("pagination", {
                                attrs: { limit: 10, data: _vm.items },
                                on: { "pagination-change-page": _vm.getItems }
                              }),
                              _vm._v(" "),
                              _c("v-spacer")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("add-execution-time-modal", {
        attrs: {
          show: _vm.showExecutionTimeModal,
          "rubro-id": _vm.currentItemId,
          "item-id": _vm.householdId,
          name: _vm.currentItemName,
          modelType: _vm.currentModel
        },
        on: {
          "update:show": function($event) {
            _vm.showExecutionTimeModal = $event
          },
          "update:rubroId": function($event) {
            _vm.currentItemId = $event
          },
          "update:rubro-id": function($event) {
            _vm.currentItemId = $event
          },
          "update:itemId": function($event) {
            _vm.householdId = $event
          },
          "update:item-id": function($event) {
            _vm.householdId = $event
          },
          "update:name": function($event) {
            _vm.currentItemName = $event
          }
        }
      }),
      _vm._v(" "),
      _c("manage-materials-to-value-modal", {
        attrs: {
          show: _vm.showMaterialsToValuesModal,
          "item-id": _vm.currentItemId,
          "household-id": _vm.householdId,
          name: _vm.currentItemName,
          modelType: _vm.currentModel,
          permissions: _vm.permissions
        },
        on: {
          "update:show": function($event) {
            _vm.showMaterialsToValuesModal = $event
          },
          "update:itemId": function($event) {
            _vm.currentItemId = $event
          },
          "update:item-id": function($event) {
            _vm.currentItemId = $event
          },
          "update:householdId": function($event) {
            _vm.householdId = $event
          },
          "update:household-id": function($event) {
            _vm.householdId = $event
          },
          "update:name": function($event) {
            _vm.currentItemName = $event
          },
          "update:modelType": function($event) {
            _vm.currentModel = $event
          },
          "update:model-type": function($event) {
            _vm.currentModel = $event
          }
        }
      }),
      _vm._v(" "),
      _c("set-values-modal", {
        attrs: {
          show: _vm.showSetValuesModal,
          "value-id": _vm.currentValueId,
          "household-id": _vm.householdId,
          "item-id": _vm.currentItemId,
          name: _vm.currentItemName,
          "model-type": _vm.currentModel
        },
        on: {
          "update:show": function($event) {
            _vm.showSetValuesModal = $event
          },
          "update:valueId": function($event) {
            _vm.currentValueId = $event
          },
          "update:value-id": function($event) {
            _vm.currentValueId = $event
          },
          "update:householdId": function($event) {
            _vm.householdId = $event
          },
          "update:household-id": function($event) {
            _vm.householdId = $event
          },
          "update:itemId": function($event) {
            _vm.currentItemId = $event
          },
          "update:item-id": function($event) {
            _vm.currentItemId = $event
          },
          "update:name": function($event) {
            _vm.currentItemName = $event
          },
          "update:modelType": function($event) {
            _vm.currentModel = $event
          },
          "update:model-type": function($event) {
            _vm.currentModel = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }