var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" } },
        [
          _vm.rol == "Administrador" || _vm.rol == "Almacen"
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  flat: "",
                                  icon: "",
                                  href: "/orden_de_salida"
                                }
                              },
                              [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3418783929
                  )
                },
                [_vm._v(" "), _c("span", [_vm._v("Regresar")])]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "pb-2": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs10: "", "offset-xs1": "" } },
            [
              _c(
                "v-card",
                { staticClass: "card--flex-toolbar" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", prominent: "" } },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-title",
                        { staticClass: "body-2 grey--text" },
                        [_vm._v("Crear orden de salida de material")]
                      ),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: _vm.rules,
                                          value: _vm.nombre,
                                          required: "",
                                          label: "Nombre de la orden",
                                          readonly: ""
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("searcher", {
                                        attrs: {
                                          configuration: _vm.searchEntregarA,
                                          itemSelected: _vm.entregar_a
                                        },
                                        on: {
                                          "update:itemSelected": function(
                                            $event
                                          ) {
                                            _vm.entregar_a = $event
                                          },
                                          "update:item-selected": function(
                                            $event
                                          ) {
                                            _vm.entregar_a = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("searcher", {
                                        attrs: {
                                          configuration: _vm.searchSalida,
                                          itemSelected: _vm.solicitud
                                        },
                                        on: {
                                          "update:itemSelected": function(
                                            $event
                                          ) {
                                            _vm.solicitud = $event
                                          },
                                          "update:item-selected": function(
                                            $event
                                          ) {
                                            _vm.solicitud = $event
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.solicitud.id
                                ? _c(
                                    "v-layout",
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              value: _vm.solicitud.folio,
                                              label: "Folio de solicitud",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              value: _vm.solicitud.solicitante,
                                              label: "Solicitante",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              value:
                                                _vm.solicitud.fraccionamiento
                                                  .nombre_proyecto,
                                              label: "Fraccionamiento",
                                              readonly: ""
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("v-divider"),
                              _vm._v(" "),
                              _vm.solicitud.id
                                ? _c(
                                    "div",
                                    _vm._l(_vm.solicitud.materiales, function(
                                      material,
                                      index
                                    ) {
                                      return _c(
                                        "v-layout",
                                        { key: index, attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { md3: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    material.material
                                                      .categoria_asociada,
                                                  readonly: "",
                                                  required: "",
                                                  label: "Categoria",
                                                  disabled: ""
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md3: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value:
                                                    material.material.nombre,
                                                  readonly: "",
                                                  required: "",
                                                  label: "Material",
                                                  disabled: ""
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md3: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  rules: _vm.rulesForMaterial(
                                                    material.cantidad_disponible_por_despachar
                                                  ),
                                                  type: "number",
                                                  label: "Cantidad"
                                                },
                                                model: {
                                                  value: material.cantidad,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      material,
                                                      "cantidad",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "material.cantidad"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md3: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  readonly: "",
                                                  required: "",
                                                  disabled: "",
                                                  label:
                                                    "Cantidad disponible para despachar"
                                                },
                                                model: {
                                                  value:
                                                    material.cantidad_disponible_por_despachar,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      material,
                                                      "cantidad_disponible_por_despachar",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "material.cantidad_disponible_por_despachar"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md3: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "prepend-icon":
                                                    "attach_money",
                                                  value:
                                                    material.material.precio,
                                                  readonly: "",
                                                  required: "",
                                                  disabled: "",
                                                  label: "Precio (Sin IVA)"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md3: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "prepend-icon":
                                                    "attach_money",
                                                  value:
                                                    material.material.precio *
                                                    material.cantidad,
                                                  readonly: "",
                                                  required: "",
                                                  label: "Precio total",
                                                  disabled: ""
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md2: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  value: material.distribucion,
                                                  readonly: "",
                                                  required: "",
                                                  label: "Distribución",
                                                  disabled: ""
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md2: "" } },
                                            [
                                              material.distribucion ==
                                              "Manzanas"
                                                ? _c("v-text-field", {
                                                    attrs: {
                                                      value:
                                                        material.manzana.nombre,
                                                      readonly: "",
                                                      required: "",
                                                      label: "Manzana",
                                                      disabled: ""
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          material.distribucion == "Manzanas"
                                            ? _c(
                                                "v-flex",
                                                { attrs: { md2: "" } },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      value:
                                                        material.lote.nombre,
                                                      readonly: "",
                                                      required: "",
                                                      label: "Lote",
                                                      disabled: ""
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md2: "" } },
                                            [
                                              _vm.solicitud.materiales.length >
                                              1
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        fab: "",
                                                        small: "",
                                                        color: "red"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.solicitud.materiales.splice(
                                                            index,
                                                            1
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "white"
                                                          }
                                                        },
                                                        [_vm._v("remove")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider")
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            dark: "",
                            color: "verdeFayal",
                            flat: "",
                            loading: _vm.vBtnSave.loading
                          },
                          on: { click: _vm.storeOrden }
                        },
                        [
                          _vm._v(
                            "\n                        Guardar\n                    "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.vSnackBar.text) + "\n        "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }