<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="450px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Añadir tiempo de ejecución</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-card-text>
            <v-text-field
                label="Tiempo de ejecución en horas"
                type="number"
                v-model="rubrable.horas"
            ></v-text-field>
            </v-card-text>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" outline flat :loading="vBtnSave.loading" @click="updateRubrable">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    localShow: false,
    rubrable: {
      horas: 0
    },
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    rubroId: Number,
    name: String,
    modelType: String,
    itemId: String
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    rubroId: function() {
      this.getRubrable();
    }
  },
  methods: {
    getRubrable: function() {
        axios.get(`/rubros/rubrables/${this.rubroId}?item_id=${this.itemId}&model_type=${this.modelType}`)
        .then(response => this.rubrable = response.data)
        .catch( error => errorHandling(error))
    },
    updateRubrable: function() {
        this.vBtnSave.loading = true;
        axios.patch(laravelRoute('rubros.rubrable', this.rubrable.id), {
            "horas": this.rubrable.horas
        })
        .then(response => {
            this.localShow = false;
            this.vSnackBar.text = response.data.message_text;
            this.vSnackBar.visible = true;
        })
        .catch( error => errorHandling(error))
        .finally(() => this.vBtnSave.loading = false);
    }
  }
}
</script>
