<template>
 <div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">
          Activar/Desactivar Trabajador 
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text>
        <v-container>
          <v-layout wrap>
            <v-flex md12>
                <v-subheader><span class="green--text lighten-1--text">Folio de activación</span></v-subheader>
              </v-flex>
            <v-flex md9>
              <v-text-field
                v-if="contratoTrabajador.estatus === 'Aprobado' ? true : false"
                :disabled="contratoTrabajador.estatus === 'Aprobado' && permissions.capture_folio ? false : true"
                v-model="contratoTrabajador.folio_nss"
                color="verdeFayal"
                type="number"
                label="Ingrese el folio del IMSS"
              />
            </v-flex>
            <v-layout wrap v-if="trabajador_is_active">
              <v-flex md12>
                  <v-subheader><span class="red--text lighten-1--text">Folio de desactivación</span></v-subheader>
              </v-flex>
              <v-flex md9>
                <v-text-field
                  v-if="contratoTrabajador.estatus === 'Aprobado' ? true : false"
                  :disabled="contratoTrabajador.estatus === 'Aprobado' && permissions.capture_folio ? false : true"
                  v-model="contratoTrabajador.folio_baja_nss"
                  color="error"
                  type="number"
                  label="Ingrese el folio de baja del IMSS"
                />
              </v-flex>
            </v-layout>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          dark color="error" 
          :disabled="!(trabajador_is_active && contratoTrabajador.folio_nss)"
          @click="desactiveWorker()" flat :loading="vBtnSave.loading"
        >Desactivar trabajador</v-btn>
        <v-btn 
          dark color="verdeFayal" 
          @click="saveFolioAndActiveWorker()" flat :loading="vBtnSave.loading"
        >Guardar folio y activar trabajador</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
 </div>
</template>

<script>
import { laravelRoute } from '../../lib/utilities';
export default {
  props: {
    show: Boolean,
    contrato_trabajador: Object,
    permissions: Object,
    trabajador_is_active: Boolean
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.contratoTrabajador = this.$props.contrato_trabajador;
      this.$emit('update:show', value);
    }
  },
  data() {
    return {
      contratoTrabajador: {
        contrato_id: null,
        trabajador_id: null,
        fecha_inicio: null,
        fecha_fin: null,
        estatus: null,
        folio_nss: null,
        folio_baja_nss: null,
      },
      localShow: false,
      vBtnSave: {
        loading: false
      },
    }
  },
  methods: {
    saveFolioAndActiveWorker: function(){
      axios.patch(laravelRoute('contratos_trabajadores.saveNSSFolio', this.contratoTrabajador.id), {folio_nss: this.contratoTrabajador.folio_nss})
      .then(response => {
        alertify.success(response.data.message_text);
        this.activeWorker();
      })
      .catch(error => errorHandling(error));
    },
    activeWorker(){
      axios.patch(laravelRoute('trabajadores.active', this.contratoTrabajador.trabajador_id))
      .then(response => {
        if(response.data.message_type == 'success'){
          alertify.success(response.data.message_text);
          this.localShow =  false;
        }
        else
          alertify.error(response.data.message_text);
      })
      .catch(error => errorHandling(error));
    },
    desactiveWorker(){
      axios.patch(laravelRoute('trabajadores.desactive', this.contratoTrabajador.trabajador_id), {folio_baja_imss: this.contratoTrabajador.folio_baja_nss})
      .then(response => {
        if(response.data.message_type == 'success'){
          alertify.success(response.data.message_text);
          this.localShow =  false;
        }
        else
          alertify.error(response.data.message_text);
      })
      .catch(error => errorHandling(error));
    }
  },
  mounted(){

  }
}
</script>