var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("\n         Activar/Desactivar Trabajador \n       ")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-subheader", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "green--text lighten-1--text"
                                  },
                                  [_vm._v("Folio de activación")]
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md9: "" } },
                            [
                              (_vm.contratoTrabajador.estatus === "Aprobado"
                              ? true
                              : false)
                                ? _c("v-text-field", {
                                    attrs: {
                                      disabled:
                                        _vm.contratoTrabajador.estatus ===
                                          "Aprobado" &&
                                        _vm.permissions.capture_folio
                                          ? false
                                          : true,
                                      color: "verdeFayal",
                                      type: "number",
                                      label: "Ingrese el folio del IMSS"
                                    },
                                    model: {
                                      value: _vm.contratoTrabajador.folio_nss,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.contratoTrabajador,
                                          "folio_nss",
                                          $$v
                                        )
                                      },
                                      expression: "contratoTrabajador.folio_nss"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.trabajador_is_active
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md12: "" } },
                                    [
                                      _c("v-subheader", [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "red--text lighten-1--text"
                                          },
                                          [_vm._v("Folio de desactivación")]
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md9: "" } },
                                    [
                                      (_vm.contratoTrabajador.estatus ===
                                      "Aprobado"
                                      ? true
                                      : false)
                                        ? _c("v-text-field", {
                                            attrs: {
                                              disabled:
                                                _vm.contratoTrabajador
                                                  .estatus === "Aprobado" &&
                                                _vm.permissions.capture_folio
                                                  ? false
                                                  : true,
                                              color: "error",
                                              type: "number",
                                              label:
                                                "Ingrese el folio de baja del IMSS"
                                            },
                                            model: {
                                              value:
                                                _vm.contratoTrabajador
                                                  .folio_baja_nss,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.contratoTrabajador,
                                                  "folio_baja_nss",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contratoTrabajador.folio_baja_nss"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "error",
                        disabled: !(
                          _vm.trabajador_is_active &&
                          _vm.contratoTrabajador.folio_nss
                        ),
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.desactiveWorker()
                        }
                      }
                    },
                    [_vm._v("Desactivar trabajador")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.saveFolioAndActiveWorker()
                        }
                      }
                    },
                    [_vm._v("Guardar folio y activar trabajador")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }