<template>
  <div>
    <template>
      <v-btn id="provider-modal-button" class="fyl-btnCreate" v-on:click="$emit('open')">Crear</v-btn>
    </template>
    <v-layout row justify-center>
      <v-dialog :value="dialog" persistent max-width="700px">
        <v-card>
          <form>
            <v-toolbar color="white" flat>
              <v-spacer></v-spacer>
              <v-toolbar-title class="primary-title">{{this.proveedor.id ? "Editar" : "Crear"}} proveedor
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn flat icon color="verdeFayal" dark @click="$emit('close')">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field color="#1ABA24" label="Nombre completo del proveedor"
                      v-model="proveedor.nombre_completo"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field color="#1ABA24" label="Acrónimo" v-model="proveedor.acronimo"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field color="#1ABA24" label="Nombre vendedor" v-model="proveedor.nombre_vendedor">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field color="#1ABA24" label="Teléfono de oficina" v-model="proveedor.telefono_oficina">
                    </v-text-field>
                  </v-flex>

                  <v-flex md4 sm4>
                    <v-switch label="Cambiar RFC" style="margin-top: 0;" color="verdeFayal" v-model="changeRFC">
                      <!--  :disabled="!$root.can('new', permissions)" -->
                    </v-switch>
                  </v-flex>
                  <v-flex md8 sm8>
                    <v-layout v-if="changeRFC == true">
                      <v-flex xs12 sm12 md12>
                        <v-text-field class="rfc-input" :color="getRfcPassed ? '#1ABA24' : 'red' "
                          label="RFC de persona fisica" v-model="proveedor.rfc_fisicas"></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout v-else>
                      <v-flex xs12 sm12 md12>
                        <v-text-field class="rfc-input" :color="getRfcPassed2 ? '#1ABA24' : 'red' "
                          label="RFC de persona moral" v-model="proveedor.rfc_morales"></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex xs12 sm12 md12>
                    <v-text-field color="#1ABA24" label="Telefono de vendedor" v-model="proveedor.telefono_vendedor">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field color="#1ABA24" label="Correo electrónico" v-model="proveedor.correo"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field color="#1ABA24" label="Dirección" v-model="proveedor.direccion"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-select color="#1ABA24" :items="items" item-text="nombre" item-value="dias"
                      label="Seleccione tipo de crédito" v-model="proveedor.tipo_credito"></v-select>
                  </v-flex>
                  <v-flex xs6 md6 sm6>
                    <v-autocomplete :items="categories" item-value="id" item-text="nombre"
                      v-model="proveedor.categorias" label="Categoría" single-line multiple></v-autocomplete>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn dark color="verdeFayal" @click="formatProveedor" flat :disabled="$store.state.saveDisabled">
                Guardar </v-btn>
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<style>
.rfc-input input{
  text-transform: uppercase
}
</style>

<script>
  import { laravelRoute, errorHandling } from '../../lib/utilities';
  export default {
  props: ["proveedores", "proveedor", "dialog"],

  data: function() {
    return {
      pattern: new RegExp(/^[A-Z]{4}([0-9]{2})(1[0-2]|0[1-9])([0-3][0-9])([ -]?)([A-Z0-9]{3})$/),
      personasMorales: new RegExp(/^[A-Z]{3}([0-9]{2})(1[0-2]|0[1-9])([0-3][0-9])([ -]?)([A-Z0-9]{3})$/),
      categories: [], 
      rfcPassed: true,
      rfcPassed2: true,
      changeRFC: true,
      items: [
        { nombre: "de Contado", dias: "0" },
        { nombre: "9 días", dias: "9" },
        { nombre: "30 días", dias: "30" },
        { nombre: "45 días", dias: "45" },
        { nombre: "60 días", dias: "60" },
        { nombre: "90 días", dias: "90" }
      ]
    };
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    RFCTest(value){
      if(!value.rfc_fisicas) return;
      this.rfcPassed = this.pattern.test(value.rfc_fisicas);
      if(!this.rfcPassed){
        	alertify.error('Formato de RFC erroneo de personas físicas.');
      }
      return this.rfcPassed;
    },
    RFCTestMorales(value){
      if(!value.rfc_morales) return;
      this.rfcPassed2 = this.personasMorales.test(value.rfc_morales);
      if(!this.rfcPassed2){
        	alertify.error('Formato de RFC erroneo de personas morales .');
      }
      return this.rfcPassed2;
    },
    getCategories: function(){
      axios
      .get(laravelRoute('categorias.filtro'))
      .then(response => {
        this.categories = response.data;
      })
      .catch(error => errorHandling(error));
    },
    formatProveedor(){
      let formatted = {};
      Object.assign(formatted, this.proveedor);
      formatted.rfc_fisicas = formatted.rfc_fisicas ? formatted.rfc_fisicas.toUpperCase() : null;
      formatted.rfc_morales = formatted.rfc_morales ? formatted.rfc_morales.toUpperCase() : null;
      if(this.changeRFC){
        if(!this.RFCTest(formatted)) return;
      }else{
        if(!this.RFCTestMorales(formatted)) return;
      }
      if(!this.proveedor.categorias) return;
      formatted.categorias = this.proveedor.categorias.map(element => element.id ? element.id : element);
      this.createProveedor(formatted);
    },
    createProveedor(body) {
				if (this.proveedor.id){
					axios
						.patch(route("proveedores.update", body.id), body)
						.then(response => {
							if(response.data.message_text){
								alertify.success(response.data.message_text);
								this.$root.cleanStore();
                this.$emit("close");
                setTimeout(()=>{
                  window.location.href = "/proveedores";
                }, 1000)
              }
						})
            .catch(error => errorHandling(error));
				}else {
					axios
						.post("/proveedores", body)
						.then(response => {							
							if (response.data.message_text)
								alertify.success(response.data.message_text);
								this.$root.cleanStore();
                this.$emit("close");
                setTimeout(()=>{
                  window.location.href = "/proveedores";
                }, 1000)
						})
            .catch(error => errorHandling(error));
				}
			}
  },

  computed: {
    FileUpload() {
      return this.$store.state.urlFileUpload;
    },
    getRfcPassed: function(){
      return this.rfcPassed;
    },
    getRfcPassed2: function(){
      return this.rfcPassed2;
    }
  },

  watch: {
    dialog: function (value){
      this.proveedor.rfc_fisicas = this.pattern.test(this.proveedor.rfc) ? this.proveedor.rfc : null;
      this.proveedor.rfc_morales = this.personasMorales.test(this.proveedor.rfc) ? this.proveedor.rfc : null;
      this.changeRFC = this.proveedor.rfc_fisicas ? true : false;
    },
    FileUpload() {
      this.proveedor.planos = this.FileUpload;
    },
    changeRFC: function(value) {
      this.proveedor.rfc_morales = !value ? this.proveedor.rfc_morales : null;
      this.proveedor.rfc_fisicas = value ? this.proveedor.rfc_fisicas : null;
    },
  }
};
</script>

<style>
.fyl-btnCreate {
  color: #fff;
  background: #1aba24 !important;
}
.v-input--is-label-active label {
  color: #1aba24 !important;
}
.fyl-btnClose {
  color: #ff5252 !important;
}
.fyl-btnSave {
  color: #fff !important;
  background: #1aba24;
  border-radius: 6px;
}
</style>