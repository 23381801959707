<template>
<v-layout>
  <v-container>
    <v-card>
      <v-flex xs12>
        <v-toolbar color="white" flat tabs>
          <v-toolbar-title>Categorias</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
          <v-btn class="fyl-btnCreate" @click="openCreateModal" v-if="$root.can('new', permissions) ? true : false">Crear</v-btn>
        </v-toolbar>
        <v-data-table :headers="vHeaders" disable-initial-sort :items="categories.data" hide-actions class="elevation-1">
          <template slot="items" slot-scope="props">
            <td>{{ props.item.nombre }}</td>
            <td class="td-ellipsis">{{ props.item.descripcion }}</td>
            <td>{{ props.item.tipo }}</td>
            <td>
              <v-layout>
                <v-tooltip bottom>
                  <v-btn icon flat dark small slot="activator" color="info" @click="openEditCategoryModal(props.item.id)" :disabled="!$root.can('update', permissions)">
                    <v-icon color="verdeFayal">edit</v-icon>
                  </v-btn>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn icon flat dark small slot="activator" color="error" @click="deleteCategory(props.item.id)" :disabled="!$root.can('removex', permissions)"> <!-- Cambiar 'removex' a 'remove' para cumplir con el permiso -->
                    <v-icon>delete</v-icon>
                  </v-btn>
                  <span>Eliminar</span>
                </v-tooltip>
              </v-layout>
            </td>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <pagination :limit="vPaginator.limit" :data="categories" @pagination-change-page="getCategories"></pagination>
        </v-card-actions>
      </v-flex>
    </v-card>
    <!-- Modal -->
    <create-category-modal :show.sync="showCreateModal"></create-category-modal>
    <edit-category-modal :show.sync="showEditModal" :category-id.sync="currentCategoryId"></edit-category-modal>
  </v-container>
</v-layout>
</template>

<script>
import swal from "sweetalert";

export default {
  data: () => ({
    search: null,
    categories: {
      current_page: 1,
      data: []
    },
    showCreateModal: false,
    showEditModal: false,
    currentCategoryId: null,
    vPaginator: {
      limit: 10
    },
    vHeaders: [{
        text: 'Nombre',
        value: 'nombre'
      },
      {
        text: 'Descripcion',
        value: 'descripcion'
      },
      {
        text: 'Tipo',
        value: 'tipo'
      },
      {
        text: 'Acciones',
        value: 'acciones',
        sortable: false
      }
    ],
  }),
  props:['permissions'],
  watch: {
    search: function (value) {
      this.getCategories();
    },
    showCreateModal: function (value) {
      if (!value) {
        this.getCategories();
      }
    },
    showEditModal: function (value) {
      if (!value) {
        this.getCategories(this.categories.current_page);
      }
    }
  },
  methods: {
    getCategories: function (page = 1) {
      axios.get(`/categorias?page=${page}&query=${this.search}`)
        .then(response => {
          this.categories = response.data;
        })
        .catch(error => errorHandling(error));
    },
    deleteCategory: function (categoryId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('categorias.destroy', categoryId))
            .then(response => {
              this.getCategories();
              alertify.success(response.data.message_text);
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      })
    },
    openEditCategoryModal: function (categoryId) {
      this.showEditModal = true;
      this.currentCategoryId = categoryId;
    },
    openCreateModal: function () {
      this.showCreateModal = true;
    },
    goBack: function () {
      window.history.back();
    }
  },
  mounted() {
    this.getCategories(this.categories.current_page);
  }
}
</script>
