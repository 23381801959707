var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "550" },
      model: {
        value: _vm.localShow,
        callback: function($$v) {
          _vm.localShow = $$v
        },
        expression: "localShow"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "white", flat: "" } },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-toolbar-title", { staticClass: "primary-title" }, [
                _vm._v("Rubros asociados a " + _vm._s(_vm.concepto.nombre))
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { flat: "", icon: "", color: "verdeFayal", dark: "" },
                  on: {
                    click: function($event) {
                      _vm.localShow = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", [
            _c(
              "table",
              { staticClass: "table table-striped table-hover table-sm" },
              [
                _c(
                  "tbody",
                  _vm._l(_vm.concepto.rubros, function(rubro) {
                    return _c("tr", { key: rubro.id }, [
                      _c("td"),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(rubro.nombre))])
                    ])
                  }),
                  0
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }