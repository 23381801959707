<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="650px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Agregar clientes al lote {{ lote.nombre }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout row wrap>
            <v-flex v-if="lote.estado.information.id == 21">
                <v-autocomplete
                    @keyup.enter.native="addCustomer"
                    hint="Buscar cliente"
                    prepend-inner-icon="search"
                    :search-input.sync="query"
                    :items="customers.data"
                    item-text="nombre_completo"
                    v-model="selected"
                    persistent-hint
                    item-value="id"
                    label="Buscar"
                    return-object
                    hide-selected
                    hide-no-data
                >
                    <template v-slot:append-outer>
                    <v-slide-x-reverse-transition mode="out-in">
                        <v-icon color="success" :disabled="!selected" @click="addCustomer">add</v-icon>
                    </v-slide-x-reverse-transition>
                    </template>
                </v-autocomplete>
            </v-flex>

            
            <v-flex xs12>
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Nombre del cliente</th>
                            <th>Correo electrónico</th>
                            <th>Télefono</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(customer, index) in localSelected" :key="index">
                            <td>{{ customer.nombre_completo }}</td>
                            <td>{{ customer.email }}</td>
                            <td>{{ customer.telefono }}</td>
                            <td>
                                <v-layout>
                                    <v-btn v-if="lote.estado.information.id == 21" flat icon color="red" @click="deleteCustomer(index)">
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </v-layout>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="lote.estado.information.id == 21" @click="storeCustomers" outline dark color="verdeFayal" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../../lib/utilities";
export default {
  data: () => ({
    customers: {
        data: []
    },
    lote: { estado:{information:{}}},
    selected: [],
    localSelected: [],
    localShow: false,
    query: null,
    searchLoading: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    loteId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    loteId: function() {
        this.getLote();
        this.getCurrentCustomers();
    },
    query: function() {
      this.getCustomers();
    }
  },
  methods: {
    getLote: function() {
        axios.get(`/manzanas/lote/${this.loteId}`)
        .then(response => this.lote = response.data)
        .catch(error => errorHandling(error))
    },
    addCustomer: function() {
        if(this.selected) {
            if(!this.localSelected.some(e => e.id == this.selected.id)) {
                this.localSelected.push(this.selected);
                this.selected = null;
            }
            else {
                alertify.error('Este elemento ya se habia agregado anteriormente');
            }
        }
    },
    getCustomers: function() {
        this.searchLoading = true;
        axios.get('/clientes')
        .then(response => this.customers = response.data)
        .catch(error => errorHandling(error))
        .finally(() => this.searchLoading = false);
    },
    deleteCustomer: function(index) {
        swal({
            title: "¿Estás seguro?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: "#1aba24"
        }).then(result => {
            if (result) {              
                this.localSelected.splice(index, 1);
            }
        }); 
    },
    getCurrentCustomers: function() {
        axios.get(`/ventas/clientes?lote_id=${this.loteId}`)
        .then(response => this.localSelected = response.data.map(item => item.cliente))
        .catch(error => errorHandling(error))
        .finally(() => this.searchLoading = false);
    },
    storeCustomers: function() {
        this.vBtnSave.loading = true;
        axios.post(laravelRoute('ventas.store'), {
            clientes: this.localSelected,
            lote_id: this.loteId
        })
        .then(response => {
            this.getLote();
            this.vSnackBar.text = response.data.message_text;
            this.vSnackBar.visible = true;
        })
        .catch(error => errorHandling(error))
        .finally(()=>this.vBtnSave.loading = false);
    }
  }
}
</script>
