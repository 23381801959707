<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="1350px" persistent>
      <v-card flat>
        <v-toolbar color="white" flat tabs>
            <v-spacer></v-spacer>
            <v-toolbar-title class="primary-title">Administrar requisición</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
                <v-icon>close</v-icon>
            </v-btn>
            <v-tabs slot="extension" v-model="vTabs" centered color="white" slider-color="verdeFayal">
                <v-tab href="#tab-edit-requisicion">Datos de la requisición</v-tab>
                <v-tab href="#tab-index-material">Lista de materiales</v-tab>
                <v-tab v-if="requisicion.visto_bueno != true || requisicion.visto_bueno_operaciones != true" href="#tab-add-material">Añadir materiales</v-tab>
            </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="vTabs">
          <v-tab-item value="tab-edit-requisicion">
              <v-card flat>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md4>
                      <v-text-field v-model="requisicion.nombre_requisicion" required label="Nombre de la orden"></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-select 
                        v-model="requisicion.solicitante" 
                        :items="solicitante" 
                        required 
                        label="Seleccione solicitante"
                      ></v-select>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field disabled v-model="requisicion.estado.information.estado" required label="Estado"></v-text-field>
                    </v-flex>
                    <v-flex md6 v-if="requisicion.estado.information.estado == 'Aprobada'">
                      <v-text-field 
                        type="date"
                        v-model="requisicion.fecha_de_llegada" 
                        required 
                        label="Fecha de llegada"
                      ></v-text-field>
                  </v-flex>
                    <v-flex md6>
                      <v-text-field v-model="requisicion.descripcion" required label="Descripcion"></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
           </v-tab-item>   
          <v-tab-item value="tab-index-material">
            <v-layout>
              <v-container>
                  <v-flex xs12>
                      <v-data-table :headers="vHeaders" :items="materials_getted" hide-actions>
                          <template slot="items" slot-scope="props">
                              <td>{{ (props.item.proveedor) ? props.item.proveedor.nombre_completo : 'Aún sin proveedor' }}</td>
                              <td>{{ props.item.material.categoria_asociada }}</td>
                              <td>{{ props.item.material.nombre }}</td>
                              <td>{{ props.item.cantidad }}</td>
                              <td>{{ props.item.material.precio | currency }}</td>
                              <td>{{ getStatus(props.item) }}</td>
                              <td>
                                <v-layout>
                                  <v-tooltip bottom>
                                    <v-btn 
                                      icon 
                                      flat 
                                      dark 
                                      small
                                      slot="activator" 
                                      color="verdeFayal" 
                                      @click="openShoppingModal(props.item.id)"
                                    >
                                      <v-icon>shopping_cart</v-icon>
                                    </v-btn>
                                    <span>Añadir datos de venta</span>
                                  </v-tooltip>

                                  <v-tooltip bottom>
                                    <v-btn icon flat dark small :disabled="props.item.surtido == true" slot="activator" color="error" @click="deleteMaterialValue(props.item.id)">
                                      <v-icon>delete</v-icon>
                                    </v-btn>
                                    <span>Eliminar</span>
                                  </v-tooltip>
                                </v-layout>
                              </td>
                          </template>
                      </v-data-table>
                  </v-flex>
              </v-container>
            </v-layout>
          </v-tab-item>
          <v-tab-item value="tab-add-material">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-container grid-list-md v-for="(material, index) in materiales" :key="index">
                <v-layout wrap> 
                  <v-flex md3>
                    <v-select  
                    :items="proveedores" 
                    required 
                    label="Proveedor"
                    item-value="id"
                    item-text="nombre_completo"
                    v-model="material.proveedor_id"
                  ></v-select>
                  </v-flex>
                  <v-flex md3>
                    <searcher
                      :configuration="searchCategories"
                      :itemSelected.sync="material.categoria"
                    ></searcher>
                  </v-flex>
                  <v-flex md3>
                    <v-autocomplete
                      :items="(material.categoria) ? material.categoria.materiales : []"
                      v-model="material.material"
                      :rules="rules"
                      return-object
                      item-text="nombre"
                      label="Material"
                      single-line
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex md3>
                    <v-text-field
                      :value="material.material.unidad"
                      readonly
                      required
                      label="Unidades"
                      disabled
                    ></v-text-field>
                  </v-flex>
                  <v-flex md3>
                    <v-text-field
                      v-model="material.cantidad"
                      type="number"
                      min="0"
                      required
                      :rules="rules"
                      label="Cantidad"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md3>
                    <v-text-field
                      prepend-icon="attach_money"
                      :value="material.material.precio"
                      readonly
                      disabled
                      required
                      label="Precio unitario"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md3>
                    <v-text-field
                      prepend-icon="attach_money"
                      :value="parseInt(material.material.precio) * parseInt(material.cantidad)"
                      readonly
                      disabled
                      label="Precio total"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md3>
                    <v-btn
                      v-if="index != 0"
                      @click="materiales.splice(index, 1)"
                      fab
                      flat
                      dark
                      small
                      color="verdeFayal"
                    >
                      <v-icon dark>remove</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-divider ></v-divider>
              </v-container>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark v-if="vTabs == 'tab-edit-requisicion'" color="verdeFayal" flat @click="updateRequisicion" :loading="vBtnSave.loading"> Guardar </v-btn>
            <v-btn dark v-if="vTabs == 'tab-add-material'" color="verdeFayal" flat @click="storeAddMaterial" :loading="vBtnSave.loading"> Guardar </v-btn>
            <v-btn dark v-if="vTabs == 'tab-add-material'" color="verdeFayal" @click="addMateriales">
                <v-icon>add</v-icon>
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
        {{ vSnackBar.text }}
        <v-btn color="pink" flat @click="vSnackBar.visible = false">
          <v-icon>close</v-icon>
        </v-btn>
    </v-snackbar>
    <add-provider-to-requisitions 
      :show.sync="showAddProvideModal" 
      :requisicion-material="currentRequisicionMaterial" 
      :material-category="currentMaterialCategory"
    ></add-provider-to-requisitions>
    <shopping-requisiciones-modal 
      :show.sync="showShoppingModal" 
      :material-requisicion-id.sync="currentMaterialRequisicionId"
    />
  </div>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../../lib/utilities";
  export default {
   data(){
      return{
        categories: [],
        materials: [],
        requisicion: {
          materiales: [],
          estado: {
            information: {}
          },
          visto_bueno_operaciones: false,
          visto_bueno: false,
        },
        currentMaterialCategory: null,
        currentMaterialRequisicionId: null,
        materiales: [
          {
            categoria_id: null,
            material: {
                precio: 0
            },
            cantidad: 0,
            materiales: []
          }
        ],
        estado: [],
        solicitante: ['solicitante 1','solicitante 2'],
        proveedores: ['proveedor 1', 'proveedor 2'],
        solicitante:['Almacén'],
        currentRequisicionMaterial: null,
        showAddProvideModal: false,
        materials_getted: [],
        vPaginator: {
          limit: 10
        },
        vHeaders: [
          { text: 'Proveedor', value: 'proveedor' },
          { text: 'Categoria', value: 'categoria' },
          { text: 'Material', value: 'material' },
          { text: 'Cantidad requerida', value: 'cantidad' },
          { text: 'Precio unitario', value: 'precio' },
          { text: 'Estado', value: 'cantidad_a_surtir' },
          { text: 'Acciones', value: 'acciones', sortable: false }
        ],
        searchCategories: {
          url: "/categorias/filtro/",
          paginatedContainer: 'data',
          noDataText: "No se encontraron resultados.",
          label: "Categoria",
          itemText: "nombre",
          itemValue: "id",
          descriptionLimit: 60,
          disabled: false,
          useHeaders: true,
        },
        localSearch: null,
        localShow: false,
        showShoppingModal: false,
        vTabs: "tab-edit-requisicion",
        reload: false,
        localItemId: null,
        valid: false,
        rules: [
          v => !!v || 'Este campo es requerido',
        ],
        vBtnSave: {
            loading: false
        },
        vSnackBar: {
          visible: false,
            text: null
          }
        }
        },
        props:{
          show: Boolean,
          itemId: Number,
          modelType: String
      },
    watch: {
      show: function (value) {
        this.localShow = value
      },
      localShow: function (value) {
        this.$emit('update:show', value)
      },
      itemId: function(value) {
        this.localItemId = value;
        this.getRequisicion();
        this.getMateriales();
      },
      vTabs: function(value) {
        this.reload = (value == "tab-index-material") ? true : false;
        this.getRequisicion();
      },
      showAddProvideModal: function() {
        this.getRequisicion();
      },
      showShoppingModal: function() {
        this.getRequisicion();
      }
    },
    methods:
    {
        getRequisicion: function(page = 1) {
          axios.get(`/requisiciones/${this.itemId}`)
          .then(response => {
            this.requisicion = response.data;
          }) 
          .catch( error => errorHandling(error));
        },
        deleteMaterialValue: function(materialRequisicionId) {
          swal({
            title: "¿Estás seguro?",
            text: "Una vez eliminado no se podrá recuperar",
            icon: "warning",
            buttons: true,
            dangerMode: true
          }).then(result => {
            if (result) {
              axios.delete(`/requisiciones/materiales/${materialRequisicionId}`)
              .then(response => {
                this.getRequisicion();
                alertify.success(response.data.message_text);
              })
              .catch(error => {
                errorHandling(error);
              });
            }
          })
        },
        getCategories: function() {
            axios.get(laravelRoute("categorias.index", {
                query: "Materiales de maquinaría"
            }))
            .then(response => {
                this.categories = response.data.data;
            })
            .catch(error => errorHandling(error));
        },
        getCurrentMateriales: function(category_id, index) {
            var categories = [];
            this.categories.map(function(category) {
                categories.push(category);
            });
            this.materiales[index].materiales = categories.find(function(category) {
                return category.id == category_id;
            }).materiales;
        },
        addMateriales: function(){
            this.materiales.push({
                categoria_id: null,
                material: {
                    precio: 0
                },
                cantidad: 0,
                materiales: []
            });
        }, 
        storeAddMaterial: function() {
            if (this.$refs.form.validate()) {
                this.vBtnSave.loading = true;
                axios.post(laravelRoute('requisiciones.materiales'), {
                    materiales: this.materiales,
                    requisicion_id: this.itemId
                })
                .then(response => {
                    this.getRequisicion();
                    this.materiales = [
                        {
                            categoria_id: null,
                            material: {
                                precio: 0
                            },
                            cantidad: 0,
                            materiales: []
                        }
                    ]
                    alertify.success(response.data.message_text);
                })
                .catch(error => errorHandling(error))
                .finally(()=>this.vBtnSave.loading = false);
            }
        },
        updateRequisicion: function(){
          this.vBtnSave.loading = true;
          axios.patch('/requisiciones/'+this.itemId, {
            nombre_requisicion: this.requisicion.nombre_requisicion,
            solicitante: this.requisicion.solicitante,
            proveedor: this.requisicion.proveedor_id,
            descripcion: this.requisicion.descripcion,
            estado: this.requisicion.estado.information.id,
            visto_bueno_operaciones: this.requisicion.visto_bueno_operaciones,
            visto_bueno: this.requisicion.visto_bueno,
            fecha_de_llegada: this.requisicion.fecha_de_llegada
          })
          .then(response => {
            alertify.success(response.data.message_text);
            this.getRequisicion();
          })
          .catch(error => {
            errorHandling(error)
          })
          .finally(()=>this.vBtnSave.loading = false);
        },
        getStatus: function(){
            axios.get('/requisiciones/estados').then(response => {
                this.estado = response.data;
            });
        },
        getProveedores: function(){
            axios.get('/proveedores').then(response => {
                this.proveedores = response.data;
            });
        },
        surtirMaterial: function(materialRequisicionId) {
            axios.patch(laravelRoute("materiales.surtir",materialRequisicionId ))
            .then(response => {
                this.getRequisicion();
                alertify.success(response.data.message_text);
            })
            .catch(error => errorHandling(error));
        },
        openAddProvider: function(materialRequisicionId, categoria_asociada) {
          this.currentRequisicionMaterial = materialRequisicionId;
          this.showAddProvideModal = true;
          this.currentMaterialCategory =  categoria_asociada;
        },
        surtirRequisicion: function() {
          axios.patch('/requisiciones/surtir/'+this.requisicion.id).then(response => {
            this.getRequisicion();
            alertify.success(response.data.message_text);
          })
          .catch(error => errorHandling(error));
        },
        openShoppingModal: function(materialRequisicionId) {
          this.showShoppingModal = true;
          this.currentMaterialRequisicionId = materialRequisicionId;
        },
        getMateriales: function() {
            axios.get(laravelRoute('requisiciones.materiales_requisiciones', this.itemId))
            .then(response => this.materials_getted = response.data)
            .catch( error => errorHandling(error));
        },
        getStatus(material) {
            if(material.cantidad_a_surtir == 0) {
                return 'Pendiente de surtir';
            }
            if(material.cantidad_a_surtir > 0 && material.cantidad_a_surtir < material.cantidad) {
                return 'Surtido parcialmente';
            }
            if(material.cantidad_a_surtir == material.cantidad_a_surtir < material.cantidad) {
                return 'Surtido totalmente';
            }
        }
    },
    created(){
        this.getCategories();
        this.getStatus();
        this.getProveedores();
    }
  }
  </script>