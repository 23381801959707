var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-toolbar",
            {
              attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" }
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                icon: "",
                                href: "/fraccionamientos"
                              }
                            },
                            [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_vm._v(" "), _c("span", [_vm._v("Regresar")])]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", "pb-2": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs10: "", "offset-xs1": "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card--flex-toolbar" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { card: "", prominent: "" } },
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-toolbar-title",
                            { staticClass: "body-2 grey--text" },
                            [
                              _vm._v(
                                "Reporte de costos de " +
                                  _vm._s(
                                    _vm.info.fraccionamiento.nombre_proyecto
                                  )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-spacer")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs4: "" } },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          attrs: { dark: "", color: "#75d57b" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      attrs: {
                                                        "align-center": "",
                                                        "justify-center": "",
                                                        row: "",
                                                        "fill-height": ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-img", {
                                                        attrs: {
                                                          src:
                                                            "/fonts/local/construction.svg",
                                                          height: "50px",
                                                          contain: ""
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    {
                                                      attrs: {
                                                        "primary-title": ""
                                                      }
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "headline total-title"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "$ " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "currency"
                                                                  )(
                                                                    _vm.info
                                                                      .total_materiales
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("div", [
                                                          _vm._v("Material")
                                                        ])
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs4: "" } },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          attrs: { dark: "", color: "#75d57b" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      attrs: {
                                                        "align-center": "",
                                                        "justify-center": "",
                                                        row: "",
                                                        "fill-height": ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-img", {
                                                        attrs: {
                                                          src:
                                                            "/fonts/local/manpower.svg",
                                                          height: "50px",
                                                          contain: ""
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    {
                                                      attrs: {
                                                        "primary-title": ""
                                                      }
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "headline total-title"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "$ " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "currency"
                                                                  )(
                                                                    _vm.info
                                                                      .total_mano_de_obra
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("div", [
                                                          _vm._v("Mano de obra")
                                                        ])
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { xs4: "" } },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          attrs: { dark: "", color: "#75d57b" }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs5: "" } },
                                                [
                                                  _c(
                                                    "v-layout",
                                                    {
                                                      attrs: {
                                                        "align-center": "",
                                                        "justify-center": "",
                                                        row: "",
                                                        "fill-height": ""
                                                      }
                                                    },
                                                    [
                                                      _c("v-img", {
                                                        attrs: {
                                                          src:
                                                            "/fonts/local/total.svg",
                                                          height: "50px",
                                                          contain: ""
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-flex",
                                                { attrs: { xs7: "" } },
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    {
                                                      attrs: {
                                                        "primary-title": ""
                                                      }
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "headline total-title"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "$ " +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "currency"
                                                                  )(
                                                                    _vm.info
                                                                      .total_mano_de_obra +
                                                                      _vm.info
                                                                        .total_materiales
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c("div", [
                                                          _vm._v("Total")
                                                        ])
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider"),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      _c(
                                        "v-tabs",
                                        {
                                          attrs: {
                                            "fixed-tabs": "",
                                            centered: "",
                                            color: "white",
                                            "slider-color": "#1aba24"
                                          },
                                          model: {
                                            value: _vm.vTabs,
                                            callback: function($$v) {
                                              _vm.vTabs = $$v
                                            },
                                            expression: "vTabs"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-tab",
                                            {
                                              attrs: { href: "#tab-manzanas" }
                                            },
                                            [_vm._v("Manzanas")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-tab",
                                            { attrs: { href: "#tab-calles" } },
                                            [_vm._v("Calles")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-tab",
                                            {
                                              attrs: {
                                                href: "#tab-areas-verdes"
                                              }
                                            },
                                            [_vm._v("Áreas verdes")]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tabs-items",
                                        {
                                          model: {
                                            value: _vm.vTabs,
                                            callback: function($$v) {
                                              _vm.vTabs = $$v
                                            },
                                            expression: "vTabs"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-tab-item",
                                            {
                                              attrs: { value: "tab-manzanas" }
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-card-text",
                                                        [
                                                          _c("v-data-table", {
                                                            ref:
                                                              "manzanasTable",
                                                            attrs: {
                                                              "hide-actions":
                                                                "",
                                                              items:
                                                                _vm.manzanas
                                                                  .data,
                                                              expand:
                                                                _vm.expandManzana
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "headers",
                                                                  fn: function(
                                                                    props
                                                                  ) {
                                                                    return [
                                                                      _c("tr", [
                                                                        _c(
                                                                          "th",
                                                                          [
                                                                            _vm._v(
                                                                              "Nombre"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "th",
                                                                          [
                                                                            _vm._v(
                                                                              "Total de lotes"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "th",
                                                                          [
                                                                            _vm._v(
                                                                              "Subtotal de materiales"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "th",
                                                                          [
                                                                            _vm._v(
                                                                              "Subtotal de mano de obra"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "th",
                                                                          {
                                                                            staticClass:
                                                                              "text-xs-right"
                                                                          }
                                                                        )
                                                                      ])
                                                                    ]
                                                                  }
                                                                },
                                                                {
                                                                  key: "items",
                                                                  fn: function(
                                                                    props
                                                                  ) {
                                                                    return [
                                                                      _c("tr", [
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _vm._v(
                                                                              "Manzana " +
                                                                                _vm._s(
                                                                                  props
                                                                                    .item
                                                                                    .nombre
                                                                                )
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "badge badge-secondary",
                                                                                staticStyle: {
                                                                                  "background-color":
                                                                                    "#ff5617"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                                            " +
                                                                                    _vm._s(
                                                                                      props
                                                                                        .item
                                                                                        .lotes_count
                                                                                    ) +
                                                                                    "\n                                                                        "
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _vm._v(
                                                                              "$ " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "currency"
                                                                                  )(
                                                                                    props
                                                                                      .item
                                                                                      .total_de_materiales
                                                                                  )
                                                                                )
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _vm._v(
                                                                              "$ " +
                                                                                _vm._s(
                                                                                  _vm._f(
                                                                                    "currency"
                                                                                  )(
                                                                                    props
                                                                                      .item
                                                                                      .total_mano_de_obra
                                                                                  )
                                                                                )
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            staticClass:
                                                                              "text-xs-right"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  flat:
                                                                                    "",
                                                                                  icon:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.openLotesExpand(
                                                                                      props
                                                                                        .item
                                                                                        .id,
                                                                                      props.index
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        props.expanded
                                                                                          ? "expand_less"
                                                                                          : "expand_more"
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ])
                                                                    ]
                                                                  }
                                                                },
                                                                {
                                                                  key: "expand",
                                                                  fn: function(
                                                                    props
                                                                  ) {
                                                                    return [
                                                                      _c(
                                                                        "table",
                                                                        {
                                                                          staticClass:
                                                                            "table table-striped table-hover table-sm"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "thead",
                                                                            [
                                                                              _c(
                                                                                "tr",
                                                                                [
                                                                                  _c(
                                                                                    "th",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Lote"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "th",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Subtotal de materiales"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "th",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Subtotal de mano de obra"
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "th",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Acciones"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "tbody",
                                                                            _vm._l(
                                                                              _vm
                                                                                .manzanas
                                                                                .data[
                                                                                props
                                                                                  .index
                                                                              ]
                                                                                .lotes,
                                                                              function(
                                                                                lote,
                                                                                index
                                                                              ) {
                                                                                return _c(
                                                                                  "tr",
                                                                                  {
                                                                                    key: index
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            lote.nombre
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "$ " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "currency"
                                                                                              )(
                                                                                                lote.total_de_materiales
                                                                                              )
                                                                                            )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "$ " +
                                                                                            _vm._s(
                                                                                              _vm._f(
                                                                                                "currency"
                                                                                              )(
                                                                                                lote.total_de_mano_de_obra
                                                                                              )
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "td",
                                                                                      [
                                                                                        _c(
                                                                                          "v-tooltip",
                                                                                          {
                                                                                            attrs: {
                                                                                              bottom:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    slot:
                                                                                                      "activator",
                                                                                                    href:
                                                                                                      "/fraccionamientos/" +
                                                                                                      _vm.fraccionamientoId +
                                                                                                      "/reporte/desglose?item_id=" +
                                                                                                      lote.id +
                                                                                                      "&item_type=lote",
                                                                                                    icon:
                                                                                                      "",
                                                                                                    flat:
                                                                                                      "",
                                                                                                    dark:
                                                                                                      "",
                                                                                                    small:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "verdeFayal"
                                                                                                  },
                                                                                                  slot:
                                                                                                    "activator"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "vertical_split"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ],
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Ver desglose por concepto"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          2
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              }
                                                                            ),
                                                                            0
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ]
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c("v-spacer"),
                                                          _vm._v(" "),
                                                          _c("pagination", {
                                                            attrs: {
                                                              limit: 10,
                                                              data: _vm.manzanas
                                                            },
                                                            on: {
                                                              "pagination-change-page":
                                                                _vm.getManzanas
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("v-spacer")
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-tab-item",
                                            {
                                              attrs: {
                                                value: "tab-areas-verdes"
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-card-text",
                                                        [
                                                          _c("v-data-table", {
                                                            attrs: {
                                                              "hide-actions":
                                                                "",
                                                              items:
                                                                _vm.areas_verdes
                                                                  .data
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "headers",
                                                                  fn: function(
                                                                    props
                                                                  ) {
                                                                    return [
                                                                      _c("tr", [
                                                                        _c(
                                                                          "th",
                                                                          [
                                                                            _vm._v(
                                                                              "Nombre"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "th",
                                                                          [
                                                                            _vm._v(
                                                                              "Subtotal de materiales"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "th",
                                                                          {
                                                                            staticClass:
                                                                              "text-xs-right"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Acciones"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ])
                                                                    ]
                                                                  }
                                                                },
                                                                {
                                                                  key: "items",
                                                                  fn: function(
                                                                    props
                                                                  ) {
                                                                    return [
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            props
                                                                              .item
                                                                              .nombre
                                                                          )
                                                                        )
                                                                      ]),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          "$ " +
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "currency"
                                                                              )(
                                                                                props
                                                                                  .item
                                                                                  .total_de_materiales
                                                                              )
                                                                            )
                                                                        )
                                                                      ]),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("td", {
                                                                        staticClass:
                                                                          "text-xs-right"
                                                                      })
                                                                    ]
                                                                  }
                                                                }
                                                              ]
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c("v-spacer"),
                                                          _vm._v(" "),
                                                          _c("pagination", {
                                                            attrs: {
                                                              limit: 10,
                                                              data:
                                                                _vm.areas_verdes
                                                            },
                                                            on: {
                                                              "pagination-change-page":
                                                                _vm.getAreasVerdes
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("v-spacer")
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-tab-item",
                                            { attrs: { value: "tab-calles" } },
                                            [
                                              _c(
                                                "v-card",
                                                { attrs: { flat: "" } },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-card-text",
                                                        [
                                                          _c("v-data-table", {
                                                            attrs: {
                                                              "hide-actions":
                                                                "",
                                                              items:
                                                                _vm.calles.data
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "headers",
                                                                  fn: function(
                                                                    props
                                                                  ) {
                                                                    return [
                                                                      _c("tr", [
                                                                        _c(
                                                                          "th",
                                                                          [
                                                                            _vm._v(
                                                                              "Nombre"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "th",
                                                                          [
                                                                            _vm._v(
                                                                              "Subtotal de materiales"
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "th",
                                                                          {
                                                                            staticClass:
                                                                              "text-xs-right"
                                                                          }
                                                                        )
                                                                      ])
                                                                    ]
                                                                  }
                                                                },
                                                                {
                                                                  key: "items",
                                                                  fn: function(
                                                                    props
                                                                  ) {
                                                                    return [
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            props
                                                                              .item
                                                                              .nombre
                                                                          )
                                                                        )
                                                                      ]),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("td", [
                                                                        _vm._v(
                                                                          "$ " +
                                                                            _vm._s(
                                                                              _vm._f(
                                                                                "currency"
                                                                              )(
                                                                                props
                                                                                  .item
                                                                                  .total_de_materiales
                                                                              )
                                                                            )
                                                                        )
                                                                      ]),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("td", {
                                                                        staticClass:
                                                                          "text-xs-right"
                                                                      })
                                                                    ]
                                                                  }
                                                                }
                                                              ]
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-card-actions",
                                                        [
                                                          _c("v-spacer"),
                                                          _vm._v(" "),
                                                          _c("pagination", {
                                                            attrs: {
                                                              limit: 10,
                                                              data: _vm.calles
                                                            },
                                                            on: {
                                                              "pagination-change-page":
                                                                _vm.getCalles
                                                            }
                                                          }),
                                                          _vm._v(" "),
                                                          _c("v-spacer")
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("show-materials-by-distribucion-modal", {
        attrs: {
          show: _vm.showMaterialsModal,
          "distribucion-id": _vm.currentDistribuicionId,
          "distribucion-type": _vm.currentDistribuicionType
        },
        on: {
          "update:show": function($event) {
            _vm.showMaterialsModal = $event
          },
          "update:distribucionId": function($event) {
            _vm.currentDistribuicionId = $event
          },
          "update:distribucion-id": function($event) {
            _vm.currentDistribuicionId = $event
          },
          "update:distribucionType": function($event) {
            _vm.currentDistribuicionType = $event
          },
          "update:distribucion-type": function($event) {
            _vm.currentDistribuicionType = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }