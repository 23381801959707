<template>
  <v-layout>
    <v-dialog :value="dialog" persistent max-width="700px" hide-overlay>
      <v-card>
        <v-toolbar flat color="white">
          <v-toolbar-title class="primary-title">Conceptos y rubros para: {{conceptosDad.nombre ? conceptosDad.nombre : dadName ? dadName : "No disponible"}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="$emit('close');">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-layout wrap>
            <v-autocomplete
              v-if="dad == 'Obra'"
              v-model="conceptosData.selected"
              browser-autocomplete="off"
              hide-selected
              return-object
              no-data-text="No hay conceptos disponibles"
              placeholder="Seleccione las conceptos a incluir en la etapa"
              :chips="true"
              :multiple="true"
              :deletable-chips="true"
              :item-text="'concepto.nombre' || 'concepto.name'"
              item-value="concepto.id"
              :loading="loading"
              :items="conceptosData.items"
            >
              <template slot="no-data">
                <v-list-tile style="cursor: pointer;">
                  <v-list-tile-content>
                    <v-list-tile-title>Sin elementos que mostrar.</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </v-autocomplete>

            <v-autocomplete
              v-else
              v-model="conceptosData.selected"
              browser-autocomplete="off"
              hide-selected
              return-object
              no-data-text="No hay conceptos disponibles"
              placeholder="Seleccione las conceptos a incluir en la etapa"
              :chips="true"
              :multiple="true"
              :deletable-chips="true"
              item-text="nombre"
              item-value="id"
              :loading="loading"
              :items="conceptosData.items"
            >
              <template slot="no-data">
                <v-list-tile style="cursor: pointer;">
                  <v-list-tile-content>
                    <v-list-tile-title>Sin elementos que mostrar.</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </v-autocomplete>
          </v-layout>

          <v-data-table
              v-if="dad == 'Obra'"
              :hide-actions="true"
              :disable-initial-sort="true"
              :headers="conceptosData.headers"
              :items="conceptosData.selected"
              class="elevation-1"
            > 
              <template slot="items" slot-scope="props">
                <td>{{props.item.concepto.nombre}}</td>
                <td>
                  <v-chip v-for="rubro in props.item.concepto.rubros" :key="rubro.id" class="ma-2" color="verdeFayal" @click="addRubro(props.item.id, rubro)" outline>
                    {{ rubro.nombre }}
                  </v-chip> 
                </td>
                <td>
                  <v-chip v-for="rubroData in conceptosData.rubros.filter(rubro => rubro.conceptoId ? rubro.conceptoId == props.item.id : rubro.concepto_id == props.item.id )" :key="rubroData.rubro ? rubroData.rubro.id : rubroData.id" class="ma-2" color="verdeFayal" @input="removeRubro(props.item.id, rubroData)" outline close>
                    {{ rubroData.rubro ? rubroData.rubro.nombre : rubroData.nombre }}
                  </v-chip>
                </td>
              </template>
          </v-data-table>

          <v-data-table
            v-else
            :hide-actions="true"
            :disable-initial-sort="true"
            :headers="conceptosData.headers"
            :items="conceptosData.selected"
            class="elevation-1"
          > 
              <template slot="items" slot-scope="props">
                <td>{{props.item.nombre}}</td>
                <td>
                  <v-chip v-for="rubro in props.item.rubros" :key="rubro.id" class="ma-2" color="verdeFayal" @click="addRubro(props.item.id, rubro)" outline>
                    {{ rubro.nombre }}
                  </v-chip> 
                </td>
                <td>
                  <v-chip v-for="rubroData in conceptosData.rubros.filter(rubro => rubro.conceptoId ? rubro.conceptoId == props.item.id : rubro.concepto_id == props.item.id )" :key="rubroData.rubro ? rubroData.rubro.id : rubroData.id" class="ma-2" color="verdeFayal" @input="removeRubro(props.item.id, rubroData)" outline close>
                    {{ rubroData.rubro ? rubroData.rubro.nombre : rubroData.nombre }}
                  </v-chip>
                </td>
              </template>
          </v-data-table>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="verdeFayal"
            flat
            @click="aceptar"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { errorHandling } from "../../lib/utilities";
export default {
  props: ["dialog", 'conceptosDad', 'dad','conceptos', 'dadId', 'dadName', 'conceptosAutofill', 'rubrossAutofill', 'loteData'],

  data() {
    return {
      loading: false,
      conceptosData: {
        items:[],
        selected:[],
        rubros:[],
        headers:[
          { 
            sortable: false,
            text: 'Concepto' 
          },
          { 
            sortable: false,
            text: 'Disponibles' 
          },
          { 
            sortable: false,
            text: 'Seleccionados' 
          },
        ],
      },
      localFraccionamiento: {},
    };
  },
  watch:{
    conceptos: function(value){
      this.conceptosData.items =  value;
    },
    dialog: function(value){
      if(!value){
        this.conceptosData.selected = [];
        this.conceptosData.rubros = [];
      }else{
        if(this.dad == 'Obra' || this.dad == 'obra'){
            this.autoFillObra();
          }else{
            this.getConceptosUrbanizacion();
        }
      }
    },
  },
  methods: {
    getConceptosUrbanizacion: function () {
      this.loading = true;
      axios.get(`/urbanizacion/valores`)
      .then(response => { 
        this.conceptosData.items = response.data.conceptos;
        this.autoFillUrbanizacion();
      })
      .catch( error => errorHandling(error))
      .finally(()=> this.loading = false)
    },
    autoFillObra: function(){
      let localRubros = [];
      let localConceptos = [];
      let conceptosIdsArray = this.loteData.rubros ? new Set(this.loteData.rubros.map(rubro => rubro.conceptoId ? rubro.conceptoId : rubro.concepto_id)) : [];
      conceptosIdsArray.forEach(element => {
        let conceptoEncontrado = this.conceptosData.items.find(concepto => concepto.id == element);          
        if(conceptoEncontrado)
          localConceptos.push(conceptoEncontrado);
      });
      localRubros = this.loteData.rubros ? this.loteData.rubros : [];

      this.conceptosData.selected = localConceptos;
      this.conceptosData.rubros = localRubros;
    },
    autoFillUrbanizacion: function(){
      if(this.conceptosAutofill){
        let localConceptosSelected = [];
        let localRubrossSelected = [];
        this.conceptosAutofill.forEach(element => {
          let conceptoEncontrado = this.conceptosData.items.find(concepto => concepto.id == element);
          if(conceptoEncontrado) {
            localConceptosSelected.push(conceptoEncontrado);
            this.rubrossAutofill.forEach(rubroItem => {
              let rubroEncontrado = conceptoEncontrado.rubros.find(rubro => rubro.id == rubroItem);
              if(rubroEncontrado)
                localRubrossSelected.push({conceptoId: conceptoEncontrado.id, rubro: rubroEncontrado});
            })
          }
        });
        this.conceptosData.selected = localConceptosSelected;
        this.conceptosData.rubros = localRubrossSelected;
      }
    },
    addRubro: function(conceptoId, rubro){
      if(this.conceptosData.rubros.filter(rubroData => rubroData.rubro.id == rubro.id).length > 0) return;
      this.conceptosData.rubros.push({conceptoId: conceptoId, rubro: rubro});
    },
    removeRubro: function(conceptoId, rubro){
      this.conceptosData.rubros.splice(this.conceptosData.rubros.indexOf(rubro), 1);
    },
    aceptar: function(){
      let dataToSend = {loteId: this.conceptosDad.id, rubros: this.conceptosData.rubros, urbanizacionId: this.dadId};
      if( !dataToSend.rubros.length ) return alertify.error('No se han seleccionado rubros');
      this.$emit('dataSended', dataToSend);
      this.$emit('close');
    }
  },
};
</script>