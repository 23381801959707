<template>
    <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" flat icon :href="`/etapas/${etapa.id}/edit`">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>

        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-toolbar card prominent>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="body-2 grey--text">Ver resumen de materiales para la </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                        <v-container grid-list-md>
                            <pre>{{ conceptos }}</pre>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    data() {
      return {
        vBtnSave: {
            loading: false
        },
        vSnackBar: {
            visible: false,
            text: null
        },
      }
    },
    props: {
      etapa: Object,
      tipo: String
    },
    methods: {
        
    },
    watch: {

    },
    computed: {
        getConceptos: function() {
            axios.get(`/etapas/${this.etapa.id}/distribucion/materiales`)
            .then(response => this.conceptos = response.data)
            .catch(error => errorHandling(error));
        }
    },
    mounted() {
        this.getConceptos();
    },
  }
</script>
