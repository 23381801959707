<template>
    <div>
        <v-card flat>
            <v-toolbar color="verdeFayal" dark extended flat>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn flat icon @click="back">
                            <v-icon>keyboard_arrow_left</v-icon>
                        </v-btn>
                    </template>
                    <span>Regresar</span>
                </v-tooltip>
            </v-toolbar>

            <v-layout row pb-2>
                <v-flex xs10 offset-xs1>
                    <v-card class="card--flex-toolbar">
                        <v-toolbar card prominent>
                            <v-spacer></v-spacer>
                            <v-toolbar-title class="body-2 grey--text">Lotes en promoción de {{ fraccionamiento.nombre_proyecto }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>

                        <v-card-text>
                            <v-card-text>
                                <v-data-table
                                    hide-actions
                                    :items="manzanas.data"
                                    :expand="expandManzana"
                                    ref="manzanasTable"
                                >
                                    <template v-slot:headers="props">
                                        <tr>
                                            <th>Nombre</th>
                                            <th>Total de lotes en promoción</th>
                                            <th class="text-xs-right"></th>
                                        </tr>
                                    </template>
                                    <template v-slot:items="props">
                                        <tr>
                                            <td>Manzana {{props.item.nombre}}</td>
                                            <td><span>{{ props.item.lotes_en_promocion_count }} de {{ props.item.lotes_count }} de lotes totales</span></td>
                                            <td class="text-xs-right">
                                                <v-btn flat icon @click="openLotesExpand(props.item.id, props.index)">
                                                    <v-icon>{{(props.expanded) ? 'expand_less' : 'expand_more'}}</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-slot:expand="props">
                                        <table class="table table-striped table-hover table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Lote</th>
                                                    <th>Modelo</th>
                                                    <th>Estado de venta</th>
                                                    <th>Estado de construcción</th>
                                                    <th>Porcentaje de avance</th>
                                                    <th>¿Hay trabajos externos?</th>
                                                    <th>Saldo</th>
                                                    <th>Dias restantes para liberar lote</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(lote, index) in manzanas.data[props.index].lotes" :key="index">
                                                    <td>{{ lote.nombre }}</td>
                                                    <td>{{ (lote.prototipo) ? lote.prototipo.nombre : null }}</td>
                                                    <td>{{ (lote.estado) ? lote.estado.information.estado : 'Sin promocionar'}}</td>
                                                    <td>{{ lote.estado_de_construccion }}</td>
                                                    <td>
                                                        <div class="progress">
                                                            <div 
                                                                class="progress-bar progress-bar-striped progress-bar-animated bg-success" 
                                                                role="progressbar" 
                                                                :style="`width: ${lote.porcentaje_de_avance}%;`" 
                                                                :aria-valuenow="lote.porcentaje_de_avance" 
                                                                aria-valuemin="0" 
                                                                aria-valuemax="100"
                                                            >
                                                                {{ lote.porcentaje_de_avance.toFixed(2) }} %
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td> {{ lote.trabajos_externos ? "Si" : "No" }} </td>
                                                    <td v-if="lote.venta && lote.venta.valor_de_saldo > 0">{{ lote.venta.saldo_a_pagar | currency }}</td>
                                                    <td v-else> 
                                                        <span 
                                                            class="badge badge-light"
                                                        >
                                                            Sin venta asociada aún
                                                        </span> 
                                                    </td>
                                                    <td>
                                                        <template>
                                                            <span 
                                                                v-if="lote.venta && lote.venta.valor_de_saldo && !lote.venta.pagado"
                                                                :style="`background-color: ${getTrafficLightColor(lote.venta.dias_restantes_para_liberar)};`" 
                                                                class="badge badge-secondary"
                                                            >
                                                                {{ lote.venta.dias_restantes_para_liberar > 0 ? `${lote.venta.dias_restantes_para_liberar} dias restantes` : 'Lote disponible para liberar'}}
                                                            </span>
                                                            <span 
                                                                v-else-if="lote.venta && lote.venta.valor_de_saldo && lote.venta.pagado"
                                                                style="background-color: 008450;" 
                                                                class="badge badge-secondary"
                                                            >
                                                                Lote pagado
                                                            </span>
                                                            <span 
                                                                v-else
                                                                class="badge badge-light"
                                                            >
                                                                Sin venta asociada aún
                                                            </span>
                                                        </template>
                                                    </td>
                                                    <td>
                                                        <v-layout>
                                                            <v-tooltip bottom>
                                                                <v-btn 
                                                                    @click="openCustomersModal(lote.id, props.item.id, props.index)" 
                                                                    :disabled="!hasPrice(lote.precios)"
                                                                    color="verdeFayal"
                                                                    slot="activator" 
                                                                    x-small 
                                                                    icon 
                                                                    flat 
                                                                    dark 
                                                                >
                                                                    <v-icon>group_add</v-icon>
                                                                </v-btn>
                                                                <span>Añadir clientes</span>
                                                            </v-tooltip>
                                                            <v-tooltip bottom>
                                                                <v-btn
                                                                    @click="openFilesModal((lote.venta)  ? lote.venta.id : null)"
                                                                    :disabled="!lote.venta"
                                                                    color="warning"
                                                                    slot="activator"
                                                                    flat
                                                                    icon
                                                                >
                                                                    <v-icon>folder</v-icon>
                                                                </v-btn>
                                                                <span>Archivos</span>
                                                            </v-tooltip>
                                                            <v-tooltip bottom>
                                                                <v-btn
                                                                    :disabled="lote.estado.estado_id == 21 && !lote.venta"
                                                                    :href="`/promociones/${lote.id}/pagos`"
                                                                    color="verdeFayal"
                                                                    slot="activator"
                                                                    flat
                                                                    icon
                                                                >
                                                                    <v-icon>local_atm</v-icon>
                                                                </v-btn>
                                                                <span>Datos generales de venta</span>
                                                            </v-tooltip>

                                                            <v-tooltip bottom>
                                                                <v-btn
                                                                    :disabled="!lote.venta"
                                                                    @click="finishPurchase(lote.venta.id, props.item.id, props.index)"
                                                                    slot="activator"
                                                                    color="verdeFayal"
                                                                    flat
                                                                    icon
                                                                >
                                                                    <v-icon>check_circle_outline</v-icon>
                                                                </v-btn>
                                                                <span>Finalizar venta</span>
                                                            </v-tooltip>

                                                            <v-tooltip bottom>
                                                                <v-btn
                                                                    :disabled="(!lote.venta) || (lote.venta && lote.venta.finalizada == 0)"
                                                                    @click="deliverLot(lote.venta.id, props.item.id, props.index)"
                                                                    slot="activator"
                                                                    color="verdeFayal"
                                                                    flat
                                                                    icon
                                                                >
                                                                    <v-icon>roofing</v-icon>
                                                                </v-btn>
                                                                <span>Entregar vivienda</span>
                                                            </v-tooltip>

                                                            <v-tooltip bottom>
                                                                <v-btn
                                                                    :disabled="(!lote.venta) || (lote.venta && !lote.venta.liberar_lote) || (lote.venta.pagado) || (lote.venta.finalizada == 1)"
                                                                    @click="freeLot(lote.venta.id, props.item.id, props.index)"
                                                                    slot="activator"
                                                                    color="red"
                                                                    flat
                                                                    icon
                                                                >
                                                                    <v-icon>lock_open</v-icon>
                                                                </v-btn>
                                                                <span>Liberar lote</span>
                                                            </v-tooltip>
                                                        </v-layout>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <pagination 
                                    :limit="10" 
                                    :data="manzanas" 
                                    @pagination-change-page="getManzanas"
                                ></pagination>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-card>
        <!-- Modal -->
        <add-customers-to-lote-promocion-modal :show.sync="showCustomersModal" :lote-id="currentLoteId"/>
        <archivos-lotes-modal
            :venta-id="currentSaleId"
            :show.sync="dialogFile"
        />
        <finish-purchase-modal :show.sync="showFinishPurchaseModal" :venta-id="currentSaleId"/>
        <deliver-lote-modal :show.sync="showDeliverLotModal" :venta-id="currentSaleId"/>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    data() {
        return {
            dialogFile: false,
            lotesFiles: "",
            dialog: false,
            elementId: "",
            currentSaleId: null,
            showFinishPurchaseModal: false,
            showDeliverLotModal: false,
            fraccionamiento: {},
                manzanas: {
                data:[]
            },
            expandManzana: false,
            showCustomersModal: false,
            currentLoteId: null,
            currentManzanaId: null,
            currentIndex: null,
            vSnackBar: {
                visible: false,
                text: null
            }
        }
    },
    props: {
        fraccionamientoId: String
    },
    watch: {
        showCustomersModal: function() {
            this.reloadManzana(this.currentManzanaId, this.currentIndex);
        },
        showFinishPurchaseModal: function() {
            this.reloadManzana(this.currentManzanaId, this.currentIndex);
        },
        showDeliverLotModal: function() {
            this.reloadManzana(this.currentManzanaId, this.currentIndex);
        }
    },
    methods: {
      getFraccionamiento: function() {
        axios.get(laravelRoute('fraccionamientos.show.refactor', this.fraccionamientoId))
        .then(response => this.fraccionamiento = response.data)
        .catch(error => errorHandling(error));
      },
      getManzanas: function(page = 1){
        axios.get(`/fraccionamientos/${this.fraccionamientoId}/manzanas_con_lotes?page=${page}`)
        .then(response => this.manzanas = response.data)
        .catch(error => errorHandling(error));
      },
      openLotesExpand: function(id, index, page = 1) {
        let expand = !this.$refs.manzanasTable.expanded[id];
        axios.get(`/fraccionamientos/${this.fraccionamientoId}/manzanas/${id}/lotes_en_promocion?page=${page}`)
        .then(response => {
            this.manzanas.data[index].lotes = response.data;
        })
        .catch(error => errorHandling(error))
        .finally(() => this.$set(this.$refs.manzanasTable.expanded, id, expand));
      },
      openCustomersModal: function(loteId, manzanaId, indexManzana) {
        this.currentLoteId = loteId;
        this.currentManzanaId = manzanaId;
        this.currentIndex = indexManzana;
        this.showCustomersModal = true;
      },
      hasPrice: function(price) {
          return price.some(item => item.precio > 0)
      },
      getTrafficLightColor: function(days) {
        if(days > 15) {
            return '#008450';
        } 
        else if(days <= 0) {
            return "#B81D13";
        }
        else {
            return "#EFB700";
        }
      },
      freeLot: function(ventaId, manzanaId, indexManzana) {
        swal({
            title: "¿Estás seguro?",
            text: "Una vez eliminado no se podrá recuperar",
            icon: "warning",
            buttons: true,
            dangerMode: true
        }).then(willDelete => {
            if (willDelete) {
                axios.delete(laravelRoute('ventas.destroy', ventaId))
                .then(response => {
                    this.reloadManzana(manzanaId, indexManzana);
                    this.vSnackBar.text = response.data.message_text;
                    this.vSnackBar.visible = true;
                })
                .catch(error => errorHandling(error));
            }
        });
      },
      finishPurchase: function(ventaId, manzanaId, indexManzana) {
        this.currentManzanaId = manzanaId;
        this.currentIndex = indexManzana;
        this.currentSaleId = ventaId;
        this.showFinishPurchaseModal = true;
      },
      deliverLot: function(ventaId, manzanaId, indexManzana) {
        this.currentManzanaId = manzanaId;
        this.currentIndex = indexManzana;
        this.currentSaleId = ventaId;
        this.showDeliverLotModal = true;
      },
      reloadManzana: function( manzanaId, index, page = 1) {
        axios.get(`/fraccionamientos/${this.fraccionamientoId}/manzanas/${manzanaId}/lotes_en_promocion?page=${page}`)
        .then(response => {
            let oldManzana = this.manzanas.data[index];
            this.manzanas.data.splice(index, 1, {
                ...oldManzana,
                lotes: response.data
            });
        })
        .catch(error => errorHandling(error))
      },
      openFilesModal: function(ventaId) {
        this.dialogFile = true;
        this.currentSaleId = ventaId;
      },
      back: function() {
        history.back()
      }
    },
    created() {
        this.getFraccionamiento();
        this.getManzanas();
    }
  }
</script>

<style>
    .badge {
        font-size: 95%;
    }
</style>
