var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { flat: "", "max-width": "700px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Agregar comentarios")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", [
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.storeComentario()
                      }
                    }
                  },
                  [
                    _c(
                      "v-container",
                      { staticStyle: { "max-width": "600px" } },
                      [
                        _c(
                          "v-timeline",
                          { attrs: { dense: "", clipped: "" } },
                          [
                            _vm._l(_vm.comentarios, function(comentario) {
                              return _c(
                                "v-timeline-item",
                                {
                                  key: comentario.id,
                                  staticClass: "mb-3",
                                  attrs: { color: "verdeFayal", small: "" }
                                },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { "justify-space-between": "" } },
                                    [
                                      _c("v-flex", {
                                        attrs: { xs7: "" },
                                        domProps: {
                                          textContent: _vm._s(
                                            comentario.comentario
                                          )
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-flex", {
                                        attrs: { xs5: "", "text-xs-right": "" },
                                        domProps: {
                                          textContent: _vm._s(
                                            comentario.created_at
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "v-timeline-item",
                              {
                                staticClass: "white--text mb-2",
                                attrs: { "fill-dot": "", color: "orange" }
                              },
                              [
                                _c("v-text-field", {
                                  staticStyle: { padding: "0" },
                                  attrs: {
                                    "hide-details": "",
                                    flat: "",
                                    label: "Dejar un comentario...",
                                    solo: ""
                                  },
                                  model: {
                                    value: _vm.comentario,
                                    callback: function($$v) {
                                      _vm.comentario = $$v
                                    },
                                    expression: "comentario"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: { click: _vm.storeComentario }
                    },
                    [_vm._v(" Guardar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }