<template>
    <div class="text-xs-center">
        <v-dialog v-model="localShow" max-width="1350px" persistent>
            <v-card>
                <v-toolbar color="white" flat tabs>
                    <v-toolbar-title class="primary-title">Rubro: {{name}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-title class="primary-title">Administrar materiales</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                    <v-tabs slot="extension" v-model="vTabs" centered color="white" slider-color="verdeFayal">
                        <v-tab href="#tab-index-material" v-if="$root.can('valores-materiales-index', permissions) ? true : false">Lista de materiales</v-tab>
                        <v-tab href="#tab-add-material" v-if="$root.can('valores-materiales-new', permissions) ? true : false">Añadir materiales</v-tab>
                    </v-tabs>
                </v-toolbar>
                <v-tabs-items v-model="vTabs">
                    <v-tab-item value="tab-index-material">
                        <index-material-value
                            :item-id.sync="localItemId"
                            :reload.sync="reload"
                            :household-id.sync="householdId"
                            :model-type="modelType"
                            :permissions="permissions"
                        ></index-material-value>
                    </v-tab-item>
                    <v-tab-item value="tab-add-material">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-container grid-list-md v-for="(material, index) in materiales" :key="index">
                                <v-layout wrap>
                                    <v-flex md2>
                                        <v-autocomplete :items="categories" @change="getCurrentMateriales(material.categoria_id, index)" :rules="rules" v-model="material.categoria_id" item-value="id" item-text="nombre" label="Categorias" single-line style=""></v-autocomplete>
                                    </v-flex>
                                    <v-flex md2>
                                        <v-autocomplete  :items="material.materiales" v-model="material.material" :rules="rules" return-object item-text="nombre" label="Material" single-line></v-autocomplete>
                                    </v-flex>
                                    <v-flex md2>
                                        <v-text-field :value="material.material.unidad" readonly required label="Unidades" disabled></v-text-field>
                                    </v-flex>
                                    <v-flex md1>
                                        <v-text-field v-model="material.cantidad" type="number" min="0.1" required :rules="rules" label="Cantidad"></v-text-field>
                                    </v-flex>
                                    <v-flex md2>
                                        <v-text-field prepend-icon="attach_money" :value="material.material.precio" readonly disabled required label="Precio unitario"></v-text-field>
                                    </v-flex>
                                    <v-flex md2>
                                        <v-text-field prepend-icon="attach_money" step="any" :value="parseFloat(material.material.precio) * parseFloat(material.cantidad)" readonly disabled label="Precio total"></v-text-field>
                                    </v-flex>
                                    <v-flex md1>
                                        <v-btn v-if="index != 0" @click="materiales.splice(index, 1)" fab flat dark small color="verdeFayal">
                                            <v-icon dark>remove</v-icon>
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-tab-item>
                </v-tabs-items>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark v-if="vTabs == 'tab-add-material'" color="verdeFayal" @click="storeMateriales" flat :loading="vBtnSave.loading"> Guardar </v-btn>
                    <v-btn dark v-if="vTabs == 'tab-add-material'" color="verdeFayal" @click="addMateriales">
                        <v-icon>add</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
  </div>
</template>

<script>
    import { errorHandling, laravelRoute } from "../../lib/utilities";
    export default {
    data: () => ({
        categories: [],
        materiales: [
            {
               categoria_id: null,
               material: {
                   precio: 0
               },
               cantidad: 0,
               materiales: []
            }
        ],
        vTabs: "tab-index-material",
        unidades: [
            "Metros", 
            "Metros cuadrados", 
            "Metros cúbicos", 
            "Bultos", 
            "Piezas", 
            "Rollo", 
            "Litros", 
            "Juego", 
            "Kilogramos"
        ],
        reload: false,
        localShow: false,
        localItemId: null,
        valid: false,
        rules: [
            v => !!v || 'Este campo es requerido',
        ],
        vBtnSave: {
            loading: false
        },
        vSnackBar: {
            visible: false,
            text: null
        }
    }),
    props: {
      show: Boolean,
      itemId: Number,
      householdId: String,
      name: String,
      permissions: Object,
      modelType: String
    },
    watch: {
        show: function (value) {
            this.localShow = value
        },
        localShow: function (value) {
            this.$emit('update:show', value)
        },
        itemId: function(value) {
            this.localItemId = value;
        },
        vTabs: function(value) {
            this.reload = (value == "tab-index-material") ? true : false;
        }
    },
    methods: {
        getCategories: function() {
            axios.get(laravelRoute('categorias.filtro', {
                query: "Materiales de construccíon"
            }))
            .then(response => {
                this.categories = response.data;                
            })
            .catch( error => errorHandling(error));  
        },
        getCurrentMateriales: function(category_id, index) {
            var categories = [];

            this.categories.map(function (category) {
                categories.push(category);
            });

            this.materiales[index].materiales = categories.find(function(category) {
                return category.id == category_id
            }).materiales;           

        },
        addMateriales: function() {
            this.materiales.push({
               categoria_id: null,
               material: {
                   precio: 0
               },
               cantidad: 0,
               materiales: []
            });
        },
        storeMateriales: function() {
            if (this.$refs.form.validate()) {
                this.vBtnSave.loading = true;
                axios.post(laravelRoute('materiales_valores.store'), {
                    materiales: this.materiales,
                    rubro_id: this.itemId,
                    model_id: this.householdId,
                    model_type: this.modelType
                })
                .then(response => {
                    this.materiales = [{
                        categoria_id: null,
                        material: {
                            precio: 0
                        },
                        cantidad: 0,
                        materiales: []
                    }];                         
                    alertify.success(response.data.message_text)
                    this.localShow = false
                })
                .catch( error => errorHandling(error))
                .finally(() => this.vBtnSave.loading = false);  
            }
        }
    },
    mounted: function() {
        this.getCategories();
    }
}
</script>
