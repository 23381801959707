var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.dialog,
            persistent: "",
            "max-width": "700px",
            "hide-overlay": ""
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { flat: "", color: "white" } },
                [
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v(
                      "Conceptos y rubros para: " +
                        _vm._s(
                          _vm.conceptosDad.nombre
                            ? _vm.conceptosDad.nombre
                            : _vm.dadName
                            ? _vm.dadName
                            : "No disponible"
                        )
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _vm.dad == "Obra"
                        ? _c(
                            "v-autocomplete",
                            {
                              attrs: {
                                "browser-autocomplete": "off",
                                "hide-selected": "",
                                "return-object": "",
                                "no-data-text": "No hay conceptos disponibles",
                                placeholder:
                                  "Seleccione las conceptos a incluir en la etapa",
                                chips: true,
                                multiple: true,
                                "deletable-chips": true,
                                "item-text":
                                  "concepto.nombre" || "concepto.name",
                                "item-value": "concepto.id",
                                loading: _vm.loading,
                                items: _vm.conceptosData.items
                              },
                              model: {
                                value: _vm.conceptosData.selected,
                                callback: function($$v) {
                                  _vm.$set(_vm.conceptosData, "selected", $$v)
                                },
                                expression: "conceptosData.selected"
                              }
                            },
                            [
                              _c(
                                "template",
                                { slot: "no-data" },
                                [
                                  _c(
                                    "v-list-tile",
                                    { staticStyle: { cursor: "pointer" } },
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v("Sin elementos que mostrar.")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _c(
                            "v-autocomplete",
                            {
                              attrs: {
                                "browser-autocomplete": "off",
                                "hide-selected": "",
                                "return-object": "",
                                "no-data-text": "No hay conceptos disponibles",
                                placeholder:
                                  "Seleccione las conceptos a incluir en la etapa",
                                chips: true,
                                multiple: true,
                                "deletable-chips": true,
                                "item-text": "nombre",
                                "item-value": "id",
                                loading: _vm.loading,
                                items: _vm.conceptosData.items
                              },
                              model: {
                                value: _vm.conceptosData.selected,
                                callback: function($$v) {
                                  _vm.$set(_vm.conceptosData, "selected", $$v)
                                },
                                expression: "conceptosData.selected"
                              }
                            },
                            [
                              _c(
                                "template",
                                { slot: "no-data" },
                                [
                                  _c(
                                    "v-list-tile",
                                    { staticStyle: { cursor: "pointer" } },
                                    [
                                      _c(
                                        "v-list-tile-content",
                                        [
                                          _c("v-list-tile-title", [
                                            _vm._v("Sin elementos que mostrar.")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.dad == "Obra"
                    ? _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          "hide-actions": true,
                          "disable-initial-sort": true,
                          headers: _vm.conceptosData.headers,
                          items: _vm.conceptosData.selected
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "items",
                              fn: function(props) {
                                return [
                                  _c("td", [
                                    _vm._v(_vm._s(props.item.concepto.nombre))
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(props.item.concepto.rubros, function(
                                      rubro
                                    ) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: rubro.id,
                                          staticClass: "ma-2",
                                          attrs: {
                                            color: "verdeFayal",
                                            outline: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addRubro(
                                                props.item.id,
                                                rubro
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(rubro.nombre) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    _vm._l(
                                      _vm.conceptosData.rubros.filter(function(
                                        rubro
                                      ) {
                                        return rubro.conceptoId
                                          ? rubro.conceptoId == props.item.id
                                          : rubro.concepto_id == props.item.id
                                      }),
                                      function(rubroData) {
                                        return _c(
                                          "v-chip",
                                          {
                                            key: rubroData.rubro
                                              ? rubroData.rubro.id
                                              : rubroData.id,
                                            staticClass: "ma-2",
                                            attrs: {
                                              color: "verdeFayal",
                                              outline: "",
                                              close: ""
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.removeRubro(
                                                  props.item.id,
                                                  rubroData
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  rubroData.rubro
                                                    ? rubroData.rubro.nombre
                                                    : rubroData.nombre
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2942881689
                        )
                      })
                    : _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          "hide-actions": true,
                          "disable-initial-sort": true,
                          headers: _vm.conceptosData.headers,
                          items: _vm.conceptosData.selected
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "items",
                            fn: function(props) {
                              return [
                                _c("td", [_vm._v(_vm._s(props.item.nombre))]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  _vm._l(props.item.rubros, function(rubro) {
                                    return _c(
                                      "v-chip",
                                      {
                                        key: rubro.id,
                                        staticClass: "ma-2",
                                        attrs: {
                                          color: "verdeFayal",
                                          outline: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.addRubro(
                                              props.item.id,
                                              rubro
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(rubro.nombre) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  _vm._l(
                                    _vm.conceptosData.rubros.filter(function(
                                      rubro
                                    ) {
                                      return rubro.conceptoId
                                        ? rubro.conceptoId == props.item.id
                                        : rubro.concepto_id == props.item.id
                                    }),
                                    function(rubroData) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: rubroData.rubro
                                            ? rubroData.rubro.id
                                            : rubroData.id,
                                          staticClass: "ma-2",
                                          attrs: {
                                            color: "verdeFayal",
                                            outline: "",
                                            close: ""
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.removeRubro(
                                                props.item.id,
                                                rubroData
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                rubroData.rubro
                                                  ? rubroData.rubro.nombre
                                                  : rubroData.nombre
                                              ) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "verdeFayal", flat: "" },
                      on: { click: _vm.aceptar }
                    },
                    [_vm._v("\n          Guardar\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }