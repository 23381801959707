<template>
  <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn flat icon v-on="on" :href="`/etapas/${etapa.id}/edit`">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>

        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-toolbar card prominent>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="body-2 grey--text">Administrar lotes de la {{ manzanaEtapa.nombre }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout>
                                <v-flex 
                                    :md5="(isVisibleConceptosForm) ? true : false"
                                    :md12="(!isVisibleConceptosForm) ? true : false"
                                >
                                    <v-autocomplete
                                        @keyup.enter.native="addLote"
                                        hint="Buscar lote a trabajar"
                                        prepend-inner-icon="search"
                                        :search-input.sync="query"
                                        :items="searchLotes.data"
                                        :loading="searchLoading"
                                        item-text="nombre"
                                        v-model="selected"
                                        persistent-hint
                                        item-value="id"
                                        label="Buscar"
                                        return-object
                                        hide-selected
                                        hide-no-data
                                    >
                                        <template v-slot:append-outer>
                                        <v-slide-x-reverse-transition mode="out-in">
                                            <v-icon color="success" :disabled="!selected" @click="addLote">add</v-icon>
                                        </v-slide-x-reverse-transition>
                                        </template>
                                    </v-autocomplete>
                                    <v-spacer></v-spacer>
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Lote</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr :class="(lote.id == currentLote.id) ? 'table-active' : ''" v-for="(lote, index) in lotes.data" :key="index">
                                                <td>{{ lote.lote_lotificacion.nombre }}</td>
                                                <td>
                                                    <v-layout>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">       
                                                                <v-btn flat icon color="verdeFayal" @click="openConceptosForm(lote)" v-on="on">
                                                                    <v-icon>edit</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Administrar conceptos y rubros</span>
                                                        </v-tooltip>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">       
                                                                <v-btn 
                                                                    flat 
                                                                    icon
                                                                    color="red" 
                                                                    v-on="on" 
                                                                    @click="deleteLoteEtapa(lote.id)">
                                                                    <v-icon>delete</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>Eliminar lote</span>
                                                        </v-tooltip>
                                                    </v-layout>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </v-flex>

                                <v-flex md7 class="conceptos-form" v-if="isVisibleConceptosForm">
                                    <v-toolbar flat color="white">
                                        <div>
                                            <div class="body-2 grey--text">Conceptos y rubros</div>
                                            <span class="grey--text">Selecciona los conceptos y rubros correspondientes al lote {{ currentLote.lote_lotificacion.nombre }}</span>
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-btn flat icon color="verdeFayal" dark @click="isVisibleConceptosForm = false">
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-progress-linear v-if="vLoadingConceptos" color="verdeFayal" :indeterminate="true"></v-progress-linear>
                                        <v-data-table
                                            :items="conceptosGet"
                                            :expand="expand"
                                            item-key="id"
                                            hide-actions
                                        >
                                            <template v-slot:headers="props">
                                                <tr>
                                                    <th></th>
                                                    <th>Nombre del concepto</th>
                                                    <th></th>
                                                </tr>
                                            </template>
                                            <template v-slot:items="props">
                                                <tr>
                                                    <td>
                                                        <v-checkbox
                                                            v-model="props.item.selected"
                                                            @change="selectedConcepto(props.index)"
                                                        ></v-checkbox>
                                                    </td>
                                                    <td>{{ props.item.concepto.nombre }}</td>
                                                    <td class="border-expanded-td">
                                                        <v-btn flat icon @click="props.expanded = !props.expanded">
                                                            <v-icon>expand_more</v-icon>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                            </template>
                                            <template v-slot:expand="props">
                                                <v-card flat>
                                                    <v-data-table
                                                        :items="props.item.rubros"
                                                        hide-actions
                                                    >
                                                        <template v-slot:headers="propsRubro">
                                                            <tr>
                                                                <th></th>
                                                                <th colspan="2">Nombre del rubro</th>
                                                            </tr>
                                                        </template>
                                                        <template v-slot:items="propsRubro">
                                                            <tr>
                                                                <td></td>
                                                                <td>
                                                                    <v-checkbox
                                                                        v-model="propsRubro.item.selected"
                                                                    ></v-checkbox>
                                                                </td>
                                                                <td>{{ propsRubro.item.nombre}}</td>
                                                            </tr>
                                                        </template>
                                                    </v-data-table>
                                                </v-card>
                                            </template>
                                        </v-data-table>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn v-if="isVisibleConceptosForm" @click="storeConceptosLotes" outline dark color="verdeFayal" flat>Guardar</v-btn>
                                        </v-card-actions>
                                    </v-card-text>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <pagination :limit="10" :data="lotes" @pagination-change-page="getLotesToManzana"></pagination>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    data() {
        return {         
            lotes: {},
            conceptos: [],
            searchLotes: [],
            query: null,
            selected: {},
            currentLote: {},
            selectAllConceptos: false,
            searchLoading: false,
            isVisibleConceptosForm: false,
            expand: true,
            vLoadingConceptos: false,
            isInfiniteScrollLoading: false,
            localConceptos: [],
            conceptosGet: [],
            currentConceptosPage: 0,
            vBtnSave: {
                loading: false
            },
            vSnackBar: {
                visible: false,
                text: null
            },
        }
    },
    props: {
            etapa: Object,
            manzanaEtapa: Object
        },
        watch: {
            query: function() {
                this.searchLotesManzana();
            },
            currentLote: function() {
                this.getLoteConceptos();
            }
        },
        methods: {
            getLotesToManzana: function(page  = 1) {
                axios.get(`/manzanas/${this.manzanaEtapa.manzana_lotificacion_id}/lotes/own?manzana_lote=${this.manzanaEtapa.id}&page=${page}`)
                .then(response => this.lotes = response.data)
                .catch(error => errorHandling(error))
                .finally(() => this.searchLoading = false);
            },
            searchLotesManzana: function() {
                this.searchLoading = true;
                axios.get(`/manzanas/${this.manzanaEtapa.manzana_lotificacion_id}/lotes?query=${this.query}`)
                .then(response => this.searchLotes = response.data)
                .catch(error => errorHandling(error))
                .finally(() => this.searchLoading = false);
            },
            addLote: function() {
                if(this.selected) {
                    axios.post(laravelRoute('etapas.manzanas.lotes', this.etapa.id), {
                        lote: this.selected,
                        manzana_lotificacion: this.manzanaEtapa.id
                    })
                    .then(response =>{
                        this.getLotesToManzana();
                        this.vSnackBar.text = response.data.message_text;
                        this.vSnackBar.loading = true;
                    })
                    .catch(error => errorHandling(error));
                }
            },
            openConceptosForm: function(lote) {
                this.currentLote = lote;
                this.isVisibleConceptosForm = true;
            },
            getLoteConceptos: function() {
                this.vLoadingConceptos = true;
                axios.get(`/etapas/${this.etapa.id}/lotes/conceptos?lote_etapa_id=${this.currentLote.id}&lote_id=${this.currentLote.lote_manzana_lotificacion_id}`)
                .then(response => {
                    this.conceptosGet = response.data.conceptos;
                    var own_conceptos = response.data.own_conceptos;
                    var own_rubros = response.data.own_rubros;

                    this.conceptosGet.map(item => {
                        if(own_conceptos.length) {
                            item.selected = (!own_conceptos.some(concepto => concepto.id == item.concepto_id)) ? false : true;
                        }
                        item.rubros.map(rubro => {
                            rubro.selected = (!own_rubros.some(f => f.id == rubro.id) ? false : true)
                        })
                    });
                })
                .catch(error => errorHandling(error))
                .finally(() => this.vLoadingConceptos = false);
            },
            storeConceptosLotes: function() {
                this.vBtnSave.visible = true;
                axios.post(`/etapas/${this.etapa.id}/lotes/conceptos`, {
                    conceptos: this.conceptosGet.filter(item => item.selected == true).map(i => i.concepto.id),
                    rubros: this.conceptosGet.filter(item => item.selected == true).map(concepto => concepto.rubros).reduce((a,b) => a.concat(b)).filter(i => i.selected == true).map(i => {
                        return {rubro_id: i.id, etapa_id: this.etapa.id}
                    }),
                    lote_manzana_id: this.currentLote.id
                })
                .then(response => {
                    this.vSnackBar.text = response.data.message_text;
                    this.vSnackBar.visible = true;
                })
                .catch(error => errorHandling(error))
                .finally(() => this.vBtnSave.visible = false);
            },
            selectedAllConceptos: function() {
                if(this.selectAllConceptos == true) {
                    this.conceptosGet.map(item => {
                        item.selected = true;
                        item.concepto.rubros.map(rubro => rubro.selected = true)
                    });
                }
                else {
                    this.conceptosGet.map(item => {
                        item.selected = false;
                        item.concepto.rubros.map(rubro => rubro.selected = false)
                    });
                }
            },
            selectedConcepto: function(indexConcepto) {
                this.conceptosGet[indexConcepto].rubros.map((item) => item.selected = this.conceptosGet[indexConcepto].selected);
            },
            selectedAllRubros: function(indexConcepto) {
                this.conceptosGet[indexConcepto].rubros.map((item) => item.selected = this.conceptosGet[indexConcepto].rubrosSelected);
            },
            deleteLoteEtapa: function(loteEtapaId) {
                swal({
                    title: "¿Estás seguro?",
                    text: "Una vez eliminado no se podrá recuperar",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true
                }).then(result => {
                    if (result) {
                        axios.delete(`/etapas/lotes_manzana/${loteEtapaId}`)
                        .then(response => {
                            this.getLotesToManzana();
                            this.vSnackBar.text = response.data.message_text;
                            this.vSnackBar.loading = true;
                        })
                        .catch(error => errorHandling(error));
                    }
                })
            }
        },
        created() {
            this.getLotesToManzana();
        }
  }
</script>
<style scoped>
    .conceptos-form {
        border-left: solid 1px #18b822;
    }
</style>