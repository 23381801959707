<template>
  <div>
    <v-dialog v-model="localShow" max-width="850px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Crear requsición</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md6>
              <v-text-field v-model="requisiciones.nombre_requisicion" required label="Nombre de la orden"></v-text-field>
            </v-flex>
            <v-flex md6>
              <v-select v-model="requisiciones.solicitante" :items="solicitante" required label="Seleccione solicitante"></v-select>
            </v-flex>
            <v-flex md12>
              <v-textarea 
                :maxlength="250" 
                rows="3"
                counter="250"
                v-model="requisiciones.descripcion" 
                required 
                label="Descripcion"
                :rules="rules"
              ></v-textarea>
            </v-flex>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-container grid-list-md v-for="(material, index) in materiales" :key="index">
                <v-layout wrap>
                  <v-flex md3>
                    <v-autocomplete
                      :items="categories"
                      :rules="rules"
                      @change="getCurrentMateriales(material.categoria_id, index)"
                      v-model="material.categoria_id"
                      item-value="id"
                      item-text="nombre"
                      label="Categoria"
                      single-line
                      style
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex md3>
                    <v-autocomplete
                      :items="material.materiales"
                      v-model="material.material"
                      :rules="rules"
                      return-object
                      item-text="nombre"
                      label="Material"
                      single-line
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex md3>
                    <v-text-field
                      :value="material.material.unidad"
                      readonly
                      required
                      label="Unidades"
                      disabled
                      :rules="rules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md3>
                    <v-text-field
                      v-model="material.cantidad"
                      type="number"
                      min="0"
                      required
                      :rules="rules"
                      label="Cantidad"
                      step=".01"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md3>
                    <v-text-field
                      prepend-icon="attach_money"
                      :value="material.material.precio"
                      readonly
                      disabled
                      required
                      label="Precio unitario"
                      :rules="rules" 
                    ></v-text-field>
                  </v-flex>
                  <v-flex md3>
                    <v-text-field
                      prepend-icon="attach_money"
                      :value="parseInt(material.material.precio) * parseInt(material.cantidad)"
                      readonly
                      disabled
                      label="Precio total"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md3>
                    <v-btn
                      v-if="index != 0"
                      @click="materiales.splice(index, 1)"
                      fab
                      flat
                      dark
                      small
                      color="verdeFayal"
                    >
                      <v-icon dark>remove</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
                 <v-divider ></v-divider>
              </v-container>
            </v-form>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-switch color="verdeFayal" label="Seguir registrando" v-model="keepStoring"></v-switch>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="storeRequisicion" flat :loading="vBtnSave.loading"> Guardar </v-btn>
        <v-btn dark color="verdeFayal" @click="addMateriales">
          <v-icon>add</v-icon>
        </v-btn>
      </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data(){
    return{
      categories: [],
      solicitante:['Almacén'],
      estado: [],
      proveedores: [],
      localShow: false,
      // localItemId: null,
      keepStoring: true,
      requisiciones:{
        nombre_de_orden: null,
        solicitante: null,
        solicitante: null,
        estado: null,
        proveedor: null,
      },
      materiales: [
        {
          categoria_id: null,
          material: {
            precio: 0
          },
          cantidad: 0,
          proveedor_id: null,
          materiales: [],
        }
      ],
      vBtnSave: {
        loading: false
      },
      valid: false,
      rules: [v => !!v || "Este campo es requerido"],
    }
  },
  props:{
    show: Boolean,
    // itemId: Number,
    modelType: String
  },
  methods:{
    getProveedores: function() {
      axios
      .get(laravelRoute("proveedores.index"))
      .then(response => {
        this.proveedores = response.data;
      })
      .catch(error => errorHandling(error));
    },
    getCategories: function() {
      axios
      .get(laravelRoute("categorias.index.without_paginate"))
      .then(response => {
        this.categories = response.data;
      })
      .catch(error => errorHandling(error));
    },
    getCurrentMateriales: function(category_id, index) {
      var categories = [];
      this.categories.map(function(category) {
        categories.push(category);
      });
      this.materiales[index].materiales = categories.find(function(category) {
        return category.id == category_id;
      }).materiales;
    },
    addMateriales: function(){
      this.materiales.push({
        categoria_id: null,
        material: {
            precio: 0
        },
        proveedor_id: null,
        cantidad: 0,
        materiales: []
      });
    },
    storeRequisicion: function(){
      if (this.$refs.form.validate()) {
        this.vBtnSave.loading = true;
        axios.post(laravelRoute('requisiciones.store'), {
          nombre_requisicion: this.requisiciones.nombre_requisicion,
          solicitante: this.requisiciones.solicitante,
          descripcion: this.requisiciones.descripcion,
          fecha: this.requisiciones.solicitante,
          fecha_de_llegada: this.requisiciones.fecha_de_llegada,
          materiales: this.materiales,
          // requisicion_id: this.itemId,
          model_type: this.modelType,
          arrayMateriales: this.materiales.map(element => element.material.id ? element.material.id : element.material)
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.localShow = (this.keepStoring) ? true : false
          this.requisiciones = {};
            this.materiales = [
            {
              categoria_id: null,
              material: {
                precio: 0
              },
              cantidad: 0,
              proveedor_id: null,
              materiales: [],
            }
          ]
        })
        .catch(error => {
            errorHandling(error)
          })
        .finally(()=>this.vBtnSave.loading = false);
      }
    },
    getStatus: function(){
    axios.get('/requisiciones/estados').then(response => {
        this.estado = response.data;
      });
    },
  },
  watch:{
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
  },
  created(){
    this.getCategories();
    this.getStatus();
    this.getProveedores();
  }
}
</script>