<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Nueva categoría</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-divider></v-divider>
            <v-flex md12>
              <v-text-field v-model="category.nombre" required label="Nombre"></v-text-field>
            </v-flex>
            <v-flex md12>
              <v-select :items="categories_types" v-model="category.tipo" label="Tipo de categoría"></v-select>
            </v-flex>
            <v-flex md12>
              <v-text-field v-model="category.descripcion" required label="Descripción"></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-switch color="verdeFayal" label="Seguir registrando" v-model="keepStoring"></v-switch>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="storeCategory" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    category: {},
    categories_types: ['Materiales de construccíon', 'Materiales de maquinaría'],
    localShow: false,
    keepStoring: true,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value)
    }
  },
  methods: {
    storeCategory: function () {
      this.vBtnSave.loading = true;
      axios.post(laravelRoute('categorias.store'), {
          nombre: this.category.nombre,
          descripcion: this.category.descripcion,
          tipo: this.category.tipo
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.vBtnSave.loading = false;
          this.category = {};
          this.localShow = (!this.keepStoring) ? false : true;
        })
        .catch(error => {
          this.vBtnSave.loading = false;
          errorHandling(error)
        });
    }
  }
}
</script>
