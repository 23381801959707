<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="550px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Entrega de lote {{ venta.lote.nombre }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md12>
              <v-text-field :disabled="(venta.vivienda != null) ? true : false" type="date" v-model="venta.fecha_de_entrega" required label="Fecha de entrega final"></v-text-field>
            </v-flex>
            <v-flex md12>
                <v-radio-group :disabled="(venta.vivienda != null) ? true : false" label="¿Cliente conforme?" v-model="venta.cliente_conforme" row color="verdeFayal">
                    <v-radio label="Si" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                </v-radio-group>
            </v-flex>
            <v-flex>
              <upload-file-field-refactor
                v-if="!venta.documento_de_entrega_url"
                placeholder="Documento de entrega"
                :url-name.sync="fileURL"
              />
              <v-btn
                v-else
                color="verdeFayal"
                flat
                outline
                :href="venta.documento_de_entrega_url" 
                target="_blank"
              >
                Descargar documento de entrega
                <v-icon right dark>cloud_download</v-icon>
              </v-btn>
            </v-flex>
            <v-flex md12>
                <v-textarea
                    v-if="venta.cliente_conforme == false"
                    name="input-7-1"
                    v-model="venta.observaciones"
                    label="Añadir comentarios"
                    :disabled="venta.documento_de_entrega_url"
                ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="(venta.vivienda != null) ? true : false" @click="updateDeliverLot" outline dark color="verdeFayal" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../../lib/utilities";
export default {
  data: () => ({
    venta: {
      lote: {},
      vivienda: null
    },
    showFilesModal: false,
    fileURL: '',
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    ventaId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    ventaId: function (value) {
      this.getVenta();
    },
  },
  methods: {
    getVenta: function() {
      axios.get(laravelRoute('ventas.show.refactor', this.ventaId))
      .then(response => {
        this.venta = response.data;
        this.venta.cliente_conforme = this.venta.cliente_conforme == 1 ? true : false;
      })
      .catch(error => errorHandling(error));
    },
    updateDeliverLot: function() {
      this.vBtnSave.loading = true;
      axios.patch(laravelRoute('ventas.entregar_lote', this.ventaId), {
        cliente_conforme: this.venta.cliente_conforme,
        fecha_de_entrega: this.venta.fecha_de_entrega,
        documento_de_entrega_url: this.$store.state.urlFileUploaded,
        observaciones: this.venta.observaciones
      })
      .then(response => {
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;
        this.getVenta();
      })
      .catch(error => errorHandling(error))
      .finally(() => this.vBtnSave.loading = false)
    }
  }
}
</script>
