var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Datos de tarjeta")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-subheader", [
                                _vm._v("Información personal")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Nombre completo",
                                  readonly: ""
                                },
                                model: {
                                  value: _vm.trabajador.nombre_completo,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trabajador,
                                      "nombre_completo",
                                      $$v
                                    )
                                  },
                                  expression: "trabajador.nombre_completo"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Grado de estudio",
                                  readonly: ""
                                },
                                model: {
                                  value: _vm.trabajador.grado_de_estudios,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trabajador,
                                      "grado_de_estudios",
                                      $$v
                                    )
                                  },
                                  expression: "trabajador.grado_de_estudios"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Estado civil", readonly: "" },
                                model: {
                                  value: _vm.trabajador.estado_civil,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trabajador,
                                      "estado_civil",
                                      $$v
                                    )
                                  },
                                  expression: "trabajador.estado_civil"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "RFC", readonly: "" },
                                model: {
                                  value: _vm.trabajador.rfc,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "rfc", $$v)
                                  },
                                  expression: "trabajador.rfc"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "CURP", readonly: "" },
                                model: {
                                  value: _vm.trabajador.curp,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "curp", $$v)
                                  },
                                  expression: "trabajador.curp"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Estado", readonly: "" },
                                model: {
                                  value: _vm.trabajador.estado,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "estado", $$v)
                                  },
                                  expression: "trabajador.estado"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Fecha de contratación",
                                  readonly: ""
                                },
                                model: {
                                  value: _vm.trabajador.fecha_de_contratacion,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trabajador,
                                      "fecha_de_contratacion",
                                      $$v
                                    )
                                  },
                                  expression: "trabajador.fecha_de_contratacion"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.trabajador.beneficiario
                        ? _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { md12: "" } },
                                [
                                  _c("v-subheader", [
                                    _vm._v("Información del beneficiario")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { md12: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Nombre completo",
                                      readonly: ""
                                    },
                                    model: {
                                      value:
                                        _vm.trabajador.beneficiario
                                          .nombre_completo,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.trabajador.beneficiario,
                                          "nombre_completo",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "trabajador.beneficiario.nombre_completo"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { md4: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Parentesco",
                                      readonly: ""
                                    },
                                    model: {
                                      value:
                                        _vm.trabajador.beneficiario.parentesco,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.trabajador.beneficiario,
                                          "parentesco",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "trabajador.beneficiario.parentesco"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { md4: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "RFC", readonly: "" },
                                    model: {
                                      value: _vm.trabajador.beneficiario.rfc,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.trabajador.beneficiario,
                                          "rfc",
                                          $$v
                                        )
                                      },
                                      expression: "trabajador.beneficiario.rfc"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { md4: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Fecha de nacimiento",
                                      readonly: ""
                                    },
                                    model: {
                                      value: _vm.trabajador.fecha_de_nacimiento,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.trabajador,
                                          "fecha_de_nacimiento",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "trabajador.fecha_de_nacimiento"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { md4: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { label: "Dirección", readonly: "" },
                                    model: {
                                      value:
                                        _vm.trabajador.beneficiario.direccion,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.trabajador.beneficiario,
                                          "direccion",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "trabajador.beneficiario.direccion"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }