<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="850px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Editar mantenimiento</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-select
                  color="verdeFayal"
                  :items="mantenimiento.maquinaria"
                  v-model="mantenimiento.maquinaria.id"
                  item-value="id"
                  item-text="nombre"
                  label="Seleccione máquina"
                  disabled
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  color="verdeFayal"
                  :items="mantenimiento.mantenimiento"
                  v-model="mantenimiento.mantenimiento.id"
                  item-value="id"
                  item-text="nombre"
                  label="Seleccione mantenimiento"
                  disabled
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  color="verdeFayal"
                  :items="estados"
                  v-model="mantenimiento.estado.information.id"
                  item-value="id"
                  item-text="estado"
                  label="Seleccione estados"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field color="#1ABA24" v-model="mantenimiento.fecha" label="Fecha de inicio de mantenimiento" type="date"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="verdeFayal" @click="updateMantenimiento" flat :loading="vBtnSave.loading"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data(){
    return{
      localShow: false,
      mantenimiento: {
        mantenimiento: [{
          nombre:''
        }],
        maquinaria: {
          nombre:''
        },
        estado:{
          information:{
            estado: ''
          }
        }
    },
      maquinariaslistProps: this.maquinariaslist,
      estados: [],
      vBtnSave: {
          loading: false
        },
        vSnackBar: {
          visible: false,
          text: null
        }
    }
  },
  props: {
    show: Boolean,
    mantenimientoId: Number,
    maquinariaslist: Array
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    mantenimientoId: function (value) {
      this.getMantenimiento();
      this.getEstados();
    },
    localShow: function (value) {
      this.$emit('update:show', value)
    }
  },
  methods: {
    getMantenimiento: function () {
    axios.get(laravelRoute('mantenimientos.show', this.mantenimientoId))
      .then(response => this.mantenimiento = response.data)
      .catch(error => errorHandling(error));
    },
    getEstados: function(){
      axios
      .get(laravelRoute('mantenimientos.estados'))
      .then(response => {
        this.estados = response.data;
      })
      .catch(error => errorHandling(error));
    },
    updateMantenimiento: function () {
      this.vBtnSave.loading = true;
      axios.patch('/mantenimientos/' + this.mantenimientoId, {
        estado: this.mantenimiento.estado.information.id,
        fecha: this.mantenimiento.fecha
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.vBtnSave.loading = false;
        this.localShow = false;
      })
      .catch(error => {
        this.vBtnSave.loading = false;
        errorHandling(error)
      });
    },
  }
}
</script>