var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.build
    ? _c(
        "section",
        {
          staticStyle: {
            position: "absolute",
            left: "15px",
            bottom: "10px",
            color: "#b8c7ce"
          }
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("p", [
            _vm._v("Build Number: "),
            _c("a", { attrs: { href: _vm.build.build_url } }, [
              _vm._v("#" + _vm._s(_vm.build.build_num))
            ])
          ]),
          _vm._v(" "),
          _c("p", [_vm._v("Made by: " + _vm._s(_vm.build.author_name))]),
          _vm._v(" "),
          _c("p", { attrs: { id: "hm-date" } }, [
            _vm._v(_vm._s(_vm.dateForHumans(_vm.build.author_date)))
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "margin-bottom": "6px" } }, [
      _c("strong", [_vm._v("TEST ENV")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }