<template>
  <v-card>
    <v-card-title class="fyl-headerTable">
      <v-flex>
        <h3>Propuestas de prototipos de vivienda</h3>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex>
        <v-text-field
          color="#1ABA24"
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-flex>
      <v-flex>
        <prototipo-vivienda-modal
          :viviendas="viviendas"
          :vivienda="vivienda"
          :dialog="dialog"
          @open="dialog = true"
          @close="cleanForm()"
        ></prototipo-vivienda-modal>
      </v-flex>
    </v-card-title>
    <v-data-table :headers="headers" :items="viviendasProps" :search="search" :pagination.sync="pagination">
      <template v-slot:items="viviendasProps">
        <td class="text-xs-left">{{ viviendasProps.item.nombre }}</td>
        <td class="text-xs-left">{{ viviendasProps.item.descripcion }}</td>
        <td class="text-xs-left">{{ viviendasProps.item.tipo }}</td>
        <td class="text-xs-left">{{ viviendasProps.item.fecha }}</td>
        <td class="text-xs-left">
          <v-layout>
            <v-tooltip bottom>
              <v-btn
                icon
                flat
                dark
                small
                slot="activator"
                color="info"
                @click="editvivienda(viviendasProps.item)"
              >
                <v-icon color="#1ABA24">edit</v-icon>
              </v-btn>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <v-btn
                icon
                flat
                dark
                small
                slot="activator"
                color="error"
                @click="sweetAlert(viviendasProps.item)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
              <span>Eliminar</span>
            </v-tooltip>
          </v-layout>
        </td>
      </template>
      <template v-slot:no-results>
        <v-alert
          :value="true"
          color="error"
          icon="warning"
        >Your search for "{{ search }}" found no results.</v-alert>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from "axios";
  import swal from "sweetalert";

  export default {
    props: ["viviendas"],
    data(){
      return{
        search: '',
        dialog: false,
        vivienda: {},
        viviendasProps: this.viviendas,
        headers:[
          { text: 'Nombre', value: 'nombre' },
          { text: 'Descripcion', value: 'descripcion' },
          { text: 'Estado', value: 'tipo' },
          { text: 'Fecha', value: 'fecha' },
          { text: 'Acciones', value: '', sortable: false }
        ],
        campoActualizado: { id: "", name: "" },
        pagination: {
          sortBy: 'id',
          descending: true
        }
      }
    },
    methods: {
      cleanForm() {
        this.vivienda = {};
        this.dialog = false;
      },
      sweetAlert: function(vivienda) {
        swal({
          title: "¿estás seguro?",
          text: "Una vez eliminado no se podrá recuperar",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(willDelete => {
          if (willDelete) {
            // this.removingRole = true;
            this.eliminar(vivienda.id);
            this.viviendasProps = this.viviendasProps.filter(function(ele) {
              return ele.id != vivienda.id;
            });
            swal("El Registro ha sido eliminado", {
              icon: "success"
            });
          } else {
            swal("El Registro no se eliminó");
          }
        });
      },
      eliminar: viviendaId => {
        var IDvivienda = viviendaId;
        axios
          .delete(route("prototipos_vivienda.destroy", IDvivienda))
          .then(response => {
            if (response.data.message_text) {
              alertify.success(response.data.message_text);
            }
          })
          .catch(error => errorHandling(error));
        // .finally(() => this.removingRole = false)
      },
      editvivienda: function(vivienda) {
        this.vivienda = vivienda;
        this.dialog = true;
      }
    }
  };
</script>

<style>
  .fyl-headerTable{
    background: #F9FBFC;
  }
</style>