var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Vendedores")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      (_vm.$root.can("new", _vm.permissions)
                      ? true
                      : false)
                        ? _c("vendedor-modal", {
                            attrs: {
                              vendedor: _vm.vendedor,
                              dialog: _vm.dialog
                            },
                            on: {
                              close: function($event) {
                                return _vm.cleanForm()
                              },
                              open: function($event) {
                                _vm.dialog = true
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      "disable-initial-sort": "",
                      items: _vm.vendedores.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", [
                              _vm._v(
                                _vm._s(props.item.nombre) +
                                  " " +
                                  _vm._s(props.item.apellido_paterno) +
                                  " " +
                                  _vm._s(props.item.apellido_materno)
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.telefono))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.email))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.created_at))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  props.item.inmobiliaria
                                    ? props.item.inmobiliaria.inmobiliaria
                                    : ""
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("v-switch", {
                                  staticClass: "fyl-vendedor-switch",
                                  attrs: {
                                    disabled: !_vm.$root.can(
                                      "status",
                                      _vm.permissions
                                    ),
                                    color: "verdeFayal"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.changeStatus(
                                        props.item.id,
                                        props.item.estado.information.estado
                                      )
                                    }
                                  },
                                  model: {
                                    value: props.item.estado.information.estado,
                                    callback: function($$v) {
                                      _vm.$set(
                                        props.item.estado.information,
                                        "estado",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "props.item.estado.information.estado"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "fyl-vendedor-td" },
                              [
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          icon: "",
                                          flat: "",
                                          dark: "",
                                          small: "",
                                          color: "info",
                                          disabled: !_vm.$root.can(
                                            "update",
                                            _vm.permissions
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.EditVendedor(
                                              props.item.id
                                            )
                                          }
                                        },
                                        slot: "activator"
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "verdeFayal" } },
                                          [_vm._v("edit")]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Editar")])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          disabled: !_vm.$root.can(
                                            "files",
                                            _vm.permissions
                                          ),
                                          flat: "",
                                          icon: "",
                                          color: "warning"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.elementId = props.item.id
                                            _vm.dialogFile = true
                                          }
                                        },
                                        slot: "activator"
                                      },
                                      [_c("v-icon", [_vm._v("folder")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Archivos")])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          icon: "",
                                          flat: "",
                                          dark: "",
                                          small: "",
                                          color: "error",
                                          disabled: !_vm.$root.can(
                                            "remove",
                                            _vm.permissions
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteVendedor(
                                              props.item.id
                                            )
                                          }
                                        },
                                        slot: "activator"
                                      },
                                      [_c("v-icon", [_vm._v("delete")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Eliminar")])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.vendedores
                        },
                        on: { "pagination-change-page": _vm.getVendedores }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("upload-files-modal", {
        attrs: {
          element_type: "App\\Vendedor",
          element_id: _vm.elementId,
          dialog: _vm.dialogFile
        },
        on: {
          close: function($event) {
            _vm.dialogFile = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }