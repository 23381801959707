<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="850px" persistent>
      <v-card>
        <v-toolbar color="white" tabs flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Editar concepto: {{ concepto.nombre }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
          <template v-slot:extension>
            <v-tabs
              v-model="model"
              centered
              color="white"
              slider-color="verdeFayal"
            >
              <v-tab href="#tab-concepto-datos">Datos del concepto</v-tab>
              <v-tab href="#tab-rubros">Rubros asociados</v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="model">
          <v-tab-item value="tab-concepto-datos">
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex md6>
                    <v-text-field
                      v-model="concepto.nombre"
                      type="text"
                      required
                      label="Nombre"
                    ></v-text-field>
                  </v-flex>

                  <v-flex md6>
                    <v-select
                      :items="['Metros lineales', 'Metros cuadrados', 'Metros cúbicos', 'Kilogramos', 'Litros', 'Unidades']"
                      v-model="concepto.unidad"
                      label="Unidad"
                      v-if="type == 'urbanizacion'"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-tab-item>

          <v-tab-item value="tab-rubros">
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex md12>
                    <v-text-field
                      label="Nombre del rubro"
                      v-model="rubro"
                      append-outer-icon="add"
                      @keyup.enter.native="addRubro"
                      hint="Da enter o click en el botón para añadir el rubro"
                    >
                      <template v-slot:append-outer>
                        <v-slide-x-reverse-transition mode="out-in">
                          <v-icon color="success" @click="addRubro">add</v-icon>
                        </v-slide-x-reverse-transition>
                      </template>
                    </v-text-field>
                  </v-flex>
                  <v-flex md12>
                    <v-data-table
                      :items="concepto.rubros"
                      hide-actions
                      flat
                    >
                      <template v-slot:headers="props">
                        <tr>
                          <th>Nombre</th>
                          <th></th>
                        </tr>
                      </template>
                      <template v-slot:items="props">
                        <tr>
                          <td>{{ props.item.nombre }}</td>
                          <td class="text-xs-right">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">       
                                <v-btn flat icon color="red" v-on="on" @click="deleteRubro(props.item.id)">
                                  <v-icon>delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Eliminar rubro</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            color="verdeFayal"
            @click="updateConcept"
            flat
            :loading="vBtnSave.loading"
          >Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { errorHandling, laravelRoute } from "../../lib/utilities";
export default {
  data: () => ({
    concepto: {
        rubros: []
    },
    model: 'tab-concepto-datos',
    nombre: null,
    rubros: [],
    localShow: false,
    rubro: null,
    vBtnSave: {
      concept: {
        loading: false
      },
      rubro: {
        loading: false
      },
    },
    keepStoring: true,
    vSnackBar: {
      visible: false,
      text: null
    },
    searchConfig: {
      url: "/rubros",
      noDataText: "No se encontraron resultados.",
      itemText: "nombre",
      itemValue: "id",
      descriptionLimit: 60,
      placeholder: "Buscar Rubro",
      prependIcon: "mdi-database-search",
      chips: true,
      multiple: true,
      deleteChips: true,
      disabled: false,
      useHeaders: true,
    },
    itemsToShare: [],
    newConcept: {
      nombre: '',
      rubros: [],
    },
    createRubro: false,
    newRubro: {
      nombre: '',
    },
  }),
  props: {
    show: Boolean,
    permissions: Object, //rubro_permissions
    type: String,
    conceptId: {
      type: Number,
      default: null
    },
  },
  watch: {
    show: function(value) {
      this.localShow = value;
    },
    localShow: function(value) {
      if(!this.conceptId){
        this.itemsToShare = [];
        this.newConcept = {
          nombre: '',
          rubros: [],
        };
      }
      this.$emit("update:show", value);
    },
    conceptId: function() {
        this.getConcepto();
    }
  },
  methods: {
    getConcepto: function() {
        axios.get(`/conceptos/${this.conceptId}`)
        .then(response => {
            this.concepto = response.data;
        })
        .catch(error => {
            errorHandling(error);
        });
    },
    updateConcept: function(){
      this.vBtnSave.loading = true;
      axios.patch(`/conceptos/${this.conceptId}`, {
        nombre: this.concepto.nombre,
        unidad: this.concepto.unidad,
        type: this.type
      })
      .then(response => {
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;
        this.localShow = false;
      })
      .catch(error => {
        errorHandling(error);
      })
      .finally(() => this.vBtnSave.loading = false );
    }, 
    addRubro: function() {
      axios.post('/rubros',{
        nombre: this.rubro,
        type: this.type,
        concepto_id: this.conceptId
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.rubro = null;
        this.getConcepto();
      })
      .catch(error => {
        errorHandling(error);
      })
    },
    deleteRubro: function(rubroId) {
        swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('rubros.destroy', rubroId))
            .then(response => {
                this.vSnackBar.text = response.data.message_text;
                this.vSnackBar.visible = true;
                this.getConcepto();
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      })
    }
  }
};
</script>
