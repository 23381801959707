<template>
    <div>
        <v-card flat>
            <v-toolbar color="verdeFayal" dark extended flat>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn flat icon onclick="history.back()">
                            <v-icon>keyboard_arrow_left</v-icon>
                        </v-btn>
                    </template>
                    <span>Regresar</span>
                </v-tooltip>
            </v-toolbar>

            <v-layout row pb-2>
                <v-flex xs10 offset-xs1>
                    <v-card class="card--flex-toolbar">
                        <v-toolbar card prominent>
                            <v-spacer></v-spacer>
                            <v-toolbar-title class="body-2 grey--text">Lote en promoción {{ current_lote.nombre }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-card-text>
                                <v-container grid-list-md text-xs-left>
                                    <v-layout row wrap>
                                        <v-flex xs12 md3>
                                            <v-card dark color='#75d57b'>
                                                <v-flex xs12>
                                                    <v-layout>
                                                        <v-flex xs4>
                                                            <v-layout  align-center justify-center row fill-height>
                                                                <v-img
                                                                    src="/fonts/local/total.svg"
                                                                    height="50px"
                                                                    contain
                                                                ></v-img>
                                                            </v-layout>
                                                        </v-flex>
                                                        <v-flex xs8>
                                                            <v-card-title primary-title>
                                                                <div>
                                                                    <div class="headline total-title">$ {{ventas.valor_de_saldo | currency}}</div>
                                                                    <div>Valor Comercial</div>
                                                                </div>
                                                            </v-card-title>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-card>  
                                        </v-flex>
                                        <v-flex xs12 md3>
                                            <v-card dark color='#75d57b'>
                                                <v-flex xs12>
                                                    <v-layout>
                                                        <v-flex xs4>
                                                            <v-layout  align-center justify-center row fill-height>
                                                                <v-img
                                                                    src="/fonts/local/house-price.png"
                                                                    height="50px"
                                                                    contain
                                                                ></v-img>
                                                            </v-layout>
                                                        </v-flex>
                                                        <v-flex xs8>
                                                            <v-card-title primary-title>
                                                                <div>
                                                                    <div class="headline total-title">$ {{ventas.valor_de_avaluo | currency}}</div>
                                                                    <div>Valor del avalúo</div>
                                                                </div>
                                                            </v-card-title>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-card>  
                                        </v-flex>
                                        <v-flex xs12 md3>
                                            <v-card dark color='#75d57b'>
                                                <v-flex>
                                                    <v-layout>
                                                        <v-flex xs4>
                                                            <v-layout  align-center justify-center row fill-height>
                                                                <v-img
                                                                    src="/fonts/local/payment.png"
                                                                    height="50px"
                                                                    contain
                                                                ></v-img>
                                                                
                                                            </v-layout>
                                                        </v-flex>
                                                        
                                                        <v-flex xs8>
                                                            <v-card-title primary-title>
                                                                <div>
                                                                    <div class="headline total-title">$ {{ ventas.pagos_asociados | currency}}</div>
                                                                    <div>Pagos asociados</div>
                                                                </div>
                                                            </v-card-title>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-card>   
                                        </v-flex>
                                        <v-flex xs12 md3>
                                            <v-card dark color='#75d57b'>
                                                <v-flex>
                                                    <v-layout>
                                                        <v-flex xs4>
                                                            <v-layout  align-center justify-center row fill-height>
                                                                <v-img
                                                                    src="/fonts/local/balance.png"
                                                                    height="50px"
                                                                    contain
                                                                ></v-img>
                                                            </v-layout>
                                                        </v-flex>
                                                        <v-flex xs8>
                                                            <v-card-title primary-title>
                                                                <div>
                                                                    <div class="headline total-title">$ {{ ventas.saldo_a_pagar | currency }}</div>
                                                                    <div>Saldo</div>
                                                                </div>
                                                            </v-card-title>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-card>  
                                        </v-flex>
                                    </v-layout>
                                    <v-divider></v-divider>
                                    <v-layout row wrap>
                                        <v-flex>
                                            <v-tabs v-model="vTabs" fixed-tabs centered color="white" slider-color="#1aba24">
                                                <v-tab href="#tab-alta_pagos">Venta</v-tab>
                                                <v-tab href="#tab-clientes">Clientes</v-tab>
                                                <v-tab v-if="ventas.valor_de_saldo" href="#tab-pagos">Pagos</v-tab>
                                            </v-tabs>
                                            <v-tabs-items v-model="vTabs">
                                                <v-tab-item value="tab-alta_pagos">
                                                    <v-card flat>
                                                        <v-container>
                                                            <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <v-btn :disabled="!ventas.valor_de_saldo" color="verdeFayal" dark @click="openAddAvaluo">Añadir valor de avalúo</v-btn>
                                                            </v-card-actions>
                                                            <v-layout wrap>
                                                                <v-flex xs12 md6>
                                                                    <v-select 
                                                                        v-if="!ventas.valor_de_saldo"
                                                                        v-model="ventas.costo"
                                                                        label="Valor comercial"
                                                                        item-value="precio" 
                                                                        :items="precios" 
                                                                        item-text="tipo"
                                                                        required 
                                                                    >
                                                                        <template slot='selection' slot-scope='{ item }'>
                                                                            ${{ item.precio | currency}} ({{ item.tipo }})
                                                                        </template>
                                                                        <template slot='item' slot-scope='{ item }'>
                                                                            ${{ item.precio | currency}} ({{ item.tipo }})
                                                                        </template>
                                                                    </v-select>
                                                                    <input-currency-mask v-else readonly disabled label="Valor comercial" v-model="ventas.costo"/>
                                                                </v-flex>
                                                                <v-flex md6 xs12>
                                                                    <v-text-field :disabled="ventas.valor_de_saldo > 0" type="date" label="Fecha máxima a pagar" v-model="ventas.fecha_maxima_a_pagar"></v-text-field>
                                                                </v-flex>
                                                            </v-layout>
                                                            <v-layout row wrap v-if="!ventas.valor_de_saldo">
                                                                <v-flex md6>
                                                                    <searcher
                                                                        :configuration="searchMethodPayment"
                                                                        :itemSelected.sync="ventas.metodo_de_pago"
                                                                    ></searcher>
                                                                </v-flex>
                                                                <v-flex md6>
                                                                    <v-switch color="verdeFayal" hint="Tipo de venta" :persistent-hint="true" :label='lote.tipo_venta ? "Externo" : "Fayal" ' v-model="lote.tipo_venta"></v-switch>
                                                                </v-flex>
                                                                <v-flex m12 v-if="lote.tipo_venta == true">
                                                                    <v-layout wrap>
                                                                        <span class="body-2 black--text">Seleccionar Vendedor</span> <v-divider inset></v-divider>
                                                                        <v-flex md12>
                                                                            <v-autocomplete 
                                                                                v-model="vAutocompleteSalesman.select" 
                                                                                :items="vAutocompleteSalesman.salesman" 
                                                                                :loading="vAutocompleteSalesman.isLoading" 
                                                                                v-bind:search-input.sync="vAutocompleteSalesman.search" 
                                                                                label="Buscar vendedor" 
                                                                                item-text="nombre_completo"
                                                                                item-value="id" 
                                                                                browser-autocomplete="off" 
                                                                                hint="Ingresa por lo menos 3 caracteres" 
                                                                                return-object
                                                                            ></v-autocomplete>
                                                                        </v-flex>
                                                                        <template v-if="vAutocompleteSalesman.select">
                                                                            <v-flex md6>
                                                                                <v-text-field v-model="vAutocompleteSalesman.select.nombre_completo" required label="Nombre" readonly></v-text-field>                   
                                                                            </v-flex>
                                                                            <v-flex md6>
                                                                                <v-text-field v-model="vAutocompleteSalesman.select.telefono" required label="Teléfono" readonly></v-text-field>                   
                                                                            </v-flex>
                                                                            <v-flex md6>
                                                                                <v-text-field v-model="vAutocompleteSalesman.select.email" required label="Correo electrónico" readonly></v-text-field>                   
                                                                            </v-flex>
                                                                            <v-flex md6 v-if="vAutocompleteSalesman.select.inmobiliaria">
                                                                                <v-text-field v-model="vAutocompleteSalesman.select.inmobiliaria.inmobiliaria" required label="Inmobiliaria" readonly></v-text-field>                   
                                                                            </v-flex>
                                                                        </template>
                                                                    </v-layout>
                                                                </v-flex>
                                                            </v-layout>

                                                            <v-layout v-else>
                                                                <v-flex xs6>
                                                                    <v-text-field disabled label="Método de compra" v-model="ventas.metodo_de_pago.nombre"></v-text-field>
                                                                </v-flex>
                                                                <v-flex xs6>
                                                                    <v-text-field disabled label="Tipo de venta" :value="ventas.vendedor_id ? 'Externa' : 'Fayal'" />
                                                                </v-flex>
                                                                <v-flex v-if="ventas.vendedor_id" xs6>
                                                                    <v-text-field disabled label="Nombre completo del vendedor" :value="ventas.vendedor.nombre_completo" />
                                                                </v-flex>
                                                            </v-layout>
                                                        </v-container>
                                                        <v-divider></v-divider>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn 
                                                                outline 
                                                                dark 
                                                                color="verdeFayal" 
                                                                flat 
                                                                @click="updateSale()" 
                                                                :loading="vBtnSave.loading"
                                                            > 
                                                                Guardar 
                                                            </v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-tab-item>
                                                <v-tab-item value="tab-clientes">
                                                    <v-card flat>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="verdeFayal" dark @click="addCustomerToSale">Añadir cliente</v-btn>
                                                        </v-card-actions>
                                                        <v-container>
                                                            <v-data-table
                                                                :items="vAutocompleteCustomer.customers"
                                                                :headers="headers_pagos"
                                                                disable-initial-sort
                                                                hide-actions
                                                            >
                                                                <template v-slot:items="props">
                                                                    <td>{{ props.item.cliente.nombre_completo }}</td>
                                                                    <td>{{ paymentDate(props.item.cliente.fecha_aprobacion)}}</td>
                                                                    <td>{{ props.item.cliente.telefono }}</td>
                                                                    <td>{{ props.item.cliente.email }}</td>
                                                                    <td>
                                                                        <v-layout>
                                                                            <v-tooltip bottom>
                                                                                <v-btn
                                                                                    flat
                                                                                    icon
                                                                                    small
                                                                                    slot="activator"
                                                                                    color="warning"
                                                                                    @click="openFilesModal(props.item.id)"
                                                                                >
                                                                                    <v-icon>folder</v-icon>
                                                                                </v-btn>
                                                                                <span>Archivos</span>
                                                                            </v-tooltip>
                                                                        </v-layout>
                                                                    </td>
                                                                </template>
                                                            </v-data-table>
                                                        </v-container>
                                                    </v-card>
                                                </v-tab-item>
                                                <v-tab-item v-if="ventas" value="tab-pagos">
                                                    <v-card flat>
                                                        <v-container>
                                                            <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <v-btn flat color="verdeFayal" outline @click="openRefundPagos">Generar devolución</v-btn>
                                                                <v-btn color="verdeFayal" dark @click="openAddPagos">Añadir pagos</v-btn>
                                                            </v-card-actions>
                                                            
                                                            <v-data-table
                                                                :items="pagos.data"
                                                                disable-initial-sort
                                                                hide-actions
                                                            >
                                                                <template v-slot:headers="props">
                                                                    <tr>
                                                                        <th>Concepto</th>
                                                                        <th>Descripción</th>
                                                                        <th>Monto</th>
                                                                        <th>Fecha de pago</th>
                                                                        <th></th>
                                                                        <th></th>
                                                                    </tr>
                                                                </template>
                                                                <template v-slot:items="props">
                                                                    <tr>
                                                                        <td>{{ props.item.concepto }}</td>
                                                                        <td class="td-ellipsis">{{ props.item.descripcion }}</td>
                                                                        <td>$ {{ props.item.monto | currency }}</td>
                                                                        <td>{{ paymentDate(props.item.fecha_de_pago) }}</td>
                                                                        <td>
                                                                            <span v-if="props.item.monto < 0" class="badge badge-primary badge-lotes">Devolución</span>
                                                                        </td>
                                                                        <td>
                                                                            <v-tooltip bottom>
                                                                                <v-btn icon flat dark small slot="activator" color="verdeFayal" @click="editPayment(props.item.id)">
                                                                                    <v-icon>edit</v-icon>
                                                                                </v-btn>
                                                                                <span>Editar</span>
                                                                            </v-tooltip>
                                                                            <v-tooltip bottom>
                                                                                <v-btn icon flat dark small slot="activator" color="error" @click="deletePayment(props.item.id)">
                                                                                    <v-icon>delete</v-icon>
                                                                                </v-btn>
                                                                                <span>Eliminar</span>
                                                                            </v-tooltip>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </v-data-table>
                                                            <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <pagination :limit="10" :data="pagos" @pagination-change-page="getPagos"></pagination>
                                                            </v-card-actions>
                                                        </v-container>
                                                    </v-card>
                                                </v-tab-item>
                                            </v-tabs-items>
                                        </v-flex>
                                    </v-layout> 
                                </v-container> 
                            </v-card-text>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-card>
        <!-- Modal -->
        <add-customers-to-lote-promocion-modal :show.sync="showCustomersModal" :lote-id="currentLoteId"/>
        <add-pagos-to-lote-modal :show.sync="showAddPagos" :venta-id="ventas.id"/>
        <edit-pago-to-lote-modal :show.sync="showEditPaymentModal" :pago-id="currentPaymentId"/>
        <refund-pagos-to-lote-modal :show.sync="showRefundPaymentModal" :venta-id="ventas.id"/>
        <add-avaluo-to-venta-modal :show.sync="showAddAvaluoModal" :venta-id="ventas.id"/>
        <add-customers-to-sale-modal :show.sync="showAddCustomerToSaleModal" :venta-id="ventas.id"/>
        <archivos-lotes-modal
            @close="dialogFile = false"
            element_type="App\LoteManzanaLotificacion"
            :element_id="elementId"
            :dialog="dialogFile"
            :lotes_files="lotesFiles ? lotesFiles : []"
        ></archivos-lotes-modal>
        <client-files-modal
            element_type="App\Cliente"
            :element_id="currentClienteId"
            :permissions="[]"
            :show.sync="dialogFile"
            :personal_files="[]"
        ></client-files-modal>

        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';
  import moment from "moment";

  export default {
    data() {
        return {
            headers_pagos: [
                { text: 'Cliente',align: 'left', sortable: false, value: 'cliente'},
                { text: 'Fecha de aprobación', value: 'fecha', sortable: false },
                { text: 'Teléfono', value: 'telefono', sortable: false },
                { text: 'Correo', value: 'correo', sortable: false },
                { text: 'Acciones', value: 'acciones', sortable: false }
            ],
            ventas: {
                costo: null,
            },
            pagos: {
                data: []
            },
            metodo: null,
            currentPaymentId: null,
            showEditPaymentModal: false,
            searchMethodPayment: {
                url: "/metodos_de_pago",
                paginatedContainer: 'data',
                noDataText: "No se encontraron resultados.",
                label: "Método de pago",
                itemText: "nombre",
                itemValue: "id",
                descriptionLimit: 60,
                disabled: false,
                useHeaders: true,
            },
            vAutocompleteCustomer: {
                isLoading: false,
                customers: [],
                search: null,
                select: {},
            },
            vAutocompleteSalesman: {
                isLoading: false,
                salesman: [],
                search: null,
                select: {},
            },
            vTabs: 'tab-alta_pagos',
            dialogFile: false,
            lotesFiles: "",
            dialog: false,
            showAddPagos: false,
            elementId: "",
            current_lote:{},
            precios:[],
            lote: {},
            expandManzana: false,
            showCustomersModal: false,
            showRefundPaymentModal: false,
            showAddAvaluoModal: false,
            showAddCustomerToSaleModal: false,
            currentLoteId: null,
            currentClienteId: null,
            vSnackBar: {
                visible: false,
                text: null
            },
            vBtnSave: {
                loading: false
            },
            cliente: {},
        }
    },
    props: {
        lote_id: Number
    },
    watch: {
        lote_id: function() {
            this.getInfo();
        },
        searchCustomers(input) {
            this.debouncedGetCustomer()
        },
        searchSalesman(input) {
            this.debouncedGetSalesman()
        },
        showAddCustomerToSaleModal() {
            this.getCustomers();
        },
        vTabs: function(value) {
            if(value == 'tab-pagos') {
                this.getPagos();
            }
            else {
                this.getInfo();
            }
        },
        showAddPagos: function(value) {
            this.getPagos();
            this.getInfo();
        },
        showEditPaymentModal: function(value) {
            this.getInfo();
            this.getPagos();
        },
        showAddAvaluoModal: function(value) {
            this.getInfo();
            this.getPagos();
        },
        showRefundPaymentModal: function() {
            this.getInfo();
            this.getPagos();
        }
    },
    methods: {
        getCurrentLote: function(page = 1){
            axios.get(`/promociones/${this.lote_id}/pagos`)
            .then(response => this.current_lote = response.data)
            .catch(error => errorHandling(error));
        },
        getLote: function() {
            axios.get(`/lotes/${this.lote_id}/precios`)
            .then(response => this.precios = response.data)
            .catch(error => errorHandling(error))
        },
        getInfo: function() {
            axios.get('/ventas/'+this.lote_id)
            .then(response => {
                this.ventas = response.data;
                this.metodo = response.data.metodo_de_pago;
                this.ventas.costo = response.data.valor_de_saldo;
                this.vAutocompleteCustomer.select = (response.data.cliente) ? response.data.cliente : {};
                this.isThereAnSalesman = (response.data.vendedor) ? true: false;
                this.vAutocompleteSalesman.select = (response.data.vendedor) ? response.data.vendedor : {};
            })
            .catch(error => errorHandling(error));
        },
        getCustomers: function() {      
            return axios.get(`/ventas/clientes?lote_id=${this.lote_id}` )
            .then(response => {
                this.vAutocompleteCustomer.customers = response.data.data ? response.data.data : response.data
                this.vAutocompleteCustomer.isLoading = false
            })
            .catch(error => errorHandling(error))
        },
        getSalesman: function() {
            if (!this.vAutocompleteSalesman.search || this.vAutocompleteSalesman.search.length < 3) return
            this.vAutocompleteSalesman.isLoading = true
            let queryParam = {
                query: this.vAutocompleteSalesman.search
            }
            return axios.get(laravelRoute('vendedores.activos', queryParam))
            .then(response => {
                this.vAutocompleteSalesman.salesman = response.data.data ? response.data.data : response.data
                this.vAutocompleteSalesman.isLoading = false
            })
            .catch(error => errorHandling(error))
        },
        saveAll: function(){
            this.storeSale(),
            this.updateSale()
        },
        updateSale: function() {
            this.vBtnSave.loading = true;
            axios.patch('/ventas/pago/'+this.ventas.id, {
                lote_id: this.lote_id,
                vendedor_id: this.vAutocompleteSalesman.select.id,
                valor_de_avaluo: this.ventas.valor_de_avaluo,
                valor_de_saldo: this.ventas.costo,
                fecha_maxima_a_pagar: this.ventas.fecha_maxima_a_pagar,
                tipo_venta: this.lote.tipo_venta,
                metodo_de_pago: this.ventas.metodo_de_pago
            })
            .then(response => {
                this.vSnackBar.visible = true;
                this.vSnackBar.text = response.data.message_text;
                this.getInfo();
            })
            .catch(error => {
                errorHandling(error)
            })
            .finally(()=>this.vBtnSave.loading = false);
        },
        getPagos: function() {
            axios.get(`/ventas/${this.ventas.id}/pagos` )
            .then(response => this.pagos = response.data)
            .catch(error => errorHandling(error));
        },
        openAddPagos: function() {
            this.showAddPagos = true;
        },
        paymentDate: function(date) {
            var localLocale = moment(date);
            moment.locale('es');
            localLocale.locale(false)
            return localLocale.format('LLLL')
        },
        deletePayment: function(id) {
            swal({
                title: "¿Estás seguro?",
                text: "Una vez eliminado no se podrá recuperar",
                icon: "warning",
                buttons: true,
                dangerMode: true
            }).then(result => {
                if (result) {
                    axios.delete(laravelRoute('pagos_lote.destroy', id))
                    .then(response => {
                        this.getInfo();
                        this.getPagos();
                        this.vSnackBar.text = response.data.message_text;
                        this.vSnackBar.visible = true;
                    })
                    .catch(error => errorHandling(error));
                }
            })
        },
        editPayment: function(id) {
            this.currentPaymentId = id;
            this.showEditPaymentModal = true;
        },
        openRefundPagos: function() {
            this.showRefundPaymentModal = true;
        },
        openAddAvaluo: function()
        {
            this.showAddAvaluoModal = true;
        },
        addCustomerToSale: function() {
            this.showAddCustomerToSaleModal = true;
        },
        openFilesModal: function(clienteId) {
            this.currentClienteId = clienteId;
            this.dialogFile = true;
        }
    },
    computed: {
        searchCustomers: function () {
            return this.vAutocompleteCustomer.search
        },
        searchSalesman: function () {
            return this.vAutocompleteSalesman.search
        }
    },
    mounted() {
        this.getCurrentLote();
        this.getLote();
        this.getInfo();
        this.getCustomers();
        this.debouncedGetSalesman = _.debounce(() => this.getSalesman(), 500);
    }
  }
</script>