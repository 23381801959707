<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" width="450" persistent>
      <v-card>
        <v-toolbar color="white" flat>
            <v-spacer></v-spacer>
            <v-toolbar-title class="primary-title">Editar pago</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card-text>
            <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex md12>
                        <v-text-field v-model="pago.concepto" required label="Concepto" />
                    </v-flex>
                    <v-flex md12>
                        <input-currency-mask v-model="pago.monto" label="Monto"/>
                    </v-flex>
                    <v-flex md12>
                        <v-text-field type="date" :min="0" v-model="pago.fecha_de_pago" required label="Fecha de pago" />
                    </v-flex>
                    <v-flex md12>
                        <v-textarea
                            label="Descripción"
                            v-model="pago.descripcion"
                        ></v-textarea>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="editPago" dark color="verdeFayal" outline flat :loading="vBtnSave.loading"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
        {{ vSnackBar.text }}
        <v-btn color="pink" flat @click="vSnackBar.visible = false">
            <v-icon>close</v-icon>
        </v-btn>
    </v-snackbar>
  </div>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../../lib/utilities";
  import moment from "moment";

  export default {
    mounted() {
    },
    data() {
      return {
        pago: {
            concepto: null,
            monto: 0,
            descripcion: null,
            fecha_de_pago: null,
        },
        localShow: false,
        vBtnSave: {
            loading: false
        },
        vSnackBar: {
            visible: false,
            text: null
        }
      }
    },
    props: {
      show : Boolean,
      pagoId: Number
    },
    watch: {
      show: function (value) {
        this.localShow = value;
      },
      localShow: function (value) {
        this.$emit('update:show', value)
      },
      pagoId: function(value) {
        this.getPago();
      }
    },
    methods: {
        getPago: function() {
            axios.get(laravelRoute('pagos_lote.show', this.pagoId))
            .then(response => {
                this.pago = response.data
                this.pago.fecha_de_pago = moment(response.data.fecha_de_pago).format('YYYY-MM-DD')
            })
            .catch(error => errorHandling(error))
        },
        editPago: function() {
            this.vBtnSave.loading = true;
            axios.patch(laravelRoute('pagos_lote.update', this.pagoId), {
                concepto: this.pago.concepto,
                monto: this.pago.monto,
                fecha_de_pago: this.pago.fecha_de_pago,
                descripcion: this.pago.descripcion,
                venta_id: this.ventaId
            })
            .then(response => {
                this.vSnackBar.text = response.data.message_text;
                this.vSnackBar.visible = true;
            })
            .catch(error => errorHandling(error))
            .finally(() => this.vBtnSave.loading = false);
        }
    }
  }
</script>
