var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        href: "/nominas/" + _vm.area.nomina_id
                      }
                    },
                    [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-breadcrumbs", {
                    staticStyle: { margin: "1%" },
                    attrs: { items: _vm.breadcrums, divider: ">" }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(_vm._s(_vm.area.area.nombre))
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.openAddWorkersModal()
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("person_add")
                          ]),
                          _vm._v(" Agregar personal \n          ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.loadingData
                    ? _c("v-progress-linear", {
                        attrs: { color: "verdeFayal", indeterminate: true }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-data-table", {
                    attrs: {
                      "disable-initial-sort": "",
                      items: _vm.trabajadores.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "headers",
                        fn: function(props) {
                          return [
                            _c("tr", [
                              _c("th", [_vm._v("Nombre del trabajador")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Ocupación")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Puesto")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Deducciones")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Prima vacacional")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Salario")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Pago")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Acciones")])
                            ])
                          ]
                        }
                      },
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(
                                _vm._s(
                                  "" + props.item.trabajador.nombre_completo
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-xs-left" }, [
                              _vm._v(
                                _vm._s(
                                  props.item.trabajador.ocupacion
                                    ? props.item.trabajador.ocupacion.nombre
                                    : "Aún sin especificar"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(props.item.trabajador.puesto))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "$ " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      props.item.trabajador.total_deducciones
                                    )
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "$ " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      props.item.trabajador.puesto === "Raya"
                                        ? "$ " + props.item.prima_vacacional
                                        : "No aplica"
                                    )
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "$ " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      props.item.trabajador.puesto === "Raya"
                                        ? "$ " + props.item.salario
                                        : "No aplica"
                                    )
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "$ " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      props.item.trabajador.puesto === "Raya"
                                        ? props.item.pago_total
                                        : props.item.total_pagos_destajo
                                    )
                                  )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-layout",
                                  [
                                    props.item.pagado === 1
                                      ? _c(
                                          "v-tooltip",
                                          { attrs: { bottom: "" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  slot: "activator",
                                                  icon: "",
                                                  flat: "",
                                                  dark: "",
                                                  "x-small": "",
                                                  color: "verdeFayal"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openSaveNominaTrabajadorModal(
                                                      props.item
                                                    )
                                                  }
                                                },
                                                slot: "activator"
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { "x-small": "" } },
                                                  [_vm._v("visibility")]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("Ver datos")])
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    props.item.pagado != 1
                                      ? _c(
                                          "v-tooltip",
                                          { attrs: { bottom: "" } },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  slot: "activator",
                                                  disabled:
                                                    props.item.pagado === 1,
                                                  icon: "",
                                                  flat: "",
                                                  dark: "",
                                                  "x-small": "",
                                                  color: "primary"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openSaveNominaTrabajadorModal(
                                                      props.item
                                                    )
                                                  }
                                                },
                                                slot: "activator"
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { "x-small": "" } },
                                                  [_vm._v("edit")]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("Editar")])
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _vm.$root.can(
                                          "prima-calculation",
                                          _vm.permissions
                                        )
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  slot: "activator",
                                                  disabled:
                                                    props.item.trabajador
                                                      .puesto === "Destajo" ||
                                                    props.item.pagado === 1,
                                                  icon: "",
                                                  flat: "",
                                                  dark: "",
                                                  "x-small": "",
                                                  color: "error"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openPrimaVacacionalModal(
                                                      props.item
                                                    )
                                                  }
                                                },
                                                slot: "activator"
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { "x-small": "" } },
                                                  [_vm._v("update")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        props.item.trabajador.puesto === "Raya"
                                          ? _c("span", [
                                              _vm._v(
                                                "Calcular prima vacacional"
                                              )
                                            ])
                                          : _c("span", [_vm._v("No aplica")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: props.item.pagado === 1,
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              "x-small": "",
                                              color: "primary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.payWorker(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-small": "" } },
                                              [_vm._v("check_circle")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Pagar")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: props.item.pagado === 1,
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              "x-small": "",
                                              color: "error"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeWorkerRelationship(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { "x-small": "" } },
                                              [_vm._v("person_remove")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("Remover de nómina")
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: { limit: 10, data: _vm.trabajadores },
                        on: { "pagination-change-page": _vm.getAreaNomina }
                      }),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("trabajadores-modal", {
        attrs: {
          show: _vm.showWorkersModal,
          area_nomina_id: _vm.area_nomina_id
        },
        on: {
          "update:show": function($event) {
            _vm.showWorkersModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("save-nomina-trabajador-modal", {
        attrs: {
          show: _vm.showSaveModal,
          selected_area_nomina_trabajador: _vm.selected_area_nomina_trabajador,
          area_nombre: _vm.area_nomina.area.nombre,
          readOnly: _vm.readOnlyCard
        },
        on: {
          "update:show": function($event) {
            _vm.showSaveModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("prima-vacacional-modal", {
        attrs: {
          show: _vm.showPrimaVacacionalModal,
          selected_area_nomina_trabajador_id:
            _vm.selected_area_nomina_trabajador.id
        },
        on: {
          "update:show": function($event) {
            _vm.showPrimaVacacionalModal = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }