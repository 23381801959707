<template>
  <v-layout>
    <v-container>
      <v-flex xs12>
            <!-- <td><pre>{{mantenimiento.materiales_mantenimiento}}</pre></td> -->
        <v-data-table :headers="vHeaders" :items="mantenimiento.materiales_mantenimiento" hide-actions class="elevation-1">
          <template slot="items" slot-scope="props">
            <td>{{ props.item.material.categoria_asociada}}</td>
            <td>{{ props.item.material.nombre }}</td>
            <td>{{ props.item.cantidad }}</td>
            <td>$ {{ props.item.material.precio | currency }}</td>
            <td>
              <v-tooltip bottom>
                <v-btn
                  icon
                  flat
                  dark
                  small
                  slot="activator"
                  color="error"
                  @click="deleteMaterialValue(props.item.id)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <span>Eliminar</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-container>
  </v-layout>
</template>

<script>
import swal from "sweetalert";

export default {
  data: () => ({
    mantenimiento: {
      data: []
    },
    vPaginator: {
      limit: 10
    },
    vHeaders: [
      { text: "Categoria", value: "categoria" },
      { text: "Material", value: "material" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio unitario", value: "precio" },
      { text: "Acciones", value: "acciones", sortable: false }
    ],
    localSearch: null,
    showEditHouseholdModal: false,
    editHouseoldId: null
  }),
  props: {
    itemId: Number,
    reload: Boolean,
    householdId: String,
    permissions: Object,
    modelType: String
  },
  watch: {
    itemId: function() {
      this.getMaterialsValue();
    },
    reload: function(value) {
      if (value == true) {
        this.getMaterialsValue();
      }
    }
  },
  methods: {
    getMaterialsValue: function(page = 1) {
      axios
        .get(`/ruta_index_requisitions_mantenimiento?page=${page}&query=${this.localSearch}&itemId=${this.itemId}&model=${this.modelType}`)
        .then(response => {
          this.mantenimiento = response.data;
        })
        .catch(error => errorHandling(error));
    },
    deleteMaterialValue: function(materialId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios
            .delete("/mantenimientos/material/"+materialId)
            .then(response => {
              this.getMaterialsValue();
              alertify.success(response.data.message_text);
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      });
    }
  }
};
</script>