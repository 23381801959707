var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1350px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "", tabs: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Administrar materiales")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        slot: "extension",
                        centered: "",
                        color: "white",
                        "slider-color": "verdeFayal"
                      },
                      slot: "extension",
                      model: {
                        value: _vm.vTabs,
                        callback: function($$v) {
                          _vm.vTabs = $$v
                        },
                        expression: "vTabs"
                      }
                    },
                    [
                      _c("v-tab", { attrs: { href: "#tab-add-material" } }, [
                        _vm._v("Añadir materiales")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.vTabs,
                    callback: function($$v) {
                      _vm.vTabs = $$v
                    },
                    expression: "vTabs"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "tab-add-material" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { "lazy-validation": "" },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          disabled: "",
                                          readonly: "",
                                          required: "",
                                          rules: _vm.rules,
                                          label: "Nombre de requisición"
                                        },
                                        model: {
                                          value:
                                            _vm.solicitud.nombre_requisicion,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.solicitud,
                                              "nombre_requisicion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "solicitud.nombre_requisicion"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.solicitante,
                                          label: "Tipo de categoría",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.solicitud.solicitante,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.solicitud,
                                              "solicitante",
                                              $$v
                                            )
                                          },
                                          expression: "solicitud.solicitante"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c("v-divider"),
                                  _vm._v(" "),
                                  _c("v-spacer")
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.materiales, function(material, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { wrap: "" } },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { md2: "" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                items: _vm.categories,
                                                rules: _vm.rules,
                                                "item-value": "id",
                                                "item-text": "nombre",
                                                label: "Categorias",
                                                "single-line": ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.getCurrentMateriales(
                                                    material.categoria_id,
                                                    index
                                                  )
                                                }
                                              },
                                              model: {
                                                value: material.categoria_id,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    material,
                                                    "categoria_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "material.categoria_id"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { md2: "" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                items: material.materiales,
                                                rules: _vm.rules,
                                                "return-object": "",
                                                "item-text": "nombre",
                                                label: "Material",
                                                required: "",
                                                "single-line": ""
                                              },
                                              model: {
                                                value: material.material,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    material,
                                                    "material",
                                                    $$v
                                                  )
                                                },
                                                expression: "material.material"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { md2: "" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                value: material.material.unidad,
                                                readonly: "",
                                                required: "",
                                                label: "Unidades",
                                                disabled: ""
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { md1: "" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                type: "number",
                                                min: "0",
                                                required: "",
                                                rules: _vm.rules,
                                                label: "Cantidad"
                                              },
                                              model: {
                                                value: material.cantidad,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    material,
                                                    "cantidad",
                                                    $$v
                                                  )
                                                },
                                                expression: "material.cantidad"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { md2: "" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                "prepend-icon": "attach_money",
                                                value: material.material.precio,
                                                readonly: "",
                                                disabled: "",
                                                required: "",
                                                label: "Precio unitario"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { md2: "" } },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                "prepend-icon": "attach_money",
                                                value:
                                                  parseInt(
                                                    material.material.precio
                                                  ) *
                                                  parseInt(material.cantidad),
                                                readonly: "",
                                                disabled: "",
                                                label: "Precio total"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { md1: "" } },
                                          [
                                            index != 0
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      fab: "",
                                                      flat: "",
                                                      dark: "",
                                                      small: "",
                                                      color: "verdeFayal"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.materiales.splice(
                                                          index,
                                                          1
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { dark: "" } },
                                                      [_vm._v("remove")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { md3: "" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                rules: _vm.rules,
                                                items: [_vm.fraccionamiento],
                                                readonly: "",
                                                disabled: "",
                                                "item-value": "id",
                                                "item-text": "nombre_proyecto",
                                                label: "Proyecto",
                                                "single-line": ""
                                              },
                                              model: {
                                                value: material.proyecto_id,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    material,
                                                    "proyecto_id",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "material.proyecto_id"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _vm.computedPropuesta
                                          ? _c(
                                              "v-flex",
                                              { attrs: { md2: "" } },
                                              [
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    items: _vm.getManzanasItems,
                                                    rules: _vm.rules,
                                                    "item-value": "id",
                                                    "item-text": "nombre",
                                                    label: "Manzana",
                                                    "single-line": ""
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      _vm.currentManzana = index
                                                    }
                                                  },
                                                  model: {
                                                    value: material.manzana_id,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        material,
                                                        "manzana_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "material.manzana_id"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.computedPropuesta
                                          ? _c(
                                              "v-flex",
                                              { attrs: { md2: "" } },
                                              [
                                                _c("v-autocomplete", {
                                                  attrs: {
                                                    rules: _vm.rules,
                                                    items: _vm.getLotesItems(
                                                      material.manzana_id
                                                    ),
                                                    "item-value": "id",
                                                    "item-text": "nombre",
                                                    label: "Lote",
                                                    "single-line": ""
                                                  },
                                                  model: {
                                                    value: material.lote_id,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        material,
                                                        "lote_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "material.lote_id"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-spacer"),
                                    _vm._v(" "),
                                    _c("v-divider"),
                                    _vm._v(" "),
                                    _c("v-spacer")
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.vTabs == "tab-add-material"
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            dark: "",
                            color: "verdeFayal",
                            flat: "",
                            loading: _vm.vBtnSave.loading
                          },
                          on: { click: _vm.storeMateriales }
                        },
                        [_vm._v("Guardar")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.vTabs == "tab-add-material"
                    ? _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal" },
                          on: { click: _vm.addMateriales }
                        },
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }