<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="700px">
      <template v-slot:activator="{ on }">
        <v-btn class="fyl-btnCreate" v-on:click="$emit('open')">Crear</v-btn>
      </template>
      <v-card>
        <form action v-on:submit.prevent.once="createObra()">
          <v-card-title>
            <span class="headline">{{ this.obra.id ? "Editar" : "Crear" }} Obra</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field label="Etapa" color="#1ABA24" v-model="obra.etapa"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    color="#1ABA24"
                    label="Tiempo de Realización"
                    v-model="obra.tiempo_realizacion"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea 
                  color="#1ABA24" 
                  label="Descripción" 
                  v-model="obra.descripcion"
                  counter="250"
                  :maxlength="max"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="fyl-btnClose" flat @click="$emit('close')">Cerrar</v-btn>
            <v-btn class="fyl-btnSave" type="submit" flat>Guardar</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
export default {
  props: ["obras", "obra", "dialog"],
  data() {
    return {
      max: 250
    };
  },
  methods: {
    createObra() {
      if (this.obra.id) {
        axios
          .patch(route("obra.update", this.obra.id), this.obra)
          .then(response => {
            if (response.data.message_text)
              alertify.success(response.data.message_text);
            this.$emit("close");
          })
          .catch(error => errorHandling(error))
          .finally(() =>
            setTimeout(() => {
              window.location.href = "/obra";
            }, 2000)
          );
      } else {
        axios
          .post("/obra", this.obra)
          .then(response => {
            this.obras.push(response.data.data);
            if (response.data.message_text)
              alertify.success(response.data.message_text);
            this.$emit("close");
          })
          .catch(error => errorHandling(error));
      }
    }
  }
};
</script>