<template>
  <v-layout>
    <v-flex>
      <v-data-table disable-initial-sort :headers="vHeaders" :items="households.data" hide-actions class="elevation-1">
        <template slot="items" slot-scope="props">
          <td>{{ props.item.nombre }}</td>
          <td>{{ props.item.descripcion }}</td>
          <td>{{ props.item.tipo }}</td>
          <td>
            <v-layout>
              <v-tooltip bottom>
                <v-btn icon flat dark small slot="activator" :href="`/prototipos_vivienda/${props.item.id}/valores`" :disabled="!$root.can('valores', permissions)">
                  <v-icon color="#1ABA24">attach_money</v-icon>
                </v-btn>
                <span>Valores</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn icon flat dark small slot="activator" color="info" @click="openEditHouseholdModal(props.item.id)" :disabled="!$root.can('update', permissions)">
                  <v-icon color="verdeFayal">edit</v-icon>
                </v-btn>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn icon flat dark small slot="activator" color="info" @click="openDuplicateHousehold(props.item.id)" :disabled="!$root.can('update', permissions)">
                  <v-icon color="verdeFayal">content_copy</v-icon>
                </v-btn>
                <span>Duplicar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <v-btn :href="`/prototipos_vivienda/${props.item.id}/materiales`" icon flat dark small slot="activator" color="verdeFayal">
                  <img src="/fonts/local/construction_icon.svg" class="customIcon">
                </v-btn>
                <span>Lista de materiales</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn icon flat dark small slot="activator" color="verdeFayal" @click="exportToExcel(props.item.id)">
                  <v-icon>import_export</v-icon>
                </v-btn>
                <span>Exportar a Excel</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn icon flat dark small slot="activator" color="error" @click="deleteHousehold(props.item.id)" :disabled="!$root.can('remove', permissions)">
                  <v-icon>delete</v-icon>
                </v-btn>
                <span>Eliminar</span>
              </v-tooltip>
            </v-layout>
          </td>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <pagination :limit="vPaginator.limit" :data="households" @pagination-change-page="getHouseholds"></pagination>
      </v-card-actions>
    </v-flex>

    <!-- Modals -->
    <edit-households-modal v-bind:show.sync="showEditHouseholdModal" v-bind:household-id.sync="editHouseoldId"></edit-households-modal>
    <duplicate-household-modal :show.sync="showDuplicateHouseHold"  :household-id="currentHouseholdId"/>
  </v-layout>
</template>

<script>
import swal from "sweetalert";

export default {
  data: () => ({
    households: {
      current_page: 1,
      data: []
    },
    vPaginator: {
      limit: 10
    },
    currentHouseholdId: null,
    showDuplicateHouseHold: false,
    vHeaders: [{
        text: 'Nombre',
        value: 'nombre'
      },
      {
        text: 'Descripción',
        value: 'descripcion'
      },
      {
        text: 'Tipo',
        value: 'tipo'
      },
      {
        text: 'Acciones',
        value: 'acciones',
        sortable: false
      }
    ],
    localSearch: null,
    showEditHouseholdModal: false,
    editHouseoldId: null
  }),
  props: {
    search: String,
    permissions: Object,
    gettingData: Boolean,
    filters: Object
  },
  watch: {
    search: function (value) {
      this.localSearch = value;
      this.getHouseholds();
    },
    showEditHouseholdModal: function (value) {
      if (!value) {
        this.getHouseholds(this.households.current_page);
      }
    },
    showDuplicateHouseHold: function() {
      this.getHouseholds();
    },
    gettingData: function() {
      this.filterHouseholds();
    }
  },
  methods: {
    getHouseholds: function (page = 1) {
      axios.get(`/prototipos_vivienda?page=${page}&query=${this.localSearch}`)
        .then(response => {
          this.households = response.data;
        })
        .catch(error => errorHandling(error));
    },
    deleteHousehold: function (householdId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('prototipos_vivienda.destroy', householdId))
            .then(response => {
              this.getHouseholds();
              alertify.success(response.data.message_text);
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      })
    },
    openEditHouseholdModal: function (householdId) {
      this.showEditHouseholdModal = true;
      this.editHouseoldId = householdId;
    },
    openDuplicateHousehold: function(householdId) {
      this.currentHouseholdId = householdId;
      this.showDuplicateHouseHold = true;
    },
    filterHouseholds: function() {
      axios.post('/prototipos_vivienda/filterHouseholds',this.filters)
      .then(response => this.households = response.data)
      .catch(error => errorHandling(error));
    },
    exportToExcel: function(householdId) {
      axios.get(laravelRoute('prototipos_vivienda.export', householdId), {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'prototipoVivienda.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
    },

  },
  mounted() {
    this.getHouseholds();
  }
}
</script>

<style scoped>
  .customIcon {
    width: 100%;
  }
</style>