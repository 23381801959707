var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Facturas")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "fyl-btnCreate",
                          on: { click: _vm.openCreateModal }
                        },
                        [_vm._v("Crear")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      "disable-initial-sort": "",
                      items: _vm.facturas.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(_vm._s(props.item.proveedor))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(_vm._s(props.item.numero_factura))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(_vm._s(props.item.monto))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(_vm._s(props.item.fecha_vigencia))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(_vm._s(props.item.plazo))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(_vm._s(props.item.pagada_at))
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-checkbox",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          flat: "",
                                          disabled: _vm.$root.can(
                                            "update",
                                            _vm.permisos
                                          )
                                            ? false
                                            : true,
                                          color: "indigo"
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.pagarFactura(props.item)
                                          }
                                        },
                                        slot: "activator",
                                        model: {
                                          value: props.item.estado,
                                          callback: function($$v) {
                                            _vm.$set(props.item, "estado", $$v)
                                          },
                                          expression: "props.item.estado"
                                        }
                                      },
                                      [_c("v-icon", [_vm._v("launch")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    props.item.estado == 0 ||
                                    props.item.estado == 3
                                      ? _c("span", [
                                          _vm._v("Marcar como pagada")
                                        ])
                                      : _c("span", [_vm._v("Factura Pagada")])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          disabled: _vm.$root.can(
                                            "update",
                                            _vm.permisos
                                          )
                                            ? false
                                            : true,
                                          icon: "",
                                          flat: "",
                                          dark: "",
                                          small: "",
                                          color: "info"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.editFactura(
                                              props.item.id
                                            )
                                          }
                                        },
                                        slot: "activator"
                                      },
                                      [_c("v-icon", [_vm._v("edit")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Editar")])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          disabled: _vm.$root.can(
                                            "remove",
                                            _vm.permisos
                                          )
                                            ? false
                                            : true,
                                          icon: "",
                                          flat: "",
                                          dark: "",
                                          small: "",
                                          color: "error"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.eliminar(props.item)
                                          }
                                        },
                                        slot: "activator"
                                      },
                                      [_c("v-icon", [_vm._v("delete")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Eliminar")])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.facturas
                        },
                        on: { "pagination-change-page": _vm.getFacturas }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      (_vm.$root.can("new", _vm.permisos)
      ? true
      : false)
        ? _c("contability-create-invoice", {
            attrs: {
              factura: _vm.factura,
              facturas: _vm.facturas,
              prov: _vm.prov,
              show: _vm.showCreateModal,
              mode: _vm.mode
            },
            on: {
              "update:show": function($event) {
                _vm.showCreateModal = $event
              },
              close: function($event) {
                return _vm.cleanForm()
              },
              "update:mode": function($event) {
                _vm.mode = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }