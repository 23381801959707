var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Nuevo trabajador")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [_c("v-subheader", [_vm._v("Datos personales")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { required: "", label: "Nombre" },
                                model: {
                                  value: _vm.trabajador.nombre,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "nombre", $$v)
                                  },
                                  expression: "trabajador.nombre"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: "Apellido paterno"
                                },
                                model: {
                                  value: _vm.trabajador.apellido_paterno,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trabajador,
                                      "apellido_paterno",
                                      $$v
                                    )
                                  },
                                  expression: "trabajador.apellido_paterno"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: "Apellido materno"
                                },
                                model: {
                                  value: _vm.trabajador.apellido_materno,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trabajador,
                                      "apellido_materno",
                                      $$v
                                    )
                                  },
                                  expression: "trabajador.apellido_materno"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "date",
                                  label: "Fecha de nacimiento"
                                },
                                model: {
                                  value: _vm.trabajador.fecha_de_nacimiento,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trabajador,
                                      "fecha_de_nacimiento",
                                      $$v
                                    )
                                  },
                                  expression: "trabajador.fecha_de_nacimiento"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: [
                                    "Soltero/a",
                                    "Casado/a",
                                    "Viudo/a",
                                    "Divorciado/a"
                                  ],
                                  label: "Estado civil"
                                },
                                model: {
                                  value: _vm.trabajador.estado_civil,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trabajador,
                                      "estado_civil",
                                      $$v
                                    )
                                  },
                                  expression: "trabajador.estado_civil"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: [
                                    "Primaria",
                                    "Secundaria",
                                    "Preparatoria",
                                    "Universidad",
                                    "Posgrado"
                                  ],
                                  label: "Grado de estudios"
                                },
                                model: {
                                  value: _vm.trabajador.grado_de_estudios,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trabajador,
                                      "grado_de_estudios",
                                      $$v
                                    )
                                  },
                                  expression: "trabajador.grado_de_estudios"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  minlength: "18",
                                  maxlength: "18",
                                  counter: 18,
                                  required: "",
                                  label: "CURP"
                                },
                                model: {
                                  value: _vm.trabajador.curp,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "curp", $$v)
                                  },
                                  expression: "trabajador.curp"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [_c("v-subheader", [_vm._v("Datos de contacto")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  mask: "(###)-###-##-##",
                                  label: "Teléfono fijo"
                                },
                                model: {
                                  value: _vm.trabajador.telefono_fijo,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trabajador,
                                      "telefono_fijo",
                                      $$v
                                    )
                                  },
                                  expression: "trabajador.telefono_fijo"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  mask: "(###)-###-##-##",
                                  label: "Teléfono celular"
                                },
                                model: {
                                  value: _vm.trabajador.telefono_celular,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trabajador,
                                      "telefono_celular",
                                      $$v
                                    )
                                  },
                                  expression: "trabajador.telefono_celular"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { type: "email", label: "Email" },
                                model: {
                                  value: _vm.trabajador.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "email", $$v)
                                  },
                                  expression: "trabajador.email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [_c("v-subheader", [_vm._v("Dirección")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Calle" },
                                model: {
                                  value: _vm.trabajador.calle,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "calle", $$v)
                                  },
                                  expression: "trabajador.calle"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Número" },
                                model: {
                                  value: _vm.trabajador.numero,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "numero", $$v)
                                  },
                                  expression: "trabajador.numero"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Colonia" },
                                model: {
                                  value: _vm.trabajador.colonia,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "colonia", $$v)
                                  },
                                  expression: "trabajador.colonia"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Municipio" },
                                model: {
                                  value: _vm.trabajador.municipio,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "municipio", $$v)
                                  },
                                  expression: "trabajador.municipio"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Estado" },
                                model: {
                                  value: _vm.trabajador.estado,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "estado", $$v)
                                  },
                                  expression: "trabajador.estado"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md4: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  minlegth: "5",
                                  maxlength: "5",
                                  counter: 5,
                                  label: "Código postal"
                                },
                                model: {
                                  value: _vm.trabajador.codigo_postal,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.trabajador,
                                      "codigo_postal",
                                      $$v
                                    )
                                  },
                                  expression: "trabajador.codigo_postal"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-subheader", [_vm._v("Datos de trabjador ")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  minlegth: "12",
                                  maxlength: "13",
                                  counter: 13,
                                  label: "RFC"
                                },
                                model: {
                                  value: _vm.trabajador.rfc,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "rfc", $$v)
                                  },
                                  expression: "trabajador.rfc"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  minlegth: "11",
                                  maxlength: "11",
                                  counter: 11,
                                  label: "Número de seguro social (NSS)"
                                },
                                model: {
                                  value: _vm.trabajador.nss,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "nss", $$v)
                                  },
                                  expression: "trabajador.nss"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: ["Destajo", "Raya"],
                                  label: "Puesto"
                                },
                                model: {
                                  value: _vm.trabajador.puesto,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "puesto", $$v)
                                  },
                                  expression: "trabajador.puesto"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.trabajador.puesto == "Destajo"
                            ? _c(
                                "v-flex",
                                { attrs: { md6: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: ["Sindicalizados", "De confianza"],
                                      label: "Tipo"
                                    },
                                    model: {
                                      value: _vm.trabajador.tipo,
                                      callback: function($$v) {
                                        _vm.$set(_vm.trabajador, "tipo", $$v)
                                      },
                                      expression: "trabajador.tipo"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.areas,
                                  label: "Área de trabajo",
                                  "item-text": "nombre",
                                  "item-value": "id"
                                },
                                model: {
                                  value: _vm.trabajador.area_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "area_id", $$v)
                                  },
                                  expression: "trabajador.area_id"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.trabajador.puesto == "Raya"
                            ? _c(
                                "v-flex",
                                { attrs: { md6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: { type: "number", label: "Salario" },
                                    model: {
                                      value: _vm.trabajador.salario,
                                      callback: function($$v) {
                                        _vm.$set(_vm.trabajador, "salario", $$v)
                                      },
                                      expression: "trabajador.salario"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  hint: "Ocupación",
                                  "search-input": _vm.query,
                                  items: _vm.ocupaciones.data,
                                  loading: _vm.isLoading,
                                  "item-text": "nombre",
                                  clearable: "",
                                  color: "verdeFayal",
                                  "persistent-hint": "",
                                  "item-value": "id",
                                  label: "Ocupación",
                                  "hide-selected": "",
                                  "hide-no-data": ""
                                },
                                on: {
                                  "update:searchInput": function($event) {
                                    _vm.query = $event
                                  },
                                  "update:search-input": function($event) {
                                    _vm.query = $event
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "no-data",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-list-tile",
                                          [
                                            _c("v-list-tile-title", [
                                              _vm._v(
                                                "\n                        Busca la \n                        "
                                              ),
                                              _c("strong", [
                                                _vm._v("Ocupación")
                                              ]),
                                              _vm._v(
                                                "\n                        del trabajador\n                      "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.trabajador.ocupacion,
                                  callback: function($$v) {
                                    _vm.$set(_vm.trabajador, "ocupacion", $$v)
                                  },
                                  expression: "trabajador.ocupacion"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [_c("v-subheader", [_vm._v("Contrato")])],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.tiposDeContratos,
                                  "item-text": "periodo",
                                  "item-value": "id",
                                  label: "Tipo de contrato"
                                },
                                model: {
                                  value: _vm.contratoTrabajador.contrato_id,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contratoTrabajador,
                                      "contrato_id",
                                      $$v
                                    )
                                  },
                                  expression: "contratoTrabajador.contrato_id"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { type: "date", label: "Fecha Inicio" },
                                model: {
                                  value: _vm.contratoTrabajador.fecha_inicio,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contratoTrabajador,
                                      "fecha_inicio",
                                      $$v
                                    )
                                  },
                                  expression: "contratoTrabajador.fecha_inicio"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "date",
                                  label: "Fecha fin (opcional)"
                                },
                                model: {
                                  value: _vm.contratoTrabajador.fecha_fin,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contratoTrabajador,
                                      "fecha_fin",
                                      $$v
                                    )
                                  },
                                  expression: "contratoTrabajador.fecha_fin"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-switch", {
                    attrs: { color: "verdeFayal", label: "Seguir registrando" },
                    model: {
                      value: _vm.keepStoring,
                      callback: function($$v) {
                        _vm.keepStoring = $$v
                      },
                      expression: "keepStoring"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: { click: _vm.storeMaterial }
                    },
                    [_vm._v(" Guardar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }