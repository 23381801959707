<template>
  <v-layout>
    <v-container>
      <v-card>
        <v-flex xs12>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Notificaciones</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat color="verdeFayal " @click="markAllAsRead">
              <v-icon>mark_chat_read</v-icon> Marcar todas como vistas
            </v-btn>
          </v-toolbar>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col" v-for="(header, key) in headers" :key="key">
                  {{header.text}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(notificacion, i) in notificaciones.data" :key="i">
                <td>{{ JSON.parse(notificacion.data).msg }}</td>
                <td>{{ notificacion.created_at }}</td>
                <td>
                  <v-layout>
                    <v-tooltip bottom>
                      <v-btn :class="(notificacion.read_at) ? 'disable-events' : null" :color="(notificacion.read_at) ? '#34b7f1' : 'grey'" icon flat dark small slot="activator" @click="markAsRead(notificacion)">
                        <v-icon>done_all</v-icon>
                      </v-btn>
                      <span>{{ (notificacion.read_at) ? "Notificación vista" : "Marcar como vista"}}</span>
                    </v-tooltip>
                  </v-layout>
                </td>
              </tr>
            </tbody>
          </table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="notificaciones" @pagination-change-page="getNotifications"></pagination>
          </v-card-actions>
        </v-flex>
      </v-card>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  data(){
    return{
      notificaciones: {
        data: []
      },
      vPaginator: {
        limit: 10
      },
      headers: [
        {
          text:'Mensaje',
          sortable: false
        },
        {
          text:'Fecha de notificación',
          sortable: false
        },
        {
          text: 'Visto',
          value: 'read_at',
          sortable: false
        }
      ]
    }
  },
  methods:{
    getNotifications: function (page = 1) {
      axios.get(`/notificaciones?page=${page}`)
        .then(response => {
          this.notificaciones = response.data;
        })
        .catch(error => errorHandling(error));
    },
    markAllAsRead: function() {
      axios.post(`/notificaciones/mark_all_as_read`)
      .then(response => {
        this.getNotifications();
      })
    },
    markAsRead: function(notification) {
        axios.post(`/notificaciones/${notification.id}/markasread`)
        .then(response => alertify.success("Notifiacion marcada como vista"))
        .catch(error => errorHandling(error))
        .finally(() => this.getNotifications());
      },
  },
  created() {
    this.getNotifications();
  }
}
</script>