<template>  
  <v-container>
    <v-layout row wrap>
      <v-flex :xs12="!showFiltersForm" :xs9="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Pruebas de laboratorio</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <v-btn v-if="$root.can('new', permissions) ? true : false" class="fyl-btnCreate" @click="showCreatePrueba = true">Crear</v-btn>
            <v-btn dark color="verdeFayal" outline @click="showFiltersForm = !showFiltersForm">
              <v-icon dark>filter_alt</v-icon>
            </v-btn>
            <v-btn dark color="verdeFayal" outline  @click="exportToExcel">
              <v-icon dark>import_export</v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table :headers="headers" disable-initial-sort :items="pruebas.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td>{{ props.item.nombre_prueba }}</td>
              <td>{{ props.item.laboratorio_encargado }}</td>
              <td>{{ props.item.fecha_respuesta }}</td>
              <td>{{props.item.fecha_de_obtencion_de_muestra}}</td>
              <td>{{ props.item.departamento_interesado }}</td>
              <td>{{ props.item.distribucion }}</td>
              <td>
                <v-layout>
                  <v-tooltip bottom>
                    <v-btn flat icon slot="activator" color="warning" @click="elementId = props.item.id; dialogFile = true;">
                      <v-icon>folder</v-icon>
                    </v-btn>
                    <span>Archivos</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      :disabled="!$root.can('update', permissions)"
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="info"
                      @click="editPruebasLab(props.item.id)"
                    >
                      <v-icon color="#1ABA24">edit</v-icon>
                    </v-btn>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      :disabled="!$root.can('remove', permissions)"                
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="error"
                      @click="deletePrueba(props.item)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <span>Eliminar</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="pruebas" @pagination-change-page="getPruebas"></pagination>
          </v-card-actions>
        </v-card>
      </v-flex>      
      <v-flex xs3 v-if="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat>
            <v-toolbar-title>Filtros</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="showFiltersForm = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout row wrap>              
                <v-flex>
                  <v-select
                    color="verdeFayal"
                    :items="itemsDepartamento"
                    label="Departamento interesado"
                    v-model="filters.filterDepartamento"
                    clearable
                  ></v-select>
                  <v-select
                    color="verdeFayal"
                    :items="proyectoItems"                    
                    item-value="id"
                    item-text="nombre_proyecto"
                    label="Proyecto al que pertenece"
                    v-model="filters.filterProyecto"                    
                    clearable
                  ></v-select>
                </v-flex>
                <v-flex>
                  <v-select
                    color="verdeFayal"
                    :items="itemsDistribucion"                    
                    label="Distribucion"
                    v-model="filters.filterDistribucion"
                    clearable
                  ></v-select>
                </v-flex>                
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn dark color="verdeFayal" small flat outline @click="limpiar">Limpiar</v-btn>
            <v-spacer></v-spacer>
            <v-btn dark color="verdeFayal" small @click="filterPruebas">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>      
      <!-- Modal -->
      <upload-files-modal @close="dialogFile = false" element_type="App\PruebaLaboratorio" :element_id="elementId" :dialog="dialogFile"></upload-files-modal>
      <pruebas-laboratorio-form
        :show.sync="showCreatePrueba"
      ></pruebas-laboratorio-form>
      <edit-pruebas-modal
        :show.sync="showEditPruebaModal"
        :prueba-id.sync="currentPruebaId"
      ></edit-pruebas-modal>
    </v-layout>
  </v-container>  
</template>

<script>
import { errorHandling, laravelRoute } from "../../lib/utilities";
import axios from "axios";
import swal from "sweetalert";

export default {
  props: ["lab_pruebas", 'concretados', 'permissions'],
  data() {
    return {
      pruebas: {
        data: []
      },
      showCreatePrueba: false,
      currentPruebaId: null,
      showEditPruebaModal: false,
      dialogFile: false,
      elementId: '',
      search: "",
      dialog: false,
      pruebasLab: {},
      headers: [
        { text: "Nombre", value: "nombre_prueba" },
        { text: "Laboratorio encargado", value: "laboratorio_encargado" },
        { text: "Fecha tentativa de respuesta", value: "fecha_respuesta" },
        { text: "Fecha de obtención de muestra", value: "fecha_de_obtencion_de_muestra" },
        { text: "Departamento interesado", value: "departamento_interesado" },
        { text: "Distribución", value: "distribucion" },
        { text: "Acciones", value: "", sortable: false }
      ],
      campoActualizado: { id: "", name: "" },
      vPaginator: {
        limit: 10
      },

      showFiltersForm: false,       
        filters: {
          filterDepartamento: null,
          filterProyecto: null,
          filterDistribucion: null,          
        },    
        itemsDepartamento: ['Urbanización', 'Edificación - Obra'],
        proyectoItems: [],
        itemsDistribucion: ['Manzanas', 'Calles', 'Áreas verdes'],
    };
  },
  computed: {
    getProjectId: () => {
      var urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('projectId');
    }
  },
  watch: {
    search: function (value) {
      this.getPruebas();
    },
    showCreatePrueba: function() {
      this.getPruebas();
    },
    showEditPruebaModal: function() {
      this.getPruebas();
    }
  },
  methods: {
    getPruebas: function(page = 1) {
      if(this.getProjectId) {
        axios.get(`/pruebas_laboratorio?page=${page}&query=${this.search}&projectId=${this.getProjectId}`)
        .then(response => this.pruebas = response.data)
        .catch(error => errorHandling(error));
      }
      else {
        axios.get(`/pruebas_laboratorio?page=${page}&query=${this.search}`)
        .then(response => this.pruebas = response.data)
        .catch(error => errorHandling(error));
      }
    },
    cleanForm() {
      this.pruebasLab = {};
      this.dialog = false;
    },
    deletePrueba: function(pruebaId) {
      swal({
        title: "¿estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('pruebas_laboratorio.destroy', pruebaId))
          .then(response => {
            this.getPruebas();
            alertify.success(response.data.message_text);
          })
          .catch(error => errorHandling(error));
        }
      })
    },
    eliminar: pruebasLabId => {
      var IDpruebasLab = pruebasLabId;
      axios
        .delete(route("pruebas_laboratorio.destroy", IDpruebasLab))
        .then(response => {
          if (response.data.message_text) {
            alertify.success(response.data.message_text);
          }
        })
        .catch(error => errorHandling(error));
    },
    editPruebasLab: function(pruebasLabId) {
      this.currentPruebaId = pruebasLabId;
      this.showEditPruebaModal = true;
    },

    getFraccionamientos: function(){
      axios.post('/tramites_dependencias/getFraccionamientos').then(response => {
        this.proyectoItems = response.data;
      }).catch(error => errorHandling(error));
    },     
    filterPruebas: function() {
      axios.post('/pruebas_laboratorio/filterPruebas',this.filters)
      .then(response => this.pruebas = response.data)
      .catch(error => errorHandling(error));
    },
    limpiar: function () {
        this.filters.filterDepartamento = null,
        this.filters.filterProyecto = null;
        this.filters.filterDistribucion = null,
        this.getPruebas();
    },
    exportToExcel: function() {
    axios.post('/pruebas_laboratorio/filterPruebas', {
      ...this.filters,
      to_excel: true
    }, {responseType: 'arraybuffer'})
    .then(response => {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'pruebas_laboratorio.xlsx');
      document.body.appendChild(fileLink);
      fileLink.click();
    })
    .catch(error => errorHandling(error));
    },
  },
  created() {
    this.getPruebas();
    this.getFraccionamientos();
  }
};
</script>