<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="550px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Finalizar la venta de {{ venta.lote.nombre }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md12>
              <v-text-field type="date" v-model="venta.fecha_de_firma" required label="Fecha de firma"></v-text-field>
            </v-flex>
            <v-flex md12>
              <v-text-field type="date" v-model="venta.fecha_de_entrega_programada" required label="Fecha de entrega programada"></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="UpdateFinishPurchase" outline dark color="verdeFayal" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../../lib/utilities";
export default {
  data: () => ({
    venta: {
      lote: {}
    },
    showFilesModal: false,
    fileURL: null,
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    ventaId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    ventaId: function (value) {
      this.getVenta();
    },
  },
  methods: {
    getVenta: function() {
      axios.get(laravelRoute('ventas.show.refactor', this.ventaId))
      .then(response => this.venta = response.data)
      .catch(error => errorHandling(error));
    },
    UpdateFinishPurchase: function() {
      this.vBtnSave.loading = true;
      axios.patch(laravelRoute('ventas.finalizar_venta', this.ventaId), {
        fecha_de_firma: this.venta.fecha_de_firma,
        fecha_de_entrega_programada: this.venta.fecha_de_entrega_programada
      })
      .then(response => {
        this.getVenta();
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;
      })
      .catch(error => errorHandling(error))
      .finally(() => this.vBtnSave.loading = false)
    }
  }
}
</script>
