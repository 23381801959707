<template>
  <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn flat icon :href="`/etapas/fraccionamiento/${etapa.fraccionamiento_id}`">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>

        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-toolbar card prominent>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="body-2 grey--text">Administrar datos de la etapa {{etapa.nombre}} </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout>
                                <v-flex xs3>
                                    <v-card dark color='#75d57b'>
                                        <v-layout>
                                            <v-flex xs5>
                                                <v-layout  align-center justify-center row fill-height>
                                                    <v-img
                                                        src="/fonts/local/watch.svg"
                                                        height="50px"
                                                        contain
                                                    ></v-img>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs7>
                                                <v-card-title primary-title>
                                                    <div>
                                                        <div class="headline total-title">{{ resumen.horas.toFixed(2) }}</div>
                                                        <div>Horas</div>
                                                    </div>
                                                </v-card-title>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex xs3>
                                    <v-card dark color='#75d57b'>
                                        <v-layout>
                                            <v-flex xs5>
                                                <v-layout  align-center justify-center row fill-height>
                                                    <v-img
                                                        src="/fonts/local/construction.svg"
                                                        height="50px"
                                                        contain
                                                    ></v-img>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs7>
                                                <v-card-title primary-title>
                                                    <div>
                                                        <div class="headline total-title">$ {{ resumen.total_materiales | currency}}</div>
                                                        <div>Material</div>
                                                    </div>
                                                </v-card-title>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex xs3>
                                    <v-card dark color='#75d57b'>
                                        <v-layout>
                                            <v-flex xs5>
                                                <v-layout  align-center justify-center row fill-height>
                                                    <v-img
                                                        src="/fonts/local/manpower.svg"
                                                        height="50px"
                                                        contain
                                                    ></v-img>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs7>
                                                <v-card-title primary-title>
                                                    <div>
                                                        <div class="headline total-title">$ {{ resumen.total_valores | currency}}</div>
                                                        <div>Mano de obra</div>
                                                    </div>
                                                </v-card-title>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex xs3>
                                    <v-card dark color='#75d57b'>
                                        <v-layout>
                                            <v-flex xs5>
                                                <v-layout  align-center justify-center row fill-height>
                                                    <v-img
                                                        src="/fonts/local/total.svg"
                                                        height="50px"
                                                        contain
                                                    ></v-img>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs7>
                                                <v-card-title primary-title>
                                                    <div>
                                                        <div class="headline total-title">$ {{ resumen.total_materiales + resumen.total_valores | currency}}</div>
                                                        <div>Total</div>
                                                    </div>
                                                </v-card-title>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                            </v-layout>

                            <v-divider></v-divider>
                            
                            <v-layout row wrap>
                                <v-flex md6>
                                    <v-text-field
                                        label="Nombre de la etapa"
                                        v-model="etapa.nombre"
                                        readonly
                                    ></v-text-field>
                                </v-flex>
                                <v-flex md6>
                                    <v-autocomplete
                                        hint="Buscar manzana, calle o área verde a trabajar"
                                        @keyup.enter.native="storeDistribucion"
                                        :search-input.sync="search"
                                        prepend-inner-icon="search"
                                        :items="distribuciones"
                                        label="Buscar"
                                        :loading="isLoading"
                                        item-text="nombre"
                                        v-model="selected"
                                        item-value="id"
                                        persistent-hint
                                        return-object
                                        hide-selected
                                        hide-no-data
                                    >
                                        <template v-slot:append-outer>
                                            <v-slide-x-reverse-transition mode="out-in">
                                                <v-icon color="success" :disabled="!selected" @click="storeDistribucion">add</v-icon>
                                            </v-slide-x-reverse-transition>
                                        </template>
                                    </v-autocomplete>
                                </v-flex>
                            </v-layout>

                            <v-divider></v-divider>
                            
                            <v-layout row wrap>
                                <v-flex>
                                    <v-tabs v-model="vTabs" fixed-tabs centered color="white" slider-color="#1aba24">
                                        <v-tab href="#tab-manzanas">
                                            <v-badge color="#ff5617" rigth>
                                                <template v-slot:badge>
                                                    <span>{{ etapa.manzanas_etapas_count }}</span>
                                                </template>
                                                <span>Manzanas</span>
                                            </v-badge>
                                        </v-tab>
                                        <v-tab href="#tab-calles">
                                            <v-badge color="#ff5617" rigth>
                                                <template v-slot:badge>
                                                    <span>{{ etapa.calles_count }}</span>
                                                </template>
                                                <span>Calles</span>
                                            </v-badge>
                                        </v-tab>
                                        <v-tab href="#tab-areas-verdes">
                                            <v-badge color="#ff5617" rigth>
                                                <template v-slot:badge>
                                                    <span>{{ etapa.areas_verdes_count }}</span>
                                                </template>
                                                <span>Áreas verdes</span>
                                            </v-badge>
                                        </v-tab>
                                    </v-tabs>
                                    <v-tabs-items v-model="vTabs">
                                        <v-tab-item value="tab-manzanas">
                                            <v-card flat>
                                                <v-container>
                                                    <v-card-text>
                                                        <table class="table table-striped table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Manzana</th>
                                                                    <th>Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(manzana, index) in manzanas.data" :key="index">
                                                                    <td>{{ manzana.nombre }}</td>
                                                                    <td>
                                                                        <v-layout>
                                                                            <!--<v-btn flat icon color="verdeFayal" :href="`/etapas/${etapa.id}/distribucion/materiales?distribucion_id=${manzana.id}&tipo=manzana`">
                                                                                <v-icon>vertical_split</v-icon>
                                                                            </v-btn>-->
                                                                            <v-btn flat icon color="verdeFayal" :href="`/etapas/${etapa.id}/distribucion/detalles?distribucion_id=${manzana.id}&tipo=manzana`">
                                                                                <v-icon>info</v-icon>
                                                                            </v-btn>
                                                                            <v-btn flat icon color="verdeFayal" :href="`/etapas/${etapa.id}/distribucion/conceptos?distribucion_id=${manzana.id}&tipo=manzana`">
                                                                                <v-icon>edit</v-icon>
                                                                            </v-btn>
                                                                            <v-btn flat icon color="red" @click="deleteDistribucion(manzana.id, 'manzana')">
                                                                                <v-icon>delete</v-icon>
                                                                            </v-btn>
                                                                        </v-layout>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <pagination :limit="10" :data="manzanas" @pagination-change-page="getManzanas"></pagination>
                                                    </v-card-actions>
                                                </v-container>
                                            </v-card>
                                        </v-tab-item>
                                        <v-tab-item value="tab-calles">
                                            <v-card flat>
                                                <v-container>
                                                    <v-card-text>
                                                        <table class="table table-striped table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Calle</th>
                                                                    <th>Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(calle, index) in calles.data" :key="index">
                                                                    <td>{{ calle.nombre }}</td>
                                                                    <td>
                                                                        <v-layout>
                                                                            <v-btn flat icon color="verdeFayal" :href="`/etapas/${etapa.id}/distribucion/detalles?distribucion_id=${calle.id}&tipo=calle`">
                                                                                <v-icon>info</v-icon>
                                                                            </v-btn>
                                                                            <v-btn flat icon color="verdeFayal" :href="`/etapas/${etapa.id}/distribucion/conceptos?distribucion_id=${calle.id}&tipo=calle`">
                                                                                <v-icon>edit</v-icon>
                                                                            </v-btn>
                                                                            <v-btn flat icon color="red" @click="deleteDistribucion(calle.id, 'calle')">
                                                                                <v-icon>delete</v-icon>
                                                                            </v-btn>
                                                                        </v-layout>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <pagination :limit="10" :data="calles" @pagination-change-page="getCalles"></pagination>
                                                    </v-card-actions>
                                                </v-container>
                                            </v-card>
                                        </v-tab-item>
                                        <v-tab-item value="tab-areas-verdes">
                                            <v-card flat>
                                                <v-container>
                                                    <v-card-text>
                                                        <table class="table table-striped table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Área verde</th>
                                                                    <th>Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(area, index) in areas.data" :key="index">
                                                                    <td>{{ area.nombre }}</td>
                                                                    <td>
                                                                        <v-layout>
                                                                            <v-btn flat icon color="verdeFayal" :href="`/etapas/${etapa.id}/distribucion/detalles?distribucion_id=${area.id}&tipo=area-verde`">
                                                                                <v-icon>info</v-icon>
                                                                            </v-btn>
                                                                            <v-btn flat icon color="verdeFayal" :href="`/etapas/${etapa.id}/distribucion/conceptos?distribucion_id=${area.id}&tipo=areas-verdes`">
                                                                                <v-icon>edit</v-icon>
                                                                            </v-btn>
                                                                            <v-btn flat icon color="red" @click="deleteDistribucion(area.id, 'area_verde')">
                                                                                <v-icon>delete</v-icon>
                                                                            </v-btn>
                                                                        </v-layout>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <pagination :limit="10" :data="areas" @pagination-change-page="getAreas"></pagination>
                                                    </v-card-actions>
                                                </v-container>
                                            </v-card>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    data() {
        return {
            fraccionamiento: {},
            search: null,
            selected: null,
            isLoading: false,
            manzanas: {},
            calles: {},
            areas: {},
            distribuciones: [],
            selectManzanas: [],
            selectAreas: [],
            selectCalles: [],
            resumen: {
                total_materiales: 0,
                total_valores: 0
            },
            vTabs: 'tab-manzanas',
            vBtnSave: {
                loading: false
            },
            vSnackBar: {
                visible: false,
                text: null
            },
        }
    },
    props: {
            etapa: Object
        },
        watch: {
            search: function(){
                this.getDistribuciones();
            },
            vTabs: function(){
                this.search = null;
                this.selected = null;
            }
        },
        computed: {
            currentDistribucion: function() {
                if(this.vTabs == 'tab-manzanas') {
                    return 'manzanas';
                }
                else if(this.vTabs == 'tab-calles') {
                    return 'calles';
                }
                else if(this.vTabs == 'tab-areas-verdes') {
                    return 'areas-verdes';
                }
            }
        },
        methods: {
            getDistribuciones: function() {
                this.isLoading = true;
                axios.get(`/etapas/${this.etapa.fraccionamiento_id}/distribuciones?tipo_distribucion=${this.currentDistribucion}&search=${this.search}`)
                .then(response => this.distribuciones = response.data.data)
                .catch(error => errorHandling(error))
                .finally(() => this.isLoading = false);
            },
            storeDistribucion: function() {
                this.vBtnSave.loading = true;
                axios.post(laravelRoute('etapas.urbanizacion.distribucion.store', this.etapa.id),{
                    etapa_id: this.etapa.id,
                    selected: this.selected,
                    current_distribucion: this.currentDistribucion
                })
                .then(response => {
                    if(this.vTabs == 'tab-manzanas') {
                        this.getManzanas();
                    }
                    else if(this.vTabs == 'tab-calles') {
                        this.getCalles();
                    }
                    else if(this.vTabs == 'tab-areas-verdes') {
                        this.getAreas();
                    }
                    this.vSnackBar.text = response.data.message_text;
                    this.vSnackBar.visible = true;
                })
                .finally(() => this.vBtnSave.loading = false)
                .catch(error => errorHandling(error));
            },
            getManzanas: function(page = 1) {
                axios.get(`/etapas/${this.etapa.id}/manzanas?page=${page}`)
                .then(response => this.manzanas = response.data)
                .catch(error => errorHandling(error));
            },
            getCalles: function(page = 1) {
                axios.get(`/etapas/${this.etapa.id}/calles?page=${page}`)
                .then(response => this.calles = response.data)
                .catch(error => errorHandling(error));
            },
            getAreas: function(page = 1) {
                axios.get(`/etapas/${this.etapa.id}/areas_verdes?page=${page}`)
                .then(response => this.areas = response.data)
                .catch(error => errorHandling(error));
            },
            deleteDistribucion: function(distribucionId, tipoDistribucion) {
                swal({
                    title: "¿Estás seguro?",
                    text: "Una vez eliminado no se podrá recuperar",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true
                }).then(result => {
                    if (result) {
                        axios.delete(`/etapas/${this.etapa.id}/distribucion?distribucion_id=${distribucionId}&tipo=${tipoDistribucion}`)
                        .then(response => {
                            if(tipoDistribucion == 'manzana') {
                                this.getManzanas();
                            }
                            else if(tipoDistribucion == 'calle') {
                                this.getCalles();
                            }
                            else if(tipoDistribucion == 'area_verde') {
                                this.getAreas();
                            }
                            this.vSnackBar.text = response.data.message_text;
                            this.vSnackBar.visible = true;
                        })
                        .catch(error => errorHandling(error));
                    }
                })
            },
            getTotales: function() {
                axios.get(`/etapas/${this.etapa.id}/totales`)
                .then(response => this.resumen = response.data)
                .catch(error => errorHandling(error));
            }
        },
        created() {
            this.getManzanas();
            this.getCalles();
            this.getAreas();
            this.getTotales();
        }
  }
</script>

<style>
    .total-title {
        font-size: 20px !important;
        line-height: 18px !important;
    }
</style>