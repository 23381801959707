var render, staticRenderFns
var script = {}


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "resources/assets/js/components/Materiales/Requisiciones/IndexRequisitions.vue"
export default component.exports