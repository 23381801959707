var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            flat: "",
                            icon: "",
                            href: "/etapas/" + _vm.etapa.id + "/actividades"
                          }
                        },
                        [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_vm._v(" "), _c("span", [_vm._v("Regresar")])]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "pb-2": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs10: "", "offset-xs1": "" } },
            [
              _c(
                "v-card",
                { staticClass: "card--flex-toolbar" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", prominent: "" } },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-title",
                        { staticClass: "body-2 grey--text" },
                        [
                          _vm._v(
                            "Actividades de la " + _vm._s(_vm.manzana.nombre)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs5: "" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { dark: "", color: "#75d57b" } },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs8: "" } },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  attrs: { "primary-title": "" }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "headline total-title"
                                                      },
                                                      [_vm._v("Iniciadas")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        " Progreso de actividades Iniciadas"
                                                      )
                                                    ])
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs4: "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    "align-center": "",
                                                    "justify-center": "",
                                                    row: "",
                                                    "fill-height": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            rotate: 180,
                                                            size: 75,
                                                            width: 10,
                                                            value:
                                                              _vm.etapa
                                                                .porcentaje_de_avance
                                                                .iniciado,
                                                            color: "white"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                _vm.etapa.porcentaje_de_avance.iniciado.toFixed(
                                                                  1
                                                                ) == "100.0"
                                                                  ? "100"
                                                                  : _vm.etapa.porcentaje_de_avance.iniciado.toFixed(
                                                                      1
                                                                    )
                                                              ) +
                                                              "%\n                            "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-flex", { attrs: { xs2: "" } }),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs5: "" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { dark: "", color: "#75d57b" } },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs8: "" } },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  attrs: { "primary-title": "" }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "headline total-title"
                                                      },
                                                      [_vm._v("Finalizadas")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        " Progreso de actividades finalizadas"
                                                      )
                                                    ])
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs4: "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    "align-center": "",
                                                    "justify-center": "",
                                                    row: "",
                                                    "fill-height": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            rotate: 180,
                                                            size: 75,
                                                            width: 10,
                                                            value:
                                                              _vm.etapa
                                                                .porcentaje_de_avance
                                                                .finalizado,
                                                            color: "white"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                              " +
                                                              _vm._s(
                                                                _vm.etapa.porcentaje_de_avance.finalizado.toFixed(
                                                                  1
                                                                ) == "100.0"
                                                                  ? "100"
                                                                  : _vm.etapa.porcentaje_de_avance.finalizado.toFixed(
                                                                      1
                                                                    )
                                                              ) +
                                                              "%\n                            "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-breadcrumbs", {
                                    staticStyle: { margin: "1%" },
                                    attrs: {
                                      items: _vm.breadcrums,
                                      divider: ">"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _vm.loading
                                    ? _c("v-progress-linear", {
                                        attrs: {
                                          color: "verdeFayal",
                                          indeterminate: true
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("v-data-table", {
                                    attrs: {
                                      items: _vm.lotes.data,
                                      "hide-actions": "",
                                      "item-key": "id"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "headers",
                                        fn: function() {
                                          return [
                                            _c("tr", [
                                              _c("th", [_vm._v("Lote")]),
                                              _vm._v(" "),
                                              _c("th", [
                                                _vm._v("Porcentaje de avance")
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "text-xs-right"
                                                },
                                                [_vm._v("Acciones")]
                                              )
                                            ])
                                          ]
                                        },
                                        proxy: true
                                      },
                                      {
                                        key: "items",
                                        fn: function(props) {
                                          return [
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(props.item.lote.nombre)
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c(
                                                  "div",
                                                  { staticClass: "progress" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "progress-bar progress-bar-striped progress-bar-animated bg-success",
                                                        style:
                                                          "width: " +
                                                          props.item
                                                            .porcentaje_de_avance +
                                                          "%;",
                                                        attrs: {
                                                          role: "progressbar",
                                                          "aria-valuenow":
                                                            props.item
                                                              .porcentaje_de_avance,
                                                          "aria-valuemin": "0",
                                                          "aria-valuemax": "100"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                              " +
                                                            _vm._s(
                                                              props.item.porcentaje_de_avance.toFixed(
                                                                2
                                                              )
                                                            ) +
                                                            "%\n                            "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-right"
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        flat: "",
                                                        icon: "",
                                                        href:
                                                          "/etapas/" +
                                                          _vm.etapa.id +
                                                          "/actividades/manzana/" +
                                                          _vm.manzana.id +
                                                          "/lote/" +
                                                          props.item.id
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("chevron_right")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: { limit: 10, data: _vm.lotes },
                        on: { "pagination-change-page": _vm.getLotes }
                      }),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }