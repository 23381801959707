<template>
  <!--<v-btn dark class="btn--blue-flat-focused jbtn-file"> {{ title }}<input
  type="file" v-on:change="fileSelected"></v-btn>-->
  <v-layout>
    <v-flex>
      <v-text-field
        color="#1ABA24"
        v-bind:label="placeholder"
        @click="pickFile"
        v-model="fileName"
        prepend-inner-icon="attach_file"
      ></v-text-field>
      <input color="#1ABA24" type="file" style="display: none" ref="file" @change="fileSelected" />
    </v-flex>
    <v-flex md1 v-show="value >= 0 && value < 100">
      <v-progress-circular
        :rotate="360"
        :size="50"
        :width="5"
        :value="value"
        color="primary"
      >{{ value }}</v-progress-circular>
    </v-flex>
    <v-flex md1 v-show="value == 100">
      <v-icon size="50" color="success">check_circle_outline</v-icon>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["placeholder"],
  data() {
    return {
      fileName: "",
      value: 0
    };
  },

  methods: {
    pickFile() {
      this.$refs.file.click();
    },

    fileSelected(e) {
      
        if (e.target.files[0]) {
          this.fileName = e.target.files[0].name;
          //this.selectedCallback(e.target.files[0]);
          this.$root.awsuploader(e.target.files[0]);
          this.$emit("fileUpload");
          this.$refs.file.value = '';
        }
      
    }
  },

  computed: {
    Upload() {
      return this.$store.state.loaded;
    },

    filename() {
      return this.$store.state.fileName;
    }
  },

  watch: {
    Upload() {
      this.value = this.Upload;
    },

    filename() {
      this.fileName = this.filename;
    }
  }
};
</script>