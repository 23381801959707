<template>
  <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom>
                <template >
                    <v-btn slot="activator" flat icon :href="`/fraccionamientos/${fraccionamientoId}/reporte`">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>

        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-toolbar card prominent>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="body-2 grey--text">Desglose por conceptos de {{ item.nombre }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                      <v-container grid-list-md>
                        <v-data-table :expand="expand" ref="conceptosTable" disable-initial-sort :items="conceptos.data" hide-actions>
                          <template v-slot:headers="props">
                            <tr>
                              <th>Nombre</th>
                              <th>Subtotal de materiales</th>
                              <th>Subtotal de mano de obra</th>
                              <th></th>
                            </tr>
                          </template>
                          <template slot="items" slot-scope="props">
                            <tr>
                              <td>{{ props.item.concepto.nombre }}</td>
                              <td>$ {{ props.item.subtotal_materiales | currency }}</td>
                              <td>$ {{ props.item.subtotal_mano_de_obra | currency }}</td>
                              <td class="text-xs-right">
                                <v-tooltip bottom>
                                  <v-btn @click="openMaterialesModal('lotes', props.item.concepto.id)" icon flat dark slot="activator" color="verdeFayal">
                                    <img src="/fonts/local/construction_icon.svg" class="customIcon">
                                  </v-btn>
                                  <span>Ver materiales asociados</span>
                                </v-tooltip>
                                <v-btn flat icon @click="openExpand(props.item.id, props.item.concepto.id, props.index)">
                                  <v-icon>{{(props.expanded) ? 'expand_less' : 'expand_more'}}</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </template>
                          <template v-slot:expand="props">
                            <table class="table table-striped table-hover table-sm">
                                <thead>
                                  <tr>
                                    <th>Rubro</th>
                                    <th>Subtotal de materiales</th>
                                    <th>Subtotal de mano de obra</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(rubro, index) in conceptos.data[props.index].rubros" :key="index">
                                    <td>{{ rubro.rubro.nombre }}</td>
                                    <td>$ {{ rubro.subtotal_materiales | currency }}</td>
                                    <td>$ {{rubro.subtotal_mano_de_obra | currency }} </td>
                                    <td></td>
                                  </tr>
                                </tbody>
                            </table>
                          </template>
                        </v-data-table>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <pagination :limit="10" :data="conceptos" @pagination-change-page="getConceptosPorDistribucion"></pagination>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <show-materials-by-distribucion-modal 
            :show.sync="showMaterialsModal" 
            :distribucion-id.sync="currentDistribuicionId"
            :distribucion-type.sync="currentDistribuicionType"
            :concepto-id="currentConceptoId"
        />
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    data() {
      return {
        conceptos: [],
        currentDistribuicionId: null,
        currentDistribuicionType: null,
        currentConceptoId: null,
        expand: false,
        showMaterialsModal: false,
        vBtnSave: {
          loading: false
        },
        vSnackBar: {
          visible: false,
          text: null
        },
      }
    },
    props: {
      item: Object,
      itemType: String,
      fraccionamientoId: String
    },
    watch: {

    },
    methods: {
      getConceptosPorDistribucion: function(page = 1) {
        axios.get(`/conceptos/asociados_por_distribucion?page=${page}&fraccionamiento_id=${this.fraccionamientoId}&item_type=${this.itemType}&item_id=${this.item.id}`)
        .then(response => this.conceptos = response.data)
        .catch(error => errorHandling(error));
      },
      openExpand: function(id, conceptoId, index, page = 1) {
        let expand = !this.$refs.conceptosTable.expanded[id];
        axios.get(`/conceptos/asociados_por_distribucion/rubros/?concepto_id=${conceptoId}&page=${page}&fraccionamiento_id=${this.fraccionamientoId}&item_type=${this.itemType}&item_id=${this.item.id}`)
        .then(response => {
            this.conceptos.data[index].rubros = response.data;
        })
        .catch(error => errorHandling(error))
        .finally(() => this.$set(this.$refs.conceptosTable.expanded, id, expand));
      },
      openMaterialesModal: function(type, conceptoId) {
        this.showMaterialsModal = true;
        this.currentDistribuicionId = this.item.id;
        this.currentDistribuicionType = type;
        this.currentConceptoId = conceptoId;
      }
    },
    created() {
      this.getConceptosPorDistribucion();
    }
  }
</script>

<style scoped>
  .customIcon {
    width: 74%;
  }
</style>
