<template>
    <v-card>
      <v-card-text>
        <h4>{{ownerName}}</h4>
        <v-data-table 
            v-if="type == 'obra'"
            :hide-actions="true"
            :disable-initial-sort="true"
            :headers="headers"
            :items="items"
            class="elevation-1"
          > 
            <template slot="items" slot-scope="props">
              <td>{{props.item.lote_lotificacion.nombre}}</td>
              <td>{{props.item.horas}}</td>
              <td>{{Math.ceil(props.item.horas / 8)}}</td>
              <td>{{props.item.estado ? props.item.estado.information.estado : "No disponible"}}</td>
              <td>{{props.item.manzana.nombre}}</td>
              <td>
                <v-tooltip bottom>
                  <v-btn
                    :disabled="props.item.estado.information.id != 28"
                    icon
                    flat
                    dark
                    small
                    slot="activator"
                    color="info"
                    @click="startFunction(props.item.id)"
                  >
                    <v-icon>play_arrow</v-icon>
                  </v-btn>
                  <span>Iniciar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn
                    :disabled="!(props.item.estado.information.id >= 28 && props.item.estado.information.id < 30)"
                    icon
                    flat
                    dark
                    small
                    slot="activator"
                    color="red"
                    @click="stopFunction(props.item.id)"
                  >
                    <v-icon>stop</v-icon>
                  </v-btn>
                  <span>Finalizar</span>
                </v-tooltip>
              </td>
            </template>
        </v-data-table>
        
        <v-data-table 
            v-if="type != 'obra'"
            :hide-actions="true"
            :disable-initial-sort="true"
            :headers="headers"
            :items="items"
            class="elevation-1"
          > 
            <template slot="items" slot-scope="props">
              <td>{{props.item.nombre}}</td>
              <td>{{props.item.horas}}</td>
              <td>{{Math.ceil(props.item.horas / 8)}}</td>
              <td>{{props.item.estado ? props.item.estado.information.estado : "No disponible"}}</td>
              <td>
                <v-tooltip bottom>
                  <v-btn
                    :disabled="([32,33,35,36,38,39].includes(props.item.estado.information.id))"
                    icon
                    flat
                    dark
                    small
                    slot="activator"
                    color="info"
                    @click="startFunction(props.item.id)"
                  >
                    <v-icon>play_arrow</v-icon>
                  </v-btn>
                  <span>Iniciar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn
                    :disabled="[33,36,39].includes(props.item.estado.information.id)"
                    icon
                    flat
                    dark
                    small
                    slot="activator"
                    color="red"
                    @click="stopFunction(props.item.id)"
                  >
                    <v-icon>stop</v-icon>
                  </v-btn>
                  <span>Finalizar</span>
                </v-tooltip>
              </td>
            </template>
        </v-data-table>

      </v-card-text>
    </v-card>
</template>

<script>
import { errorHandling } from "../../lib/utilities";
export default {
  props: ["dialog", 'headers', 'ownerName', 'items', 'startFunction', 'stopFunction', 'type'],

  data() {
    return {
      loading: false,
      conceptosData: {
        items:[],
        selected:[],
        rubros:[],
        headers:[
          { 
            sortable: false,
            text: 'Concepto' 
          },
          { 
            sortable: false,
            text: 'Disponibles' 
          },
          { 
            sortable: false,
            text: 'Seleccionados' 
          },
        ],
      },
      localFraccionamiento: {},
    };
  },
  watch:{
    conceptos: function(value){
      this.conceptosData.items =  value;
    },
    dialog: function(value){
      if(!value){
        this.conceptosData.selected = [];
        this.conceptosData.rubros = [];
      }else{
        if(this.dad == 'Obra' || this.dad == 'obra'){
            this.autoFillObra();
          }else{
            this.getConceptosUrbanizacion();
        }
      }
    },
  },
  methods: {
    getConceptosUrbanizacion: function () {
      this.loading = true;
      axios.get(`/urbanizacion/valores`)
      .then(response => { 
        this.conceptosData.items = response.data.conceptos;
        this.autoFillUrbanizacion();
      })
      .catch( error => errorHandling(error))
      .finally(()=> this.loading = false)
    },
    autoFillObra: function(){
      let localRubros = [];
      let localConceptos = [];
      let conceptosIdsArray = this.loteData.rubros ? new Set(this.loteData.rubros.map(rubro => rubro.conceptoId ? rubro.conceptoId : rubro.concepto_id)) : [];
      conceptosIdsArray.forEach(element => {
        let conceptoEncontrado = this.conceptosData.items.find(concepto => concepto.id == element);          
        if(conceptoEncontrado) {
          localConceptos.push(conceptoEncontrado);
        }
      });
      localRubros = this.loteData.rubros ? this.loteData.rubros : [];

      this.conceptosData.selected = localConceptos;
      this.conceptosData.rubros = localRubros;
    },
    autoFillUrbanizacion: function(){
      if(this.conceptosAutofill){
        let localConceptosSelected = [];
        let localRubrossSelected = [];
        this.conceptosAutofill.forEach(element => {
          let conceptoEncontrado = this.conceptosData.items.find(concepto => concepto.id == element);
          if(conceptoEncontrado) {
            localConceptosSelected.push(conceptoEncontrado);
            this.rubrossAutofill.forEach(rubroItem => {
              let rubroEncontrado = conceptoEncontrado.rubros.find(rubro => rubro.id == rubroItem);
              if(rubroEncontrado){
                localRubrossSelected.push({conceptoId: conceptoEncontrado.id, rubro: rubroEncontrado});
              }
            })
          }
        });
        this.conceptosData.selected = localConceptosSelected;
        this.conceptosData.rubros = localRubrossSelected;
      }
    },
    addRubro: function(conceptoId, rubro){
      if(this.conceptosData.rubros.filter(rubroData => rubroData.rubro.id == rubro.id).length > 0) return;
      this.conceptosData.rubros.push({conceptoId: conceptoId, rubro: rubro});
    },
    removeRubro: function(conceptoId, rubro){
      this.conceptosData.rubros.splice(this.conceptosData.rubros.indexOf(rubro), 1);
    },
    aceptar: function(){
      let dataToSend = {loteId: this.conceptosDad.id, rubros: this.conceptosData.rubros, urbanizacionId: this.dadId};
      if( !dataToSend.rubros.length ) return alertify.error('No se han seleccionado rubros');
      this.$emit('dataSended', dataToSend);
      this.$emit('close');
    }
  },
};
</script>