<template>
  <v-layout>
    <v-container>
      <v-card>
        <v-flex xs12>
          <v-toolbar color="white" flat tabs>
            <v-btn fab small flat :href="`/etapas/fraccionamiento/${etapa.fraccionamiento_id}`">
              <v-icon>chevron_left</v-icon>
            </v-btn>
            <v-toolbar-title>Incidencias</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn 
              color="verdeFayal"
              dark
              @click="dialogIncidencia = true; incidenciaId = null"
            >
              Crear
            </v-btn>
          </v-toolbar>
          <v-data-table :headers="headers" disable-initial-sort :items="incidencias.data" hide-actions class="elevation-1">
            <template v-slot:items="props">
              <td>{{ props.item.nombre }}</td>
              <td>{{ props.item.descripcion }}</td>
              <td>{{ props.item.horas }}</td>
              <td>{{ props.item.creador.name }}</td>
              <td>
                <v-layout>
                  <v-tooltip bottom>
                    <v-btn
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="info"
                      @click="dialogIncidencia = true; incidenciaId = props.item.id"
                    >
                      <v-icon color="verdeFayal">remove_red_eye</v-icon>
                    </v-btn>
                    <span>Ver</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <v-btn
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="red"
                      @click="deleteIncidencia(props.item.id)"
                    >
                      <v-icon color="red">delete</v-icon>
                    </v-btn>
                    <span>Eliminar</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="incidencias" @pagination-change-page="getIncidencias"></pagination>
            <v-spacer></v-spacer>
            <div>
              <span>
                Total de horas: <span class="grey--text">{{ etapa.horas_incidencias }}</span>
              </span>
            </div>
          </v-card-actions>
        </v-flex>
      </v-card>
      <!-- Modal -->
      <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
        {{ vSnackBar.text }}
        <v-btn color="pink" flat @click="vSnackBar.visible = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-snackbar>
      <incidencias-modal 
        @close="dialogIncidencia = false"
        :dialog="dialogIncidencia"
        :incidenciaId="incidenciaId"
        :etapaId="etapaId"
      ></incidencias-modal>
    </v-container>
  </v-layout>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from "axios";
  import swal from "sweetalert";

  export default {
    data(){
      return{
        dialog: false,
        dialogIncidencia: false,
        incidenciaId: null,
        incidencias: {},
        etapa: {},
        search: '',
        vPaginator: {
          limit: 10
        },
        vSnackBar: {
          visible: false,
          text: null
        },
        headers:[
          { 
            sortable: false,
            text: 'Nombre', 
          },
          { 
            sortable: false,
            text: 'Descripción', 
          },
          { 
            sortable: false,
            text: 'Horas totales', 
          },
          { 
            sortable: false,
            text: 'Creador', 
          },
          { 
            sortable: false,
            text: 'Acciones', 
          }
        ],
      }
    },
    props: {
      etapaId: String,
    },
    methods: {
      getIncidencias: function(page = 1) {
        axios.get(`/incidencias/owner/${this.etapaId}?page=${page}`)
        .then(response =>  this.incidencias = response.data)
        .catch(error => errorHandling(error));
      },
      openEtapa: function(){
        this.dialog = true;
      },
      getEtapa: function() {
        axios.get(laravelRoute('etapas.show.refactor', this.etapaId))
        .then(response =>  this.etapa = response.data)
        .catch(error => errorHandling(error));
      },
      deleteIncidencia: function(incidenciaId) {
        swal({
          title: "¿Estás seguro?",
          text: "Una vez eliminado no se podrá recuperar",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(result => {
          axios.delete(laravelRoute('incidencias.destroy', incidenciaId))
          .then(response => {
            this.getIncidencias();
            this.getEtapa();
            this.vSnackBar.text = response.data.message_text;
            this.vSnackBar.visible = true;
          })
          .catch(error => errorHandling(error));
        })
      }
    },
    created() {
      this.getIncidencias();
      this.getEtapa();
    }
  };
</script>

<style>
  .fyl-headerTable{
    background: #F9FBFC;
  }
</style>