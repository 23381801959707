var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: { right: true, timeout: _vm.vSnackbar.timeout },
      model: {
        value: _vm.vSnackbar.show,
        callback: function($$v) {
          _vm.$set(_vm.vSnackbar, "show", $$v)
        },
        expression: "vSnackbar.show"
      }
    },
    [
      _vm._v("\n  " + _vm._s(_vm.vSnackbar.message) + "\n  "),
      _c(
        "v-btn",
        {
          attrs: { dark: "", color: _vm.vSnackbar.color, flat: "" },
          on: { click: _vm.vSnackbar.onClick }
        },
        [_vm._v("\n    " + _vm._s(_vm.vSnackbar.actionText) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }