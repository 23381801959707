<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">{{selected_nomina ? 'Editar nómina' : 'Nueva nómina'}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
            <v-layout wrap>
              <v-flex md12>
                  <v-subheader>Datos de la nómina</v-subheader>
              </v-flex>
              <v-flex md12>
                  <v-text-field v-model="nomina.nombre" required label="Nombre"></v-text-field>
              </v-flex>
              <v-flex md12>
                  <v-text-field type="date" v-model="nomina.fecha_creacion" required label="Fecha de creacion"></v-text-field>
              </v-flex>
            </v-layout>
        </v-container>
      </v-card-text>  
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outline dark color="verdeFayal" @click="saveNomina()" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data() {
    return {
      nomina: {},
      localShow: false,
      vBtnSave: {
        loading: false
      },
    }
  },
  props: {
    show: Boolean,
    selected_nomina: Object,
  },
  watch: {
    show: function (value) {
      if (this.selected_nomina) this.nomina = this.selected_nomina;
      this.localShow = value;
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    }
  },
  methods: {
    storeNomina: function(){
      axios.post(laravelRoute('nominas.store'), {
        nombre: this.nomina.nombre,
        fecha_creacion: this.nomina.fecha_creacion
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.nomina = {
          nombre: null,
          fecha_creacion: null,
        };
        this.localShow = (!this.keepStoring) ? false : true;
      })
      .catch(error => {
        errorHandling(error)
      })
      .finally(()=>this.vBtnSave.loading = false);
    },
    updateNomina: function () {
      axios.put(laravelRoute('nominas.update', this.nomina.id), {
        nombre: this.nomina.nombre,
        fecha_creacion: this.nomina.fecha_creacion
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.nomina = {
          nombre: null,
          fecha_creacion: null,
        };
        this.localShow = (!this.keepStoring) ? false : true;
      })
      .catch(error => {
        errorHandling(error)
      })
      .finally(()=>this.vBtnSave.loading = false);
    },
    saveNomina: function () {
      if(this.selected_nomina) this.updateNomina();
      else this.storeNomina();
    }
  },
}
</script>