<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Añadir pago</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-form ref="form">
            <v-layout v-if="trabajador.puesto == 'Destajo'">
              <v-flex md3>
                <v-autocomplete 
                  v-model="trabajador.fraccionamiento_id" 
                  item-value="id" 
                  item-text="nombre_proyecto" 
                  :items="fraccionamientos" 
                  label="Proyecto"
                  @change="getFraccionamiento"
                  :rules="rules"
                ></v-autocomplete>
              </v-flex>
              <v-flex md2>
                <v-select 
                  :disabled="(trabajador.fraccionamiento_id) ? false : true" 
                  :items="['Obra', 'Urbanizacion']" 
                  @change="getEtapas"
                  label="Tipo" 
                  v-model="tipo"
                  :rules="rules"
                ></v-select>
              </v-flex>
              <v-flex md3>
                <v-autocomplete :rules="rules" :disabled="(tipo) ? false : true" v-model="trabajador.etapa" return-object item-value="id" item-text="nombre" :items="filterEtapas" label="Etapas"></v-autocomplete>
              </v-flex>
              <v-flex md4 v-if="trabajador.etapa">
                <v-text-field readonly v-model="trabajador.etapa.fecha_fin" required label="Fecha final de etapa"></v-text-field>
              </v-flex>
            </v-layout>
            <template v-if="trabajador.puesto == 'Destajo' && trabajador.etapa" v-for="(item, index) in desglose">
              <v-layout>
                <v-flex md2>
                  <v-autocomplete 
                    @change="getLotes(index)" 
                    v-model="desglose[index].distribucion" 
                    label="Distribución"
                    :rules="rules"
                    :items="(tipo == 'Obra') ? ['Manzanas'] : ['Manzanas', 'Calles', 'Áreas verdes']"
                  ></v-autocomplete>
                </v-flex>              
                <v-flex md2 v-if="tipo == 'Urbanizacion'">
                  <v-autocomplete 
                    v-if="desglose[index].distribucion  == 'Manzanas' || desglose[index].distribucion  == null"
                    v-model="desglose[index].lote"
                    label="Lote"
                    :rules="rules"
                    item-value="id"
                    item-text="manzana_lotificación.nombre"
                    :items="desglose[index].itemsLote"
                    return-object
                    @change="assingConceptosRubro(index)"
                  ></v-autocomplete>
                  <v-autocomplete 
                    v-if="desglose[index].distribucion  == 'Calles'"
                    v-model="desglose[index].lote"
                    label="Lote"
                    :rules="rules"
                    item-value="calles_lotificación.id"
                    item-text="calles_lotificación.nombre"
                    :items="desglose[index].itemsLote"
                    return-object
                    @change="assingConceptosRubro(index)"
                  ></v-autocomplete>
                  <v-autocomplete 
                    v-if="desglose[index].distribucion  == 'Áreas verdes'"
                    v-model="desglose[index].lote"
                    label="Lote"
                    :rules="rules"
                    item-value="calles_lotificación.id"
                    item-text="area_verde_lotificación.nombre"
                    :items="desglose[index].itemsLote"
                    return-object
                    @change="assingConceptosRubro(index)"
                  ></v-autocomplete>
                </v-flex>
                <v-flex md2 v-else>
                  <v-autocomplete 
                    v-model="desglose[index].lote"
                    label="Lote"
                    item-value="id"
                    :rules="rules"
                    item-text="lote_lotificacion.nombre"
                    :items="desglose[index].itemsLote"
                    return-object
                    @change="assingConceptosRubro(index)"
                  ></v-autocomplete>
                </v-flex>              
                <v-flex md2>
                  <v-autocomplete 
                    v-model="desglose[index].concepto"
                    label="Concepto"
                    item-text="nombre"
                    :rules="rules"
                    :items="desglose[index].itemsConceptos"
                    return-object
                  ></v-autocomplete>
                </v-flex>
                <v-flex md2>
                  <v-autocomplete 
                    label="Rubro"
                    item-text="nombre"
                    :rules="rules"
                    :items="desglose[index].itemsRubros"
                    v-model="desglose[index].rubro"
                    return-object
                    @change="calculateCantidad(index)"
                  ></v-autocomplete>
                </v-flex>
                
                <v-flex md2>
                  <v-text-field :rules="rules" prepend-icon="money-attach" type="number" min="0" v-model="desglose[index].pago" label="Pago"></v-text-field>
                </v-flex>
                <v-flex v-if="index != 0" md2>
                  <v-btn @click="desglose.splice(index, 1)" fab flat dark small  color="verdeFayal" >
                    <v-icon dark>remove</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </template>
            <v-layout wrap>
              <v-flex md6>
                  <v-text-field type="date" :rules="rules" v-model="trabajador.fecha_de_pago" required label="Fecha de pago"></v-text-field>
              </v-flex>
              <v-flex md6 v-if="trabajador.puesto == 'Destajo'">
                  <v-text-field readonly required label="Total" :value="totalDestajo"></v-text-field>
              </v-flex>
              <v-flex md6 v-if="trabajador.puesto == 'Raya'">
                  <v-text-field type="input" :rules="rules" readonly v-model="trabajador.salario" required label="Salario (referencial)"></v-text-field>
              </v-flex>
              <v-flex md6 v-if="trabajador.puesto == 'Raya'">
                  <v-switch color="verdeFayal" label="¿Aplicar prima vacacional (25%)?" v-model="primaVacacional"></v-switch>
              </v-flex>
              <v-flex md6 v-if="trabajador.puesto == 'Raya'">
                  <v-text-field type="number" :rules="rules" :min="0" v-model="trabajador.monto" required label="Monto"></v-text-field>
              </v-flex>
              <v-flex md6 v-if="trabajador.puesto == 'Raya'">
                  <v-text-field type="input" :rules="rules" min="0" readonly v-model="total" required label="Total"></v-text-field>
              </v-flex>
              <v-flex md6>
                <v-autocomplete 
                  v-model="estado"
                  label="Estado"
                  item-value="id"
                  item-text="estado"
                  :items="estados"
                  :rules="rules"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" flat @click="storePayment" :loading="vBtnSave.loading"> Guardar </v-btn>
        <v-btn dark color="verdeFayal" @click="addDesglose" v-if="trabajador.puesto == 'Destajo'">
          <v-icon>add</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    trabajador: {
      salario: 0,
      monto: 0,
      fraccionamiento_id: null,
      etapa: {}
    },
    rules: [
      (v) => !!v || 'Este campo es requerido'
    ],
    tipo: null,
    etapas: [],
    filterEtapas: [],
    fraccionamientos: [],
    fraccionamiento: {
        etapas: []
    },
    templateDesglose: {
      distribucion: null,
      itemsLote: [],
      lote: {},
      itemsConceptos: [],
      concepto: {},
      itemsRubros: [],
      rubro: {},
      pago: 0
    },
    desglose: [
      {
        distribucion: null,
        itemsLote: [],
        lote: {},
        itemsConceptos: [],
        concepto: {},
        itemsRubros: [],
        rubro: {},
        pago: 0
      }
    ],
    estado: null,
    estados: [],
    prima: 0,
    localShow: false,
    primaVacacional: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    workerId: String
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    workerId: function() {
        this.getWorker();
    },
  },
  computed: {
      total: function() {
        var total = 0 + parseInt(this.trabajador.monto);
        if(this.primaVacacional) {
          var prima = (parseInt(this.trabajador.salario) / 100) * 25;
          total = prima + total;            
          this.prima = prima;
        }
        return (isNaN(total)) ? 0 : total;
      },
      totalDestajo: function() {
        var total = 0;

        this.desglose.forEach(function (item) {
          total = total + parseInt(item.pago)
        });

        return (isNaN(total)) ? 0 : total;
      }
  },
  methods: {
    getWorker: function () {
        axios.get(laravelRoute('trabajadores.show', this.workerId))
        .then(response => this.trabajador = response.data)
        .catch(error => errorHandling(error));
    },
    getStatus: function() {
      axios.get('/estatus/pago')
      .then(response => this.estados = response.data)
      .catch(error => errorHandling(error));
    },
    getProspectos: function () {
        axios.get(laravelRoute('fraccionamientos.index'))
        .then(response => this.fraccionamientos = response.data)
        .catch(error => errorHandling(error));
    },
    storePayment: function() {
      if(this.$refs.form.validate()) {
        axios.post(laravelRoute('pagos.store'), {
          trabajador_id: this.workerId,
          fecha_de_pago: this.trabajador.fecha_de_pago,
          monto: this.trabajador.monto,
          total: this.trabajador.total,
          prima: this.prima,
          puesto: this.trabajador.puesto,
          desglose: this.desglose,
          total_desglose: this.totalDesglose,
          estado: this.estado,
          fraccionamiento_id: this.trabajador,
          etapa_id: (this.trabajador.etapa) ? this.trabajador.etapa.id : null,
          fraccionamiento_tipo: this.tipo
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.material = {};
          this.localShow = false;

          this.trabajador.monto = 0;
          this.prima = 0;
          this.fecha_de_pago = null;
          this.localShow = false;
          this.primaVacacional = false;
          this.desglose = [this.templateDesglose];
        })
        .catch(error => {
          errorHandling(error)
        })
        .finally(()=>this.vBtnSave.loading = false);
      }
    },
    getFraccionamiento: function() {
        axios.get(laravelRoute('fraccionamientos.show', this.trabajador.fraccionamiento_id))
        .then(response => {
          this.fraccionamiento = response.data;
          this.etapas = response.data.etapas;
          this.desglose = [{}]
          this.trabajador.etapa = {}
        })
        .catch(error => errorHandling(error));
    },
    getEtapas: function() {
      this.filterEtapas = [];
      this.etapas.forEach(etapa => {
        if(etapa.tipo == this.tipo.toLowerCase()) {
          this.filterEtapas.push(etapa);
        }
      });
    },
    getLotes: function(index) {
      if(this.desglose[index].distribucion == 'Manzanas') {
        this.desglose[index].itemsLote = (this.tipo == "Urbanizacion") ? this.trabajador.etapa.manzanas_etapas : this.trabajador.etapa.lotes;
      }
      else if (this.desglose[index].distribucion == 'Calles') {
        this.desglose[index].itemsLote = this.trabajador.etapa.calles
      }
      else if (this.desglose[index].distribucion == 'Áreas verdes') {
        this.desglose[index].itemsLote = this.trabajador.etapa.areas_verdes
      }
      else {
        return [];
      }
    },
    assingConceptosRubro: function(index) {
      this.desglose[index].itemsConceptos = this.desglose[index].lote.conceptos;
      this.desglose[index].itemsRubros = this.desglose[index].lote.rubros;
    }, 
    calculateCantidad: function(index) {
      this.desglose[index].pago = (this.desglose[index].rubro.valores[0]) ? this.desglose[index].rubro.valores[0].precio_total : 0;
    },
    addDesglose: function() {
      this.desglose.push({
        distribucion: null,
        itemsLote: [],
        lote: {},
        itemsConceptos: [],
        concepto: {},
        itemsRubros: [],
        rubro: {},
        pago: 0
      })
    }
  },
  created: function() {
    this.getProspectos();
    this.getStatus();
  }
}
</script>
