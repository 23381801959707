var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v(
                          _vm._s(
                            _vm.currentPath != "/trabajos_externos"
                              ? "Plan de trabajo de obra"
                              : "Trabajos externos"
                          )
                        )
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      (_vm.$root.can("new", _vm.permissions_concepto)
                      ? true
                      : false)
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "fyl-btnCreate",
                              on: { click: _vm.openAddConceptsModal }
                            },
                            [_vm._v("\n            Crear\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", outline: "" },
                          on: { click: _vm.exportToExcel }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("import_export")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("concepts-index", {
                    attrs: {
                      search: _vm.search,
                      updateConceptos: _vm.showConceptsModal,
                      permissions: _vm.permissions_concepto,
                      permissions_rubro: _vm.permissions_rubro,
                      "current-path": _vm.currentPath
                    },
                    on: {
                      "update:search": function($event) {
                        _vm.search = $event
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("create-concepts-modal", {
                attrs: {
                  show: _vm.showConceptsModal,
                  type:
                    _vm.currentPath != "/trabajos_externos"
                      ? "obra"
                      : "trabajos_externos",
                  permissions: _vm.permissions_rubro
                },
                on: {
                  "update:show": function($event) {
                    _vm.showConceptsModal = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }