<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="1050px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Pagos asociados a {{ area_nomina_trabajador.trabajador.nombre_completo }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-layout>
          <v-flex>
            <v-tabs v-model="vTabs" fixed-tabs centered color="white" slider-color="#1aba24">
              <v-tab href="#tab-index-pagos">
                <span>Pagos asociados</span>
              </v-tab>
              <v-tab href="#tab-agregar-pago">
                <span>Agregar pago</span>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="vTabs">
              <v-tab-item value="tab-index-pagos">
                <v-container>
                  <v-card-text>
                    <v-data-table
                      :items="pagos.data"
                      hide-actions
                      v-if="area_nomina_trabajador.puesto == 'Destajo'"
                    >
                      <template v-slot:headers="props">
                          <tr>
                            <th>Fraccionamiento</th>
                            <th>Etapa</th>
                            <th>Distribución</th>
                            <th>Lote</th>
                            <th>Concepto</th>
                            <th>Rubro</th>
                            <th>Monto</th>
                            <th>Acciones</th>
                          </tr>
                      </template>
                      <template slot="items" slot-scope="props">
                        <tr>
                          <td>{{ props.item.fraccionamiento.nombre_proyecto }}</td>
                          <td>{{ props.item.etapa.nombre }}</td>
                          <td>{{ props.item.distribucion }}</td>
                          <td>{{ (props.item.distribucion == 'Manzana') ? null : "No aplica"}}</td>
                          <td>{{ (props.item.concepto) ? props.item.concepto.nombre : null}}</td>
                          <td>{{ (props.item.rubro) ? props.item.rubro.nombre : null}}</td>
                          <td>$ {{ props.item.monto | currency}}</td>
                          <td>
                            <v-tooltip bottom>
                              <v-btn icon flat dark small slot="activator" color="error" @click="deletePago(props.item.id)">
                                <v-icon>delete</v-icon>
                              </v-btn>
                              <span>Eliminar</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </template> 
                    </v-data-table>

                    <v-data-table
                      :items="pagos.data"
                      hide-actions
                      v-else
                    >
                      <template v-slot:headers="props">
                          <tr>
                            <th>Fraccionamiento</th>
                            <th></th>
                          </tr>
                      </template>
                      <template slot="items" slot-scope="props">
                        <tr>
                          <td>{{ props.item.fraccionamiento.nombre_proyecto }}</td>
                          <td>
                            <v-tooltip bottom>
                              <v-btn icon flat dark small slot="activator" color="error" @click="deletePago(props.item.id)">
                                <v-icon>delete</v-icon>
                              </v-btn>
                              <span>Eliminar</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </template> 
                    </v-data-table>
                    
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <pagination :limit="10" :data="pagos" @pagination-change-page="getPagosNomina"></pagination>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card-text>
                </v-container>
              </v-tab-item>

              <v-tab-item value="tab-agregar-pago">
                <v-container>
                  <v-card-text>
                    <v-card flat outlined v-if="!pago.delete_at">
                      <v-container grid-list-md>
                        <v-layout wrap v-if="area_nombre != 'Contabilidad'">
                          <v-flex md12>   
                            <v-autocomplete
                              v-if="!pago.fraccionamiento_id"
                              :search-input.sync="query"
                              :items="fraccionamientos_nomina"
                              :loading="isLoading"
                              item-text="nombre_proyecto"
                
                              color="verdeFayal"
                              v-model="pago.fraccionamiento.id"
                              persistent-hint
                              item-value="id"
                              label="Fraccionamiento"
                              hide-selected
                              hide-no-data
                              @change="getEtapasNomina(pago.fraccionamiento.id)"
                          >
                          <template v-slot:no-data>
                              <v-list-tile>
                                <v-list-tile-title>
                                  Buscar El 
                                  <strong>Fraccionamiento</strong>
                                  a Seleccionar
                                </v-list-tile-title>
                              </v-list-tile>
                            </template>
                          </v-autocomplete>
                            <v-text-field
                              v-else
                              v-model="pago.fraccionamiento.nombre_proyecto"
                              readonly
                              color="verdeFayal" single-line 
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout wrap v-if="area_nomina_trabajador.puesto == 'Destajo'" class="mb-0">
                            <v-flex md6 class="mb-0">
                              <v-select  
                                :disabled="!pago.fraccionamiento"
                                v-model="pago.etapa_id"
                                :items="etapas_nomina"
                                item-text="nombre"
                                :readonly="readOnly"
                                item-value="id"
                                label="Etapa"
                                color="verdeFayal"
                              />
                            </v-flex>
                            <v-flex md6>
                              <v-select 
                                :disabled="!pago.fraccionamiento"
                                v-model="pago.distribucion"
                                :items="['Manzana', 'Calle', 'Área Verde']"
                                item-text="nombre"
                                item-value="id"
                                :readonly="readOnly"
                                label="Distribución"
                                color="verdeFayal"
                                @change="getManzanasNomina(pago.etapa_id), getCallesNomina(pago.etapa_id), getAreaNomina(pago.etapa_id)"
                              />
                            </v-flex>
                        </v-layout>
                        <v-layout v-if="pago.distribucion == 'Calle'" wrap>
                          <v-flex md12>
                            <v-select 
                              :disabled="!pago.etapa_id"
                              v-model="pago.calle"
                              return-object
                              item-text="nombre"
                              item-value="id"
                              :readonly="readOnly"
                              :items="calles_nomina"
                              label="Calle"
                              color="verdeFayal"
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout v-else-if="pago.distribucion == 'Área Verde'" wrap>
                          <v-flex md12>
                            <v-select 
                              :items="areas_verdes_nomina"
                              :disabled="!pago.etapa_id"
                              v-model="pago.area_verde"
                              :readonly="readOnly"
                              item-text="nombre"
                              color="verdeFayal"
                              label="Área verde"
                              item-value="id"
                              return-object
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout v-else-if="pago.distribucion == 'Manzana'" wrap>
                          <v-flex md12>
                            <v-select 
                              :disabled="!pago.etapa_id"
                              item-text="nombre"
                              v-model="pago.manzanas_lotificacion_id"
                              item-value="id"
                              :readonly="readOnly"
                              :items="manzanas_nomina"
                              color="verdeFayal"
                              label="Manzana"
                
                              @change="getLotesNomina(pago.etapa_id, pago.manzanas_lotificacion_id)"
                            />
                          </v-flex>
                        </v-layout>
                        <v-layout v-if="pago.distribucion" wrap>
                          <v-flex md4 v-if="pago.distribucion == 'Manzana'">
                            <v-select 
                              :disabled="!pago.manzanas_lotificacion_id"
                              v-model="pago.lote_manzana_etapa"
                              item-text="lote.nombre"
                              item-value="id"
                              :readonly="readOnly"
                              color="verdeFayal"
                              return-object
                              :items="pago.manzanas_lotificacion_id > 0
                                ? lotes_nomina : []"
                              label="Lote" 
                
                              @change="getConceptosNomina(pago.etapa_id, pago.manzanas_lotificacion_id ? pago.manzanas_lotificacion_id : 0)"
                            />
                          </v-flex>
                          
                          <v-flex md4>
                            <v-select 
                              :disabled="!pago.lote_manzana_etapa"
                              item-text="nombre"
                              v-model="pago.concepto_id"
                              item-value="id"
                              :readonly="readOnly"
                              color="verdeFayal"
                              :items="(conceptos_nomina.length) ? conceptos_nomina.find(item => item.etapa_manzana_lotificacion_id == pago.manzanas_lotificacion_id).conceptos : []"
                              label="Conceptos" 
                              @change="getRubrosNomina(pago.etapa_id, pago.manzanas_lotificacion_id, pago.concepto_id, area_nomina_trabajador.trabajador.ocupacion.id)"
                            />
                          </v-flex>
                          <v-flex md4>
                            <v-select 
                              :disabled="!pago.concepto_id"
                              item-text="rubro.nombre"
                              v-model="pago.rubro_id"
                              item-value="rubro_id"
                              :readonly="readOnly"
                              color="verdeFayal"
                              :items="rubros_nomina.filter(rubro => rubro.rubro != null && rubro.rubro.valores != null )"
                              label="Rubro"
                
                              @change="getValoresNomina(pago.rubro_id, pago.lote_manzana_etapa ? pago.lote_manzana_etapa.lote.modelo_id : 0, area_nomina_trabajador.trabajador.ocupacion.id)"
                            />
                          </v-flex>
                          <v-flex md12 v-if="pago.lote_manzana_etapa != null && pago.concepto_id != null && pago.rubro_id != null && !readOnly">
                            <span>
                              <!-- {{getValoresNomina(pago.rubro_id, pago.fraccionamiento.id, index)}} -->
                              <!-- {{(pago.precio_sugerido) ? pago.precio_sugerido : 0 | currency}} -->
                              <v-subheader v-if="valor != 0">
                                <span class="verdeFayal--text">Pago Sugerido: ${{valor ? valor : 'Sin Pago Sugerido' | currency }}</span>
                              </v-subheader>
                              <v-subheader v-if="valor == 0">
                                <span class="red--text">Sin Pago Sugerido, Revise los Precios</span>
                              </v-subheader>
                            </span>
                        </v-flex>
                          <v-flex md4>
                            <v-text-field
                              v-model="pago.monto"
                              label="Monto"
                              type="number"
                              :readonly="readOnly"
                              id="id"
                            />
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-card>
                  </v-card-text>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-flex>
        </v-layout>
      </v-card-text> 
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          v-if="!readOnly && vTabs == 'tab-agregar-pago'"
          dark 
          color="verdeFayal" 
          flat 
          :loading="vBtnSave.loading"
          outline
          @click="storePaymentWorker()"
        > 
          Guardar 
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
import Searcher from '../Searcher.vue';
export default {
  components: { Searcher },
  props: {
    selected_area_nomina_trabajador: Object,
    area_nombre: String,
    show: Boolean,
    readOnly: Boolean
  },
  data() {
    return {
      pagos: {
        data: []
      },
      search_fraccionamiento: {
          url: "/fraccionamientos?relationships[]=estado",
          paginatedContainer: 'data',
          noDataText: "No se encontraron resultados.",
          label: "Fraccionamiento",
          itemText: "nombre_proyecto",
          itemValue: "id",
          descriptionLimit: 60,
          disabled: false,
          useHeaders: true,
      },
      vTabs: 'tab-index-pagos',
      fraccionamiento: {},
      isLoading: false,
      query: null,
      area_nomina_trabajador: {
        id: null,
        trabajador: {nombre_completo: ''},
        pagos: [],
        descripcion: null,
      },
      deleted_payments: [],
      localShow: false,
      vBtnSave: {
        loading: false
      },
      valor: null,
      etapas_nomina: [],
      fraccionamientos_nomina: [],
      calles_nomina: [],
      areas_verdes_nomina: [],
      manzanas_nomina: [],
      lotes_nomina: [],
      conceptos_nomina: [],
      rubros_nomina: [],
      pago: {
        fraccionamiento: {}
      },
      vSnackBar: {
        visible: false,
        text: null
      }
    }
  },
  created() {
    
  },
  watch: {
    show: function (value) {
      this.area_nomina_trabajador = this.selected_area_nomina_trabajador;
      this.deleted_payments = [];
      this.localShow = value;
      this.getPagosNomina(this.area_nomina_trabajador.trabajador.id, this.area_nomina_trabajador.id);
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    query: function (value){
      this.getFraccionamientoNomina();
    },
    vTabs: function() {
      this.getPagosNomina(this.area_nomina_trabajador.trabajador.id, this.area_nomina_trabajador.id);
    }
  },
  methods: {
    getData: function() {
      if(this.pago.distribucion == 'Manzana') {
        this.getManzanasNomina(this.pago.etapa_id)
      }
      else if(this.pago.distribucion == 'Área Verde') {
        this.getAreaNomina(this.pago.etapa_id)
      }
      else if(this.pago.distribucion == 'Calle') {
        this.getCallesNomina(this.pago.etapa_id)
      }
    },
     getFraccionamientoNomina: function () {
      axios.get(`/fraccionamientos?relationships[]=estado`)
      .then(response => this.fraccionamientos_nomina = response.data)
      .catch(error => errorHandling(error));
    },
      getEtapasNomina: function (fraccionamiento_id, page = 1) {
      axios.get(`/etapas_nomina?page=${page}&query=${fraccionamiento_id}`)
      .then(response => this.etapas_nomina = response.data.data)
      .catch(error => errorHandling(error));
    },
    getManzanasNomina: function (etapa_id, page = 1) {
      axios.get(`/manzanas_nomina?page=${page}&query=${etapa_id}`)
      .then(response => this.manzanas_nomina = response.data)
      .catch(error => errorHandling(error));
    },
    getCallesNomina: function (etapa_id, page = 1) {
      axios.get(`/calles_nomina?&etapa_id=${etapa_id}`)
      .then(response => this.calles_nomina = response.data)
      .catch(error => errorHandling(error));
    },
    getAreaNomina: function (etapa_id, page = 1) {
      axios.get(`/areas_verdes_nomina?page=${page}&etapa_id=${etapa_id}`)
      .then(response => this.areas_verdes_nomina = response.data)
      .catch(error => errorHandling(error));
    },
    getLotesNomina: function (etapa_id, manzana_id, page = 1) {
      axios.get(`/lotes_nomina?page=${page}&manzana_id=${manzana_id}&etapa_id=${etapa_id}`)
      .then(response => this.lotes_nomina = response.data.data)
      .catch(error => errorHandling(error));
    },
    getConceptosNomina: function (etapa_id, manzana_id, page = 1) {
      axios.get(`/conceptos_nomina?page=${page}&manzana_etapa_id=${manzana_id}&etapa_id=${etapa_id}`)
      .then(response => this.conceptos_nomina = response.data.data)
      .catch(error => errorHandling(error));
    },
    getRubrosNomina: function (etapa_id, manzana_id, concepto_id, ocupacion_id, page = 1) {
      axios.get(`/rubros_nomina?etapa_id=${etapa_id}&page=${page}&manzana_id=${manzana_id}&concepto_id=${concepto_id}&ocupacion_id=${ocupacion_id}`)
      .then(response => this.rubros_nomina = response.data.data)
      .catch(error => errorHandling(error));
    },
     getValoresNomina: function (rubro_id, valor_id, ocupacion_id) {
      axios.get(`/valores_nomina?rubro_id=${rubro_id}&valor_id=${valor_id}&ocupacion_id=${ocupacion_id}`)
      .then(response => this.valor = response.data)
      .catch(error => errorHandling(error));
    },
    getPagosNomina: function (trabajador_id, area_nomina_id, page = 1) {
      axios.get(`/pagos_nomina/${trabajador_id}?&query=${area_nomina_id}&page=${page}`)
      .then(response => this.pagos = response.data)
      .catch(error => errorHandling(error));
    },
    storePaymentWorker: function () {
      this.vBtnSave.loading = true;
      console.log('conncepto', this.pago.concepto_id);
      axios.post(laravelRoute('pagos.store'), {
        puesto: this.area_nomina_trabajador.puesto,
        area_nomina_id: this.area_nomina_trabajador.area_nomina_id,
        area_nomina_trabajador_id: this.selected_area_nomina_trabajador.id, 
        trabajador_id: this.selected_area_nomina_trabajador.trabajador_id,
        fraccionamiento_id: this.pago.fraccionamiento.id,
        etapa_id: this.pago.etapa_id,
        manzana_etapa_id: this.pago.manzanas_lotificacion_id,
        distribucion: this.pago.distribucion,
        lote_manzana_etapa: (this.pago.lote_manzana_etapa) ? this.pago.lote_manzana_etapa.id : null,
        calle_id: (this.pago.calle) ? this.pago.calle.calles_lotificacion_id : null,
        calle_etapa_id: (this.pago.calle) ? this.pago.calle.id : null,
        area_verde_id: (this.pago.area_verde) ? this.pago.area_verde.areas_verdes_lotificacion_id : null,
        area_verde_etapa_id: (this.pago.area_verde) ? this.pago.area_verde.id : null,
        lote_id: (this.pago.lote_manzana_etapa) ? this.pago.lote_manzana_etapa.lote_manzana_lotificacion_id : null,
        concepto_id: this.pago.concepto_id,
        rubro_id: this.pago.rubro_id,
        monto: this.pago.monto,
        ocupacion_id: this.area_nomina_trabajador.trabajador.ocupacion_id,
      })
      .then(response => {
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;
        this.pago = {};
        this.pago = {
          fraccionamiento: {}
        }
      })
      .catch(error => errorHandling(error))
      .finally(() => this.vBtnSave.loading = false);
    },
    editPaymentWorker: function () {
      
    },
    destroyPayments: function () {
      this.deleted_payments.forEach(pago => {
        axios.delete(laravelRoute('pagos.destroy', pago.id))
        .then(response => alertify.success(response.data.message_text))
        .catch(error => errorHandling(error));
      });
    },
    savePayments: function (pago) {
      this.destroyPayments();
      this.area_nomina_trabajador.pagos.forEach(pago => {
        if (pago.id) this.editPaymentWorker(pago);
        else this.storePaymentWorker(pago);
        this.localShow = false;
      });
    },
    addPago: function () {
      let pago = {
        id: null,
        area_nomina_trabajador_id: this.area_nomina_trabajador.id,
        etapa_id: 0,
        trabajador_id: this.area_nomina_trabajador.trabajador.id,
        fraccionamiento: {},
        fraccionamiento_id: null,
        puesto: this.area_nomina_trabajador.puesto,
        monto: null,
        distribucion: null,
      };
      this.area_nomina_trabajador.pagos.push(pago);
    },
    removePago: function (pago) {
      this.deleted_payments.push(pago);
    },
    deletePago: function(pagoId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('pagos.destroy', pagoId))
            .then(response => {
              this.getPagosNomina(this.area_nomina_trabajador.trabajador.id, this.area_nomina_trabajador.id);
              this.vSnackBar.text = response.data.message_text;
              this.vSnackBar.visible = true;
            })
            .catch(error =>errorHandling(error));
        }
      })
    }
  }
}
</script>