<template>
  <v-layout>
    <v-container>
      <v-card>
        <v-flex xs12>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Usuarios</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <v-btn class="fyl-btnCreate" @click="openCreateModal">Crear</v-btn>
          </v-toolbar>
          <v-data-table :headers="vHeaders" disable-initial-sort :items="users.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.email }}</td>
              <td>
                <v-chip v-for="(role, index) in props.item.roles" :key="index" class="ma-2" color="verdeFayal" outline small>
                  {{role.name}}
                </v-chip>
              </td>
              <td>
                <v-tooltip bottom>
                  <v-btn icon flat dark small slot="activator" color="info" @click="openEditModal(props.item.id)">
                    <v-icon color="verdeFayal">edit</v-icon>
                  </v-btn>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn icon flat dark small slot="activator" color="error" @click="deleteUser(props.item.id)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="users" @pagination-change-page="getUsers"></pagination>
          </v-card-actions>
        </v-flex>
      </v-card>
    </v-container>
    <!-- Modal -->
    <create-users-modal :show.sync="showCreateModal"></create-users-modal>
    <edit-user-modal :show.sync="showEditModal" :user-id="currentUserId"></edit-user-modal>
  </v-layout>
</template>

<script>
import swal from "sweetalert";

export default {
  data: () => ({
    search: null,
    users: {
      data: []
    },
    showCreateModal: false,
    showEditModal: false,
    currentUserId: null,
    vPaginator: {
      limit: 10
    },
    vHeaders: [{
        text: 'Nombre',
        value: 'nombre'
      },
      {
        text: 'Email',
        value: 'email'
      },
      {
        text: 'Roles',
        value: 'roles'
      },
      {
        text: 'Acciones',
        value: 'acciones',
        sortable: false
      }
    ],
  }),
  watch: {
    search: function (value) {
      this.getUsers();
    },
    showCreateModal: function (value) {
      if (!value) {
        this.getUsers();
      }
    },
    showEditModal: function (value) {
      if (!value) {
        this.getUsers();
      }
    }
  },
  methods: {
    getUsers: function (page = 1) {
      axios.get(`/usuarios?page=${page}&query=${this.search}`)
        .then(response => {
          this.users = response.data;
        })
        .catch(error => errorHandling(error));
    },
    openCreateModal: function() {
      this.showCreateModal = true;
    },
    openEditModal: function (userId) {
      this.showEditModal = true;
      this.currentUserId = userId;
    },
    deleteUser: function (usuarioId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('usuarios.destroy', usuarioId))
            .then(response => {
              this.getUsers();
              alertify.success(response.data.message_text);
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      })
    },
  },
  created() {
    this.getUsers();
  }
}
</script>
