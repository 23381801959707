var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("\n        Renovar/Cambiar contrato\n      ")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.contractsTypes,
                                  "item-text": "periodo",
                                  "item-value": "id",
                                  label: "Tipo de contrato"
                                },
                                model: {
                                  value: _vm.contratoTrabajador.contrato_id,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contratoTrabajador,
                                      "contrato_id",
                                      $$v
                                    )
                                  },
                                  expression: "contratoTrabajador.contrato_id"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "prepend-icon": "calendar_today",
                                  type: "date",
                                  label: "Fecha de contratación",
                                  readonly: ""
                                },
                                model: {
                                  value: _vm.contratoTrabajador.fecha_inicio,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contratoTrabajador,
                                      "fecha_inicio",
                                      $$v
                                    )
                                  },
                                  expression: "contratoTrabajador.fecha_inicio"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  "prepend-icon": "calendar_today",
                                  type: "date",
                                  label: "Nueva fecha de finalizacion"
                                },
                                model: {
                                  value: _vm.contratoTrabajador.fecha_fin,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.contratoTrabajador,
                                      "fecha_fin",
                                      $$v
                                    )
                                  },
                                  expression: "contratoTrabajador.fecha_fin"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-flex", { attrs: { md12: "" } }, [
                        _c("div", { staticClass: "wrapper-table" }, [
                          _c("span", { staticClass: "table-title" }, [
                            _vm._v("Historial")
                          ]),
                          _vm._v(" "),
                          _c("table", { staticClass: "table-history" }, [
                            _c("thead", { staticClass: "table-head-history" }, [
                              _c("tr", [
                                _c("th", [_vm._v("Contrato")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Fecha")])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              { staticClass: "table-body-history" },
                              _vm._l(_vm.contratoTrabajador.bitacoras, function(
                                bitacora,
                                index
                              ) {
                                return _c("tr", { key: index }, [
                                  _c("td", [
                                    _vm._v(_vm._s(bitacora.contrato.periodo))
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(_vm._s(bitacora.created_at))
                                  ])
                                ])
                              }),
                              0
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.renovate()
                        }
                      }
                    },
                    [_vm._v("Renovar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }