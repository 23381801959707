<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card flat>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Añadir valores de compra</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout row wrap>
              <v-flex xs12 sm6 md3>
                <v-text-field
                  label="Precio de compra"
                  v-model="material.compra.precio_compra"
                  type="number"
                  step=".01"
                  min="1"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md3>
                <v-text-field
                  label="Fecha de llegada"
                  v-model="material.fecha_de_llegada"
                  type="date"
                ></v-text-field>
              </v-flex>

              <v-flex>
                <searcher
                  :configuration="searchProvider"
                  :itemSelected.sync="material.proveedor"
                ></searcher>
              </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark :disabled="material.length ? true : false" color="verdeFayal" outline @click="(compra) ? updateShop() : storeShop()" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import { errorHandling, laravelRoute } from "../../../lib/utilities";

export default {
  data: () => ({
    material: {
      compra: {
        precio_compra: 0,
      },
    },
    compra: {},
    localShow: false,
    searchProvider: {
      url: "/proveedores",
      paginatedContainer: 'data',
      noDataText: "No se encontraron resultados.",
      label: "Proveedor",
      itemText: "nombre_completo",
      itemValue: "id",
      descriptionLimit: 60,
      disabled: false,
      useHeaders: true,
    },
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    materialId: Number,
    requisicionId: Number,
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    materialId: function() {
      this.getMaterialRequisicion();
    }
  },
  methods: {
    getMaterialRequisicion: function() {
      axios.get(`/requisiciones/materiales/${this.requisicionId}?material_id=${this.materialId}`)
      .then(response => {
        this.material = response.data; 
        this.compra = response.data.compra;
        this.material.compra = (this.compra) ? response.data.compra :  { precio_compra: 0};
      }) 
      .catch( error => errorHandling(error));
    },
    storeShop: function() {
      axios.post(laravelRoute('compras.store'), {
        material_id: this.materialId,
        proveedor: this.material.proveedor,
        fecha_de_llegada: this.material.fecha_de_llegada,
        precio_compra: this.material.compra.precio_compra,
        requisicion_id: this.requisicionId
      })
      .then(response => {
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;
      }) 
      .catch( error => errorHandling(error));
    },
    updateShop: function() {
      axios.patch(laravelRoute('compras.update', this.compra.id), {
        material_id: this.materialId,
        proveedor: this.material.proveedor,
        fecha_de_llegada: this.material.fecha_de_llegada,
        precio_compra: this.material.compra.precio_compra,
        requisicion_id: this.requisicionId
      })
      .then(response => {
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;
      }) 
      .catch( error => errorHandling(error));
    }
  }
}
</script>
