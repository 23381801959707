var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Asignar costos de mano de obra")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        color: "verdeFayal",
                        icon: "",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        slot: "extension",
                        centered: "",
                        color: "white",
                        "slider-color": "verdeFayal"
                      },
                      slot: "extension",
                      model: {
                        value: _vm.vTabs,
                        callback: function($$v) {
                          _vm.vTabs = $$v
                        },
                        expression: "vTabs"
                      }
                    },
                    [
                      _c("v-tab", { attrs: { href: "#tab-index-valores" } }, [
                        _vm._v("Costos de mano de obra")
                      ]),
                      _vm._v(" "),
                      _c("v-tab", { attrs: { href: "#tab-add-costos" } }, [
                        _vm._v("Añadir costo de mano de obra")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.vTabs,
                            callback: function($$v) {
                              _vm.vTabs = $$v
                            },
                            expression: "vTabs"
                          }
                        },
                        [
                          _c(
                            "v-tab-item",
                            { attrs: { value: "tab-index-valores" } },
                            [
                              _c("v-data-table", {
                                attrs: {
                                  items: _vm.currentValues.data,
                                  "hide-actions": ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "headers",
                                    fn: function(props) {
                                      return [
                                        _c("tr", [
                                          _c("th", [_vm._v("Ocupación")]),
                                          _vm._v(" "),
                                          _c("th", [_vm._v("Precio")]),
                                          _vm._v(" "),
                                          _c("th", [_vm._v("Cantidad")]),
                                          _vm._v(" "),
                                          _c("th", [_vm._v("Total")]),
                                          _vm._v(" "),
                                          _c("th")
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "items",
                                    fn: function(props) {
                                      return [
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                props.item.ocupacion
                                                  ? props.item.ocupacion.nombre
                                                  : "Aún sin especificar"
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "$ " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    props.item.precio_unitario
                                                  )
                                                )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("currency")(
                                                  props.item.cantidad
                                                )
                                              )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "$ " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    props.item.precio_total
                                                  )
                                                )
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "v-tooltip",
                                                { attrs: { bottom: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        slot: "activator",
                                                        icon: "",
                                                        flat: "",
                                                        dark: "",
                                                        small: "",
                                                        color: "error"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteValue(
                                                            props.item.id
                                                          )
                                                        }
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("delete")
                                                      ])
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v("Eliminar")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tab-item",
                            { attrs: { value: "tab-add-costos" } },
                            [
                              _c(
                                "v-form",
                                {
                                  ref: "form",
                                  attrs: { "lazy-validation": "" },
                                  model: {
                                    value: _vm.valid,
                                    callback: function($$v) {
                                      _vm.valid = $$v
                                    },
                                    expression: "valid"
                                  }
                                },
                                [
                                  _vm._l(_vm.values, function(value, index) {
                                    return [
                                      _c(
                                        "v-layout",
                                        { key: index, attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { md4: "" } },
                                            [
                                              _c("searcher", {
                                                attrs: {
                                                  configuration:
                                                    _vm.searchOcupaciones,
                                                  itemSelected:
                                                    value.ocupacion_id
                                                },
                                                on: {
                                                  "update:itemSelected": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      value,
                                                      "ocupacion_id",
                                                      $event
                                                    )
                                                  },
                                                  "update:item-selected": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      value,
                                                      "ocupacion_id",
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md2: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: _vm.rules,
                                                  label: "Precio",
                                                  min: "1",
                                                  type: "number"
                                                },
                                                model: {
                                                  value: value.precio_unitario,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      value,
                                                      "precio_unitario",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "value.precio_unitario"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md2: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  rules: _vm.rules,
                                                  label: "Cantidad",
                                                  min: "0.1",
                                                  type: "number"
                                                },
                                                model: {
                                                  value: value.cantidad,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      value,
                                                      "cantidad",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "value.cantidad"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md2: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  readonly: "",
                                                  label: "Total",
                                                  value: _vm._f("currency")(
                                                    _vm.totalNeto(
                                                      value.precio_unitario *
                                                        value.cantidad
                                                    )
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md1: "" } },
                                            [
                                              index != 0
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        fab: "",
                                                        flat: "",
                                                        dark: "",
                                                        small: "",
                                                        color: "red"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.values.splice(
                                                            index,
                                                            1
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { dark: "" } },
                                                        [_vm._v("remove")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-divider", { key: index })
                                    ]
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.vTabs == "tab-add-costos"
                    ? _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal" },
                          on: { click: _vm.addValue }
                        },
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.vTabs == "tab-add-costos"
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            outline: "",
                            dark: "",
                            color: "verdeFayal",
                            flat: "",
                            loading: _vm.vBtnSave.loading
                          },
                          on: { click: _vm.storeValues }
                        },
                        [_vm._v(" Guardar ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }