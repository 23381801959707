var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Nóminas")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      (_vm.$root.can("new", _vm.permissions)
                      ? true
                      : false)
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "fyl-btnCreate",
                              on: { click: _vm.openCreateModal }
                            },
                            [_vm._v("Crear")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.vHeaders,
                      "disable-initial-sort": "",
                      items: _vm.nominas.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", [_vm._v(_vm._s(props.item.nombre))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(props.item.fecha_creacion))
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.estatus))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.total))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          icon: "",
                                          flat: "",
                                          dark: "",
                                          small: "",
                                          color: "verdeFayal",
                                          href: "/nominas/" + props.item.id
                                        },
                                        slot: "activator"
                                      },
                                      [_c("v-icon", [_vm._v("visibility")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Ver/editar datos")])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          disabled: !_vm.$root.can(
                                            "update",
                                            _vm.permissions
                                          ),
                                          icon: "",
                                          flat: "",
                                          dark: "",
                                          small: "",
                                          color: "primary"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openEditModal(props.item)
                                          }
                                        },
                                        slot: "activator"
                                      },
                                      [_c("v-icon", [_vm._v("edit")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Editar")])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          icon: "",
                                          flat: "",
                                          dark: "",
                                          small: "",
                                          color: "verdeFayal"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.exportToExcel(
                                              props.item.id
                                            )
                                          }
                                        },
                                        slot: "activator"
                                      },
                                      [_c("v-icon", [_vm._v("import_export")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Exportar a Excel")])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.nominas
                        },
                        on: { "pagination-change-page": _vm.getNominas }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("save-nominas-modal", {
                attrs: {
                  show: _vm.showSaveModal,
                  selected_nomina: _vm.currentNomina
                },
                on: {
                  "update:show": function($event) {
                    _vm.showSaveModal = $event
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }