<template>
  <v-layout>
    <v-dialog :value="dialog" persistent max-width="700px" fullscreen hide-overlay>
      <v-card>
        <v-toolbar dark color="verdeFayal">
          <v-btn
            icon
            dark
            @click="$emit('close');"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Fraccionamiento: {{ localFraccionamiento.prospecto.nombre_proyecto }}</v-toolbar-title>
        </v-toolbar>
          <v-card-text>
            <v-layout wrap>
              <v-expansion-panel focusable>
                <v-expansion-panel-content
                  v-for="(manzana, manzanasIndex) in localFraccionamiento.prospecto.propuestas_lotificacion[0].manzanas"
                  :key="manzanasIndex"
                >
                  <template v-slot:header>
                    <div>Manzana: {{manzana.nombre}}</div>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="manzana.lotes"
                        class="elevation-1"
                      > 
                        <template slot="items" slot-scope="props">
                          <td>{{ props.item.nombre }}</td>
                          <td>{{ props.item.modelo }}</td>
                          <td>{{ props.item.prototipo_vivienda.plantas }}</td>
                          <td>{{ props.item.prototipo_vivienda.recamaras }}</td>
                          <td>{{ props.item.prototipo_vivienda.baños }}</td>
                          <td>
                            <v-switch color="verdeFayal" :label='props.item.promocion_tipo ? "Externo" : "Fayal" ' v-model="props.item.promocion_tipo"></v-switch>
                          </td>
                          <td>
                            <v-text-field
                              color="verdeFayal"
                              label="Precio"
                              type="number"
                              min="1"
                              v-model="props.item.precio_total"
                            ></v-text-field>
                          </td>
                          <td v-if="props.item.estado">{{props.item.estado.information.estado}}</td>
                          <td v-if="props.item.estado">
                            <v-tooltip bottom>
                              <v-btn
                                v-if="props.item.estado.information.estado != 'Por promocionar'"
                                flat
                                icon
                                slot="activator"
                                color="verdeFayal"
                                @click="openClienteModal(props.item.id)"
                              >
                                <v-icon>local_atm</v-icon>
                              </v-btn>
                              <span>Manejar datos de venta</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <v-btn icon flat dark small slot="activator" color="warning" @click="openFilesModal(props.item.id)">
                                <v-icon>folder</v-icon>
                              </v-btn>
                              <span>Archivos</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <v-btn
                                flat
                                icon
                                slot="activator"
                                color="warning"
                                @click="promocionarCreate(props.item)"
                              >
                                <v-icon>near_me</v-icon>
                              </v-btn>
                              <span>Promocionar</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <v-btn
                                flat
                                icon
                                slot="activator"
                                color="error"
                                @click="despromocionar(props.item)"
                              >
                                <v-icon>near_me</v-icon>
                              </v-btn>
                              <span>Retirar promocion</span>
                            </v-tooltip>
                          </td>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-layout>
          </v-card-text>
      </v-card>
    </v-dialog>
    <cliente-promocion-modal :show.sync="showClienteModal" :lote-id.sync="currentLoteId"></cliente-promocion-modal>
    <upload-files-modal @close="showFilesModal = false" element_type="App\LoteManzanaLotificacion" :element_id="currentLoteId" :dialog="showFilesModal"></upload-files-modal>
  </v-layout>
</template>

<script>
import { errorHandling } from "../../../lib/utilities";
export default {
  props: ["dialog", 'fraccionamientoId'],

  data() {
    return {
      headers:[
        { text: 'Nombre' },
        { text: 'Modelo' },
        { text: 'Plantas' },
        { text: 'Recamaras' },
        { text: 'Baños' },
        { text: 'Tipo de venta' },
        { text: 'Precio' },
        { text: 'Estado' },
        { text: 'Acciones', sortable: false },
      ],
      showClienteModal: false,
      currentLoteId: null,
      localFraccionamiento: {
        prospecto: {
          propuestas_lotificacion: [{}]
        }
      },
      showFilesModal: false,
    };
  },
  watch:{
    fraccionamientoId: function(value) {
      this.getFraccionamiento();
    }
  },
  methods: {
    promocionarCreate: function (element){
      axios.patch(route("promociones.update", element.id), {
        fraccionamiento: this.localFraccionamiento,
        precio: element.precio_total,
        promocion_tipo: element.promocion_tipo,
        lote_id: element.id,
      })
        .then(response => {
          alertify.success(response.data.message_text);
          this.updateFraccionamiento();
          console.log('response.data?: ', response.data);
        })
        .catch(error => errorHandling(error));
    },
    despromocionar: function (element){
      axios.patch(route("promociones.despromocionar", element.id))
        .then(response => {
          // if (response.data.message_text)
            // alertify.success(response.data.message_text);
          this.updateFraccionamiento();
          console.log('despromocionar?: ', response.data);
        })
        .catch(error => errorHandling(error));
    },
    updateFraccionamiento: function(){
      axios.get(`/fraccionamientos/${this.localFraccionamiento.id}`)
        .then(response => {
          this.localFraccionamiento = response.data;
          console.log('this.localFraccionamiento' ,this.localFraccionamiento);
        })
        .catch(error => errorHandling(error));
    },
    openClienteModal: function(lote_id) {
      this.currentLoteId = lote_id;
      this.showClienteModal = true;
    },
    openFilesModal: function(lote_id) {
      this.showFilesModal = true;
      this.currentLoteId = lote_id;
    },
    getFraccionamiento: function() {
      axios.get("/fraccionamientos/"+this.fraccionamientoId)
      .then(response => {
        this.localFraccionamiento = response.data;
      })
      .catch(error => errorHandling(error));
    },
  }
};
</script>