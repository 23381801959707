<template>
  <div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Editar maquinaria</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="vTabs" centered slider-color="verdeFayal">
            <v-tab href="#datos-maquinaria-tab">Maquinaria</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="vTabs">
        <v-tab-item value="datos-maquinaria-tab">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex md6>
                <v-text-field v-model="maquinaria.nombre" required label="Nombre de la máquina"></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  :disabled="(maquinaria.proyecto_actual) ? true : false"
                  color="verdeFayal"
                  :items="estados"
                  item-value="id"
                  item-text="estado"
                  label="Seleccione status"
                  v-model="maquinaria.estado.information.id"
                ></v-select>
              </v-flex>
              <v-flex md12>
                <v-text-field v-model="maquinaria.descripcion" label="Descripción" counter="250" type="text" min="0" max="250"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-form ref="form" lazy-validation v-if="maquinaria.mantenimientos">
                <v-container grid-list-md v-for="(mantenimiento, index) in maquinaria.mantenimientos" :key="index">
                  <v-flex sm12 xs12 md12>
                    <v-layout wrap>
                      <v-flex xs12 sm4 md4>
                        <v-select
                          :items="mantenimientoslistProps"
                          v-model="mantenimiento.mantenimiento_id"
                          color="verdeFayal"
                          item-value="id"
                          item-text="nombre"
                          label="Seleccione mantenimiento"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 sm3 md3>
                        <v-select
                          v-model="mantenimiento.estado.information.id"
                          :items="statusMantenimiento"
                          color="verdeFayal"
                          item-value="id"
                          item-text="estado"
                          label="Seleccione status"
                        ></v-select>
                      </v-flex>
                      <v-flex xs10 sm3 md3>
                        <v-text-field v-model="mantenimiento.fecha" color="#1ABA24" label="Fecha de inicio de mantenimiento" type="date"></v-text-field>
                      </v-flex>
                      <v-flex xs1 sm1 md1>
                        <v-btn v-if="index != 0" @click="maquinaria.mantenimientos.splice(index, 1)" fab flat dark small color="verdeFayal">
                            <v-icon dark>remove</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-container>
              </v-form>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs-items>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="updateMaquinaria" flat :loading="vBtnSave.loading"> Guardar </v-btn>
        <v-btn dark color="verdeFayal" @click="addMateriales">
          <v-icon>add</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        mantenimientoslistProps: this.mantenimientoslist,
        statusMantenimiento: [],
        proyecto_perteneciente: {
          operador: null,
            horas_trabajadas: null,
            costo_operador: null,
            gasto_diesel: null,
            fecha_termino: null,
            slug: 'lorem'
        },
        estados: [
          {id:5, estado:'Disponible'},
          {id:6, estado: 'Averiado'},
          {id:7, estado: 'Mantenimiento'},
          {id:8, estado: 'Ocupada', disabled: true}
        ],
        maquinaria: {
          proyecto_actual: {
            operador: null,
            horas_trabajadas: null,
            costo_operador: null,
            gasto_diesel: null,
            fecha_termino: null,
            slug: 'lorem'
          },
          estado:{
            information:{
              estado:''
            }
          },
          mantenimientos: [{
            id: null,
            mantenimiento_id: null,
            estado:{
              information:{
                estado:''
              }
            },
            fecha: null
          }],
        },
        mantenimiento: {
          estado:{
            information:{
              estado:''
            }
          }
        },
        status: [],
        localShow: false,
        vTabs: 'datos-maquinaria-tab',
         headers: [
          { text: 'Proyecto', value: 'proyecto' },
          { text: 'Operador', value: 'operador' },
          { text: 'Horas trabajadas', value: 'horas' },
          { text: 'Costo de operador por hora', value: 'costo_operador' },
          { text: 'Gasto de diesel', value: 'gasto_diesel' },
          { text: 'Fecha de término', value: 'fecha_termino' },
          { text: 'Total de diesel', value: 'diesel' },
        ],
        vBtnSave: {
          loading: false
        },
        vSnackBar: {
          visible: false,
          text: null
        }
      }
    },
    props: {
      show: Boolean,
      prospectos: Array,
      maquinariaId: Number,
      mantenimientoslist: Array
    },
    watch: {
      show: function (value) {
        this.localShow = value
      },
      maquinariaId: function (value) {
        this.getMaquinaria();
        this.getStatus();
      },
      localShow: function (value) {
        this.$emit('update:show', value);
        this.getStatusMantenimiento();
      }
    },
    methods: {
      getMaquinaria: function () {
      axios.get(laravelRoute('maquinaria.show', this.maquinariaId))
        .then(response => {
          this.maquinaria = response.data;
          this.proyecto_perteneciente = (this.maquinaria.proyecto_actual) ? this.maquinaria.proyecto_actual : null; 
        })
        .catch(error => errorHandling(error));
      },
      getStatusMantenimiento: function(){
        axios
        .get(laravelRoute('mantenimientos.estados',{query: this.maquinaria.id}))
        .then(response => {
          this.statusMantenimiento = response.data;
        })
        .catch(error => errorHandling(error));
      },
      updateMaquinaria: function () {
        this.vBtnSave.loading = true;
          axios.patch('/maquinaria/' + this.maquinariaId, {
            nombre: this.maquinaria.nombre,
            descripcion: this.maquinaria.descripcion,
            estado: this.maquinaria.estado.information.id,
            mantenimientos: this.maquinaria.mantenimientos.filter(mantenimiento => mantenimiento.mantenimiento_id &&  mantenimiento.estado && mantenimiento.fecha)
          })
          .then(response => {
            alertify.success(response.data.message_text);
            this.vBtnSave.loading = false;
            this.localShow = false;
          })
          .catch(error => {
            this.vBtnSave.loading = false;
            errorHandling(error)
          });
        },
        getStatus: function(){
          axios.get("/maquinaria/estados").then(response => {
            this.status = response.data;
          })
          .catch(error => errorHandling(error));
        },
        addMateriales: function() {
          this.maquinaria.mantenimientos.push({
            mantenimiento_id: null,
            estado: {
              information:{
                estado:''
              }
            },
            fecha: null
          });
        },
    },
    created(){
      this.getMaquinaria();
    }
  }
</script>