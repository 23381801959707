var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-toolbar",
            {
              attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" }
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                icon: "",
                                href: "/fraccionamientos"
                              }
                            },
                            [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_vm._v(" "), _c("span", [_vm._v("Regresar")])]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", "pb-2": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs10: "", "offset-xs1": "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card--flex-toolbar" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { card: "", prominent: "" } },
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-toolbar-title",
                            { staticClass: "body-2 grey--text" },
                            [
                              _vm._v(
                                "Ventas de lotes de " +
                                  _vm._s(_vm.fraccionamiento.nombre_proyecto)
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-spacer")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-data-table", {
                                    ref: "manzanasTable",
                                    attrs: {
                                      "hide-actions": "",
                                      items: _vm.manzanas.data,
                                      expand: _vm.expandManzana
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "headers",
                                        fn: function(props) {
                                          return [
                                            _c("tr", [
                                              _c("th", [_vm._v("Nombre")]),
                                              _vm._v(" "),
                                              _c("th", [
                                                _vm._v("Total de lotes")
                                              ]),
                                              _vm._v(" "),
                                              _c("th", {
                                                staticClass: "text-xs-right"
                                              })
                                            ])
                                          ]
                                        }
                                      },
                                      {
                                        key: "items",
                                        fn: function(props) {
                                          return [
                                            _c("tr", [
                                              _c("td", [
                                                _vm._v(
                                                  "Manzana " +
                                                    _vm._s(props.item.nombre)
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "badge badge-secondary",
                                                    staticStyle: {
                                                      "background-color":
                                                        "#ff5617"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                    " +
                                                        _vm._s(
                                                          props.item.lotes_count
                                                        ) +
                                                        "\n                                                "
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-right"
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        flat: "",
                                                        icon: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.openLotesExpand(
                                                            props.item.id,
                                                            props.index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.expanded
                                                              ? "expand_less"
                                                              : "expand_more"
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ])
                                          ]
                                        }
                                      },
                                      {
                                        key: "expand",
                                        fn: function(props) {
                                          return [
                                            _c(
                                              "table",
                                              {
                                                staticClass:
                                                  "table table-striped table-hover table-sm"
                                              },
                                              [
                                                _c("thead", [
                                                  _c("tr", [
                                                    _c("th", [_vm._v("Lote")]),
                                                    _vm._v(" "),
                                                    _c("th", [
                                                      _vm._v("Estado")
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("th", [
                                                      _vm._v(
                                                        "Porcentaje de avance"
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("th", [
                                                      _vm._v(
                                                        "Estado de construcción"
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("th", [
                                                      _vm._v(
                                                        "¿Hay trabajos externos?"
                                                      )
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("th", [
                                                      _vm._v("Acciones")
                                                    ])
                                                  ])
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "tbody",
                                                  _vm._l(
                                                    _vm.manzanas.data[
                                                      props.index
                                                    ].lotes,
                                                    function(lote, index) {
                                                      return _c(
                                                        "tr",
                                                        { key: index },
                                                        [
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                lote.nombre
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                lote.estado
                                                                  ? lote.estado
                                                                      .information
                                                                      .estado
                                                                  : "Sin promocionar"
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "progress"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "progress-bar progress-bar-striped progress-bar-animated bg-success",
                                                                    style:
                                                                      "width: " +
                                                                      lote.porcentaje_de_avance +
                                                                      "%;",
                                                                    attrs: {
                                                                      role:
                                                                        "progressbar",
                                                                      "aria-valuenow":
                                                                        lote.porcentaje_de_avance,
                                                                      "aria-valuemin":
                                                                        "0",
                                                                      "aria-valuemax":
                                                                        "100"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                                " +
                                                                        _vm._s(
                                                                          lote.porcentaje_de_avance.toFixed(
                                                                            2
                                                                          )
                                                                        ) +
                                                                        " %\n                                                            "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                lote.estado_de_construccion
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("td", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  lote.trabajos_externos
                                                                    ? "Si"
                                                                    : "No"
                                                                ) +
                                                                " "
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "td",
                                                            [
                                                              _c(
                                                                "v-layout",
                                                                [
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            slot:
                                                                              "activator",
                                                                            disabled:
                                                                              !lote.estado ||
                                                                              lote
                                                                                .estado
                                                                                .information
                                                                                .id ==
                                                                                20
                                                                                ? false
                                                                                : true,
                                                                            color:
                                                                              "verdeFayal",
                                                                            flat:
                                                                              "",
                                                                            icon:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.promocionarLote(
                                                                                lote.id,
                                                                                props
                                                                                  .item
                                                                                  .id,
                                                                                props.index
                                                                              )
                                                                            }
                                                                          },
                                                                          slot:
                                                                            "activator"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "near_me"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Promocionar"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            slot:
                                                                              "activator",
                                                                            disabled:
                                                                              lote.estado &&
                                                                              lote
                                                                                .estado
                                                                                .information
                                                                                .id ==
                                                                                21
                                                                                ? false
                                                                                : true,
                                                                            color:
                                                                              "error",
                                                                            icon:
                                                                              "",
                                                                            flat:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.despromocionarLote(
                                                                                lote.id,
                                                                                props
                                                                                  .item
                                                                                  .id,
                                                                                props.index
                                                                              )
                                                                            }
                                                                          },
                                                                          slot:
                                                                            "activator"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "near_me"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Retirar promocion"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  lote.estado
                                                                    ? _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs: {
                                                                                slot:
                                                                                  "activator",
                                                                                disabled:
                                                                                  !lote.estado ||
                                                                                  lote
                                                                                    .estado
                                                                                    .information
                                                                                    .id ==
                                                                                    21
                                                                                    ? false
                                                                                    : true,
                                                                                color:
                                                                                  "verdeFayal",
                                                                                flat:
                                                                                  "",
                                                                                icon:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.addPricesToLotes(
                                                                                    lote.id,
                                                                                    props
                                                                                      .item
                                                                                      .id,
                                                                                    props.index
                                                                                  )
                                                                                }
                                                                              },
                                                                              slot:
                                                                                "activator"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "price_check"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Precios de lote"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  !lote.estado
                                                                    ? _c(
                                                                        "v-tooltip",
                                                                        {
                                                                          attrs: {
                                                                            bottom:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-btn",
                                                                            {
                                                                              attrs: {
                                                                                slot:
                                                                                  "activator",
                                                                                disabled: !lote
                                                                                  ? false
                                                                                  : true,
                                                                                color:
                                                                                  "verdeFayal",
                                                                                flat:
                                                                                  "",
                                                                                icon:
                                                                                  ""
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.addPricesToLotes(
                                                                                    lote.id,
                                                                                    props
                                                                                      .item
                                                                                      .id,
                                                                                    props.index
                                                                                  )
                                                                                }
                                                                              },
                                                                              slot:
                                                                                "activator"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "price_check"
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Añadir precios de lote"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c("pagination", {
                                    attrs: { limit: 10, data: _vm.manzanas },
                                    on: {
                                      "pagination-change-page": _vm.getManzanas
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.vSnackBar.text) + "\n        "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("add-prices-to-lotes-modal", {
        attrs: { show: _vm.showAddPricesToLotes, "lote-id": _vm.currentLoteId },
        on: {
          "update:show": function($event) {
            _vm.showAddPricesToLotes = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }