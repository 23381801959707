<template>
    <div>
        <v-card flat>
            <v-toolbar color="verdeFayal" dark extended flat>
            </v-toolbar>

            <v-layout row pb-2>
                <v-flex xs10 offset-xs1>
                    <v-card class="card--flex-toolbar">
                        <v-toolbar card prominent>
                            <v-spacer></v-spacer>
                            <v-toolbar-title class="body-2 grey--text"> Administrar garantía de {{ vivienda.lote.nombre }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn dark color="verdeFayal" @click="openCreateModal">Añadir garantía</v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-data-table
                                :items="garantias.data"
                                hide-actions
                            >
                                <template v-slot:headers="props">
                                    <tr>
                                        <th>Tipo de garantía</th>
                                        <th>Estado</th>
                                        <th>Fecha de solicitud</th>
                                        <th>Acciones</th>
                                    </tr>
                                </template>
                                <template v-slot:items="props">
                                    <td>{{ props.item.tipo.nombre }}</td>
                                    <td>
                                        <span 
                                            :style="`background-color: ${ getTrafficLightColor(props.item.estado.estado_id) } ;`" 
                                            class="badge badge-secondary"
                                        >
                                            {{ props.item.estado.information.estado }}
                                        </span>
                                    </td>
                                    <td class="date-td">{{ translateDate(props.item.fecha_de_solicitud) }}</td>
                                    <td>
                                        <v-layout>
                                            <v-tooltip bottom>
                                                <v-btn 
                                                    @click="openStatusModal(props.item.id)"
                                                    :disabled="props.item.estado.estado_id != 40"
                                                    color="verdeFayal"
                                                    slot="activator" 
                                                    x-small
                                                    icon 
                                                    flat 
                                                    dark 
                                                >
                                                    <v-icon x-small>low_priority</v-icon>
                                                </v-btn>
                                                <span>Estados</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <v-btn
                                                    @click="openEditModal(props.item.id)"
                                                    slot="activator"
                                                    color="verdeFayal"
                                                    x-small
                                                    icon 
                                                    flat 
                                                    dark 
                                                >
                                                    <v-icon x-small>edit</v-icon>
                                                </v-btn>
                                                <span>Editar</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <v-btn
                                                    @click="openCommentsModal(props.item.id)"
                                                    slot="activator"
                                                    color="verdeFayal"
                                                    icon 
                                                    flat 
                                                    dark 
                                                >
                                                    <v-icon x-small>chat</v-icon>
                                                </v-btn>
                                                <span>Comentarios</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <v-btn
                                                    @click="currentHouseId = props.item.id; showFilesModal = true;"
                                                    slot="activator"
                                                    color="warning"
                                                    x-small
                                                    icon 
                                                    flat 
                                                    dark 
                                                >
                                                    <v-icon x-small>folder</v-icon>
                                                </v-btn>
                                                <span>Archivos</span>
                                            </v-tooltip>
                                        </v-layout>
                                    </td>
                                </template>
                            </v-data-table>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <pagination :limit="10" :data="garantias" @pagination-change-page="getGarantias"></pagination>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-card>
        <!-- Modal -->
        <add-guarantee-to-house-modal 
            :show.sync="showCreateModal"
            :vivienda-id="viviendaId"
        />
        <manage-comments-modal
            :show.sync="showCommentsModal"
            modelable-type="App\Garantia"
            :modelable-id="currentGuaranteeId"
        />
        <edit-guarantee-to-house-modal
            :show.sync="showEditModal"
            :vivienda-id="viviendaId"
            :garantia-id="currentGuaranteeId"
        >
        </edit-guarantee-to-house-modal>
        <manage-estados-modal-component 
            :show.sync="showStatusModal"
            :model-id.sync="currentHouseId"
            model-type="App\Garantia"
        ></manage-estados-modal-component>
        <upload-files-modal
            @close="showFilesModal = false"
            element_type="App\Garantia"
            :element_id="currentHouseId"
            :dialog="showFilesModal"
        ></upload-files-modal>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from 'axios';
  import moment from "moment";

  export default {
    data() {
      return {
        vivienda: {
            lote: {}
        },
        garantias: {
            data: []
        },
        currentHouseId: null,
        currentGuaranteeId: null,
        showStatusModal: false,
        showCreateModal: false,
        showFilesModal: false,
        showEditModal: false,
        showCommentsModal: false,
        vSnackBar: {
            visible: false,
            text: null
        }
      }
    },
    props: {
        viviendaId: String
    },
    watch: {
        showStatusModal: function() {
            this.getGarantias();
        },
        showCreateModal: function() {
            this.getGarantias();
        }
    },
    methods: {
        getVivienda: function() {
            axios.get(laravelRoute('viviendas.show', this.viviendaId))
            .then(response => this.vivienda = response.data)
            .catch(error => errorHandling(error))
        },
        getGarantias: function(page = 1) {
            axios.get(`/viviendas/${this.viviendaId}/garantias_por_vivienda?page=${page}`)
            .then(response => this.garantias = response.data)
            .catch(error => errorHandling(error))
        },
        translateDate: function(date) {
            var localLocale = moment(date);
            moment.locale('es');
            localLocale.locale(false)
            return localLocale.format('LLLL')
        },
        openCreateModal: function() {
            this.showCreateModal = true;
        },
        openStatusModal: function(viviendaId) {
            this.showStatusModal = true;
            this.currentHouseId = viviendaId;
        },
        openEditModal: function(guaranteeId) {
            this.showEditModal = true;
            this.currentGuaranteeId = guaranteeId;
        },
        openCommentsModal: function(guaranteeId) {
            this.showCommentsModal = true;
            this.currentGuaranteeId = guaranteeId;
        },
        getTrafficLightColor: function(statusId) {
            if(statusId == 41) {
                return '#008450'; // Verde
            } 
            if(statusId == 40) {
                return "#EFB700" //Naranja;
            }
            if(statusId == 42) {
                return "#B81D13"; // Rojo
            }
        },
    },
    created() {
        this.getVivienda();
        this.getGarantias();
    }
  }
</script>

<style>
    .date-td {
        text-transform: capitalize;
    }
</style>