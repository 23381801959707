<template>
    <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom v-if="(rol == 'Administrador') || (rol == 'Almacen')">
                <template v-slot:activator="{ on }">
                    <v-btn flat icon href="/orden_de_salida">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>

        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-toolbar card prominent>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="body-2 grey--text">Crear orden de salida de material</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                        <v-container grid-list-md>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-layout wrap>
                                    <v-flex md6>
                                        <v-text-field :rules="rules" :value="nombre" required label="Nombre de la orden" readonly></v-text-field>
                                    </v-flex>
                                    <v-flex md6>
                                        <searcher
                                            :configuration="searchEntregarA"
                                            :itemSelected.sync="entregar_a"
                                        ></searcher>
                                    </v-flex>
                                    <v-flex md6>
                                        <searcher
                                            :configuration="searchSalida"
                                            :itemSelected.sync="solicitud"
                                        ></searcher>
                                    </v-flex>
                                </v-layout>

                                <v-layout v-if="solicitud.id">
                                    <v-flex md6>
                                        <v-text-field :value="solicitud.folio" label="Folio de solicitud" readonly></v-text-field>
                                    </v-flex>
                                    <v-flex md6>
                                        <v-text-field :value="solicitud.solicitante" label="Solicitante" readonly></v-text-field>
                                    </v-flex>
                                    <v-flex md6>
                                        <v-text-field :value="solicitud.fraccionamiento.nombre_proyecto" label="Fraccionamiento" readonly></v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-divider></v-divider>
                                <div v-if="solicitud.id">
                                    <v-layout  wrap v-for="(material, index) in solicitud.materiales" :key="index">
                                        <v-flex md3>
                                            <v-text-field
                                                :value="material.material.categoria_asociada"
                                                readonly
                                                required
                                                label="Categoria"
                                                disabled
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex md3>
                                            <v-text-field
                                                :value="material.material.nombre"
                                                readonly
                                                required
                                                label="Material"
                                                disabled
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex md3>
                                            <v-text-field
                                                v-model="material.cantidad"
                                                required
                                                :rules="rulesForMaterial(material.cantidad_disponible_por_despachar)"
                                                type="number"
                                                label="Cantidad"
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex md3>
                                            <v-text-field
                                                v-model="material.cantidad_disponible_por_despachar"
                                                readonly
                                                required
                                                disabled
                                                label="Cantidad disponible para despachar"
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex md3>
                                            <v-text-field
                                                prepend-icon="attach_money"
                                                :value="material.material.precio"
                                                readonly
                                                required
                                                disabled
                                                label="Precio (Sin IVA)"
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex md3>
                                            <v-text-field
                                                prepend-icon="attach_money"
                                                :value="material.material.precio * material.cantidad"
                                                readonly
                                                required
                                                label="Precio total"
                                                disabled
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex md2>
                                            <v-text-field
                                                :value="material.distribucion"
                                                readonly
                                                required
                                                label="Distribución"
                                                disabled
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex md2>
                                            <v-text-field
                                                v-if="material.distribucion == 'Manzanas'"
                                                :value="material.manzana.nombre"
                                                readonly
                                                required
                                                label="Manzana"
                                                disabled
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex md2 v-if="material.distribucion == 'Manzanas'">
                                            <v-text-field                                                
                                                :value="material.lote.nombre"
                                                readonly
                                                required
                                                label="Lote"
                                                disabled
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex md2>
                                            <v-btn
                                                v-if="solicitud.materiales.length > 1"
                                                @click="solicitud.materiales.splice(index, 1)"
                                                fab
                                                small
                                                color="red"
                                            >
                                                <v-icon color="white">remove</v-icon>
                                            </v-btn>
                                        </v-flex>
                                        <v-divider></v-divider>
                                    </v-layout>
                                </div>
                            </v-form>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            dark
                            color="verdeFayal"
                            @click="storeOrden"
                            flat
                            :loading="vBtnSave.loading"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<script>
    import moment from "moment";

    export default {
        data: () => ({
            orden: {
                solicitante: null,
                fecha_solicitud: null,
                estado: null
            },
            solicitud: {
                id: null,
                folio: null,
                fraccionamiento: {
                    nombre_proyecto: null
                },
                solicitante: null,
                materiales: []
            },
            entregar_a: {
                id: null,
            },
            searchEntregarA: {
                url: "/trabajadores/active",
                paginatedContainer: 'data',
                noDataText: "No se encontraron resultados.",
                label: "Entregar a",
                itemText: "nombre_completo",
                itemValue: "id",
                descriptionLimit: 60,
                disabled: false,
                useHeaders: true,
            },
            searchSalida: {
                url: "/solicitud_de_material/search_for_orden",
                paginatedContainer: 'data',
                noDataText: "No se encontraron resultados.",
                label: "Buscar solicitud de material",
                itemText: "folio",
                itemValue: "id",
                descriptionLimit: 60,
                disabled: false,
                useHeaders: true,
            },            
            valid: false,
            rules: [v => !!v || "Este campo es requerido"],
            vBtnSave: {
                loading: false
            },
            vSnackBar: {
                visible: false,
                text: null
            },
        }),
        props: ["rol"],
        computed: {
            nombre: function() {
                return `Orden de salida ${moment().format('DD/MM/YYYY HH:mm:ss')}`;
            }
        },
        methods: {
            getLotes: function(index) {
                return (this.solicitud.material[index].manzana) ? this.solicitud.material[index].manzana.lotes : [] ;
            },
            rulesForMaterial: function(cantidad_disponible_por_despachar) {
                return [
                    (v) => v && v > 0 || 'La cantidad debe ser mayor a 0',
                    (v) => v && !isNaN(v) || 'La cantidad debe ser un número',
                    (v) => {
                        return cantidad_disponible_por_despachar >= v || 'Límite excedido';
                    },
            ];
            },
            storeOrden: function() {
                if (this.$refs.form.validate()) {
                    this.vBtnSave.loading = true;

                    axios.post(laravelRoute('orden_de_salida.store'), {
                        nombre: this.nombre,
                        entregar_a: this.entregar_a.id,
                        materiales: this.solicitud.materiales,
                        solicitud: this.solicitud.id,
                        fraccionamiento_id: this.solicitud.fraccionamiento.id
                    })
                    .then(response => {
                        this.vSnackBar.text = response.data.message_text;
                        this.vSnackBar.visible = true;

                        setTimeout(function () {
                            location.reload();
                        }, 1500);
                    })
                    .catch(error => {
                        errorHandling(error)
                    })
                    .finally(()=>this.vBtnSave.loading = false);
                }
            }
        }
    }
</script>
<style>
    .card--flex-toolbar {
        margin-top: -64px;
    }
</style>
