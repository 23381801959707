<template>
  <v-layout>
    <v-container>
      <v-card flat>
        <v-toolbar color="white" flat>
          <v-btn flat icon :href="`/nominas/${area.nomina_id}`">
            <v-icon>keyboard_arrow_left</v-icon>
          </v-btn>
          <v-breadcrumbs style="margin: 1%;" :items="breadcrums" divider=">"></v-breadcrumbs>
        </v-toolbar>
        <v-flex xs12>
          <v-toolbar color="white" flat>
            <v-toolbar-title>{{ area.area.nombre }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="openAddWorkersModal()">
              <v-icon left>person_add</v-icon> Agregar personal 
            </v-btn>            
          </v-toolbar>
          <v-progress-linear color="verdeFayal" v-if="loadingData" :indeterminate="true"></v-progress-linear>
          <v-data-table disable-initial-sort :items="trabajadores.data" hide-actions>
            <template v-slot:headers="props">
              <tr>
                <th>Nombre del trabajador</th>
                <th>Ocupación</th>
                <th>Puesto</th>
                <th>Deducciones</th>
                <th>Prima vacacional</th>
                <th>Salario</th>
                <th>Pago</th>
                <th>Acciones</th>
              </tr>
            </template>
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">{{ `${props.item.trabajador.nombre_completo}` }}</td>
              <td class="text-xs-left">{{ props.item.trabajador.ocupacion ?  props.item.trabajador.ocupacion.nombre : "Aún sin especificar"}}</td>
              <td>{{ props.item.trabajador.puesto }}</td>
              <td>$ {{ props.item.trabajador.total_deducciones | currency}}</td>
              <td>$ {{props.item.trabajador.puesto === 'Raya' ? `$ ${ props.item.prima_vacacional }` : 'No aplica' | currency}}</td>
              <td>$ {{ props.item.trabajador.puesto === 'Raya' ? `$ ${props.item.salario}` : 'No aplica' | currency}}</td>
              <td>$ {{ props.item.trabajador.puesto === 'Raya' ? props.item.pago_total : props.item.total_pagos_destajo | currency}}</td>
              <td>
                <v-layout>
                  <v-tooltip bottom v-if="props.item.pagado === 1">
                    <v-btn 
                      icon flat dark x-small slot="activator" color="verdeFayal"
                      @click="openSaveNominaTrabajadorModal(props.item)"
                    >
                      <v-icon x-small>visibility</v-icon>
                    </v-btn>
                    <span>Ver datos</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="props.item.pagado != 1">
                    <v-btn 
                      :disabled="props.item.pagado === 1"
                      icon flat dark x-small slot="activator" color="primary" 
                      @click="openSaveNominaTrabajadorModal(props.item)">
                      <v-icon x-small>edit</v-icon>
                    </v-btn>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn 
                      v-if="$root.can('prima-calculation', permissions)" 
                      :disabled="props.item.trabajador.puesto === 'Destajo' || props.item.pagado === 1"
                      icon flat dark x-small slot="activator" color="error"
                      @click="openPrimaVacacionalModal(props.item)">
                      <v-icon x-small>update</v-icon>
                    </v-btn>
                    <span v-if="props.item.trabajador.puesto === 'Raya'">Calcular prima vacacional</span>
                    <span v-else>No aplica</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn 
                      :disabled="props.item.pagado === 1"
                      icon flat dark x-small slot="activator" color="primary" 
                      @click="payWorker(props.item.id)">
                      <v-icon x-small>check_circle</v-icon>
                    </v-btn>
                    <span>Pagar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn  
                      :disabled="props.item.pagado === 1"
                      icon flat dark x-small slot="activator" color="error" 
                      @click="removeWorkerRelationship(props.item.id)">
                      <v-icon x-small>person_remove</v-icon>
                    </v-btn>
                    <span>Remover de nómina</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="10" :data="trabajadores" @pagination-change-page="getAreaNomina"></pagination>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-flex>
      </v-card>
    </v-container>
    <!-- Modal -->
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
    <trabajadores-modal :show.sync="showWorkersModal" :area_nomina_id="area_nomina_id"/>
    <save-nomina-trabajador-modal 
      :show.sync="showSaveModal"
      :selected_area_nomina_trabajador="selected_area_nomina_trabajador"
      :area_nombre="area_nomina.area.nombre"
      :readOnly="readOnlyCard"
    />
    <prima-vacacional-modal 
      :show.sync="showPrimaVacacionalModal"
      :selected_area_nomina_trabajador_id="selected_area_nomina_trabajador.id"
    />
  </v-layout>
</template>

<script>
import swal from 'sweetalert';
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";

export default {
  props: ['area', 'area_nomina_id', 'permissions', 'rol'],
  data() {
    return {
      loadingData: false,
      trabajadores: {
        data: []
      },
      area_nomina: {
        id: null,
        area_id: null,
        nomina_id: null,
        area: {
          id: 1,
          nombre: ''
        },
        nomina: {
          nombre: ''
        },
        areas_nominas_trabajadores: [],
        estatus: null,
      },
      total_pago_nomina: 0,
      readOnlyCard: false,
      selected_area_nomina_trabajador: {},
      vHeaders: [
        {text: "Nombre de trabajador", value: "nombre", align: 'start'},
        {text: "Ocupacion", value: "ocupacion_id", align: 'start'},
        {text: "Puesto", value: "puesto", align: 'start'},
        {text: "Deducciones", value: "deducciones", align: 'start'},
        {text: "Prima vacacional", value: "prima", align: 'start'},
        {text: "Salario", value: "salario", align: 'start'},
        {text: "Pago", value: "pago", align: 'start'},
        {text: "Acciones", value: "acciones", align: 'start'},
      ],
      showWorkersModal: false,
      showSaveModal: false,
      showPrimaVacacionalModal: false,
        vSnackBar: {
        visible: false,
        text: null
      },      
    }
  },
  watch: {
    showWorkersModal: function (value) {
      if (!value) this.getAreaNomina();
    },
    showSaveModal: function (value) {
      if (!value) this.getAreaNomina();
    },
    showPrimaVacacionalModal: function (value) {
      if (!value) this.getAreaNomina();
    },
  },
  computed: {
    getPagosSum: function () {
      let mount = 0;
      this.area_nomina.areas_nominas_trabajadores.forEach(area_nomina_trabajador => {
        if (area_nomina_trabajador.puesto === 'Raya') 
          mount = Number(mount) + Number(area_nomina_trabajador.pago_total);
        else 
          mount = Number(mount) + Number(area_nomina_trabajador.total_pagos_destajo);
      });
      return mount;
    },
    breadcrums: function() {
      return (this.rol.id == 1) ? 
      [
        {
          text: 'Nóminas',
          disabled: false,
          href: '/nominas'
        },
        {
          text: `${this.area.nombre_nomina}`,
          disabled: false,
          href: `/nominas/${this.area.nomina_id}`
        },
        {
          text: `${this.area.nombre_area}`,
          disabled: false
        } 
      ] :
      [
        {
          text: 'Nóminas',
          disabled: false,
          href: '/areas_nominas'
        },
        {
          text: this.area.nombre_nomina,
          disabled: true
        },
      ]
    }
  },
  methods: {
    getAreaNomina: function(page = 0){
      this.loadingData = true;
      axios.get(`/areas_nominas/${this.area_nomina_id}/trabajadores?page=${page}`)
      .then(response => this.trabajadores = response.data)
      .finally(() => this.loadingData = false)
      .catch(error => errorHandling(error));
    },
    openAddWorkersModal: function(){
      this.showWorkersModal = true;
    },
    openSaveNominaTrabajadorModal(area_nomina_trabajador){
      if (area_nomina_trabajador.pagado) this.readOnlyCard = true;
      else this.readOnlyCard = false;
      this.selected_area_nomina_trabajador = area_nomina_trabajador;
      this.showSaveModal = true;
    },
    openPrimaVacacionalModal(area_nomina_trabajador){
      this.selected_area_nomina_trabajador = area_nomina_trabajador;
      this.showPrimaVacacionalModal = true;
    },
    removeWorkerRelationship: function (area_nomina_trabajador_id) {
      this.loadingData = true;
      axios.delete(laravelRoute('areas_nominas.removeWorkerRelationship', area_nomina_trabajador_id))
      .then(response => {
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;
        this.getAreaNomina();
      })
      .finally(() => this.loadingData = false)
      .catch(error => errorHandling(error));
    },
    payWorker: function (area_nomina_trabajador_id) {
      this.loadingData = true;
      axios.patch(laravelRoute('areas_nominas.trabajador.payWorker', area_nomina_trabajador_id))
      .then(response => {
        if(response.data.message_type === 'success') alertify.success(response.data.message_text);
        else alertify.error(response.data.message_text)
        this.getAreaNomina();
      })
      .finally(() => this.loadingData = false)
      .catch(error => errorHandling(error));
    },

    exportToExcel: function() {
      axios.get(laravelRoute('areas_nominas.exportar_a_excel', this.area_nomina_id), {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'nomina.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
    },
  },
  mounted() {
    this.getAreaNomina();
  },
}
</script>