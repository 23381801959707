
<template>
  <v-card flat>
    <v-toolbar color="verdeFayal" dark extended flat>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn flat icon :href="breadcrums[1].href">
            <v-icon>keyboard_arrow_left</v-icon>
          </v-btn>
        </template>
        <span>Regresar</span>
      </v-tooltip>
    </v-toolbar>

      <v-layout row pb-2>
        <v-flex xs10 offset-xs1>
          <v-card class="card--flex-toolbar">
            <v-toolbar card prominent>
              <v-spacer></v-spacer>
              <v-toolbar-title class="body-2 grey--text">Conceptos del Lote: {{ lote.lote.nombre }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout>
                  <v-flex xs4>
                    <v-card dark color='#75d57b'>
                      <v-layout>
                        <v-flex xs8>
                          <v-card-title primary-title>
                            <div>
                              <div class="headline total-title">Iniciadas</div>
                              <div> Progreso de actividades Iniciadas de la etapa</div>
                            </div>
                          </v-card-title>
                        </v-flex>
                        <v-flex xs4>
                          <v-layout  align-center justify-center row fill-height>
                            <v-container>
                              <v-progress-circular
                                :rotate="180"
                                :size="75"
                                :width="10"
                                :value="localEtapa.porcentaje_de_avance.iniciado"
                                color="white"
                              >
                                {{ localEtapa.porcentaje_de_avance.iniciado.toFixed(1) == '100.0' ? '100' : localEtapa.porcentaje_de_avance.iniciado.toFixed(1)}}%
                              </v-progress-circular>
                            </v-container>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  
                  <v-flex xs4>
                    <v-card dark color='#75d57b'>
                      <v-layout>
                        <v-flex xs8>
                          <v-card-title primary-title>
                            <div>
                              <div class="headline total-title">Finalizadas</div>
                              <div> Progreso de actividades finalizadas de la etapa</div>
                            </div>
                          </v-card-title>
                        </v-flex>
                        <v-flex xs4>
                          <v-layout  align-center justify-center row fill-height>
                            <v-container>
                              <v-progress-circular
                                :rotate="180"
                                :size="75"
                                :width="10"
                                :value="localEtapa.porcentaje_de_avance.finalizado"
                                color="white"
                              >
                                {{ localEtapa.porcentaje_de_avance.finalizado.toFixed(1) == '100.0' ? '100' : localEtapa.porcentaje_de_avance.finalizado.toFixed(1)}}%
                              </v-progress-circular>
                            </v-container>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>

                  <v-flex xs4>
                    <v-card dark color='#75d57b'>
                      <v-layout>
                        <v-flex xs8>
                          <v-card-title primary-title>
                            <div>
                              <div class="headline total-title">Porcentaje de avance</div>
                              <div>Porcentaje de avance de {{ lote.lote.nombre }}</div>
                            </div>
                          </v-card-title>
                        </v-flex>
                        <v-flex xs4>
                          <v-layout  align-center justify-center row fill-height>
                            <v-container>
                              <v-progress-circular
                                :rotate="180"
                                :size="75"
                                :width="10"
                                :value="localLote.porcentaje_de_avance"
                                color="white"
                              >
                                {{ localLote.porcentaje_de_avance.toFixed(1) == '100.0' ? '100' : localLote.porcentaje_de_avance.toFixed(1)}}%
                              </v-progress-circular>
                            </v-container>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>

                <v-divider></v-divider>
                
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-breadcrumbs style="margin: 1%;" :items="breadcrums" divider=">"></v-breadcrumbs>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12>
                    <v-progress-linear v-if="loading" color="verdeFayal" :indeterminate="true"/>
                    <v-data-table
                      :items="conceptos"
                      hide-actions
                      item-key="id"
                    >
                      <template v-slot:headers>
                        <th>Concepto</th>
                        <th>Porcentaje de avance</th>
                        <th class="text-xs-right">Acciones</th>
                      </template>

                      <template v-slot:items="props">
                        <tr>
                          <td>{{ props.item.concepto.nombre}}</td>
                          <td>
                            <div class="progress">
                              <div 
                                class="progress-bar progress-bar-striped progress-bar-animated bg-success" 
                                role="progressbar" 
                                :style="`width: ${props.item.porcentaje_de_avance}%;`" 
                                :aria-valuenow="props.item.porcentaje_de_avance" 
                                aria-valuemin="0" 
                                aria-valuemax="100"
                              >
                                {{ props.item.porcentaje_de_avance.toFixed(2) }}%
                              </div>
                            </div>
                          </td>
                          <td class="text-xs-right">
                            <v-btn flat icon :href="`/etapas/${etapa.id}/actividades/manzana/${manzana.id}/lote/${lote.id}/conceptos/${props.item.concepto_id}`">
                              <v-icon>chevron_right</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

      <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
        {{ vSnackBar.text }}
        <v-btn color="pink" flat @click="vSnackBar.visible = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-snackbar>
  </v-card>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';
  import moment from "moment";

  export default {
    
    data() {
      return {
        conceptos: [],
        loading: false,
        localEtapa: {
          porcentaje_de_avance: {"finalizado":0,"iniciado":0}
        },
        localLote: {
          porcentaje_de_avance: 0
        },
        vSnackBar: {
          visible: false,
          text: null
        },
      }
    },
    props: {
      etapa: Object,
      manzana: Object,
      lote: Object
    },
    computed: {
      breadcrums: function() {
        return [
          {
            text: `${this.etapa.nombre}`,
            disabled: false,
            href: `/etapas/${this.etapa.id}/actividades`
          },
          {
            text: `${this.manzana.nombre}`,
            disabled: false,
            href: `/etapas/${this.etapa.id}/actividades/manzana/${this.manzana.id}`
          },
          {
            text: `Lote ${this.lote.lote.nombre}`,
            disabled: true,
          }
        ]
      }
    },
    methods: {
      getEtapa: function() {
        axios.get(`/etapas/refactor/${this.etapa.id}`)
        .then(response => this.localEtapa = response.data)
        .catch(error => errorHandling(error));
      },
      getConcepts: function(page = 1) {
        this.loading = true;
        axios.get(`/lotes_etapa/${this.lote.id}/conceptos?page=${page}`)
        .then(response => this.conceptos = response.data)
        .catch(error => errorHandling(error))
        .finally(() => this.loading = false)
      },
      getActividades: function(page = 1){
        this.loading = true;
        axios.get(`/etapas/${this.etapa.id}/actividades/manzana/${this.manzana.id}/lote/${this.lote.id}?page=${page}`)
        .then(response => this.actividades = response.data)
        .catch(error => errorHandling(error))
        .finally(() => this.loading = false)
      },
      formatDate: function(date) {
        if(date) {
          var localLocale = moment(date);
          moment.locale('es');
          localLocale.locale(false)
          return localLocale.format('LLLL')
        }
      },
      initDistribucionById: function(activity) {
        /*swal({
          title: "¿Estás seguro?",
          text: `Al aceptar iniciarás: ${activity.rubro.nombre}`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
          cancelButtonText: 'Cancelar',
          confirmButtonColor: "#1aba24"
        }).then(result => {
          if (result) {*/
            axios.post(`/etapas/${this.etapa.id}/distribucion/iniciar_por_id`, {
              actividad_id: activity.id
            })
            .then(response => {
              this.vSnackBar.text = response.data.message_text;
              this.vSnackBar.visible = true;
              this.getActividades(this.actividades.current_page);
              this.getEtapa();
              this.getLote();
            })
            .catch(error => errorHandling(error));
          /*}
        });*/
      },
      finishDistribucionById: function(activity) {
        /*swal({
          title: "¿Estás seguro?",
          text: `Al aceptar finalizarás: ${activity.rubro.nombre}`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
          cancelButtonText: 'Cancelar',
          confirmButtonColor: "#1aba24"
        }).then(result => {
          if (result) {*/
            axios.post(`/etapas/${this.etapa.id}/distribucion/finalizar_por_id`, {
              actividad_id: activity.id
            })
            .then(response => {
              this.vSnackBar.text = response.data.message_text;
              this.vSnackBar.visible = true;
              this.getActividades(this.actividades.current_page);
              this.getEtapa();
              this.getLote();
            })
            .catch(error => errorHandling(error));
         /* }
        });*/
      },
      getLote: function(){
        axios.get(laravelRoute('lotes_etapa.show', this.lote.id))
        .then(response => this.localLote = response.data)
        .catch(error => errorHandling(error))
      }
    },
    mounted() {
      this.getConcepts();
      this.localEtapa = this.etapa;
      this.localLote = this.lote;
    }
  }
</script>
