var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "fyl-headerTable" },
            [
              _c("v-flex", { staticClass: "card-blog", attrs: { md6: "" } }, [
                _c("h2", { staticClass: "card-title" }, [
                  _vm._v("Lista de Roles")
                ])
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { md3: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      color: "#1ABA24",
                      "append-icon": "search",
                      label: "Search",
                      "single-line": "",
                      "hide-details": "",
                      sortable: "true"
                    },
                    model: {
                      value: _vm.search,
                      callback: function($$v) {
                        _vm.search = $$v
                      },
                      expression: "search"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("edit-role", {
                staticStyle: { display: "none" },
                attrs: {
                  roles: _vm.roles,
                  role: _vm.selectedRole,
                  permissions: _vm.permissions,
                  dialog: _vm.dialog
                },
                on: {
                  open: function($event) {
                    _vm.dialog = true
                  },
                  close: function($event) {
                    return _vm.cleanForm()
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.rolesProps,
              search: _vm.search
            },
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function(rolesProps) {
                  return [
                    _c("td", [_vm._v(_vm._s(rolesProps.item.id))]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-xs-left" }, [
                      _vm._v(_vm._s(rolesProps.item.name))
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-xs-left" },
                      [
                        _c(
                          "v-layout",
                          [
                            _c(
                              "v-tooltip",
                              { attrs: { bottom: "" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      slot: "activator",
                                      icon: "",
                                      flat: "",
                                      dark: "",
                                      small: "",
                                      color: "info"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editRole(rolesProps.item)
                                      }
                                    },
                                    slot: "activator"
                                  },
                                  [_c("v-icon", [_vm._v("edit")])],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Editar")])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-tooltip",
                              { attrs: { bottom: "" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      slot: "activator",
                                      icon: "",
                                      flat: "",
                                      dark: "",
                                      small: "",
                                      color: "error"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.sweetAlert(rolesProps.item)
                                      }
                                    },
                                    slot: "activator"
                                  },
                                  [_c("v-icon", [_vm._v("delete")])],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Eliminar")])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              },
              {
                key: "no-results",
                fn: function() {
                  return [
                    _c(
                      "v-alert",
                      {
                        attrs: { value: true, color: "error", icon: "warning" }
                      },
                      [
                        _vm._v(
                          'Your search for "' +
                            _vm._s(_vm.search) +
                            '" found no results.'
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }