<template>
    <div flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn flat icon href="/requisiciones">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>
        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                      <v-toolbar color="white" flat tabs>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="primary-title">Administrar requisición</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tabs slot="extension" v-model="vTabs" centered color="white" slider-color="verdeFayal">
                            <v-tab href="#tab-edit-requisicion">Datos de la requisición</v-tab>
                            <v-tab href="#tab-index-material">Lista de materiales</v-tab>
                        </v-tabs>
                    </v-toolbar>
                    <v-card-text>
                        <v-tabs-items v-model="vTabs">
                            <v-tab-item value="tab-edit-requisicion">
                                <v-container grid-list-md>
                                    <v-layout>
                                        <v-flex md6>
                                            <v-text-field v-model="localRequisicion.nombre_requisicion" required label="Nombre de la orden"></v-text-field>
                                        </v-flex>
                                        <v-flex md6>
                                            <v-select 
                                                v-model="localRequisicion.solicitante" 
                                                :items="solicitante" 
                                                required 
                                                label="Seleccione solicitante"
                                            ></v-select>
                                        </v-flex>
                                        <v-flex md6>
                                            <v-text-field disabled v-model="localRequisicion.estado.information.estado" required label="Estado"></v-text-field>
                                        </v-flex>
                                        <v-flex md6 v-if="localRequisicion.estado.information.estado == 'Aprobada'">
                                            <v-text-field 
                                                type="date"
                                                v-model="localRequisicion.fecha_de_llegada" 
                                                required 
                                                label="Fecha de llegada"
                                            ></v-text-field>
                                        </v-flex>
                                        <v-flex md6>
                                            <v-text-field v-model="localRequisicion.descripcion" required label="Descripcion"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-tab-item>
                            <v-tab-item value="tab-index-material">
                                <v-container grid-list-md>
                                    <v-layout>
                                        <v-data-table :items="materiales" hide-actions>
                                            <template slot="headers">
                                                <td>Proveedor</td>
                                                <td>Estado</td>
                                                <td>Categoria</td>
                                                <td>Material</td>
                                                <td>Cantidad requerida</td>
                                                <td>Precio unitario</td>
                                                <td>Total</td>
                                                <td>Acciones</td>
                                            </template>
                                            <template slot="items" slot-scope="props">
                                                <tr>
                                                    <td>{{ (props.item.proveedor) ? props.item.proveedor.nombre_completo : 'Aún sin proveedor' }}</td>
                                                    <td>{{ getStatus(props.item) }}</td>
                                                    <td>{{ props.item.material.categoria_asociada }}</td>
                                                    <td>{{ props.item.material.nombre }}</td>
                                                    <td>{{ props.item.cantidad }}</td>
                                                    <td>$ {{ props.item.material.precio | currency }}</td>
                                                    <td>$ {{ props.item.material.precio * props.item.cantidad | currency}}</td>
                                                    <td>
                                                        <v-layout>
                                                            <v-tooltip bottom>
                                                                <v-btn 
                                                                    icon 
                                                                    flat 
                                                                    dark 
                                                                    small
                                                                    slot="activator" 
                                                                    color="verdeFayal" 
                                                                    @click="openShoppingModal(props.item.material.id)"
                                                                >
                                                                    <v-icon>shopping_cart</v-icon>
                                                                </v-btn>
                                                                <span>Añadir datos de venta</span>
                                                            </v-tooltip>
                                                            <v-tooltip bottom>
                                                                <v-btn 
                                                                    icon 
                                                                    flat 
                                                                    dark 
                                                                    small 
                                                                    :disabled="getStatus(props.item) != 'Pendiente de surtir'" 
                                                                    slot="activator" 
                                                                    color="error" 
                                                                    @click="deleteMaterialValue(props.item.material.id)"
                                                                >
                                                                    <v-icon>delete</v-icon>
                                                                </v-btn>
                                                                <span>Eliminar</span>
                                                            </v-tooltip>
                                                        </v-layout>
                                                    </td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </v-layout>
                                </v-container>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn dark v-if="vTabs == 'tab-edit-requisicion'" color="verdeFayal" outline flat @click="updateRequisicion" :loading="vBtnSave.loading"> Guardar </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <!-- Modals -->
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
        <shopping-requisiciones-modal 
            :show.sync="showShoppingModal" 
            :material-id.sync="currentMaterialId"
            :requisicion-id="requisicion.id"
        />
    </div>
</template>

<script>
export default {
    data: () => ({
        materiales: [],
        localRequisicion: {
          estado: null
        },
        showShoppingModal: false,
        currentMaterialId: null,
        solicitante:['Almacén'],
        vTabs: "tab-edit-requisicion",
        vBtnSave: {
          loading: false
        },
        vSnackBar: {
          visible: false,
          text: null
        }
    }),
    props: {
        requisicion: Object
    },
    wach: {
        showShoppingModal: function() {
            this.getMateriales();
        }
    },
    methods: {
        getMateriales: function() {
            axios.get(laravelRoute('requisiciones.materiales_requisiciones', this.requisicion.id))
            .then(response => this.materiales = response.data)
            .catch( error => errorHandling(error));
        },
        updateRequisicion: function(){
          this.vBtnSave.loading = true;
          axios.patch('/requisiciones/'+this.requisicion.id, {
            nombre_requisicion: this.localRequisicion.nombre_requisicion,
            solicitante: this.localRequisicion.solicitante,
            proveedor: this.localRequisicion.proveedor_id,
            descripcion: this.localRequisicion.descripcion,
            estado: this.localRequisicion.estado.information.id,
            visto_bueno_operaciones: this.localRequisicion.visto_bueno_operaciones,
            visto_bueno: this.localRequisicion.visto_bueno,
            fecha_de_llegada: this.localRequisicion.fecha_de_llegada
          })
          .then(response => {
            this.vSnackBar.text = response.data.message_text;
            this.vSnackBar.visible = true;
          })
          .catch(error => errorHandling(error))
          .finally(()=>this.vBtnSave.loading = false);
        },
        getStatus(material) {
            if(material.cantidad_a_surtir == 0) {
                return 'Pendiente de surtir';
            }
            if(material.cantidad_a_surtir > 0 && material.cantidad_a_surtir < material.cantidad) {
                return 'Surtido parcialmente';
            }
            if(material.cantidad_a_surtir == material.cantidad_a_surtir < material.cantidad) {
                return 'Surtido totalmente';
            }
        },
        deleteMaterialValue: function(materialId) {
          swal({
            title: "¿Estás seguro?",
            text: "Una vez eliminado no se podrá recuperar",
            icon: "warning",
            buttons: true,
            dangerMode: true
          }).then(result => {
            if (result) {
              axios.delete(`/requisiciones/materiales/${this.requisicion.id}?material_id=${materialId}`)
              .then(response => {
                this.getMateriales();
                this.vSnackBar.text = response.data.message_text;
                this.vSnackBar.visible = true;
              })
              .catch(error => errorHandling(error));
            }
          })
        },
        openShoppingModal: function(materialId) {
          this.showShoppingModal = true;
          this.currentMaterialId = materialId;
        },
    },
    created(){
        this.getMateriales();
        this.localRequisicion = this.requisicion;
    },
}
</script>

<style>
    .card--flex-toolbar {
        margin-top: -64px;
    }

    .disable-events {
        pointer-events: none;
    }
</style>