<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="550px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Editar garantía</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
            <v-layout wrap>
                <v-flex md12>
                    <v-text-field :value="garantia.tipo.nombre" required label="Garantía" disabled></v-text-field>
                </v-flex>
                <v-flex md12>
                    <v-text-field :value="garantia.tipo.descripcion" required label="Descripción" disabled></v-text-field>
                </v-flex>
                <v-flex md12>
                    <v-text-field :value="`${garantia.tipo.meses} meses`" required label="Vigencia" disabled></v-text-field>
                </v-flex>
                <v-flex md12>
                    <v-text-field disabled v-model="garantia.fecha_de_solicitud" type="date" label="Fecha de solicitud"></v-text-field>
                </v-flex>
                <v-flex md12>
                    <v-textarea
                        name="input-7-4"
                        label="Observaciones"
                        v-model="garantia.observaciones"
                    ></v-textarea>
                </v-flex>
            </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="updateGarantia" dark color="verdeFayal" outline flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
    data: () => ({
        garantia: {
            tipo: {}
        },
        fecha: null,
        observaciones: null,
        searchGarantia: {
            url: "/garantias",
            paginatedContainer: 'data',
            noDataText: "No se encontraron resultados.",
            label: "Garantía",
            itemText: "nombre",
            itemValue: "id",
            descriptionLimit: 60,
            disabled: false,
            useHeaders: true,
        },
        garantiaSelect: {
            id: null
        },
        localShow: false,
        vBtnSave: {
            loading: false
        },
        vSnackBar: {
            visible: false,
            text: null
        }
    }),
    props: {
        show: Boolean,
        viviendaId: String,
        garantiaId: Number
    },
    watch: {
        show: function (value) {
            this.localShow = value
        },
        localShow: function (value) {
            this.$emit('update:show', value);
        },
        garantiaId: function(value) {
            this.getGarantia();
        }
    },
    methods: {
        getGarantia: function() {
            axios.get(laravelRoute('garantias.show', this.garantiaId))
            .then(response => this.garantia = response.data)
            .catch(error =>  errorHandling(error));
        },
        updateGarantia:  function() {
            this.vBtnSave.loading = true;
            axios.patch(laravelRoute('garantias.update', this.garantiaId), {
                observaciones: this.garantia.observaciones
            })
            .then(response => {
                this.vSnackBar.text = response.data.message_text;
                this.vSnackBar.visible = true;
            })
            .catch(error =>  errorHandling(error))
            .finally(()=> this.vBtnSave.loading = false);
        }
    }
}
</script>
