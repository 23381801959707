var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "da" } },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-toolbar",
            {
              attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" }
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  flat: "",
                                  icon: "",
                                  href: "/fraccionamientos"
                                }
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_vm._v(" "), _c("span", [_vm._v("Regresar")])]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-layout",
            { attrs: { row: "", "pb-2": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs10: "", "offset-xs1": "" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card--flex-toolbar" },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { card: "", prominent: "" } },
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-toolbar-title",
                            { staticClass: "body-2 grey--text" },
                            [
                              _vm._v(
                                "Crear etapa de obra para el fraccionamiento " +
                                  _vm._s(_vm.fraccionamiento.nombre_proyecto) +
                                  " "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-spacer")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: { label: "Nombre de la etapa" },
                                        model: {
                                          value: _vm.etapa.nombre,
                                          callback: function($$v) {
                                            _vm.$set(_vm.etapa, "nombre", $$v)
                                          },
                                          expression: "etapa.nombre"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          hint: "Buscar manzana a trabajar",
                                          "prepend-inner-icon": "search",
                                          "search-input": _vm.search,
                                          items: _vm.distribuciones,
                                          "item-text": "nombre",
                                          "persistent-hint": "",
                                          "item-value": "id",
                                          loading: _vm.searchLoading,
                                          label: "Buscar",
                                          "return-object": "",
                                          "hide-selected": "",
                                          "hide-no-data": ""
                                        },
                                        on: {
                                          "update:searchInput": function(
                                            $event
                                          ) {
                                            _vm.search = $event
                                          },
                                          "update:search-input": function(
                                            $event
                                          ) {
                                            _vm.search = $event
                                          }
                                        },
                                        nativeOn: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.addDistribucion($event)
                                          }
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append-outer",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-slide-x-reverse-transition",
                                                  { attrs: { mode: "out-in" } },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "success",
                                                          disabled: !_vm.selected
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.addDistribucion
                                                        }
                                                      },
                                                      [_vm._v("add")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ]),
                                        model: {
                                          value: _vm.selected,
                                          callback: function($$v) {
                                            _vm.selected = $$v
                                          },
                                          expression: "selected"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider"),
                              _vm._v(" "),
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c("v-flex", [
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "table table-striped table-hover"
                                      },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [_vm._v("Manzana")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { scope: "col" } },
                                              [
                                                _vm._v(
                                                  "Total de lotes seleccionados"
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("th", [_vm._v("Acciones")])
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "tbody",
                                          _vm._l(_vm.selectManzanas, function(
                                            manzana,
                                            index
                                          ) {
                                            return _c("tr", { key: index }, [
                                              _c("td", [
                                                _vm._v(
                                                  "Manzana " +
                                                    _vm._s(manzana.nombre)
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "badge badge-primary badge-lotes"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        manzana.lotes.length
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "v-layout",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            flat: "",
                                                            icon: "",
                                                            color: "verdeFayal"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.openAddLotesModal(
                                                                manzana
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("add")
                                                          ])
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            flat: "",
                                                            icon: "",
                                                            color: "red"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.selectManzanas.splice(
                                                                index,
                                                                1
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("delete")
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ])
                                          }),
                                          0
                                        )
                                      ]
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outline: "",
                                dark: "",
                                color: "verdeFayal",
                                flat: "",
                                loading: _vm.vBtnSave.loading
                              },
                              on: { click: _vm.storeEtapa }
                            },
                            [_vm._v(" Guardar y continuar ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-snackbar",
            {
              attrs: { bottom: "bottom", right: "right" },
              model: {
                value: _vm.vSnackBar.visible,
                callback: function($$v) {
                  _vm.$set(_vm.vSnackBar, "visible", $$v)
                },
                expression: "vSnackBar.visible"
              }
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.vSnackBar.text) + "\n            "
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "pink", flat: "" },
                  on: {
                    click: function($event) {
                      _vm.vSnackBar.visible = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("add-lotes-to-etapa-modal", {
        attrs: {
          show: _vm.showAddLotesModal,
          manzana: _vm.currentManzana,
          lotesManzanas: _vm.lotesSelected
        },
        on: {
          "update:show": function($event) {
            _vm.showAddLotesModal = $event
          },
          "update:lotesManzanas": function($event) {
            _vm.lotesSelected = $event
          },
          "update:lotes-manzanas": function($event) {
            _vm.lotesSelected = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }