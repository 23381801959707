var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.dialog,
            persistent: "",
            transition: "dialog-bottom-transition"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "fyl-btnCreate",
                      on: {
                        click: function($event) {
                          return _vm.$emit("open")
                        }
                      }
                    },
                    [_vm._v("Crear")]
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v(
                      _vm._s(this.currentProyectoId ? "Editar" : "Crear") +
                        " Propuesta"
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.$emit("close")
                          _vm.$refs.form.resetValidation()
                          _vm.countManzanas = 1
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.loading
                ? _c("v-progress-linear", {
                    attrs: { color: "success", indeterminate: true }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "", md6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Nombre de la propuesta",
                                      color: "#1ABA24",
                                      readonly: _vm.readonly,
                                      rules: [
                                        function(v) {
                                          return !!v || "Campo requerido"
                                        }
                                      ]
                                    },
                                    model: {
                                      value: _vm.proyecto.nombre,
                                      callback: function($$v) {
                                        _vm.$set(_vm.proyecto, "nombre", $$v)
                                      },
                                      expression: "proyecto.nombre"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm6: "", md6: "" } },
                                [
                                  _c("searcher", {
                                    attrs: {
                                      configuration: _vm.searchConfig,
                                      itemSelected: _vm.selected
                                    },
                                    on: {
                                      "update:itemSelected": function($event) {
                                        _vm.selected = $event
                                      },
                                      "update:item-selected": function($event) {
                                        _vm.selected = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.currentProyectoId
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "", md6: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.status,
                                          "item-text": "estado",
                                          "item-value": "id",
                                          label: "Status",
                                          color: "#1ABA24",
                                          readonly: _vm.readonly,
                                          rules: [
                                            function(v) {
                                              return !!v || "Campo requerido"
                                            }
                                          ]
                                        },
                                        model: {
                                          value: _vm.proyecto.status,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.proyecto,
                                              "status",
                                              $$v
                                            )
                                          },
                                          expression: "proyecto.status"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    sm12: "",
                                    md12: "",
                                    center: ""
                                  }
                                },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      color: "#1ABA24",
                                      label: "Descripcion",
                                      counter: "250",
                                      maxlength: _vm.max,
                                      readonly: _vm.readonly
                                    },
                                    model: {
                                      value: _vm.proyecto.descripcion,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.proyecto,
                                          "descripcion",
                                          $$v
                                        )
                                      },
                                      expression: "proyecto.descripcion"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-flex", { attrs: { md12: "" } }, [
                                _c("table", { staticClass: "table" }, [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v("Manzanas")
                                      ]),
                                      _vm._v(" "),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v("lotes regulares")
                                      ]),
                                      _vm._v(" "),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v("Lotes irregulares")
                                      ]),
                                      _vm._v(" "),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v("Lotes")
                                      ]),
                                      _vm._v(" "),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v("Superficie (M2)")
                                      ])
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.proyecto.only_manzanas.length
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(_vm.lotes_regulares))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(_vm.lotes_irregulares))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.lotes_regulares +
                                              _vm.lotes_irregulares
                                          )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-center" }, [
                                        _vm._v(
                                          _vm._s(
                                            (_vm.proyecto.superficie =
                                              _vm.superficieLotes +
                                              _vm.superficieAreaVerde +
                                              _vm.superficieCalles)
                                          )
                                        )
                                      ])
                                    ])
                                  ])
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { md12: "" } },
                                [
                                  [
                                    _c(
                                      "v-tabs",
                                      {
                                        attrs: {
                                          "fixed-tabs": "",
                                          centered: "",
                                          "slider-color": "verdeFayal"
                                        },
                                        model: {
                                          value: _vm.active,
                                          callback: function($$v) {
                                            _vm.active = $$v
                                          },
                                          expression: "active"
                                        }
                                      },
                                      [
                                        _c("v-tab", [_vm._v("Manzanas")]),
                                        _vm._v(" "),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "v-layout",
                                              { attrs: { wrap: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      md1: "",
                                                      "offset-md-9": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      { attrs: { bottom: "" } },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              slot: "activator",
                                                              color:
                                                                "verdeFayal",
                                                              dark: "",
                                                              disabled:
                                                                _vm.readonly
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.addManzana
                                                            },
                                                            slot: "activator"
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("add")
                                                            ])
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            "Añadir manzana"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-layout",
                                              { attrs: { wrap: "" } },
                                              [
                                                _c(
                                                  "v-expansion-panel",
                                                  [
                                                    _vm._l(
                                                      _vm.proyecto
                                                        .only_manzanas,
                                                      function(manzana, index) {
                                                        return [
                                                          _c(
                                                            "v-flex",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                md12: "",
                                                                row: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    md12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-expansion-panel-content",
                                                                    {
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "header",
                                                                            fn: function() {
                                                                              return [
                                                                                _c(
                                                                                  "div",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "Manzana " +
                                                                                        _vm._s(
                                                                                          manzana.nombre
                                                                                        )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            },
                                                                            proxy: true
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-layout",
                                                                        {
                                                                          attrs: {
                                                                            row:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              attrs: {
                                                                                md1:
                                                                                  "",
                                                                                "offset-md-9":
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-text-field",
                                                                                {
                                                                                  attrs: {
                                                                                    type:
                                                                                      "number",
                                                                                    min:
                                                                                      "0",
                                                                                    "single-line":
                                                                                      "",
                                                                                    solo:
                                                                                      "",
                                                                                    label:
                                                                                      "Lotes",
                                                                                    hint:
                                                                                      "Enter para crear...",
                                                                                    disabled:
                                                                                      _vm.readonly
                                                                                  },
                                                                                  on: {
                                                                                    keyup: function(
                                                                                      $event
                                                                                    ) {
                                                                                      if (
                                                                                        !$event.type.indexOf(
                                                                                          "key"
                                                                                        ) &&
                                                                                        _vm._k(
                                                                                          $event.keyCode,
                                                                                          "enter",
                                                                                          13,
                                                                                          $event.key,
                                                                                          "Enter"
                                                                                        )
                                                                                      ) {
                                                                                        return null
                                                                                      }
                                                                                      return _vm.addMultiplesLotes(
                                                                                        index
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm.numberLotes,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.numberLotes = $$v
                                                                                    },
                                                                                    expression:
                                                                                      "numberLotes"
                                                                                  }
                                                                                }
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "v-flex",
                                                                            {
                                                                              attrs: {
                                                                                md1:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-tooltip",
                                                                                {
                                                                                  attrs: {
                                                                                    bottom:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        slot:
                                                                                          "activator",
                                                                                        dark:
                                                                                          "",
                                                                                        icon:
                                                                                          "",
                                                                                        color:
                                                                                          "verdeFayal",
                                                                                        disabled:
                                                                                          _vm.readonly
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.addLote(
                                                                                            index,
                                                                                            manzana.nombre
                                                                                          )
                                                                                        }
                                                                                      },
                                                                                      slot:
                                                                                        "activator"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "add"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Añadir lote"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          index >
                                                                            0 &&
                                                                          !_vm.currentProyectoId
                                                                            ? _c(
                                                                                "v-flex",
                                                                                {
                                                                                  attrs: {
                                                                                    md1:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        flat:
                                                                                          "",
                                                                                        icon:
                                                                                          "",
                                                                                        color:
                                                                                          "error"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.removeManzana(
                                                                                            index
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "delete_outline"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e()
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-card",
                                                                        [
                                                                          _c(
                                                                            "v-card-text",
                                                                            [
                                                                              _vm._l(
                                                                                manzana.lotes,
                                                                                function(
                                                                                  lote,
                                                                                  indexLote
                                                                                ) {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-flex",
                                                                                      {
                                                                                        key: indexLote,
                                                                                        attrs: {
                                                                                          md12:
                                                                                            "",
                                                                                          row:
                                                                                            ""
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-flex",
                                                                                          {
                                                                                            attrs: {
                                                                                              md1:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "h3",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Lote " +
                                                                                                    _vm._s(
                                                                                                      (lote.nombre =
                                                                                                        manzana.nombre +
                                                                                                        (indexLote +
                                                                                                          1))
                                                                                                    )
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "v-flex",
                                                                                          {
                                                                                            attrs: {
                                                                                              md3:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-select",
                                                                                              {
                                                                                                attrs: {
                                                                                                  label:
                                                                                                    "Tipo de lote",
                                                                                                  items: [
                                                                                                    "Regular",
                                                                                                    "Irregular"
                                                                                                  ],
                                                                                                  readonly:
                                                                                                    _vm.readonly,
                                                                                                  rules: [
                                                                                                    function(
                                                                                                      v
                                                                                                    ) {
                                                                                                      return (
                                                                                                        !!v ||
                                                                                                        "Campo requerido"
                                                                                                      )
                                                                                                    }
                                                                                                  ]
                                                                                                },
                                                                                                on: {
                                                                                                  change: function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.countLotes(
                                                                                                      lote.tipo,
                                                                                                      indexLote,
                                                                                                      manzana.nombre
                                                                                                    )
                                                                                                  }
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    lote.tipo,
                                                                                                  callback: function(
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      lote,
                                                                                                      "tipo",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "lote.tipo"
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "v-flex",
                                                                                          {
                                                                                            attrs: {
                                                                                              md4:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-autocomplete",
                                                                                              {
                                                                                                attrs: {
                                                                                                  label:
                                                                                                    "Modelo",
                                                                                                  items:
                                                                                                    _vm.prototipos_vivienda,
                                                                                                  attach:
                                                                                                    "",
                                                                                                  "item-text":
                                                                                                    "nombre",
                                                                                                  "item-value":
                                                                                                    "id",
                                                                                                  readonly:
                                                                                                    _vm.readonly,
                                                                                                  rules: [
                                                                                                    function(
                                                                                                      v
                                                                                                    ) {
                                                                                                      return (
                                                                                                        !!v ||
                                                                                                        "Campo requerido"
                                                                                                      )
                                                                                                    }
                                                                                                  ]
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    lote.modelo_id,
                                                                                                  callback: function(
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      lote,
                                                                                                      "modelo_id",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "lote.modelo_id"
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "v-flex",
                                                                                          {
                                                                                            attrs: {
                                                                                              md3:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-text-field",
                                                                                              {
                                                                                                attrs: {
                                                                                                  type:
                                                                                                    "number",
                                                                                                  min:
                                                                                                    "0",
                                                                                                  label:
                                                                                                    "Superficie en metro cuadrados",
                                                                                                  readonly:
                                                                                                    _vm.readonly,
                                                                                                  rules: [
                                                                                                    function(
                                                                                                      v
                                                                                                    ) {
                                                                                                      return (
                                                                                                        !!v ||
                                                                                                        "Campo requerido"
                                                                                                      )
                                                                                                    }
                                                                                                  ]
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    lote.superficie,
                                                                                                  callback: function(
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      lote,
                                                                                                      "superficie",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                  expression:
                                                                                                    "lote.superficie"
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "v-flex",
                                                                                          {
                                                                                            attrs: {
                                                                                              md1:
                                                                                                ""
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-layout",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-tooltip",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      bottom:
                                                                                                        ""
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-btn",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          slot:
                                                                                                            "activator",
                                                                                                          icon:
                                                                                                            "",
                                                                                                          color:
                                                                                                            "verdeFayal",
                                                                                                          flat:
                                                                                                            "",
                                                                                                          disabled:
                                                                                                            _vm.readonly
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.duplicateItem(
                                                                                                              index,
                                                                                                              lote
                                                                                                            )
                                                                                                          }
                                                                                                        },
                                                                                                        slot:
                                                                                                          "activator"
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "content_copy"
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                    _vm._v(
                                                                                                      " "
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "Duplicar lote"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                                _vm._v(
                                                                                                  " "
                                                                                                ),
                                                                                                _c(
                                                                                                  "v-tooltip",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      bottom:
                                                                                                        ""
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-btn",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          slot:
                                                                                                            "activator",
                                                                                                          icon:
                                                                                                            "",
                                                                                                          color:
                                                                                                            "error",
                                                                                                          flat:
                                                                                                            "",
                                                                                                          disabled:
                                                                                                            _vm.readonly
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.areYouShure(
                                                                                                              index,
                                                                                                              indexLote,
                                                                                                              lote.id,
                                                                                                              lote.tipo
                                                                                                            )
                                                                                                          }
                                                                                                        },
                                                                                                        slot:
                                                                                                          "activator"
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "remove"
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                    _vm._v(
                                                                                                      " "
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "span",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "Eliminar lote"
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              )
                                                                            ],
                                                                            2
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("v-tab", [_vm._v("Áreas verdes")]),
                                        _vm._v(" "),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "v-layout",
                                              { attrs: { wrap: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      md1: "",
                                                      "offset-md-11": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "verdeFayal",
                                                          dark: "",
                                                          disabled: _vm.readonly
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.addAreaVerde
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("add")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.proyecto.areas_verdes,
                                              function(area_verde, index) {
                                                return _c(
                                                  "v-container",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      "grid-list-md": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      { attrs: { wrap: "" } },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { md2: "" }
                                                          },
                                                          [
                                                            _c("h3", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  (area_verde.nombre =
                                                                    "Área verde " +
                                                                    (index + 1))
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { md6: "" }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                color:
                                                                  "verdeFayal",
                                                                label:
                                                                  "Superficie en metros cuadrados",
                                                                type: "number",
                                                                min: "0",
                                                                readonly:
                                                                  _vm.readonly,
                                                                rules: [
                                                                  function(v) {
                                                                    return (
                                                                      !!v ||
                                                                      "Campo requerido"
                                                                    )
                                                                  }
                                                                ]
                                                              },
                                                              model: {
                                                                value:
                                                                  area_verde.superficie,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    area_verde,
                                                                    "superficie",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "area_verde.superficie"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { md1: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  color:
                                                                    "error",
                                                                  disabled:
                                                                    _vm.readonly
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.proyecto.areas_verdes.splice(
                                                                      index,
                                                                      1
                                                                    )
                                                                    _vm.deleteAreaVerde(
                                                                      area_verde.id
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "remove"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c("v-tab", [_vm._v("Calles")]),
                                        _vm._v(" "),
                                        _c(
                                          "v-tab-item",
                                          [
                                            _c(
                                              "v-layout",
                                              { attrs: { wrap: "" } },
                                              [
                                                _c(
                                                  "v-flex",
                                                  {
                                                    attrs: {
                                                      md1: "",
                                                      "offset-md-11": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          color: "verdeFayal",
                                                          dark: "",
                                                          disabled: _vm.readonly
                                                        },
                                                        on: {
                                                          click: _vm.addCalle
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("add")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.proyecto.calles,
                                              function(calle, index) {
                                                return _c(
                                                  "v-container",
                                                  {
                                                    key: index,
                                                    attrs: {
                                                      "grid-list-md": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "v-layout",
                                                      { attrs: { wrap: "" } },
                                                      [
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { md2: "" }
                                                          },
                                                          [
                                                            _c("h3", [
                                                              _vm._v(
                                                                "Calle " +
                                                                  _vm._s(
                                                                    index + 1
                                                                  )
                                                              )
                                                            ])
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { md4: "" }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                color:
                                                                  "verdeFayal",
                                                                label:
                                                                  "Nombre de la calle",
                                                                readonly:
                                                                  _vm.readonly,
                                                                rules: [
                                                                  function(v) {
                                                                    return (
                                                                      !!v ||
                                                                      "Campo requerido"
                                                                    )
                                                                  }
                                                                ]
                                                              },
                                                              model: {
                                                                value:
                                                                  calle.nombre,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    calle,
                                                                    "nombre",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "calle.nombre"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { md4: "" }
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                color:
                                                                  "verdeFayal",
                                                                label:
                                                                  "Superficie en metros cuadrados",
                                                                type: "number",
                                                                min: "0",
                                                                readonly:
                                                                  _vm.readonly,
                                                                rules: [
                                                                  function(v) {
                                                                    return (
                                                                      !!v ||
                                                                      "Campo requerido"
                                                                    )
                                                                  }
                                                                ]
                                                              },
                                                              model: {
                                                                value:
                                                                  calle.superficie,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    calle,
                                                                    "superficie",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "calle.superficie"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-flex",
                                                          {
                                                            attrs: { md1: "" }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  color:
                                                                    "error",
                                                                  disabled:
                                                                    _vm.readonly
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.proyecto.calles.splice(
                                                                      index,
                                                                      1
                                                                    )
                                                                    _vm.deleteCalle(
                                                                      calle.id
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "remove"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            disabled:
                              _vm.$store.state.saveDisabled || _vm.readonly,
                            dark: "",
                            color: "verdeFayal"
                          },
                          on: {
                            click: function($event) {
                              return _vm.projectCreate()
                            }
                          }
                        },
                        [_vm._v("Guardar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }