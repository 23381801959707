<template>
<v-layout>
  <v-container>
    <v-card>
      <v-flex xs12>
        <v-toolbar color="white" flat tabs>
          <v-toolbar-title>Conceptos de urbanización</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat color="verdeFayal" @click="openCreateModal">
            <v-icon right dark>add</v-icon>Crear
          </v-btn>
          <v-btn dark color="verdeFayal" outline  @click="exportToExcel">
              <v-icon dark>import_export</v-icon>
            </v-btn>
        </v-toolbar>
        <values-housing-development-index :reload="showCreateModal" :permissions_rubro="permissions_rubro" :permissions="permissions_valor"></values-housing-development-index>
        <v-card-actions>
          <v-spacer></v-spacer>
          <pagination :limit="vPaginator.limit" :data="planes" @pagination-change-page="getPlanes"></pagination>
        </v-card-actions>
      </v-flex>
    </v-card>
    <!-- Modal -->
    <create-concepts-modal type="urbanizacion" :permissions="permissions_rubro" :show.sync="showCreateModal"></create-concepts-modal>
  </v-container>
</v-layout>
</template>

<script>
import swal from "sweetalert";

export default {
  data: () => ({
    search: null,
    planes: {
      data: []
    },
    showCreateModal: false,
    showEditModal: false,
    currentHousingId: null,
    vPaginator: {
      limit: 10
    },
    vHeaders: [{
        text: 'Nombre',
        value: 'nombre'
      },
      {
        text: 'Descipción',
        value: 'descripcion'
      },
      {
        text: 'Acciones',
        value: 'acciones',
        sortable: false
      }
    ],

    filters: {},
  }),
  props: ['permissions', 'permissions_concepto', 'permissions_rubro', 'permissions_valor'],
  watch: {
    search: function (value) {
      this.getPlanes();
    },
    showCreateModal: function (value) {
      if (!value) {
        this.getPlanes();
      }
    },
    showEditModal: function (value) {
      if (!value) {
        this.getPlanes();
      }
    }
  },
  methods: {
    getPlanes: function (page = 1) {
      axios.get(`/urbanizacion?page=${page}&query=${this.search}`)
        .then(response => {
          this.planes = response.data;
        })
        .catch(error => errorHandling(error));
    },
    deleteHousing: function (housingId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('urbanizacion.destroy', housingId))
            .then(response => {
              this.getPlanes();
              alertify.success(response.data.message_text);
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      })
    },
    openEditModal: function (housingId) {
      this.showEditModal = true;
      this.currentHousingId = housingId;
    },
    openCreateModal: function () {
      this.showCreateModal = true;
    },

    exportToExcel: function() {
      axios.post('/urbanizacion/export', {
        ...this.filters,
        to_excel: true
      }, {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'conceptosUrbanizacion.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
    },
  },
  created() {
    this.getPlanes();
  }
}
</script>
