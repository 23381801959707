<template>
  <div>
    <v-dialog v-model="localShow" max-width="850px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Crear salida de almacén</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md6>
              <v-text-field v-model="salidas.nombre_de_orden" required label="Nombre de la orden" readonly></v-text-field>
            </v-flex>
            <v-flex md6>
              <v-text-field v-model="salidas.fecha_solicitud" required label="Fecha de solicitud" type="date"></v-text-field>
            </v-flex>
            <v-flex md6>
              <v-select v-model="salidas.solicitante" :items="solicitante" required label="Seleccione solicitante"></v-select>
            </v-flex>
            <v-flex md6>
              <v-select
                v-model="salidas.estado"
                :items="estado"
                item-value="id"
                item-text="estado"
                label="Seleccione estado"
                required
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-switch color="verdeFayal" label="Seguir registrando" v-model="keepStoring"></v-switch>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="storeMaterial" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data(){
    return{
      localShow: false,
      keepStoring: true,
      salidas:{
        nombre_de_orden: "",
        solicitante: null,
        fecha_solicitud: null,
        estado: null
      },
      solicitante: [
        'Obra',
        'Urbanización',
        'Maquinaría',
      ],
      estado: [],
      vBtnSave: {
        loading: false
      },
    }
  },
  methods:{
    storeMaterial: function(){
      this.vBtnSave.loading = true;
      axios.post(laravelRoute('salidas_de_almacen.store'), {
        nombre_de_orden: this.salidas.nombre_de_orden,
        solicitante: this.salidas.solicitante,
        fecha_solicitud: this.salidas.fecha_solicitud,
        estado: this.salidas.estado
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.localShow = (this.keepStoring) ? true : false
        this.salidas = {};
      })
      .catch(error => {
          errorHandling(error)
        })
      .finally(()=>this.vBtnSave.loading = false);
    },
    getStatus: function(){
      axios.get("/salidas_de_almacen/estados").then(response => {
          this.estado = response.data;
        });
    },
  },
  props:{
    show: Boolean
  },
  watch:{
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.salidas.nombre_de_orden = `Orden de salida de almacen ${moment().format('DD/MM/YYYY HH:mm:ss')}`;
      this.$emit('update:show', value);
    }
  },
  created(){
    this.getStatus();
  }
}
</script>