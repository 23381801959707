<template>
  <v-container>
    <v-layout row wrap>    
      <v-flex :xs12="!showFiltersForm" :xs9="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Recepción de materiales</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <v-btn dark color="verdeFayal" outline @click="showFiltersForm = !showFiltersForm">
              <v-icon dark>filter_alt</v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table :headers="vHeaders" :items="requisiciones.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td>{{ props.item.folio }}</td>
              <td class="td-ellipsis">{{ props.item.nombre_requisicion }}</td>
              <td>{{ props.item.solicitante }}</td>
              <td class="td-ellipsis">{{props.item.descripcion }}</td>
              <td>{{props.item.estado.information.estado}}</td>
              <td>{{ props.item.fecha_de_llegada }}</td>
              <td>
                <v-layout>
                  <v-tooltip bottom>
                    <v-btn :disabled="!$root.can('materials', permissions)" @click="openAddMaterialsValuesModal(props.item.id,props.item.nombre)"  icon flat dark small slot="activator" color="verdeFayal">
                        <v-icon>vertical_split</v-icon>
                    </v-btn>
                    <span>Materiales requeridos</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn icon flat dark small slot="activator" color="verdeFayal" @click="exportToExcel(props.item.id)">
                      <v-icon>import_export</v-icon>
                    </v-btn>
                    <span>Exportar a Excel</span>
                  </v-tooltip>                  
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination
              :limit="vPaginator.limit"
              :data="requisiciones"
              @pagination-change-page="getRequisiciones"
            ></pagination>
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex xs3 v-if="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat>
            <v-toolbar-title>Filtros</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="showFiltersForm = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout row wrap>
                <div>
                  <span class="text-center">Si al menos uno de los materiales solicitados tiene este estado, la requisición se mostrará al aplicar el filtro;</span>
                  <span class="text-center">Esto no significa que todos los materiales de la requisición tengan dicho estado.</span>
                  <br>
                  <span class="text-center">Para revisar el estado individual de cada material solicitado, es necesario abrir los materiales requeridos en la requisición o exportar la requisición a excel.</span>
                </div>
                <v-flex>
                  <v-select 
                    v-model="filters.filterEstado" 
                    :items="this.itemsEstados"
                    item-value="id"
                    item-text="estado"
                    label="Estado de los materiales"
                    clearable                                 
                  />
                </v-flex>                
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn dark color="verdeFayal" small flat outline @click="limpiar">Limpiar</v-btn>
            <v-spacer></v-spacer>
            <v-btn dark color="verdeFayal" small @click="filterRecepcion">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <!-- modals -->
      <supply-recepcion-material-modal
        v-bind:show.sync="showMaterialsToValuesModal"
        v-bind:item-id.sync="currentRequisicionId"
        v-bind:modelType.sync="currentModel"
        v-bind:name.sync="currentItemName"
      ></supply-recepcion-material-modal>
      </v-layout>
  </v-container>  
</template>

<script>
export default {
  data(){
    return{
      search: null,
      requisiciones:{},
      showCreateModal: false,
      currentItemId: null,
      showEditModal: false,
      currentRequisicionId: null,
      showMaterialsToValuesModal: false,
      itemId: null,
      currentItemName: null,
      currentModel: 'App\\Requisicion',
      vPaginator: {
        limit: 10
      },
      vHeaders: [
        { text: "Folio", value: "folio", sortable: false  },
        { text: "Nombre de requisición", value: "nombre_requisicion", sortable: false  },
        { text: "Solicitador", value: "solicitante", sortable: false  },
        { text: "Descripción", value: "descripcion", sortable: false  },
        { text: "Estado", value: "estado", sortable: false  },
        { text: "Fecha de llegada", value: "fecha_de_llegada", sortable: false  },
        { text: "Acciones", value: "acciones", sortable: false }
      ],
      materials: {
        current_page: 1,
        data: []
      },

      showFiltersForm: false,
      filters: {                
        filterEstado: null,        
      },
      itemsEstados: [
        'Pendiente de surtir',
        'Surtido parcialmente',
        'Surtido totalmente',
      ],
    }
  },
  props:['permissions'],
  methods:{
    getRequisiciones: function (page = 1) {
      axios.get(`/recepcion_de_materiales?page=${page}&query=${this.search}`)
      .then(response => {
        this.requisiciones = response.data;
      })
      .catch(error => errorHandling(error));
    },
    openCreateModal: function(currentItemId){
      this.showCreateModal = true;
      this.currentItemId = currentItemId;
    },
    openEditRequisicionModal: function(requisicionId){
      this.showEditModal = true;
      this.currentRequisicionId = requisicionId;
    },
    deleteRequisicion: function(requisicionId){
    swal({
      title: "¿Estás seguro?",
      text: "Una vez eliminado no se podrá recuperar",
      icon: "warning",
      buttons: true,
      dangerMode: true
      }).then(result => {
        if (result) {
          axios
          .delete(laravelRoute('requisiciones.destroy', requisicionId))
          .then(response => {
            this.getRequisiciones();
            alertify.success(response.data.message_text);
          })
          .catch(error => {
            errorHandling(error);
          });
        }
      })
    },
    openAddMaterialsValuesModal: function(requisicionId, currentItemName) {
      this.showMaterialsToValuesModal = true;
      this.currentItemName = currentItemName;
      this.currentRequisicionId = requisicionId;
    },
    AprobadoGerencia: function(aprobado){
      swal({
        title: "¿Está seguro que quiere dar visto bueno a esta requisición?",
        // text: "Tenga en cuenta que, por el momento, esta acción será irrevocable",
        icon: "warning",
        buttons: true
      }).then(response => {
        if(response){
          this.vistoBueno(aprobado);
          swal("la requisición se aprobó satisfactoriamente", {
            icon: "success"
        }).then(response => {
          this.getRequisiciones();
        });
        }else{
          swal("la requisición no se aprobó");
        }
      });
    },
    aprobadoOperaciones: function(requisicion) {
      swal({
        title: "¿Está seguro que quiere dar visto bueno a esta requisición?",
        icon: "warning",
        buttons: true
      }).then(response => {
        if(response){
          axios.patch('/requisiciones/' + requisicion.id, {
            ...requisicion,
            aprobado_operacion: true
          })
          .then(response => {
            alertify.success(response.data.message_text);
            this.getRequisiciones()
          })
          .catch(error => errorHandling(error));
        
        }else{
          let key = this.requisiciones.data.findIndex(getRequisicion => getRequisicion.id = requisicion.id);
          this.requisiciones.data[key].visto_bueno_operaciones = false;
          swal("la requisición no se aprobó");
        }
      });
    }, 
    vistoBueno: function(prospecto) {
      axios
        .put(route("requisicion.aprobar", prospecto.id))
        .then(response => {
          if (response.data.message_text)
            alertify.success(response.data.message_text);
        })
        .catch(error => errorHandling(error));
    },

    filterRecepcion: function() {
      axios.post('/recepcion_de_materiales/filterRecepcion',this.filters)
      .then(response => this.requisiciones = response.data)
      .catch(error => errorHandling(error));
    },
    limpiar: function () {                     
        this.filters.filterEstado = null;
        this.getRequisiciones();
    },    
    exportToExcel: function(requisicion_Id) {
      axios.get(laravelRoute('recepcion_materiales.export',requisicion_Id), {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'recepcionMateriales.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
    },
  },
  watch:{
    showCreateModal: function (value) {
      if (!value) {
        this.getRequisiciones();
      }
    },
    showEditModal: function (value) {
      this.getRequisiciones();
    },
    showMaterialsToValuesModal: function(value) {
      if(!value) {
        this.getRequisiciones()
      }
    },
    search: function (value) {
      this.getRequisiciones();
    },
  },
  created(){
    this.getRequisiciones();
  }
}
</script>

<style scoped>
  .fyl-check-requisitions{
    display: inline-block;
    width: 27px;
    height: 27px;
  }
  .fyl-descripcion-td{
    max-width: 166px;
  }
</style>