<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="550px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Crear propuesta de lotificación</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                label="Nombre de la propuesta"
                v-model="propuesta.nombre"
                color="#1ABA24"
                :rules="[(v) => !!v || 'Campo requerido']"
              ></v-text-field>
            </v-flex>

            <v-flex xs12>
              <searcher
                :configuration="searchConfig"
                :itemSelected.sync="propuesta.proyecto"
              />
            </v-flex>

            <v-flex xs12>
              <v-textarea
                color="#1ABA24"
                label="Descripción"
                v-model="propuesta.descripcion"
                counter="250"
              ></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" outline flat :loading="vBtnSave.loading" @click="storeProspecto">Guardar y continuar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    propuesta: {
      nombre: null,
      proyecto: {},
      descripcion: null
    },
    searchConfig: {
      url: "/prospectos/concretados",
      paginatedContainer: 'data',
      noDataText: "No se encontraron resultados.",
      label: "Nombre del proyecto",
      itemText: "nombre_proyecto",
      itemValue: "id",
      descriptionLimit: 60,
      disabled: false,
      useHeaders: true,
    },
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
  },
  methods: {
    storeProspecto: function() {
      this.vBtnSave.loading = true;
      axios.post('/proyectos', {
        nombre: this.propuesta.nombre,
        descripcion: this.propuesta.descripcion,
        prospecto_id: this.propuesta.proyecto.id
      })
      .then(response => {
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;

        setTimeout(() => {
          window.location.replace(`/proyectos/${response.data.data.id}`);
        }, 1300);
      })
      .catch(error => {
        errorHandling(error)
      })
      .finally(()=>this.vBtnSave.loading = false);
    }
  }
}
</script>
