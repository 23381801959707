var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Añadir pago")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-form",
                        { ref: "form" },
                        [
                          _vm.trabajador.puesto == "Destajo"
                            ? _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md3: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          "item-value": "id",
                                          "item-text": "nombre_proyecto",
                                          items: _vm.fraccionamientos,
                                          label: "Proyecto",
                                          rules: _vm.rules
                                        },
                                        on: { change: _vm.getFraccionamiento },
                                        model: {
                                          value:
                                            _vm.trabajador.fraccionamiento_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.trabajador,
                                              "fraccionamiento_id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "trabajador.fraccionamiento_id"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md2: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          disabled: _vm.trabajador
                                            .fraccionamiento_id
                                            ? false
                                            : true,
                                          items: ["Obra", "Urbanizacion"],
                                          label: "Tipo",
                                          rules: _vm.rules
                                        },
                                        on: { change: _vm.getEtapas },
                                        model: {
                                          value: _vm.tipo,
                                          callback: function($$v) {
                                            _vm.tipo = $$v
                                          },
                                          expression: "tipo"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md3: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          rules: _vm.rules,
                                          disabled: _vm.tipo ? false : true,
                                          "return-object": "",
                                          "item-value": "id",
                                          "item-text": "nombre",
                                          items: _vm.filterEtapas,
                                          label: "Etapas"
                                        },
                                        model: {
                                          value: _vm.trabajador.etapa,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.trabajador,
                                              "etapa",
                                              $$v
                                            )
                                          },
                                          expression: "trabajador.etapa"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.trabajador.etapa
                                    ? _c(
                                        "v-flex",
                                        { attrs: { md4: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              readonly: "",
                                              required: "",
                                              label: "Fecha final de etapa"
                                            },
                                            model: {
                                              value:
                                                _vm.trabajador.etapa.fecha_fin,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador.etapa,
                                                  "fecha_fin",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "trabajador.etapa.fecha_fin"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.desglose, function(item, index) {
                            return _vm.trabajador.puesto == "Destajo" &&
                              _vm.trabajador.etapa
                              ? [
                                  _c(
                                    "v-layout",
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { md2: "" } },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              label: "Distribución",
                                              rules: _vm.rules,
                                              items:
                                                _vm.tipo == "Obra"
                                                  ? ["Manzanas"]
                                                  : [
                                                      "Manzanas",
                                                      "Calles",
                                                      "Áreas verdes"
                                                    ]
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.getLotes(index)
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.desglose[index]
                                                  .distribucion,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.desglose[index],
                                                  "distribucion",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "desglose[index].distribucion"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.tipo == "Urbanizacion"
                                        ? _c(
                                            "v-flex",
                                            { attrs: { md2: "" } },
                                            [
                                              _vm.desglose[index]
                                                .distribucion == "Manzanas" ||
                                              _vm.desglose[index]
                                                .distribucion == null
                                                ? _c("v-autocomplete", {
                                                    attrs: {
                                                      label: "Lote",
                                                      rules: _vm.rules,
                                                      "item-value": "id",
                                                      "item-text":
                                                        "manzana_lotificación.nombre",
                                                      items:
                                                        _vm.desglose[index]
                                                          .itemsLote,
                                                      "return-object": ""
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.assingConceptosRubro(
                                                          index
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.desglose[index]
                                                          .lote,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.desglose[index],
                                                          "lote",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "desglose[index].lote"
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.desglose[index]
                                                .distribucion == "Calles"
                                                ? _c("v-autocomplete", {
                                                    attrs: {
                                                      label: "Lote",
                                                      rules: _vm.rules,
                                                      "item-value":
                                                        "calles_lotificación.id",
                                                      "item-text":
                                                        "calles_lotificación.nombre",
                                                      items:
                                                        _vm.desglose[index]
                                                          .itemsLote,
                                                      "return-object": ""
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.assingConceptosRubro(
                                                          index
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.desglose[index]
                                                          .lote,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.desglose[index],
                                                          "lote",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "desglose[index].lote"
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.desglose[index]
                                                .distribucion == "Áreas verdes"
                                                ? _c("v-autocomplete", {
                                                    attrs: {
                                                      label: "Lote",
                                                      rules: _vm.rules,
                                                      "item-value":
                                                        "calles_lotificación.id",
                                                      "item-text":
                                                        "area_verde_lotificación.nombre",
                                                      items:
                                                        _vm.desglose[index]
                                                          .itemsLote,
                                                      "return-object": ""
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.assingConceptosRubro(
                                                          index
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.desglose[index]
                                                          .lote,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.desglose[index],
                                                          "lote",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "desglose[index].lote"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-flex",
                                            { attrs: { md2: "" } },
                                            [
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  label: "Lote",
                                                  "item-value": "id",
                                                  rules: _vm.rules,
                                                  "item-text":
                                                    "lote_lotificacion.nombre",
                                                  items:
                                                    _vm.desglose[index]
                                                      .itemsLote,
                                                  "return-object": ""
                                                },
                                                on: {
                                                  change: function($event) {
                                                    return _vm.assingConceptosRubro(
                                                      index
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.desglose[index].lote,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.desglose[index],
                                                      "lote",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "desglose[index].lote"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md2: "" } },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              label: "Concepto",
                                              "item-text": "nombre",
                                              rules: _vm.rules,
                                              items:
                                                _vm.desglose[index]
                                                  .itemsConceptos,
                                              "return-object": ""
                                            },
                                            model: {
                                              value:
                                                _vm.desglose[index].concepto,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.desglose[index],
                                                  "concepto",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "desglose[index].concepto"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md2: "" } },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              label: "Rubro",
                                              "item-text": "nombre",
                                              rules: _vm.rules,
                                              items:
                                                _vm.desglose[index].itemsRubros,
                                              "return-object": ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.calculateCantidad(
                                                  index
                                                )
                                              }
                                            },
                                            model: {
                                              value: _vm.desglose[index].rubro,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.desglose[index],
                                                  "rubro",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "desglose[index].rubro"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md2: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              rules: _vm.rules,
                                              "prepend-icon": "money-attach",
                                              type: "number",
                                              min: "0",
                                              label: "Pago"
                                            },
                                            model: {
                                              value: _vm.desglose[index].pago,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.desglose[index],
                                                  "pago",
                                                  $$v
                                                )
                                              },
                                              expression: "desglose[index].pago"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      index != 0
                                        ? _c(
                                            "v-flex",
                                            { attrs: { md2: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    fab: "",
                                                    flat: "",
                                                    dark: "",
                                                    small: "",
                                                    color: "verdeFayal"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.desglose.splice(
                                                        index,
                                                        1
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { dark: "" } },
                                                    [_vm._v("remove")]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e()
                          }),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { md6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      type: "date",
                                      rules: _vm.rules,
                                      required: "",
                                      label: "Fecha de pago"
                                    },
                                    model: {
                                      value: _vm.trabajador.fecha_de_pago,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.trabajador,
                                          "fecha_de_pago",
                                          $$v
                                        )
                                      },
                                      expression: "trabajador.fecha_de_pago"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.trabajador.puesto == "Destajo"
                                ? _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          readonly: "",
                                          required: "",
                                          label: "Total",
                                          value: _vm.totalDestajo
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.trabajador.puesto == "Raya"
                                ? _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "input",
                                          rules: _vm.rules,
                                          readonly: "",
                                          required: "",
                                          label: "Salario (referencial)"
                                        },
                                        model: {
                                          value: _vm.trabajador.salario,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.trabajador,
                                              "salario",
                                              $$v
                                            )
                                          },
                                          expression: "trabajador.salario"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.trabajador.puesto == "Raya"
                                ? _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          color: "verdeFayal",
                                          label:
                                            "¿Aplicar prima vacacional (25%)?"
                                        },
                                        model: {
                                          value: _vm.primaVacacional,
                                          callback: function($$v) {
                                            _vm.primaVacacional = $$v
                                          },
                                          expression: "primaVacacional"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.trabajador.puesto == "Raya"
                                ? _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          rules: _vm.rules,
                                          min: 0,
                                          required: "",
                                          label: "Monto"
                                        },
                                        model: {
                                          value: _vm.trabajador.monto,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.trabajador,
                                              "monto",
                                              $$v
                                            )
                                          },
                                          expression: "trabajador.monto"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.trabajador.puesto == "Raya"
                                ? _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "input",
                                          rules: _vm.rules,
                                          min: "0",
                                          readonly: "",
                                          required: "",
                                          label: "Total"
                                        },
                                        model: {
                                          value: _vm.total,
                                          callback: function($$v) {
                                            _vm.total = $$v
                                          },
                                          expression: "total"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { md6: "" } },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      label: "Estado",
                                      "item-value": "id",
                                      "item-text": "estado",
                                      items: _vm.estados,
                                      rules: _vm.rules
                                    },
                                    model: {
                                      value: _vm.estado,
                                      callback: function($$v) {
                                        _vm.estado = $$v
                                      },
                                      expression: "estado"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: { click: _vm.storePayment }
                    },
                    [_vm._v(" Guardar ")]
                  ),
                  _vm._v(" "),
                  _vm.trabajador.puesto == "Destajo"
                    ? _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal" },
                          on: { click: _vm.addDesglose }
                        },
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }