var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Entrega de lote " + _vm._s(_vm.venta.lote.nombre))
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  disabled:
                                    _vm.venta.vivienda != null ? true : false,
                                  type: "date",
                                  required: "",
                                  label: "Fecha de entrega final"
                                },
                                model: {
                                  value: _vm.venta.fecha_de_entrega,
                                  callback: function($$v) {
                                    _vm.$set(_vm.venta, "fecha_de_entrega", $$v)
                                  },
                                  expression: "venta.fecha_de_entrega"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.venta.vivienda != null ? true : false,
                                    label: "¿Cliente conforme?",
                                    row: "",
                                    color: "verdeFayal"
                                  },
                                  model: {
                                    value: _vm.venta.cliente_conforme,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.venta,
                                        "cliente_conforme",
                                        $$v
                                      )
                                    },
                                    expression: "venta.cliente_conforme"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: { label: "Si", value: true }
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    attrs: { label: "No", value: false }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            [
                              !_vm.venta.documento_de_entrega_url
                                ? _c("upload-file-field-refactor", {
                                    attrs: {
                                      placeholder: "Documento de entrega",
                                      "url-name": _vm.fileURL
                                    },
                                    on: {
                                      "update:urlName": function($event) {
                                        _vm.fileURL = $event
                                      },
                                      "update:url-name": function($event) {
                                        _vm.fileURL = $event
                                      }
                                    }
                                  })
                                : _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "verdeFayal",
                                        flat: "",
                                        outline: "",
                                        href:
                                          _vm.venta.documento_de_entrega_url,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Descargar documento de entrega\n                "
                                      ),
                                      _c(
                                        "v-icon",
                                        { attrs: { right: "", dark: "" } },
                                        [_vm._v("cloud_download")]
                                      )
                                    ],
                                    1
                                  )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _vm.venta.cliente_conforme == false
                                ? _c("v-textarea", {
                                    attrs: {
                                      name: "input-7-1",
                                      label: "Añadir comentarios",
                                      disabled:
                                        _vm.venta.documento_de_entrega_url
                                    },
                                    model: {
                                      value: _vm.venta.observaciones,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.venta,
                                          "observaciones",
                                          $$v
                                        )
                                      },
                                      expression: "venta.observaciones"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: _vm.venta.vivienda != null ? true : false,
                        outline: "",
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: { click: _vm.updateDeliverLot }
                    },
                    [_vm._v(" Guardar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }