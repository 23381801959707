var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.dialog,
            persistent: "",
            "max-width": "700px",
            "hide-overlay": ""
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Crear nueva etapa para " + _vm._s(_vm.dad))
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-progress-linear", {
                attrs: {
                  active: _vm.loadingToolbar,
                  indeterminate: _vm.loadingToolbar,
                  striped: "",
                  absolute: "",
                  top: "",
                  color: "verdeFayal"
                }
              }),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("v-text-field", {
                    attrs: { label: "Nombre de la etapa" },
                    model: {
                      value: _vm.etapaNombre,
                      callback: function($$v) {
                        _vm.etapaNombre = $$v
                      },
                      expression: "etapaNombre"
                    }
                  }),
                  _vm._v(" "),
                  _c("h4", [_vm._v("Manzanas")]),
                  _vm._v(" "),
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          "browser-autocomplete": "off",
                          "hide-selected": "",
                          "return-object": "",
                          "no-data-text": "No hay manzanas disponibles",
                          placeholder:
                            "Seleccione las manzanas a incluir en la etapa",
                          chips: true,
                          multiple: true,
                          "deletable-chips": true,
                          "item-text": "nombre",
                          "item-value": "id",
                          items: _vm.manzanas.items
                        },
                        model: {
                          value: _vm.manzanas.selected,
                          callback: function($$v) {
                            _vm.$set(_vm.manzanas, "selected", $$v)
                          },
                          expression: "manzanas.selected"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      "hide-actions": true,
                      "disable-initial-sort": true,
                      headers:
                        _vm.dad == "Obra"
                          ? _vm.manzanas.headers
                          : _vm.manzanas.headersUrbanizacion,
                      items: _vm.manzanas.selected
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", [
                              _vm._v("Manzana: " + _vm._s(props.item.nombre))
                            ]),
                            _vm._v(" "),
                            _vm.dad == "Obra"
                              ? _c(
                                  "td",
                                  _vm._l(props.item.lotes, function(lote) {
                                    return _c(
                                      "v-chip",
                                      {
                                        key: lote.id,
                                        staticClass: "ma-2",
                                        attrs: {
                                          color: "verdeFayal",
                                          outline: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.addLote(
                                              props.item.id,
                                              lote
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(lote.nombre) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.dad == "Obra"
                              ? _c(
                                  "td",
                                  _vm._l(
                                    _vm.manzanas.lotes.filter(function(lote) {
                                      return lote.manzanaId == props.item.id
                                    }),
                                    function(loteData) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: loteData.lote.id,
                                          staticClass: "ma-2",
                                          attrs: {
                                            color: "verdeFayal",
                                            outline: "",
                                            close: ""
                                          },
                                          on: {
                                            input: function($event) {
                                              return _vm.removeLote(
                                                props.item.id,
                                                loteData
                                              )
                                            },
                                            click: function($event) {
                                              return _vm.sendConcepts(loteData)
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(loteData.lote.nombre) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.dad != "Obra"
                              ? _c(
                                  "td",
                                  [
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "verdeFayal"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.addConceptosUrbanizacion(
                                                  props.item.id,
                                                  props.item
                                                )
                                                _vm.im_working_on = "manzanas"
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c("v-icon", [_vm._v("assignment")])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("Conceptos Y Rubros")
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _vm.dad != "Obra"
                    ? _c("h4", [_vm._v("Áreas Verdes")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dad != "Obra"
                    ? _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              "browser-autocomplete": "off",
                              "hide-selected": "",
                              "return-object": "",
                              "no-data-text": "No hay áreas verdes disponibles",
                              placeholder:
                                "Seleccione las áreas verdes a incluir en la etapa",
                              chips: true,
                              multiple: true,
                              "deletable-chips": true,
                              "item-text": "nombre",
                              "item-value": "id",
                              items: _vm.areasVerdes.items
                            },
                            model: {
                              value: _vm.areasVerdes.selected,
                              callback: function($$v) {
                                _vm.$set(_vm.areasVerdes, "selected", $$v)
                              },
                              expression: "areasVerdes.selected"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dad != "Obra"
                    ? _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          "hide-actions": true,
                          "disable-initial-sort": true,
                          headers: _vm.areasVerdes.headers,
                          items: _vm.areasVerdes.selected
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "items",
                              fn: function(props) {
                                return [
                                  _c("td", [_vm._v(_vm._s(props.item.nombre))]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-tooltip",
                                        { attrs: { bottom: "" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                slot: "activator",
                                                icon: "",
                                                flat: "",
                                                dark: "",
                                                small: "",
                                                color: "verdeFayal"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.addConceptosUrbanizacion(
                                                    props.item.id,
                                                    props.item
                                                  )
                                                  _vm.im_working_on =
                                                    "areasVerdes"
                                                }
                                              },
                                              slot: "activator"
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("assignment")
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v("Conceptos Y Rubros")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3049196236
                        )
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dad != "Obra" ? _c("v-divider") : _vm._e(),
                  _vm._v(" "),
                  _vm.dad != "Obra" ? _c("h4", [_vm._v("Calles")]) : _vm._e(),
                  _vm._v(" "),
                  _vm.dad != "Obra"
                    ? _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              "browser-autocomplete": "off",
                              "hide-selected": "",
                              "return-object": "",
                              "no-data-text": "No hay calles disponibles",
                              placeholder:
                                "Seleccione las calles a incluir en la etapa",
                              chips: true,
                              multiple: true,
                              "deletable-chips": true,
                              "item-text": "nombre",
                              "item-value": "id",
                              items: _vm.calles.items
                            },
                            model: {
                              value: _vm.calles.selected,
                              callback: function($$v) {
                                _vm.$set(_vm.calles, "selected", $$v)
                              },
                              expression: "calles.selected"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dad != "Obra"
                    ? _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          "hide-actions": true,
                          "disable-initial-sort": true,
                          headers: _vm.calles.headers,
                          items: _vm.calles.selected
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "items",
                              fn: function(props) {
                                return [
                                  _c("td", [_vm._v(_vm._s(props.item.nombre))]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "v-tooltip",
                                        { attrs: { bottom: "" } },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                slot: "activator",
                                                icon: "",
                                                flat: "",
                                                dark: "",
                                                small: "",
                                                color: "verdeFayal"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.addConceptosUrbanizacion(
                                                    props.item.id,
                                                    props.item
                                                  )
                                                  _vm.im_working_on = "calles"
                                                }
                                              },
                                              slot: "activator"
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("assignment")
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v("Conceptos Y Rubros")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          4275073647
                        )
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.dad == "Obra"
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "verdeFayal", flat: "" },
                          on: { click: _vm.createStoreBody }
                        },
                        [_vm._v("\n          Guardar\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.dad != "Obra"
                    ? _c(
                        "v-btn",
                        {
                          attrs: { color: "verdeFayal", flat: "" },
                          on: { click: _vm.createStoreUrbanizacionBody }
                        },
                        [_vm._v("\n          Guardar\n        ")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("conseptos-rubros-portatil", {
                attrs: {
                  dialog: _vm.dialogConceptosRubros,
                  conceptos: _vm.conceptosToSend,
                  conceptosDad: _vm.conceptosDad,
                  loteData: _vm.loteData,
                  conceptosAutofill: _vm.conceptosAutofill,
                  rubrossAutofill: _vm.rubrossAutofill,
                  dad: _vm.dad,
                  dadName: _vm.dadName,
                  searchConcepts: false,
                  dadId: _vm.selectedId
                },
                on: {
                  close: function($event) {
                    _vm.dialogConceptosRubros = false
                  },
                  dataSended: _vm.associateRubros
                }
              }),
              _vm._v(" "),
              _c("v-divider")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }