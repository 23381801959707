var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" } },
        [
          _c(
            "v-tooltip",
            { attrs: { bottom: "" } },
            [
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      slot: "activator",
                      flat: "",
                      icon: "",
                      href:
                        "/fraccionamientos/" +
                        _vm.fraccionamientoId +
                        "/reporte"
                    },
                    slot: "activator"
                  },
                  [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                  1
                )
              ],
              _vm._v(" "),
              _c("span", [_vm._v("Regresar")])
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "pb-2": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs10: "", "offset-xs1": "" } },
            [
              _c(
                "v-card",
                { staticClass: "card--flex-toolbar" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", prominent: "" } },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-title",
                        { staticClass: "body-2 grey--text" },
                        [
                          _vm._v(
                            "Desglose por conceptos de " +
                              _vm._s(_vm.item.nombre)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c("v-data-table", {
                            ref: "conceptosTable",
                            attrs: {
                              expand: _vm.expand,
                              "disable-initial-sort": "",
                              items: _vm.conceptos.data,
                              "hide-actions": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "headers",
                                fn: function(props) {
                                  return [
                                    _c("tr", [
                                      _c("th", [_vm._v("Nombre")]),
                                      _vm._v(" "),
                                      _c("th", [
                                        _vm._v("Subtotal de materiales")
                                      ]),
                                      _vm._v(" "),
                                      _c("th", [
                                        _vm._v("Subtotal de mano de obra")
                                      ]),
                                      _vm._v(" "),
                                      _c("th")
                                    ])
                                  ]
                                }
                              },
                              {
                                key: "items",
                                fn: function(props) {
                                  return [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(props.item.concepto.nombre)
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "$ " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                props.item.subtotal_materiales
                                              )
                                            )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "$ " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                props.item.subtotal_mano_de_obra
                                              )
                                            )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-right" },
                                        [
                                          _c(
                                            "v-tooltip",
                                            { attrs: { bottom: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    slot: "activator",
                                                    icon: "",
                                                    flat: "",
                                                    dark: "",
                                                    color: "verdeFayal"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openMaterialesModal(
                                                        "lotes",
                                                        props.item.concepto.id
                                                      )
                                                    }
                                                  },
                                                  slot: "activator"
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "customIcon",
                                                    attrs: {
                                                      src:
                                                        "/fonts/local/construction_icon.svg"
                                                    }
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "Ver materiales asociados"
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { flat: "", icon: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openExpand(
                                                    props.item.id,
                                                    props.item.concepto.id,
                                                    props.index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.expanded
                                                      ? "expand_less"
                                                      : "expand_more"
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                }
                              },
                              {
                                key: "expand",
                                fn: function(props) {
                                  return [
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "table table-striped table-hover table-sm"
                                      },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c("th", [_vm._v("Rubro")]),
                                            _vm._v(" "),
                                            _c("th", [
                                              _vm._v("Subtotal de materiales")
                                            ]),
                                            _vm._v(" "),
                                            _c("th", [
                                              _vm._v("Subtotal de mano de obra")
                                            ]),
                                            _vm._v(" "),
                                            _c("th")
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.conceptos.data[props.index]
                                              .rubros,
                                            function(rubro, index) {
                                              return _c("tr", { key: index }, [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(rubro.rubro.nombre)
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    "$ " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          rubro.subtotal_materiales
                                                        )
                                                      )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    "$ " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          rubro.subtotal_mano_de_obra
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td")
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: { limit: 10, data: _vm.conceptos },
                        on: {
                          "pagination-change-page":
                            _vm.getConceptosPorDistribucion
                        }
                      }),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("show-materials-by-distribucion-modal", {
        attrs: {
          show: _vm.showMaterialsModal,
          "distribucion-id": _vm.currentDistribuicionId,
          "distribucion-type": _vm.currentDistribuicionType,
          "concepto-id": _vm.currentConceptoId
        },
        on: {
          "update:show": function($event) {
            _vm.showMaterialsModal = $event
          },
          "update:distribucionId": function($event) {
            _vm.currentDistribuicionId = $event
          },
          "update:distribucion-id": function($event) {
            _vm.currentDistribuicionId = $event
          },
          "update:distribucionType": function($event) {
            _vm.currentDistribuicionType = $event
          },
          "update:distribucion-type": function($event) {
            _vm.currentDistribuicionType = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n          " + _vm._s(_vm.vSnackBar.text) + "\n          "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }