var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    attrs: { type: "hidden", name: "form_previous_url" },
    domProps: { value: _vm.url }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }