<template>
  <div>
    <v-card>
      <v-card-title>
        <v-flex class="card-blog" md3>
          <h1 class="card-title">Obra</h1>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex md3>
          <v-text-field
            color="#1ABA24"
            v-model="search"
            append-icon="search"
            label="Buscar registros en Obra"
            single-line
            hide-details
            sortable="true"
          ></v-text-field>
        </v-flex>
        <obra-create-form
          v-if="$root.can('new', permissions) ? true : false"
          :obras="obras"
          :obra="obra"
          :dialog="dialog"
          @close="cleanForm()"
          @open="dialog = true"
        ></obra-create-form>
      </v-card-title>
      <v-data-table :headers="headers" :items="obrasProps" :search="search" :pagination.sync="pagination">
        <template v-slot:items="obrasProps">
          <td class="text-xs-left">{{obrasProps.item.etapa}}</td>
          <td class="text-xs-left">{{obrasProps.item.descripcion}}</td>
          <td class="text-xs-left">{{obrasProps.item.tiempo_realizacion}}</td>
          <td>
            <v-layout>
              <v-tooltip bottom>
                <v-btn
                  :disabled="!$root.can('update', permissions)"
                  icon
                  flat
                  dark
                  small
                  slot="activator"
                  color="info"
                  @click="editObra(obrasProps.item.id)"
                >
                  <v-icon color="#1ABA24">edit</v-icon>
                </v-btn>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn
                  :disabled="!$root.can('remove', permissions)"
                  icon
                  flat
                  dark
                  small
                  slot="activator"
                  color="error"
                  @click="sweetAlert(obrasProps.item)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <span>Eliminar</span>
              </v-tooltip>
            </v-layout>
          </td>
        </template>
        <template v-slot:no-results>
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert>
        </template>
      </v-data-table>

    </v-card>
  </div>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from "axios";
  import swal from "sweetalert";

  export default {
    props: ['obras', 'permissions'],

    data(){
      return{
        search: '',
        dialog: false,
        obra: {},
        obrasProps: this.obras,
        headers: [
          { text:'Etapa', value: 'etapa' },
          { text: 'Descripcion', value: 'descripcion' },
          { text: 'Tiempo de realizacion', value: 'tiempo_realizacion' },
          { text: 'Acciones', sortable: false}
        ],
        pagination: {
          sortBy: 'created_at', // The field that you're sorting by
          descending: true
        },
      }
    },

    methods: {
      cleanForm() {
        this.obra = {};
        this.dialog = false;
      },

      sweetAlert: function(obra) {
        swal({
          title: "¿Estás seguro?",
          text: "Una vez eliminado no se podrá recuperar",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(willDelete => {
          if (willDelete) {
            // this.removingRole = true;
            this.eliminar(obra.id);
            this.obrasProps.splice(this.obrasProps.indexOf(obra));
            swal("El Registro ha sido eliminado", {
              icon: "success"
            });
          } else {
            swal("El Registro no se eliminó");
          }
        });
      },
  
      eliminar: obraId => {
        var IDobra = obraId;
        axios
          .delete(route("obra.destroy", IDobra))
          .then(response => {
            if (response.data.message_text) {
              alertify.success(response.data.message_text);
            }
          })
          .catch(error => errorHandling(error));
        // .finally(() => this.removingRole = false)
      },
  
      editObra: function(obraId) {
        axios.get(route("obra.edit", obraId)).then(response => {
          this.obra = response.data;
          this.dialog = true;
        });
      }
    }

  };
</script>