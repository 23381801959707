var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "", id: "container-supplier" } },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: !_vm.showFiltersForm, xs9: _vm.showFiltersForm } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Proveedores")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      (_vm.$root.can("new", _vm.permissions)
                      ? true
                      : false)
                        ? _c("proveedores-create", {
                            attrs: {
                              proveedor: _vm.proveedor,
                              proveedores: _vm.providers,
                              dialog: _vm.ShowCreateModal
                            },
                            on: {
                              close: function($event) {
                                return _vm.cleanForm()
                              },
                              open: function($event) {
                                _vm.ShowCreateModal = true
                              }
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", outline: "" },
                          on: {
                            click: function($event) {
                              _vm.showFiltersForm = !_vm.showFiltersForm
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("filter_alt")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", outline: "" },
                          on: { click: _vm.exportToExcel }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("import_export")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.vHeaders,
                      "disable-initial-sort": "",
                      items: _vm.providers.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(_vm._s(props.item.nombre_completo))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(_vm._s(props.item.nombre_vendedor))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(_vm._s(props.item.telefono_oficina))
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              _vm._l(props.item.categorias, function(
                                categoria
                              ) {
                                return _c(
                                  "span",
                                  {
                                    key: categoria.id,
                                    staticClass:
                                      "badge badge-pills badge-light badge-categories",
                                    attrs: { flat: "" }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(categoria.nombre) +
                                        "\n              "
                                    )
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(
                                _vm._s(
                                  props.item.tipo_credito == "0"
                                    ? "Contado"
                                    : props.item.tipo_credito + " días"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-xs-left" },
                              [
                                _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: !_vm.$root.can(
                                                "update",
                                                _vm.permissions
                                              ),
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "verdeFayal"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.editproveedor(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("edit")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Editar")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: !_vm.$root.can(
                                                "remove",
                                                _vm.permissions
                                              ),
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "error"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sweetAlert(
                                                  props.item
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("delete")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Eliminar")])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.providers
                        },
                        on: { "pagination-change-page": _vm.getProviders }
                      }),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.showFiltersForm
            ? _c(
                "v-flex",
                { attrs: { xs3: "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { color: "white", flat: "" } },
                        [
                          _c("v-toolbar-title", [_vm._v("Filtros")]),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                icon: "",
                                color: "verdeFayal",
                                dark: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.showFiltersForm = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          multiple: "",
                                          chips: "",
                                          items: _vm.categorias,
                                          label: "Categoria",
                                          "item-text": "nombre",
                                          "item-value": "id",
                                          clearable: "",
                                          hint:
                                            "Los elementos seleccionados son incluyentes"
                                        },
                                        model: {
                                          value: _vm.filters.filterCategoria,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "filterCategoria",
                                              $$v
                                            )
                                          },
                                          expression: "filters.filterCategoria"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: this.creditos,
                                          "item-text": "nombre",
                                          "item-value": "dias",
                                          label: "Tipo de crédito",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.filters.filterTipoCredito,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "filterTipoCredito",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.filterTipoCredito"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                color: "verdeFayal",
                                small: "",
                                flat: "",
                                outline: ""
                              },
                              on: { click: _vm.limpiar }
                            },
                            [_vm._v("Limpiar")]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                color: "verdeFayal",
                                small: ""
                              },
                              on: { click: _vm.filterProveedores }
                            },
                            [_vm._v("Filtrar")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }