<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="850px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Crear cliente</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex md4>
                  <v-text-field label="Nombre" v-model="cliente.nombre" required></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field label="Apellido paterno" v-model="cliente.apellido_paterno" required></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field label="Apellido materno" v-model="cliente.apellido_materno"></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field
                    label="Fecha de aprobación"
                    v-model="cliente.fecha_aprobacion"
                    type="date"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field mask="(###)-###-##-##" label="Teléfono" v-model="cliente.telefono" required></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field
                    label="Correo"
                    v-model="cliente.email"
                  ></v-text-field>
                </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark outline color="verdeFayal" @click="clienteCreate" flat :loading="vBtnSave.loading"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { errorHandling } from "../../lib/utilities";
export default {
  props: ["show"],
  data() {
    return {
      cliente: {},
      localShow: false,
      vBtnSave: {
        loading: false
      },
      vSnackBar: {
        visible: false,
        text: null
      }
    };
  },

  methods: {
    clienteCreate() {
      this.vBtnSave.loading = true;
      axios.post("/clientes", this.cliente)
      .then(response => {
        this.cliente = {}
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;
        this.localShow = false;
      })
      .catch(error => errorHandling(error))
      .finally(() => this.vBtnSave.loading = false);
    }
  },

  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
  }
};
</script>