<template>
  <v-layout>
    <v-container>
      <v-flex xs12>
        <v-card>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Solicitud de material</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <v-btn class="fyl-btnCreate" href="/solicitud_de_material/create" v-if="$root.can('new', permissions) ? true : false">Crear</v-btn>
          </v-toolbar>

          <v-data-table :headers="vHeaders" disable-initial-sort :items="salidas.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td>{{ props.item.folio }}</td>
              <td>{{ props.item.nombre }}</td>
              <td>{{ props.item.fecha_solicitud }}</td>
              <td class="td-ellipsis">{{ props.item.solicitante }}</td>
              <td>{{ props.item.estado.information.estado }}</td>
              <td>
                <v-tooltip bottom>
                  <v-btn :disabled="!$root.can('materials', permissions)" @click="openRequisiconesAsociadasModal(props.item.id)"  icon flat dark small slot="activator" color="verdeFayal">
                      <v-icon>list</v-icon>
                  </v-btn>
                  <span>Requisiciones relacionadas</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn :disabled="!$root.can('materials', permissions)" @click="openAddMaterialsValuesModal(props.item.id,props.item.nombre)"  icon flat dark small slot="activator" color="verdeFayal">
                      <v-icon>vertical_split</v-icon>
                  </v-btn>
                  <span>Materiales</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn icon flat dark small slot="activator" color="verdeFayal" @click="openRequisitionsModal(props.item.id)">
                    <v-icon>assignment</v-icon>
                  </v-btn>
                  <span>Añadir requisición</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="salidas" @pagination-change-page="getSalidas"></pagination>
          </v-card-actions>
        </v-card>
      </v-flex>
      <!-- Modals -->
      <manage-materials-to-salidas-almacen-modal
        v-bind:show.sync="showMaterialsToValuesModal"
        v-bind:item-id.sync="currentItemId"
        v-bind:modelType.sync="currentModel"
        v-bind:modelTypeOther.sync="otherModel"
        v-bind:name.sync="currentItemName"
      ></manage-materials-to-salidas-almacen-modal>
      <!-- requisitions modal -->
      <requisiciones-create
        :show.sync="showRequisitionsModal"
        :requisicion_id="requisicionID"
      ></requisiciones-create>
      <requisiciones-asociadas-modal
        :show.sync="showRequisicionesAsociadasModal"
        :solicitud_id="currentSalidaId"
      ></requisiciones-asociadas-modal>
    </v-container>
  </v-layout>
</template>

<script>
import swal from "sweetalert";

export default {
  data(){
    return{
      search: null,
      showCreateModal: false,
      showEditModal: false,
      salidas:[],
      currentSalidaId: null,
      showMaterialsToValuesModal: false,
      showRequisitionsModal: false,
      showRequisicionesAsociadasModal: false,
      currentModel: 'App\\SalidasAlmacen',
      otherModel: 'App\\MantenimientoRelacion',
      currentItemId: null,
      currentItemName: null,
      vPaginator: {
        limit: 10
      },
      vHeaders: [
        {text: 'Folio', value: 'folio'},
        {text: 'Nombre', value: 'nombre'},
        {text: 'Fecha de solicitud', value: 'fecha_solicitud'},
        {text: 'Solicitante', value: 'categoria_asociada'},
        {text: 'Estado', value: 'unidad'},
        {text: 'Acciones', value: 'acciones', sortable: false}
      ],
      requisicionID: null,
    }
  },
  props:['permissions'],
  methods:{
    getValues: function() {
      axios.get(`/salidas_de_almacen`)
      .then(response => {this.data = response.data})
      .catch( error => errorHandling(error))
    },
    getSalidas: function (page = 1) {
      axios.get(`/salidas_de_almacen?page=${page}&query=${this.search}`)
      .then(response => {
        this.salidas = response.data;
      })
      .catch(error => errorHandling(error));
    },
    deleteSalida: function(salidaId){
    swal({
      title: "¿Estás seguro?",
      text: "Una vez eliminado no se podrá recuperar",
      icon: "warning",
      buttons: true,
      dangerMode: true
      }).then(result => {
        if (result) {
          axios
          .delete(laravelRoute('salidas_de_almacen.destroy', salidaId))
          .then(response => {
            this.getSalidas();
            alertify.success(response.data.message_text);
          })
          .catch(error => {
            errorHandling(error);
          });
        }
      })
    },
    openCreateModal: function(){
      this.showCreateModal = true;
    },
    openEditMaterialModal: function(salidaId){
      this.showEditModal = true;
      this.currentSalidaId = salidaId;
    },
    openAddMaterialsValuesModal: function(currentItemId, currentItemName) {
      this.showMaterialsToValuesModal = true;
      this.currentItemId = currentItemId;
      this.currentItemName = currentItemName;
    },
    openRequisitionsModal: function(requisicionId){
      this.showRequisitionsModal = true;
      this.requisicionID = requisicionId;
    },
    goBack: function () {
      window.history.back();
    },
    openRequisiconesAsociadasModal: function(salidaId) {
      this.showRequisicionesAsociadasModal = true;
      this.currentSalidaId = salidaId;
    }
  },
  watch:{
    showCreateModal: function (value) {
      if (!value) {
        this.getSalidas();
      }
    },
    showEditModal: function (value) {
      if (!value) {
        this.getSalidas();
      }
    },
    showMaterialsToValuesModal: function(value) {
      if(!value) {
        this.getValues()
      }
    },
    showRequisitionsModal: function(value){
      if(!value) {
        this.getSalidas()
      }
    },
    search: function (value) {
      this.getSalidas();
    },
  },
  created(){
    this.getSalidas();
  }
}
</script>