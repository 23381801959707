<template>
  <v-layout>
    <v-dialog :value="dialog" persistent max-width="700px" hide-overlay>
      <v-card>
        <v-toolbar dark color="verdeFayal">
          <v-btn
            icon
            dark
            @click="$emit('close');"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Asignar Garantías</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table
              v-model="garantias.selected"
              :hide-actions="true"
              :disable-initial-sort="true"
              :headers="garantias.headers"
              :items="garantias.items"
              class="elevation-1"
            > 
              <template v-slot:headers="props">
                <tr>
                  <th>
                    <v-checkbox
                      :input-value="props.all"
                      :indeterminate="props.indeterminate"
                      primary
                      hide-details
                      @click.stop="toggleAll"
                    ></v-checkbox>
                  </th>
                  <th
                    v-for="header in props.headers"
                    :key="header.text"
                    :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                    @click="changeSort(header.value)"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </template>
              <template v-slot:items="props">
                <tr v-if="!garantias.disableds.includes(props.item.id)" :active="props.selected" @click="props.selected = !props.selected">
                  <td>
                    <v-checkbox
                      :input-value="props.selected"
                      primary
                      hide-details
                    ></v-checkbox>
                  </td>
                  <td>{{ props.item.nombre }}</td>
                  <td>{{ props.item.descripcion }}</td>
                  <td>{{ props.item.meses }}</td>
                </tr>
              </template>
            </v-data-table>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="verdeFayal"
            flat
            @click="!!garantias.garantiasViviendas ? updateGarantias() : storeGarantias()"
          >
            {{!!garantias.garantiasViviendas ? 'Actualizar': 'Guardar'}}
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { errorHandling } from "../../lib/utilities";
export default {
  props: ["dialog", 'vivienda'],

  data() {
    return {
      pagination: {
        sortBy: 'name'
      },
      garantias:{
        items: [],
        garantiasViviendas: [],
        garantiasTotales: [],
        selected: [],
        headers:[
          {
            text: 'Nombre',
            sortable: false,
          },
          {
            text: 'Descripción',
            sortable: false,
          },
          {
            text: 'Duración en meses',
            sortable: false,
          },
        ],
        disableds: [],
      }
    };
  },
  watch:{
    dialog: async function(value){
      if(!value){
      }else{
        await this.getGarantias();
        await this.getGarantiasDeVivienda();
        let itemsWithoutSelected = this.garantias.garantiasTotales.filter(item => this.findItem(item.id) ? null : item);
        this.garantias.items = itemsWithoutSelected;
      }
    },
  },
  methods: {
    findItem: function (itemId) {
      return this.garantias.garantiasViviendas.find(element => element.information.id == itemId);
    },
    getGarantias:  function () {
      return  axios.get(laravelRoute('garantias.index'))
      .then(response => {
        this.garantias.garantiasTotales = response.data;
      })
      .catch(error => {
        errorHandling(error);
      });
    },
    getGarantiasDeVivienda:  function () {
      return  axios.get(laravelRoute('viviendas.show', this.vivienda.id))
      .then(response => {
        this.garantias.garantiasViviendas = response.data.garantias;
      })
      .catch(error => {
        errorHandling(error);
      });
    },
    storeGarantias: function () {
      axios.post(laravelRoute('viviendas.store'), {
        vivienda_id: this.vivienda.id,
        garantias_array: this.garantias.selected.map(element => element.id)
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.$emit('close');
      })
      .catch(error => {
        errorHandling(error);
      });
    },
    updateGarantias: function () {
      axios.patch(laravelRoute('viviendas.update', this.vivienda.id), {
        vivienda_id: this.vivienda.id,
        garantias_array: [...this.garantias.selected.map(element => element.id), ...this.garantias.garantiasViviendas.map(element => element.information.id)]
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.$emit('close');
      })
      .catch(error => {
        errorHandling(error);
      });
    },
    toggleAll () {
      if (this.garantias.selected.length) this.garantias.selected = []
      else this.garantias.selected = this.garantias.items.slice()
    },
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    }
  }
};
</script>S