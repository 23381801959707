<template>
  <v-card flat>
    <v-toolbar color="verdeFayal" dark extended flat>
      <v-spacer></v-spacer>
      <v-toolbar-title>Sistema Integral de Control de Proyectos de Obra SICPRO</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-divider></v-divider>
    <v-card-text>
      <v-container fluid grid-list-lg>
        <v-layout row wrap>
          <v-flex xs3>
            <v-hover v-slot:default="{ hover }">
              <v-card href="/usuarios" color="#52b8c4" class="white--text" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                <v-layout>
                  <v-flex xs7>
                    <v-card-title primary-title>
                      <div>
                        <div class="display-3">{{usuarios}}</div>                  
                      </div>
                    </v-card-title>
                  </v-flex>
                  <v-flex xs5>
                    <v-avatar>
                      <v-icon size="55" dark>account_circle</v-icon>
                    </v-avatar>
                  </v-flex>
                </v-layout>
                <v-divider light></v-divider>
                <v-card-actions class="pa-3">
                  <div>Usuarios</div>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-flex>
          
          <v-flex xs3>
            <v-hover v-slot:default="{ hover }">
              <v-card href="/trabajadores" color="#3350b9" class="white--text" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                <v-layout>
                  <v-flex xs7>
                    <v-card-title primary-title>
                      <div>
                        <div class="display-3">{{trabajadores}}</div>                  
                      </div>
                    </v-card-title>
                  </v-flex>
                  <v-flex xs5>
                    <v-avatar style="height: 120px; width: 50px;">
                      <v-icon dark size="55">supervisor_account</v-icon>
                    </v-avatar>
                  </v-flex>
                </v-layout>
                <v-divider light></v-divider>
                <v-card-actions class="pa-3">
                  <div>Trabajadores</div>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-flex>
          
          <v-flex xs3>
            <v-hover v-slot:default="{ hover }">
              <v-card href="/clientes" color="#ff5617" class="white--text" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                <v-layout>
                  <v-flex xs7>
                    <v-card-title primary-title>
                      <div>
                        <div class="display-3">{{clientes}}</div>                  
                      </div>
                    </v-card-title>
                  </v-flex>
                  <v-flex xs5>
                    <v-avatar style="height: 120px; width: 50px;">
                      <v-icon dark size="55">group</v-icon>
                    </v-avatar>
                  </v-flex>
                </v-layout>
                <v-divider light></v-divider>
                <v-card-actions class="pa-3">
                  <div>Clientes</div>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-flex>

          <v-flex xs3>
            <v-hover v-slot:default="{ hover }">
              <v-card href="/prospectos" color="#ffc107" class="white--text" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                <v-layout>
                  <v-flex xs7>
                    <v-card-title primary-title>
                      <div>
                        <div class="display-3">{{prospectos}}</div>                  
                      </div>
                    </v-card-title>
                  </v-flex>
                  <v-flex xs5>
                    <v-avatar style="height: 120px; width: 50px;">
                      <v-icon dark size="55">next_week</v-icon>
                    </v-avatar>
                  </v-flex>
                </v-layout>
                <v-divider light></v-divider>
                <v-card-actions class="pa-3">
                  <div>Prospectos</div>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-flex>

          <v-flex xs3>
            <v-hover v-slot:default="{ hover }">
              <v-card href="/fraccionamientos" color="#ffc107" class="white--text" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                <v-layout>
                  <v-flex xs7>
                    <v-card-title primary-title>
                      <div>
                        <div class="display-3">{{fraccionamientos}}</div>                  
                      </div>
                    </v-card-title>
                  </v-flex>
                  <v-flex xs5>
                    <v-avatar style="height: 120px; width: 50px;">
                      <v-icon dark size="55">home_work</v-icon>
                    </v-avatar>
                  </v-flex>
                </v-layout>
                <v-divider light></v-divider>
                <v-card-actions class="pa-3">
                  <div>Fraccionamientos</div>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-flex>

          <v-flex xs3>
            <v-hover v-slot:default="{ hover }">
              <v-card href="/urbanizacion" color="#ff5617" class="white--text" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                <v-layout>
                  <v-flex xs7>
                    <v-card-title primary-title>
                      <div>
                        <div class="display-3">{{urbanizacion}}</div>                  
                      </div>
                    </v-card-title>
                  </v-flex>
                  <v-flex xs5>
                    <v-avatar style="height: 120px; width: 50px;">
                      <v-icon dark size="55">domain</v-icon>
                    </v-avatar>
                  </v-flex>
                </v-layout>
                <v-divider light></v-divider>
                <v-card-actions class="pa-3">
                  <div>Conceptos de urbanización</div>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-flex>

          <v-flex xs3>
            <v-hover v-slot:default="{ hover }">
              <v-card href="/conceptos" color="#3350b9" class="white--text" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                <v-layout>
                  <v-flex xs7>
                    <v-card-title primary-title>
                      <div>
                        <div class="display-3">{{obras}}</div>                  
                      </div>
                    </v-card-title>
                  </v-flex>
                  <v-flex xs5>
                    <v-avatar style="height: 120px; width: 50px;">
                      <v-icon dark size="55">domain</v-icon>
                    </v-avatar>
                  </v-flex>
                </v-layout>
                <v-divider light></v-divider>
                <v-card-actions class="pa-3">
                  <div>Conceptos de obra</div>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-flex>

          <v-flex xs3>
            <v-hover v-slot:default="{ hover }">
              <v-card href="/prototipos_vivienda" color="#52b8c4" class="white--text" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                <v-layout>
                  <v-flex xs7>
                    <v-card-title primary-title>
                      <div>
                        <div class="display-3">{{viviendas}}</div>                  
                      </div>
                    </v-card-title>
                  </v-flex>
                  <v-flex xs5>
                    <v-avatar style="height: 120px; width: 50px;">
                      <v-icon dark size="55">house</v-icon>
                    </v-avatar>
                  </v-flex>
                </v-layout>
                <v-divider light></v-divider>
                <v-card-actions class="pa-3">
                  <div>Prototipos de vivienda</div>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

  </v-card>
</template>

<style>
</style>

<script>

  export default {
    data() {
      return {
      }
    },
    props:['obras',
    'urbanizacion',
    'fraccionamientos',
    'viviendas',
    'prospectos',
    'clientes',
    'trabajadores',
    'usuarios'
    ],
  }
</script>

<style scoped>
  .v-avatar {
    height: 120px !important; 
    width: 48px  !important;
  }  
</style>