<template>
  <v-flex xs12 sm6 md3>
    <v-text-field
    label="componente de ejemplo de vuetify"
    ></v-text-field>
      
    <v-icon class="black--text">more_vert</v-icon> Icono con material design

    <v-btn class="mx-0" outline small color="green darken-2" slot="activator" v-on:click="sweetAlert">
      ejemplo sweet alert
    </v-btn>
    <v-btn class="mx-0" outline small color="green darken-2" slot="activator" v-on:click="exampleOk">
      ejemplo success alertify 
    </v-btn>
    <v-btn class="mx-0" outline small color="red darken-2" slot="activator" v-on:click="exampleError">
      ejemplo error alertify 
    </v-btn>

    <v-btn class="mx-0" outline small color="red darken-2" slot="activator" v-on:click="showExampleModal = true">
      ejemplo modal
    </v-btn>

    <example-modal-component v-bind:show.sync="showExampleModal" v-on:dataSended="showText" />
  </v-flex>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    mounted() {
      console.log('Se cargó bien el componente.')
    },
    data() {
      return {
        showExampleModal: false,
      }
    },
    props: [
      
    ],
    watch: {

    },
    methods: {
      showText: function (textValue){
        alertify.success(textValue);
      },
      sweetAlert: function(){
          swal("Hello world!");                
      },
      exampleError: function () {
        return axios.get(laravelRoute('ejemplo.error'))
          .then(response => { 
              return response.data.splice(1, response.data.length-1);
          })
          .catch(error => errorHandling(error));
      },
      exampleOk: function () {
        return axios.get(laravelRoute('ejemplo.ok'))
          .then(response => { 
              if (response.data.message_text) alertify.success(response.data.message_text)
          })
          .catch(error => errorHandling(error));
      }
    },
    computed: {

    },
  }
</script>
