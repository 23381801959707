<template>
  <div class="text-xs-center">
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          <v-flex md8>
            <p>{{type}}</p>
          </v-flex>
          <v-flex md4>
            <v-btn color="warning" flat @click="pickFile" :disabled="$store.state.saveDisabled || edit_disabled">
              Adjuntar
              <v-icon right>attach_file</v-icon>
            </v-btn>
            <input type="file" style="display: none" ref="file" @change="fileSelected" />
          </v-flex>
        </v-card-title>
        <v-flex md12 v-show="Uploading > 0 && Uploading < 100">
          <v-progress-linear v-model="Uploading"></v-progress-linear>
        </v-flex>
        <v-card-text>
          <table class="table">
            <thead>
              <th>Nombre del archivo</th>
              <th>Acciones</th>
            </thead>
            <tbody>
              <tr v-for="archivo in archivos" :key="archivo.id">
                <td>{{ archivo.filename }}</td>
                <td>
                  <v-tooltip bottom>
                    <v-btn
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="success"
                      :href="archivo.url"
                      target="_blank"
                    >
                      <v-icon>launch</v-icon>
                    </v-btn>
                    <span>Ver</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      icon
                      flat
                      dark
                      slot="activator"
                      color="error"
                      :disabled="edit_disabled"
                      @click="deleteFile(archivo)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <span>Eliminar</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="$emit('close')" :disabled="closeDisabled">Cerrar</v-btn>
          <v-btn
            color="primary"
            flat
            @click="storeFiles"
            :disabled="$store.state.saveDisabled || saveDisabled"
          >Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["dialog", "element_type", "element_id", "edit_disabled", "type"],

  data() {
    return {
      saveDisabled: true,
      closeDisabled: false,
      archivo: {},
      archivos: [],
      archivosOld: []
    };
  },

  methods: {
    pickFile: function() {
      this.$refs.file.click();
    },

    fileSelected: function(e) {
      if (e.target.files[0]) {
        this.$root.awsuploader(e.target.files[0]);
        this.archivo.associated_type = this.element_type;
        this.archivo.associated_id = this.element_id;
        this.archivo.type = this.type;
        this.$refs.file.value = "";
      }
    },

    storeFiles: function() {
      axios
        .post("/files", this.archivos)
        .then(response => {
          if (response.data.message_text)
            alertify.success(response.data.message_text);
          this.$root.cleanStore();
          this.saveDisabled = true;
          this.closeDisabled = false;
          this.$emit("close");
          this.archivos = [];
          setTimeout(() => {
                window.location.href = "/clientes";
              }, 300);
        })
        .catch(error => errorHandling(error));
    },

    deleteFile: function(file) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
          this.$root.awseraser(file);
          if (file.id) {
            axios
              .delete(route("files.destroy", file.id))
              .then(response => {})
              .catch(error => errorHandling(error));
            this.archivosOld = this.archivosOld.filter(function(ele) {
              return ele.id != file.id;
            });
          } else {
            this.archivos = this.archivos.filter(function(ele) {
              return ele.aws_uuid != file.aws_uuid;
            });
            this.archivosOld = this.archivosOld.filter(function(ele) {
              return ele.aws_uuid != file.aws_uuid;
            });
            this.archivos.length == 0
              ? ((this.saveDisabled = true), (this.closeDisabled = false))
              : ((this.saveDisabled = false), (this.closeDisabled = true));
          }
          swal("El Registro ha sido eliminado", {
            icon: "success"
          });
        } else {
          swal("El Registro no se eliminó");
        }
      });
    }
  },

  computed: {
    Uploading: function() {
      return this.$store.state.loaded;
    },

    filename: function() {
      return this.$store.state.fileName;
    },

    FileUpload: function() {
      return this.$store.state.urlFileUpload;
    },

    uuidFileUpload: function() {
      return this.$store.state.uuidFile;
    },

    dialogOpen() {
      return this.dialog;
    }
  },

  watch: {
    Uploading: function() {
      this.archivo.upload = this.Uploading;
    },

    filename: function() {
      this.archivo.filename = this.filename;
    },

    uuidFileUpload: function() {
      this.archivo.aws_uuid = this.uuidFileUpload;
    },

    FileUpload: function() {
      this.archivo.url = this.FileUpload;
      if (this.FileUpload) {
        this.archivos.push(this.archivo);
        this.archivosOld.push(this.archivo);
        this.archivo = {};
        this.$root.cleanStore();
        this.saveDisabled = false;
        this.closeDisabled = true;
      }
    },

    dialogOpen: function() {
      axios
        .post(`/files/get/${this.element_id}`, {
          element_type: this.element_type
        })
        .then(response => {
          this.archivosOld = response.data;
        })
        .catch(error => errorHandling(error));
    }
  }
};
</script>