var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Notificaciones")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "", color: "verdeFayal " },
                          on: { click: _vm.markAllAsRead }
                        },
                        [
                          _c("v-icon", [_vm._v("mark_chat_read")]),
                          _vm._v(" Marcar todas como vistas\n          ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("table", { staticClass: "table table-hover" }, [
                    _c("thead", [
                      _c(
                        "tr",
                        _vm._l(_vm.headers, function(header, key) {
                          return _c(
                            "th",
                            { key: key, attrs: { scope: "col" } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(header.text) +
                                  "\n              "
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.notificaciones.data, function(
                        notificacion,
                        i
                      ) {
                        return _c("tr", { key: i }, [
                          _c("td", [
                            _vm._v(_vm._s(JSON.parse(notificacion.data).msg))
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(notificacion.created_at))]),
                          _vm._v(" "),
                          _c(
                            "td",
                            [
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-tooltip",
                                    { attrs: { bottom: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          class: notificacion.read_at
                                            ? "disable-events"
                                            : null,
                                          attrs: {
                                            slot: "activator",
                                            color: notificacion.read_at
                                              ? "#34b7f1"
                                              : "grey",
                                            icon: "",
                                            flat: "",
                                            dark: "",
                                            small: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.markAsRead(
                                                notificacion
                                              )
                                            }
                                          },
                                          slot: "activator"
                                        },
                                        [_c("v-icon", [_vm._v("done_all")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            notificacion.read_at
                                              ? "Notificación vista"
                                              : "Marcar como vista"
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      }),
                      0
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.notificaciones
                        },
                        on: { "pagination-change-page": _vm.getNotifications }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }