
<template>
  <v-card flat>
    <v-toolbar color="verdeFayal" dark extended flat>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn flat icon :href="`/etapas/${etapa.id}/actividades`">
            <v-icon>keyboard_arrow_left</v-icon>
          </v-btn>
        </template>
        <span>Regresar</span>
      </v-tooltip>
    </v-toolbar>

      <v-layout row pb-2>
        <v-flex xs10 offset-xs1>
          <v-card class="card--flex-toolbar">
            <v-toolbar card prominent>
              <v-spacer></v-spacer>
              <v-toolbar-title class="body-2 grey--text">Actividades de la {{ manzana.nombre }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout>
                  <v-flex xs5>
                    <v-card dark color='#75d57b'>
                      <v-layout>
                        <v-flex xs8>
                          <v-card-title primary-title>
                            <div>
                              <div class="headline total-title">Iniciadas</div>
                              <div> Progreso de actividades Iniciadas</div>
                            </div>
                          </v-card-title>
                        </v-flex>
                        <v-flex xs4>
                          <v-layout  align-center justify-center row fill-height>
                            <v-container>
                              <v-progress-circular
                                :rotate="180"
                                :size="75"
                                :width="10"
                                :value="etapa.porcentaje_de_avance.iniciado"
                                color="white"
                              >
                                {{ etapa.porcentaje_de_avance.iniciado.toFixed(1) == '100.0' ? '100' : etapa.porcentaje_de_avance.iniciado.toFixed(1)}}%
                              </v-progress-circular>
                            </v-container>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <v-flex xs2></v-flex>
                  <v-flex xs5>
                    <v-card dark color='#75d57b'>
                      <v-layout>
                        <v-flex xs8>
                          <v-card-title primary-title>
                            <div>
                              <div class="headline total-title">Finalizadas</div>
                              <div> Progreso de actividades finalizadas</div>
                            </div>
                          </v-card-title>
                        </v-flex>
                        <v-flex xs4>
                          <v-layout  align-center justify-center row fill-height>
                            <v-container>
                              <v-progress-circular
                                :rotate="180"
                                :size="75"
                                :width="10"
                                :value="etapa.porcentaje_de_avance.finalizado"
                                color="white"
                              >
                                {{ etapa.porcentaje_de_avance.finalizado.toFixed(1) == '100.0' ? '100' : etapa.porcentaje_de_avance.finalizado.toFixed(1)}}%
                              </v-progress-circular>
                            </v-container>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>

                <v-divider></v-divider>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-breadcrumbs style="margin: 1%;" :items="breadcrums" divider=">"></v-breadcrumbs>
                  </v-flex>
                  <v-flex xs12>
                    <v-progress-linear v-if="loading" color="verdeFayal" :indeterminate="true"/>
                    <v-data-table
                      :items="lotes.data"
                      hide-actions
                      item-key="id"
                    >
                      <template v-slot:headers>
                        <tr>
                          <th>Lote</th>
                          <th>Porcentaje de avance</th>
                          <th class="text-xs-right">Acciones</th>
                        </tr>
                      </template>

                      <template v-slot:items="props">
                        <tr>
                          <td>{{ props.item.lote.nombre}}</td>
                          <td>
                            <div class="progress">
                              <div 
                                class="progress-bar progress-bar-striped progress-bar-animated bg-success" 
                                role="progressbar" 
                                :style="`width: ${props.item.porcentaje_de_avance}%;`" 
                                :aria-valuenow="props.item.porcentaje_de_avance" 
                                aria-valuemin="0" 
                                aria-valuemax="100"
                              >
                                {{ props.item.porcentaje_de_avance.toFixed(2) }}%
                              </div>
                            </div>
                          </td>
                          <td class="text-xs-right">
                            <v-btn flat icon :href="`/etapas/${etapa.id}/actividades/manzana/${manzana.id}/lote/${props.item.id}`">
                              <v-icon>chevron_right</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <pagination :limit="10" :data="lotes" @pagination-change-page="getLotes"></pagination>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
  </v-card>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    
    data() {
      return {
        lotes: {
          data: []
        },
        loading: false
      }
    },
    props: {
      etapa: Object,
      manzana: Object
    },
    computed: {
      breadcrums: function() {
        return [
          {
            text: `${this.etapa.nombre}`,
            disabled: false,
            href: `/etapas/${this.etapa.id}/actividades`
          },
          {
            text: `${this.manzana.nombre}`,
            disabled: true,
          }
        ]
      }
    },
    methods: {
      getLotes: function(page = 1){
        this.loading = true;
        axios.get(`/etapas/${this.etapa.id}/manzanas/${this.manzana.id}/lotes?page=${page}`)
        .then(response => this.lotes = response.data)
        .catch(error => errorHandling(error))
        .finally(() => this.loading = false)
      }
    },
    mounted() {
      this.getLotes();
    }
  }
</script>
