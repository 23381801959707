<template>
  <div>
    <v-dialog v-model="localShow" max-width="850px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Requisiciones asociadas</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md class="text-xs-center">
          <v-layout wrap>
            <v-flex md12 class="text-xs-center">
                <table class="table table-borderless table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Folio</th>
                      <th scope="col">Nombre</th>
                      <th scope="col">Solicitud</th>
                      <th scope="col">Fecha de llegada</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in solicitud.requisiciones" :key="item.id">
                      <td>{{ item.requisicion.folio }}</td>
                      <td>{{ item.requisicion.nombre_requisicion }}</td>
                      <td>{{ item.requisicion.solicitante }}</td>
                      <td>{{ (!item.requisicion.fecha_de_llegada) ? "Aún sin especificar" : item.requisicion.fecha_de_llegada }}</td>
                    </tr>
                  </tbody>
                </table>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data(){
    return{
      solicitud: {},
      localShow: false,
      vHeaders: [
        {text: 'Folio', value: 'folio'},
        {text: 'Nombre', value: 'nombre_requisicion'},
        {text: 'Solicitud', value: 'solicitante'},
        {text: 'Fecha de llegada', value: 'fecha_de_llegada'}
      ]
    }
  },
  props:{
    show: Boolean,
    solicitud_id: Number
  },
  methods:{
    getRequisicion: function (page = 1) {
        axios.get(laravelRoute('solicitud_de_material.show', this.solicitud_id))
        .then(response => {
          this.solicitud = response.data;
        })
        .catch(error => errorHandling(error));
    }
  },
  watch:{
    show: function (value) {
      this.localShow = value;
      this.choosen = 0;
    },
    localShow: function (value) {
      this.$emit('update:show', value);
      this.getRequisicion();
    },
    solicitud_id: function() {
      this.getRequisicion();
    }
  }
}
</script>