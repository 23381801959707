var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.localShow, persistent: "", "max-width": "700px" }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Crear trámite")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.dependencias,
                                      "item-text": "nombre_dependencia",
                                      "item-value": "id",
                                      label: "Dependencias",
                                      rules: _vm.rules
                                    },
                                    on: { change: _vm.getDepartamentos },
                                    model: {
                                      value: _vm.tramite.dependencia_id,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.tramite,
                                          "dependencia_id",
                                          $$v
                                        )
                                      },
                                      expression: "tramite.dependencia_id"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _vm.tramite.dependencia_id &&
                                  _vm.departamentos.length
                                    ? _c("v-select", {
                                        attrs: {
                                          items: _vm.departamentos,
                                          "item-text": "nombre_departamento",
                                          "item-value": "id",
                                          label: "Departamento",
                                          rules: _vm.rules
                                        },
                                        on: { change: _vm.getTramites },
                                        model: {
                                          value: _vm.tramite.departamento_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tramite,
                                              "departamento_id",
                                              $$v
                                            )
                                          },
                                          expression: "tramite.departamento_id"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _vm.tramite.dependencia_id &&
                                  _vm.tramites.length
                                    ? _c("v-select", {
                                        attrs: {
                                          items: _vm.tramites,
                                          "item-text": "nombre_tramite",
                                          "item-value": "id",
                                          label: "Trámites",
                                          "return-object": "",
                                          rules: _vm.rules
                                        },
                                        model: {
                                          value: _vm.tramite.tramite,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.tramite,
                                              "tramite",
                                              $$v
                                            )
                                          },
                                          expression: "tramite.tramite"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("searcher", {
                                    attrs: {
                                      configuration: _vm.searchComponent,
                                      itemSelected:
                                        _vm.tramite.proyecto_perteneciente
                                    },
                                    on: {
                                      "update:itemSelected": function($event) {
                                        return _vm.$set(
                                          _vm.tramite,
                                          "proyecto_perteneciente",
                                          $event
                                        )
                                      },
                                      "update:item-selected": function($event) {
                                        return _vm.$set(
                                          _vm.tramite,
                                          "proyecto_perteneciente",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "verdeFayal",
                                      type: "date",
                                      label: "Fecha de resolución",
                                      rules: _vm.rules
                                    },
                                    model: {
                                      value: _vm.tramite.fecha_resolucion,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.tramite,
                                          "fecha_resolucion",
                                          $$v
                                        )
                                      },
                                      expression: "tramite.fecha_resolucion"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !this.tramite.id
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm6: "", md6: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          color: "verdeFayal",
                                          items: _vm.estados,
                                          label: "Seleccione el estado",
                                          "item-text": "estado",
                                          "item-value": "id",
                                          rules: _vm.rules
                                        },
                                        model: {
                                          value: _vm.tramite.estado,
                                          callback: function($$v) {
                                            _vm.$set(_vm.tramite, "estado", $$v)
                                          },
                                          expression: "tramite.estado"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      color: "verdeFayal",
                                      label: "Descripción",
                                      type: "textarea",
                                      counter: "250",
                                      maxlength: _vm.max
                                    },
                                    model: {
                                      value: _vm.tramite.descripcion,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.tramite,
                                          "descripcion",
                                          $$v
                                        )
                                      },
                                      expression: "tramite.descripcion"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "verdeFayal",
                        flat: "",
                        dark: "",
                        outline: ""
                      },
                      on: { click: _vm.storeTramite }
                    },
                    [_vm._v("\n          Guardar\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }