var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { xs12: "" } },
    [
      _c(
        "v-container",
        [
          _c(
            "v-expansion-panel",
            _vm._l(_vm.data.conceptos, function(concepto, i) {
              return _c(
                "v-expansion-panel-content",
                {
                  key: i,
                  staticClass: "white",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function() {
                          return [
                            _c("h4", { staticClass: "primary-title" }, [
                              _vm._v(_vm._s(concepto.nombre))
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  )
                },
                [
                  _vm._v(" "),
                  _c("v-card", { staticClass: "grey lighten-3" }, [
                    _c(
                      "table",
                      { staticClass: "table table-hover table-borderless" },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("Rubro")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Tiempo de ejecución")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Subtotal de material")]),
                            _vm._v(" "),
                            _c("th", [_vm._v("Subtotal de mano de obra")]),
                            _vm._v(" "),
                            _c(
                              "th",
                              [
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          icon: "",
                                          flat: "",
                                          dark: "",
                                          small: "",
                                          color: "info"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.openEditConceptoModal(
                                              concepto.id
                                            )
                                          }
                                        },
                                        slot: "activator"
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "verdeFayal" } },
                                          [_vm._v("edit")]
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Editar concepto")])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-tooltip",
                                  { attrs: { bottom: "" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          slot: "activator",
                                          icon: "",
                                          flat: "",
                                          dark: "",
                                          small: "",
                                          color: "error"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteConcept(
                                              concepto.id
                                            )
                                          }
                                        },
                                        slot: "activator"
                                      },
                                      [_c("v-icon", [_vm._v("delete")])],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Eliminar")])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          [
                            _vm._l(concepto.rubros, function(rubro, index) {
                              return _c("tr", { key: index }, [
                                _c("td", [_vm._v(_vm._s(rubro.nombre))]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(rubro.horas))]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "$ " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.getSubtotalMateriales(
                                            rubro.materiales_valores
                                          )
                                        )
                                      ) +
                                      " "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "$ " +
                                      _vm._s(
                                        _vm._f("currency")(
                                          rubro.valores.length
                                            ? _vm.getSubtotalManoDeObra(
                                                rubro.valores
                                              )
                                            : 0
                                        )
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { attrs: { colspan: "2" } },
                                  [
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "verdeFayal"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openExecutionTimeModal(
                                                  rubro.id,
                                                  rubro.nombre
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("timer")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("Tiempo de ejecución")
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "verdeFayal"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openAddMaterialsValuesModal(
                                                  rubro.id,
                                                  rubro.nombre,
                                                  "App\\Urbanizacion"
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "customIcon",
                                              attrs: {
                                                src:
                                                  "/fonts/local/construction_icon.svg"
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Materiales")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "verdeFayal"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.setValues(
                                                  (_vm.valueID = rubro.valores
                                                    .length
                                                    ? rubro.valores[0].id
                                                    : null),
                                                  rubro.id,
                                                  rubro.nombre,
                                                  "App\\Urbanizacion"
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "customIcon",
                                              attrs: {
                                                src:
                                                  "/fonts/local/manpower_icon.svg"
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("Costos de mano de obra")
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            }),
                            _vm._v(" "),
                            _c(
                              "tr",
                              {
                                staticStyle: {
                                  "background-color": "#757575",
                                  color: "#fff"
                                }
                              },
                              [
                                _c("td", [_vm._v("Totales")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .getTotalHoras(concepto.rubros)
                                        .toFixed(2)
                                    )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "$" +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.getTotalMateriales(
                                            concepto.rubros
                                          )
                                        )
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "$" +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.getTotalManoDeObra(
                                            concepto.rubros
                                          )
                                        )
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "$" +
                                      _vm._s(
                                        _vm._f("currency")(
                                          _vm.getTotalMateriales(
                                            concepto.rubros
                                          ) +
                                            _vm.getTotalManoDeObra(
                                              concepto.rubros
                                            )
                                        )
                                      )
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", { attrs: { colspan: "2" } })
                              ]
                            )
                          ],
                          2
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("set-values-modal", {
        attrs: {
          show: _vm.showSetValuesModal,
          "value-id": _vm.currentValueId,
          "household-id": _vm.housingId,
          tax: parseInt(_vm.impuesto.valor),
          "item-id": _vm.currentItemId,
          name: _vm.currentItemName,
          "model-type": _vm.currentModel
        },
        on: {
          "update:show": function($event) {
            _vm.showSetValuesModal = $event
          },
          "update:valueId": function($event) {
            _vm.currentValueId = $event
          },
          "update:value-id": function($event) {
            _vm.currentValueId = $event
          },
          "update:householdId": function($event) {
            _vm.housingId = $event
          },
          "update:household-id": function($event) {
            _vm.housingId = $event
          },
          "update:itemId": function($event) {
            _vm.currentItemId = $event
          },
          "update:item-id": function($event) {
            _vm.currentItemId = $event
          },
          "update:name": function($event) {
            _vm.currentItemName = $event
          },
          "update:modelType": function($event) {
            _vm.currentModel = $event
          },
          "update:model-type": function($event) {
            _vm.currentModel = $event
          }
        }
      }),
      _vm._v(" "),
      _c("manage-materials-to-value-modal", {
        attrs: {
          show: _vm.showMaterialsToValuesModal,
          "item-id": _vm.currentItemId,
          "household-id": _vm.housingId,
          name: _vm.currentItemName,
          "model-type": _vm.currentModel,
          permissions: _vm.permissions
        },
        on: {
          "update:show": function($event) {
            _vm.showMaterialsToValuesModal = $event
          },
          "update:itemId": function($event) {
            _vm.currentItemId = $event
          },
          "update:item-id": function($event) {
            _vm.currentItemId = $event
          },
          "update:householdId": function($event) {
            _vm.housingId = $event
          },
          "update:household-id": function($event) {
            _vm.housingId = $event
          },
          "update:name": function($event) {
            _vm.currentItemName = $event
          },
          "update:modelType": function($event) {
            _vm.currentModel = $event
          },
          "update:model-type": function($event) {
            _vm.currentModel = $event
          }
        }
      }),
      _vm._v(" "),
      _c("edit-concepts-modal", {
        attrs: {
          show: _vm.showEditConceptModal,
          conceptId: _vm.editConceptId,
          permissions: _vm.permissions_rubro,
          type: "urbanizacion"
        },
        on: {
          "update:show": function($event) {
            _vm.showEditConceptModal = $event
          },
          "update:conceptId": function($event) {
            _vm.editConceptId = $event
          },
          "update:concept-id": function($event) {
            _vm.editConceptId = $event
          }
        }
      }),
      _vm._v(" "),
      _c("add-execution-time-urbanizacion-modal", {
        attrs: {
          show: _vm.showExecutionTimeModal,
          "rubro-id": _vm.currentItemId
        },
        on: {
          "update:show": function($event) {
            _vm.showExecutionTimeModal = $event
          },
          "update:rubroId": function($event) {
            _vm.currentItemId = $event
          },
          "update:rubro-id": function($event) {
            _vm.currentItemId = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }