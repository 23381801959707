<template>
  <div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">
          Renovar/Cambiar contrato
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text>
        <v-container>
          <v-layout wrap>
            <v-flex md12>
              <v-select 
                v-model="contratoTrabajador.contrato_id" 
                :items="contractsTypes" 
                item-text="periodo"
                item-value="id"
                label="Tipo de contrato" 
              />
            </v-flex>
            <v-flex md6>
              <v-text-field 
                prepend-icon="calendar_today" 
                type="date" 
                v-model="contratoTrabajador.fecha_inicio" 
                label="Fecha de contratación" 
                readonly 
              />
            </v-flex>
            <v-flex md6>
              <v-text-field 
                prepend-icon="calendar_today" 
                type="date" 
                v-model="contratoTrabajador.fecha_fin" 
                label="Nueva fecha de finalizacion"  
              />
            </v-flex>
          </v-layout>
          <v-flex md12>
              <div class="wrapper-table">
                <span class="table-title">Historial</span>
                <table class="table-history">
                  <thead class="table-head-history">
                    <tr>
                      <th>Contrato</th>
                      <th>Fecha</th>
                    </tr>
                  </thead>
                  <tbody class="table-body-history">
                    <tr v-for="(bitacora, index) in contratoTrabajador.bitacoras" :key="index">
                      <td>{{ bitacora.contrato.periodo }}</td>
                      <td>{{ bitacora.created_at }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-flex>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          dark color="verdeFayal" 
          @click="renovate()" flat :loading="vBtnSave.loading"
        >Renovar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";

export default {
  props: {
    show: Boolean,
    contrato_trabajador_id: Number,
    permissions: Object,
    trabajador_is_active: Boolean
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.getContratoTrabajador();
      this.$emit('update:show', value);
    }
  },
  data() {
    return {
      contratoTrabajador: {
        contrato_id: null,
        trabajador_id: null,
        fecha_inicio: null,
        fecha_fin: null,
      },
      localShow: false,
      vBtnSave: {
        loading: false
      },
      contractsTypes: []
    }
  },
  methods: {
    renovate: function () {
      axios.patch(laravelRoute('contratos_trabajadores.renovate', this.contratoTrabajador.id), {
        fecha_fin: this.contratoTrabajador.fecha_fin,
        contrato_id: this.contratoTrabajador.contrato_id
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.localShow =  false;
      })
      .catch(error => errorHandling(error))
      .finally(() => this.vBtnSave.loading = false);
    },
    getContratoTrabajador: function () {
      axios.get(laravelRoute('contratos_trabajadores.show', this.contrato_trabajador_id))
      .then(response => this.contratoTrabajador = response.data);
    },
    getContratos: function () {
      axios.get(laravelRoute('contratos.index'))
      .then(response => this.contractsTypes = response.data);
    },
  },
  mounted() {
    this.getContratos();
  },
}
</script>