<template>
  <v-layout row justify-center>
    <v-card>
      <v-flex xs12>
          <div class="">
            <a href="../prospectos">
              <v-btn color="#1ABA24" class="white--text">Ir a prospectos</v-btn>
            </a>
          </div>
        </v-flex>
      <v-card-title>
        <span class="headline">Ver prospecto</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md6>
              <v-text-field
                color="#1ABA24"
                label="Nombre del Proyecto"
                v-model="prospecto.nombre_proyecto"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                color="#1ABA24"
                label="Dirección"
                v-model="prospecto.direccion"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                color="#1ABA24"
                label="Fecha"
                type="date"
                v-model="prospecto.fecha"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-textarea
                color="#1ABA24"
                label="Descripción"
                v-model="prospecto.descripcion"
                disabled
              ></v-textarea>
            </v-flex>
            <v-flex md12>
              <v-text-field color="#1ABA24" label="Ver planos" readonly @click="elementId = prospectosProps.id; dialogFile = true;"></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
    <upload-files-modal @close="dialogFile = false" element_type="App\Prospecto" :element_id="elementId" :dialog="dialogFile"></upload-files-modal>
  </v-layout>
</template>

<script>
export default {
  props: ["prospecto"],
  data() {
    return {
      prospectosProps: this.prospecto,
      elementId: '',
      dialogFile: false,
    };
  },
  watch: {
    dialog: function(value){
      if(!value){
        this.getProspecto();
      }
    }
  },
  methods:{
    getProspecto: function() {
      axios.get(route("prospectos.index")).then(response => {
        this.prospectosProps = response.data;
      });
    },
  }
};
</script>
