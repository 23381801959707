<template>  
  <v-container>
    <v-layout row wrap>
      <v-flex :xs12="!showFiltersForm" :xs9="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Requisiciones</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <v-btn dark color="verdeFayal" outline @click="showFiltersForm = !showFiltersForm">
              <v-icon dark>filter_alt</v-icon>
            </v-btn>
            <v-btn dark color="verdeFayal" outline  @click="exportToExcel">
              <v-icon dark>import_export</v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table :headers="vHeaders" :items="requisiciones.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td>{{ props.item.folio }}</td>
              <td class="td-ellipsis">{{ props.item.nombre_requisicion }}</td>
              <td>{{ props.item.solicitante }}</td>
              <td class="td-ellipsis">{{props.item.descripcion }}</td>
              <td>{{props.item.estado.information.estado}}</td>
              <td>{{ (props.item.fecha_de_llegada) ? props.item.fecha_de_llegada : 'Aún sin especificar'}}</td>
              <td>
                <v-layout>
                  <v-tooltip bottom>
                    <v-checkbox
                      :disabled="!$root.can('visto-bueno', permissions) ? true : (props.item.visto_bueno ? true : false)"
                      flat
                      v-model="props.item.visto_bueno"
                      slot="activator"
                      color="indigo"
                      @click="AprobadoGerencia(props.item)"
                    ></v-checkbox>
                    <span>Visto bueno por Gerencia</span>
                  </v-tooltip>  

                  <v-tooltip bottom>
                    <v-checkbox
                      :disabled="!$root.can('visto_bueno_operaciones', permissions) ? true : (props.item.visto_bueno_operaciones ? true : false)"
                      flat
                      v-model="props.item.visto_bueno_operaciones"
                      slot="activator"
                      color="indigo"
                      @change="aprobadoOperaciones(props.item)"
                    ></v-checkbox>
                    <span>Visto bueno por Operación</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <v-btn 
                      icon 
                      flat 
                      dark 
                      small 
                      slot="activator" 
                      color="verdeFayal" 
                      @click="openFilesModal(props.item.id)"
                    >
                      <v-icon>folder</v-icon>
                    </v-btn>
                    <span>Archivos</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <v-btn 
                      :disabled="!$root.can('materials', permissions)" 
                      :href="`/requisiciones/${props.item.id}/edit`"  
                      icon 
                      flat 
                      dark 
                      small 
                      slot="activator" 
                      color="verdeFayal"
                    >
                      <v-icon>remove_red_eye</v-icon>
                    </v-btn>
                    <span>Editar</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <v-btn
                      :disabled="!$root.can('remove', permissions)"
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="error"
                      @click="deleteRequisicion(props.item.id)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <span>Eliminar</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination
              :limit="vPaginator.limit"
              :data="requisiciones"
              @pagination-change-page="getRequisiciones"
            ></pagination>
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex xs3 v-if="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat>
            <v-toolbar-title>Filtros</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="showFiltersForm = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout row wrap>                                                                
                <v-flex>
                  <v-select 
                    v-model="filters.filterSolicitante" 
                    :items="['Almacén']" 
                    label="Solicitante"
                    clearable                                 
                  />
                </v-flex>
                <v-flex>
                  <v-select 
                    v-model="filters.filterEstado" 
                    :items="this.itemsEstados"
                    item-value="id"
                    item-text="estado"
                    label="Estado"
                    clearable                                 
                  />
                </v-flex>                
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn dark color="verdeFayal" small flat outline @click="limpiar">Limpiar</v-btn>
            <v-spacer></v-spacer>
            <v-btn dark color="verdeFayal" small @click="filterRequisiciones">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <!-- modals -->
      <requisiciones-modal-create
        :show.sync="showCreateModal"
        v-bind:item-id.sync="currentItemId"
        v-bind:modelType.sync="currentModel"
      ></requisiciones-modal-create>
      <manage-requisitions-to-requisitions-modal
        v-bind:show.sync="showMaterialsToValuesModal"
        v-bind:item-id.sync="currentRequisicionId"
        v-bind:modelType.sync="currentModel"
        v-bind:name.sync="currentItemName"
      ></manage-requisitions-to-requisitions-modal>
      <upload-files-modal @close="showFilesModal = false" element_type="App\Requisicion" :element_id="currentRequisicionId" :dialog="showFilesModal"></upload-files-modal>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data(){
    return{
      search: null,
      requisiciones:{},
      showCreateModal: false,
      currentItemId: null,
      showEditModal: false,
      currentRequisicionId: null,
      showMaterialsToValuesModal: false,
      itemId: null,
      currentItemName: null,
      showFilesModal: false,
      currentModel: 'App\\Requisicion',
      vPaginator: {
        limit: 10
      },
      vHeaders: [
        { text: "Folio", value: "folio", sortable: false  },
        { text: "Nombre de requisición", value: "nombre_requisicion", sortable: false  },
        { text: "Solicitante", value: "solicitante", sortable: false  },
        { text: "Descripción", value: "descripcion", sortable: false  },
        { text: "Estado", value: "estado", sortable: false  },
        { text: "Fecha de llegada", value: "fecha_de_llegada", sortable: false  },
        { text: "Acciones", value: "acciones", sortable: false }
      ],
      materials: {
        current_page: 1,
        data: []
      },

      showFiltersForm: false,
      filters: {        
        filterSolicitante: null,
        filterEstado: null,        
      },
      itemsEstados: [],
      
    }
  },
  props:['permissions'],
  methods:{
    getRequisiciones: function (page = 1) {
      axios.get(`/requisiciones?page=${page}&query=${this.search}`)
      .then(response => {
        this.requisiciones = response.data;
      })
      .catch(error => errorHandling(error));
    },
    openCreateModal: function(currentItemId){
      this.showCreateModal = true;
      this.currentItemId = currentItemId;
    },
    openEditRequisicionModal: function(requisicionId){
      this.showEditModal = true;
      this.currentRequisicionId = requisicionId;
    },
    deleteRequisicion: function(requisicionId){
    swal({
      title: "¿Estás seguro?",
      text: "Una vez eliminado no se podrá recuperar",
      icon: "warning",
      buttons: true,
      dangerMode: true
      }).then(result => {
        if (result) {
          axios
          .delete(laravelRoute('requisiciones.destroy', requisicionId))
          .then(response => {
            this.getRequisiciones();
            alertify.success(response.data.message_text);
          })
          .catch(error => {
            errorHandling(error);
          });
        }
      })
    },
    openAddMaterialsValuesModal: function(requisicionId, currentItemName) {
      this.showMaterialsToValuesModal = true;
      this.currentItemName = currentItemName;
      this.currentRequisicionId = requisicionId;
    },
    AprobadoGerencia: function(aprobado){
      swal({
        title: "¿Está seguro que quiere dar visto bueno a esta requisición?",
        icon: "warning",
        buttons: true
      }).then(response => {
        if(response){
          this.vistoBueno(aprobado);
          swal("la requisición se aprobó satisfactoriamente", {
            icon: "success"
        }).then(response => {
          this.getRequisiciones();
        });
        }else{
          swal("la requisición no se aprobó");
        }
      });
    },
    aprobadoOperaciones: function(requisicion) {
      swal({
        title: "¿Está seguro que quiere dar visto bueno a esta requisición?",
        icon: "warning",
        buttons: true
      }).then(response => {
        if(response){
          axios.patch('/requisiciones/' + requisicion.id, {
            ...requisicion,
            aprobado_operacion: true
          })
          .then(response => {
            alertify.success(response.data.message_text);
            this.getRequisiciones()
          })
          .catch(error => errorHandling(error));
        
        }else{
          let key = this.requisiciones.data.findIndex(getRequisicion => getRequisicion.id = requisicion.id);
          this.requisiciones.data[key].visto_bueno_operaciones = false;
          swal("la requisición no se aprobó");
        }
      });
    }, 
    vistoBueno: function(prospecto) {
      axios
        .put(route("requisicion.aprobar", prospecto.id))
        .then(response => {
          if (response.data.message_text)
            alertify.success(response.data.message_text);
        })
        .catch(error => errorHandling(error));
    },
    openFilesModal: function(requisicionId) {
      this.currentRequisicionId = requisicionId;
      this.showFilesModal = true;
    },

    getEstados: function() {
      axios.get('/requisiciones/estados')
      .then(response => this.itemsEstados = response.data)
      .catch(error => errorHandling(error));
    },    
    filterRequisiciones: function() {
      axios.post('/requisiciones/filterRequisiciones',this.filters)
      .then(response => this.requisiciones = response.data)
      .catch(error => errorHandling(error));
    },
    limpiar: function () {             
        this.filters.filterSolicitante = null;
        this.filters.filterEstado = null;
        this.getRequisiciones();
    },
    exportToExcel: function() {
      axios.post('/requisiciones/filterRequisiciones', {
        ...this.filters,
        to_excel: true
      }, {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'requisiciones.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
    },
  },
  watch:{
    showCreateModal: function (value) {
      if (!value) {
        this.getRequisiciones();
      }
    },
    showEditModal: function (value) {
      this.getRequisiciones();
    },
    showMaterialsToValuesModal: function(value) {
      if(!value) {
        this.getRequisiciones()
      }
    },
    search: function (value) {
      this.getRequisiciones();
    },
  },
  created(){
    this.getEstados();
    this.getRequisiciones();
  }
}
</script>

<style scoped>
  .fyl-check-requisitions{
    display: inline-block;
    width: 27px;
    height: 27px;
  }
  .fyl-descripcion-td{
    max-width: 166px;
  }
</style>