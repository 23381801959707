<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Editar prototipo de vivienda</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat color="verdeFayal" icon dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs
            v-model="model"
            centered
            color="white"
            slider-color="verdeFayal"
          >
            <v-tab href="#tab-datos-generales">Datos generales del prototipo</v-tab>
            <v-tab href="#tab-conceptos">Conceptos asociados</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="model">
        <v-tab-item value="tab-datos-generales">
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex md12>
                  <v-subheader>Datos del prototipo</v-subheader>
                </v-flex>
                <v-divider></v-divider>
                <v-flex md12>
                  <v-text-field v-model="household.nombre" required label="Nombre"></v-text-field>
                </v-flex>
                <v-flex md12>
                  <v-text-field v-model="household.descripcion" required label="Descripción"></v-text-field>
                </v-flex>
              </v-layout>
              <hr>
              <v-layout wrap>
                <v-flex md12>
                  <v-subheader>Composición del prototipo</v-subheader>
                </v-flex>
                <v-divider></v-divider>
                <v-flex md6>
                  <v-text-field type="number" v-model="household.plantas" min="1" max="4" required label="Plantas"></v-text-field>
                </v-flex>
                <v-flex md6>
                  <v-text-field type="number" v-model="household.recamaras" min="1" max="4" required label="Recámaras"></v-text-field>
                </v-flex>
                <v-flex md6>
                  <v-text-field type="number" v-model="household.baños" min="1" max="4" step="0.5" required label="Baños"></v-text-field>
                </v-flex>
                <v-flex md6>
                  <v-select :items="tiposItems" v-model="household.tipo" required label="Tipo"></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-tab-item>

        <v-tab-item value="tab-conceptos">
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex md12>
                  <v-autocomplete
                    @keyup.enter.native="addConcepto"
                    hint="Buscar lote a trabajar"
                    prepend-inner-icon="search"
                    :search-input.sync="query"
                    :items="searchConceptos.data"
                    :loading="searchLoading"
                    item-text="nombre"
                    v-model="selectedOne"
                    persistent-hint
                    item-value="id"
                    label="Buscar"
                    return-object
                    hide-selected
                    hide-no-data
                  >
                    <template v-slot:append-outer>
                      <v-slide-x-reverse-transition mode="out-in">
                        <v-icon color="success" :disabled="!selectedOne" @click="addConcepto">add</v-icon>
                      </v-slide-x-reverse-transition>
                    </template>
                  </v-autocomplete>
                  <template v-slot:append-outer>
                    <v-slide-x-reverse-transition mode="out-in">
                      <v-icon color="success" @click="addRubro">add</v-icon>
                    </v-slide-x-reverse-transition>
                  </template>
                </v-flex>
                <v-flex xs12>
                  <v-data-table
                    :items="household.custom_conceptos"
                    class="elevation-1"
                    :expand="expand"
                    hide-actions
                    flat
                  >
                    <template v-slot:headers="props">
                      <tr>
                        <th>Nombre</th>
                        <th></th>
                      </tr>
                    </template>
                    <template v-slot:items="props">
                      <tr>
                        <td>{{ (props.item.concepto) ? props.item.concepto.nombre : props.item.nombre }}</td>
                        <td class="text-xs-right">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">       
                              <v-btn flat icon color="red" v-on="on" @click="household.custom_conceptos.splice(props.index, 1)">
                                <v-icon>delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Eliminar rubro</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">       
                              <v-btn flat icon  v-on="on" @click="props.expanded = !props.expanded">
                                <v-icon>expand_more</v-icon>
                              </v-btn>
                            </template>
                            <span>Expandir rubros</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                    <template v-slot:expand="props">
                      <v-card flat>
                        <v-data-table
                          :items="(props.item.concepto) ? props.item.concepto.rubros : props.item.rubros"
                          hide-actions
                          item-key="id"
                          select-all
                        >
                          <template v-slot:headers>
                            <tr>
                              <th></th>
                              <th>Lote</th>
                              <th>Acciones</th>
                            </tr>
                          </template>
                          <template v-slot:items="propsRubros">
                            <tr>
                              <td></td>
                              <td>{{ propsRubros.item.nombre }}</td>
                              <td class="text-xs-right">
                                <v-checkbox v-model="propsRubros.item.selected"></v-checkbox>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="updateHousehold" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<style>
.primary-title {
  color: #1aba24;
}
</style>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    household: {},
    bañosItems: [1, 1.5, 2, 2.5, 3, 3.5, 4],
    tiposItems: ['Regular', 'Irregular'],
    model: 'tab-datos-generales',
    expand: false,
    searchConceptos: [],
    selectedOne: null,
    query: null,
    searchLoading: false,
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    },
    searchConfig: {
      url: "/conceptos?type=obra",
      paginatedContainer: 'data',
      noDataText: "No se encontraron resultados.",
      itemText: "nombre",
      itemValue: "id",
      descriptionLimit: 60,
      placeholder: "Buscar Concepto",
      prependIcon: "mdi-database-search",
      chips: true,
      multiple: true,
      deleteChips: true,
      disabled: false,
      useHeaders: true,
    },
    items: [],
    vDataTable: {
      headers: [
        {
          text: 'Concepto',
          align: 'left',
          value: 'name',
          sortable: false
        },
        { text: 'Rubros disponibles', value: 'calories', sortable: false },
        { text: 'Rubros seleccionados', value: 'fat', sortable: false },
      ],
      pagination: {
        sortBy: 'name'
      },
    },
    concepts: [],
    rubros: [],
  }),
  props: {
    show: Boolean,  
    householdId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    householdId: function (value) {
      this.getHousehold(value);
    },
    localShow: function (value) {
      this.$emit('update:show', value)
      this.$refs.form.reset()
    },
    items: function(value){
      this.handleRubros(value);
    },
    rubros: function(value){
      let localValue = value;
      let assosiated_rubros_local = [];
      this.household.custom_conceptos.forEach(concepto => {
        concepto.rubros.forEach(rubro => {
          assosiated_rubros_local.push(rubro.id);
        })
      });
      value = localValue.map(rubro => rubro.selected = assosiated_rubros_local.includes(rubro.id));
    },
    query: function() {
      this.getConceptos();
    },
  },
  methods: {
    manageRubro: function(rubro, state){
      this.rubros[this.rubros.indexOf(rubro)].selected = state;
    },
    handleRubros: function(elements){
      this.rubros = [];
      let rubrosContainer = [];
      elements.forEach(element => {
        element.rubros.forEach( rubro => rubrosContainer.push(Object.assign({}, { selected: false, ...rubro })) );
      });
      this.rubros = rubrosContainer;
    },
    selected: function(item) {
      this.concepts = item.map(element => element.id);
      this.items = item; 
      this.handleRubros(this.items)
    },
    getHousehold: function (householdId) {
      axios.get(laravelRoute('prototipos_vivienda.show', householdId))
        .then(response => {
          this.household = response.data;

          this.household.custom_conceptos.forEach (item => item.concepto.rubros.forEach(rubro => { 
            if(this.household.rubros.some(item_rubro => item_rubro.rubro_id == rubro.id)) {
              rubro.selected = true
            }
          }));
        })
        .catch(error => errorHandling(error))
    },
    updateHousehold: function () {
      this.vBtnSave.loading = true;
      axios.patch(laravelRoute('prototipos_vivienda.update', this.householdId), {
        nombre: this.household.nombre,
        descripcion: this.household.descripcion,
        plantas: this.household.plantas,
        recamaras: this.household.recamaras,
        baños: this.household.baños,
        tipo: this.household.tipo,
        conceptos: this.household.custom_conceptos.map(item => item.concepto.id),
        rubros: [].concat.apply([], this.household.custom_conceptos.map(item => item.concepto.rubros.filter( rubro => rubro.selected == true).map(i => i.id)))
      })
      .then(response => {
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;
        this.vBtnSave.loading = false;
        this.localShow = false;
      })
      .catch(error => {
        this.vBtnSave.loading = false;
        errorHandling(error)
      });
    },
    getConceptos: function() {
      this.searchLoading = true;
      axios.get(`/conceptos/para_prototipos?type=obra&paginated=1&query=${this.query}`)
      .then(response => this.searchConceptos = response.data)
      .catch(error => errorHandling(error))
      .finally(() => this.searchLoading = false);
    },
    addConcepto: function() {
      if(this.selectedOne) {
        if(!this.household.custom_conceptos.some(item => item.concepto.id == this.selectedOne.id)) {
          this.household.custom_conceptos.push({
            ...this.selectedOne,
            concepto: this.selectedOne
          })
          this.selectedOne = null;
        }
        else {
          alertify.error('El concepto seleccionado ya se habia añadido anteriormente');
        }
      }
    },
  },
  computed: {
    getRubros: function(){
      return this.rubros;
    }
  }
}
</script>
