<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="1150px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Ver orden de salida</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
              <v-flex md6>
                <v-text-field :value="orden.nombre" required label="Orden de salida" readonly></v-text-field>
              </v-flex>
              <v-flex md6>
                <v-text-field :value="orden.folio" required label="Folio" readonly></v-text-field>
              </v-flex>
              <v-flex md6>
                <v-text-field :value="orden.solicitud.solicitante" required label="Solicitante" readonly></v-text-field>
              </v-flex>
              <v-flex md6>
                <v-text-field :value="orden.trabajador.nombre_completo" required label="Entregar" readonly></v-text-field>
              </v-flex>
              <v-flex md6>
                <v-text-field :value="orden.solicitud.fraccionamiento.nombre_proyecto" required label="Fraccionamiento" readonly></v-text-field>
              </v-flex>
              <v-flex md6 v-if="orden.estado.information.estado == 'Pendiente'">
                <v-btn flat color="red" @click="cancelOrden()">
                  Rechazar orden
                  <v-icon right dark>cancel</v-icon>
                </v-btn>
              </v-flex>
          </v-layout>
          <v-divider></v-divider>

          <v-layout wrap>
            <v-flex>
              <v-data-table :headers="vHeaders" disable-initial-sort :items="orden.materiales" hide-actions class="elevation-1">
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.origin_material.material.categoria_asociada }}</td>
                  <td>{{ props.item.origin_material.material.nombre }}</td>
                  <td>$ {{ props.item.origin_material.material.precio | currency }}</td>
                  <td>{{ props.item.cantidad }}</td>
                  <td>$ {{ props.item.cantidad * props.item.origin_material.material.precio | currency }}</td>
                  <td>{{ props.item.origin_material.distribucion }}</td>
                  <td>{{ (props.item.origin_material.distribucion == 'Manzanas' ? props.item.origin_material.lote.nombre : "No aplica") }}</td>
                  <td v-if="props.item.origin_material.distribucion == 'Calles'">
                    {{props.item.origin_material.calle.nombre}}
                  </td>
                  <td v-else-if="props.item.origin_material.distribucion == 'Áreas verdes'">
                    {{props.item.origin_material.area_verde.nombre}}
                  </td>
                  <td v-else>No aplica</td>
                  <td>
                    <v-chip class="ma-2" :color="(props.item.despachado == 1) ? 'green' :'red'" outline small>
                      {{ (props.item.despachado == 1) ? "Despachado" : "No" }}
                    </v-chip>
                  </td>
                  <td>
                    <v-tooltip bottom v-if="orden.estado.information.estado != 'Rechazada'">
                      <v-btn :disabled="props.item.despachado == 1" @click="despacharMaterial(props.item.id)" icon flat dark small slot="activator" color="verdeFayal">
                          <v-icon>compare_arrows</v-icon>
                      </v-btn>
                      <span>Despachar material</span>
                    </v-tooltip>
                  </td>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    orden: {
      nombre: null,
      trabajador: {
        nombre_completo: null
      },
      estado: {
        information: {

        }
      },
      solicitud: {
        nombre: null,
        fraccionamiento: {
          nombre_proyecto: null,
        },
      }
    },
    vHeaders: [
      {
        text: 'Categoria',
        value: 'categoria'
      },
      {
        text: 'Material',
        value: 'material'
      },
      {
        text: 'Precio (Sin IVA)',
        value: 'precio'
      },
      {
        text: 'Cantidad',
        value: 'cantidad'
      },
      {
        text: 'Total',
        value: 'total'
      },
      {
        text: 'Distribución',
        value: 'distribucion'
      },
      {
        text: 'Lote',
        value: 'lote'
      },
      {
        text: 'Calle / Área verde',
        value: 'calle/area-verde'
      },
      {
        text: 'Despachado',
        value: 'despachado'
      },
      {
        text: 'Acciones',
        value: 'acciones',
        sortable: false
      }
    ],
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    ordenId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    ordenId: function(value) {
        this.getOrden();
    }
  },
  methods: {
    getOrden: function() {
      axios.get(`/orden_de_salida/${this.ordenId}`)
      .then( response => {
        this.orden = response.data;
      })
      .catch( error =>  errorHandling(error));
    },
    despacharMaterial: function(materialOrdenId) {
      axios.patch(laravelRoute("materiales.despachar",materialOrdenId ))
      .then(response => {
        this.getOrden();
        alertify.success(response.data.message_text);
      })
      .catch(error => errorHandling(error));
    },
    cancelOrden: function() {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) { 
          this.vBtnSave = true;
          axios.patch(`/orden_de_salida/${this.ordenId}`)
          .then( response => {
            this.vSnackBar.text = response.data.message_text;
            this.vSnackBar.visible = true;
            this.getOrden();
          })
          .catch( error =>  errorHandling(error))
          .finally(() => this.vBtnSave = false);
        }
      })
    }
  }
}
</script>
