<template>
  <v-snackbar v-model="vSnackbar.show" v-bind:right="true" v-bind:timeout="vSnackbar.timeout">
    {{ vSnackbar.message }}
    <v-btn dark v-bind:color="vSnackbar.color" flat v-on:click="vSnackbar.onClick">
      {{ vSnackbar.actionText }}
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'AlertSuccess',
  data() {
    return {
      vSnackbar: {
        show: false,
        message: '',
        timeout: 5000,
        color: 'pink',
        actionText: 'Cerrar',
        onClick: () => {
          this.vSnackbar.show = false
        }
      }
    }
  },
  created() {
    const defaultOptions = { show: true }
    this.$bus.$on('alert-success', (options) => {
      if (!options.message) options.message = 'Operación completada'
      this.vSnackbar = { ...this.vSnackbar, ...defaultOptions, ...options }
    })
  }
}
</script>
