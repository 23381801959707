<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">
          {{!contratoTrabajador.id ? 'Nuevo Contrato' : 'Ver/editar contrato'}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
              <v-flex md12>
                  <v-subheader>Contrato</v-subheader>
              </v-flex>
              <v-flex md12>
                  <v-select 
                    :disabled="!validarEdicion(contratoTrabajador.estatus)"
                    v-model="contratoTrabajador.contrato_id" :items="tiposDeContratos" 
                    item-text="periodo"
                    item-value="id"
                    required
                    label="Tipo de contrato" />
              </v-flex>
              <v-flex md6>
                <v-text-field 
                  :disabled="!validarEdicion(contratoTrabajador.estatus)"
                  type="date"
                  v-model="contratoTrabajador.fecha_inicio"
                  required
                  label="Fecha Inicio" 
                />
              </v-flex>
              <v-flex md6>
                <v-text-field 
                  :disabled="!validarEdicion(contratoTrabajador.estatus)"
                  type="date"
                  v-model="contratoTrabajador.fecha_fin"
                  label="Fecha fin (opcional)" 
                />
              </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          :disabled="!validarEdicion(contratoTrabajador.estatus)" 
          dark color="verdeFayal" 
          @click="saveContratoTrabajador()" flat :loading="vBtnSave.loading"
        > Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    tiposDeContratos: [],
    contratoTrabajador: {
      contrato_id: null,
      trabajador_id: null,
      fecha_inicio: null,
      fecha_fin: null,
      estatus: null,
      folio_nss: null,
    },
    keepStoring: true,
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    contrato_trabajador: Object,
    permissions: Object
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.contratoTrabajador = this.$props.contrato_trabajador;
      this.$emit('update:show', value);
    }
  },
  mounted(){
    this.getContratos();
  },
  methods: {
    validarFechas(){
      if(this.contratoTrabajador.fecha_inicio < this.contratoTrabajador.fecha_fin) return true;
      else alertify.error('La fecha de fin debe ser mayor a la fecha de inicio');
    },
    storeContratoTrabajador(){
      if(this.validarFechas()){
        axios.post(laravelRoute('contratos_trabajadores.store'),{
          contrato_id: this.contratoTrabajador.contrato_id,
          trabajador_id: this.contratoTrabajador.trabajador_id,
          fecha_inicio: this.contratoTrabajador.fecha_inicio,
          fecha_fin: this.contratoTrabajador.fecha_fin
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.contratoTrabajador = {
            contrato_id: null,
            fecha_inicio: null,
            fecha_fin: null
          }
          this.localShow =  false;
        })
        .catch(error => errorHandling(error))
        .finally(() => this.vBtnSave.loading = false);
      }
    },
    updateContratoTrabajador(){
       if(this.validarFechas()){
        axios.put(laravelRoute('contratos_trabajadores.update', this.contratoTrabajador.id),{
          accion: 'edit',
          contrato_id: this.contratoTrabajador.contrato_id,
          trabajador_id: this.trabajador_id,
          fecha_inicio: this.contratoTrabajador.fecha_inicio,
          fecha_fin: this.contratoTrabajador.fecha_fin
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.contratoTrabajador = {
            contrato_id: null,
            fecha_inicio: null,
            fecha_fin: null
          }
          this.localShow =  false;
        })
        .catch(error => errorHandling(error))
        .finally(() => this.vBtnSave.loading = false);
      }
    },
    saveContratoTrabajador(){
      this.contratoTrabajador.id ? 
        this.updateContratoTrabajador() : this.storeContratoTrabajador();
    },
    guardarFolio(){
      axios.patch(laravelRoute('contratos_trabajadores.saveNSSFolio', this.contratoTrabajador.id), {folio_nss: this.contratoTrabajador.folio_nss})
      .then(response => {
        alertify.success(response.data.message_text);
        })
      .catch(error => errorHandling(error));
    },
    getContratos(){
      axios.get(laravelRoute('contratos.index')).then(response => this.tiposDeContratos = response.data);
    },
    validarEdicion: function(estatus){
      return this.$root.can('update', this.$props.permissions) && estatus === 'Pendiente' ? true : false;
    },
    activeWorker(){
      axios.patch(laravelRoute('trabajadores.active', this.contratoTrabajador.trabajador_id))
      .then(response => {
        if(response.data.message_type == 'success'){
          alertify.success(response.data.message_text);
          this.localShow =  false;
        }
        else
          alertify.error(response.data.message_text);
      })
      .catch(error => errorHandling(error));
    }
  }
}
</script>
