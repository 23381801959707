var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "700" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline grey lighten-2",
                  attrs: { "primary-title": "" }
                },
                [_vm._v("\n        Titulo del modal\n      ")]
              ),
              _vm._v(" "),
              _c("v-container", { attrs: { fluid: "", "grid-list-sm": "" } }, [
                _c(
                  "div",
                  { staticClass: "layout wrap" },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", sm6: "", md3: "" } },
                      [
                        _c("v-text-field", {
                          attrs: { label: "Nombre*" },
                          model: {
                            value: _vm.text,
                            callback: function($$v) {
                              _vm.text = $$v
                            },
                            expression: "text"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-switch", {
                    staticClass: "mt-0",
                    attrs: {
                      color: "purple lighten-1",
                      "hide-details": "",
                      label: "Agregar más"
                    },
                    model: {
                      value: _vm.keepAdding,
                      callback: function($$v) {
                        _vm.keepAdding = $$v
                      },
                      expression: "keepAdding"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_vm._v("\n          Cancelar\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", flat: "" },
                      on: { click: _vm.returnData }
                    },
                    [_vm._v("\n          Agregar\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }