<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="550px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Editar lote  {{ lote.nombre }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md12>
              <v-text-field disabled readonly v-model="lote.nombre" required label="Nombre del lote"></v-text-field>
            </v-flex>
            <v-flex md12>
              <v-select
                label="Tipo de lote"
                v-model="lote.tipo"
                :items="['Regular', 'Irregular']"
                :rules="[(v) => !!v || 'Campo requerido']"
              ></v-select>
            </v-flex>
            <v-flex xs12>
              <searcher
                :configuration="searchModelo"
                :itemSelected.sync="lote.prototipo"
              ></searcher>
            </v-flex>
            <v-flex md12>
                <v-text-field type="number" min="1" v-model="lote.superficie" required label="Superficie"></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" outline @click="updateLote()" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    lote: {},
    modelo: {},
    searchModelo: {
      url: "/prototipos_vivienda/with_rubros",
      paginatedContainer: 'data',
      noDataText: "No se encontraron resultados.",
      label: "Modelo",
      itemText: "nombre",
      itemValue: "id",
      descriptionLimit: 60,
      disabled: false,
      useHeaders: true,
      rules: [
        v => !!v || 'Este campo es requerido',
      ]
    },
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    loteId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    loteId: function() {
      this.getLote();
    }
  },
  methods: {
    getLote: function() {
      axios.get(laravelRoute('manzanas.lote.get', this.loteId))
      .then(response => {
        this.lote = response.data;
        this.modelo = response.data.modelo;
      })
      .catch(error => errorHandling(error));
    },
    updateLote: function() {
      this.vBtnSave.loading = true;
      axios.post(laravelRoute('manzanas.lote.update', this.loteId), {
        ...this.lote
      })
      .then(response => {
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;
        //this.localShow = false;
      })
      .catch(error => errorHandling(error))
      .finally(() => this.vBtnSave.loading = false);
    }
  },
}
</script>
