var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "white", flat: "" } },
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("v-toolbar-title", { staticClass: "primary-title" }, [
                _vm._v("Cambiar estado al mantenimiento")
              ]),
              _vm._v(" "),
              _c("v-spacer")
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { attrs: { "grid-list-md": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Nombre de la máquina",
                              disabled: ""
                            },
                            model: {
                              value: _vm.mantenimiento.maquinaria.nombre,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.mantenimiento.maquinaria,
                                  "nombre",
                                  $$v
                                )
                              },
                              expression: "mantenimiento.maquinaria.nombre"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Fecha de inicio de mantenimiento",
                              disabled: ""
                            },
                            model: {
                              value: _vm.mantenimiento.mantenimiento.nombre,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.mantenimiento.mantenimiento,
                                  "nombre",
                                  $$v
                                )
                              },
                              expression: "mantenimiento.mantenimiento.nombre"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-select", {
                            attrs: {
                              color: "verdeFayal",
                              items: _vm.status,
                              "item-value": "id",
                              "item-text": "estado",
                              label: "Seleccione status"
                            },
                            model: {
                              value: _vm.mantenimiento.estado.information.id,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.mantenimiento.estado.information,
                                  "id",
                                  $$v
                                )
                              },
                              expression: "mantenimiento.estado.information.id"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Fecha de inicio de mantenimiento",
                              type: "date"
                            },
                            model: {
                              value: _vm.mantenimiento.fecha,
                              callback: function($$v) {
                                _vm.$set(_vm.mantenimiento, "fecha", $$v)
                              },
                              expression: "mantenimiento.fecha"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.vBtnSave.loading,
                    dark: "",
                    color: "verdeFayal",
                    flat: ""
                  },
                  on: { click: _vm.updateMantenimiento }
                },
                [_vm._v(" Guardar ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }