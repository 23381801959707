<template>
<v-layout>
  <v-container>
    <v-card>
      <v-flex xs12>
        <v-toolbar color="white" flat tabs>
          <v-toolbar-title>Nóminas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
          <v-btn class="fyl-btnCreate" @click="openCreateModal" v-if="$root.can('new', permissions) ? true : false">Crear</v-btn>
        </v-toolbar>
        <v-data-table :headers="vHeaders" disable-initial-sort :items="nominas.data" hide-actions class="elevation-1">
          <template slot="items" slot-scope="props">
            <td>{{ props.item.nombre }}</td>
            <td>{{ props.item.fecha_creacion }}</td>
            <td>{{ props.item.estatus }}</td>
            <td>{{ props.item.total }}</td>
            <td>
              <v-tooltip bottom>
                <v-btn icon flat dark small slot="activator" color="verdeFayal" :href="`/nominas/${props.item.id}`">
                  <v-icon>visibility</v-icon>
                </v-btn>
                <span>Ver/editar datos</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn :disabled="!$root.can('update', permissions)" icon flat dark small slot="activator" color="primary" @click="openEditModal(props.item)">
                  <v-icon>edit</v-icon>
                </v-btn>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <v-btn icon flat dark small slot="activator" color="verdeFayal" @click="exportToExcel(props.item.id)">
                  <v-icon>import_export</v-icon>
                </v-btn>
                <span>Exportar a Excel</span>
              </v-tooltip>

            </td>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <pagination :limit="vPaginator.limit" :data="nominas" @pagination-change-page="getNominas"></pagination>
        </v-card-actions>
      </v-flex>
      <save-nominas-modal :show.sync="showSaveModal" :selected_nomina="currentNomina"/>
    </v-card>
  </v-container>
</v-layout>
</template>

<script>
import swal from 'sweetalert';
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  props: ['permissions'],
  data() {
    return {
      search: null,
      nominas: {
        data: []
      },
      currentNomina: {},
      vPaginator: {
        limit: 10
      },
      showSaveModal: false,
      vHeaders: [{
          text: 'Nombre de nómina',
          value: 'nombre_nomina'
        },
        {
          text: 'Fecha de creación',
          value: 'fecha_creacion'
        },
        {
          text: 'Estatus',
          value: 'estatus'
        },
        {
          text: 'Total de nómina',
          value: 'total_nomina'
        },
        {
          text: 'Acciones',
          value: 'acciones'
        },
      ],
    }
  },
  watch: {
    search: function(value){
      this.getNominas();
    },
    showSaveModal: function (value) {
      if (!value) {
        this.getNominas();
      }
    },
  },
  methods: {
    getNominas: function (page = 1) {
      axios.get(`/nominas?page=${page}&query=${this.search}`)
        .then(response => {
          this.nominas = response.data;
        })
        .catch(error => errorHandling(error));
    },
    openEditModal: function (nomina) {
      this.currentNomina = nomina;
      this.showSaveModal = true;
    },
    openCreateModal: function () {
      this.currentNomina = null;
      this.showSaveModal = true;
    },

    exportToExcel: function(idNomina) {
      axios.get(laravelRoute('nominas.exportar_a_excel', idNomina), {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'nomina.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
    },

  },
  created() {
    this.getNominas();
  },
}
</script>