<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="550px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Editar calle</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md12>
              <v-text-field v-model="calle.nombre" required label="Nombre de la calle"></v-text-field>
            </v-flex>
            <v-flex md12>
                <v-text-field type="number" min="1" v-model="calle.superficie" required label="Superficie"></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" outline @click="updateCalle" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    calle: {},
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    calleId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    calleId: function() {
        this.getCalle();
    }
  },
  methods: {
    getCalle: function() {
        axios.get(laravelRoute('calles.show', this.calleId))
        .then(response => this.calle = response.data)
        .catch(error => errorHandling(error));
    },
    updateCalle: function() {
        this.vBtnSave.loading = true;
        axios.patch(laravelRoute('calles.update', this.calleId), {
            ...this.calle
        })
        .then(response => {
            this.vSnackBar.text = response.data.message_text;
            this.vSnackBar.visible = true;
        })
        .catch(error => errorHandling(error))
        .finally(() => this.vBtnSave.loading = false);
    }
  }
}
</script>
