var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Archivos personales")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-lg": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c("v-flex", { attrs: { xs9: "" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold green--text"
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Archivo\n                    "
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("v-flex", { attrs: { xs3: "" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-bold green--text"
                                          },
                                          [
                                            _vm._v(
                                              "\n                      Acciones\n                    "
                                            )
                                          ]
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-divider")
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticStyle: {
                                            "align-self": "center"
                                          },
                                          attrs: { xs8: "" }
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Acta de Nacimiento\n                      "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs2: "" } },
                                        [
                                          _vm.personal_files.find(function(
                                            file
                                          ) {
                                            return (
                                              file.type == "Acta de Nacimiento"
                                            )
                                          }) == null
                                            ? _c(
                                                "v-tooltip",
                                                { attrs: { bottom: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        slot: "activator",
                                                        icon: "",
                                                        disabled: !_vm.$root.can(
                                                          "files",
                                                          _vm.permissions
                                                        ),
                                                        flat: "",
                                                        color: "warning"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.type =
                                                            "Acta de Nacimiento"
                                                          _vm.dialogFile2 = true
                                                        }
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "orange"
                                                          }
                                                        },
                                                        [_vm._v("contact_mail")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Click Para Subir Acta de Nacimiento"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-tooltip",
                                                { attrs: { bottom: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        slot: "activator",
                                                        target: "_blank",
                                                        icon: "",
                                                        color: "green",
                                                        flat: "",
                                                        href: _vm.personal_files.find(
                                                          function(file) {
                                                            return (
                                                              file.type ==
                                                              "Acta de Nacimiento"
                                                            )
                                                          }
                                                        ).url
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { dark: "" } },
                                                        [_vm._v("contact_mail")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Click Para Ver Archivo"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs2: "" } },
                                        [
                                          _c(
                                            "v-tooltip",
                                            { attrs: { bottom: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    slot: "activator",
                                                    disabled:
                                                      !_vm.$root.can(
                                                        "files",
                                                        _vm.permissions
                                                      ) ||
                                                      _vm.personal_files.find(
                                                        function(file) {
                                                          return (
                                                            file.type ==
                                                            "Acta de Nacimiento"
                                                          )
                                                        }
                                                      ) == null,
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.deleteFile(
                                                        _vm.personal_files.find(
                                                          function(file) {
                                                            return (
                                                              file.type ==
                                                              "Acta de Nacimiento"
                                                            )
                                                          }
                                                        )
                                                      )
                                                    }
                                                  },
                                                  slot: "activator"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { color: "red" } },
                                                    [_vm._v("delete")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v("Eliminar Archivo")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticStyle: {
                                            "align-self": "center"
                                          },
                                          attrs: { xs8: "" }
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        INE\n                      "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs2: "" } },
                                        [
                                          _vm.personal_files.find(function(
                                            file
                                          ) {
                                            return file.type == "INE"
                                          }) == null
                                            ? _c(
                                                "v-tooltip",
                                                { attrs: { bottom: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        slot: "activator",
                                                        icon: "",
                                                        disabled: !_vm.$root.can(
                                                          "files",
                                                          _vm.permissions
                                                        ),
                                                        flat: "",
                                                        color: "warning"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.type = "INE"
                                                          _vm.dialogFile2 = true
                                                        }
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "orange"
                                                          }
                                                        },
                                                        [_vm._v("contact_mail")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Click Para Subir INE"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-tooltip",
                                                { attrs: { bottom: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        slot: "activator",
                                                        target: "_blank",
                                                        icon: "",
                                                        color: "green",
                                                        flat: "",
                                                        href: _vm.personal_files.find(
                                                          function(file) {
                                                            return (
                                                              file.type == "INE"
                                                            )
                                                          }
                                                        ).url
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { dark: "" } },
                                                        [_vm._v("contact_mail")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Click Para Ver Archivo"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs2: "" } },
                                        [
                                          _c(
                                            "v-tooltip",
                                            { attrs: { bottom: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    slot: "activator",
                                                    disabled:
                                                      !_vm.$root.can(
                                                        "files",
                                                        _vm.permissions
                                                      ) ||
                                                      _vm.personal_files.find(
                                                        function(file) {
                                                          return (
                                                            file.type == "INE"
                                                          )
                                                        }
                                                      ) == null,
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.deleteFile(
                                                        _vm.personal_files.find(
                                                          function(file) {
                                                            return (
                                                              file.type == "INE"
                                                            )
                                                          }
                                                        )
                                                      )
                                                    }
                                                  },
                                                  slot: "activator"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { color: "red" } },
                                                    [_vm._v("delete")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v("Eliminar Archivo")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticStyle: {
                                            "align-self": "center"
                                          },
                                          attrs: { xs8: "" }
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        CURP\n                      "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs2: "" } },
                                        [
                                          _vm.personal_files.find(function(
                                            file
                                          ) {
                                            return file.type == "CURP"
                                          }) == null
                                            ? _c(
                                                "v-tooltip",
                                                { attrs: { bottom: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        slot: "activator",
                                                        icon: "",
                                                        disabled: !_vm.$root.can(
                                                          "files",
                                                          _vm.permissions
                                                        ),
                                                        flat: "",
                                                        color: "warning"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.type = "CURP"
                                                          _vm.dialogFile2 = true
                                                        }
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "orange"
                                                          }
                                                        },
                                                        [_vm._v("contact_mail")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Click Para Subir CURP"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-tooltip",
                                                { attrs: { bottom: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        slot: "activator",
                                                        target: "_blank",
                                                        icon: "",
                                                        color: "green",
                                                        flat: "",
                                                        href: _vm.personal_files.find(
                                                          function(file) {
                                                            return (
                                                              file.type ==
                                                              "CURP"
                                                            )
                                                          }
                                                        ).url
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { dark: "" } },
                                                        [_vm._v("contact_mail")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Click Para Ver Archivo"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs2: "" } },
                                        [
                                          _c(
                                            "v-tooltip",
                                            { attrs: { bottom: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    slot: "activator",
                                                    disabled:
                                                      !_vm.$root.can(
                                                        "files",
                                                        _vm.permissions
                                                      ) ||
                                                      _vm.personal_files.find(
                                                        function(file) {
                                                          return (
                                                            file.type == "CURP"
                                                          )
                                                        }
                                                      ) == null,
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.deleteFile(
                                                        _vm.personal_files.find(
                                                          function(file) {
                                                            return (
                                                              file.type ==
                                                              "CURP"
                                                            )
                                                          }
                                                        )
                                                      )
                                                    }
                                                  },
                                                  slot: "activator"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { color: "red" } },
                                                    [_vm._v("delete")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v("Eliminar Archivo")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticStyle: {
                                            "align-self": "center"
                                          },
                                          attrs: { xs8: "" }
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        RFC\n                      "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs2: "" } },
                                        [
                                          _vm.personal_files.find(function(
                                            file
                                          ) {
                                            return file.type == "RFC"
                                          }) == null
                                            ? _c(
                                                "v-tooltip",
                                                { attrs: { bottom: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        slot: "activator",
                                                        icon: "",
                                                        disabled: !_vm.$root.can(
                                                          "files",
                                                          _vm.permissions
                                                        ),
                                                        flat: "",
                                                        color: "warning"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.type = "RFC"
                                                          _vm.dialogFile2 = true
                                                        }
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "orange"
                                                          }
                                                        },
                                                        [_vm._v("contact_mail")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Click Para Subir RFC"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-tooltip",
                                                { attrs: { bottom: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        slot: "activator",
                                                        target: "_blank",
                                                        icon: "",
                                                        color: "green",
                                                        flat: "",
                                                        href: _vm.personal_files.find(
                                                          function(file) {
                                                            return (
                                                              file.type == "RFC"
                                                            )
                                                          }
                                                        ).url
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { dark: "" } },
                                                        [_vm._v("contact_mail")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Click Para Ver Archivo"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs2: "" } },
                                        [
                                          _c(
                                            "v-tooltip",
                                            { attrs: { bottom: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    slot: "activator",
                                                    disabled:
                                                      !_vm.$root.can(
                                                        "files",
                                                        _vm.permissions
                                                      ) ||
                                                      _vm.personal_files.find(
                                                        function(file) {
                                                          return (
                                                            file.type == "RFC"
                                                          )
                                                        }
                                                      ) == null,
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.deleteFile(
                                                        _vm.personal_files.find(
                                                          function(file) {
                                                            return (
                                                              file.type == "RFC"
                                                            )
                                                          }
                                                        )
                                                      )
                                                    }
                                                  },
                                                  slot: "activator"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { color: "red" } },
                                                    [_vm._v("delete")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v("Eliminar Archivo")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          staticStyle: {
                                            "align-self": "center"
                                          },
                                          attrs: { xs8: "" }
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "font-weight-bold"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        Comprobante de Domicilio\n                      "
                                                )
                                              ]
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs2: "" } },
                                        [
                                          _vm.personal_files.find(function(
                                            file
                                          ) {
                                            return (
                                              file.type ==
                                              "Comprobante de Domicilio"
                                            )
                                          }) == null
                                            ? _c(
                                                "v-tooltip",
                                                { attrs: { bottom: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        slot: "activator",
                                                        icon: "",
                                                        disabled: !_vm.$root.can(
                                                          "files",
                                                          _vm.permissions
                                                        ),
                                                        flat: "",
                                                        color: "warning"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.type =
                                                            "Comprobante de Domicilio"
                                                          _vm.dialogFile2 = true
                                                        }
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "orange"
                                                          }
                                                        },
                                                        [_vm._v("contact_mail")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Click Para Subir Comprobante de Domicilio"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            : _c(
                                                "v-tooltip",
                                                { attrs: { bottom: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        slot: "activator",
                                                        target: "_blank",
                                                        icon: "",
                                                        color: "green",
                                                        flat: "",
                                                        href: _vm.personal_files.find(
                                                          function(file) {
                                                            return (
                                                              file.type ==
                                                              "Comprobante de Domicilio"
                                                            )
                                                          }
                                                        ).url
                                                      },
                                                      slot: "activator"
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { dark: "" } },
                                                        [_vm._v("contact_mail")]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [
                                                    _vm._v(
                                                      "Click Para Ver Archivo"
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs2: "" } },
                                        [
                                          _c(
                                            "v-tooltip",
                                            { attrs: { bottom: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    slot: "activator",
                                                    disabled:
                                                      !_vm.$root.can(
                                                        "files",
                                                        _vm.permissions
                                                      ) ||
                                                      _vm.personal_files.find(
                                                        function(file) {
                                                          return (
                                                            file.type ==
                                                            "Comprobante de Domicilio"
                                                          )
                                                        }
                                                      ) == null,
                                                    icon: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.deleteFile(
                                                        _vm.personal_files.find(
                                                          function(file) {
                                                            return (
                                                              file.type ==
                                                              "Comprobante de Domicilio"
                                                            )
                                                          }
                                                        )
                                                      )
                                                    }
                                                  },
                                                  slot: "activator"
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { color: "red" } },
                                                    [_vm._v("delete")]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v("Eliminar Archivo")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("upload-client-files-modal", {
            attrs: {
              element_type: _vm.element_type,
              element_id: _vm.element_id,
              dialog: _vm.dialogFile2,
              type: _vm.type
            },
            on: {
              close: function($event) {
                _vm.dialogFile2 = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }