<template>
    <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn flat icon href="/solicitud_de_material">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>

        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-toolbar card prominent>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="body-2 grey--text">Crear solicitud de materiales</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                        <v-container grid-list-md>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-layout wrap>
                                    <v-flex md6>
                                        <v-text-field :rules="rules" :value="nombre" required label="Nombre de la orden" readonly></v-text-field>
                                    </v-flex>
                                    <v-flex md6>
                                        <v-text-field :rules="rules" v-model="solicitud.fecha_solicitud" required label="Fecha de solicitud" type="date"></v-text-field>
                                    </v-flex>
                                    <v-flex 
                                        :md6="(solicitud.solicitante == 'Maquinaría') ? false : true"
                                        :md4="(solicitud.solicitante == 'Maquinaría') ? true : false"
                                    >
                                        <v-select v-if="(rol == 'Administrador') || (rol == 'Almacen')" :rules="rules" v-model="solicitud.solicitante" :items="solicitante" required label="Solicitante"></v-select>
                                        <v-text-field v-else :value="rol" required label="Solicitante" readonly></v-text-field>
                                    </v-flex>
                                    <v-flex
                                        v-if="solicitud.solicitante == 'Maquinaría'"
                                        md4
                                    >                                        
                                        <searcher
                                            :configuration="searchMaquinaria"
                                            :itemSelected.sync="solicitud.maquinaria"
                                        ></searcher>
                                    </v-flex>
                                    <v-flex 
                                        :md6="(solicitud.solicitante == 'Maquinaría') ? false : true"
                                        :md4="(solicitud.solicitante == 'Maquinaría') ? true : false"
                                    >
                                        <searcher
                                            :configuration="searchConfig"
                                            :itemSelected.sync="fraccionamiento"
                                        ></searcher>
                                    </v-flex>
                                </v-layout>

                                <v-divider></v-divider>
                                <div>
                                    <template v-for="(material, index) in materiales">
                                        <v-layout wrap :key="index">
                                            <v-flex md3>
                                                <searcher
                                                    :configuration="searchCategories"
                                                    :itemSelected.sync="material.categoria"
                                                ></searcher>
                                            </v-flex>
                                            <v-flex md3>
                                                <v-autocomplete
                                                    :items="(material.categoria) ? material.categoria.materiales : []"
                                                    v-model="material.material"
                                                    :rules="rules"
                                                    return-object
                                                    item-text="nombre"
                                                    label="Material"
                                                    single-line
                                                ></v-autocomplete>
                                            </v-flex>
                                            <v-flex md3>
                                                <v-text-field
                                                    :value="material.material.unidad"
                                                    readonly
                                                    required
                                                    label="Unidades"
                                                    disabled
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex md3>
                                                <v-text-field
                                                    v-model="material.cantidad"
                                                    type="number"
                                                    min="0"
                                                    required
                                                    :rules="rules"
                                                    label="Cantidad"
                                                ></v-text-field>
                                            </v-flex>
                                            <template v-if="fraccionamiento.id">
                                                <v-flex md3>
                                                    <v-select
                                                        :items="['Manzanas', 'Calles', 'Áreas verdes']"
                                                        v-model="material.distribucion"
                                                        :rules="rules"
                                                        label="Distribución"
                                                        single-line
                                                    ></v-select>
                                                </v-flex>
                                                <v-flex md3>
                                                    <v-select
                                                        v-if="material.distribucion == 'Manzanas'"
                                                        :items="fraccionamiento.prospecto.propuesta[0].only_manzanas"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        v-model="material.manzana"
                                                        :rules="rules"
                                                        label="Manzanas"
                                                        return-object
                                                        single-line
                                                    ></v-select>
                                                    <v-select
                                                        v-else-if="material.distribucion == 'Calles'"
                                                        :items="fraccionamiento.prospecto.propuesta[0].calles"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        v-model="material.calle"
                                                        :rules="rules"
                                                        label="Calles"
                                                        single-line
                                                    ></v-select>
                                                    <v-select
                                                        v-else-if="material.distribucion == 'Áreas verdes'"
                                                        :items="fraccionamiento.prospecto.propuesta[0].areas_verdes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        v-model="material.area_verde"
                                                        :rules="rules"
                                                        label="Áreas verdes"
                                                        single-line
                                                    ></v-select>
                                                </v-flex>
                                                <v-flex md3 v-if="material.manzana && material.distribucion == 'Manzanas'">
                                                    <v-select
                                                        :items="material.manzana.lotes"
                                                        v-model="material.lote"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Lotes"
                                                        single-line
                                                    ></v-select>
                                                </v-flex>
                                                <v-flex md3>
                                                    <searcher
                                                        v-if="material.manzana && material.distribucion == 'Manzanas' && material.lote"
                                                        :configuration="searchConcepto(material.lote)"
                                                        :itemSelected.sync="material.concepto"
                                                    ></searcher>

                                                    <searcher
                                                        v-if="(material.calle || material.area_verde) && material.distribucion != 'Manzanas'"
                                                        :configuration="searchConceptosUrbanizacion"
                                                        :itemSelected.sync="material.concepto"
                                                    ></searcher>
                                                </v-flex>
                                                <v-flex md3>
                                                    <searcher
                                                        v-if="material.manzana && material.distribucion == 'Manzanas' && material.lote && material.concepto"
                                                        :configuration="searchRubro(material.concepto.id, material.lote)"
                                                        :itemSelected.sync="material.rubro"
                                                    ></searcher>
                                                    
                                                    <v-select
                                                        v-if="(material.calle || material.area_verde) && material.concepto && material.distribucion != 'Manzanas'"
                                                        v-model="material.rubro"
                                                        :items="material.concepto.rubros"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Rubro"
                                                        return-object
                                                        single-line
                                                    ></v-select>
                                                </v-flex>
                                            </template>
                                            <v-flex md1>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn
                                                            @click="materiales.splice(index, 1)"
                                                            v-if="index != 0"
                                                            color="red"
                                                            v-on="on"
                                                            small
                                                            flat
                                                            dark
                                                            fab
                                                        >
                                                            <v-icon dark>delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Eliminar</span>
                                                </v-tooltip>
                                            </v-flex>
                                        </v-layout>
                                        <v-divider :key="index"></v-divider>
                                    </template>
                                </div>
                            </v-form>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            :loading="vBtnSave.loading"
                            @click="storeMateriales"
                            color="verdeFayal"
                            outline
                            dark
                            flat
                        >
                            Guardar
                        </v-btn>
                        <v-btn dark color="verdeFayal" @click="addMateriales">
                            <v-icon>add</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<script>
    import moment from "moment";

    export default {
        data: () => ({
            solicitud: {
                solicitante: null,
                fecha_solicitud: null,
                estado: null,
                maquinaria: {}
            },
            solicitante: [
                'Obra',
                'Urbanización',
                'Maquinaría',
            ],
            fraccionamiento: {
                id: null,
                prospecto: {
                    propuesta: [{
                        manzanas: []
                    }]
                }
            },
            searchCategories: {
                url: "/categorias/filtro/",
                paginatedContainer: 'data',
                noDataText: "No se encontraron resultados.",
                label: "Categoria",
                itemText: "nombre",
                itemValue: "id",
                descriptionLimit: 60,
                disabled: false,
                useHeaders: true,
            },
            searchConceptosUrbanizacion: {
                url: "/conceptos/index_refactor?tipo=urbanizacion",
                paginatedContainer: 'data',
                noDataText: "No se encontraron resultados.",
                label: "Concepto",
                itemText: "nombre",
                itemValue: "id",
                descriptionLimit: 60,
                disabled: false,
                useHeaders: true,
            },
            categories: [],
            estados: [],
            fraccionamientosSelect: [{
                prospecto:{
                    id: null,
                    nombre_proyecto: null,
                    propuesta_lotificacion: [{
                        manzanas:[{
                        nombre: null,
                        lotes:[{
                            nombre: null
                        }]
                        }]
                    }]
                }
            }],
            searchConfig: {
                url: "/fraccionamientos/con_propuestas",
                paginatedContainer: 'data',
                noDataText: "No se encontraron resultados.",
                label: "Nombre del proyecto",
                itemText: "nombre_proyecto",
                itemValue: "id",
                descriptionLimit: 60,
                disabled: false,
                useHeaders: true,
            },
            searchMaquinaria: {
                url: "/maquinaria",
                paginatedContainer: 'data',
                noDataText: "No se encontraron resultados.",
                label: "Maquinaría",
                itemText: "nombre",
                itemValue: "id",
                descriptionLimit: 60,
                disabled: false,
                useHeaders: true,
            },
            valid: false,
            proyectosLoading: false,
            currentMateriales: [],
            materiales: [
                {
                    categoria_id: null,
                    material: {
                        precio: 0,
                        existencia: 0
                    },
                    cantidad: 0,
                    materiales: [],
                    proyecto_id: null,
                }
            ],
            rules: [v => !!v || "Este campo es requerido"],
            vBtnSave: {
                loading: false
            },
            vSnackBar: {
                visible: false,
                text: null
            },
        }),
        props: ["rol"],
        computed: {
            nombre: function() {
                return `Solicitud de material ${moment().format('DD/MM/YYYY HH:mm:ss')}`;
            }
        },
        methods: {
            findFraccionamiento(id){
                let proyectoId = this.materiales[id].proyecto_id;
                if(!proyectoId) return [];
                return this.fraccionamientosSelect.find(element => element.id == proyectoId);
            },
            storeMaterial: function() {
                this.vBtnSave.loading = true;
                axios.post(laravelRoute('salidas_de_almacen.store'), {
                    nombre_de_orden: this.salidas.nombre_de_orden,
                    solicitante: this.salidas.solicitante,
                    fecha_solicitud: this.salidas.fecha_solicitud,
                    estado: this.salidas.estado
                })
                .then(response => {
                    alertify.success(response.data.message_text);
                    this.localShow = (this.keepStoring) ? true : false
                    this.salidas = {};
                })
                .catch(error => {
                    errorHandling(error)
                })
                .finally(()=>this.vBtnSave.loading = false);
            },
            getStatus: function() {
                axios.get("/salidas_de_almacen/estados").then(response => {
                    this.estados = response.data;
                });
            },
            getCategories: function() {
                axios.get(laravelRoute("categorias.index"))
                .then(response => {
                    this.categories = response.data.data;
                })
                .catch(error => errorHandling(error));
            },
            getCurrentMateriales: function(category_id, index) {
                var categories = [];
                this.categories.map(function(category) {
                    categories.push(category);
                });
                this.materiales[index].materiales = categories.find(function(category) {
                    return category.id == category_id;
                }).materiales;
            },
            getManzanasItems: function (id) {
                let fraccionamiento = this.findFraccionamiento(id);
                if (fraccionamiento.length < 1) return [];
                return fraccionamiento.prospecto.propuesta_lotificacion[0].manzanas;
            },
            addMateriales: function() {
                this.materiales.push({
                    categoria_id: null,
                    material: {
                        precio: 0
                    },
                    cantidad: 0,
                    materiales: [],
                });
            },
            storeMateriales: function() {
                if (this.$refs.form.validate()) {
                    this.vBtnSave.loading = true;

                    axios.post(laravelRoute('salidas_de_almacen.store'), {
                        nombre_de_orden: this.nombre,
                        solicitante: this.solicitud.solicitante,
                        fecha_solicitud: this.solicitud.fecha_solicitud,
                        materiales: this.materiales,
                        fraccionamiento_id: this.fraccionamiento.id,
                        maquinaria_id: this.solicitud.maquinaria.id
                    })
                    .then(response => {
                        this.vSnackBar.text = response.data.message_text;
                        this.vSnackBar.visible = true;

                        setTimeout(function () {
                            location.reload();
                        }, 1500);
                    })
                    .catch(error => {
                        errorHandling(error)
                    })
                    .finally(()=>this.vBtnSave.loading = false);
                }
            },
            searchConcepto: function(loteId) {
                return {
                    url: `/conceptos/lotes/${loteId}`,
                    paginatedContainer: 'data',
                    noDataText: "No se encontraron resultados.",
                    label: "Concepto",
                    itemText: "nombre",
                    itemValue: "id",
                    descriptionLimit: 60,
                    disabled: false,
                    useHeaders: true,
                }
            },
            searchRubro: function(conceptoId, loteId) {
                return {
                    url: `/rubros/lotes/${loteId}?concepto_id=${conceptoId}`,
                    paginatedContainer: 'data',
                    noDataText: "No se encontraron resultados.",
                    label: "Rubro",
                    itemText: "nombre",
                    itemValue: "id",
                    descriptionLimit: 60,
                    disabled: false,
                    useHeaders: true,
                }
            }
        },
        mounted() {
            this.getCategories();
        }
    }
</script>
<style>
    .card--flex-toolbar {
        margin-top: -64px;
    }

    .disable-events {
        pointer-events: none;
    }
</style>
