<template>
  <v-card>
    <v-toolbar color="verdeFayal" dark extended flat></v-toolbar>
    <v-layout row pb-2>
      <v-flex xs10 offset-xs1>
        <v-card class="card--flex-toolbar" flat>
          <v-toolbar card prominent>
            <v-spacer></v-spacer>
            <v-toolbar-title class="body-2 grey--text">Configuraciones generales</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex 
                v-for="configuracion in configuraciones.data"
                :key="configuracion.id"
                md6
                xs12
              >
                <v-card>
                  <v-toolbar card>
                    <v-toolbar-title flat>{{ configuracion.nombre }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <div>
                      <div class="headline"></div>
                      <div>{{ configuracion.descripcion }}</div>
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-text-field
                      v-model="configuracion.valor"
                      label="Valor"
                      type="number"
                    ></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn @click="updateSettings(configuracion.id, configuracion.valor)" color="verdeFayal" flat outline>Actualizar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      configuraciones: {
        data: []
      },
      search: null,
      impuesto: {
        valor: null
      },
      vBtnSave: {
        loading: false
      },
      vSnackBar: {
        visible: false,
        text: null
      },
    };
  },
  props:['permissions_impuesto'],
  methods: {
    getSettings: function() {
      axios.get(laravelRoute('configuraciones.index'))
      .then(response => this.configuraciones = response.data)
      .catch(error => errorHandling(error));
    },
    getImpuesto: function() {
      axios.get(laravelRoute('configuraciones.show', 1))
      .then(response => {
        this.impuesto = response.data;
      })
      .catch(error => errorHandling(error));
    },
    updateSettings: function(settingId, value) {
      axios.patch(laravelRoute('configuraciones.show', settingId), {
        valor: value
      })
      .then(response => {
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;
        this.getSettings();
      })
      .catch(error => errorHandling(error));
    },
    updateImpuesto: function() {
      this.vBtnSave.loading = true;
      axios.patch(laravelRoute('configuraciones.show', 1), {
        valor: this.impuesto.valor 
      })
      .then(response => {
        this.vBtnSave.loading = false;
        alertify.success(response.data.message_text);
        this.getImpuesto();
      })
      .catch(error => {
        this.vBtnSave.loading = false;
        errorHandling(error)
      });
    }
  },
  mounted: function () {
    this.getSettings();
  }
};
</script>

<style>
  .card--flex-toolbar {
    margin-top: -64px;
  }
  .settings-card {
    border-style: solid;
    border-color: #000 !important;
    border-width: thin;
  }
</style>