<template>
    <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="850px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Nuevo mantenimiento</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-select
                  color="verdeFayal"
                  :items="maquinariaslistProps"
                  v-model="mantenimiento.maquinaria_id"
                  item-value="id"
                  item-text="nombre"
                  label="Seleccione máquina"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  color="verdeFayal"
                  :items="mantenimientoslistProps"
                  v-model="mantenimiento.mantenimiento_id"
                  item-value="id"
                  item-text="nombre"
                  label="Seleccione mantenimiento"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  color="verdeFayal"
                  :items="status"
                  v-model="mantenimiento.estado"
                  item-value="id"
                  item-text="estado"
                  label="Seleccione status"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field color="#1ABA24" v-model="mantenimiento.fecha" label="Fecha de inicio de mantenimiento" type="date"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="storeMantenimiento" :loading="vBtnSave.loading" dark color="verdeFayal" flat> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>  
</template>

<script>
import { laravelRoute, errorHandling } from '../../../lib/utilities';
export default {
  data(){
    return{
      localShow: false,
      keepStoring: true,
      mantenimientoslistProps: this.mantenimientoslist,
      maquinariaslistProps: this.maquinariaslist,
      vBtnSave: {
        loading: false
      },
      vSnackBar: {
        visible: false,
        text: null
      },
      status: [],
      mantenimiento: {
        maquinaria_id: null,
        mantenimiento_id: null,
        estado: null,
        fecha: null
      }
    }
  },
  props: {
      show: Boolean,
      maquinas: Array,
      mantenimientoslist: Array,
      maquinariaslist: Array,
  },
  methods:{
    storeMantenimiento: function(){
      this.vBtnSave.loading = true;
      axios.post(laravelRoute('mantenimientos.store'), {
        maquinaria_id: this.mantenimiento.maquinaria_id,
        mantenimiento_id: this.mantenimiento.mantenimiento_id,
        estado: this.mantenimiento.estado,
        fecha: this.mantenimiento.fecha
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.mantenimiento = {};
        this.localShow = (this.keepStoring) ? false : true
      })
      .catch(error => {
        errorHandling(error)
      })
      .finally(()=>this.vBtnSave.loading = false);
    },
    getStatus: function(){
      axios
      .get(laravelRoute('mantenimientos.estados'))
      .then(response => {
        this.status = response.data;
      })
      .catch(error => errorHandling(error));
    },
  },
  watch: {
    show: function(value){
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
      this.getStatus();
      // this.getMateriales();
    }
  }
}
</script>