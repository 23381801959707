var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        "v-card",
        [
          _c("v-flex", { attrs: { xs12: "" } }, [
            _c("div", {}, [
              _c(
                "a",
                { attrs: { href: "../prospectos" } },
                [
                  _c(
                    "v-btn",
                    { staticClass: "white--text", attrs: { color: "#1ABA24" } },
                    [_vm._v("Ir a prospectos")]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("v-card-title", [
            _c("span", { staticClass: "headline" }, [_vm._v("Ver prospecto")])
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { attrs: { "grid-list-md": "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Nombre del Proyecto",
                              disabled: ""
                            },
                            model: {
                              value: _vm.prospecto.nombre_proyecto,
                              callback: function($$v) {
                                _vm.$set(_vm.prospecto, "nombre_proyecto", $$v)
                              },
                              expression: "prospecto.nombre_proyecto"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Dirección",
                              disabled: ""
                            },
                            model: {
                              value: _vm.prospecto.direccion,
                              callback: function($$v) {
                                _vm.$set(_vm.prospecto, "direccion", $$v)
                              },
                              expression: "prospecto.direccion"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm6: "", md6: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Fecha",
                              type: "date",
                              disabled: ""
                            },
                            model: {
                              value: _vm.prospecto.fecha,
                              callback: function($$v) {
                                _vm.$set(_vm.prospecto, "fecha", $$v)
                              },
                              expression: "prospecto.fecha"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm12: "", md12: "" } },
                        [
                          _c("v-textarea", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Descripción",
                              disabled: ""
                            },
                            model: {
                              value: _vm.prospecto.descripcion,
                              callback: function($$v) {
                                _vm.$set(_vm.prospecto, "descripcion", $$v)
                              },
                              expression: "prospecto.descripcion"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-flex",
                        { attrs: { md12: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              color: "#1ABA24",
                              label: "Ver planos",
                              readonly: ""
                            },
                            on: {
                              click: function($event) {
                                _vm.elementId = _vm.prospectosProps.id
                                _vm.dialogFile = true
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("upload-files-modal", {
        attrs: {
          element_type: "App\\Prospecto",
          element_id: _vm.elementId,
          dialog: _vm.dialogFile
        },
        on: {
          close: function($event) {
            _vm.dialogFile = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }