<template>
  <div>
    <!-- <v-form v-on:submit.prevent="submitForm" ref="formulario" v-model="instalacionIsValid" lazy-validation> -->
    <v-card>
      <v-card-title class="fyl-headerTable">
        <v-flex md6 class="card-blog">
          <h2 class="card-title">Agregar un nuevo Rol</h2>
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-form v-on:submit.prevent="submitForm" v-model="dataToSendIsValid" ref="formulario" lazy-validation>
        <v-flex xs12 sm6 md3>
          <v-text-field color="#1ABA24" label="Nombre del nuevo rol" v-model="newRoleName"></v-text-field>
        </v-flex>

        <v-data-table
          color="#1ABA24"
          v-model="selectedToshow"
          :headers="headers"
          :items="permisosComputated"
          :pagination.sync="pagination"
          select-all
          item-key="name"
          class="elevation-1">
          <template v-slot:headers="permisosProps">
            <tr>
              <th>
                <v-checkbox
                  :input-value="permisosProps.all"
                  :indeterminate="permisosProps.indeterminate"
                  primary
                  hide-details
                  @click.stop="toggleAll"
                ></v-checkbox>
              </th>
              <th
                v-for="header in permisosProps.headers"
                :key="header.text"
                :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                @click="changeSort(header.value)"
              >
                <v-icon small>arrow_upward</v-icon>
                {{ header.text }}
              </th>
            </tr>
          </template>
          <template v-slot:items="props">
            <tr style="background: white;">
              <td colspan="3">
                <v-layout  style="background: white;" row wrap>
                  <v-flex xs12 lg12 style="background: white;">
                    <v-expansion-panel popout style="background: white !important;">
                      <v-expansion-panel-content style="background: white;">
                        <div color="#1ABA24" style="background: white;" slot="header">{{Object.keys(props.item)[0].charAt(0).toUpperCase() + Object.keys(props.item)[0].slice(1)}}</div>
                        <v-card style="background: white;">
                          <v-card-text color="#1ABA24" style="background: white;">
                            <td v-for="(it, index) in props.item" :key="index" style="background: #fff;">
                              <li v-for="(item, index) in it" :key="index">
                                <td>
                                  <v-checkbox
                                    v-model="selected"
                                    :value="item.name"
                                    primary
                                    hide-details
                                    :label="`${item.description}`"
                                  ></v-checkbox>
                                </td>
                              </li>
                            </td>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-flex>
                </v-layout>
              </td>
            </tr>
          </template>
        </v-data-table>

        <v-btn
          class="white--text pull-right fyl-btnCreate" 
          type="submit" color="blue" 
          :loading="creatingRole" 
          :disabled="!dataToSendIsValid || creatingRole"
        >
          crear
        </v-btn>

      </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>             


<script>
import { errorHandling, laravelRoute } from "../../lib/utilities";
import axios from "axios";
import swal from "sweetalert";

export default {
  mounted() {
  },
  data() {
    return {
      permisosProps: Object.values(this.permissions),
      pagination: {
        sortBy: 'name'
      },
      headers: [
        {
          sortable: false,
          text: 'Nombre',
          align: 'left',
          value: 'name'
        }
      ],

      dataToSendIsValid: true,
      newRoleName: '',
      selected: [],
      selectedToshow: [],
      creatingRole: false,
    };
  },
  props: [
    'permissions',
  ],
  watch: {},
  methods: {
    toggleAll () {
      if (this.selected.length) {
        this.selected = []
        this.selectedToshow = []
      }else{
        this.selected = this.permisosProps.slice().map(element => element.name)
        this.selectedToshow = this.permisosComputated.slice()
      }
    },
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },

    submitForm: function () {
      if (!this.$refs.formulario.validate()) {
        this.dataToSendIsValid = false;
        return;
      }
      this.storeRole(this.newRole);
    },
    storeRole: function(body) {
      this.creatingRole = true;
      body.permission.forEach(element => delete element.description);
      return axios.post(laravelRoute('roles.store'), body)
        .then(response => {
          if (response.data.message_text) alertify.success(response.data.message_text)
        })
        .catch(error => errorHandling(error))
        .finally(() => this.creatingRole = false)
    }
  },
  computed: {
    newRole: function() {
      return {
        name: this.newRoleName,
        permission: this.selected.map(element => element.name ? element.name : element)
      }
    },
    permisosComputated: function() {
      let categoriasFiltered = [];
      let categoriasWithPermissions = [];
      this.permisosProps.forEach(element => {
        if(categoriasFiltered.includes(element.categoria)) return;
        categoriasFiltered.push(element.categoria);
      });

      categoriasFiltered.forEach(elemento => {
        let objectElement = {};
        objectElement[elemento] = this.permisosProps.filter(element => {
            return element.categoria == elemento;
          });
        categoriasWithPermissions.push(objectElement);
      });

      return categoriasWithPermissions;
    },
  }
};
</script>

<!--<style scoped>
  .rolesCreateForm-title1{
    text-align: left;
    margin-left: 4%;
    font-size: 1.4em;
    font-weight: bold;
    padding: 2.5% 0;
    text-transform: uppercase;
  }
  .v-form{
    text-align: left !important;
    margin-left: 4% !important;
  }
  .elevation-1{
    width: 90%;
  }
</style>-->
<style>
  .fyl-headerTable{
    color: #000;
    background: #FCFCFC;
  }
</style>