var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-autocomplete",
    {
      attrs: {
        "browser-autocomplete": "off",
        "hide-selected": "",
        "return-object": "",
        items: _vm.items,
        loading: _vm.isLoading,
        "search-input": _vm.search,
        "hide-no-data": !_vm.search,
        "no-data-text": _vm.configuration.noDataText,
        "item-text": _vm.configuration.itemText,
        "item-value": _vm.configuration.itemValue,
        placeholder: _vm.configuration.placeholder,
        "prepend-icon": _vm.configuration.prependIcon,
        chips: _vm.configuration.chips,
        label: _vm.configuration.label,
        multiple: _vm.configuration.multiple,
        "deletable-chips": _vm.configuration.deleteChips,
        disabled: _vm.configuration.disabled
      },
      on: {
        "update:searchInput": function($event) {
          _vm.search = $event
        },
        "update:search-input": function($event) {
          _vm.search = $event
        },
        change: _vm.selected
      },
      model: {
        value: _vm.model,
        callback: function($$v) {
          _vm.model = $$v
        },
        expression: "model"
      }
    },
    [
      _c(
        "template",
        { slot: "no-data" },
        [
          _c(
            "v-list-tile",
            { staticStyle: { cursor: "pointer" } },
            [
              _c(
                "v-list-tile-content",
                [
                  _c("v-list-tile-title", [
                    _vm._v("No se encontró ningún registro.")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }