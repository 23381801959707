var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v(
                      "Archivos asociados a " + _vm._s(_vm.venta.lote.nombre)
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  [
                    _c(
                      "v-layout",
                      { attrs: { row: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          _vm._l(_vm.listado, function(archivo, index) {
                            return _c(
                              "v-list-tile",
                              { key: index, attrs: { avatar: "" } },
                              [
                                _c(
                                  "v-list-tile-content",
                                  [
                                    _c("v-list-tile-title", [
                                      _vm._v(_vm._s(archivo.nombre))
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-list-tile-action",
                                  [
                                    _c(
                                      "v-layout",
                                      [
                                        !_vm.fileExist(archivo.nombre)
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  icon: "",
                                                                  ripple: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.openFileModal(
                                                                      archivo.nombre
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "warning"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "insert_drive_file"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", [_vm._v("Subir")])
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.fileExist(archivo.nombre)
                                          ? _c(
                                              "v-tooltip",
                                              {
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-btn",
                                                            _vm._g(
                                                              {
                                                                attrs: {
                                                                  href: _vm.getUrl(
                                                                    archivo.nombre
                                                                  ).url,
                                                                  icon: "",
                                                                  ripple: "",
                                                                  target:
                                                                    "_blank"
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "verdeFayal"
                                                                  }
                                                                },
                                                                [_vm._v("task")]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v("Ver archivo")
                                                ])
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("upload-lotes-files", {
        attrs: {
          element_type: "App\\Venta",
          element_id: _vm.ventaId,
          show: _vm.showFileModal,
          type: _vm.type
        },
        on: {
          "update:show": function($event) {
            _vm.showFileModal = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }