<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="450px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Añadir tiempo de ejecución</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-card-text>
            <v-text-field
              label="Tiempo de ejecución en horas"
              type="number"
              v-model="rubro.horas"
            ></v-text-field>
          </v-card-text>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" outline flat :loading="vBtnSave.loading" @click="updateRubros">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    localShow: false,
    rubro: {
      horas: 0
    },
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    rubroId: Number,
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    rubroId: function() {
      this.getRubros();
    }
  },
  methods: {
    getRubros: function() {
        axios.get(laravelRoute('rubros.show', this.rubroId))
        .then(response => this.rubro = response.data)
        .catch( error => errorHandling(error))
    },
    updateRubros: function() {
        this.vBtnSave.loading = true;
        axios.patch(laravelRoute('rubros.update', this.rubroId), {
            "horas": this.rubro.horas
        })
        .then(response => {
          this.localShow = false;
          this.vSnackBar.text = response.data.message_text;
          this.vSnackBar.visible = true;
        })
        .catch( error => errorHandling(error))
        .finally(() => this.vBtnSave.loading = false);
    }
  }
}
</script>
