var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-flex",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              "disable-initial-sort": "",
              headers: _vm.vHeaders,
              items: _vm.households.data,
              "hide-actions": ""
            },
            scopedSlots: _vm._u([
              {
                key: "items",
                fn: function(props) {
                  return [
                    _c("td", [_vm._v(_vm._s(props.item.nombre))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(props.item.descripcion))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(props.item.tipo))]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c(
                          "v-layout",
                          [
                            _c(
                              "v-tooltip",
                              { attrs: { bottom: "" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      slot: "activator",
                                      icon: "",
                                      flat: "",
                                      dark: "",
                                      small: "",
                                      href:
                                        "/prototipos_vivienda/" +
                                        props.item.id +
                                        "/valores",
                                      disabled: !_vm.$root.can(
                                        "valores",
                                        _vm.permissions
                                      )
                                    },
                                    slot: "activator"
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "#1ABA24" } },
                                      [_vm._v("attach_money")]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Valores")])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-tooltip",
                              { attrs: { bottom: "" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      slot: "activator",
                                      icon: "",
                                      flat: "",
                                      dark: "",
                                      small: "",
                                      color: "info",
                                      disabled: !_vm.$root.can(
                                        "update",
                                        _vm.permissions
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openEditHouseholdModal(
                                          props.item.id
                                        )
                                      }
                                    },
                                    slot: "activator"
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "verdeFayal" } },
                                      [_vm._v("edit")]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Editar")])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-tooltip",
                              { attrs: { bottom: "" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      slot: "activator",
                                      icon: "",
                                      flat: "",
                                      dark: "",
                                      small: "",
                                      color: "info",
                                      disabled: !_vm.$root.can(
                                        "update",
                                        _vm.permissions
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openDuplicateHousehold(
                                          props.item.id
                                        )
                                      }
                                    },
                                    slot: "activator"
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "verdeFayal" } },
                                      [_vm._v("content_copy")]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Duplicar")])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-tooltip",
                              { attrs: { bottom: "" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      slot: "activator",
                                      href:
                                        "/prototipos_vivienda/" +
                                        props.item.id +
                                        "/materiales",
                                      icon: "",
                                      flat: "",
                                      dark: "",
                                      small: "",
                                      color: "verdeFayal"
                                    },
                                    slot: "activator"
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "customIcon",
                                      attrs: {
                                        src:
                                          "/fonts/local/construction_icon.svg"
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Lista de materiales")])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-tooltip",
                              { attrs: { bottom: "" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      slot: "activator",
                                      icon: "",
                                      flat: "",
                                      dark: "",
                                      small: "",
                                      color: "verdeFayal"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.exportToExcel(props.item.id)
                                      }
                                    },
                                    slot: "activator"
                                  },
                                  [_c("v-icon", [_vm._v("import_export")])],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Exportar a Excel")])
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-tooltip",
                              { attrs: { bottom: "" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      slot: "activator",
                                      icon: "",
                                      flat: "",
                                      dark: "",
                                      small: "",
                                      color: "error",
                                      disabled: !_vm.$root.can(
                                        "remove",
                                        _vm.permissions
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteHousehold(
                                          props.item.id
                                        )
                                      }
                                    },
                                    slot: "activator"
                                  },
                                  [_c("v-icon", [_vm._v("delete")])],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Eliminar")])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("pagination", {
                attrs: { limit: _vm.vPaginator.limit, data: _vm.households },
                on: { "pagination-change-page": _vm.getHouseholds }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("edit-households-modal", {
        attrs: {
          show: _vm.showEditHouseholdModal,
          "household-id": _vm.editHouseoldId
        },
        on: {
          "update:show": function($event) {
            _vm.showEditHouseholdModal = $event
          },
          "update:householdId": function($event) {
            _vm.editHouseoldId = $event
          },
          "update:household-id": function($event) {
            _vm.editHouseoldId = $event
          }
        }
      }),
      _vm._v(" "),
      _c("duplicate-household-modal", {
        attrs: {
          show: _vm.showDuplicateHouseHold,
          "household-id": _vm.currentHouseholdId
        },
        on: {
          "update:show": function($event) {
            _vm.showDuplicateHouseHold = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }