var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-toolbar",
                {
                  attrs: { color: "white", flat: "", tabs: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "extension",
                      fn: function() {
                        return [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                centered: "",
                                "slider-color": "verdeFayal"
                              },
                              model: {
                                value: _vm.vTabs,
                                callback: function($$v) {
                                  _vm.vTabs = $$v
                                },
                                expression: "vTabs"
                              }
                            },
                            [
                              (_vm.$root.can("index", _vm.permissions_prototipo)
                              ? true
                              : false)
                                ? _c(
                                    "v-tab",
                                    { attrs: { href: "#listado-tab" } },
                                    [_vm._v("Listado")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              (_vm.$root.can("index", _vm.permissions_concepto)
                              ? true
                              : false)
                                ? _c(
                                    "v-tab",
                                    { attrs: { href: "#conceptos-tab" } },
                                    [_vm._v("Conceptos y Rubros")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              (_vm.$root.can("index", _vm.permissions_impuesto)
                              ? true
                              : false)
                                ? _c(
                                    "v-tab",
                                    { attrs: { href: "#impuesto-tab" } },
                                    [_vm._v("Impuesto")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("v-toolbar-title", [_vm._v("Viviendas")]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.vTabs == "listado-tab" &&
                  (_vm.$root.can("new", _vm.permissions_prototipo)
                    ? true
                    : false)
                    ? _c(
                        "v-btn",
                        {
                          attrs: { flat: "", color: "verdeFayal" },
                          on: { click: _vm.openAddHouseholdModal }
                        },
                        [
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v("add")
                          ]),
                          _vm._v("Crear vivienda\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.vTabs == "listado-tab"
                    ? _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.vTabs == "conceptos-tab" &&
                  (_vm.$root.can("new", _vm.permissions_concepto)
                    ? true
                    : false)
                    ? _c(
                        "v-btn",
                        {
                          attrs: { flat: "", color: "verdeFayal" },
                          on: { click: _vm.openAddConceptsModal }
                        },
                        [
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v("add")
                          ]),
                          _vm._v("Añadir Concepto\n        ")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.vTabs == "conceptos-tab"
                    ? _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.vTabs,
                    callback: function($$v) {
                      _vm.vTabs = $$v
                    },
                    expression: "vTabs"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "listado-tab" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c("households-index", {
                            attrs: {
                              search: _vm.search,
                              permissions: _vm.permissions_prototipo
                            },
                            on: {
                              "update:search": function($event) {
                                _vm.search = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "conceptos-tab" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c("concepts-index", {
                            attrs: {
                              search: _vm.search,
                              updateConceptos: _vm.showConceptsModal,
                              permissions: _vm.permissions_concepto,
                              permissions_rubro: _vm.permissions_rubro
                            },
                            on: {
                              "update:search": function($event) {
                                _vm.search = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "impuesto-tab" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          required: "",
                                          label: "Impuesto (%)",
                                          min: "0",
                                          max: "100",
                                          disabled: !_vm.$root.can(
                                            "update",
                                            _vm.permissions_impuesto
                                          )
                                        },
                                        model: {
                                          value: _vm.impuesto.valor,
                                          callback: function($$v) {
                                            _vm.$set(_vm.impuesto, "valor", $$v)
                                          },
                                          expression: "impuesto.valor"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    dark: "",
                                    color: "verdeFayal",
                                    flat: "",
                                    loading: _vm.vBtnSave.loading,
                                    disabled: !_vm.$root.can(
                                      "update",
                                      _vm.permissions_impuesto
                                    )
                                  },
                                  on: { click: _vm.updateImpuesto }
                                },
                                [_vm._v(" Guardar ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("create-households-modal", {
        attrs: { show: _vm.showHouseholdModal },
        on: {
          "update:show": function($event) {
            _vm.showHouseholdModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("create-concepts-modal", {
        attrs: {
          show: _vm.showConceptsModal,
          permissions: _vm.permissions_rubro
        },
        on: {
          "update:show": function($event) {
            _vm.showConceptsModal = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }