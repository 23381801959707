<template>
  <v-layout>
    <v-dialog
      :value="dialog"
      persistent
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn class="fyl-btnCreate" v-on:click="$emit('open')">Crear</v-btn>
      </template>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">{{ this.currentProyectoId ? "Editar" : "Crear" }} Propuesta</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="$emit('close'); $refs.form.resetValidation(); countManzanas = 1;">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-progress-linear v-if="loading" color="success" :indeterminate="true"></v-progress-linear>
        <v-form v-model="valid" ref="form">
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    label="Nombre de la propuesta"
                    v-model="proyecto.nombre"
                    color="#1ABA24"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Campo requerido']"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <searcher
                    :configuration="searchConfig"
                    :itemSelected.sync="selected"
                  />
                </v-flex>
                <v-flex xs12 sm6 md6 v-if="currentProyectoId">
                  <v-select
                    :items="status"
                    item-text="estado"
                    item-value="id"
                    label="Status"
                    v-model="proyecto.status"
                    color="#1ABA24"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Campo requerido']"
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm12 md12 center>
                  <v-textarea
                    color="#1ABA24"
                    label="Descripcion"
                    v-model="proyecto.descripcion"
                    counter="250"
                    :maxlength="max"
                    :readonly="readonly"
                  ></v-textarea>
                </v-flex>
                <v-flex md12>
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-center">Manzanas</th>
                        <th class="text-center">lotes regulares</th>
                        <th class="text-center">Lotes irregulares</th>
                        <th class="text-center">Lotes</th>
                        <th class="text-center">Superficie (M2)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">{{ proyecto.only_manzanas.length }}</td>
                        <td class="text-center">{{ lotes_regulares }}</td>
                        <td class="text-center">{{ lotes_irregulares }}</td>
                        <td class="text-center">{{ lotes_regulares + lotes_irregulares }}</td>
                        <td class="text-center">{{ proyecto.superficie = superficieLotes + superficieAreaVerde + superficieCalles }}</td>
                      </tr>
                    </tbody>
                  </table>
                </v-flex>
                <v-flex md12>
                  <template>
                    <v-tabs fixed-tabs centered slider-color="verdeFayal" v-model="active">
                      <v-tab>Manzanas</v-tab>
                      <v-tab-item>
                        <v-layout wrap>
                          <v-flex md1 offset-md-9>
                            <v-tooltip bottom>
                              <v-btn
                                slot="activator"
                                color="verdeFayal"
                                dark
                                @click="addManzana"
                                :disabled="readonly"
                              >
                                <v-icon>add</v-icon>
                              </v-btn>
                              <span>Añadir manzana</span>
                            </v-tooltip>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap>
                          <v-expansion-panel>
                            <template v-for="(manzana, index) in proyecto.only_manzanas">
                              <v-flex md12 row :key="index">
                                <v-flex md12>
                                  <v-expansion-panel-content>
                                    <template v-slot:header>
                                      <div>Manzana {{manzana.nombre}}</div>
                                    </template>
                                    <v-layout row>
                                      <v-flex md1 offset-md-9>
                                        <v-text-field
                                          type="number"
                                          min="0"
                                          single-line
                                          solo
                                          label="Lotes"
                                          @keyup.enter="addMultiplesLotes(index)"
                                          v-model="numberLotes"
                                          hint="Enter para crear..."
                                          :disabled="readonly"
                                        ></v-text-field>
                                      </v-flex>
                                      <v-flex md1>
                                        <v-tooltip bottom>
                                          <v-btn
                                            slot="activator"
                                            dark
                                            icon
                                            color="verdeFayal"
                                            @click="addLote(index, manzana.nombre)"
                                            :disabled="readonly"
                                          >
                                            <v-icon>add</v-icon>
                                          </v-btn>
                                          <span>Añadir lote</span>
                                        </v-tooltip>
                                      </v-flex>                                      
                                      <v-flex md1 v-if="(index > 0) && (!currentProyectoId)">
                                        <!-- <v-tooltip bottom> -->
                                          <v-btn @click="removeManzana(index)" flat icon color="error">
                                            <v-icon>delete_outline</v-icon>
                                          </v-btn>
                                          <!-- <span>Eliminar manzana</span>
                                        </v-tooltip> -->
                                      </v-flex>
                                    </v-layout>
                                    <v-card>
                                      <v-card-text>
                                        <template v-for="(lote,indexLote) in manzana.lotes">
                                          <v-flex md12 row :key="indexLote">
                                            <v-flex md1>
                                              <h3>Lote {{ lote.nombre = manzana.nombre + ( indexLote + 1 )}}</h3>
                                            </v-flex>
                                            <v-flex md3>
                                              <v-select
                                                label="Tipo de lote"
                                                :items="['Regular', 'Irregular']"
                                                v-model="lote.tipo"
                                                @change="countLotes(lote.tipo, indexLote, manzana.nombre)"
                                                :readonly="readonly"
                                                :rules="[(v) => !!v || 'Campo requerido']"
                                              ></v-select>
                                            </v-flex>
                                            <v-flex md4>
                                              <v-autocomplete
                                                label="Modelo"
                                                v-model="lote.modelo_id"
                                                :items="prototipos_vivienda"
                                                attach
                                                item-text="nombre"
                                                item-value="id"
                                                :readonly="readonly"
                                                :rules="[(v) => !!v || 'Campo requerido']"
                                              ></v-autocomplete>
                                            </v-flex>
                                            <v-flex md3>
                                              <v-text-field
                                                type="number"
                                                min="0"
                                                label="Superficie en metro cuadrados"
                                                v-model="lote.superficie"
                                                :readonly="readonly"
                                                :rules="[(v) => !!v || 'Campo requerido']"
                                              ></v-text-field>
                                            </v-flex>
                                            <v-flex md1>
                                              <v-layout>
                                                <v-tooltip bottom>
                                                  <v-btn
                                                    slot="activator"
                                                    icon
                                                    color="verdeFayal"
                                                    flat
                                                    :disabled="readonly"
                                                    @click="duplicateItem(index, lote)"
                                                  >
                                                    <v-icon>content_copy</v-icon>
                                                  </v-btn>
                                                  <span>Duplicar lote</span>
                                                </v-tooltip>

                                                <v-tooltip bottom>
                                                  <v-btn
                                                    slot="activator"
                                                    icon
                                                    color="error"
                                                    flat
                                                    :disabled="readonly"
                                                    @click="areYouShure(index, indexLote, lote.id, lote.tipo)"
                                                  >
                                                    <v-icon>remove</v-icon>
                                                  </v-btn>
                                                  <span>Eliminar lote</span>
                                                </v-tooltip>
                                              </v-layout>
                                            </v-flex>
                                          </v-flex>
                                        </template>
                                      </v-card-text>
                                    </v-card>
                                  </v-expansion-panel-content>
                                </v-flex>
                              </v-flex>
                            </template>
                          </v-expansion-panel>
                        </v-layout>
                      </v-tab-item>
                      <v-tab>Áreas verdes</v-tab>
                      <v-tab-item>
                        <v-layout wrap>
                          <v-flex md1 offset-md-11>
                            <v-btn
                              color="verdeFayal"
                              dark
                              @click="addAreaVerde"
                              :disabled="readonly"
                            >
                              <v-icon>add</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <v-container
                          grid-list-md
                          v-for="(area_verde, index) in proyecto.areas_verdes"
                          :key="index"
                        >
                          <v-layout wrap>
                            <v-flex md2>
                              <h3>{{ area_verde.nombre = 'Área verde ' + ( index + 1 ) }}</h3>
                            </v-flex>
                            <v-flex md6>
                              <v-text-field
                                v-model="area_verde.superficie"
                                color="verdeFayal"
                                label="Superficie en metros cuadrados"
                                type="number"
                                min="0"
                                :readonly="readonly"
                                :rules="[(v) => !!v || 'Campo requerido']"
                              ></v-text-field>
                            </v-flex>
                            <v-flex md1>
                              <v-btn
                                icon
                                color="error"
                                :disabled="readonly"
                                @click="proyecto.areas_verdes.splice(index, 1); deleteAreaVerde(area_verde.id);"
                              >
                                <v-icon>remove</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-tab-item>
                      <v-tab>Calles</v-tab>
                      <v-tab-item>
                        <v-layout wrap>
                          <v-flex md1 offset-md-11>
                            <v-btn color="verdeFayal" dark @click="addCalle" :disabled="readonly">
                              <v-icon>add</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <v-container
                          grid-list-md
                          v-for="(calle, index) in proyecto.calles"
                          :key="index"
                        >
                          <v-layout wrap>
                            <v-flex md2>
                              <h3>Calle {{index + 1}}</h3>
                            </v-flex>
                            <v-flex md4>
                              <v-text-field
                                v-model="calle.nombre"
                                color="verdeFayal"
                                label="Nombre de la calle"
                                :readonly="readonly"
                                :rules="[(v) => !!v || 'Campo requerido']"
                              ></v-text-field>
                            </v-flex>
                            <v-flex md4>
                              <v-text-field
                                v-model="calle.superficie"
                                color="verdeFayal"
                                label="Superficie en metros cuadrados"
                                type="number"
                                min="0"
                                :readonly="readonly"
                                :rules="[(v) => !!v || 'Campo requerido']"
                              ></v-text-field>
                            </v-flex>
                            <v-flex md1>
                              <v-btn
                                icon
                                color="error"
                                :disabled="readonly"
                                @click="proyecto.calles.splice(index, 1); deleteCalle(calle.id)"
                              >
                                <v-icon>remove</v-icon>
                              </v-btn>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-tab-item>
                    </v-tabs>
                  </template>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="projectCreate()"
              :disabled="$store.state.saveDisabled || readonly"
              dark 
              color="verdeFayal"
            >Guardar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { errorHandling } from "../../lib/utilities";
export default {
  props: ["proyectos", "dialog", 'proyectoId', "concretados", "readonly"],
  data() {
    return {
      active: null,
      placeholder: "Subiendo archivo",
      valid: false,
      concretadosProps: this.concretados,
      max: 250,
      loading: false,
      proyecto: {
        only_manzanas: [],
        areas_verdes: [],
        calles: [],
        lotes_regulares: 0,
        lotes_irregulares: 0,
        superficie: 0
      },
      searchConfig: {
        url: "/prospectos/concretados",
        paginatedContainer: 'data',
        noDataText: "No se encontraron resultados.",
        label: "Nombre del proyecto",
        itemText: "nombre_proyecto",
        itemValue: "id",
        descriptionLimit: 60,
        disabled: false,
        useHeaders: true,
      },
      selected: {},
      currentProyectoId: null,      
      numberLotes: 0,
      countManzanas: 1,
      prototipos_vivienda: [],
      items: [],
      lotes: []
    };
  },

  mounted() {
    axios.get("/get_prototipos_viviendas").then(response => {
      this.prototipos_vivienda = response.data;
    });
    axios.get("/proyectos/estados").then(response => {
      this.items = response.data;
    });
  },

  methods: {
    addManzana: function() {
      let number = this.countManzanas++;
      var baseChar = "A".charCodeAt(0),
        nombre = "";

      do {
        number -= 1;
        nombre = String.fromCharCode(baseChar + (number % 26)) + nombre;
        number = (number / 26) >> 0;
      } while (number > 0);

      this.proyecto.only_manzanas.push({ nombre: nombre, superficie: 0, lotes: [] });
    },

    addMultiplesLotes: function(index) {
      for (let i = 0; i < this.numberLotes; i++) {
        this.proyecto.only_manzanas[index].lotes.push({
          superficie: 0,
          tipo: "",
          modelo: ""
        });
      }
      this.numberLotes = 0;
    },

    addLote: function(index, nombre) {
      this.proyecto.only_manzanas[index].lotes.push({
        superficie: 0,
        tipo: "",
        modelo: ""
      });
    },

    countLotes: function(tipo, index, manzana) {
      if (
        this.lotes.find(
          element => element.index == index && element.manzana == manzana
        )
      ) {
        if (tipo == "Regular")
          this.proyecto.lotes_regulares++, this.proyecto.lotes_irregulares--;
        if (tipo == "Irregular")
          this.proyecto.lotes_irregulares++, this.proyecto.lotes_regulares--;
      } else {
        this.lotes.push({ manzana: manzana, index: index });

        if (tipo == "Regular") this.proyecto.lotes_regulares++;
        if (tipo == "Irregular") this.proyecto.lotes_irregulares++;
      }
    },

    deleteCalle: function(id) {
      if (id) {
        axios.delete(`/delete_calle_lotificacion/${id}`).then(response => {
          alertify.success(response.data.message_text);
        });
        axios
          .patch(route("proyectos.update", this.proyecto.id), this.proyecto)
          .then(response => {});
      }
    },

    deleteAreaVerde: function(id) {
      if (id) {
        axios.delete(`/delete_area_verde/${id}`).then(response => {
          alertify.success(response.data.message_text);
        });
        axios
          .patch(route("proyectos.update", this.proyecto.id), this.proyecto)
          .then(response => {});
      }
    },

    areYouShure: function(indexManzana, indexLote, lote, tipo) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) 
        {
          this.proyecto.only_manzanas[indexManzana].lotes.splice(indexLote, 1);
          this.deleteLoteManzana(lote, tipo);
        }
      })
    },

    duplicateItem: function(indexManzana, lote) {
      this.proyecto.only_manzanas[indexManzana].lotes.push({
        superficie: lote.superficie,
        tipo: lote.tipo,
        modelo: lote.modelo,
        modelo_id: lote.modelo_id
      });
      this.countLotes(lote.tipo);
    },
    deleteLoteManzana: function(id, tipo) {
      if (tipo == "Regular") this.proyecto.lotes_regulares--;
      if (tipo == "Irregular") this.proyecto.lotes_irregulares--;

      if (id) {
        axios.delete(`/delete_lote/${id}`).then(response => {
          alertify.success(response.data.message_text);
        });
        axios
          .patch(route("proyectos.update", this.proyecto.id), this.proyecto)
          .then(response => {});
      }
    },

    addAreaVerde: function() {
      this.proyecto.areas_verdes.push({ superficie: 0 });
    },

    addCalle: function() {
      this.proyecto.calles.push({ nombre: "", superficie: 0 });
    },

    projectCreate() {
      if (this.$refs.form.validate()) {
        this.proyecto.prospecto_id = this.selected.id;
        if (this.proyecto.id) {
          this.proyecto.only_manzanas.forEach(manzana => {
            manzana.lotes.forEach(lote => {
              lote.modelo = this.prototipos_vivienda.find(
                element => element.id == lote.modelo_id
              ).nombre;
            });
          });
          
          axios
            .patch(route("proyectos.update", this.proyecto.id), {
              ...this.proyecto,
              lotes_regulares: this.lotes_regulares,
              lotes_irregulares: this.lotes_irregulares
            })
            .then(response => {
              if (response.data.message_text)
                alertify.success(response.data.message_text);
                this.$root.cleanStore();
                this.$emit("close");
            })
            .catch(error => errorHandling(error))
            .finally(() => (window.location.href = "/proyectos"));
        } else {
          this.proyecto.only_manzanas.forEach(manzana => {
            manzana.lotes.forEach(lote => {
              lote.modelo = this.prototipos_vivienda.find(
                element => element.id == lote.modelo_id
              ).nombre;
            });
          });
          axios.post("/proyectos",{
              ...this.proyecto,
              lotes_regulares: this.lotes_regulares,
              lotes_irregulares: this.lotes_irregulares
            })
            .then(response => {
              this.proyectos.push(response.data.data);
              if (response.data.message_text) {
                alertify.success(response.data.message_text);
                this.$root.cleanStore();
                this.$emit("close");
              }
              // return window.location.href = response.data.redirect
            })
            .catch(error => errorHandling(error))
            .finally(() => (window.location.href = "/proyectos"));
        }
      } else {
        alertify.error("Hay campos requeridos aun sin llenar");
      }
    },

    removeManzana(indexManzana) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) 
        {
          this.proyecto.only_manzanas.splice(indexManzana, 1);
          var count = 1;
          this.countManzanas--;
          this.proyecto.only_manzanas.forEach(manzana => {
            let number = count++;
            var baseChar = "A".charCodeAt(0),
              nombre = "";

            do {
              number -= 1;
              nombre = String.fromCharCode(baseChar + (number % 26)) + nombre;
              number = (number / 26) >> 0;
            } while (number > 0);
              manzana.nombre = nombre;
          });
        }
      })
    }
  },

  computed: {
    status: function() {
      return this.items;
    },

    FileUpload() {
      return this.$store.state.urlFileUpload;
    },

    superficieLotes: function() {
      let suma = 0;
      this.proyecto.only_manzanas.forEach(element => {
        element.lotes.forEach(e => {
          suma += Number(e.superficie);
        });
      });

      return suma;
    },

    superficieAreaVerde: function() {
      let suma = 0;
      this.proyecto.areas_verdes.forEach(element => {
        suma += Number(element.superficie);
      });

      return suma;
    },

    superficieCalles: function() {
      let suma = 0;
      this.proyecto.calles.forEach(element => {
        suma += Number(element.superficie);
      });

      return suma;
    },

    estado: function() {
      if (this.proyecto.estado) {
        return this.proyecto.estado.information;
      }
    },

    lotes_regulares: function() {
      let suma = 0;
      this.proyecto.only_manzanas.forEach(element => {
        element.lotes.forEach(e => {
          if(e.tipo == "Regular") {
            suma ++;
          }
        });
      });

      return suma;
    },

    lotes_irregulares: function() {
      let suma = 0;
      this.proyecto.only_manzanas.forEach(element => {
        element.lotes.forEach(e => {
          if(e.tipo == "Irregular") {
            suma ++;
          }
        });
      });

      return suma;
    },
  },

  watch: {
    estado: function() {
      if (this.estado) {
        this.proyecto.status = this.estado.id;
      }
    },
    proyectoId: function(value) {
      this.currentProyectoId = value;
      this.loading = true;
      axios.get(route("proyectos.edit", this.proyectoId))
      .then(response => {
        this.proyecto = response.data;
        this.selected = response.data.prospecto
        this.countManzanas = response.data.only_manzanas.length + 1;
      })
      .finally(() => this.loading = false)
    },
    currentProyectoId: function(value) {
      if(!value) {
        this.proyecto = {
          only_manzanas: [],
          areas_verdes: [],
          calles: [],
          lotes_regulares: 0,
          lotes_irregulares: 0,
          superficie: 0
        }

        this.selected = {}
      }
    },
    FileUpload() {
      this.proyecto.planos = this.FileUpload;
    }
  }
};
</script>

<style>
.fyl-btnCreate {
  background: #1aba24 !important;
  color: #fff !important;
}
.v-input--is-label-active label {
  color: #1aba24 !important;
}
.fyl-btnClose {
  color: #ff5252 !important;
}
.fyl-btnSave {
  color: #fff !important;
  background: #1aba24;
  border-radius: 6px;
}
</style>