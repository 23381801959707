<template>
  <v-layout>
    <v-container>
      <v-flex xs12>
        <v-data-table 
          flat 
          :headers="vHeaders" 
          disable-initial-sort 
          :items="conceptos.data" 
          hide-actions 
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.nombre }}</td>
            <td>
              <span 
                @click="openRubrosModal(props.item)" 
                class="badge badge-primary badge-lotes"
                style="cursor: pointer;"
              >
                {{ props.item.rubros.length }}
              </span>
            </td>
            <td>
              <v-layout>
                <v-tooltip bottom>
                  <v-btn icon flat dark small slot="activator" color="info" @click="openEditConceptoModal(props.item.id)" :disabled="!$root.can('update', permissions)">
                    <v-icon color="verdeFayal">edit</v-icon>
                  </v-btn>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn icon flat dark small slot="activator" color="error" @click="deleteConcept(props.item.id)" :disabled="!$root.can('remove', permissions)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                  <span>Eliminar</span>
                </v-tooltip>  
              </v-layout>
            </td>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <pagination :limit="vPaginator.limit" :data="conceptos" @pagination-change-page="getConceptos"></pagination>
        </v-card-actions>
      </v-flex>
    </v-container>

    <!-- Modals -->
    <edit-concepts-modal type="obra" :show.sync="showEditConceptModal" :conceptId.sync="editConceptId" :permissions="permissions_rubro"></edit-concepts-modal>
    <rubros-per-conceptos-modal :show.sync="showSeeRubrosPerConceptos" :concepto.sync="currentConcepto"></rubros-per-conceptos-modal>
  </v-layout>
</template>

<script>
import swal from "sweetalert";

export default {
  data: () => ({
    conceptos: {
      current_page: 1,
      data: []
    },
    vPaginator: {
      limit: 10
    },
    vHeaders: [
      {
        text: 'Nombre',
        value: 'nombre'
      },
      {
        text: 'Cantidad de rubros asociados',
        value: 'rubros'
      },
      {
        text: 'Acciones',
        value: 'acciones',
        sortable: false
      }
    ],
    localSearch: null,
    currentConcepto: {},
    showSeeRubrosPerConceptos: false,
    showEditConceptModal: false,
    editConceptId: null
  }),
  props: {
    search: String,
    updateConceptos: Boolean,
    permissions: Object,
    permissions_rubro: Object,
    currentPath: String
  },
  watch: {
    search: function (value) {
      this.localSearch = value;
      this.getConceptos();
    },
    showEditConceptModal: function (value) {
      if (!value) {
        this.getConceptos(this.conceptos.current_page);
      }
    },
    updateConceptos: function (value){
      if (!value) {
        this.getConceptos(this.conceptos.current_page);
      }
    },
  },
  methods: {
    getConceptos: function (page = 1) {
      axios.get(`/conceptos?page=${page}&query=${this.localSearch}&type=${(this.currentPath != '/trabajos_externos' ? 'obra' : 'trabajos_externos')}&paginated=1&`)
        .then(response => {
          this.conceptos = response.data;
        })
        .catch(error => errorHandling(error));
    },
    deleteConcept: function (id) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('conceptos.destroy', id))
            .then(response => {
              this.getConceptos();
              alertify.success(response.data.message_text);
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      })
    },
    openEditConceptoModal: function (id) {
      this.showEditConceptModal = true;
      this.editConceptId = id;
    },
    openRubrosModal: function(concepto) {
      this.showSeeRubrosPerConceptos = true; 
      this.currentConcepto = concepto;
    }
  },
  mounted() {
    this.getConceptos(this.conceptos.current_page);
  }
}
</script>
