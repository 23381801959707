<template>
  <v-container>
    <v-layout row wrap>
      <v-flex :xs12="!showFiltersForm" :xs9="showFiltersForm">
        <v-card>        
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Fraccionamientos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <v-btn dark color="verdeFayal" outline @click="showFiltersForm = !showFiltersForm">
              <v-icon dark>filter_alt</v-icon>
            </v-btn>
            <v-btn dark color="verdeFayal" outline  @click="exportToExcel">
              <v-icon dark>import_export</v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table :headers="headers" disable-initial-sort :items="fraccionamientos.data" hide-actions>
            <template v-slot:items="concretadosProps">
              <td class="text-xs-left">{{ concretadosProps.item.prospecto.nombre_proyecto }}</td>
              <td class="text-xs-left">{{ concretadosProps.item.prospecto.direccion }}</td>
              <td class="text-xs-left fyl-fraccionamientos-td">{{ concretadosProps.item.prospecto.descripcion }}</td>
              <td class="text-xs-left fyl-fraccionamientos-td">{{ concretadosProps.item.estado.information.estado || 'No disponible' }}</td>
              <td>
                <div class="text-xs-center">
                  <v-layout justify-space-around>
                    <v-btn icon flat color="black" @click="expandOptions(concretadosProps.index)">
                      <v-icon dark>more_horiz</v-icon>
                    </v-btn>
                    <v-expand-x-transition>
                      <div v-show="concretadosProps.item.expand">
                        <v-tooltip bottom>
                          <v-btn :disabled="!$root.can('files', permissions)" flat icon slot="activator" color="verdeFayal" @click="elementId = concretadosProps.item.prospecto.id; dialogFile = true;">
                            <v-icon>folder</v-icon>
                          </v-btn>
                          <span>Archivos</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <v-btn :disabled="(!$root.can('status', permissions))" flat icon slot="activator" color="verdeFayal" @click="dad_id = concretadosProps.item.id; itemSelected = concretadosProps.item; dialogStatus = true;">
                            <v-icon>low_priority</v-icon>
                          </v-btn>
                          <span>Estados</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <v-btn :disabled="!$root.can('lotification', permissions)" flat icon slot="activator" color="verdeFayal" :href="`/proyectos/lotificaciones/${concretadosProps.item.prospecto.id}`">
                            <v-icon>launch</v-icon>
                          </v-btn>
                          <span>Propuestas de Lotificación</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <v-btn flat icon slot="activator" color="verdeFayal" :href="`/viviendas/fraccionamiento/${concretadosProps.item.id}`">
                            <v-icon>house</v-icon>
                          </v-btn>
                          <span>Viviendas</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <v-btn flat icon slot="activator" color="verdeFayal" :href="`/pruebas_laboratorio?projectId=${concretadosProps.item.id}`">
                            <v-icon>fas fa-flask</v-icon>
                          </v-btn>
                          <span>Pruebas de laboratorio</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <v-btn flat icon slot="activator" color="verdeFayal" :href="`/tramites_dependencias?fraccionamiento_id=${concretadosProps.item.id}`">
                            <v-icon>inventory</v-icon>
                          </v-btn>
                          <span>Trámites de dependencias</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <v-btn :disabled="!$root.can('obra', permissions)" flat icon slot="activator" color="verdeFayal" :href="`/etapas/${concretadosProps.item.id}/create?tipo=obra`">
                            <v-icon>house</v-icon>
                          </v-btn>
                          <span>Etapas de Obra</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <v-btn :disabled="!$root.can('urbanizacion', permissions)" flat icon slot="activator" color="verdeFayal" :href="`/etapas/${concretadosProps.item.id}/create?tipo=urbanizacion`">
                            <v-icon>location_city</v-icon>
                          </v-btn>
                          <span>Etapas de Urbanización</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <v-btn :disabled="!$root.can('advances', permissions)" flat icon slot="activator" color="verdeFayal" :href="`/etapas/fraccionamiento/${concretadosProps.item.id}`">
                            <v-icon>date_range</v-icon>
                          </v-btn>
                          <span>Avances</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <v-btn flat icon slot="activator" color="verdeFayal" :href="`/fraccionamientos/${concretadosProps.item.id}/ventas`">
                            <v-icon>point_of_sale</v-icon>
                          </v-btn>
                          <span>Venta de lotes</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <v-btn
                            :href="`/fraccionamientos/${concretadosProps.item.id}/promocion`"
                            color="verdeFayal"
                            slot="activator"
                            icon
                            flat
                          >
                            <v-icon>local_offer</v-icon>
                          </v-btn>
                          <span>Lotes en promoción</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <v-btn
                            :href="`/fraccionamientos/${concretadosProps.item.id}/viviendas`"
                            color="verdeFayal"
                            slot="activator"
                            icon
                            flat
                          >
                            <v-icon>villa</v-icon>
                          </v-btn>
                          <span>Viviendas</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <v-btn flat icon slot="activator" color="verdeFayal" :href="`/fraccionamientos/${concretadosProps.item.id}/reporte`">
                            <v-icon>backup_table</v-icon>
                          </v-btn>
                          <span>Reporte</span>
                        </v-tooltip>
                      </div>
                    </v-expand-x-transition>
                  </v-layout>
                </div>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="fraccionamientos" @pagination-change-page="getFraccionamientos"></pagination>
          </v-card-actions>        
        </v-card>
      </v-flex>

      <v-flex xs3 v-if="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat>
            <v-toolbar-title>Filtros</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="showFiltersForm = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex>
                  <v-select
                    color="verdeFayal"
                    :items="estados"
                    item-value="id"
                    item-text="estado"
                    label="Status actual"
                    v-model="filters.filterEstado"
                    clearable
                  ></v-select>
                </v-flex>                                                
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn dark color="verdeFayal" small flat outline @click="limpiar">Limpiar</v-btn>
            <v-spacer></v-spacer>
            <v-btn dark color="verdeFayal" small @click="filterMaquinaria">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <!-- Modal -->
      <upload-files-modal @close="dialogFile = false" element_type="App\Prospecto" :element_id="elementId" :dialog="dialogFile" :edit_disabled="true"></upload-files-modal>
      <fraccionamientos-show-status
        @close="dialogStatus = false" 
        :dialog="dialogStatus" 
        :estado="itemSelected ? itemSelected.estado : null" 
        :estados="itemSelected ? itemSelected.all_estados : null"
        :dad_id="dad_id"
      ></fraccionamientos-show-status>
      <etapas-modal
        @close="dialogEtapasObras = false"
        :propuestaLotificacion="itemSelected"
        dad="Obra"
        :dialog="dialogEtapasObras"
      />
      <etapas-modal
        @close="dialogEtapasUrbanizacion = false"
        :propuestaLotificacion="itemSelected"
        dad="Urbanización"
        :dialog="dialogEtapasUrbanizacion"
      />
      <!-- <manage-materials-to-fraccionamientos-modal
        v-bind:show.sync="showMaterialsToValuesModal"
        v-bind:item-id.sync="currentItemId"
        v-bind:modelType.sync="currentModel"
        v-bind:name.sync="currentItemName"
        v-bind:fraccionamiento.sync="currentItem"
      ></manage-materials-to-fraccionamientos-modal> -->    
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    props: ['permissions'],
    data(){
      return{
        fraccionamientos: {
          data: []
        },
        vPaginator: {
          limit: 10
        },
        search: null,
        dialogFile: false,
        dialogStatus: false,
        dialogEtapasObras: false,
        dialogEtapasUrbanizacion: false,
        showMaterialsToValuesModal: false,
        currentModel: 'App\\SalidasAlmacen',
        currentItem: null,
        currentItemId: null,
        currentItemName: null,
        dad_id: '',
        elementId: '',
        itemSelected: '',
        search: '',
        headers:[
          { text: 'Nombre del proyecto', value: 'nombre_proyecto' },
          { text: 'Direccion', value: 'direccion' },
          { text: 'Descripcion', value: 'descripcion' },
          { text: 'Estado', value: 'descripcion' },
          { text: 'Acciones', value: 'comprado', sortable: false }
        ],
        pagination: {
          sortBy: 'created_at',
          descending: true
        },

        estados: [],
        showFiltersForm: false,       
        filters: {
          filterEstado: null,                
        },
      }
    },
    watch: {
      search: function (value) {
        this.getFraccionamientos();
      },
    },
    methods:{
      expandOptions: function(index) {
        this.fraccionamientos.data[index].expand = !this.fraccionamientos.data[index].expand
      },
      openAddMaterialsValuesModal: function(currentItemId, currentItemName, currentItem) {
        if(currentItem.prospecto.propuesta_lotificacion.length < 1) return alertify.error("No tiene una propuesta de lotificación aceptada.");
        this.showMaterialsToValuesModal = true;
        this.currentItemId = currentItemId;
        this.currentItem = currentItem;
      },
      getFraccionamientos: function (page = 1) {
        axios.get(`/fraccionamientos?page=${page}&query=${this.search}&paginated=1&relationships[]=estado`)
          .then(response => {
            this.fraccionamientos = response.data;
          })
          .catch(error => errorHandling(error));
      },

      getEstados: function(){
        axios
        .get('/fraccionamientos/estados')
        .then(response => {
          this.estados = response.data;
        })
        .catch(error => errorHandling(error));
      },
      filterMaquinaria: function() {
        axios.post('/fraccionamientos/filterFraccionamiento',this.filters)
        .then(response => this.fraccionamientos = response.data)
        .catch(error => errorHandling(error));
      },
      limpiar: function () {        
          this.filters.filterEstado = null;          
          this.getFraccionamientos();
      },
      exportToExcel: function() {
        axios.post('/fraccionamientos/filterFraccionamiento', {
          ...this.filters,
          to_excel: true
        }, {responseType: 'arraybuffer'})
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'fraccionamientos.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(error => errorHandling(error));
      },
    },
    created(){
      this.getEstados();
      this.getFraccionamientos();
    }
  }
</script>

<style>
  .comprado{
    position: relative;
    top: 5px;
  }
  .fyl-fraccionamientos-td{
    max-width: 166px;
  }
</style>