<template>
  <v-layout row justify-center>
    <v-card>
      <v-flex xs12>
        <div class>
          <a href="../facturas">
            <v-btn color="#1ABA24" class="white--text">Ir a facturas</v-btn>
          </a>
        </div>
      </v-flex>
      <v-card-title>
        <span class="headline">Ver factura</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md6>
              <v-text-field color="#1ABA24" label="Proveedor" v-model="factura.proveedor" disabled></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                disabled
                color="#1ABA24"
                label="Numero de factura"
                v-model="factura.numero_factura"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                disabled
                color="#1ABA24"
                label="Monto"
                type="date"
                v-model="factura.monto"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <v-text-field
                disabled
                color="#1ABA24"
                label="Fecha de emision"
                v-model="factura.fecha_emision"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <v-text-field
                disabled
                color="#1ABA24"
                label="Fecha de vigencia"
                v-model="factura.fecha_vigencia"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <v-text-field
                disabled
                color="#1ABA24"
                label="Fecha de pago"
                v-model="factura.pagada_at"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12>
              <v-textarea disabled color="#1ABA24" label="Comentarios" v-model="factura.comentario"></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
export default {
  props: ["factura"]
};
</script>