var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-toolbar", {
        attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" }
      }),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "pb-2": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs10: "", "offset-xs1": "" } },
            [
              _c(
                "v-card",
                { staticClass: "card--flex-toolbar" },
                [
                  _c("v-card-text", [
                    _c(
                      "div",
                      { staticClass: "text-xs-center" },
                      [
                        _c(
                          "v-container",
                          { attrs: { "grid-list-md": "" } },
                          [
                            _c(
                              "v-layout",
                              { attrs: { column: "" } },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c(
                                      "v-avatar",
                                      {
                                        attrs: {
                                          tile: false,
                                          size: 100,
                                          color: "grey lighten-4"
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/img/avatar.svg",
                                            alt: "avatar"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("h3", { staticClass: "headline mb-0" }, [
                                      _vm._v(_vm._s(_vm.user.name))
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "badge badge-secondary",
                                        staticStyle: {
                                          "background-color": "#ff5617"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(_vm.user.roles[0].name) +
                                            "\n                                    "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: { label: "Nombre completo" },
                                      model: {
                                        value: _vm.user.name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.user, "name", $$v)
                                        },
                                        expression: "user.name"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { xs12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "emaill",
                                        label: "Correo electrónico"
                                      },
                                      model: {
                                        value: _vm.user.email,
                                        callback: function($$v) {
                                          _vm.$set(_vm.user, "email", $$v)
                                        },
                                        expression: "user.email"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { md12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "password",
                                        required: "",
                                        label: "Contraseña"
                                      },
                                      model: {
                                        value: _vm.user.contraseña,
                                        callback: function($$v) {
                                          _vm.$set(_vm.user, "contraseña", $$v)
                                        },
                                        expression: "user.contraseña"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-flex",
                                  { attrs: { md12: "" } },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "password",
                                        required: "",
                                        label: "Confirmar contraseña"
                                      },
                                      model: {
                                        value: _vm.user.confirmar_contraseña,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.user,
                                            "confirmar_contraseña",
                                            $$v
                                          )
                                        },
                                        expression: "user.confirmar_contraseña"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            dark: "",
                            outline: "",
                            color: "verdeFayal",
                            flat: "",
                            loading: _vm.vBtnSave.loading
                          },
                          on: { click: _vm.updateUser }
                        },
                        [_vm._v(" Guardar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.vSnackBar.text) + "\n        "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }