var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "550px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v(
                      "Añadir precios de venta al lote " +
                        _vm._s(_vm.lote.nombre)
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: _vm.isEditing ? false : true,
                                xs6: _vm.isEditing ? true : false
                              }
                            },
                            [
                              _c(
                                "table",
                                {
                                  staticClass: "table table-striped table-hover"
                                },
                                [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [_vm._v("Tipo")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Precio")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Observaciones")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Acciones")])
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(_vm.prices, function(item, index) {
                                      return _c("tr", { key: index }, [
                                        _c("td", [_vm._v(_vm._s(item.tipo))]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("currency")(item.precio)
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(item.observaciones))
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-layout",
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      flat: "",
                                                      icon: "",
                                                      color: "verdeFayal"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editingPrice(
                                                          index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("edit")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _vm.isEditing
                            ? _c(
                                "v-flex",
                                { attrs: { xs6: "" } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.currentPrice.tipo) +
                                      "\n                "
                                  ),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("input-currency-mask", {
                                        attrs: { label: "Precio" },
                                        model: {
                                          value: _vm.currentPrice.precio,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.currentPrice,
                                              "precio",
                                              $$v
                                            )
                                          },
                                          expression: "currentPrice.precio"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md12: "" } },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          outline: "",
                                          name: "input-7-4",
                                          label: "Observaciones"
                                        },
                                        model: {
                                          value: _vm.currentPrice.observaciones,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.currentPrice,
                                              "observaciones",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "currentPrice.observaciones"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        outline: "",
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: { click: _vm.storePrices }
                    },
                    [_vm._v(" Guardar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }