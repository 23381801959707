var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-text-field",
        _vm._b(
          {
            attrs: {
              label: _vm.label,
              placeholder: _vm.placeholder,
              readonly: _vm.readonly,
              disabled: _vm.disabled,
              outlined: _vm.outlined,
              dense: _vm.dense,
              "hide-details": _vm.hideDetails,
              error: _vm.error,
              "error-messages": _vm.errorMessages,
              rules: _vm.rules,
              clearable: _vm.clearable,
              backgroundColor: _vm.backgroundColor,
              prefix: _vm.options.prefix,
              suffix: _vm.options.suffix
            },
            on: { keypress: _vm.keyPress, blur: _vm.onBlur },
            model: {
              value: _vm.cmpValue,
              callback: function($$v) {
                _vm.cmpValue = $$v
              },
              expression: "cmpValue"
            }
          },
          "v-text-field",
          _vm.properties,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }