<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="700px">
      <template v-slot:activator="{ on }">
        <v-btn class="fyl-btnCreate" v-on:click="$emit('open')">Crear</v-btn>
      </template>
      <v-card>
        <form action v-on:submit.prevent.once="createVivienda()">
          <v-card-title>
            <span class="headline">Subir un prototipo de vivienda</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm4 md4>
                  <v-text-field color="#1ABA24" label="Nombre" v-model="vivienda.nombre"></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md4>
                    <v-select color="#1ABA24"
                      :items="items"
                      label="Tipo de vivienda"
                      v-model="vivienda.tipo"
                    ></v-select>
                </v-flex>
                <v-flex xs12 sm4 md4>
                  <v-text-field color="#1ABA24"
                    label="Fecha"
                    type="date"
                    v-model="vivienda.fecha"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea color="#1ABA24" label="Descripción" v-model="vivienda.descripcion" counter="250" :maxlength="max"></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="fyl-btnClose" flat @click="$emit('close')">Cerrar</v-btn>
            <v-btn class="fyl-btnSave" type="submit" flat>Guardar</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
  // import { errorHandling } from '../../lib/utilities';
  export default {
    props:['viviendas', 'vivienda', 'dialog'],
    data(){
      return{
        items:['existente', 'irregular'],
        max:250
      }
    },
    methods:{
      createVivienda() {
        if (this.vivienda.id) {
          axios
            .patch(route("prototipos_vivienda.update", this.vivienda.id), this.vivienda)
            .then(response => {
              if (response.data.message_text)
                alertify.success(response.data.message_text);
                this.$emit("close");
            })
            .catch(error => errorHandling(error));
        } else {
          axios
            .post("/prototipos_vivienda", this.vivienda)
            .then(response => {
              this.viviendas.push(response.data.data);
              if (response.data.message_text)
                alertify.success(response.data.message_text);
              this.$emit("close");
            })
            .catch(error => errorHandling(error));
        }
      }
    }
  };
</script>

<style>
  .fyl-btnCreate{
    background: #1ABA24 !important;
    color: #FFF !important;
  }
  .v-input--is-label-active label {
  color: #1ABA24 !important;
  }
  .fyl-btnClose{
    color: #ff5252 !important;
  }
  .fyl-btnSave{
    color: #FFF !important;
    background: #1ABA24;
    border-radius: 6px;
  }
</style>