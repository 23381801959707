<template>
  <v-layout row justify-center>
    <v-card>
      <v-flex xs12>
          <div class="">
            <a href="../tramites_dependencias">
              <v-btn color="#1ABA24" class="white--text">Ir a tramites</v-btn>
            </a>
          </div>
        </v-flex>
      <v-card-title>
        <span class="headline">Ver tramite</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md6>
              <v-text-field
                color="#1ABA24"
                label="Nombre del Proyecto"
                v-model="tramite.nombre_tramite"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                color="#1ABA24"
                label="Descripción"
                v-model="tramite.descripcion"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field
                color="#1ABA24"
                label="Proyecto al que pertenece"
                v-model="tramite.proyecto_perteneciente"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <v-text-field
                color="#1ABA24"
                label="Fecha de resolución aproximada"
                v-model="tramite.fecha_resolucion"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <v-text-field
                color="#1ABA24"
                label="Fecha de resolución aproximada"
                v-model="tramite.estado"
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex md12>
              <a :href="tramite.planos" target="_blank">
                <v-text-field color="#1ABA24" label="Ver documento" disabled></v-text-field>
              </a>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-layout>
</template>

<script>
export default {
  props: ["tramite"],
  data() {
    return {
      tramitesProps: this.tramite
    };
  }
};
</script>
