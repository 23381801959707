<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="850px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Nuevo mantenimiento</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex md6>
                <v-text-field v-model="mantenimiento.nombre" required label="Nombre del mantenimiento"></v-text-field>
              </v-flex>
              <v-flex md6>
                <v-text-field v-model="mantenimiento.periodo" type="number" min="0" required label="Periodo (Días)"></v-text-field>
              </v-flex>
              <v-flex md6>
                <v-text-field v-model="mantenimiento.duracion" type="number" min="0" required label="Duración (horas)"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="storeMantenimiento" :loading="vBtnSave.loading" dark color="verdeFayal" flat> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>  
</template>

<script>
  import { errorHandling, laravelRoute } from '../../../lib/utilities';

  export default {
    data(){
      return{
        localShow: false,
        keepStoring: true,
        createMantenimiento: false,
        vBtnSave: {
          loading: false
        },
        vSnackBar: {
          visible: false,
          text: null
        },
        mantenimiento: 
          {
            nombre: null,
            periodo: null,
            duracion: null,
          }
      }
    },
    props: {
      show: Boolean
    },
    watch: {
      show: function(value){
        this.localShow = value
      },
      localShow: function (value) {
        this.$emit('update:show', value);
      }
    },
    methods:{
      storeMantenimiento: function(){
        this.vBtnSave.loading = true;
        axios.post(laravelRoute('catalogo_mantenimientos.store'), {
          nombre: this.mantenimiento.nombre,
          periodo: this.mantenimiento.periodo,
          duracion: this.mantenimiento.duracion,
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.mantenimientos = {};
          this.localShow = (this.keepStoring) ? false : true;
        })
        .catch(error => {
          errorHandling(error)
        })
        .finally(()=>this.vBtnSave.loading = false);
      }
    }
  }
</script>