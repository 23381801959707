var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-flex",
        [
          _c("v-text-field", {
            attrs: {
              color: "#1ABA24",
              label: _vm.placeholder,
              "prepend-inner-icon": "attach_file"
            },
            on: { click: _vm.pickFile },
            model: {
              value: _vm.fileName,
              callback: function($$v) {
                _vm.fileName = $$v
              },
              expression: "fileName"
            }
          }),
          _vm._v(" "),
          _c("input", {
            ref: "file",
            staticStyle: { display: "none" },
            attrs: { color: "#1ABA24", type: "file" },
            on: { change: _vm.fileSelected }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.value >= 0 && _vm.value < 100,
              expression: "value >= 0 && value < 100"
            }
          ],
          attrs: { md1: "" }
        },
        [
          _c(
            "v-progress-circular",
            {
              attrs: {
                rotate: 360,
                size: 50,
                width: 5,
                value: _vm.value,
                color: "primary"
              }
            },
            [_vm._v(_vm._s(_vm.value))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-flex",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.value == 100,
              expression: "value == 100"
            }
          ],
          attrs: { md1: "" }
        },
        [
          _c("v-icon", { attrs: { size: "50", color: "success" } }, [
            _vm._v("check_circle_outline")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }