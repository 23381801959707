<template>
  <v-container grid-list-md id="container-workers">
    <v-layout row wrap>
      <v-flex :xs12="!showFiltersForm" :xs9="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat>
            <v-toolbar-title>Trabajadores</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <v-btn dark color="verdeFayal" @click="openCreateModal" v-if="$root.can('new', permissions) ? true : false">Crear</v-btn>
            <v-btn dark color="verdeFayal" outline @click="showFiltersForm = !showFiltersForm">
              <v-icon dark>filter_alt</v-icon>
            </v-btn>
            <v-btn dark color="verdeFayal" outline  @click="exportToExcel">
              <v-icon dark>import_export</v-icon>
            </v-btn>            
          </v-toolbar>                      
          <v-data-table :headers="vHeaders" disable-initial-sort :items="workers.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td>{{ props.item.nombre_completo }}</td>
              <td>{{ props.item.area.nombre }}</td>
              <td>{{ props.item.puesto }}</td>
              <td>{{ props.item.nss }}</td>
              <td>{{ props.item.fecha_de_contratacion }}</td>
              <td>{{ (props.item.activo) ? "Activo" : "Inactivo" }}</td>
              <td>
                <v-layout>
                  <v-tooltip bottom>
                    <v-btn :disabled="!$root.can('files', permissions)" icon flat dark small slot="activator" color="warning" @click="openFilesModal(props.item.id)">
                      <v-icon>folder</v-icon>
                    </v-btn>
                    <span>Archivos</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn :disabled="!$root.can('update', permissions)" icon flat dark small slot="activator" color="verdeFayal" @click="openPaymentModal(props.item.id)">
                      <v-icon>payment</v-icon>
                    </v-btn>
                    <span>Ver datos de la tarjeta</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn :disabled="!$root.can('update', permissions)" icon flat dark small slot="activator" color="verdeFayal" :href="`/trabajadores/${props.item.id}/edit?tab=pagos-tab`">
                      <v-icon>payments</v-icon>
                    </v-btn>
                    <span>Pagos asociados</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn :disabled="!$root.can('update', permissions)" icon flat dark small slot="activator" color="verdeFayal" :href="`/trabajadores/${props.item.id}/edit`">
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <span>Ver/editar datos</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn :disabled="!$root.can('remove', permissions)" icon flat dark small slot="activator" color="error" @click="deleteWorker(props.item.id)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <span>Eliminar</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="workers" @pagination-change-page="getWorkers"></pagination>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs3 v-if="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat>
            <v-toolbar-title>Filtros</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="showFiltersForm = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex>
                  <v-select 
                    v-model="filters.filterArea" 
                    :items="areas" 
                    label="Área de trabajo"
                    item-text="nombre"
                    item-value="id"
                    clearable
                  />
                </v-flex>
                <v-flex>
                  <v-select 
                    v-model="filters.filterPuesto" 
                    :items="['Destajo', 'Raya']" 
                    label="Puesto"
                    clearable         
                  />
                </v-flex>
                <v-flex>
                  <v-select 
                    v-model="filters.filterActivo" 
                    :items="['Activo', 'Inactivo']" 
                    label="Estado del trabajador"
                    clearable            
                  />
                </v-flex>
                <v-flex>
                  <v-select 
                    v-model="filters.filterEstado" 
                    :items="['Pendiente', 'Aprobado', 'Finalizado']" 
                    label="Estado del último contrato"
                    clearable                                 
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn dark color="verdeFayal" small flat outline @click="limpiar">Limpiar</v-btn>
            <v-spacer></v-spacer>
            <v-btn dark color="verdeFayal" small @click="filterWorkers">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <!-- Modal -->
      <create-workers-modal :show.sync="showCreateModal"></create-workers-modal>
      <payment-worker-modal :show.sync="showPaymentModal" :worker-id.sync="currentWorkerId"></payment-worker-modal>
      <upload-files-modal @close="showFilesModal = false" element_type="App\Trabajador" :element_id="currentWorkerId" :dialog="showFilesModal"></upload-files-modal>
    </v-layout>
  </v-container>
</template>

<script>
import swal from "sweetalert";

export default {
  props:['permissions'],
  data: () => ({
    search: null,
    workers: {
      data: []
    },
    showCreateModal: false,
    showFilesModal: false,
    showEditModal: false,
    currentWorkerId: null,
    showPaymentModal: false,
    showFiltersForm: false,
    vPaginator: {
      limit: 10
    },
    vHeaders: [{
        text: 'Nombre',
        value: 'nombre_completo'
      },
      {
        text: 'Área de trabajo',
        value: 'area_de_trabajo'
      },
      {
        text: 'Puesto',
        value: 'puesto'
      },
      {
        text: 'NSS',
        value: 'nss'
      },
      {
        text: 'Fecha de ingreso',
        value: 'fecha_de_contratacion'
      },
      {
        text: 'Activo',
        value: 'activo'
      },
      {
        text: 'Acciones',
        value: 'acciones',
        sortable: false
      }
    ],

    filters: {
      filterArea: null,      
      filterPuesto: null,
      filterActivo: null,
      filterEstado: null,
    },
    option: 0,    
    areas: [],        

  }),
  watch: {
    search: function (value) {
      this.getWorkers();
    },
    showCreateModal: function (value) {
      if (!value) {
        this.getWorkers();
      }
    },
    showEditModal: function (value) {
      if (!value) {
        this.getWorkers();
      }
    },   

  },

  

  methods: {
    getWorkers: function (page = 1) {
      axios.get(`/trabajadores?page=${page}&query=${this.search}`)
        .then(response => {
          this.workers = response.data;
        })
        .catch(error => errorHandling(error));
    },
    openCreateModal: function() {
      this.showCreateModal = true;
    },
    openEditModal: function(workerId) {
      this.showEditModal = true;
      this.currentWorkerId = workerId;
    },   

    deleteWorker: function (workerId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('trabajadores.destroy', workerId))
            .then(response => {
              this.getWorkers();
              alertify.success(response.data.message_text);
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      })
    },
    openPaymentModal: function(workerId) {
      this.showPaymentModal = true;
      this.currentWorkerId = workerId;
    },
    openFilesModal: function(workerId) {
      this.showFilesModal = true;
      this.currentWorkerId = workerId;
    },
    deleteWorker: function (workerId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('trabajadores.destroy', workerId))
          .then(response => {
            this.getWorkers();
            alertify.success(response.data.message_text);
          })
          .catch(error => {
            errorHandling(error);
          });
        }
      })
    },

    exportToExcel: function() {
      axios.post('/trabajadores/filterWorkers', {
        ...this.filters,
        to_excel: true
      }, {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'trabajadores.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
    },

    //Inician Metodos usados para el modal de filtros      
    getAreas: function () {
      let headers = {

      }
      axios.get(laravelRoute('areas.index'))
      .then(response => this.areas = response.data)
      .catch(error => errorHandling(error));
    },

    filterWorkers: function() {
      axios.post('/trabajadores/filterWorkers',this.filters)
      .then(response => this.workers = response.data)
      .catch(error => errorHandling(error));
    },

    limpiar: function () {             
        this.filters.filterArea = null;
        this.filters.filterPuesto = null;
        this.filters.filterActivo = null;
        this.filters.filterEstado = null; 
        this.getWorkers();
    },
    //Terminan Metodos usados para el modal de filtros
  },
  created() {
    this.getWorkers();
    this.getAreas();
  }
}
</script>
