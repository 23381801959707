<template>
  <div class="text-xs-center">
    <v-layout>
      <v-flex xs12>
        <v-card>
          <v-container>
            <v-toolbar color="white" flat tabs>
              <v-btn flat icon href="/trabajadores">
                <v-icon>keyboard_arrow_left</v-icon>
              </v-btn>
              <v-toolbar-title>{{trabajador.nombre_completo.toUpperCase()}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <span :class="`badge badge-${trabajador.activo ? 'success' : 'warning' }`">{{trabajador.activo ? 'ACTIVO' : 'INACTIVO'}}</span>
              <v-spacer></v-spacer>
              <template v-slot:extension>
                <v-tabs v-model="vTabs" centered slider-color="verdeFayal">
                  <v-tab href="#datos-personales-tab">Datos personales</v-tab>
                  <v-tab href="#datos-beneficiario-tab">Beneficiario</v-tab>
                  <v-tab href="#datos-nomina-tab">Deducciones</v-tab>
                  <v-tab href="#datos-contratos-tab">Contratos</v-tab>
                  <v-tab href="#pagos-tab">Pagos</v-tab>
                  <v-tab v-if="trabajador.puesto === 'Raya'" href="#datos-vacaciones-tab">Vacaciones</v-tab>
                </v-tabs>
              </template>
            </v-toolbar>
          </v-container>
          <v-tabs-items v-model="vTabs">
            <v-tab-item value="datos-personales-tab">
              <v-card flat>
                <v-container grid-list-md>
                  <v-layout wrap>
                      <v-flex md4>
                          <v-text-field v-model="trabajador.nombre" required label="Nombre"></v-text-field>
                      </v-flex>
                      <v-flex md4>
                          <v-text-field v-model="trabajador.apellido_paterno" required label="Apellido paterno"></v-text-field>
                      </v-flex>
                      <v-flex md4>
                          <v-text-field v-model="trabajador.apellido_materno" required label="Apellido materno"></v-text-field>
                      </v-flex>
                      <v-flex md6>
                            <v-text-field prepend-icon="calendar_today" type="date" v-model="trabajador.fecha_de_nacimiento" label="Fecha de nacimiento"></v-text-field>
                      </v-flex>
                      <v-flex md6>
                          <v-select v-model="trabajador.estado_civil" :items="['Soltero/a', 'Casado/a', 'Viudo/a', 'Divorciado/a']" label="Estado civil"></v-select>
                      </v-flex>
                      <v-flex md6>
                          <v-select v-model="trabajador.grado_de_estudios" :items="['Primaria', 'Secundaria', 'Preparatoria', 'Universidad', 'Posgrado']" label="Grado de estudios"></v-select>
                      </v-flex>
                      <v-flex md6>
                          <v-text-field v-model="trabajador.curp" minlength="18" maxlength="18" required label="CURP"></v-text-field>
                      </v-flex>
                  </v-layout>
                  <v-layout wrap>
                      <v-flex md12>
                          <v-subheader>Datos de contacto</v-subheader>
                      </v-flex>
                      <v-flex md6>
                        <v-text-field mask="(###)-###-##-##" v-model="trabajador.telefono_fijo" label="Teléfono fijo"></v-text-field>
                      </v-flex>
                      <v-flex md6>
                        <v-text-field mask="(###)-###-##-##" v-model="trabajador.telefono_celular" label="Teléfono celular"></v-text-field>
                      </v-flex>
                      <v-flex md6>
                        <v-text-field type="email" v-model="trabajador.correo" label="Email"></v-text-field>
                      </v-flex>
                  </v-layout>
                  <v-layout wrap>
                      <v-flex md12>
                          <v-subheader>Dirección</v-subheader>
                      </v-flex>
                      <v-flex md4>
                          <v-text-field v-model="trabajador.calle" label="Calle"></v-text-field>
                      </v-flex>
                      <v-flex md4>
                          <v-text-field v-model="trabajador.numero" label="Número"></v-text-field>
                      </v-flex>
                      <v-flex md4>
                          <v-text-field v-model="trabajador.colonia" label="Colonia"></v-text-field>
                      </v-flex>
                      <v-flex md4>
                          <v-text-field v-model="trabajador.municipio" label="Municipio"></v-text-field>
                      </v-flex>
                      <v-flex md4>
                          <v-text-field v-model="trabajador.estado" label="Estado"></v-text-field>
                      </v-flex>
                      <v-flex md4>
                        <v-text-field v-model="trabajador.codigo_postal" minlength="5" maxlength="5" label="Código postal"></v-text-field>
                      </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md12>
                      <v-subheader>Datos de trabajador </v-subheader>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field v-model="trabajador.rfc" minlegth="12" maxlength="13" label="RFC"></v-text-field>
                    </v-flex>
                    <v-flex md6>
                        <v-text-field v-model="trabajador.nss" minlegth="11" maxlength="11" label="Número de seguro social (NSS)"></v-text-field>
                    </v-flex>
                    <v-flex md6>
                        <v-text-field prepend-icon="calendar_today" type="date" v-model="trabajador.fecha_de_contratacion" label="Fecha de contratación" readonly></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-select v-model="trabajador.puesto" :items="['Destajo', 'Raya']" label="Puesto"></v-select>
                    </v-flex>
                    <v-flex md6 v-if="trabajador.puesto == 'Destajo'">
                      <v-select v-model="trabajador.tipo_de_puesto" :items="['Sindicalizados', 'De confianza']" label="Tipo"></v-select>
                    </v-flex>
                    <v-flex md6>
                        <v-select 
                          v-model="trabajador.area_id" 
                          :items="areas" 
                          label="Área de trabajo"
                          item-text="nombre"
                          item-value="id"
                        />
                    </v-flex>
                    <v-flex md6 v-if="trabajador.puesto == 'Raya'">
                        <v-text-field type="number" v-model="trabajador.salario" label="Salario"></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-autocomplete
                    hint="Ocupación"
                    :search-input.sync="query"
                    :items="ocupaciones.data"
                    :loading="isLoading"
                    item-text="nombre"
                    clearable
                    color="verdeFayal"
                    v-model="trabajador.ocupacion_id"
                    persistent-hint
                    item-value="id"
                    label="Ocupación"
                    
                    hide-selected
                    hide-no-data
                    
                >
                  <template v-slot:no-data>
                    <v-list-tile>
                      <v-list-tile-title>
                        Busca la 
                        <strong>Ocupación</strong>
                        del trabajador
                      </v-list-tile-title>
                    </v-list-tile>
                  </template>
                </v-autocomplete>

                        <!-- <v-text-field v-model="trabajador.ocupacion.nombre" label="Ocupación" /> -->
                    </v-flex>        
                  </v-layout>
                </v-container>
              </v-card> 
            </v-tab-item>
            <v-tab-item value="datos-beneficiario-tab">
              <v-card flat>
                <v-container grid-list-md>
                  <v-card flat>
                    <v-layout wrap>
                        <v-flex md4>
                            <v-text-field v-model="beneficiario.nombre" required label="Nombre"></v-text-field>
                        </v-flex>
                        <v-flex md4>
                            <v-text-field v-model="beneficiario.apellido_paterno" required label="Apellido paterno"></v-text-field>
                        </v-flex>
                        <v-flex md4>
                            <v-text-field v-model="beneficiario.apellido_materno" required label="Apellido materno"></v-text-field>
                        </v-flex>
                        <v-flex md6>
                            <v-text-field prepend-icon="calendar_today" type="date" v-model="beneficiario.fecha_de_nacimiento" label="Fecha de nacimiento"></v-text-field>
                        </v-flex>
                        <v-flex md6>
                            <v-text-field v-model="beneficiario.rfc" required :counter="13" label="RFC"></v-text-field>
                        </v-flex>
                        <v-flex md6>
                            <v-text-field v-model="beneficiario.direccion" required label="Dirección"></v-text-field>
                        </v-flex>
                        <v-flex md6>
                            <v-select v-model="beneficiario.parentesco" :items="['Esposo/a', 'Hijo/a', 'Padre/madre', 'Hermano/a', 'Abuelo/a', 'Nieto/a', 'Tío/a', 'Sobrino/a', 'Otro']" label="Parentesco"></v-select>
                        </v-flex>
                    </v-layout>
                  </v-card>
                </v-container>
              </v-card> 
            </v-tab-item>
            <v-tab-item value="datos-nomina-tab">
              <v-card flat>
                <v-container grid-list-md>
                  <v-card flat>
                    <v-layout wrap>
                        <v-flex md4>
                            <v-text-field type="number" prepend-icon="attach_money" v-model="trabajador.credito" required label="Crédito"></v-text-field>
                        </v-flex>
                        <v-flex md4>
                            <v-text-field type="number" prepend-icon="attach_money" v-model="trabajador.pension_alimenticia" required label="Pensión alimenticia"></v-text-field>
                        </v-flex>
                        <v-flex md4>
                            <v-text-field type="number" prepend-icon="attach_money" v-model="trabajador.fondo_de_ahorro" required label="Fondo de ahorro"></v-text-field>
                        </v-flex>
                    </v-layout>
                  </v-card>
                </v-container>
              </v-card> 
            </v-tab-item>
            <v-tab-item value="pagos-tab">
              <payments-index :workerId="workerId" :puesto="trabajador.puesto" :salary="trabajador.salario" /></payments-index>
            </v-tab-item>
            <v-tab-item value="datos-contratos-tab">
              <v-container>
                <v-layout row wrap class="pl-4 pr-2"> 
                  <v-toolbar-title>Historial de contratos</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn 
                    v-if="$root.can('new', permissions)" 
                    color="success" 
                    dark @click="openCreateContractModal"> 
                    <v-icon left> post_add </v-icon>
                    Nuevo contrato 
                  </v-btn> 
                </v-layout>

                <v-data-table
                  :headers="headers"
                  :items="trabajador.contratos"
                  :items-per-page="20"
                  disable-initial-sort
                  hide-actions
                  class="mt-4 elevation-1"
                  sortBy="item.pivot.created_at"
                  item-key="item.pivot.id"
                >
                <template v-slot:items="props">
                  <td>{{ props.item.pivot.estatus }}</td>
                  <td>{{ props.item.periodo }}</td>
                  <td>{{ props.item.pivot.fecha_inicio }}</td>
                  <td>{{ props.item.pivot.fecha_fin }}</td>
                  <td>
                    <v-layout>
                      <v-tooltip bottom>
                        <v-checkbox
                          :disabled="!($root.can('accept', permissions) && props.item.pivot.estatus == 'Pendiente')  ? true : false"
                          :input-value="props.item.pivot.estatus === 'Pendiente' ? 'false' : 'true'"
                          true-value="true"
                          false-value="false"
                          flat
                          slot="activator"
                          color="orange darken-3"
                          @click="sweetAlertAprobadoGerencia(props.item.pivot)"
                        />
                        <span color="orange">
                          {{ props.item.pivot.estatus === 'Pendiente' ? 'Aprobar contrato - Gerencia Administrativa' : 'Contrato aprobado' }}
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <v-btn 
                          icon flat dark small slot="activator" color="verdeFayal"
                          @click="openEditContractModal(props.item.pivot)"
                        >
                          <v-icon>visibility</v-icon>
                        </v-btn>
                        <span>Ver/editar contrato</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <v-btn 
                          :disabled="!($root.can('remove', permissions) && props.item.pivot.estatus == 'Pendiente') ? true : false" 
                          icon flat dark small slot="activator" color="error"
                          @click="deleteContract(props.item.pivot.id)">
                          <v-icon>delete</v-icon>
                        </v-btn>
                        <span>Eliminar</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <v-btn 
                          v-if="($root.can('capture_folio', permissions) && props.item.pivot.estatus == 'Aprobado')"
                          :disabled="(props.item.pivot.estatus == 'finalizado')"
                          icon flat dark small slot="activator" 
                          :color="!(trabajador.activo == 1) ? 'verdeFayal' : 'error'"
                          @click="openActiveWorkerModal(props.item.pivot)">
                          <v-icon>settings_power</v-icon>
                        </v-btn>
                        <span v-if="!(trabajador.activo == 1)">Activar trabajador</span>
                        <span v-else>Desactivar trabajador</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <v-btn 
                          v-if="($root.can('expire', permissions) && props.item.pivot.estatus === 'Aprobado')"
                          :disabled="(props.item.pivot.estatus === 'finalizado')"
                          icon flat dark small slot="activator" color="primary"
                          @click="openRenovateWorkerModal(props.item.pivot)">
                          <v-icon>refresh</v-icon>
                        </v-btn>
                        <span>Renovar/Cambiar contrato</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <v-btn 
                          v-if="($root.can('expire', permissions) && props.item.pivot.estatus == 'Aprobado')"
                          :disabled="(props.item.pivot.estatus == 'finalizado')"
                          icon flat dark small slot="activator" color="error"
                          @click="sweetAlertFinalizarContrato(props.item.pivot)">
                          <v-icon>check</v-icon>
                        </v-btn>
                        <span>Finalizar contrato</span>
                      </v-tooltip>
                    </v-layout>
                  </td>
                </template>
                </v-data-table>
              </v-container>
            </v-tab-item>
            <v-tab-item v-if="trabajador.puesto === 'Raya'" value="datos-vacaciones-tab">
              <v-card flat>
                <v-container grid-list-md>
                  <v-select
                    class="pt-4"
                    :items="getVacationsFiltered"
                    :item-text="item => item.antiguedad_years + ' años'"
                    :item-value="item => item"
                    solo
                    label="Años de antiguedad"
                    v-model="selected_vacation"
                  />
                  <v-card>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-spacer />
                        <div class="h4 weight pr-2">Días de vacaciones por año: {{selected_vacation.dias_totales}}</div>
                      </v-layout>
                      <v-layout wrap>
                        <v-flex md6>
                          <v-text-field 
                            type="number" 
                            :max="selected_vacation.dias_totales"
                            :disabled="selected_vacation.antiguedad_years === '0 años'"
                            v-model="selected_vacation.pivot.dias_tomados"
                            label="Días tomados" 
                          />
                        </v-flex>
                        <v-flex md6>
                          <v-text-field 
                            type="number" 
                            label="Días restantes" 
                            v-model="selected_vacation.pivot.dias_restantes" 
                            readonly 
                          />
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card>
                  
                </v-container>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="vTabs != 'pagos-tab'" dark color="verdeFayal" @click="updateInfo" flat :loading="vBtnSave.loading"> Guardar </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <save-contracts-workers-modal 
      :show.sync="showSaveContractModal" :contrato_trabajador="current_contratoTrabajador"
      :permissions="permissions"
    />
    <active-worker-modal 
      :show.sync="showActiveWorkerModal" 
      :contrato_trabajador="current_contratoTrabajador" 
      :permissions="permissions" 
      :trabajador_is_active="trabajador.activo == 1 ? true : false"
    />
    <renovate-worker-modal 
      :show.sync="showRenovateWorkerModal" 
      :contrato_trabajador_id="current_contratoTrabajador.id" 
      :trabajador_is_active="trabajador.activo == 1 ? true : false"
    />
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import swal from 'sweetalert';
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    isLoading: false,
    query: null,
    selected_vacation: {
      pivot: {}
    },
    headers: [
      {text: "Estatus", value: "estatus", align: 'start'},
      {text: "Periodo", value: "periodo", align: 'start'},
      {text: "Fecha inicio", value:'fecha_inicio', align: 'start'},
      {text: "Fecha fin", value:'fecha_fin', align: 'start'},
      {text: "Opciones", value:'opciones', }
    ],
    showSaveContractModal: false,
    showActiveWorkerModal: false,
    showRenovateWorkerModal: false,
    trabajador: {
        nombre_completo: '',
        area_id: null,
        activo: false,
        ocupacion_id: null,
        vacaciones: {
          antiguedad_years: '',
          dias_totales: 0,
          dias_tomados: 0,
          dias_restantes: 0
        },
    },
    current_contratoTrabajador: {
      id: null,
      fecha_inicio: null,
      fecha_fin: null,
      trabajador_id: null,
      contrato_id: null,
      estatus: null,
      folio_nss: null,
      folio_baja_nss: null,
    },
    beneficiario: {
        nombre: null,
        apellido_paterno: null,
        apellido_materno: null,
        fecha_de_nacimiento: null,
        rfc: null,
        direccion: null,
        parentesco: null
    },
    areas: [],
    localShow: false,
    vTabs: "datos-personales-tab",
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    },
    ocupaciones: []
  }),
  props: ['workerId', 'permissions'],
  watch: {
    vTabs: function(value) {
      this.getWorker();
    },
    showSaveContractModal: function (value) {
      if (!value)
        this.getWorker();
    },
    showActiveWorkerModal: function(value){
      if (!value) 
        this.getWorker();
    },
    showRenovateWorkerModal: function(value){
      if (!value) 
        this.getWorker();
    },
    selected_vacation: function (value) {
      this.selected_vacation.pivot.dias_restantes = this.selected_vacation.dias_totales - this.selected_vacation.pivot.dias_tomados
    },
    query: function (value){
      this.getOcupaciones();
    }
  },
  computed: {
    getVacationsFiltered: function () {
      let years = this.getYears(this.trabajador.fecha_de_contratacion);
      const vacations = [];
      this.trabajador.vacaciones.forEach(vacation => {
        if (vacation.id <= years) 
          vacations.push(vacation);
      });
      return vacations;
    }
  },
  methods: {
    getWorker: function () {
      axios.get(laravelRoute('trabajadores.show', this.workerId))
        .then(response => {
            this.trabajador = response.data;
            this.beneficiario = (response.data.beneficiario) ? response.data.beneficiario : {
                nombre: null,
                apellido_paterno: null,
                apellido_materno: null,
                fecha_de_nacimiento: null,
                rfc: null,
                direccion: null,
                parentesco: null
            };
            if(this.getYears(this.trabajador.fecha_de_contratacion) >= 1)
              this.selected_vacation = this.getVacationsFiltered[0];
        })
        .catch(error => errorHandling(error));
    },
    updateWorker: function () {
      this.vBtnSave.loading = true;
      axios.patch(laravelRoute('trabajadores.update', this.workerId), {
        nombre: this.trabajador.nombre,
        apellido_paterno: this.trabajador.apellido_paterno,
        apellido_materno: this.trabajador.apellido_materno,
        fecha_de_nacimiento: this.trabajador.fecha_de_nacimiento,
        estado_civil: this.trabajador.estado_civil,
        grado_de_estudios: this.trabajador.grado_de_estudios,
        curp: this.trabajador.curp,
        telefono_fijo: this.trabajador.telefono_fijo,
        telefono_celular: this.trabajador.telefono_celular,
        email: this.trabajador.correo,
        calle: this.trabajador.calle,
        numero: this.trabajador.numero,
        colonia: this.trabajador.colonia,
        municipio: this.trabajador.municipio,
        estado: this.trabajador.estado,
        codigo_postal: this.trabajador.codigo_postal,
        rfc: this.trabajador.rfc,
        nss: this.trabajador.nss,
        puesto: this.trabajador.puesto,
        ocupacion_id: this.trabajador.ocupacion_id,
        tipo: this.trabajador.tipo_de_puesto,
        area_id: this.trabajador.area_id,
        salario: this.trabajador.salario,
        credito: this.trabajador.credito,
        pension_alimenticia: this.trabajador.pension_alimenticia,
        fondo_de_ahorro: this.trabajador.fondo_de_ahorro,
        vacaciones: this.getVacationsFiltered
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.getWorker();
      })
      .catch(error => {
        errorHandling(error)
      })
      .finally(()=>this.vBtnSave.loading = false);
    },
    updateBeneficiary: function() {
      this.vBtnSave.loading = true;
      if(this.trabajador.beneficiario) {
        axios.patch(laravelRoute('beneficiarios.update', this.beneficiario.id), {
          nombre: this.beneficiario.nombre,
          apellido_paterno: this.beneficiario.apellido_paterno,
          apellido_materno: this.beneficiario.apellido_materno,
          fecha_de_nacimiento: this.beneficiario.fecha_de_nacimiento,
          rfc: this.beneficiario.rfc,
          direccion: this.beneficiario.direccion,
          parentesco: this.beneficiario.parentesco
        })
        .then(response => {
            this.vSnackBar.visible = true;
            this.vSnackBar.text = response.data.message_text;
            this.getWorker();
        })
        .catch(error => {
            errorHandling(error)
        })
        .finally(()=>this.vBtnSave.loading = false);
      }
      else {
        axios.post(laravelRoute('beneficiarios.store'), {
          nombre: this.beneficiario.nombre,
          apellido_paterno: this.beneficiario.apellido_paterno,
          apellido_materno: this.beneficiario.apellido_materno,
          fecha_de_nacimiento: this.beneficiario.fecha_de_nacimiento,
          rfc: this.beneficiario.rfc,
          direccion: this.beneficiario.direccion,
          parentesco: this.beneficiario.parentesco,
          trabajador_id: this.trabajador.id
        })
        .then(response => {
          this.vSnackBar.visible = true;
          this.vSnackBar.text = response.data.message_text;
          this.getWorker();   
        })
        .catch(error => {
          errorHandling(error)
        })
        .finally(()=>this.vBtnSave.loading = false);
      }
    },
    updateInfo: function() {
        switch(this.vTabs) {
            case "datos-beneficiario-tab":
                this.updateBeneficiary();
                break;
            default:
              this.updateWorker();
            } 
    },
    openEditContractModal: function(contrato_trabajador){
      this.current_contratoTrabajador = contrato_trabajador;
      this.showSaveContractModal = true;
    },
    openCreateContractModal: function(){
      this.current_contratoTrabajador = {      
        id: null,
        fecha_inicio: null,
        fecha_fin: null,
        trabajador_id: this.workerId,
        contrato_id: null,
        estatus: 'Pendiente',
        folio_nss: null,
      };
      this.showSaveContractModal = true;
    },
    openActiveWorkerModal: function (contrato_trabajador) {
      this.current_contratoTrabajador = contrato_trabajador;
      this.showActiveWorkerModal = true;
    },
    openRenovateWorkerModal: function (contrato_trabajador) {
      this.current_contratoTrabajador = contrato_trabajador;
      this.showRenovateWorkerModal = true;
    },
    deleteContract(contratoTrabajador_id){
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('contratos_trabajadores.destroy', contratoTrabajador_id))
          .then(response => {
            this.getWorker();
            alertify.success(response.data.message_text);
          })
          .catch(error => {
            errorHandling(error);
          });
        }
      })
    },
    aprobarContrato: function(contratoTrabajador_id){
      axios.patch(laravelRoute('contratos_trabajadores.approve', contratoTrabajador_id))
          .then(response => {
            this.getWorker();
            if (response.data.message_type == 'success') 
              alertify.success(response.data.message_text);
            else if (response.data.message_type == 'error')
              alertify.error(response.data.message_text);
          })
          .catch(error => {
            errorHandling(error);
          });
    },
    sweetAlertAprobadoGerencia: function(contrato){
      swal({
        title: "¿Está seguro que quiere dar visto bueno a este contrato?",
        text: "Tenga en cuenta que, por el momento, esta acción será irrevocable",
        icon: "warning",
        buttons: true
      })
      .then(response => {
        if(response) this.aprobarContrato(contrato.id)
        else swal("El contrato no se aprobó");
      });
    },
    finalizarContrato: function(contratoTrabajador_id){
      axios.patch(laravelRoute('contratos_trabajadores.expire', contratoTrabajador_id))
          .then(response => {
            this.getWorker();
            alertify.success(response.data.message_text);
          })
          .catch(error => {
            errorHandling(error);
          });
    },
    sweetAlertFinalizarContrato: function(contrato){
      swal({
        title: "¿Está seguro que quiere finalizar este contrato?",
        text: "Tenga en cuenta que, por el momento, esta acción será irrevocable",
        icon: "warning",
        buttons: true
      }).then(response => {
        if(response){
          this.finalizarContrato(contrato.id)
          swal("El contrato finalizó satisfactoriamente", {
            icon: "success"
          })
        }else swal("El contrato no finalizó");
      });
    },
    activeWorker: function(){
      console.log('safsd');
    },
    getAreas: function () {
      axios.get(laravelRoute('areas.index'))
      .then(response => this.areas = response.data)
      .catch(error => errorHandling(error));
    },
    getYears: function (dateString) {
      let today = new Date()
      let date = new Date(dateString)
      let edad = today.getFullYear() - date.getFullYear()
      let diferenciaMeses = today.getMonth() - date.getMonth()
      if ( diferenciaMeses < 0 || 
          (diferenciaMeses === 0 && today.getDate() < date.getDate()))
        edad--

      return edad
    },
    getOcupaciones: function (page = 1) {
      axios.get(`/get_trabajadores_ocupaciones?page=${page}&query=${this.trabajador.area_id}`)
      .then(response => this.ocupaciones = response.data)
      .catch(error => errorHandling(error));
    },
    initialTabs: function() {
      this.vTabs = this.$route.query.tab ? this.$route.query.tab : "datos-personales-tab";
    }
  },
  created() {
    this.initialTabs();
    this.getAreas();
    this.getWorker();
    this.getOcupaciones();
  },
  
}
</script>
