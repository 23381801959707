<template>
  <v-container>      
    <v-layout row wrap>
      <v-flex :xs12="!showFiltersForm" :xs9="showFiltersForm">
        <v-card flat>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Lotes en promoción</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              color="verdeFayal"
              single-line
              label="Buscar"
              append-icon="search"
              v-model="search"
            ></v-text-field>
            <v-btn dark color="verdeFayal" outline @click="showFiltersForm = !showFiltersForm">
              <v-icon dark>filter_alt</v-icon>
            </v-btn>
            <v-btn dark color="verdeFayal" outline  @click="exportToExcel">
              <v-icon dark>import_export</v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            disable-initial-sort
            :items="fraccionamientos.data"
            hide-actions
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.prospecto.nombre_proyecto }}</td>
              <td>{{ props.item.prospecto.descripcion }}</td>
              <td>{{ props.item.prospecto.direccion }}</td>
              <td class="text-xs-left">
                <v-layout>
                    <v-tooltip bottom>
                        <v-btn
                            :href="`/promociones/${props.item.id}/fraccionamiento`"
                            color="verdeFayal"
                            slot="activator"
                            icon
                            flat
                        >
                            <v-icon>local_offer</v-icon>
                        </v-btn>
                        <span>Lotes en promoción</span>
                    </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination
              :limit="vPaginator.limit"
              :data="fraccionamientos"
              @pagination-change-page="getFraccionamientos"
            ></pagination>
          </v-card-actions>
        </v-card>
      </v-flex>   

      <v-flex xs3 v-if="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat>
            <v-toolbar-title>Filtros</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="showFiltersForm = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex>
                  <v-select 
                    v-model="filters.filterEstado" 
                    :items="this.itemsEstados"
                    item-value="id"
                    item-text="estado"
                    label="Estado"
                    clearable                                 
                  />
                </v-flex>                
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn dark color="verdeFayal" small flat outline @click="limpiar">Limpiar</v-btn>            
            <v-spacer></v-spacer>
            <v-btn dark color="verdeFayal" small @click="filterPromociones">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>

    </v-layout>
  </v-container>  
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import { errorHandling, laravelRoute } from "../../../lib/utilities";

export default {
  data() {
    return {
        fraccionamientos: {
            data: []
        },
        search: '',
        headers: [
            { text: "Nombre", value: "nombre" },
            { text: "Descripción", value: "telefono" },
            { text: "Dirección", value: "created_at" },
            { text: "Acciones", sortable: false }
        ],
        vPaginator: {
            limit: 10
        },

        showFiltersForm: false,
        filters: {                  
          filterEstado: null,        
        },
        itemsEstados: [],        
    };
  },
  methods: {
    getFraccionamientos: function (page = 1) {
        axios.get(`/fraccionamientos?page=${page}&query=${this.search}&paginated=1&relationships[]=estado`)
        .then(response => this.fraccionamientos = response.data)
        .catch(error => errorHandling(error));
    },

    getEstados: function() {
      axios.post('/promociones/estados')
      .then(response => this.itemsEstados = response.data)
      .catch(error => errorHandling(error));
    },    
    filterPromociones: function() {
      axios.post('/promociones/filterPromociones',this.filters)
      .then(response => this.fraccionamientos = response.data)
      .catch(error => errorHandling(error));
    },
    limpiar: function () {             
        this.filters.filterSolicitante = null;
        this.filters.filterEstado = null;
        this.getFraccionamientos();
    },
    exportToExcel: function() {
      axios.post('/promociones/filterPromociones', {
        ...this.filters,
        to_excel: true
      }, {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'promocionesFraccionamientos.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
    },
  },
  created(){
    this.getEstados();
    this.getFraccionamientos();
  }
};
</script>