<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="1350px" persistent>
      <v-card>
        <v-toolbar color="white" flat tabs>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Abastecer materiales</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-tabs slot="extension" v-model="vTabs" centered color="white" slider-color="verdeFayal">
            <v-tab href="#tab-index-material">Lista de materiales</v-tab>
            <v-tab href="#tab-add-material">Añadir materiales</v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="vTabs">
          <v-tab-item value="tab-index-material">
            <index-requisitions-mantenimiento
              :item-id.sync="localItemId"
              :reload.sync="reload"
              :model-type="modelType"
            ></index-requisitions-mantenimiento>
          </v-tab-item>
          <v-tab-item value="tab-add-material">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-container grid-list-md v-for="(material, index) in materiales" :key="index">
                <v-layout wrap> 
                  <v-flex md2>
                    <v-autocomplete
                      :items="categories"
                      :rules="rules"
                      @change="getCurrentMateriales(material.categoria_id, index)"
                      v-model="material.categoria_id"
                      item-value="id"
                      item-text="nombre"
                      label="Categorias"
                      single-line
                      style
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex md2>
                    <v-autocomplete
                      :items="material.materiales"
                      v-model="material.material"
                      :rules="rules"
                      return-object
                      item-text="nombre"
                      label="Material"
                      single-line
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex md2>
                    <v-text-field
                      :value="material.material.unidad"
                      readonly
                      required
                      label="Unidades"
                      disabled
                    ></v-text-field>
                  </v-flex>
                  <v-flex md1>
                    <v-text-field
                      v-model="material.cantidad"
                      type="number"
                      min="0"
                      required
                      :rules="rules"
                      label="Cantidad"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md2>
                    <v-text-field
                      prepend-icon="attach_money"
                      :value="material.material.precio"
                      readonly
                      disabled
                      required
                      label="Precio unitario"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md2>
                    <v-text-field
                      prepend-icon="attach_money"
                      :value="parseInt(material.material.precio) * parseInt(material.cantidad)"
                      readonly
                      disabled
                      label="Precio total"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md1>
                    <v-btn
                      v-if="index != 0"
                      @click="materiales.splice(index, 1)"
                      fab
                      flat
                      dark
                      small
                      color="verdeFayal"
                    >
                      <v-icon dark>remove</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            v-if="vTabs == 'tab-add-material'"
            color="verdeFayal"
            @click="storeRequisition"
            flat
            :loading="vBtnSave.loading"
          >Guardar</v-btn>
          <v-btn dark v-if="vTabs == 'tab-add-material'" color="verdeFayal" @click="addMateriales">
            <v-icon>add</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data(){
    return{
      localShow: false,
      reload: false,
      vTabs: "tab-index-material",
      localItemId: null,
      categories:[],
      catWMat:[],
      valid: false,
      rules: [v => !!v || "Este campo es requerido"],
      vBtnSave: {
        loading: false
      },
      materiales:[
        {
          categoria_id: null,
          material: {
            precio: 0
          },
          cantidad: 0,
          materiales: [],
        }
      ],
      fraccionamientosSelect:[],
      fraccionamientos:{
        prospecto:{
          nombre_proyecto: null,
          propuesta_lotificacion: [{
            manzanas:[{
              nombre: null,
              lotes:[{
                nombre: null
              }]
            }]
          }]
        }
      },
      localSearch: null,
    }
  },
  props:{
    show: Boolean,
    itemId: Number,
    modelType: String
  },
  methods:{
    getCategories: function() {
      axios
      .get(laravelRoute("categorias.index", {
        query: "Materiales de maquinaría"
      }))
      .then(response => {
        this.categories = response.data.data;
      })
      .catch(error => errorHandling(error));
    },
    addMateriales: function(){
      this.materiales.push({
        categoria_id: null,
        material: {
            precio: 0
        },
        cantidad: 0,
        materiales: []
      });
    }, 
    getCurrentMateriales: function(category_id, index) {
      var categories = [];
      this.categories.map(function (category) {
          categories.push(category);
      });

      this.materiales[index].materiales = categories.find(function(category) {
          return category.id == category_id
      }).materiales;           
    },
    getFraccionamientos: function() {
      axios
        .get(laravelRoute("fraccionamientos.index"))
        .then(response => {
          this.fraccionamientosSelect = response.data[0];
        })
        .catch(error => errorHandling(error));
    },
    storeRequisition: function(){
      if (this.$refs.form.validate()) {
        this.vBtnSave.loading = true;
        axios.post(laravelRoute("mantenimientos_materiales.save"), {
            materiales: this.materiales,
            manteniminiento_id: this.itemId
          })
          .then(response => {
            this.materiales = [
              {
                categoria_id: null,
                material: {
                  precio: 0
                },
                cantidad: 0,
                materiales: []
              },
            ];
            alertify.success(response.data.message_text);
          })
          .catch(error => errorHandling(error))
          .finally(() => (this.vBtnSave.loading = false));
      }
    }
  },
  watch:{
    show: function(value) {
      this.localShow = value;
    },
    localShow: function(value) {
      this.$emit("update:show", value);
    },
    itemId: function(value) {
      this.localItemId = value;
    },
    vTabs: function(value) {
      this.reload = value == "tab-index-material" ? true : false;
    },
  },
  mounted(){
    this.getCategories();
    this.getFraccionamientos();
  }
}
</script>