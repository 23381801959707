var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: { color: "white", flat: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "extension",
                      fn: function() {
                        return [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                centered: "",
                                "slider-color": "verdeFayal"
                              },
                              model: {
                                value: _vm.vTabs,
                                callback: function($$v) {
                                  _vm.vTabs = $$v
                                },
                                expression: "vTabs"
                              }
                            },
                            [
                              _c(
                                "v-tab",
                                { attrs: { href: "#datos-requisicion-tab" } },
                                [_vm._v("Datos personales")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab",
                                { attrs: { href: "#listado-materiales-tab" } },
                                [_vm._v("Listado de materiales")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab",
                                { attrs: { href: "#anadir-materiales-tab" } },
                                [_vm._v("Añadir materiales")]
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Editar Requisición")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.vTabs,
                    callback: function($$v) {
                      _vm.vTabs = $$v
                    },
                    expression: "vTabs"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "datos-requisicion-tab" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md4: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          label: "Nombre de la orden"
                                        },
                                        model: {
                                          value:
                                            _vm.requisiciones
                                              .nombre_requisicion,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.requisiciones,
                                              "nombre_requisicion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "requisiciones.nombre_requisicion"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md4: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.solicitante,
                                          required: "",
                                          label: "Seleccione solicitante"
                                        },
                                        model: {
                                          value: _vm.requisiciones.solicitante,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.requisiciones,
                                              "solicitante",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "requisiciones.solicitante"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md4: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.estado,
                                          label: "Seleccione estado",
                                          required: "",
                                          "item-value": "id",
                                          "item-text": "estado"
                                        },
                                        model: {
                                          value:
                                            _vm.requisiciones.estado.information
                                              .id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.requisiciones.estado
                                                .information,
                                              "id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "requisiciones.estado.information.id"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.proveedores,
                                          required: "",
                                          label: "Seleccione proveedor"
                                        },
                                        model: {
                                          value: _vm.requisiciones.proveedor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.requisiciones,
                                              "proveedor",
                                              $$v
                                            )
                                          },
                                          expression: "requisiciones.proveedor"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          required: "",
                                          label: "Descripcion"
                                        },
                                        model: {
                                          value: _vm.requisiciones.descripcion,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.requisiciones,
                                              "descripcion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "requisiciones.descripcion"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "listado-materiales-tab" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md12: "" } },
                                    [
                                      _c("v-data-table", {
                                        staticClass: "elevation-1",
                                        attrs: {
                                          headers: _vm.headers,
                                          items: _vm.requisiciones.materiales
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "items",
                                            fn: function(props) {
                                              return [
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.categoria
                                                        .nombre
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(props.item.nombre)
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(props.item.unidad)
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(props.item.cantidad)
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.precio_unitario
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    _vm._s(
                                                      props.item.precio_total
                                                    )
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td")
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "anadir-materiales-tab" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        _vm._l(_vm.materiales, function(material, index) {
                          return _c(
                            "v-container",
                            { key: index, attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md2: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: _vm.categories,
                                          rules: _vm.rules,
                                          "item-value": "id",
                                          "item-text": "nombre",
                                          label: "Categorias",
                                          "single-line": ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.getCurrentMateriales(
                                              material.categoria_id,
                                              index
                                            )
                                          }
                                        },
                                        model: {
                                          value: material.categoria_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              material,
                                              "categoria_id",
                                              $$v
                                            )
                                          },
                                          expression: "material.categoria_id"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md2: "" } },
                                    [
                                      _c("v-autocomplete", {
                                        attrs: {
                                          items: material.materiales,
                                          rules: _vm.rules,
                                          "return-object": "",
                                          "item-text": "nombre",
                                          label: "Material",
                                          "single-line": ""
                                        },
                                        model: {
                                          value: material.material,
                                          callback: function($$v) {
                                            _vm.$set(material, "material", $$v)
                                          },
                                          expression: "material.material"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md2: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          value: material.material.unidad,
                                          readonly: "",
                                          required: "",
                                          label: "Unidades",
                                          disabled: ""
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md1: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "number",
                                          min: "0",
                                          required: "",
                                          rules: _vm.rules,
                                          label: "Cantidad"
                                        },
                                        model: {
                                          value: material.cantidad,
                                          callback: function($$v) {
                                            _vm.$set(material, "cantidad", $$v)
                                          },
                                          expression: "material.cantidad"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md2: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-icon": "attach_money",
                                          value: material.material.precio,
                                          readonly: "",
                                          disabled: "",
                                          required: "",
                                          label: "Precio unitario"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md2: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          "prepend-icon": "attach_money",
                                          value:
                                            parseInt(material.material.precio) *
                                            parseInt(material.cantidad),
                                          readonly: "",
                                          disabled: "",
                                          label: "Precio total"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md1: "" } },
                                    [
                                      index != 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                fab: "",
                                                flat: "",
                                                dark: "",
                                                small: "",
                                                color: "verdeFayal"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.materiales.splice(
                                                    index,
                                                    1
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { dark: "" } },
                                                [_vm._v("remove")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.vTabs == "datos-requisicion-tab"
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            dark: "",
                            color: "verdeFayal",
                            flat: "",
                            loading: _vm.vBtnSave.loading
                          },
                          on: { click: _vm.updateRequisicion }
                        },
                        [_vm._v(" Guardar ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.vTabs == "anadir-materiales-tab"
                    ? _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal" },
                          on: {
                            click: function($event) {
                              return _vm.materiales.push({
                                categoria_id: null,
                                material: { precio: 0 },
                                cantidad: 0,
                                materiales: []
                              })
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.vTabs == "anadir-materiales-tab"
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            dark: "",
                            color: "verdeFayal",
                            flat: "",
                            loading: _vm.vBtnSave.loading
                          },
                          on: { click: _vm.addMateriales }
                        },
                        [_vm._v(" Guardar ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }