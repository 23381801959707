<template>
  <v-layout>
    <v-container>
      <v-card>
        <v-flex xs12>
          <v-toolbar color="white" flat tabs>
            <v-btn fab small flat href="/fraccionamientos">
              <v-icon>chevron_left</v-icon>
            </v-btn>
            <v-toolbar-title>Etapas de fraccionamiento</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-data-table :headers="headers" disable-initial-sort :items="etapas.data" hide-actions class="elevation-1">
            <template v-slot:items="etapasProps">
              <td class="text-xs-left">{{ etapasProps.item.nombre }}</td>
              <td class="text-xs-left capitalize">{{ etapasProps.item.tipo }}</td>
              <td class="text-xs-left">{{ etapasProps.item.estado.information ? etapasProps.item.estado.information.estado : "No disponible" }}</td>
              <td class="text-xs-left">
                <v-layout>
                  <v-tooltip bottom>
                    <v-btn
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="verdeFayal"
                      @click="openDatosModal(etapasProps.item.id)"
                    >
                      <v-icon>watch_later</v-icon>
                    </v-btn>
                    <span>Ver cálculos de tiempo</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="info"
                      :href="laravelRoute('etapas.edit', etapasProps.item.id)"
                    >
                      <v-icon color="verdeFayal">remove_red_eye</v-icon>
                    </v-btn>
                    <span>Ver</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      :disabled="etapasProps.item.estado.estado_id != 25"
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="info"
                      @click="startEtapa(etapasProps.item)"
                    >
                      <v-icon>play_arrow</v-icon>
                    </v-btn>
                    <span>Iniciar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      :disabled="etapasProps.item.estado.estado_id != 26"
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="red"
                      @click="stopEtapa(etapasProps.item)"
                    >
                      <v-icon>stop</v-icon>
                    </v-btn>
                    <span>Finalizar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="#d74d5a"
                      :href="`/incidencias/owner/${etapasProps.item.id}`"
                      :disabled="etapasProps.item.estado.estado_id != 26"
                    >
                      <v-icon>alarm_add</v-icon>
                    </v-btn>
                    <span>Incidencias</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="#a7b14a"
                      :disabled="etapasProps.item.estado.estado_id != 26"
                      :href="`/etapas/${etapasProps.item.id}/actividades`"
                    >
                      <v-icon>date_range</v-icon>
                    </v-btn>
                    <span>Iniciar actividades</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="red"
                      :disabled="etapasProps.item.estado.estado_id == 26"
                      @click="deleteEtapa(etapasProps.item.id)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <span>Eliminar etapa</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
            <template v-slot:no-results>
              <v-alert
                :value="true"
                color="error"
                icon="warning"
              >Your search for "{{ search }}" found no results.</v-alert>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="etapas" @pagination-change-page="getEtapas"></pagination>
          </v-card-actions>
        </v-flex>
      </v-card>
      <!-- Modal -->
      <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
        {{ vSnackBar.text }}
        <v-btn color="pink" flat @click="vSnackBar.visible = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-snackbar>
      <etapas-iniciar 
        @close="dialogEtapasIniciar = false"
        :dialog="dialogEtapasIniciar"
        :idEtapa="idEtapaToFind"
      />
      <etapas-edit 
        @close="dialog = false"
        :propuestaLotificacion="etapaSelected"
        :dad="etapaSelected.tipo"
        :dialog="dialog"
      />
      <etapa-hours-calculation-modal 
        :show.sync = "showDatosModal"
        :etapa-id = "currentEtapaId"
      />
    </v-container>
  </v-layout>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from "axios";
  import swal from "sweetalert";
  import moment from "moment";

  export default {
    props:['fraccionamientoId'],
    data(){
      return{
        laravelRoute,
        etapas: {
          data: []
        },
        vPaginator: {
          limit: 10
        },
        currentEtapaId: null,
        showDatosModal: false,
        dialog: false,
        dialogEtapasIniciar: false,
        idEtapaToFind: 0,
        etapaSelected: {},
        search: '',
        vSnackBar: {
          visible: false,
          text: null
        },
        headers:[
          { 
            sortable: false,
            text: 'Nombre', 
          },
          { 
            sortable: false,
            text: 'Tipo', 
          },
          { 
            sortable: false,
            text: 'Estado', 
          },
          { 
            sortable: false,
            text: 'Acciones', 
          }
        ],
      }
    },
    methods: {
      getEtapas: function (page = 1) {
        axios.get(`/etapas/fraccionamiento/${this.fraccionamientoId}?page=${page}`)
        .then(response => {
          this.etapas = response.data;
        })
        .catch(error => errorHandling(error));
      },
      openDatosModal: function(etapaId) {
        this.currentEtapaId = etapaId;
        this.showDatosModal = true;
      },
      startEtapa: function(etapa){
        swal({
          title: "¿Estás seguro?",
          text: "Una vez iniciada la etapa no se puede revertir.",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(result => {
          if (result) {
            axios.post(laravelRoute('etapas.fracccionamiento.start', etapa.id), {
              horas_totales: etapa.horas_por_etapa,
            })
              .then(response => {
                this.refreshData(etapa.fraccionamiento_id);
                alertify.success(response.data.message_text);
              })
              .catch(error => {
                errorHandling(error);
              });
          }
        })
      },
      stopEtapa: function(etapa){
        swal({
          title: "¿Estás seguro?",
          text: "Esta por finalizar una etapa.",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(result => {
          if (result) {
            axios.post(laravelRoute('etapas.fracccionamiento.finalizar', etapa.id))
              .then(response => {
                this.refreshData(etapa.fraccionamiento_id);
                alertify.success(response.data.message_text);
              })
              .catch(error => {
                errorHandling(error);
              });
          }
        })
      },
      stoppable: function(etapa){
        return moment() >= moment(etapa.fecha_fin) ? false : true;
      },
      deleteEtapa: function(etapaId) {
        swal({
          title: "¿Estás seguro?",
          text: "Una vez eliminado no se podrá recuperar",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(result => {
            axios.delete(laravelRoute('etapas.destroy', etapaId))
            .then(response => {
              this.getEtapas();
              this.vSnackBar.visible = true;
              this.vSnackBar.text = response.data.message_text;
            })
            .catch(error => errorHandling(error));
        })
      },
      refreshData: function(id){
        axios.get(laravelRoute('etapas.fracccionamiento.index', id))
        .then(response => {
          this.etapas = response.data;
        })
        .catch(error => {
          errorHandling(error);
        });
      },
    },
    created() {
      this.getEtapas();
    }
  };
</script>

<style>
  .capitalize {
    text-transform: capitalize;
  }
</style>