<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Asignar costos de mano de obra</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat color="verdeFayal" icon dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-tabs slot="extension" v-model="vTabs" centered color="white" slider-color="verdeFayal">
            <v-tab href="#tab-index-valores">Costos de mano de obra</v-tab>
            <v-tab href="#tab-add-costos">Añadir costo de mano de obra</v-tab>
        </v-tabs>
      </v-toolbar>
      <v-card-text>
        <v-container grid-list-md>
          <v-tabs-items v-model="vTabs">
            <v-tab-item value="tab-index-valores">
              <v-data-table :items="currentValues.data" hide-actions>
                <template v-slot:headers="props">
                  <tr>
                    <th>Ocupación</th>
                    <th>Precio</th>
                    <th>Cantidad</th>
                    <th>Total</th>
                    <th></th>
                  </tr>
                </template>
                <template v-slot:items="props">
                  <tr>
                    <td>{{ (props.item.ocupacion) ? props.item.ocupacion.nombre : 'Aún sin especificar'}}</td>
                    <td>$ {{ props.item.precio_unitario| currency }}</td>
                    <td>{{ props.item.cantidad | currency }}</td>
                    <td>$ {{ props.item.precio_total | currency }}</td>
                    <td>
                      <v-tooltip bottom>
                        <v-btn icon flat dark small slot="activator" color="error" @click="deleteValue(props.item.id)">
                          <v-icon>delete</v-icon>
                        </v-btn>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </td>
                  </tr>
              </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item value="tab-add-costos">
              <v-form ref="form" v-model="valid" lazy-validation>
                <template v-for="(value, index) in values">
                  <v-layout wrap :key="index">
                    <v-flex md4>
                      <searcher
                        :configuration="searchOcupaciones"
                        :itemSelected.sync="value.ocupacion_id"
                      ></searcher>
                    </v-flex>
                    <v-flex md2>
                      <v-text-field :rules="rules" label="Precio" min="1" v-model="value.precio_unitario" type="number"></v-text-field>
                    </v-flex>
                    <v-flex md2>
                      <v-text-field :rules="rules" label="Cantidad" min="0.1" v-model="value.cantidad" type="number"></v-text-field>
                    </v-flex>
                    <v-flex md2>
                      <v-text-field readonly label="Total" :value="totalNeto(value.precio_unitario * value.cantidad) | currency"></v-text-field>
                    </v-flex>
                    <v-flex md1>
                      <v-btn v-if="index != 0" @click="values.splice(index, 1)" fab flat dark small color="red">
                          <v-icon dark>remove</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-divider :key="index"/>
                </template>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark v-if="vTabs == 'tab-add-costos'" color="verdeFayal" @click="addValue">
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn v-if="vTabs == 'tab-add-costos'" outline dark color="verdeFayal" flat :loading="vBtnSave.loading" @click="storeValues"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<style>
.primary-title {
  color: #1aba24;
}
</style>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    values: [
      {
        ocupacion_id: '',
        precio_unitario: 0,
        cantidad: 0,
        unidad: null,
        horas: 0
      },
    ],
    currentValues: {
      data: []
    },
    unidades: [
      "Pieza",
      "Tramo",
      "Lote (m2)"
    ],
    tax: 0,
    localShow: false,
    valid: false,
    vTabs: "tab-index-valores",
    rules: [v => !!v || "Este campo es requerido"],
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    valueId: Number,
    householdId: String,
    itemId: Number,
    name: String,
    modelType: String
  },
  computed: {
    searchOcupaciones: function() {
      return {
        url: `/get_trabajadores_ocupaciones?query=${(this.modelType == 'App\\Urbanizacion') ? 4 : 3}`,
        paginatedContainer: 'data',
        noDataText: "No se encontraron resultados.",
        label: "Ocupación",
        itemText: "nombre",
        itemValue: "id",
        descriptionLimit: 60,
        disabled: false,
        useHeaders: true,
      }
    }
  },
  watch: {
    show: function(val) {
      this.localShow = val;
      this.getValues();
    },
    localShow: function(value) {
      this.$emit('update:show', value),
      this.$refs.form.reset(),
      this.$refs.form.resetValidation()
    },
    vTabs: function(value) {
      this.getValues();
    }
  },
  methods: {
    totalNeto: function (totalBeforeTax = 0) {
      var calculateTax = (totalBeforeTax / 100) * this.tax;
      var total = totalBeforeTax + calculateTax;
      return total;
    },
    addValue: function() {
      this.values.push({
        precio_unitario: 0,
        cantidad: 0,
        unidad: null,
        horas: 0
      });
    },
    storeValues: function() {
      this.vBtnSave.loading = true;
      if (this.$refs.form.validate()) {
        axios.post(laravelRoute('valores.store'), {
          valores: this.values,
          modelo_id: this.householdId,
          modelo_type: this.modelType,
          rubro_id: this.itemId
        })
        .then(response => {
          this.vSnackBar.text = response.data.message_text;
          this.vSnackBar.visible = true;
          this.localShow = false;
          this.values = [];
          this.values.push({
            ocupacion_id: null,
            precio_unitario: 0,
            cantidad: 0,
            unidad: null,
            horas: 0
          });
        })
        .catch(error => errorHandling(error))
      }
      this.vBtnSave.loading = false;
      this.getValues();
    },
    getValues: function(page = 1) {
      axios.get(`/valores/prototipo_vivienda/${this.householdId}?rubro_id=${this.itemId}&page=${page}&modelType=${this.modelType}`)
      .then(response => this.currentValues = response.data)
      .catch(error => errorHandling(error))
    },
    getTax: function(page = 1) {
      axios.get(`/configuraciones/2`)
      .then(response => this.tax = response.data.valor)
      .catch(error => errorHandling(error))
    },
    deleteValue: function(valueId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.delete(laravelRoute('valores.destroy', valueId))
          .then(response => {
            this.getValues();
            this.vSnackBar.text = response.data.message_text;
            this.vSnackBar.visible = true;
          })
          .catch(error => {
            errorHandling(error);
          });
        }
      })
    }
  },
  created() {
    this.getTax();
  }
}
</script>
