var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1150px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Ver orden de salida")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.orden.nombre,
                                  required: "",
                                  label: "Orden de salida",
                                  readonly: ""
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.orden.folio,
                                  required: "",
                                  label: "Folio",
                                  readonly: ""
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.orden.solicitud.solicitante,
                                  required: "",
                                  label: "Solicitante",
                                  readonly: ""
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value: _vm.orden.trabajador.nombre_completo,
                                  required: "",
                                  label: "Entregar",
                                  readonly: ""
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  value:
                                    _vm.orden.solicitud.fraccionamiento
                                      .nombre_proyecto,
                                  required: "",
                                  label: "Fraccionamiento",
                                  readonly: ""
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.orden.estado.information.estado == "Pendiente"
                            ? _c(
                                "v-flex",
                                { attrs: { md6: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { flat: "", color: "red" },
                                      on: {
                                        click: function($event) {
                                          return _vm.cancelOrden()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Rechazar orden\n                  "
                                      ),
                                      _c(
                                        "v-icon",
                                        { attrs: { right: "", dark: "" } },
                                        [_vm._v("cancel")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            [
                              _c("v-data-table", {
                                staticClass: "elevation-1",
                                attrs: {
                                  headers: _vm.vHeaders,
                                  "disable-initial-sort": "",
                                  items: _vm.orden.materiales,
                                  "hide-actions": ""
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "items",
                                    fn: function(props) {
                                      return [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              props.item.origin_material
                                                .material.categoria_asociada
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              props.item.origin_material
                                                .material.nombre
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "$ " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  props.item.origin_material
                                                    .material.precio
                                                )
                                              )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(props.item.cantidad))
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "$ " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  props.item.cantidad *
                                                    props.item.origin_material
                                                      .material.precio
                                                )
                                              )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              props.item.origin_material
                                                .distribucion
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              props.item.origin_material
                                                .distribucion == "Manzanas"
                                                ? props.item.origin_material
                                                    .lote.nombre
                                                : "No aplica"
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        props.item.origin_material
                                          .distribucion == "Calles"
                                          ? _c("td", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    props.item.origin_material
                                                      .calle.nombre
                                                  ) +
                                                  "\n                  "
                                              )
                                            ])
                                          : props.item.origin_material
                                              .distribucion == "Áreas verdes"
                                          ? _c("td", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    props.item.origin_material
                                                      .area_verde.nombre
                                                  ) +
                                                  "\n                  "
                                              )
                                            ])
                                          : _c("td", [_vm._v("No aplica")]),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "ma-2",
                                                attrs: {
                                                  color:
                                                    props.item.despachado == 1
                                                      ? "green"
                                                      : "red",
                                                  outline: "",
                                                  small: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      props.item.despachado == 1
                                                        ? "Despachado"
                                                        : "No"
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          [
                                            _vm.orden.estado.information
                                              .estado != "Rechazada"
                                              ? _c(
                                                  "v-tooltip",
                                                  { attrs: { bottom: "" } },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          slot: "activator",
                                                          disabled:
                                                            props.item
                                                              .despachado == 1,
                                                          icon: "",
                                                          flat: "",
                                                          dark: "",
                                                          small: "",
                                                          color: "verdeFayal"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.despacharMaterial(
                                                              props.item.id
                                                            )
                                                          }
                                                        },
                                                        slot: "activator"
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "compare_arrows"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        "Despachar material"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }