<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">{{housing.nombre}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat color="verdeFayal" icon dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md12>
              <v-subheader>Datos del plan de trabajo de urbanización</v-subheader>
            </v-flex>
            <v-divider></v-divider>
            <v-flex md12>
              <v-text-field v-model="housing.nombre" required label="Nombre"></v-text-field>
            </v-flex>
            <v-flex md12>
              <v-text-field v-model="housing.descripcion" required label="Descripción"></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <hr>
      <v-layout wrap>
        <v-flex md12>
          <v-subheader>Conceptos del prototipo</v-subheader>
        </v-flex>
        <v-divider></v-divider>
        <v-flex md12>
          <searcher-component
            :configuration="searchConfig"
            :itemsShared="housing.conceptos"
            v-on:itemSelected="selected"
          />
            <v-data-table
              :headers="vDataTable.headers"
              :items="items"
              item-key="name"
              class="elevation-1"
              disable-initial-sort
              no-data-text = 'Sin rubros a asignar'
            >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="props.selected = !props.selected">
                <td>{{ props.item.nombre }}</td>
                <td v-if="!!props.item.rubros.length">
                  <v-chip v-for="rubro in getRubros.filter(rubro => rubro.concepto_id == props.item.id)" :key="rubro.id" class="ma-2" color="verdeFayal" @click="manageRubro(rubro, true)" outline>
                    <v-avatar>
                      <v-icon>check_circle</v-icon>
                    </v-avatar>
                    {{ rubro.nombre }}
                  </v-chip>  
                </td>
                <td v-else>
                  <v-chip class="ma-2" color="error" outline small>
                    Concepto sin rubros.
                  </v-chip>
                </td>
                <td v-if="!!getRubros.filter(rubro => rubro.concepto_id == props.item.id && rubro.selected == true ).length" class="text-xs-right">
                  <v-chip v-for="rubro in getRubros.filter(rubro => rubro.concepto_id == props.item.id && rubro.selected == true )" :key="rubro.id" class="ma-2" color="verdeFayal" @input="manageRubro(rubro, false)" outline close>
                    {{ rubro.nombre }}
                  </v-chip>
                </td>
                <td v-else>
                  <v-chip class="ma-2" color="error" outline small>
                    Sin rubros seleccionados.
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="updateHousing" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<style>
.primary-title {
  color: #1aba24;
}
</style>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    housing: {},
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    },
    searchConfig: {
      url: "/conceptos",
      paginatedContainer: 'data',
      noDataText: "No se encontraron resultados.",
      itemText: "nombre",
      itemValue: "id",
      descriptionLimit: 60,
      placeholder: "Buscar Concepto",
      prependIcon: "mdi-database-search",
      chips: true,
      multiple: true,
      deleteChips: true,
      disabled: false,
      useHeaders: true,
    },
    items: [],
    vDataTable: {
      headers: [
        {
          text: 'Concepto',
          align: 'left',
          value: 'name',
          sortable: false
        },
        { text: 'Rubros disponibles', value: 'calories', sortable: false },
        { text: 'Rubros seleccionados', value: 'fat', sortable: false },
      ],
      pagination: {
        sortBy: 'name'
      },
    },
    concepts: [],
    rubros: [],
  }),
  props: {
    show: Boolean,  
    housingId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    housingId: function (value) {
      this.getHousing(value);
    },
    localShow: function (value) {
      this.$emit('update:show', value)
    },
    items: function(value){
      this.handleRubros(value);
    },
    rubros: function(value){
      let localValue = value;
      let assosiated_rubros_local = [];
      this.housing.custom_conceptos.forEach(concepto => {
        concepto.rubros.forEach(rubro => {
          assosiated_rubros_local.push(rubro.id);
        })
      });
      value = localValue.map(rubro => rubro.selected = assosiated_rubros_local.includes(rubro.id));
    },
  },
  methods: {
    manageRubro: function(rubro, state){
      this.rubros[this.rubros.indexOf(rubro)].selected = state;
    },
    handleRubros: function(elements){
      this.rubros = [];
      let rubrosContainer = [];
      elements.forEach(element => {
        element.rubros.forEach( rubro => rubrosContainer.push(Object.assign({}, { selected: false, ...rubro })) );
      });
      this.rubros = rubrosContainer;
    },
    selected: function(item) {
      this.concepts = item.map(element => element.id);
      this.items = item; 
      this.handleRubros(this.items)
    },
    getHousing: function (housingId) {
      axios.get(laravelRoute('urbanizacion.show', housingId))
        .then(response => this.housing = response.data)
        .catch(error => errorHandling(error))
    },
    updateHousing: function () {
      this.vBtnSave.loading = true;
      axios.patch(laravelRoute('urbanizacion.update', this.housingId), {
        nombre: this.housing.nombre,
        descripcion: this.housing.descripcion,
        conceptos: this.concepts,
        rubros: this.rubros.filter(rubro => rubro.selected == true).map(element => element.id)
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.vBtnSave.loading = false;
        this.localShow = false;
        setTimeout(function () {
          location.reload();
        }, 1500);
      })
      .catch(error => {
        this.vBtnSave.loading = false;
        errorHandling(error)
      });
    }
  },
  computed: {
    getRubros: function(){
      return this.rubros;
    }
  }
}
</script>
