<template>
    <div>
        <v-card flat>
            <v-toolbar color="verdeFayal" dark extended flat>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn flat icon :href="`/proyectos`">
                            <v-icon>keyboard_arrow_left</v-icon>
                        </v-btn>
                    </template>
                    <span>Regresar</span>
                </v-tooltip>
            </v-toolbar>

            <v-layout row pb-2>
                <v-flex xs10 offset-xs1>
                    <v-card class="card--flex-toolbar">
                        <v-toolbar card prominent>
                            <v-spacer></v-spacer>
                            <v-toolbar-title class="body-2 grey--text">Editar propuesta de lotificación {{lotificacion.nombre}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-container grid-list-md>
                                <v-layout wrap>
                                    <v-flex xs12 sm6 md6>
                                        <v-text-field
                                            label="Nombre de la propuesta"
                                            v-model="lotificacion.nombre"
                                            color="#1ABA24"
                                            :rules="[(v) => !!v || 'Campo requerido']"
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm6 md6>
                                        <v-text-field
                                            label="Estado"
                                            v-model="lotificacion.estado.information.estado"
                                            color="#1ABA24"
                                            readonly
                                        ></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm12 md12 center>
                                        <v-textarea
                                            color="#1ABA24"
                                            label="Descripcion"
                                            v-model="lotificacion.descripcion"
                                            counter="250"
                                            :maxlength="250"
                                        ></v-textarea>
                                    </v-flex>
                                </v-layout>

                                <v-divider></v-divider>
                                
                                <v-layout row wrap>
                                    <v-flex>
                                        <v-tabs v-model="vTabs" fixed-tabs centered color="white" slider-color="#1aba24">
                                            <v-tab href="#tab-manzanas">Manzanas</v-tab>
                                            <v-tab href="#tab-calles">Calles</v-tab>
                                            <v-tab href="#tab-areas-verdes">Áreas verdes</v-tab>
                                        </v-tabs>

                                        <v-tabs-items v-model="vTabs">
                                            <v-tab-item value="tab-manzanas">
                                                <v-card flat>
                                                    <v-container>
                                                        <v-layout wrap>
                                                            <v-flex xs12>
                                                                <v-toolbar color="white" flat tabs>
                                                                    <v-spacer></v-spacer>
                                                                    <v-tooltip bottom>
                                                                        <v-btn
                                                                            slot="activator"
                                                                            color="verdeFayal"
                                                                            :disabled="disabled"
                                                                            dark
                                                                            @click="addManzanas"
                                                                            :loading="addButtonLoading"
                                                                        >
                                                                            <v-icon>add</v-icon>
                                                                        </v-btn>
                                                                        <span>Añadir manzana</span>
                                                                    </v-tooltip>
                                                                </v-toolbar>
                                                                <v-card-text>
                                                                    <v-data-table
                                                                        hide-actions
                                                                        :items="manzanas.data"
                                                                        ref="manzanasTable"
                                                                    >
                                                                        <template v-slot:headers="props">
                                                                            <tr>
                                                                                <th>Nombre</th>
                                                                                <th>Número de lotes</th>
                                                                                <th>Superficie (M2)</th>
                                                                                <th class="text-xs-right">Acciones</th>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-slot:items="props">
                                                                            <tr>
                                                                                <td>Manzana {{ props.item.nombre }}</td>
                                                                                <td>
                                                                                    <span style="background-color: #ff5617;" class="badge badge-secondary">
                                                                                        {{ props.item.lotes_count }}
                                                                                    </span>
                                                                                </td>
                                                                                <td>{{ props.item.superficie.toFixed(2) }}</td>
                                                                                <td class="text-xs-right">
                                                                                    <v-layout justify-end row>
                                                                                        <v-tooltip bottom>
                                                                                            <v-btn
                                                                                                slot="activator"
                                                                                                color="verdeFayal"
                                                                                                flat
                                                                                                icon
                                                                                                :disabled="disabled"
                                                                                                @click="openAddLotesModal(props.item)"
                                                                                            >
                                                                                                <v-icon>add</v-icon>
                                                                                            </v-btn>
                                                                                            <span>Añadir lotes</span>
                                                                                        </v-tooltip>
                                                                                        <v-tooltip bottom>
                                                                                            <v-btn
                                                                                                slot="activator"
                                                                                                color="red"
                                                                                                flat
                                                                                                icon
                                                                                                :disabled="disabled"
                                                                                                @click="deleteManzana(props.item.id)"
                                                                                            >
                                                                                                <v-icon>delete</v-icon>
                                                                                            </v-btn>
                                                                                            <span>Eliminar</span>
                                                                                        </v-tooltip>
                                                                                        <v-btn flat icon @click="openLotesExpand(props.item.id, props.index)">
                                                                                            <v-icon>{{(props.expanded) ? 'expand_less' : 'expand_more'}}</v-icon>
                                                                                        </v-btn>
                                                                                    </v-layout>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-slot:expand="props">
                                                                            <table class="table table-striped table-hover table-sm">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Lote</th>
                                                                                        <th>Tipo</th>
                                                                                        <th>Modelo</th>               
                                                                                        <th>Superficie</th>
                                                                                        <th>Acciones</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr v-for="(lote, index) in manzanas.data[props.index].lotes" :key="index">
                                                                                        <td>{{ lote.nombre }}</td>
                                                                                        <td>{{ lote.tipo }}</td>
                                                                                        <td>{{ lote.modelo }}</td>
                                                                                        <td>{{ lote.superficie}}</td>
                                                                                        <td>
                                                                                            <v-layout>
                                                                                                <v-tooltip bottom>
                                                                                                    <v-btn
                                                                                                        slot="activator"
                                                                                                        color="verdeFayal"
                                                                                                        flat
                                                                                                        icon
                                                                                                        :disabled="disabled"
                                                                                                        @click="editLote(lote.id, props.item.id, props.index)"
                                                                                                    >
                                                                                                        <v-icon>edit</v-icon>
                                                                                                    </v-btn>
                                                                                                    <span>Editar</span>
                                                                                                </v-tooltip>
                                                                                                <v-tooltip bottom>
                                                                                                    <v-btn
                                                                                                        slot="activator"
                                                                                                        color="red"
                                                                                                        flat
                                                                                                        icon
                                                                                                        :disabled="disabled"
                                                                                                        @click="deleteLote(lote.id, props.item.id, props.index)"
                                                                                                    >
                                                                                                        <v-icon>delete</v-icon>
                                                                                                    </v-btn>
                                                                                                    <span>Eliminar</span>
                                                                                                </v-tooltip>
                                                                                            </v-layout>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </template>
                                                                    </v-data-table>
                                                                </v-card-text>
                                                                <v-card-actions>
                                                                    <v-spacer></v-spacer>
                                                                    <pagination 
                                                                        :limit="10" 
                                                                        :data="manzanas" 
                                                                        @pagination-change-page="getManzanas"
                                                                    ></pagination>
                                                                    <v-spacer></v-spacer>
                                                                </v-card-actions>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-container>
                                                </v-card>
                                            </v-tab-item>
                                            <v-tab-item value="tab-calles">
                                                <v-card flat>
                                                    <v-container>
                                                        <v-layout wrap>
                                                            <v-flex xs12>
                                                                <v-card-text>
                                                                    <v-toolbar color="white" flat tabs>
                                                                        <v-spacer></v-spacer>
                                                                        <v-tooltip bottom>
                                                                            <v-btn
                                                                                slot="activator"
                                                                                color="verdeFayal"
                                                                                dark
                                                                                @click="openAddCalleModal"
                                                                                :loading="addButtonLoading"
                                                                                :disabled="disabled"
                                                                            >
                                                                                <v-icon>add</v-icon>
                                                                            </v-btn>
                                                                            <span>Añadir calles</span>
                                                                        </v-tooltip>
                                                                    </v-toolbar>
                                                                    <v-data-table
                                                                        hide-actions
                                                                        :items="calles.data"
                                                                    >
                                                                        <template v-slot:headers="props">
                                                                            <tr>
                                                                                <th>Nombre</th>
                                                                                <th>Superficie (M2)</th>
                                                                                <th class="text-xs-right">Acciones</th>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-slot:items="props">
                                                                            <tr>
                                                                                <td>{{ props.item.nombre }}</td>
                                                                                <td>{{ props.item.superficie.toFixed(2) }}</td>
                                                                                <td class="text-xs-right">
                                                                                    <v-layout justify-end row>
                                                                                        <v-tooltip bottom>
                                                                                            <template v-slot:activator="{ on }">
                                                                                                <v-btn :disabled="disabled" flat icon color="verdeFayal" @click="editCalle(props.item.id)">
                                                                                                    <v-icon v-on="on">edit</v-icon>
                                                                                                </v-btn>
                                                                                            </template>
                                                                                            <span>Editar</span>
                                                                                        </v-tooltip>
                                                                                        <v-tooltip bottom>
                                                                                            <template v-slot:activator="{ on }">
                                                                                                <v-btn :disabled="disabled" flat icon color="red" @click="deleteCalle(props.item.id)">
                                                                                                    <v-icon v-on="on">delete</v-icon>
                                                                                                </v-btn>
                                                                                            </template>
                                                                                            <span>Eliminar</span>
                                                                                        </v-tooltip>
                                                                                    </v-layout>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                    </v-data-table>
                                                                </v-card-text>
                                                            </v-flex>
                                                        </v-layout>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <pagination 
                                                                :limit="10" 
                                                                :data="calles" 
                                                                @pagination-change-page="getCalles"
                                                            ></pagination>
                                                            <v-spacer></v-spacer>
                                                        </v-card-actions>
                                                    </v-container>
                                                </v-card>
                                            </v-tab-item>
                                            <v-tab-item value="tab-areas-verdes">
                                                <v-card flat>
                                                    <v-container>
                                                        <v-layout wrap>
                                                            <v-flex xs12>
                                                                <v-card-text>
                                                                    <v-toolbar color="white" flat tabs>
                                                                        <v-spacer></v-spacer>
                                                                        <v-tooltip bottom>
                                                                            <v-btn
                                                                                slot="activator"
                                                                                color="verdeFayal"
                                                                                dark
                                                                                @click="openAddAreasModal"
                                                                                :disabled="disabled"
                                                                            >
                                                                                <v-icon>add</v-icon>
                                                                            </v-btn>
                                                                            <span>Añadir áreas verdes</span>
                                                                        </v-tooltip>
                                                                    </v-toolbar>
                                                                    <v-data-table
                                                                        hide-actions
                                                                        :items="areas_verdes.data"
                                                                    >
                                                                        <template v-slot:headers="props">
                                                                            <tr>
                                                                                <th>Nombre</th>
                                                                                <th>Superficie (M2)</th>
                                                                                <th class="text-xs-right">Acciones</th>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-slot:items="props">
                                                                            <tr>
                                                                                <td>{{ props.item.nombre }}</td>
                                                                                <td>{{ props.item.superficie.toFixed(2) }}</td>
                                                                                <td class="text-xs-right">
                                                                                    <v-layout justify-end row>  
                                                                                         <v-tooltip bottom>
                                                                                            <template v-slot:activator="{ on }">
                                                                                                <v-btn :disabled="disabled" flat icon color="verdeFayal" @click="editArea(props.item.id)">
                                                                                                    <v-icon v-on="on">edit</v-icon>
                                                                                                </v-btn>
                                                                                            </template>
                                                                                            <span>Editar</span>
                                                                                        </v-tooltip>   

                                                                                        <v-tooltip bottom>
                                                                                            <template v-slot:activator="{ on }">
                                                                                                <v-btn :disabled="disabled" flat icon color="red" @click="deleteArea(props.item.id)">
                                                                                                    <v-icon v-on="on">delete</v-icon>
                                                                                                </v-btn>
                                                                                            </template>
                                                                                            <span>Eliminar</span>
                                                                                        </v-tooltip>
                                                                                    </v-layout>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                    </v-data-table>
                                                                </v-card-text>
                                                                <v-card-actions>
                                                                    <v-spacer></v-spacer>
                                                                    <pagination 
                                                                        :limit="10" 
                                                                        :data="areas_verdes" 
                                                                        @pagination-change-page="getAreasVerdes"
                                                                    ></pagination>
                                                                    <v-spacer></v-spacer>
                                                                </v-card-actions>
                                                            </v-flex>
                                                        </v-layout>
                                                    </v-container>
                                                </v-card>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-flex>
                                </v-layout>    
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-card>
        <!-- Modals -->
        <add-lotes-manzanas-modal :show.sync="showAddLotesModal" :manzana="currentMazana"/>
        <add-calles-to-lotificacion-modal :show.sync="showAddCalleModal" :lotificacion="lotificacion"/>
        <add-areas-to-lotificacion-modal :total="areas_verdes.total" :show.sync="showAddAreasModal" :lotificacion="lotificacion"/>
        <edit-calle-lotificacion-modal-form :show.sync="showEditCalleModal" :calle-id="currentCalleId"/>
        <edit-area-lotificacion-modal :show.sync="showEditAreaModal" :area-id="currentAreaId"/>
        <edit-lote-lotificacion-modal :show.sync="showEditLoteModal" :lote-id="currentLoteId"/>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    data() {
        return {
            lotificacion: {
                estado: {
                    information: {}
                }
            },
            manzanas: {
                data: []
            },
            calles: {
                data: []
            },
            areas_verdes: {
                data: []
            },
            showAddLotesModal: false,
            showAddCalleModal: false,
            showAddAreasModal: false,
            showEditAreaModal: false,
            showEditLoteModal: false,
            currentLoteId: null,
            currentIndexManzana: null,
            searchConfig: {
                url: "/prospectos/concretados",
                paginatedContainer: 'data',
                noDataText: "No se encontraron resultados.",
                label: "Nombre del proyecto",
                itemText: "nombre_proyecto",
                itemValue: "id",
                descriptionLimit: 60,
                disabled: false,
                useHeaders: true,
            },
            currentMazana: {},
            currentCalleId: null,
            currentAreaId: null,
            showEditCalleModal: false,
            selected: {},
            vTabs: 'tab-manzanas',
            addButtonLoading: false,
            vSnackBar: {
                visible: false,
                text: null
            }
        }
        },
        props: {
            lotificacionId: String
        },
        watch: {
            showAddAreasModal: function() {
                this.getAreasVerdes();
            },
            showEditAreaModal: function() {
                this.getAreasVerdes();
            },
            showAddCalleModal: function() {
                this.getCalles();
            },
            showEditCalleModal: function() {
                this.getCalles();
            },
            showAddLotesModal: function() {
                this.getManzanas();
            },
            vTabs: function(value) {
                if(value == 'tab-manzanas') {
                    this.getManzanas();
                }
                else if(value == 'tab-calles') {
                    this.getCalles();
                }
                else if(value == 'tab-areas-verdes') {
                    this.getAreasVerdes();
                }
            }
        },
        computed: {
            disabled: function() {
                return (this.lotificacion.estado.information.id == 9) ? false : true;
            }
        },
        methods: {
            getLotificacion: function() {
                axios.get(laravelRoute('proyectos.show', this.lotificacionId))
                .then(response => this.lotificacion = response.data)
                .catch(error => errorHandling(error));
            },
            getManzanas: function(page = 1) {
                axios.get(`/proyectos/${this.lotificacionId}/manzanas?page=${page}`)
                .then(response => this.manzanas = response.data)
                .catch(error => errorHandling(error));
            },
            openLotesExpand: function(id, index, page = 1) {
                let expand = !this.$refs.manzanasTable.expanded[id];
                axios.get(`/proyectos/${this.fraccionamientoId}/manzanas/${id}/lotes?page=${page}`)
                .then(response => {
                    this.manzanas.data[index].lotes = response.data;
                })
                .catch(error => errorHandling(error))
                .finally(() => this.$set(this.$refs.manzanasTable.expanded, id, expand));
            },
            getCalles: function(page = 1) {
                axios.get(`/proyectos/${this.lotificacionId}/calles?page=${page}`)
                .then(response => this.calles = response.data)
                .catch(error => errorHandling(error));
            },
            getAreasVerdes: function(page = 1) {
                axios.get(`/proyectos/${this.lotificacionId}/areas_verdes?page=${page}`)
                .then(response => this.areas_verdes = response.data)
                .catch(error => errorHandling(error));
            },
            generateManzanaName: function() {
                let number = this.manzanas.total + 1;
                var baseChar = "A".charCodeAt(0),
                    nombre = "";

                do {
                    number -= 1;
                    nombre = String.fromCharCode(baseChar + (number % 26)) + nombre;
                    number = (number / 26) >> 0;
                } while (number > 0);
                return nombre;
            },
            addManzanas: function() {
                this.addButtonLoading = true;
                axios.post(laravelRoute('proyectos.manzanas.store', this.lotificacionId), {
                    nombre: this.generateManzanaName()
                })
                .then(response => {
                    this.vSnackBar.text = response.data.message_text;
                    this.vSnackBar.visible = true;
                    this.getManzanas(this.manzanas.current_page);
                })
                .catch(error => errorHandling(error))
                .finally(() => this.addButtonLoading = false);
            },
            deleteManzana: function(manzanaId) {
                swal({
                    title: "¿Estás seguro?",
                    text: "Una vez eliminado no se podrá recuperar",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true
                }).then(result => {
                    if (result) {
                        axios.delete(laravelRoute('manzanas.destroy', manzanaId))
                        .then(response => {
                            this.getManzanas();
                            this.vSnackBar.text = response.data.message_text;
                            this.vSnackBar.visible = true;
                        })
                        .catch(error => errorHandling(error))
                    }
                })
            }, 
            openAddLotesModal: function(manzana) {
                this.showAddLotesModal = true;
                this.currentMazana = manzana;
            },
            openAddCalleModal: function() {
                this.showAddCalleModal = true;
            }, 
            openAddAreasModal: function() {
                this.showAddAreasModal = true;
            },
            editCalle: function(calleId) {
                this.currentCalleId = calleId;
                this.showEditCalleModal = true;
            },
            deleteCalle: function(calleId) {
                swal({
                    title: "¿Estás seguro?",
                    text: "Una vez eliminado no se podrá recuperar",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true
                }).then(result => {
                    if (result) {
                        axios.delete(laravelRoute('calles.destroy', calleId))
                        .then(response => {
                            this.getCalles();
                            this.vSnackBar.text = response.data.message_text;
                            this.vSnackBar.visible = true;
                        })
                        .catch(error => errorHandling(error))
                    }
                })
            }, 
            editArea: function(areaId) {
                this.currentAreaId = areaId;
                this.showEditAreaModal = true;
            },
            deleteArea: function(areaId) {
                swal({
                    title: "¿Estás seguro?",
                    text: "Una vez eliminado no se podrá recuperar",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true
                }).then(result => {
                    if (result) {
                        axios.delete(laravelRoute('areas_verdes.destroy', areaId))
                        .then(response => {
                            this.getAreasVerdes();
                            this.vSnackBar.text = response.data.message_text;
                            this.vSnackBar.visible = true;
                        })
                        .catch(error => errorHandling(error))
                    }
                })
            },
            deleteLote: function(loteId, manzanaId, manzanaIndex) {
                swal({
                    title: "¿Estás seguro?",
                    text: "Una vez eliminado no se podrá recuperar",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true
                }).then(result => {
                    if (result) {
                        axios.delete(laravelRoute('manzanas.lote.delete', loteId))
                        .then(response => {
                            this.openLotesExpand(manzanaId, manzanaIndex);
                            this.vSnackBar.text = response.data.message_text;
                            this.vSnackBar.visible = true;
                        })
                        .catch(error => errorHandling(error))
                    }
                })
            }, 
            editLote: function(loteId, manzanaId, manzanaIndex) {
                this.showEditLoteModal = true;
                this.currentLoteId = loteId;
                this.currentIndexManzana = manzanaIndex;
            }
        }, 
        created() {
            this.getLotificacion();
            this.getManzanas();
        }
    }
</script>
