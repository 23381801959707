<template>
  <v-container grid-list-md id="container-workers">
    <v-layout row wrap>
      <v-flex :xs12="!showFiltersModal" :xs9="showFiltersModal">
        <v-card>
          <v-toolbar color="white" flat>
            <v-toolbar-title>Prototipos de vivienda</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              color="verdeFayal"
              single-line
              label="Buscar"
              append-icon="search"
              v-model="search"
            ></v-text-field>
            <v-btn v-if="($root.can('new', permissions_prototipo) ? true : false)" dark color="verdeFayal" @click="openAddHouseholdModal">
              Crear
            </v-btn>
            <v-btn dark color="verdeFayal" outline @click="showFiltersModal = !showFiltersModal">
              <v-icon dark>filter_alt</v-icon>
            </v-btn>      
          </v-toolbar>
          <households-index 
            v-bind:search.sync="search" 
            :permissions="permissions_prototipo"
            :getting-data.sync="switchDataFilter"
            :filters.sync="filters"
          />
        </v-card>
      </v-flex>

      <v-flex xs3 v-if="showFiltersModal">
        <v-card>
          <v-toolbar color="white" flat>
            <v-toolbar-title>Filtros</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="showFiltersModal = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex>
                  <v-select 
                    color="verdeFayal"
                    :items="tiposItems" 
                    label="Tipo"
                    v-model="filters.filterTipo"                       
                    clearable
                  ></v-select>
                </v-flex>                  
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn dark color="verdeFayal" small flat outline @click="clearFilters">Limpiar</v-btn>
            <v-spacer></v-spacer>
            <v-btn dark color="verdeFayal" small @click="switchDataFilter = !switchDataFilter">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <!-- Modals  -->
      <create-households-modal v-bind:show.sync="showHouseholdModal"></create-households-modal>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      showHouseholdModal: false,
      showFiltersModal: false,
      switchDataFilter: false,
      filters: {
        filterTipo: null,          
      },
      tiposItems: ['Regular', 'Irregular'],
      vBtnSave: {
        loading: false
      },
    };
  },
  props:['permissions_prototipo'],
  methods: {
    openAddHouseholdModal: function() {
      this.showHouseholdModal = true;
    },
    clearFilters: function() {
      this.filters.filterTipo = null;
    }  
  },
};
</script>
