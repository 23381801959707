<template>
  <div>
    <v-layout>
      <v-container>
        <v-card>
          <v-flex xs12>
            <v-toolbar color="white" flat tabs>
              <v-toolbar-title>Nóminas</v-toolbar-title>
            </v-toolbar>
            <v-data-table disable-initial-sort :items="nominas.data" hide-actions>
              <template slot="headers">
                <th>Nombre de nómina</th>
                <th>Estado</th>
                <th>Total de nómina</th>
                <th>Fecha de creación</th>
                <th>Acciones</th>
              </template>
              <template slot="items" slot-scope="props">
                <td>{{ props.item.nombre_nomina }}</td>
                <td>{{ props.item.nomina.estatus }}</td>
                <td>{{ props.item.nomina.total }}</td>
                <td>{{ props.item.nomina.fecha_creacion }}</td>
                <td>
                  <v-layout>
                    <v-tooltip bottom>
                      <v-btn icon flat dark small slot="activator" color="verdeFayal" 
                        :href="`/areas_nominas/${props.item.id}/edit`"
                      >
                        <v-icon>visibility</v-icon>
                      </v-btn>
                      <span>Ver/editar datos</span>
                    </v-tooltip>
                  </v-layout>
                </td>
              </template>
            </v-data-table>
          </v-flex>
        </v-card>
      </v-container>
    </v-layout>
  </div>
</template>

<script>
import { errorHandling, laravelRoute } from "../../lib/utilities";

export default {
  data() {
    return {
      nominas: {}
    }
  },
  methods: {
    getNominas: function (page = 1) {
      axios.get(laravelRoute('areas_nominas.index'))
      .then(response => this.nominas = response.data)
      .catch(error => errorHandling(error));
    }
  },
  created() {
    this.getNominas();
  },
}
</script>