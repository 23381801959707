<template>
  <v-layout row justify-center>
    <v-dialog :value="dialog" persistent max-width="700px">
      <template v-slot:activator="{ on }">
        <v-btn class="fyl-btnCreate" v-on:click="$emit('open')">Crear</v-btn>
      </template>
      <v-card>
        <form action v-on:submit.prevent.once="createUrbanizacion()">
          <v-toolbar color="white" flat>
            <v-spacer></v-spacer>
            <v-toolbar-title class="primary-title">{{ this.prospecto.id ? "Editar" : "Crear" }} prospecto</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="$emit('close')">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md6>
                  <v-text-field
                    color="#1ABA24"
                    label="Nombre del Proyecto"
                    v-model="prospecto.nombre_proyecto"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field color="#1ABA24" label="Dirección" v-model="prospecto.direccion"></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field color="#1ABA24" label="Fecha" type="date" v-model="prospecto.fecha"></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-textarea color="#1ABA24" label="Descripción" v-model="prospecto.descripcion" counter="250" :maxlength="max"></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="verdeFayal" outline type="submit" :disabled="$store.state.saveDisabled" flat>Guardar</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: ["prospectos", "prospecto", "dialog"],

  data: function() {
    return {
      max: 250
    };
  },

  methods: {
    createUrbanizacion() {
      if (this.prospecto.id) {
        axios
          .patch(route("prospectos.update", this.prospecto.id), this.prospecto)
          .then(response => {
            if (response.data.message_text)
              alertify.success(response.data.message_text);
            this.$root.cleanStore();
            this.$emit("close");
          })
          .catch(error => errorHandling(error))
          .finally(() =>
            setTimeout(() => {
              window.location.href = "/prospectos";
            })
          );
      } else {
        axios
          .post("/prospectos", this.prospecto)
          .then(response => {
            this.prospectos.push(response.data.data);
            if (response.data.message_text)
              alertify.success(response.data.message_text);
            this.$root.cleanStore();
            this.$emit("close");
          })
          .catch(error => errorHandling(error))
          .finally(() => window.location.href = "/prospectos");
      }
    }
  },

  computed: {
    FileUpload() {
      return this.$store.state.urlFileUpload;
    }
  },

  watch: {
    FileUpload() {
      this.prospecto.planos = this.FileUpload;
    }
  }
};
</script>

<style>
.fyl-btnCreate {
  color: #fff;
  background: #1aba24 !important;
}
.v-input--is-label-active label {
  color: #1aba24 !important;
}
.fyl-btnClose {
  color: #ff5252 !important;
}
.fyl-btnSave {
  color: #fff !important;
  background: #1aba24;
  border-radius: 6px;
}
</style>