<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="850px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">{{mode}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-select
                  :items="creadosProps"
                  label="Nombre del proveedor"
                  color="#1ABA24"
                  item-text="nombre_completo"
                  item-value="id"
                  return-object
                  v-model="factura.proveedor"
                  @change="setPlazo"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  color="#1ABA24"
                  label="Número de la factura"
                  v-model="factura.numero_factura"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  color="#1ABA24"
                  label="Monto"
                  type="number"
                  min="1"
                  v-model="factura.monto"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                  color="#1ABA24"
                  label="Fecha de emisión"
                  type="date"
                  v-model="factura.fecha_emision"
                  id="fechaEmision"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm6 md6 class="tooltp">
                <v-select
                  :items="plazos"
                  item-text="nombre"
                  item-value="dias"
                  label="Plazo"
                  color="#1ABA24"
                  v-model="factura.plazo"
                  :readonly="checkPlazo"
                  @change="changePlazo"
                ></v-select>
                <span
                  v-if="factura.fecha_emision == '' || checkPlazo"
                  class="tooltiptext"
                >Introducir Fecha de Emisión</span>
              </v-flex>

              <v-flex xs12 sm6 md6>
                <v-text-field
                  color="#1ABA24"
                  label="Fecha de vencimiento"
                  type="date"
                  v-model="dateEnd"
                  readonly="readonly"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-textarea
                  color="#1ABA24"
                  label="Comentarios"
                  v-model="factura.comentario"
                  counter="250"
                  :maxlength="max"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="verdeFayal" flat  @click="createInvoice" :loading="vBtnSave.loading"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { errorHandling } from "../../lib/utilities";
import moment from "moment";
export default {
  props: ["factura", "facturas", "facturaStore", "show", "prov", "mode"],
  data() {
    return {
      creadosProps: this.prov,
      localShow: false,
      newprov: {},
      provId: "",
      plazo: '',
      dateEnd: '',
      valid: false,
      checkPlazo: true,
      facturaProps: this.factura,
      plazos: [
        { nombre: "De contado", dias: "0" },
        { nombre: "9 días", dias: "9" },
        { nombre: "30 días", dias: "30" },
        { nombre: "45 días", dias: "45" },
        { nombre: "60 días", dias: "60" },
        { nombre: "90 días", dias: "90" }
      ],
      max: 250,
      vBtnSave: {
        loading: false
      },
      vSnackBar: {
        visible: false,
        text: null
      }
    };
  },

  methods: {
    setPlazo: function() {
      this.factura.plazo = this.factura.proveedor.tipo_credito;
      this.plazo = this.factura.proveedor.tipo_credito;
      this.factura.proveedor = this.factura.proveedor.nombre_completo;
    },
    createInvoice: function() {
      this.vBtnSave.loading = true;
      if (this.factura.id) {
          axios.patch(route("facturas.update", this.factura.id), this.factura)
          .then(response => {
              alertify.success(response.data.message_text);
              this.localShow = false;
          })
          .catch(error => errorHandling(error))
          .finally(() =>  this.vBtnSave.loading = false);
      } else {
          axios.post("/facturas", this.factura)
          .then(response => {
            alertify.success(response.data.message_text);
            this.localShow = false;
          })
          .catch(e => errorHandling(e))
          .finally(() =>  this.vBtnSave.loading = false);
      }
    },
    changePlazo: function() {
      this.plazo = this.factura.plazo;
    }
  },

  computed: {
    checkDateStart: function() {
      return this.factura.fecha_emision;
    },
    checkDateEnd: function() {
      return this.dateEnd;
    },
    checkPlazoChange: function() {
      return this.plazo;
    }
  },
  watch: {
    checkDateStart: function() {
      this.checkPlazo = false;
      this.dateEnd = moment(this.checkDateStart)
        .add(this.factura.plazo, "days")
        .format("Y-MM-DD");
    },
    checkDateEnd: function() {
      this.factura.fecha_vigencia = this.checkDateEnd;
    },
    checkPlazoChange: function() {
      if (this.factura.fecha_emision) {
        this.factura.plazo = this.checkPlazoChange;
        this.dateEnd = moment(this.factura.fecha_emision)
          .add(this.checkPlazoChange, "days")
          .format("Y-MM-DD");
      }
    },
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
  }
};
</script>

<style>
.fyl-btnCreate {
  color: #fff;
  background: #1aba24;
}

.tooltp .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #cacaca;
  color: rgb(0, 0, 0);
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: relative;
  z-index: 1;
  top: -12px;
}

.tooltp:hover .tooltiptext {
  visibility: visible;
}
</style>