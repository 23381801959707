<template>
  <div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Administrar proyectos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="vTabs" centered slider-color="verdeFayal">
            <v-tab href="#datos-maquinaria-tab">Datos del proyecto</v-tab>
            <v-tab href="#datos-proyectos-tab" v-if="maquinaria.proyectos.length">Proyectos</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="vTabs">
        <v-tab-item value="datos-maquinaria-tab">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-select
                  color="verdeFayal"
                  :items="prospectos"
                  item-value="id"
                  item-text="nombre_proyecto"
                  label="Proyecto en que se utiliza la máquina"
                  v-model="proyecto_perteneciente"
                  return-object
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 v-if="(maquinaria.estado.information.estado == 'Ocupada')">
                <v-checkbox v-model="liberar" label="Liberar del proyecto"></v-checkbox>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="proyecto_perteneciente">
              <v-flex md6>
                <searcher
                  :configuration="searchOperador"
                  :itemSelected.sync="proyecto_perteneciente.trabajador"
                ></searcher>
              </v-flex>
              <v-flex md6>
                <v-text-field v-model="proyecto_perteneciente.horas_trabajadas" type="number" min="0" required label="Horas trabajadas"></v-text-field>
              </v-flex>
              <v-flex md6>
                <v-text-field v-model="proyecto_perteneciente.costo_operador" type="number" min="0" required label="Costo de operador por hora"></v-text-field>
              </v-flex>
              <v-flex md6>
                <v-text-field v-model="proyecto_perteneciente.gasto_diesel" type="number" min="0" required label="Gasto de diesel por hora (litros)"></v-text-field>
              </v-flex>
              <v-flex md6>
                <v-text-field v-model="proyecto_perteneciente.fecha_termino" type="date" required label="Fecha de término"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <v-tab-item v-if="maquinaria.proyectos.length" value="datos-proyectos-tab">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex md12> 
                <v-data-table :headers="headers" :items="maquinaria.proyectos" class="elevation-1">
                  <template v-slot:items="props">
                    <td>{{ props.item.proyecto.nombre_proyecto }}</td>
                    <td>{{ props.item.trabajador.nombre_completo }}</td>
                    <td>{{ props.item.horas_trabajadas }}</td>
                    <td>$ {{ props.item.costo_operador | currency }}</td>
                    <td>{{ props.item.gasto_diesel }}</td>
                    <td>{{ props.item.fecha_termino }}</td>
                    <td>{{ props.item.horas_trabajadas * props.item.gasto_diesel }}</td>
                  </template>
                </v-data-table>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs-items>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="vTabs != 'datos-proyectos-tab'" dark color="verdeFayal" @click="updateMaquinaria" flat :loading="vBtnSave.loading">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        proyecto_perteneciente: {
          trabajador: {
            id: null
          },
          id: null,
          horas_trabajadas: null,
          costo_operador: null,
          gasto_diesel: null,
          fecha_termino: null,
          slug: 'lorem'
        },
        liberar: false,
        operador: {
          id: null
        },
        searchOperador: {
          url: "/trabajadores",
          paginatedContainer: 'data',
          noDataText: "No se encontraron resultados.",
          label: "Operador",
          itemText: "nombre_completo",
          itemValue: "id",
          descriptionLimit: 60,
          disabled: false,
          useHeaders: true,
        },
        maquinaria: {
          proyecto_actual: {
            operador: null,
            horas_trabajadas: null,
            costo_operador: null,
            gasto_diesel: null,
            fecha_termino: null,
            slug: 'lorem'
          },
          estado:{
            information:{
              estado:''
            }
          },
          proyectos: []
        },
        status: [],
        localShow: false,
        vTabs: 'datos-maquinaria-tab',
         headers: [
          { text: 'Proyecto', value: 'proyecto' },
          { text: 'Operador', value: 'operador' },
          { text: 'Horas trabajadas', value: 'horas' },
          { text: 'Costo de operador por hora', value: 'costo_operador' },
          { text: 'Gasto de diesel', value: 'gasto_diesel' },
          { text: 'Fecha de término', value: 'fecha_termino' },
          { text: 'Total de diesel', value: 'diesel' },
        ],
        vBtnSave: {
          loading: false
        },
        vSnackBar: {
          visible: false,
          text: null
        }
      }
    },
    props: {
      show: Boolean,
      prospectos: Array,
      maquinariaId: Number,
    },
    watch: {
      show: function (value) {
        this.localShow = value
      },
      maquinariaId: function (value) {
        this.getMaquinaria();
      },
      localShow: function (value) {
        this.$emit('update:show', value);
      }
    },
    methods: {
      getMaquinaria: function () {
        axios.get(laravelRoute('maquinaria.show', this.maquinariaId))
        .then(response => {
          this.maquinaria = response.data;
          this.proyecto_perteneciente = (this.maquinaria.proyecto_actual) ? this.maquinaria.proyecto_actual : null; 
        })
        .catch(error => errorHandling(error));
      },
      updateMaquinaria: function () {
        this.vBtnSave.loading = true;
        let operador = (typeof this.proyecto_perteneciente.trabajador != 'undefined') ? this.proyecto_perteneciente.trabajador.id : null;
        axios.patch('/maquinaria/proyecto/' + this.maquinariaId, {
          nombre: this.maquinaria.nombre,
          descripcion: this.maquinaria.descripcion,
          operador: operador,
          gasto_diesel: this.proyecto_perteneciente.gasto_diesel,
          costo_operador: this.proyecto_perteneciente.costo_operador,
          proyecto_perteneciente: this.proyecto_perteneciente,
          horas_trabajadas: this.proyecto_perteneciente.horas_trabajadas,
          fecha_termino: this.proyecto_perteneciente.fecha_termino,
          proyecto_maquinaria: this.proyecto_perteneciente.id,
          liberar: this.liberar
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.vBtnSave.loading = false;
          this.liberar = false;
          this.getMaquinaria();
        })
        .catch(error => {
          this.vBtnSave.loading = false;
          errorHandling(error)
        });
      }
    },
    created(){
      this.getMaquinaria();
    }
  }
</script>