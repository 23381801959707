<template>
  <section v-if="build" style="position: absolute; left: 15px; bottom: 10px; color: #b8c7ce;">
    <p style="margin-bottom: 6px;"><strong>TEST ENV</strong></p>
    <p>Build Number: <a v-bind:href="build.build_url">#{{ build.build_num }}</a></p>
    <p>Made by: {{ build.author_name }}</p>
    <p id="hm-date">{{ dateForHumans(build.author_date) }}</p>
  </section>
</template>

<style scoped>
p {
  margin-bottom: 2px;
}
a {
  color: #66BB6A;
}
p#hm-date::first-letter {
  text-transform: capitalize;
}
</style>

<style>
.sidebar {
  padding-bottom: 140px !important;
}
</style>

<script>
import { errorHandling, laravelRoute } from "../lib/utilities";
import moment from "moment";

export default {
  name: 'BuildInformation',
  data() {
    return {
      build: undefined
    }
  },
  methods: {
    getLastBuild: function () {
      return axios.get(laravelRoute('builds.latest'))
        .then(response => response.data.data ? response.data.data : response.data)
        .catch(error => errorHandling(error))
    },
    dateForHumans: function (dateString) {
      return moment(dateString).locale('en').fromNow()
    }
  },
  created() {
    this.getLastBuild()
      .then(build => this.build = build)
  }
}
</script>
