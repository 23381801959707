<template>
  <v-layout>
    <v-container>
      <v-card>
        <v-flex xs12>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Vendedores</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              color="verdeFayal"
              single-line
              label="Buscar"
              append-icon="search"
              v-model="search"
            ></v-text-field>
            <vendedor-modal v-if="$root.can('new', permissions) ? true : false" :vendedor="vendedor" :dialog="dialog" @close="cleanForm()" @open="dialog = true"></vendedor-modal>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            disable-initial-sort
            :items="vendedores.data"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.nombre }} {{ props.item.apellido_paterno }} {{ props.item.apellido_materno }}</td>
              <td>{{ props.item.telefono }}</td>
              <td>{{ props.item.email }}</td>
              <td>{{ props.item.created_at }}</td>
              <td>{{ props.item.inmobiliaria ? props.item.inmobiliaria.inmobiliaria : ''}}</td>
              <td>
                <v-switch :disabled="!$root.can('status', permissions)" class="fyl-vendedor-switch" color="verdeFayal" v-model="props.item.estado.information.estado" @change="changeStatus(props.item.id, props.item.estado.information.estado)"></v-switch>
              </td>
              <td class="fyl-vendedor-td">
                <v-tooltip bottom>
                  <v-btn
                    @click="EditVendedor(props.item.id)"
                    icon
                    flat
                    dark
                    small
                    slot="activator"
                    color="info"
                    :disabled="!$root.can('update', permissions)"
                  >
                    <v-icon color="verdeFayal">edit</v-icon>
                  </v-btn>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn
                    :disabled="!$root.can('files', permissions)"
                    flat
                    icon
                    slot="activator"
                    color="warning"
                    @click="elementId = props.item.id; dialogFile = true;"
                  >
                    <v-icon>folder</v-icon>
                  </v-btn>
                  <span>Archivos</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn
                    @click="deleteVendedor(props.item.id)"
                    icon
                    flat
                    dark
                    small
                    slot="activator"
                    color="error"
                    :disabled="!$root.can('remove', permissions)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination
              :limit="vPaginator.limit"
              :data="vendedores"
              @pagination-change-page="getVendedores"
            ></pagination>
          </v-card-actions>
        </v-flex>
      </v-card>
      <!-- modals -->
    </v-container>
    <upload-files-modal
      @close="dialogFile = false"
      element_type="App\Vendedor"
      :element_id="elementId"
      :dialog="dialogFile"
    ></upload-files-modal>
  </v-layout>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import { errorHandling, laravelRoute } from "../../lib/utilities";

export default {
  props: ["permissions"],
  data() {
    return {
      search: null,
      dialogFile: false,
      dialog: false,
      elementId: "",
      estados: [],
      status: "",
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Teléfono", value: "telefono" },
        { text: "Correo", value: "email" },
        { text: "Fecha de registro", value: "created_at" },
        { text: "Inmobiliaria", value: "inmobiliaria" },
        { text: "Estado", value: "estado", sortable: false },
        { text: "Acciones", sortable: false }
      ],
      currentVendedorId: null,
      vPaginator: {
        limit: 10
      },
      vendedor: {},
      vendedores: {
        data: []
      }
    };
  },

  mounted() {
    this.getVendedores();
  },

  methods: {
    cleanForm() {
      this.vendedor = {};
      this.dialog = false;
    },
    changeStatus(vendedorId, status){
      let statusId;
      status == true ? statusId = 18 : statusId = 19;
      axios
        .patch(`/vendedor/status/${vendedorId}`, {estado: statusId})
        .then(response => {
          
        })
        .catch(error => errorHandling(error));
    },
    getVendedores: function(page = 1) {
      axios
        .get(`/vendedores?page=${page}&query=${this.search}`)
        .then(response => {
          this.vendedores = response.data;
        })
        .catch(error => errorHandling(error));
    },
    deleteVendedor: function(vendedoresId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios
            .delete(laravelRoute("vendedores.destroy", vendedoresId))
            .then(response => {
              this.getVendedores();
              alertify.success(response.data.message_text);
              setTimeout(() => {
                window.location.href = "/vendedores";
              }, 300);
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      });
    },

    EditVendedor: function(vendedorId) {
      axios.get(route("vendedores.edit", vendedorId)).then(response => {
        this.vendedor = response.data;
        this.dialog = true;
      });
    }
  }
};
</script>

<style>
  .fyl-vendedor-td{
    min-width: 244px;
  }
  .fyl-vendedor-td .fyl-vendedor-switch{
    display: inline-block;
  }
</style>