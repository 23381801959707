<template>
  <v-layout>
    <v-container>
      <v-card>
        <v-flex xs12>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>{{ currentPath != "/trabajos_externos" ? "Plan de trabajo de obra" : "Trabajos externos" }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-text-field
              color="verdeFayal"
              single-line
              label="Buscar"
              append-icon="search"
              v-model="search"
            ></v-text-field>
            <v-btn
              v-if="($root.can('new', permissions_concepto) ? true : false)"
              class="fyl-btnCreate"
              @click="openAddConceptsModal"
            >
              Crear
            </v-btn>
            <v-btn dark color="verdeFayal" outline  @click="exportToExcel">
              <v-icon dark>import_export</v-icon>
            </v-btn>
          </v-toolbar>
          <concepts-index 
            v-bind:search.sync="search" 
            v-bind:updateConceptos="showConceptsModal" 
            :permissions="permissions_concepto" 
            :permissions_rubro="permissions_rubro"
            :current-path="currentPath"
          />
        </v-flex>
        <!-- Modals  -->
        <create-concepts-modal 
          v-bind:show.sync="showConceptsModal" 
          :type="(currentPath != '/trabajos_externos' ? 'obra' : 'trabajos_externos')" 
          :permissions="permissions_rubro"
        />
      </v-card>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      showConceptsModal: false,
      vBtnSave: {
        loading: false
      },

      filters: {
        origen: (this.currentPath != '/trabajos_externos') ? 'obra' : 'trabajos_externos'
      },
    };
  },
  props:['permissions_concepto', 'permissions_rubro'],
  methods: {
    openAddConceptsModal: function() {
      this.showConceptsModal = true;
    },
    exportToExcel: function() {
      axios.post('/conceptos/export', {
        ...this.filters,
        to_excel: true
      }, {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'conceptosObra.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
    },
  },
  computed: {
    currentPath: function() {
      return this.$route.path
    }
  },
};
</script>
