var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            value: _vm.dialog,
            persistent: "",
            "max-width": "1000px",
            "hide-overlay": ""
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "verdeFayal" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-toolbar-title", [_vm._v("Aplicar Garantías")])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      "hide-actions": true,
                      "disable-initial-sort": true,
                      headers: _vm.garantias.headers,
                      items: _vm.garantias.items
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "headers",
                        fn: function(props) {
                          return [
                            _c(
                              "tr",
                              _vm._l(props.headers, function(header) {
                                return _c(
                                  "th",
                                  {
                                    key: header.text,
                                    class: [
                                      "column sortable",
                                      _vm.pagination.descending
                                        ? "desc"
                                        : "asc",
                                      header.value === _vm.pagination.sortBy
                                        ? "active"
                                        : ""
                                    ],
                                    on: {
                                      click: function($event) {
                                        return _vm.changeSort(header.value)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(header.text) +
                                        "\n                "
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        }
                      },
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c(
                              "tr",
                              {
                                attrs: { active: props.selected },
                                on: {
                                  click: function($event) {
                                    props.selected = !props.selected
                                  }
                                }
                              },
                              [
                                _c("td", [
                                  _vm._v(_vm._s(props.item.information.nombre))
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(props.item.information.descripcion)
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(props.item.information.meses))
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(props.item.created_at))
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(props.item.estado.information.estado)
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(props.item.estado.created_at))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled:
                                                props.item.estado.information
                                                  .id != 40,
                                              flat: "",
                                              icon: "",
                                              color: "verdeFayal"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.acceptGaranty(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("done")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Aplica")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled:
                                                props.item.estado.information
                                                  .id != 40,
                                              flat: "",
                                              icon: "",
                                              color: "red"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.denyGaranty(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("clear")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("No aplica")])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.garantias.selected,
                      callback: function($$v) {
                        _vm.$set(_vm.garantias, "selected", $$v)
                      },
                      expression: "garantias.selected"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }