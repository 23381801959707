<template>
    <div>
        <v-card flat>
            <v-toolbar color="verdeFayal" dark extended flat>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn flat icon :href="`/fraccionamientos`">
                            <v-icon>keyboard_arrow_left</v-icon>
                        </v-btn>
                    </template>
                    <span>Regresar</span>
                </v-tooltip>
            </v-toolbar>

            <v-layout row pb-2>
                <v-flex xs10 offset-xs1>
                    <v-card class="card--flex-toolbar">
                        <v-toolbar card prominent>
                            <v-spacer></v-spacer>
                            <v-toolbar-title class="body-2 grey--text">Reporte de costos de {{info.fraccionamiento.nombre_proyecto}}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <v-container grid-list-md>
                                <v-layout>
                                    <v-flex xs4>
                                        <v-card dark color='#75d57b'>
                                            <v-layout>
                                                <v-flex xs5>
                                                    <v-layout  align-center justify-center row fill-height>
                                                        <v-img
                                                            src="/fonts/local/construction.svg"
                                                            height="50px"
                                                            contain
                                                        ></v-img>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs7>
                                                    <v-card-title primary-title>
                                                        <div>
                                                            <div class="headline total-title">$ {{ info.total_materiales | currency }}</div>
                                                            <div>Material</div>
                                                        </div>
                                                    </v-card-title>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs4>
                                        <v-card dark color='#75d57b'>
                                            <v-layout>
                                                <v-flex xs5>
                                                    <v-layout  align-center justify-center row fill-height>
                                                        <v-img
                                                            src="/fonts/local/manpower.svg"
                                                            height="50px"
                                                            contain
                                                        ></v-img>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs7>
                                                    <v-card-title primary-title>
                                                        <div>
                                                            <div class="headline total-title">$ {{ info.total_mano_de_obra | currency }}</div>
                                                            <div>Mano de obra</div>
                                                        </div>
                                                    </v-card-title>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs4>
                                        <v-card dark color='#75d57b'>
                                            <v-layout>
                                                <v-flex xs5>
                                                    <v-layout  align-center justify-center row fill-height>
                                                        <v-img
                                                            src="/fonts/local/total.svg"
                                                            height="50px"
                                                            contain
                                                        ></v-img>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs7>
                                                    <v-card-title primary-title>
                                                        <div>
                                                            <div class="headline total-title">$ {{ info.total_mano_de_obra + info.total_materiales | currency }}</div>
                                                            <div>Total</div>
                                                        </div>
                                                    </v-card-title>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                                </v-layout>

                                <v-divider></v-divider>
                                
                                <v-layout row wrap>
                                    <v-flex>
                                        <v-tabs v-model="vTabs" fixed-tabs centered color="white" slider-color="#1aba24">
                                            <v-tab href="#tab-manzanas">Manzanas</v-tab>
                                            <v-tab href="#tab-calles">Calles</v-tab>
                                            <v-tab href="#tab-areas-verdes">Áreas verdes</v-tab>
                                        </v-tabs>
                                        <v-tabs-items v-model="vTabs">
                                            <v-tab-item value="tab-manzanas">
                                                <v-card flat>
                                                    <v-container>
                                                        <v-card-text>
                                                            <v-data-table
                                                                hide-actions
                                                                :items="manzanas.data"
                                                                :expand="expandManzana"
                                                                ref="manzanasTable"
                                                            >
                                                                <template v-slot:headers="props">
                                                                    <tr>
                                                                        <th>Nombre</th>
                                                                        <th>Total de lotes</th>
                                                                        <th>Subtotal de materiales</th>
                                                                        <th>Subtotal de mano de obra</th>
                                                                        <th class="text-xs-right"></th>
                                                                    </tr>
                                                                </template>
                                                                <template v-slot:items="props">
                                                                    <tr>
                                                                        <td>Manzana {{props.item.nombre}}</td>
                                                                        <td>
                                                                            <span style="background-color: #ff5617;" class="badge badge-secondary">
                                                                                {{ props.item.lotes_count }}
                                                                            </span>
                                                                        </td>
                                                                        <td>$ {{ props.item.total_de_materiales | currency }}</td>
                                                                        <td>$ {{ props.item.total_mano_de_obra | currency }}</td>
                                                                        <td class="text-xs-right">
                                                                            <v-btn flat icon @click="openLotesExpand(props.item.id, props.index)">
                                                                                <v-icon>{{(props.expanded) ? 'expand_less' : 'expand_more'}}</v-icon>
                                                                            </v-btn>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                                <template v-slot:expand="props">
                                                                    <table class="table table-striped table-hover table-sm">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Lote</th>
                                                                                <th>Subtotal de materiales</th>
                                                                                <th>Subtotal de mano de obra</th>
                                                                                <th>Acciones</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-for="(lote, index) in manzanas.data[props.index].lotes" :key="index">
                                                                                <td>{{ lote.nombre }}</td>
                                                                                <td>$ {{ lote.total_de_materiales | currency }}</td>
                                                                                <td>$ {{lote.total_de_mano_de_obra | currency }} </td>
                                                                                <td>
                                                                                    <v-tooltip bottom>
                                                                                        <template>
                                                                                            <v-btn :href="`/fraccionamientos/${fraccionamientoId}/reporte/desglose?item_id=${lote.id}&item_type=lote`" icon flat dark small slot="activator" color="verdeFayal">
                                                                                                <v-icon>vertical_split</v-icon>
                                                                                            </v-btn>
                                                                                        </template>
                                                                                        <span>Ver desglose por concepto</span>
                                                                                    </v-tooltip>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </template>
                                                            </v-data-table>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <pagination 
                                                                :limit="10" 
                                                                :data="manzanas" 
                                                                @pagination-change-page="getManzanas"
                                                            ></pagination>
                                                            <v-spacer></v-spacer>
                                                        </v-card-actions>
                                                    </v-container>
                                                </v-card>
                                            </v-tab-item>
                                            <v-tab-item value="tab-areas-verdes">
                                                <v-card flat>
                                                    <v-container>
                                                        <v-card-text>
                                                            <v-data-table
                                                                hide-actions
                                                                :items="areas_verdes.data"
                                                            >
                                                                <template v-slot:headers="props">
                                                                    <tr>
                                                                        <th>Nombre</th>
                                                                        <th>Subtotal de materiales</th>
                                                                        <th class="text-xs-right">Acciones</th>
                                                                    </tr>
                                                                </template>
                                                                <template v-slot:items="props">
                                                                    <td>{{props.item.nombre}}</td>
                                                                    <td>$ {{props.item.total_de_materiales | currency}}</td>
                                                                    <td class="text-xs-right"></td>
                                                                </template>
                                                            </v-data-table>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <pagination 
                                                                :limit="10" 
                                                                :data="areas_verdes" 
                                                                @pagination-change-page="getAreasVerdes"
                                                            ></pagination>
                                                            <v-spacer></v-spacer>
                                                        </v-card-actions>
                                                    </v-container>
                                                </v-card>
                                            </v-tab-item>
                                            <v-tab-item value="tab-calles">
                                                <v-card flat>
                                                    <v-container>
                                                        <v-card-text>
                                                            <v-data-table
                                                                hide-actions
                                                                :items="calles.data"
                                                            >
                                                                <template v-slot:headers="props">
                                                                    <tr>
                                                                        <th>Nombre</th>
                                                                        <th>Subtotal de materiales</th>
                                                                        <th class="text-xs-right"></th>
                                                                    </tr>
                                                                </template>
                                                                <template v-slot:items="props">
                                                                    <td>{{props.item.nombre}}</td>
                                                                    <td>$ {{props.item.total_de_materiales | currency}}</td>
                                                                    <td class="text-xs-right"></td>
                                                                </template>
                                                            </v-data-table>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <pagination 
                                                                :limit="10" 
                                                                :data="calles" 
                                                                @pagination-change-page="getCalles"
                                                            ></pagination>
                                                            <v-spacer></v-spacer>
                                                        </v-card-actions>
                                                    </v-container>
                                                </v-card>
                                            </v-tab-item>
                                        </v-tabs-items>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-card>
        <show-materials-by-distribucion-modal 
            :show.sync="showMaterialsModal" 
            :distribucion-id.sync="currentDistribuicionId"
            :distribucion-type.sync="currentDistribuicionType"
        />
    </div>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    data: () => ({
        info: {
            fraccionamiento: {},
        },
        vTabs: 'tab-manzanas',
        manzanas: {
            data: []
        },
        areas_verdes: {
            data: []
        },
        calles: {
            data: []
        },
        expandManzana: false,
        currentDistribuicionId: null,
        currentDistribuicionType: null,
        showMaterialsModal: false
    }),
    props: {
        fraccionamientoId: String
    },
    methods: {
      getFraccionamiento: function() {
        axios.get(laravelRoute('fraccionamientos.reporte', this.fraccionamientoId))
        .then(response => this.info = response.data)
        .catch(error => errorHandling(error));
      },
      getManzanas: function(page = 1){
        axios.get(`/fraccionamientos/${this.fraccionamientoId}/manzanas?page=${page}`)
        .then(response => this.manzanas = response.data)
        .catch(error => errorHandling(error));
      },
      getAreasVerdes: function(page = 1) {
        axios.get(`/fraccionamientos/${this.fraccionamientoId}/areas_verdes?page=${page}`)
        .then(response => this.areas_verdes = response.data)
        .catch(error => errorHandling(error));
      },
      getCalles: function(page = 1) {
        axios.get(`/fraccionamientos/${this.fraccionamientoId}/calles?page=${page}`)
        .then(response => this.calles = response.data)
        .catch(error => errorHandling(error));
      },
      openMaterialsModal: function(id, distribuidor) {
        this.currentDistribuicionId = id;
        this.currentDistribuicionType = distribuidor;
        this.showMaterialsModal = true;
      },
      openLotesExpand: function(id, index, page = 1) {
        let expand = !this.$refs.manzanasTable.expanded[id];
        axios.get(`/fraccionamientos/${this.fraccionamientoId}/manzanas/${id}/lotes?page=${page}`)
        .then(response => {
            this.manzanas.data[index].lotes = response.data;
        })
        .catch(error => errorHandling(error))
        .finally(() => this.$set(this.$refs.manzanasTable.expanded, id, expand));
      }
    },
    created() {
        this.getFraccionamiento();
        this.getManzanas();
        this.getAreasVerdes();
        this.getCalles();
    }
  }
</script>
