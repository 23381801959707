var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c("v-toolbar", {
        attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" }
      }),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "pb-2": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs10: "", "offset-xs1": "" } },
            [
              _c(
                "v-card",
                { staticClass: "card--flex-toolbar", attrs: { flat: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", prominent: "" } },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-title",
                        { staticClass: "body-2 grey--text" },
                        [_vm._v("Configuraciones generales")]
                      ),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        _vm._l(_vm.configuraciones.data, function(
                          configuracion
                        ) {
                          return _c(
                            "v-flex",
                            {
                              key: configuracion.id,
                              attrs: { md6: "", xs12: "" }
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-toolbar",
                                    { attrs: { card: "" } },
                                    [
                                      _c(
                                        "v-toolbar-title",
                                        { attrs: { flat: "" } },
                                        [_vm._v(_vm._s(configuracion.nombre))]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-card-text", [
                                    _c("div", [
                                      _c("div", { staticClass: "headline" }),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(configuracion.descripcion)
                                        )
                                      ])
                                    ])
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Valor",
                                          type: "number"
                                        },
                                        model: {
                                          value: configuracion.valor,
                                          callback: function($$v) {
                                            _vm.$set(
                                              configuracion,
                                              "valor",
                                              $$v
                                            )
                                          },
                                          expression: "configuracion.valor"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "verdeFayal",
                                            flat: "",
                                            outline: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.updateSettings(
                                                configuracion.id,
                                                configuracion.valor
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Actualizar")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }