<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card flat>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">
          Agregar trabajador
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
              <v-flex md12>
                <v-subheader>Trabajadores que no han sido incluidos en nómina:</v-subheader>
              </v-flex>
              <v-flex md12>
                <v-data-table :headers="vHeaders" disable-initial-sort :items="trabajadores" hide-actions>
                  <template slot="items" slot-scope="props">
                    <td>{{ props.item.nombre_completo }}</td>
                    <td>{{ props.item.nombre_area }}</td>
                    <td>
                      <v-tooltip bottom>
                        <v-btn  icon flat dark small slot="activator" color="primary" @click="addWorker(props.item.id)">
                          <v-icon x-small>add</v-icon>
                        </v-btn>
                        <span>Agregar a nómina</span>
                      </v-tooltip>
                    </td>
                  </template>
                </v-data-table>
              </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    area_nomina_id: Number
  },
  data() {
    return {
      trabajadores: [],
      vHeaders: [
        {text: "Nombre de trabajador", value: "nombre", align: 'start'},
        {text: "Área", value: "area", align: 'start'},
        {text: "Acciones", value: "area", align: 'start'},
      ],
      keepStoring: true,
      localShow: false,
      vBtnSave: {
        loading: false
      },
      vSnackBar: {
        visible: false,
        text: null
      }
    }
  },
  watch: {
    show: function (value) {
      if (value) this.getAreaNominaTrabajadores();
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    }
  },
  methods: {
    getAreaNominaTrabajadores: function () {
      axios.get(laravelRoute('areas_nominas.getTrabajadoresDoesntHaveAreaNomina', this.area_nomina_id))
      .then(response => this.trabajadores = response.data)
      .catch(error => errorHandling(error));
    },
    addWorker: function (trabajador_id) {
      axios.post(laravelRoute('areas_nominas.addWorker', this.area_nomina_id), {trabajador_id: trabajador_id})
      .then(response => {
        this.vSnackBar.text = response.data.message_text;
        this.vSnackBar.visible = true;
        this.localShow =  false;
      })
      .catch(error => errorHandling(error));
    }
  },
}
</script>