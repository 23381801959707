<template>
    <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn flat icon :href="`/etapas/fraccionamiento/${etapa.fraccionamiento_id}`">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>

        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-toolbar card prominent>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="body-2 grey--text">Iniciar actividades para la etapa {{etapa.nombre}} </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout>
                                <v-flex xs5>
                                    <v-card dark color='#75d57b'>
                                        <v-layout>
                                            <v-flex xs8>
                                                <v-card-title primary-title>
                                                    <div>
                                                        <div class="headline total-title">Iniciadas</div>
                                                        <div> Progreso de actividades Iniciadas</div>
                                                    </div>
                                                </v-card-title>
                                            </v-flex>
                                            <v-flex xs4>
                                                <v-layout  align-center justify-center row fill-height>
                                                    <v-container>
                                                        <v-progress-circular
                                                            :rotate="180"
                                                            :size="75"
                                                            :width="10"
                                                            :value="localEtapa.porcentaje_de_avance.iniciado"
                                                            color="white"
                                                        >
                                                            {{ localEtapa.porcentaje_de_avance.iniciado.toFixed(1) == '100.0' ? '100' : localEtapa.porcentaje_de_avance.iniciado.toFixed(1) }}%
                                                        </v-progress-circular>
                                                    </v-container>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex xs2></v-flex>
                                <v-flex xs5>
                                    <v-card dark color='#75d57b'>
                                        <v-layout>
                                            <v-flex xs8>
                                                <v-card-title primary-title>
                                                    <div>
                                                        <div class="headline total-title">Finalizadas</div>
                                                        <div> Progreso de actividades finalizadas</div>
                                                    </div>
                                                </v-card-title>
                                            </v-flex>
                                            <v-flex xs4>
                                                <v-layout  align-center justify-center row fill-height>
                                                    <v-container>
                                                        <v-progress-circular
                                                            :rotate="180"
                                                            :size="75"
                                                            :width="10"
                                                            :value="localEtapa.porcentaje_de_avance.finalizado"
                                                            color="white"
                                                        >
                                                            {{ localEtapa.porcentaje_de_avance.finalizado.toFixed(1) == '100.0' ? '100' : localEtapa.porcentaje_de_avance.finalizado.toFixed(1) }}%
                                                        </v-progress-circular>
                                                    </v-container>
                                                </v-layout>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                            </v-layout>

                            <v-divider></v-divider>

                            <v-layout row wrap>
                                <v-flex>
                                    <v-tabs v-model="vTabs" fixed-tabs centered color="white" slider-color="#1aba24">
                                        <v-tab href="#tab-manzanas">
                                            <span>Manzanas</span>
                                        </v-tab>
                                        <v-tab href="#tab-calles">
                                            <span>Calles</span>
                                        </v-tab>
                                        <v-tab href="#tab-areas-verdes">
                                            <span>Áreas verdes</span>
                                        </v-tab>
                                    </v-tabs>
                                    <v-tabs-items v-model="vTabs">
                                        <v-tab-item value="tab-manzanas">
                                            <v-card flat>
                                                <v-container>
                                                    <v-card-text>
                                                        <v-data-table
                                                            :items="manzanas.data"
                                                            :expand="expandManzana"
                                                            hide-actions
                                                            item-key="id"
                                                        >
                                                            <template v-slot:headers="props">
                                                                <tr>
                                                                    <th>Manzana</th>
                                                                    <th></th>
                                                                </tr>
                                                            </template>
                                                            <template v-slot:items="props">
                                                                <tr>
                                                                    <td>{{ props.item.nombre }}</td>
                                                                    <td class="text-xs-right">
                                                                        <v-btn flat icon @click="props.expanded = !props.expanded">
                                                                            <v-icon>expand_more</v-icon>
                                                                        </v-btn>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                            <template v-slot:expand="props">
                                                                <v-card flat>
                                                                    <v-data-table
                                                                        :items="props.item.actividad"
                                                                        hide-actions
                                                                        item-key="id"
                                                                    >
                                                                        <template v-slot:headers>
                                                                            <tr>
                                                                                <th></th>
                                                                                <th>Rubro</th>
                                                                                <th>Concepto</th>
                                                                                <th>Tiempo esperado</th>
                                                                                <th>Fecha de incio</th>
                                                                                <th>Fecha de finalización</th>
                                                                                <th class="text-xs-right">Acciones</th>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-slot:items="propsRubrosManzana">
                                                                            <tr>
                                                                                <td></td>
                                                                                <td>{{ propsRubrosManzana.item.rubro.nombre }}</td>
                                                                                <td>{{ propsRubrosManzana.item.rubro.conceptos.nombre }}</td>
                                                                                <td>{{ propsRubrosManzana.item.tiempo_estimado.toFixed(2) }} horas</td>
                                                                                <td>{{ formatDate(propsRubrosManzana.item.fecha_de_inicio) }}</td>
                                                                                <td>{{ formatDate(propsRubrosManzana.item.fecha_de_finalizacion) }}</td>
                                                                                <td class="text-xs-right">
                                                                                    <v-tooltip bottom>
                                                                                        <v-btn
                                                                                            icon
                                                                                            flat
                                                                                            dark
                                                                                            small
                                                                                            slot="activator"
                                                                                            color="verdeFayal"
                                                                                            :disabled="(propsRubrosManzana.item.iniciado) ? true : false"
                                                                                            @click="initDistribucionById(propsRubrosManzana.item)"
                                                                                        >
                                                                                            <v-icon>play_arrow</v-icon>
                                                                                        </v-btn>
                                                                                        <span>Iniciar</span>
                                                                                    </v-tooltip>
                                                                                    <v-tooltip bottom>
                                                                                        <v-btn
                                                                                            icon
                                                                                            flat
                                                                                            dark
                                                                                            small
                                                                                            slot="activator"
                                                                                            color="red"
                                                                                            :disabled="(!propsRubrosManzana.item.iniciado || propsRubrosManzana.item.finalizado) ? true : false"
                                                                                            @click="finishDistribucionById(propsRubrosManzana.item)"
                                                                                        >
                                                                                            <v-icon>stop</v-icon>
                                                                                        </v-btn>
                                                                                        <span>Finalizar</span>
                                                                                    </v-tooltip>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                    </v-data-table>
                                                                </v-card>
                                                            </template>
                                                        </v-data-table>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <pagination :limit="10" :data="manzanas" @pagination-change-page="getManzanas"></pagination>
                                                        <v-spacer></v-spacer>
                                                    </v-card-actions>
                                                </v-container>
                                            </v-card>
                                        </v-tab-item>
                                        <v-tab-item value="tab-calles">
                                            <v-card flat>
                                                <v-container>
                                                    <v-card-text>
                                                        <v-data-table
                                                            :items="calles.data"
                                                            :expand="expandCalles"
                                                            hide-actions
                                                            item-key="id"
                                                        >
                                                            <template v-slot:headers="props">
                                                                <tr>
                                                                    <th>Calle</th>
                                                                    <th class="text-xs-right">Acciones</th>
                                                                </tr>
                                                            </template>
                                                            <template v-slot:items="props">
                                                                <tr>
                                                                    <td>{{ props.item.nombre }}</td>
                                                                    <td class="text-xs-right">
                                                                        <v-btn flat icon @click="props.expanded = !props.expanded">
                                                                            <v-icon>expand_more</v-icon>
                                                                        </v-btn>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                            <template v-slot:expand="props">
                                                                <v-card flat>
                                                                    <v-data-table
                                                                        :items="props.item.actividad"
                                                                        hide-actions
                                                                        item-key="id"
                                                                    >
                                                                        <template v-slot:headers>
                                                                            <tr>
                                                                                <th></th>
                                                                                <th>Rubro</th>
                                                                                <th>Concepto</th>
                                                                                <th>Tiempo esperado</th>
                                                                                <th>Fecha de incio</th>
                                                                                <th>Fecha de finalización</th>
                                                                                <th class="text-xs-right">Acciones</th>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-slot:items="propsRubrosCalles">
                                                                            <tr>
                                                                                <td></td>
                                                                                <td>{{ propsRubrosCalles.item.rubro.nombre }}</td>
                                                                                <td>{{ propsRubrosCalles.item.rubro.conceptos.nombre }}</td>
                                                                                <td>{{ propsRubrosCalles.item.tiempo_estimado.toFixed(2) }} horas</td>
                                                                                <td>{{ formatDate(propsRubrosCalles.item.fecha_de_inicio) }}</td>
                                                                                <td>{{ formatDate(propsRubrosCalles.item.fecha_de_finalizacion) }}</td>
                                                                                <td class="text-xs-right">
                                                                                    <v-tooltip bottom>
                                                                                        <v-btn
                                                                                            icon
                                                                                            flat
                                                                                            dark
                                                                                            small
                                                                                            slot="activator"
                                                                                            color="verdeFayal"
                                                                                            :disabled="(propsRubrosCalles.item.iniciado) ? true : false"
                                                                                            @click="initDistribucionById(propsRubrosCalles.item)"
                                                                                        >
                                                                                            <v-icon>play_arrow</v-icon>
                                                                                        </v-btn>
                                                                                        <span>Iniciar</span>
                                                                                    </v-tooltip>
                                                                                    <v-tooltip bottom>
                                                                                        <v-btn
                                                                                            icon
                                                                                            flat
                                                                                            dark
                                                                                            small
                                                                                            slot="activator"
                                                                                            color="red"
                                                                                            :disabled="(!propsRubrosCalles.item.iniciado || propsRubrosCalles.item.finalizado) ? true : false"
                                                                                            @click="finishDistribucionById(propsRubrosCalles.item)"
                                                                                        >
                                                                                            <v-icon>stop</v-icon>
                                                                                        </v-btn>
                                                                                        <span>Finalizar</span>
                                                                                    </v-tooltip>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                    </v-data-table>
                                                                </v-card>
                                                            </template>
                                                        </v-data-table>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <pagination :limit="10" :data="calles" @pagination-change-page="getCalles"></pagination>
                                                        <v-spacer></v-spacer>
                                                    </v-card-actions>
                                                </v-container>
                                            </v-card>
                                        </v-tab-item>
                                        <v-tab-item value="tab-areas-verdes">
                                            <v-card flat>
                                                <v-container>
                                                    <v-card-text>
                                                        <v-data-table
                                                            :items="areas.data"
                                                            :expand="expandAreas"
                                                            hide-actions
                                                            item-key="id"
                                                        >
                                                            <template v-slot:headers="props">
                                                                <tr>
                                                                    <th>Calle</th>
                                                                    <th class="text-xs-right">Acciones</th>
                                                                </tr>
                                                            </template>
                                                            <template v-slot:items="props">
                                                                <tr>
                                                                    <td>{{ props.item.nombre }}</td>
                                                                    <td class="text-xs-right">
                                                                        <v-btn flat icon @click="props.expanded = !props.expanded">
                                                                            <v-icon>expand_more</v-icon>
                                                                        </v-btn>
                                                                    </td>
                                                                </tr>
                                                            </template>
                                                            <template v-slot:expand="props">
                                                                <v-card flat>
                                                                    <v-data-table
                                                                        :items="props.item.actividad"
                                                                        hide-actions
                                                                        item-key="id"
                                                                    >
                                                                        <template v-slot:headers>
                                                                            <tr>
                                                                                <th></th>
                                                                                <th>Rubro</th>
                                                                                <th>Concepto</th>
                                                                                <th>Tiempo esperado</th>
                                                                                <th>Fecha de incio</th>
                                                                                <th>Fecha de finalización</th>
                                                                                <th class="text-xs-right">Acciones</th>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-slot:items="propsRubrosAreas">
                                                                            <tr>
                                                                                <td></td>
                                                                                <td>{{ propsRubrosAreas.item.rubro.nombre }}</td>
                                                                                <td>{{ propsRubrosAreas.item.rubro.conceptos.nombre }}</td>
                                                                                <td>{{ propsRubrosAreas.item.tiempo_estimado.toFixed(2) }} horas</td>
                                                                                <td>{{ formatDate(propsRubrosAreas.item.fecha_de_inicio) }}</td>
                                                                                <td>{{ formatDate(propsRubrosAreas.item.fecha_de_finalizacion) }}</td>
                                                                                <td class="text-xs-right">
                                                                                    <v-tooltip bottom>
                                                                                        <v-btn
                                                                                            icon
                                                                                            flat
                                                                                            dark
                                                                                            small
                                                                                            slot="activator"
                                                                                            color="verdeFayal"
                                                                                            :disabled="(propsRubrosAreas.item.iniciado) ? true : false"
                                                                                            @click="initDistribucionById(propsRubrosAreas.item)"
                                                                                        >
                                                                                            <v-icon>play_arrow</v-icon>
                                                                                        </v-btn>
                                                                                        <span>Iniciar</span>
                                                                                    </v-tooltip>
                                                                                    <v-tooltip bottom>
                                                                                        <v-btn
                                                                                            icon
                                                                                            flat
                                                                                            dark
                                                                                            small
                                                                                            slot="activator"
                                                                                            color="red"
                                                                                            :disabled="(!propsRubrosAreas.item.iniciado || propsRubrosAreas.item.finalizado) ? true : false"
                                                                                            @click="finishDistribucionById(propsRubrosAreas.item)"
                                                                                        >
                                                                                            <v-icon>stop</v-icon>
                                                                                        </v-btn>
                                                                                        <span>Finalizar</span>
                                                                                    </v-tooltip>
                                                                                </td>
                                                                            </tr>
                                                                        </template>
                                                                    </v-data-table>
                                                                </v-card>
                                                            </template>
                                                        </v-data-table>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <pagination :limit="10" :data="areas" @pagination-change-page="getAreas"></pagination>
                                                        <v-spacer></v-spacer>
                                                    </v-card-actions>
                                                </v-container>
                                            </v-card>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import moment from "moment";

  export default {
    data() {
        return {
            manzanas: {
                data: []
            },
            calles: {
                data: []
            },
            areas: {
                data: []
            },
            expandManzana: false,
            expandCalles: false,
            expandAreas: false,
            vTabs: 'tab-manzanas',
            localEtapa: {
                porcentaje_de_avance: {"finalizado":0,"iniciado":0}
            },
            vBtnSave: {
                loading: false
            },
            vSnackBar: {
                visible: false,
                text: null
            },
        }
    },
    props: {
      etapa : Object 
    },
    watch: {
      etapa: function(value) {
        this.localEtapa = value;
      }
    },
    methods: {
        getEtapa: function() {
            axios.get(`/etapas/refactor/${this.etapa.id}`)
            .then(response => this.localEtapa = response.data)
            .catch(error => errorHandling(error));
        },
        getManzanas: function(page = 1) {
            axios.get(`/etapas/${this.etapa.id}/manzanas?page=${page}&withConceptos=true`)
            .then(response => this.manzanas = response.data)
            .catch(error => errorHandling(error));
        },
        getCalles: function(page = 1) {
            axios.get(`/etapas/${this.etapa.id}/calles?page=${page}&withConceptos=true`)
            .then(response => this.calles = response.data)
            .catch(error => errorHandling(error));
        },
        getAreas: function(page = 1) {
            axios.get(`/etapas/${this.etapa.id}/areas_verdes?page=${page}&withConceptos=true`)
            .then(response => this.areas = response.data)
            .catch(error => errorHandling(error));
        },
        formatDate: function(date) {
            if(date) {
                var localLocale = moment(date);
                moment.locale('es');
                localLocale.locale(false)
                return localLocale.format('LLLL')
            }
        },
        initDistribucion: function(manzanaEtapaLotificacionId, model) {
            swal({
                title: "¿Estás seguro?",
                text: "Al aceptar iniciarás todas las actividades referentes a esta manzana",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: "#1aba24"
            }).then(result => {
                if (result) {
                    axios.post(`/etapas/${this.etapa.id}/distribucion/iniciar`, {
                        manzana_etapa_lotificacion: manzanaEtapaLotificacionId,
                        model,
                    })
                    .then(response => {
                        this.vSnackBar.text = response.data.message_text;
                        this.vSnackBar.visible = true;
                        this.getManzanas();
                        this.getEtapa();
                    })
                    .catch(error => errorHandling(error));
                }
            });
        }, 
        finishDistribucion: function(manzanaEtapaLotificacionId, model) {
            swal({
                title: "¿Estás seguro?",
                text: "Al aceptar terminarás todas las actividades referentes a esta manzana",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: "#1aba24"
            }).then(result => {
                if (result) {
                    axios.post(`/etapas/${this.etapa.id}/distribucion/finalizar`, {
                        manzana_etapa_lotificacion: manzanaEtapaLotificacionId,
                        model,
                    })
                    .then(response => {
                        this.vSnackBar.text = response.data.message_text;
                        this.vSnackBar.visible = true;
                        this.getManzanas();
                        this.getEtapa();
                    })
                    .catch(error => errorHandling(error));
                }
            });
        },
        initDistribucionById: function(activity) {
            swal({
                title: "¿Estás seguro?",
                text: `Al aceptar iniciarás: ${activity.rubro.nombre}`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: "#1aba24"
            }).then(result => {
                if (result) {
                    axios.post(laravelRoute('actividades.iniciar'), {
                        actividad_id: activity.id
                    })
                    .then(response => {
                        this.vSnackBar.text = response.data.message_text;
                        this.vSnackBar.visible = true;
                        this.getManzanas();
                        this.getCalles();
                        this.getAreas();
                        this.getEtapa();
                    })
                    .catch(error => errorHandling(error));
                }
            });
        },
        finishDistribucionById: function(activity) {
            swal({
                title: "¿Estás seguro?",
                text: `Al aceptar finalizarás: ${activity.rubro.nombre}`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: "#1aba24"
            }).then(result => {
                if (result) {
                    axios.post(laravelRoute('actividades.finalizar'), {
                        actividad_id: activity.id
                    })
                    .then(response => {
                        this.vSnackBar.text = response.data.message_text;
                        this.vSnackBar.visible = true;
                        this.getManzanas();
                        this.getCalles();
                        this.getAreas();
                        this.getEtapa();
                    })
                    .catch(error => errorHandling(error));
                }
            });
        }
    },
    computed: {

    },
    created(){
        this.getManzanas();
        this.getAreas();
        this.getCalles();
        this.getEtapa();
    }
  }
</script>
