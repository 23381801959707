<template>
  <input type="hidden" name="form_previous_url" v-bind:value="url">
</template>

<script>
export default {
  name: 'FormPreviousUrl',
  props: {
    url: {
      type: String,
      required: true
    }
  }
}
</script>
