<template>
    <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn flat icon href="/prototipos_vivienda">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>

        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-toolbar card prominent>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="body-2 grey--text">Administración de valores de {{ household.nombre }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout>
                                    <v-flex xs3>
                                        <v-card dark color='#75d57b'>
                                            <v-layout>
                                                <v-flex xs5>
                                                    <v-layout  align-center justify-center row fill-height>
                                                        <v-img
                                                            src="/fonts/local/watch.svg"
                                                            height="50px"
                                                            contain
                                                        ></v-img>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs7>
                                                    <v-card-title primary-title>
                                                        <div>
                                                            <div class="headline total-title">{{ statics.horas }}</div>
                                                            <div>Horas de ejecución</div>
                                                        </div>
                                                    </v-card-title>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs3>
                                        <v-card dark color='#75d57b'>
                                            <v-layout>
                                                <v-flex xs5>
                                                    <v-layout  align-center justify-center row fill-height>
                                                        <v-img
                                                            src="/fonts/local/construction.svg"
                                                            height="50px"
                                                            contain
                                                        ></v-img>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs7>
                                                    <v-card-title primary-title>
                                                        <div>
                                                            <div class="headline total-title">$ {{ statics.materiales | currency }}</div>
                                                            <div>Materiales</div>
                                                        </div>
                                                    </v-card-title>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs3>
                                        <v-card dark color='#75d57b'>
                                            <v-layout>
                                                <v-flex xs5>
                                                    <v-layout  align-center justify-center row fill-height>
                                                        <v-img
                                                            src="/fonts/local/manpower.svg"
                                                            height="50px"
                                                            contain
                                                        ></v-img>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs7>
                                                    <v-card-title primary-title>
                                                        <div>
                                                            <div class="headline total-title">$ {{ statics.obra | currency }}</div>
                                                            <div>Mano de obra</div>
                                                        </div>
                                                    </v-card-title>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs3>
                                        <v-card dark color='#75d57b'>
                                            <v-layout>
                                                <v-flex xs5>
                                                    <v-layout  align-center justify-center row fill-height>
                                                        <v-img
                                                            src="/fonts/local/total.svg"
                                                            height="50px"
                                                            contain
                                                        ></v-img>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs7>
                                                    <v-card-title primary-title>
                                                        <div>
                                                            <div class="headline total-title">$ {{ statics.total | currency }}</div>
                                                            <div>Total</div>
                                                        </div>
                                                    </v-card-title>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                            </v-layout>

                            <v-divider></v-divider>

                            <v-card-text>
                                <v-progress-linear v-if="loadingData" color="verdeFayal" :indeterminate="true"></v-progress-linear>
                                <v-data-table
                                    :items="concepts.data"
                                    hide-actions
                                    item-key="id"
                                    ref="conceptosTable"
                                >
                                    <template v-slot:headers="props">
                                        <tr>
                                            <th>Concepto</th>
                                            <th>Tiempo de ejecución</th>
                                            <th>Subtotal de material</th>
                                            <th>Subtotal de mano de obra</th>
                                            <th>Total</th>
                                            <th></th>
                                        </tr>
                                    </template>
                                    <template v-slot:items="props">
                                        <tr>
                                            <td>{{ props.item.concepto.nombre }}</td>
                                            <td>{{ props.item.total_horas }}</td>
                                            <td>$ {{ props.item.total_materiales | currency }}</td>
                                            <td>$ {{ props.item.total_obra | currency }}</td>
                                            <td>$ {{ props.item.total_obra + props.item.total_materiales | currency }}</td>
                                            <td class="text-xs-right">
                                                <v-btn flat icon :href="`/prototipos_vivienda/${householdId}/valores/${props.item.concepto.id}/rubros`">
                                                    <v-icon>navigate_next</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-slot:expand="props">
                                        <table class="table table-hover table-borderless">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Rubro</th>
                                                    <th>Tiempo de ejecución</th>
                                                    <th>Subtotal de material</th>
                                                    <th>Subtotal de mano de obra</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in props.item.rubros" :key="index">
                                                    <td></td>
                                                    <td>{{ item.nombre }}</td>
                                                    <td>{{(item.pivot.horas) ? `${item.pivot.horas} horas` : 'Aún sin registrar'}}</td>
                                                
                                                    <td v-if="item.total_materiales">$ {{item.total_materiales | currency }}</td>
                                                    <td v-else>$ 0.00</td>

                                                    <td v-if="item.total_valores">$ {{ item.total_valores | currency}}</td>
                                                    <td v-else>$ 0.00</td>

                                                    <td class="text-xs-left">
                                                        <v-layout>
                                                            <v-tooltip bottom>
                                                                <v-btn 
                                                                    :disabled="!$root.can('valores-materiales-index', permissions)" 
                                                                      @click="openExecutionTimeModal(item.id, item.nombre, props.index)"
                                                                    icon flat dark small slot="activator" color="verdeFayal"
                                                                >
                                                                    <v-icon>timer</v-icon>
                                                                </v-btn>
                                                                <span>Tiempo de ejecución</span>
                                                            </v-tooltip>

                                                            <v-tooltip bottom>
                                                                <v-btn 
                                                                    :disabled="!$root.can('valores-materiales-index', permissions)" 
                                                                    @click="openAddMaterialsValuesModal(item.id, item.nombre, props.index)"  
                                                                    icon flat dark 
                                                                    small slot="activator" color="verdeFayal"
                                                                >
                                                                    <img src="/fonts/local/construction_icon.svg" class="customIcon">
                                                                </v-btn>
                                                                <span>Materiales</span>
                                                            </v-tooltip>
                                                            <v-tooltip bottom>
                                                                <v-btn 
                                                                    :disabled="!$root.can('valores-materiales-obra-update', permissions)" 
                                                                    @click="setValues(valueID = null, item.id, item.nombre, `App\\PrototipoVivienda`, props.index)"
                                                                    color="verdeFayal"
                                                                    slot="activator" 
                                                                    small 
                                                                    dark 
                                                                    icon 
                                                                    flat 
                                                                >
                                                                    <img src="/fonts/local/manpower_icon.svg" class="customIcon">
                                                                </v-btn>
                                                                <span>Costos de mano de obra</span>
                                                            </v-tooltip>
                                                        </v-layout>
                                                    </td>
                                                </tr>

                                                <tr class="td-total">
                                                    <td></td>
                                                    <td class="text-xs-left">Totales</td>
                                                    <td class="text-xs-left">{{ getTotalHoras(props.item.rubros) }}</td>
                                                    <td class="text-xs-left">$ {{ getTotalMateriales(props.item.rubros) | currency }}</td>
                                                    <td class="text-xs-left">$ {{ getTotalManoDeObra(props.item.rubros) | currency }}</td>
                                                    <td class="text-xs-left">$ {{ getTotalManoDeObra(props.item.rubros) + getTotalMateriales(props.item.rubros) | currency }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <pagination :limit="10" :data="concepts" @pagination-change-page="getConcepts"></pagination>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>

        <!-- Modals -->
        <set-values-modal
            v-bind:show.sync="showSetValuesModal"
            v-bind:value-id.sync="currentValueId"
            v-bind:household-id.sync="householdId"
            v-bind:tax="parseInt(impuesto.valor)"
            v-bind:item-id.sync="currentItemId"
            v-bind:name.sync="currentItemName"
            v-bind:model-type.sync="currentModel"
        ></set-values-modal>
        <manage-materials-to-value-modal
            v-bind:show.sync="showMaterialsToValuesModal"
            v-bind:item-id.sync="currentItemId"
            v-bind:household-id.sync="householdId"
            v-bind:name.sync="currentItemName"
            v-bind:modelType.sync="currentModel"
            :permissions="permissions"
        ></manage-materials-to-value-modal>
        <add-execution-time-modal 
            :show.sync="showExecutionTimeModal" 
            :rubro-id.sync="currentItemId"
            :item-id.sync="householdId"
            :name.sync="currentItemName"
            :modelType="currentModel"
        />
    </v-card>
</template>

<style>
</style>

<script>
import { errorHandling, laravelRoute } from '../../lib/utilities'
  export default {
    data: () => ({
        household: {},
        impuesto: {},
        statics: {
            obra: 0,
            materiales: 0,
            total: 0,
            horas: 0
        },
        concepts: {
            data: []
        },
        loadingData: false,
        currentIndex: null,
        concept: 1,
        vTable: {
            headers: [
                { text: 'Rubro', value: 'rubro' },
                { text: 'Precio unitario', value: 'unitario' },
                { text: 'Cantidad', value: 'cantidad' },
                { text: 'Impuesto', value: 'impuesto' },
                { text: 'Total antes de impuesto', value: 'impuesto' },
                { text: 'Total neto', value: 'neto' },
                { text: 'Acciones', value: 'acciones', sortable: false }
            ]
        },
        showSetValuesModal: false,
        showMaterialsToValuesModal: false,
        showExecutionTimeModal: false,
        currentValueId: null,
        currentItemName: null,
        currentValueMaterialId: null,
        currentRubrId: null,
        currentItemId: null,
        currentModel: 'App\\PrototipoVivienda'
    }),
    props: {
        householdId: String,
        permissions: Object,
    },
    watch: {
        showSetValuesModal: function(value) {
            if(!value) {
                this.reloadData(this.currentIndex);
                this.getInfo();
            }       
        },
        showMaterialsToValuesModal: function(value) {
            if(!value) {
                this.reloadData(this.currentIndex);
                this.getInfo();
            }
        },
        showExecutionTimeModal: function name(value) {
            if(!value) {
                this.reloadData(this.currentIndex);
                this.getInfo();
            }
        }
    },
    methods: {
        getHousehold: function() {
            axios.get(laravelRoute('prototipos_vivienda.show.refactor', this.householdId))
            .then(response => this.household = response.data)
            .catch(error => errorHandling(error))
        },
        getInfo: function() {
            this.loadingData = true;
            axios.get(`/prototipos_vivienda/${this.householdId}/estadisticas`)
            .then(response => this.statics = response.data)
            .catch( error => errorHandling(error))
            .finally(() => this.loadingData = false);
        },
        getConcepts: function(page = 1) {
            axios.get(`/prototipos_vivienda/${this.householdId}/conceptos?page=${page}`)
            .then(response => this.concepts = response.data)
            .catch( error => errorHandling(error))
        },
        openExpand: function(id, index) {
            let expand = !this.$refs.conceptosTable.expanded[id];
            axios.get(`/prototipos_vivienda/${this.householdId}/conceptos/${this.concepts.data[index].concepto_id}`)
            .then(response => {
                this.concepts.data[index].rubros = response.data;
            })
            .catch(error => errorHandling(error))
            .finally(() => this.$set(this.$refs.conceptosTable.expanded, id, expand));
        },
        getValues: function() {
            axios.get(`/prototipos_vivienda/${this.householdId}/valores`)
            .then(response => this.data = response.data)
            .catch( error => errorHandling(error))
        },
        setValues: function(valorId, itemId, currentItemName, model, index) {
            this.showSetValuesModal = true;
            this.currentValueId = valorId;
            this.currentItemId = itemId;
            this.currentItemName = currentItemName;
            this.currentModel = model;
            this.currentIndex = index;
        },
        openAddMaterialsValuesModal: function(currentItemId, currentItemName, index) {
            this.showMaterialsToValuesModal = true;
            this.currentItemId = currentItemId;
            this.currentItemName = currentItemName;
            this.currentIndex = index;
        },
        openExecutionTimeModal: function(rubroId, name, index) {
            this.currentItemName = name;
            this.showExecutionTimeModal = true;
            this.currentItemId = rubroId;
            this.currentModel = this.currentModel;
            this.currentIndex = index
        },
        getImpuesto: function() {
            axios.get(`/configuraciones/1`)
            .then(response => this.impuesto = response.data)
            .catch( error => errorHandling(error))
        },
        getTotalManoDeObra: function(rubros) {
            var total = 0;
            rubros.forEach(item => total = total + item.total_valores);
            return total;
        },
        getTotalMateriales: function(rubros) {
            var total = 0;
            rubros.forEach(item => total = total + item.total_materiales);
            return total;
        },
        getSubtotalManoObra: function(valores) {
            var total = 0;
            valores.forEach(item => total = total + item.precio_total);
            return total;
        },
        getTotalHoras: function(rubros) {
            var total = 0;
            rubros.forEach(item => total = total + item.pivot.horas);
            return total;
        },
        reloadData: function(index) {
            axios.get(`/prototipos_vivienda/${this.householdId}/conceptos/${this.concepts.data[index].concepto_id}`)
            .then(response => {
                let oldData = this.concepts.data[index];
                this.concepts.data.splice(index, 1, {
                    ...oldData,
                    rubros: response.data
                })
            })
            .catch( error => errorHandling(error))
        }
    },
    mounted() {
        this.getImpuesto();
        this.getHousehold();
        this.getInfo();
        this.getConcepts();
    }
  }
</script>

<style scoped>
    .customIcon {
        width: 100%;
    }

    .card--flex-toolbar {
        margin-top: -64px;
    }

    .v-datatable__expand-col {
        background-color: red;
    }

    .td-total {
        background-color: #757575; color: #fff;
    }
</style>