import { render, staticRenderFns } from "./ValuesHousingDevelopmentIndex.vue?vue&type=template&id=35417c78&scoped=true&"
import script from "./ValuesHousingDevelopmentIndex.vue?vue&type=script&lang=js&"
export * from "./ValuesHousingDevelopmentIndex.vue?vue&type=script&lang=js&"
import style1 from "./ValuesHousingDevelopmentIndex.vue?vue&type=style&index=1&id=35417c78&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35417c78",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/api/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('35417c78', component.options)
    } else {
      api.reload('35417c78', component.options)
    }
    module.hot.accept("./ValuesHousingDevelopmentIndex.vue?vue&type=template&id=35417c78&scoped=true&", function () {
      api.rerender('35417c78', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/planeacion/ValuesHousingDevelopmentIndex.vue"
export default component.exports