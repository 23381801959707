<template>
  <v-layout>
    <v-dialog :value="dialog" persistent max-width="700px" hide-overlay>
      <v-card>
        <v-toolbar color="white" flat>
          <v-toolbar-title class="primary-title">Incidencia para {{ etapa.nombre }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            flat
            color="verdeFayal"
            @click="$emit('close');"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          
          <v-text-field
            :disabled="!!incidenciaId"
            v-model="incidencia.nombre"
            label="Nombre de la incidencia"
          ></v-text-field>
          
          <v-textarea
            :disabled="!!incidenciaId"
            v-model="incidencia.descripcion"
            auto-grow
            color="verdeFayal"
            label="Descripción de la incidencia"
            rows="3"
            max="250"
            counter="250"
          ></v-textarea>

          <div v-if="!!manzanas.items.length">
            <h4>Manzanas</h4>
            <v-layout wrap>
              <v-autocomplete
                :disabled="!!incidenciaId"
                attach
                v-model="manzanas.selected"
                browser-autocomplete="off"
                hide-selected
                return-object
                placeholder="Seleccione los elementos a incluir en la incidencia"
                :chips="true"
                :multiple="true"
                :deletable-chips="true"
                item-text="manzana_lotificación.nombre"
                item-value="manzana_lotificación.id"
                :items="manzanas.items"
              >
              </v-autocomplete>
            </v-layout>
            <v-data-table
              :hide-actions="true"
              :disable-initial-sort="true"
              :headers="manzanas.headers"
              :items="manzanas.selected"
              class="elevation-1"
              > 
              <template slot="items" slot-scope="props">
                <td>
                  <v-chip class="ma-2" color="verdeFayal" outline> Manzana: {{ props.item.manzana_lotificación.nombre }}
                  </v-chip>
                </td>
                <td>
                  <v-text-field
                    :disabled="!!incidenciaId"
                    type="Number"
                    v-model="manzanas.selected[manzanas.selected.indexOf(props.item)].horas_incidencia"
                    label="Horas de retraso"
                    @input="calculate"
                    min='1'
                  ></v-text-field>
                </td>
              </template>
            </v-data-table>
            <v-divider></v-divider>
          </div>

          <div v-if="!!lotes.items.length">
            <h4>Lotes</h4>
            <v-layout wrap>
              <v-autocomplete
                :disabled="!!incidenciaId"
                attach
                v-model="lotes.selected"
                browser-autocomplete="off"
                hide-selected
                return-object
                placeholder="Seleccione los elementos a incluir en la incidencia"
                :chips="true"
                :multiple="true"
                :deletable-chips="true"
                item-text="lote_lotificacion.nombre"
                item-value="lote_lotificacion.id"
                :items="lotes.items"
              >
              </v-autocomplete>
            </v-layout>
            <v-data-table
              :hide-actions="true"
              :disable-initial-sort="true"
              :headers="lotes.headers"
              :items="lotes.selected"
              class="elevation-1"
              > 
              <template slot="items" slot-scope="props">
                <td>
                  <v-chip class="ma-2" color="verdeFayal" outline> Lote: {{ props.item.lote_lotificacion.nombre }}
                  </v-chip>
                </td>
                <td>
                  <v-text-field
                    :disabled="!!incidenciaId"
                    type="Number"
                    v-model="lotes.selected[lotes.selected.indexOf(props.item)].horas_incidencia"
                    label="Horas de retraso"
                    @input="calculate"
                    min='1'
                  ></v-text-field>
                </td>
              </template>
            </v-data-table>
            <v-divider></v-divider>
          </div>
          
          <div v-if="!!areas_verdes.items.length">
            <h4>Äreas Verdes</h4>
            <v-layout wrap>
              <v-autocomplete
                :disabled="!!incidenciaId"
                attach
                v-model="areas_verdes.selected"
                browser-autocomplete="off"
                hide-selected
                return-object
                placeholder="Seleccione los elementos a incluir en la incidencia"
                :chips="true"
                :multiple="true"
                :deletable-chips="true"
                item-text="area_verde_lotificación.nombre"
                item-value="area_verde_lotificación.id"
                :items="areas_verdes.items"
              >
              </v-autocomplete>
            </v-layout>
            <v-data-table
              :disabled="!!incidenciaId"
              :hide-actions="true"
              :disable-initial-sort="true"
              :headers="areas_verdes.headers"
              :items="areas_verdes.selected"
              class="elevation-1"
              > 
              <template slot="items" slot-scope="props">
                <td>
                  <v-chip class="ma-2" color="verdeFayal" outline> {{ props.item.area_verde_lotificación.nombre }}
                  </v-chip>
                </td>
                <td>
                  <v-text-field
                    :disabled="!!incidenciaId"
                    type="Number"
                    v-model="areas_verdes.selected[areas_verdes.selected.indexOf(props.item)].horas_incidencia"
                    label="Horas de retraso"
                    @input="calculate"
                    min='1'
                  ></v-text-field>
                </td>
              </template>
            </v-data-table>
            <v-divider></v-divider>
          </div>
          
          <div v-if="!!calles.items.length">
            <h4>Calles</h4>
            <v-layout wrap>
              <v-autocomplete
                :disabled="!!incidenciaId"
                attach
                v-model="calles.selected"
                browser-autocomplete="off"
                hide-selected
                return-object
                placeholder="Seleccione los elementos a incluir en la incidencia"
                :chips="true"
                :multiple="true"
                :deletable-chips="true"
                item-text="calles_lotificación.nombre"
                item-value="calles_lotificación.id"
                :items="calles.items"
              >
              </v-autocomplete>
            </v-layout>
            <v-data-table
              :hide-actions="true"
              :disable-initial-sort="true"
              :headers="calles.headers"
              :items="calles.selected"
              class="elevation-1"
              > 
              <template slot="items" slot-scope="props">
                <td>
                  <v-chip class="ma-2" color="verdeFayal" outline> Calle: {{ props.item.calles_lotificación.nombre }}
                  </v-chip>
                </td>
                <td>
                  <v-text-field
                    :disabled="!!incidenciaId"
                    type="Number"
                    v-model="calles.selected[calles.selected.indexOf(props.item)].horas_incidencia"
                    label="Horas de retraso"
                    @input="calculate"
                    min='1'
                  ></v-text-field>
                </td>
              </template>
            </v-data-table>
            <v-divider></v-divider>
          </div>

          <div>
            <h3>Horas: {{incidencia.horas}}</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!!incidenciaId"
            color="verdeFayal"
            flat
            @click="makeBody"
          >
            Guardar
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { errorHandling } from "../../lib/utilities";
export default {
  props: ["dialog","etapaId","etapaprops", "incidenciaId"],

  data() {
    return {
      etapa: {},
      incidencia: {
        nombre: "",
        descripcion: "",
        horas: 0,
        response: {}
      },
      manzanas: {
        items: [],
        selected: [],
        headers: [
          { 
            sortable: false,
            text: 'Nombre', 
          },
          { 
            sortable: false,
            text: 'Horas', 
          },
        ]
      },
      lotes: {
        items: [],
        selected: [],
        headers: [
          { 
            sortable: false,
            text: 'Nombre', 
          },
          { 
            sortable: false,
            text: 'Horas', 
          },
        ]
      },
      areas_verdes: {
        items: [],
        selected: [],
        headers: [
          { 
            sortable: false,
            text: 'Nombre', 
          },
          { 
            sortable: false,
            text: 'Horas', 
          },
        ]
      },
      calles: {
        items: [],
        selected: [],
        headers: [
          { 
            sortable: false,
            text: 'Nombre', 
          },
          { 
            sortable: false,
            text: 'Horas', 
          },
        ]
      },
    };
  },
  watch:{
    dialog: function(value){
      if(value) {
        Object.assign(this.etapa, this.etapaprops);
        this.autofill();
      }
    }
  },
  methods: {
    autofill: async function(){
      if(!!this.incidenciaId){
        const incidencia = await this.getIncidencia();
        const manzanas = incidencia.contenido.filter(element => element.contenido_type == 'App\\ManzanaEtapa').map(element => ({id: element.contenido_id, horas: element.horas }));
        const lotes = incidencia.contenido.filter(element => element.contenido_type == 'App\\LotesManzanaEtapa').map(element => ({id: element.contenido_id, horas: element.horas }));
        const areas = incidencia.contenido.filter(element => element.contenido_type == 'App\\AreasVerdesEpatas').map(element => ({id: element.contenido_id, horas: element.horas }));
        const calles = incidencia.contenido.filter(element => element.contenido_type == 'App\\CallesEpatas').map(element => ({id: element.contenido_id, horas: element.horas }));
        
        let manzanas_arr = [];
        let lotes_arr = [];
        let areas_arr = [];
        let calles_arr = [];

        manzanas.forEach(manzana => {
          let manzana_completa_encontrada = this.manzanas.items.filter(element => element.id == manzana.id);
          if(manzana_completa_encontrada){
            manzana_completa_encontrada[0].horas_incidencia = manzana.horas;
            manzanas_arr.push(manzana_completa_encontrada[0]);
          }
        });

        lotes.forEach(lote => {
          let lote_completo_encontrado = this.lotes.items.filter(element => element.id == lote.id);
          if(lote_completo_encontrado){
            lote_completo_encontrado[0].horas_incidencia = lote.horas;
            lotes_arr.push(lote_completo_encontrado[0]);
          }
        });

        areas.forEach(area => {
          let area_completa_encontrada = this.areas_verdes.items.filter(element => element.id == area.id);
          if(area_completa_encontrada){
            area_completa_encontrada[0].horas_incidencia = area.horas;
            areas_arr.push(area_completa_encontrada[0]);
          }
        });

        calles.forEach(calle => {
          let calle_completa_encontrada = this.calles.items.filter(element => element.id == calle.id);
          if(calle_completa_encontrada){
            calle_completa_encontrada[0].horas_incidencia = calle.horas;
            calles_arr.push(calle_completa_encontrada[0]);
          }
        });

        this.manzanas.selected = manzanas_arr;
        this.lotes.selected = lotes_arr;
        this.areas_verdes.selected = areas_arr;
        this.calles.selected = calles_arr;

        this.calculate();
      }else{
        this.manzanas.selected = [];
        this.lotes.selected = [];
        this.areas_verdes.selected = [];
        this.calles.selected = [];
        this.incidencia = {
          nombre: "",
          descripcion: "",
          horas: 0,
          response: {}
        };
      }
    },
    getIncidencia: function(){
      return axios.get(route("incidencias.show",this.incidenciaId))
      .then(response => {
        this.incidencia.nombre = response.data.nombre;
        this.incidencia.descripcion = response.data.descripcion;
        return response.data;
      })
      .catch(error => errorHandling(error));
    },
    calculate: function(){
      const manzanas = this.manzanas.selected.reduce( (previousValue, currentValue) => currentValue.horas_incidencia ? previousValue + parseInt(currentValue.horas_incidencia) : previousValue, 0);
      const lotes = this.lotes.selected.reduce( (previousValue, currentValue) => currentValue.horas_incidencia ? previousValue + parseInt(currentValue.horas_incidencia) : previousValue, 0);
      const areas_verdes = this.areas_verdes.selected.reduce( (previousValue, currentValue) => currentValue.horas_incidencia ? previousValue + parseInt(currentValue.horas_incidencia) : previousValue, 0);
      const calles = this.calles.selected.reduce( (previousValue, currentValue) => currentValue.horas_incidencia ? previousValue + parseInt(currentValue.horas_incidencia) : previousValue, 0);
      let total = manzanas + lotes + areas_verdes + calles;

      this.incidencia.horas = total;
    },
    makeBody: function(){
      const body = {
        nombre: this.incidencia.nombre,
        descripcion: this.incidencia.descripcion,
        etapaID: this.etapa.id,
        manzanas: this.manzanas.selected,
        lotes: this.lotes.selected,
        areas_verdes: this.areas_verdes.selected,
        calles: this.calles.selected,
      }
      this.storeIncidencia(body);
    }, 
    storeIncidencia: function(body){
      axios.post(route("incidencias.store"), body)
      .then(response => {
        alertify.success(response.data.message_text);
        setTimeout(() => {
          location.reload(); 
        }, 300);
        this.$emit('close');
      })
      .catch(error => errorHandling(error));
    },
    getEtapa: function(page = 1) {
      axios.get(`/etapas/refactor/${this.etapaId}`)
      .then(response => {
        this.etapa = response.data
        this.manzanas.items = this.etapa.only_manzanas_etapas;
        this.lotes.items = this.etapa.lotes;
        this.areas_verdes.items = this.etapa.areas_verdes;
        this.calles.items = this.etapa.calles;
      })
      .catch(error => errorHandling(error));
    },
  }, 
  created() {
    this.getEtapa();
  }
};
</script>