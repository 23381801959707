var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" } },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-toolbar-title", [
            _vm._v("Sistema Integral de Control de Proyectos de Obra SICPRO")
          ]),
          _vm._v(" "),
          _c("v-spacer")
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "grid-list-lg": "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "white--text",
                                    class: { "on-hover": hover },
                                    attrs: {
                                      href: "/usuarios",
                                      color: "#52b8c4",
                                      elevation: hover ? 12 : 2
                                    }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs7: "" } },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                attrs: { "primary-title": "" }
                                              },
                                              [
                                                _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "display-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.usuarios)
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs5: "" } },
                                          [
                                            _c(
                                              "v-avatar",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      size: "55",
                                                      dark: ""
                                                    }
                                                  },
                                                  [_vm._v("account_circle")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-divider", { attrs: { light: "" } }),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-actions",
                                      { staticClass: "pa-3" },
                                      [_c("div", [_vm._v("Usuarios")])]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "white--text",
                                    class: { "on-hover": hover },
                                    attrs: {
                                      href: "/trabajadores",
                                      color: "#3350b9",
                                      elevation: hover ? 12 : 2
                                    }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs7: "" } },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                attrs: { "primary-title": "" }
                                              },
                                              [
                                                _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "display-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.trabajadores)
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs5: "" } },
                                          [
                                            _c(
                                              "v-avatar",
                                              {
                                                staticStyle: {
                                                  height: "120px",
                                                  width: "50px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      dark: "",
                                                      size: "55"
                                                    }
                                                  },
                                                  [_vm._v("supervisor_account")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-divider", { attrs: { light: "" } }),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-actions",
                                      { staticClass: "pa-3" },
                                      [_c("div", [_vm._v("Trabajadores")])]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "white--text",
                                    class: { "on-hover": hover },
                                    attrs: {
                                      href: "/clientes",
                                      color: "#ff5617",
                                      elevation: hover ? 12 : 2
                                    }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs7: "" } },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                attrs: { "primary-title": "" }
                                              },
                                              [
                                                _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "display-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.clientes)
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs5: "" } },
                                          [
                                            _c(
                                              "v-avatar",
                                              {
                                                staticStyle: {
                                                  height: "120px",
                                                  width: "50px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      dark: "",
                                                      size: "55"
                                                    }
                                                  },
                                                  [_vm._v("group")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-divider", { attrs: { light: "" } }),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-actions",
                                      { staticClass: "pa-3" },
                                      [_c("div", [_vm._v("Clientes")])]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "white--text",
                                    class: { "on-hover": hover },
                                    attrs: {
                                      href: "/prospectos",
                                      color: "#ffc107",
                                      elevation: hover ? 12 : 2
                                    }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs7: "" } },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                attrs: { "primary-title": "" }
                                              },
                                              [
                                                _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "display-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.prospectos)
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs5: "" } },
                                          [
                                            _c(
                                              "v-avatar",
                                              {
                                                staticStyle: {
                                                  height: "120px",
                                                  width: "50px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      dark: "",
                                                      size: "55"
                                                    }
                                                  },
                                                  [_vm._v("next_week")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-divider", { attrs: { light: "" } }),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-actions",
                                      { staticClass: "pa-3" },
                                      [_c("div", [_vm._v("Prospectos")])]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "white--text",
                                    class: { "on-hover": hover },
                                    attrs: {
                                      href: "/fraccionamientos",
                                      color: "#ffc107",
                                      elevation: hover ? 12 : 2
                                    }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs7: "" } },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                attrs: { "primary-title": "" }
                                              },
                                              [
                                                _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "display-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.fraccionamientos
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs5: "" } },
                                          [
                                            _c(
                                              "v-avatar",
                                              {
                                                staticStyle: {
                                                  height: "120px",
                                                  width: "50px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      dark: "",
                                                      size: "55"
                                                    }
                                                  },
                                                  [_vm._v("home_work")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-divider", { attrs: { light: "" } }),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-actions",
                                      { staticClass: "pa-3" },
                                      [_c("div", [_vm._v("Fraccionamientos")])]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "white--text",
                                    class: { "on-hover": hover },
                                    attrs: {
                                      href: "/urbanizacion",
                                      color: "#ff5617",
                                      elevation: hover ? 12 : 2
                                    }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs7: "" } },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                attrs: { "primary-title": "" }
                                              },
                                              [
                                                _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "display-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.urbanizacion)
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs5: "" } },
                                          [
                                            _c(
                                              "v-avatar",
                                              {
                                                staticStyle: {
                                                  height: "120px",
                                                  width: "50px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      dark: "",
                                                      size: "55"
                                                    }
                                                  },
                                                  [_vm._v("domain")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-divider", { attrs: { light: "" } }),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-actions",
                                      { staticClass: "pa-3" },
                                      [
                                        _c("div", [
                                          _vm._v("Conceptos de urbanización")
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "white--text",
                                    class: { "on-hover": hover },
                                    attrs: {
                                      href: "/conceptos",
                                      color: "#3350b9",
                                      elevation: hover ? 12 : 2
                                    }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs7: "" } },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                attrs: { "primary-title": "" }
                                              },
                                              [
                                                _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "display-3"
                                                    },
                                                    [_vm._v(_vm._s(_vm.obras))]
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs5: "" } },
                                          [
                                            _c(
                                              "v-avatar",
                                              {
                                                staticStyle: {
                                                  height: "120px",
                                                  width: "50px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      dark: "",
                                                      size: "55"
                                                    }
                                                  },
                                                  [_vm._v("domain")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-divider", { attrs: { light: "" } }),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-actions",
                                      { staticClass: "pa-3" },
                                      [_c("div", [_vm._v("Conceptos de obra")])]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-flex",
                    { attrs: { xs3: "" } },
                    [
                      _c("v-hover", {
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(ref) {
                              var hover = ref.hover
                              return [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "white--text",
                                    class: { "on-hover": hover },
                                    attrs: {
                                      href: "/prototipos_vivienda",
                                      color: "#52b8c4",
                                      elevation: hover ? 12 : 2
                                    }
                                  },
                                  [
                                    _c(
                                      "v-layout",
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs7: "" } },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                attrs: { "primary-title": "" }
                                              },
                                              [
                                                _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "display-3"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.viviendas)
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs5: "" } },
                                          [
                                            _c(
                                              "v-avatar",
                                              {
                                                staticStyle: {
                                                  height: "120px",
                                                  width: "50px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      dark: "",
                                                      size: "55"
                                                    }
                                                  },
                                                  [_vm._v("house")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-divider", { attrs: { light: "" } }),
                                    _vm._v(" "),
                                    _c(
                                      "v-card-actions",
                                      { staticClass: "pa-3" },
                                      [
                                        _c("div", [
                                          _vm._v("Prototipos de vivienda")
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }