<template>
  <v-layout>
    <v-dialog :value="dialog" persistent max-width="700px" hide-overlay>
      <v-card>
        <v-toolbar dark color="verdeFayal">
          <v-btn
            icon
            dark
            @click="$emit('close');"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Avances de trabajo para la etapa {{idEtapa}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="etapaNombre"
            label="Nombre de la etapa"
            disabled
            :loading="loading"
          />
          
          <etapas-card 
            v-if="getLotes.length > 0"
            ownerName="Lotes"
            :headers="lotes.headers"
            :items="getLotes"
            :startFunction="iniciarLote"
            :stopFunction="detenerLote"
            type="obra"
          />  
          <v-divider />

          <v-card>
            <v-card-text>
              <h4>Manzanas Obra</h4>
              <v-card>
                <v-card-text>
                  <v-expansion-panel class="pt-1">
                    <v-expansion-panel-content
                      v-for="(manzana, index) in getManzanasObra" 
                      :key="index"
                      expand
                    >
                      <template v-slot:actions>
                        <v-icon>expand_more</v-icon>
                      </template>
                      <template v-slot:header>
                        <div>Manzana {{ manzana.nombre }}</div>
                      </template>
                      <v-card>
                        <v-card-text>
                          <v-expansion-panel>
                            <v-expansion-panel-content
                              v-for="(lote, index) in manzana.lotes" 
                              :key="index"
                              expand
                            >
                              <template v-slot:actions>
                                <v-icon>expand_more</v-icon>
                              </template>
                              <template v-slot:header>
                                <div>Lote {{ lote.nombre }}</div>
                              </template>
                              <v-card>
                                <v-card-text v-if="etapa.lotes.find(oLote => lote.id === oLote.id)">
                                    <v-expansion-panel>
                                      <v-expansion-panel-content 
                                        v-for="(concepto, index) in getConceptosArrayFromLote(lote)" 
                                        :key="index"
                                      > 
                                        <template v-slot:actions>
                                          <v-icon>expand_more</v-icon>
                                        </template>
                                        <template v-slot:header>
                                          <div>{{ concepto.nombre }}</div>
                                        </template>
                                        <v-card>
                                          <v-divider></v-divider>
                                          <v-card-actions 
                                              v-for="(rubro, index) in getRubrosArrayFromConcepto(lote, concepto.id)" 
                                              :key="index"
                                              class="pl-4 pr-4"
                                            >
                                            <div>{{rubro.nombre}}</div>
                                            <v-spacer/>
                                            <v-tooltip bottom>
                                              <v-btn
                                                icon
                                                flat
                                                dark
                                                small
                                                slot="activator"
                                                color="info"
                                              >
                                                <v-icon>play_arrow</v-icon>
                                              </v-btn>
                                              <span>Iniciar</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                              <v-btn
                                                icon
                                                flat
                                                dark
                                                small
                                                slot="activator"
                                                color="red"
                                              >
                                                <v-icon>stop</v-icon>
                                              </v-btn>
                                              <span>Finalizar</span>
                                            </v-tooltip>
                                          </v-card-actions>
                                          <v-divider />
                                        </v-card>
                                      </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-card-text>
                              </v-card>               
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
          <v-divider />
          
          <div v-if="getLotes.length < 1">
            <etapas-card 
              ownerName="'Manzanas"
              :headers="manzanas_urbanizacion.headers"
              :items="getManzanasUrbanizacion"
              :startFunction="iniciarManzanaUrbanizacion"
              :stopFunction="detenerManzanaUrbanizacion"
              type="urbanizacion"
            />
            <v-divider />
          </div>
          
          <div v-if="getLotes.length < 1">
            <etapas-card 
              ownerName="'Áreas verdes"
              :headers="manzanas_urbanizacion.headers"
              :items="getAreasVerdes"
              :startFunction="iniciarAreaVerde"
              :stopFunction="detenerAreaVerde"
              type="urbanizacion"
            />          
            <v-divider />
          </div>
          
          <div v-if="getLotes.length < 1">
            <etapas-card 
              ownerName="'Calles"
              :headers="manzanas_urbanizacion.headers"
              :items="getCalles"
              :startFunction="iniciarCalle"
              :stopFunction="detenerCalle"
              type="urbanizacion"
            />          
            <v-divider></v-divider>
          </div>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { errorHandling } from "../../lib/utilities";
import swal from "sweetalert";

export default {
  props: ["dialog", 'idEtapa', 'fraccionamiento', 'propuestaLotificacion', 'showAreasVerdes', 'showCalles', 'dad'],

  data() {
    return {
      loading:true,
      etapaNombre: "",
      etapa: {},
      im_working_on: '',
      dadName:'',
      selectedId : null,
      conceptosAutofill: [],
      rubrossAutofill: [],
      loteData: [],
      manzanas_obra: {
        items:[],
        selected:[],
        lotes:[],
        headers:[
          { sortable: false, text: 'Nombre' },
          { sortable: false, text: 'Horas' },
          { sortable: false, text: 'Dias' },
          { sortable: false, text: 'Estado' },
          { sortable: false, text: 'Acciones' },
        ],
        headersUrbanizacion:[
          { sortable: false, text: 'Distribución' },
          { sortable: false, text: 'Acciones' },
        ]
      },
      lotes: {
        items:[],
        selected:[],
        headers:[
          { sortable: false, text: 'Nombre' },
          { sortable: false, text: 'Horas' },
          { sortable: false, text: 'Dias' },
          { sortable: false, text: 'Estado' },
          { sortable: false, text: 'Manzana' },
          { sortable: false, text: 'Acciones' },
        ],
        headersUrbanizacion:[
          { sortable: false, text: 'Distribución' },
          { sortable: false, text: 'Acciones' },
        ]
      },
      manzanas_urbanizacion: {
        items:[],
        selected:[],
        lotes:[],
        headers:[
          { sortable: false, text: 'Nombre' },
          { sortable: false, text: 'Horas' },
          { sortable: false, text: 'Dias' },
          { sortable: false, text: 'Estado' },
          { sortable: false, text: 'Acciones' },
        ],
        headersUrbanizacion:[
          { sortable: false, text: 'Distribución' },
          { sortable: false, text: 'Acciones' },
        ]
      },
      manzanas: {
        items:[],
        selected:[],
        lotes:[],
        headers:[
          { sortable: false, text: 'Distribución' },
          { sortable: false, text: 'Lotes' },
          { sortable: false, text: 'Lotes seleccionados' },
        ],
        headersUrbanizacion:[
          { sortable: false, text: 'Distribución' },
          { sortable: false, text: 'Acciones' },
        ]
      },
      areasVerdes: {
        items:[],
        selected:[],
        headers:[
          { sortable: false, text: 'Distribución' },
          { sortable: false, text: 'Acciones' },
        ]
      },
      calles: {
        items:[],
        selected:[],
        headers:[
          { sortable: false, text: 'Distribución' },
          { sortable: false, text: 'Acciones' },
        ]
      },
      localFraccionamiento: {},
      dialogConceptosRubros:false,
      conceptosToSend: [],
      conceptosDad: '',
    };
  },
  watch:{
    dialog: function(value){
      if(!value){
        this.etapaNombre = "";
        this.manzanas_obra.items =  [];
        this.manzanas_urbanizacion.items =  [];
        this.areasVerdes.items =  [];
        this.calles.items =  [];
        this.lotes.items =  [];
      } else {
        this.etapaFinder();
        Object.assign(this.localFraccionamiento, this.fraccionamiento);
      }
    },
    propuestaLotificacion: function(value){
      this.manzanas.items =  this.propuestaLotificacion.prospecto.propuesta_lotificacion[0].manzanas;
      this.areasVerdes.items =  this.propuestaLotificacion.prospecto.propuesta_lotificacion[0].areas_verdes;
      this.calles.items =  this.propuestaLotificacion.prospecto.propuesta_lotificacion[0].calles;
    },
  },
  computed: {
    getManzanasObra: function(){
      if (this.etapa.tipo === 'obra')
        return this.etapa.fraccionamiento.prospecto.propuesta_lotificacion[0].manzanas;
      else []
    },
    getManzanasUrbanizacion: function(){
      return this.manzanas_urbanizacion.items;
    },
    getAreasVerdes: function(){
      return this.areasVerdes.items;
    },
    getCalles: function(){
      return this.calles.items;
    },
    getLotes: function(){
      return this.lotes.items;
    },
    getActividades: function () {
      return this.etapa.actividades;
    }
  },
  methods: {
    etapaFinder: function (){
      this.loading = true
      axios.get(route("etapas.show", this.idEtapa))
        .then(response => {
          this.etapa = response.data
          this.etapaNombre = response.data.nombre;
          if(response.data.tipo == "urbanizacion"){
            this.manzanas_urbanizacion.items = response.data.manzanas_etapas;
            this.calles.items = response.data.calles;
            this.areasVerdes.items = response.data.areas_verdes;
          } else {
            this.manzanas_obra.items = response.data.manzanas_etapas;
            this.lotes.items = response.data.lotes;
          }
        })
        .catch(error => errorHandling(error))
        .finally(() => this.loading = false);
    },
    getLotesManzasEtapas: function (manzanas_etapas) {
      let lotes = [];

      manzanas_etapas.forEach(manzana_etapa => {
        
      });

      return lotes;
    },
    iniciarLote: function(id){
      swal({
          title: "¿Estás seguro?",
          text: "Una vez iniciado no se puede revertir.",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(result => {
          if (result) {
            axios.post(`/estados/lote_etapa`, {
              lote_etapa_id: id,
              estado: 29,
            })
            .then(response => {
              alertify.success(response.data.message_text);
              this.etapaFinder();
            })
            .catch(error => {
              errorHandling(error);
            });
          }
        })
    },
    iniciarManzanaUrbanizacion: function(id){
      swal({
          title: "¿Estás seguro?",
          text: "Una vez iniciado no se puede revertir.",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(result => {
          if (result) {
            axios.post(`/estados/manzana_etapa`, {
              manzana_etapa_id: id,
              estado: 32,
            })
            .then(response => {
              alertify.success(response.data.message_text);
              this.etapaFinder();
            })
            .catch(error => {
              errorHandling(error);
            });
          }
        })
    },
    iniciarAreaVerde: function(id){
      swal({
          title: "¿Estás seguro?",
          text: "Una vez iniciado no se puede revertir.",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(result => {
          if (result) {
            axios.post(`/estados/areas_verdes_etapa`, {
              areas_verdes_etapa_id: id,
              estado: 35,
            })
            .then(response => {
              alertify.success(response.data.message_text);
              this.etapaFinder();
            })
            .catch(error => {
              errorHandling(error);
            });
          }
        })
    },
    iniciarCalle: function(id){
      swal({
          title: "¿Estás seguro?",
          text: "Una vez iniciado no se puede revertir.",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(result => {
          if (result) {
            axios.post(`/estados/calles_etapa`, {
              calles_etapa_id: id,
              estado: 38,
            })
            .then(response => {
              alertify.success(response.data.message_text);
              this.etapaFinder();
            })
            .catch(error => {
              errorHandling(error);
            });
          }
        })
    },
    detenerLote: function(id){
      swal({
          title: "¿Estás seguro?",
          text: "Una vez iniciado no se puede revertir.",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(result => {
          if (result) {
            axios.post(`/estados/lote_etapa`, {
              lote_etapa_id: id,
              estado: 30,
            })
            .then(response => {
              alertify.success(response.data.message_text);
              this.etapaFinder();
            })
            .catch(error => {
              errorHandling(error);
            });
          }
        })
    },
    detenerManzanaUrbanizacion: function(id){
      swal({
          title: "¿Estás seguro?",
          text: "Una vez iniciado no se puede revertir.",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(result => {
          if (result) {
            axios.post(`/estados/manzana_etapa`, {
              manzana_etapa_id: id,
              estado: 33,
            })
            .then(response => {
              alertify.success(response.data.message_text);
              this.etapaFinder();
            })
            .catch(error => {
              errorHandling(error);
            });
          }
        })
    },
    detenerAreaVerde: function(id){
      swal({
          title: "¿Estás seguro?",
          text: "Una vez iniciado no se puede revertir.",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(result => {
          if (result) {
            axios.post(`/estados/areas_verdes_etapa`, {
              areas_verdes_etapa_id: id,
              estado: 36,
            })
            .then(response => {
              alertify.success(response.data.message_text);
              this.etapaFinder();
            })
            .catch(error => {
              errorHandling(error);
            });
          }
        })
    },
    detenerCalle: function(id){
      swal({
          title: "¿Estás seguro?",
          text: "Una vez iniciado no se puede revertir.",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(result => {
          if (result) {
            axios.post(`/estados/calles_etapa`, {
              calles_etapa_id: id,
              estado: 39,
            })
            .then(response => {
              alertify.success(response.data.message_text);
              this.etapaFinder();
            })
            .catch(error => {
              errorHandling(error);
            });
          }
        })
    },
    getConceptosArrayFromLote: function (lote) {
      let conceptos = this.etapa.lotes.find(oLote => lote.id === oLote.id)
      .actividades.map(function (actividad) {
        return actividad.concepto;
      });

      const conceptosFiltered = new Set();
      return conceptos.filter(concepto => {
        const duplicated = conceptosFiltered.has(concepto.id);
        conceptosFiltered.add(concepto.id);
        return !duplicated;
      })
    },
    getRubrosArrayFromConcepto: function (lote, concepto_id) {
      let rubros = this.etapa.lotes.find(oLote => lote.id === oLote.id)
      .actividades.filter(actividad => actividad.concepto_id === concepto_id)

      return rubros.map(function (actividad) {
        return actividad.rubro;
      })
    }
  }
};
</script>