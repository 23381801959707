<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Datos de tarjeta</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md12>
                <v-subheader>Información personal</v-subheader>
            </v-flex>
            <v-flex md12>
                <v-text-field v-model="trabajador.nombre_completo" label="Nombre completo" readonly></v-text-field>
            </v-flex>
            <v-flex md4>
                <v-text-field v-model="trabajador.grado_de_estudios" label="Grado de estudio" readonly></v-text-field>
            </v-flex>
            <v-flex md4>
                <v-text-field v-model="trabajador.estado_civil" label="Estado civil" readonly></v-text-field>
            </v-flex>
            <v-flex md4>
                <v-text-field v-model="trabajador.rfc" label="RFC" readonly></v-text-field>
            </v-flex>
            <v-flex md4>
                <v-text-field v-model="trabajador.curp" label="CURP" readonly></v-text-field>
            </v-flex>   
            <v-flex md4>
                <v-text-field v-model="trabajador.estado" label="Estado" readonly></v-text-field>
            </v-flex>  
            <v-flex md4>
                <v-text-field v-model="trabajador.fecha_de_contratacion" label="Fecha de contratación" readonly></v-text-field>
            </v-flex> 
          </v-layout>
          <v-layout wrap v-if="trabajador.beneficiario">
            <v-flex md12>
                <v-subheader>Información del beneficiario</v-subheader>
            </v-flex>
            <v-flex md12>
                <v-text-field v-model="trabajador.beneficiario.nombre_completo" label="Nombre completo" readonly></v-text-field>
            </v-flex>
            <v-flex md4>
                <v-text-field v-model="trabajador.beneficiario.parentesco" label="Parentesco" readonly></v-text-field>
            </v-flex>
            <v-flex md4>
                <v-text-field v-model="trabajador.beneficiario.rfc" label="RFC" readonly></v-text-field>
            </v-flex>
            <v-flex md4>
                <v-text-field v-model="trabajador.fecha_de_nacimiento" label="Fecha de nacimiento" readonly></v-text-field>
            </v-flex>  
            <v-flex md4>
                <v-text-field v-model="trabajador.beneficiario.direccion" label="Dirección" readonly></v-text-field>
            </v-flex> 
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    trabajador: {},
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    workerId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    workerId: function (value) {
      this.getWorker();
    },
  },
  methods: {
    getWorker: function() {
      axios.get(`/trabajadores/${this.workerId}`)
      .then( response => this.trabajador = response.data)
      .catch( error =>  errorHandling(error));
    }
  }
}
</script>
