<template>
  <v-container>
    <v-layout>
      <v-flex xs12>
        <v-toolbar color="white" flat tabs>
          <v-toolbar-title>Viviendas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-btn v-if="(vTabs == 'listado-tab') && ($root.can('new', permissions_prototipo) ? true : false) " flat color="verdeFayal" @click="openAddHouseholdModal">
            <v-icon right dark>add</v-icon>Crear vivienda
          </v-btn>
          <v-text-field
            v-if="vTabs == 'listado-tab'"
            color="verdeFayal"
            single-line
            label="Buscar"
            append-icon="search"
            v-model="search"
          ></v-text-field>

          <v-btn
            v-if="(vTabs == 'conceptos-tab') && ($root.can('new', permissions_concepto) ? true : false)"
            flat
            color="verdeFayal"
            @click="openAddConceptsModal"
          >
            <v-icon right dark>add</v-icon>Añadir Concepto
          </v-btn>
          <v-text-field
            v-if="vTabs == 'conceptos-tab'"
            color="verdeFayal"
            single-line
            label="Buscar"
            append-icon="search"
            v-model="search"
          ></v-text-field>

          <template v-slot:extension>
            <v-tabs v-model="vTabs" centered slider-color="verdeFayal">
              <v-tab href="#listado-tab" v-if="$root.can('index', permissions_prototipo) ? true : false" >Listado</v-tab>
              <v-tab href="#conceptos-tab" v-if="$root.can('index', permissions_concepto) ? true : false">Conceptos y Rubros</v-tab>
              <v-tab href="#impuesto-tab" v-if="$root.can('index', permissions_impuesto) ? true : false">Impuesto</v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-tabs-items v-model="vTabs">
          <v-tab-item value="listado-tab">
            <v-card flat>
              <households-index v-bind:search.sync="search" :permissions="permissions_prototipo"></households-index>
            </v-card>
          </v-tab-item>
          <v-tab-item value="conceptos-tab">
            <v-card flat>
              <concepts-index v-bind:search.sync="search" v-bind:updateConceptos="showConceptsModal" :permissions="permissions_concepto" :permissions_rubro="permissions_rubro"></concepts-index>
            </v-card>
          </v-tab-item>
          <v-tab-item value="impuesto-tab">
            <v-card flat>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex md6>
                    <v-text-field v-model="impuesto.valor" type="number" required label="Impuesto (%)" min="0" max="100" :disabled="!$root.can('update', permissions_impuesto)"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark color="verdeFayal" @click="updateImpuesto" flat :loading="vBtnSave.loading" :disabled="!$root.can('update', permissions_impuesto)"> Guardar </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>
    </v-layout>
    <!-- Modals  -->
    <create-households-modal v-bind:show.sync="showHouseholdModal"></create-households-modal>
    <create-concepts-modal v-bind:show.sync="showConceptsModal" :permissions="permissions_rubro"></create-concepts-modal>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      vTabs: "listado-tab",
      search: null,
      showHouseholdModal: false,
      showConceptsModal: false,
      impuesto: {
        valor: null
      },
      vBtnSave: {
        loading: false
      }
    };
  },
  props:['permissions_prototipo', 'permissions_concepto', 'permissions_rubro', 'permissions_impuesto'],
  methods: {
    openAddHouseholdModal: function() {
      this.showHouseholdModal = true;
    },
    openAddConceptsModal: function() {
      this.showConceptsModal = true;
    },
    getImpuesto: function() {
      axios.get(laravelRoute('configuraciones.show', 1))
      .then(response => {
        this.impuesto = response.data;
      })
      .catch(error => errorHandling(error));
    },
    updateImpuesto: function() {
      this.vBtnSave.loading = true;
      axios.patch(laravelRoute('configuraciones.show', 1), {
        valor: this.impuesto.valor 
      })
      .then(response => {
        this.vBtnSave.loading = false;
        alertify.success(response.data.message_text);
        this.getImpuesto();
      })
      .catch(error => {
        this.vBtnSave.loading = false;
        errorHandling(error)
      });
    }
  },
  mounted: function () {
    this.getImpuesto();
  }
};
</script>
