var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1050px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v(
                      "Pagos asociados a " +
                        _vm._s(
                          _vm.area_nomina_trabajador.trabajador.nombre_completo
                        )
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                "fixed-tabs": "",
                                centered: "",
                                color: "white",
                                "slider-color": "#1aba24"
                              },
                              model: {
                                value: _vm.vTabs,
                                callback: function($$v) {
                                  _vm.vTabs = $$v
                                },
                                expression: "vTabs"
                              }
                            },
                            [
                              _c(
                                "v-tab",
                                { attrs: { href: "#tab-index-pagos" } },
                                [_c("span", [_vm._v("Pagos asociados")])]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab",
                                { attrs: { href: "#tab-agregar-pago" } },
                                [_c("span", [_vm._v("Agregar pago")])]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-tabs-items",
                            {
                              model: {
                                value: _vm.vTabs,
                                callback: function($$v) {
                                  _vm.vTabs = $$v
                                },
                                expression: "vTabs"
                              }
                            },
                            [
                              _c(
                                "v-tab-item",
                                { attrs: { value: "tab-index-pagos" } },
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _vm.area_nomina_trabajador.puesto ==
                                          "Destajo"
                                            ? _c("v-data-table", {
                                                attrs: {
                                                  items: _vm.pagos.data,
                                                  "hide-actions": ""
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "headers",
                                                      fn: function(props) {
                                                        return [
                                                          _c("tr", [
                                                            _c("th", [
                                                              _vm._v(
                                                                "Fraccionamiento"
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("th", [
                                                              _vm._v("Etapa")
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("th", [
                                                              _vm._v(
                                                                "Distribución"
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("th", [
                                                              _vm._v("Lote")
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("th", [
                                                              _vm._v("Concepto")
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("th", [
                                                              _vm._v("Rubro")
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("th", [
                                                              _vm._v("Monto")
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("th", [
                                                              _vm._v("Acciones")
                                                            ])
                                                          ])
                                                        ]
                                                      }
                                                    },
                                                    {
                                                      key: "items",
                                                      fn: function(props) {
                                                        return [
                                                          _c("tr", [
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  props.item
                                                                    .fraccionamiento
                                                                    .nombre_proyecto
                                                                )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  props.item
                                                                    .etapa
                                                                    .nombre
                                                                )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  props.item
                                                                    .distribucion
                                                                )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  props.item
                                                                    .distribucion ==
                                                                    "Manzana"
                                                                    ? null
                                                                    : "No aplica"
                                                                )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  props.item
                                                                    .concepto
                                                                    ? props.item
                                                                        .concepto
                                                                        .nombre
                                                                    : null
                                                                )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  props.item
                                                                    .rubro
                                                                    ? props.item
                                                                        .rubro
                                                                        .nombre
                                                                    : null
                                                                )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                "$ " +
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "currency"
                                                                    )(
                                                                      props.item
                                                                        .monto
                                                                    )
                                                                  )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "td",
                                                              [
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          slot:
                                                                            "activator",
                                                                          icon:
                                                                            "",
                                                                          flat:
                                                                            "",
                                                                          dark:
                                                                            "",
                                                                          small:
                                                                            "",
                                                                          color:
                                                                            "error"
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.deletePago(
                                                                              props
                                                                                .item
                                                                                .id
                                                                            )
                                                                          }
                                                                        },
                                                                        slot:
                                                                          "activator"
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "delete"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Eliminar"
                                                                      )
                                                                    ])
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ])
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3352708355
                                                )
                                              })
                                            : _c("v-data-table", {
                                                attrs: {
                                                  items: _vm.pagos.data,
                                                  "hide-actions": ""
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "headers",
                                                    fn: function(props) {
                                                      return [
                                                        _c("tr", [
                                                          _c("th", [
                                                            _vm._v(
                                                              "Fraccionamiento"
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c("th")
                                                        ])
                                                      ]
                                                    }
                                                  },
                                                  {
                                                    key: "items",
                                                    fn: function(props) {
                                                      return [
                                                        _c("tr", [
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                props.item
                                                                  .fraccionamiento
                                                                  .nombre_proyecto
                                                              )
                                                            )
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "td",
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        slot:
                                                                          "activator",
                                                                        icon:
                                                                          "",
                                                                        flat:
                                                                          "",
                                                                        dark:
                                                                          "",
                                                                        small:
                                                                          "",
                                                                        color:
                                                                          "error"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deletePago(
                                                                            props
                                                                              .item
                                                                              .id
                                                                          )
                                                                        }
                                                                      },
                                                                      slot:
                                                                        "activator"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "delete"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Eliminar"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ])
                                                      ]
                                                    }
                                                  }
                                                ])
                                              }),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c("pagination", {
                                                attrs: {
                                                  limit: 10,
                                                  data: _vm.pagos
                                                },
                                                on: {
                                                  "pagination-change-page":
                                                    _vm.getPagosNomina
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("v-spacer")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-tab-item",
                                { attrs: { value: "tab-agregar-pago" } },
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          !_vm.pago.delete_at
                                            ? _c(
                                                "v-card",
                                                {
                                                  attrs: {
                                                    flat: "",
                                                    outlined: ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    {
                                                      attrs: {
                                                        "grid-list-md": ""
                                                      }
                                                    },
                                                    [
                                                      _vm.area_nombre !=
                                                      "Contabilidad"
                                                        ? _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    md12: ""
                                                                  }
                                                                },
                                                                [
                                                                  !_vm.pago
                                                                    .fraccionamiento_id
                                                                    ? _c(
                                                                        "v-autocomplete",
                                                                        {
                                                                          attrs: {
                                                                            "search-input":
                                                                              _vm.query,
                                                                            items:
                                                                              _vm.fraccionamientos_nomina,
                                                                            loading:
                                                                              _vm.isLoading,
                                                                            "item-text":
                                                                              "nombre_proyecto",
                                                                            color:
                                                                              "verdeFayal",
                                                                            "persistent-hint":
                                                                              "",
                                                                            "item-value":
                                                                              "id",
                                                                            label:
                                                                              "Fraccionamiento",
                                                                            "hide-selected":
                                                                              "",
                                                                            "hide-no-data":
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            "update:searchInput": function(
                                                                              $event
                                                                            ) {
                                                                              _vm.query = $event
                                                                            },
                                                                            "update:search-input": function(
                                                                              $event
                                                                            ) {
                                                                              _vm.query = $event
                                                                            },
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.getEtapasNomina(
                                                                                _vm
                                                                                  .pago
                                                                                  .fraccionamiento
                                                                                  .id
                                                                              )
                                                                            }
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "no-data",
                                                                                fn: function() {
                                                                                  return [
                                                                                    _c(
                                                                                      "v-list-tile",
                                                                                      [
                                                                                        _c(
                                                                                          "v-list-tile-title",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                  Buscar El \n                                  "
                                                                                            ),
                                                                                            _c(
                                                                                              "strong",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Fraccionamiento"
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              "\n                                  a Seleccionar\n                                "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                },
                                                                                proxy: true
                                                                              }
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            4062316773
                                                                          ),
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .pago
                                                                                .fraccionamiento
                                                                                .id,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .pago
                                                                                  .fraccionamiento,
                                                                                "id",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "pago.fraccionamiento.id"
                                                                          }
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "v-text-field",
                                                                        {
                                                                          attrs: {
                                                                            readonly:
                                                                              "",
                                                                            color:
                                                                              "verdeFayal",
                                                                            "single-line":
                                                                              ""
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .pago
                                                                                .fraccionamiento
                                                                                .nombre_proyecto,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm
                                                                                  .pago
                                                                                  .fraccionamiento,
                                                                                "nombre_proyecto",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "pago.fraccionamiento.nombre_proyecto"
                                                                          }
                                                                        }
                                                                      )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.area_nomina_trabajador
                                                        .puesto == "Destajo"
                                                        ? _c(
                                                            "v-layout",
                                                            {
                                                              staticClass:
                                                                "mb-0",
                                                              attrs: {
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  staticClass:
                                                                    "mb-0",
                                                                  attrs: {
                                                                    md6: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      attrs: {
                                                                        disabled: !_vm
                                                                          .pago
                                                                          .fraccionamiento,
                                                                        items:
                                                                          _vm.etapas_nomina,
                                                                        "item-text":
                                                                          "nombre",
                                                                        readonly:
                                                                          _vm.readOnly,
                                                                        "item-value":
                                                                          "id",
                                                                        label:
                                                                          "Etapa",
                                                                        color:
                                                                          "verdeFayal"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .pago
                                                                            .etapa_id,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.pago,
                                                                            "etapa_id",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "pago.etapa_id"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    md6: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      attrs: {
                                                                        disabled: !_vm
                                                                          .pago
                                                                          .fraccionamiento,
                                                                        items: [
                                                                          "Manzana",
                                                                          "Calle",
                                                                          "Área Verde"
                                                                        ],
                                                                        "item-text":
                                                                          "nombre",
                                                                        "item-value":
                                                                          "id",
                                                                        readonly:
                                                                          _vm.readOnly,
                                                                        label:
                                                                          "Distribución",
                                                                        color:
                                                                          "verdeFayal"
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          _vm.getManzanasNomina(
                                                                            _vm
                                                                              .pago
                                                                              .etapa_id
                                                                          ),
                                                                            _vm.getCallesNomina(
                                                                              _vm
                                                                                .pago
                                                                                .etapa_id
                                                                            ),
                                                                            _vm.getAreaNomina(
                                                                              _vm
                                                                                .pago
                                                                                .etapa_id
                                                                            )
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .pago
                                                                            .distribucion,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.pago,
                                                                            "distribucion",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "pago.distribucion"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.pago.distribucion ==
                                                      "Calle"
                                                        ? _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    md12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      attrs: {
                                                                        disabled: !_vm
                                                                          .pago
                                                                          .etapa_id,
                                                                        "return-object":
                                                                          "",
                                                                        "item-text":
                                                                          "nombre",
                                                                        "item-value":
                                                                          "id",
                                                                        readonly:
                                                                          _vm.readOnly,
                                                                        items:
                                                                          _vm.calles_nomina,
                                                                        label:
                                                                          "Calle",
                                                                        color:
                                                                          "verdeFayal"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .pago
                                                                            .calle,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.pago,
                                                                            "calle",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "pago.calle"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm.pago
                                                            .distribucion ==
                                                          "Área Verde"
                                                        ? _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    md12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      attrs: {
                                                                        items:
                                                                          _vm.areas_verdes_nomina,
                                                                        disabled: !_vm
                                                                          .pago
                                                                          .etapa_id,
                                                                        readonly:
                                                                          _vm.readOnly,
                                                                        "item-text":
                                                                          "nombre",
                                                                        color:
                                                                          "verdeFayal",
                                                                        label:
                                                                          "Área verde",
                                                                        "item-value":
                                                                          "id",
                                                                        "return-object":
                                                                          ""
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .pago
                                                                            .area_verde,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.pago,
                                                                            "area_verde",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "pago.area_verde"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm.pago
                                                            .distribucion ==
                                                          "Manzana"
                                                        ? _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    md12: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      attrs: {
                                                                        disabled: !_vm
                                                                          .pago
                                                                          .etapa_id,
                                                                        "item-text":
                                                                          "nombre",
                                                                        "item-value":
                                                                          "id",
                                                                        readonly:
                                                                          _vm.readOnly,
                                                                        items:
                                                                          _vm.manzanas_nomina,
                                                                        color:
                                                                          "verdeFayal",
                                                                        label:
                                                                          "Manzana"
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.getLotesNomina(
                                                                            _vm
                                                                              .pago
                                                                              .etapa_id,
                                                                            _vm
                                                                              .pago
                                                                              .manzanas_lotificacion_id
                                                                          )
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .pago
                                                                            .manzanas_lotificacion_id,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.pago,
                                                                            "manzanas_lotificacion_id",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "pago.manzanas_lotificacion_id"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm.pago.distribucion
                                                        ? _c(
                                                            "v-layout",
                                                            {
                                                              attrs: {
                                                                wrap: ""
                                                              }
                                                            },
                                                            [
                                                              _vm.pago
                                                                .distribucion ==
                                                              "Manzana"
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        md4: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-select",
                                                                        {
                                                                          attrs: {
                                                                            disabled: !_vm
                                                                              .pago
                                                                              .manzanas_lotificacion_id,
                                                                            "item-text":
                                                                              "lote.nombre",
                                                                            "item-value":
                                                                              "id",
                                                                            readonly:
                                                                              _vm.readOnly,
                                                                            color:
                                                                              "verdeFayal",
                                                                            "return-object":
                                                                              "",
                                                                            items:
                                                                              _vm
                                                                                .pago
                                                                                .manzanas_lotificacion_id >
                                                                              0
                                                                                ? _vm.lotes_nomina
                                                                                : [],
                                                                            label:
                                                                              "Lote"
                                                                          },
                                                                          on: {
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.getConceptosNomina(
                                                                                _vm
                                                                                  .pago
                                                                                  .etapa_id,
                                                                                _vm
                                                                                  .pago
                                                                                  .manzanas_lotificacion_id
                                                                                  ? _vm
                                                                                      .pago
                                                                                      .manzanas_lotificacion_id
                                                                                  : 0
                                                                              )
                                                                            }
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .pago
                                                                                .lote_manzana_etapa,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.pago,
                                                                                "lote_manzana_etapa",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "pago.lote_manzana_etapa"
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    md4: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      attrs: {
                                                                        disabled: !_vm
                                                                          .pago
                                                                          .lote_manzana_etapa,
                                                                        "item-text":
                                                                          "nombre",
                                                                        "item-value":
                                                                          "id",
                                                                        readonly:
                                                                          _vm.readOnly,
                                                                        color:
                                                                          "verdeFayal",
                                                                        items: _vm
                                                                          .conceptos_nomina
                                                                          .length
                                                                          ? _vm.conceptos_nomina.find(
                                                                              function(
                                                                                item
                                                                              ) {
                                                                                return (
                                                                                  item.etapa_manzana_lotificacion_id ==
                                                                                  _vm
                                                                                    .pago
                                                                                    .manzanas_lotificacion_id
                                                                                )
                                                                              }
                                                                            )
                                                                              .conceptos
                                                                          : [],
                                                                        label:
                                                                          "Conceptos"
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.getRubrosNomina(
                                                                            _vm
                                                                              .pago
                                                                              .etapa_id,
                                                                            _vm
                                                                              .pago
                                                                              .manzanas_lotificacion_id,
                                                                            _vm
                                                                              .pago
                                                                              .concepto_id,
                                                                            _vm
                                                                              .area_nomina_trabajador
                                                                              .trabajador
                                                                              .ocupacion
                                                                              .id
                                                                          )
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .pago
                                                                            .concepto_id,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.pago,
                                                                            "concepto_id",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "pago.concepto_id"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    md4: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      attrs: {
                                                                        disabled: !_vm
                                                                          .pago
                                                                          .concepto_id,
                                                                        "item-text":
                                                                          "rubro.nombre",
                                                                        "item-value":
                                                                          "rubro_id",
                                                                        readonly:
                                                                          _vm.readOnly,
                                                                        color:
                                                                          "verdeFayal",
                                                                        items: _vm.rubros_nomina.filter(
                                                                          function(
                                                                            rubro
                                                                          ) {
                                                                            return (
                                                                              rubro.rubro !=
                                                                                null &&
                                                                              rubro
                                                                                .rubro
                                                                                .valores !=
                                                                                null
                                                                            )
                                                                          }
                                                                        ),
                                                                        label:
                                                                          "Rubro"
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.getValoresNomina(
                                                                            _vm
                                                                              .pago
                                                                              .rubro_id,
                                                                            _vm
                                                                              .pago
                                                                              .lote_manzana_etapa
                                                                              ? _vm
                                                                                  .pago
                                                                                  .lote_manzana_etapa
                                                                                  .lote
                                                                                  .modelo_id
                                                                              : 0,
                                                                            _vm
                                                                              .area_nomina_trabajador
                                                                              .trabajador
                                                                              .ocupacion
                                                                              .id
                                                                          )
                                                                        }
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .pago
                                                                            .rubro_id,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.pago,
                                                                            "rubro_id",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "pago.rubro_id"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.pago
                                                                .lote_manzana_etapa !=
                                                                null &&
                                                              _vm.pago
                                                                .concepto_id !=
                                                                null &&
                                                              _vm.pago
                                                                .rubro_id !=
                                                                null &&
                                                              !_vm.readOnly
                                                                ? _c(
                                                                    "v-flex",
                                                                    {
                                                                      attrs: {
                                                                        md12: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm.valor !=
                                                                          0
                                                                            ? _c(
                                                                                "v-subheader",
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "verdeFayal--text"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Pago Sugerido: $" +
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "currency"
                                                                                            )(
                                                                                              _vm.valor
                                                                                                ? _vm.valor
                                                                                                : "Sin Pago Sugerido"
                                                                                            )
                                                                                          )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.valor ==
                                                                          0
                                                                            ? _c(
                                                                                "v-subheader",
                                                                                [
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "red--text"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Sin Pago Sugerido, Revise los Precios"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e()
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-flex",
                                                                {
                                                                  attrs: {
                                                                    md4: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Monto",
                                                                        type:
                                                                          "number",
                                                                        readonly:
                                                                          _vm.readOnly,
                                                                        id: "id"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .pago
                                                                            .monto,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.pago,
                                                                            "monto",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "pago.monto"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  !_vm.readOnly && _vm.vTabs == "tab-agregar-pago"
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            dark: "",
                            color: "verdeFayal",
                            flat: "",
                            loading: _vm.vBtnSave.loading,
                            outline: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.storePaymentWorker()
                            }
                          }
                        },
                        [_vm._v(" \n          Guardar \n        ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }