<template>
    <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="500px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Seleccione el estado</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
      
        <v-card-text>
          <v-container grid-list-md>
            <v-flex>
              <div class="wrapper-table">
                <span class="table-title">Historial</span>
                <table class="table-history">
                  <thead class="table-head-history">
                    <tr>
                      <th>Estado</th>
                      <th>Usuario</th>
                      <th>Fecha</th>
                    </tr>
                  </thead>
                  <tbody class="table-body-history">
                    <tr v-for="(item, index) in historyStatus" :key="index">
                      <td>{{item.information ? item.information.estado : 'No disponible'}}</td>
                      <td>{{item.user.name}}</td>
                      <td>{{item.created_at}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-flex>
          </v-container>
        </v-card-text>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout>
              <v-flex sm6 md6>
                <div>
                  <h5>Estado actual</h5>
                  <span style="background-color: #1d7423;" class="badge badge-secondary">
                    {{ (currentStatus) ? currentStatus.information.estado : null }}
                  </span>
                </div>
              </v-flex>
              <v-flex sm6 md6>
                <v-select
                  color="verdeFayal"
                  :items="listStatus"
                  label="Seleccione status"
                  v-model="status"
                  item-text="estado"
                  item-value="id"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn outline color="verdeFayal" @click="createStatus" :loading="vBtn.loading" form="status-form" flat>Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import axios from "axios";
  import swal from "sweetalert";
  import { errorHandling } from "../../lib/utilities";

  export default {
    props: ['show', 'modelType', 'modelId'],
    data(){
      return{
        status: [],
        listStatus: [],
        historyStatus: [],
        localEstados: [],
        localShow: false,
        vBtn: {
          loading: false
        },
        vSnackBar: {
          visible: false,
          text: null
        }
      }
    },
    watch:{
        show: function (value) {
            this.localShow = value
        },
        localShow: function (value) {
            this.$emit('update:show', value);
        },
        modelId: function() {
            this.getCurrentStatus();
        }
    },
    computed: {
      currentStatus: function() {
        return this.historyStatus.find(status => status.deleted_at == null);
      },
      historyStatuses: function() {
        return this.historyStatus.filter(status => status.deleted_at != null);
      }
    },
    methods:{
      createStatus: function(){
        this.vBtn.loading = true;
        axios.post("/estados/store", {
          estado: this.status,
          modelType: this.modelType ,
          modelId: this.modelId ,
        })
        .then(response => {
          this.vSnackBar.visible = true;
          this.vSnackBar.text = response.data.message_text;
          this.getCurrentStatus();
        })
        .catch(error => errorHandling(error))
        .finally(() => this.vBtn.loading = false);
      },
      getStatusList: function() {
        axios.get(`/estados/list?model=${this.modelType}`)
        .then(response => this.listStatus = response.data)
        .catch(error => errorHandling(error));
      },
      getCurrentStatus: function() {
        axios.get(`/estados/current?model=${this.modelType}&id=${this.modelId}`)
        .then(response => {
          this.historyStatus = response.data;
          this.status = this.historyStatus.find(status => status.deleted_at == null).information.id;
        })
        .catch(error => errorHandling(error));
      },
    },
    created() {
        this.getStatusList();
    }
  }
</script>