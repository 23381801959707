var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Crear requsición")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: "Nombre de la orden"
                                },
                                model: {
                                  value: _vm.requisiciones.nombre_requisicion,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.requisiciones,
                                      "nombre_requisicion",
                                      $$v
                                    )
                                  },
                                  expression: "requisiciones.nombre_requisicion"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.solicitante,
                                  required: "",
                                  label: "Seleccione solicitante"
                                },
                                model: {
                                  value: _vm.requisiciones.solicitante,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.requisiciones,
                                      "solicitante",
                                      $$v
                                    )
                                  },
                                  expression: "requisiciones.solicitante"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  maxlength: 250,
                                  rows: "3",
                                  counter: "250",
                                  required: "",
                                  label: "Descripcion",
                                  rules: _vm.rules
                                },
                                model: {
                                  value: _vm.requisiciones.descripcion,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.requisiciones,
                                      "descripcion",
                                      $$v
                                    )
                                  },
                                  expression: "requisiciones.descripcion"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            _vm._l(_vm.materiales, function(material, index) {
                              return _c(
                                "v-container",
                                { key: index, attrs: { "grid-list-md": "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { md3: "" } },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.categories,
                                              rules: _vm.rules,
                                              "item-value": "id",
                                              "item-text": "nombre",
                                              label: "Categoria",
                                              "single-line": ""
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.getCurrentMateriales(
                                                  material.categoria_id,
                                                  index
                                                )
                                              }
                                            },
                                            model: {
                                              value: material.categoria_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  material,
                                                  "categoria_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "material.categoria_id"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md3: "" } },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: material.materiales,
                                              rules: _vm.rules,
                                              "return-object": "",
                                              "item-text": "nombre",
                                              label: "Material",
                                              "single-line": ""
                                            },
                                            model: {
                                              value: material.material,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  material,
                                                  "material",
                                                  $$v
                                                )
                                              },
                                              expression: "material.material"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md3: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              value: material.material.unidad,
                                              readonly: "",
                                              required: "",
                                              label: "Unidades",
                                              disabled: "",
                                              rules: _vm.rules
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md3: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "number",
                                              min: "0",
                                              required: "",
                                              rules: _vm.rules,
                                              label: "Cantidad",
                                              step: ".01"
                                            },
                                            model: {
                                              value: material.cantidad,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  material,
                                                  "cantidad",
                                                  $$v
                                                )
                                              },
                                              expression: "material.cantidad"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md3: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "prepend-icon": "attach_money",
                                              value: material.material.precio,
                                              readonly: "",
                                              disabled: "",
                                              required: "",
                                              label: "Precio unitario",
                                              rules: _vm.rules
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md3: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "prepend-icon": "attach_money",
                                              value:
                                                parseInt(
                                                  material.material.precio
                                                ) * parseInt(material.cantidad),
                                              readonly: "",
                                              disabled: "",
                                              label: "Precio total"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md3: "" } },
                                        [
                                          index != 0
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    fab: "",
                                                    flat: "",
                                                    dark: "",
                                                    small: "",
                                                    color: "verdeFayal"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.materiales.splice(
                                                        index,
                                                        1
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { dark: "" } },
                                                    [_vm._v("remove")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-divider")
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-switch", {
                    attrs: { color: "verdeFayal", label: "Seguir registrando" },
                    model: {
                      value: _vm.keepStoring,
                      callback: function($$v) {
                        _vm.keepStoring = $$v
                      },
                      expression: "keepStoring"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: { click: _vm.storeRequisicion }
                    },
                    [_vm._v(" Guardar ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "", color: "verdeFayal" },
                      on: { click: _vm.addMateriales }
                    },
                    [_c("v-icon", [_vm._v("add")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }