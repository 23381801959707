<template>
  <div>
    <v-dialog v-model="localShow" max-width="850px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Editar Requisición</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="vTabs" centered slider-color="verdeFayal">
            <v-tab href="#datos-requisicion-tab">Datos personales</v-tab>
            <v-tab href="#listado-materiales-tab">Listado de materiales</v-tab>
            <v-tab href="#anadir-materiales-tab">Añadir materiales</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="vTabs">
        <v-tab-item value="datos-requisicion-tab">
          <v-card flat>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex md4>
                  <v-text-field v-model="requisiciones.nombre_requisicion" required label="Nombre de la orden"></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-select 
                    v-model="requisiciones.solicitante" 
                    :items="solicitante" 
                    required 
                    label="Seleccione solicitante"
                  ></v-select>
                </v-flex>
                <v-flex md4>
                  <v-select
                    v-model="requisiciones.estado.information.id"
                    :items="estado"
                    label="Seleccione estado"
                    required
                    item-value="id"
                    item-text="estado"
                  ></v-select>
                </v-flex>
                <v-flex md6>
                  <v-select 
                    v-model="requisiciones.proveedor" 
                    :items="proveedores" 
                    required 
                    label="Seleccione proveedor"
                  ></v-select>
                </v-flex>
                <v-flex md6>
                  <v-text-field v-model="requisiciones.descripcion" required label="Descripcion"></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item value="listado-materiales-tab">
          <v-card flat>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex md12>
                  <v-data-table :headers="headers" :items="requisiciones.materiales" class="elevation-1">
                    <template v-slot:items="props">
                      <td>{{ props.item.categoria.nombre }}</td>
                      <td>{{ props.item.nombre }}</td>
                      <td>{{ props.item.unidad }}</td>
                      <td>{{ props.item.cantidad }}</td>
                      <td>{{ props.item.precio_unitario }}</td>
                      <td>{{ props.item.precio_total }}</td>
                      <td>

                      </td>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-tab-item>
        <v-tab-item value="anadir-materiales-tab">
          <v-card flat>
            <v-container grid-list-md v-for="(material, index) in materiales" :key="index">
                <v-layout wrap> 
                  <v-flex md2>
                    <v-autocomplete
                      :items="categories"
                      :rules="rules"
                      @change="getCurrentMateriales(material.categoria_id, index)"
                      v-model="material.categoria_id"
                      item-value="id"
                      item-text="nombre"
                      label="Categorias"
                      single-line
                      style
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex md2>
                    <v-autocomplete
                      :items="material.materiales"
                      v-model="material.material"
                      :rules="rules"
                      return-object
                      item-text="nombre"
                      label="Material"
                      single-line
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex md2>
                    <v-text-field
                      :value="material.material.unidad"
                      readonly
                      required
                      label="Unidades"
                      disabled
                    ></v-text-field>
                  </v-flex>
                  <v-flex md1>
                    <v-text-field
                      v-model="material.cantidad"
                      type="number"
                      min="0"
                      required
                      :rules="rules"
                      label="Cantidad"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md2>
                    <v-text-field
                      prepend-icon="attach_money"
                      :value="material.material.precio"
                      readonly
                      disabled
                      required
                      label="Precio unitario"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md2>
                    <v-text-field
                      prepend-icon="attach_money"
                      :value="parseInt(material.material.precio) * parseInt(material.cantidad)"
                      readonly
                      disabled
                      label="Precio total"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md1>
                    <v-btn
                      v-if="index != 0"
                      @click="materiales.splice(index, 1)"
                      fab
                      flat
                      dark
                      small
                      color="verdeFayal"
                    >
                      <v-icon dark>remove</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark v-if="vTabs == 'datos-requisicion-tab'" color="verdeFayal" @click="updateRequisicion" flat :loading="vBtnSave.loading"> Guardar </v-btn>
        <v-btn dark v-if="vTabs == 'anadir-materiales-tab'" color="verdeFayal" @click="materiales.push({categoria_id: null,material: {precio: 0},cantidad: 0,materiales: []})"> <v-icon>add</v-icon></v-btn>
        <v-btn dark v-if="vTabs == 'anadir-materiales-tab'" color="verdeFayal" @click="addMateriales" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data(){
    return{
      requisiciones:{},
      categories: [],
      solicitante: ['solicitante 1','solicitante 2'],
      estado:[],
      proveedores: ['proveedor 1', 'proveedor 2'],
      localShow: false,
      vTabs: "datos-requisicion-tab",
      materiales: [
        {
          categoria_id: null,
          material: {
            precio: 0
          },
          cantidad: 0,
          materiales: [],
        }
      ],
      headers: [
        { text: 'Categoria', value: 'categoria', sortable: false },
        { text: 'Material', value: 'material', sortable: false },
        { text: 'Unidad', value: 'Unidad', sortable: false },
        { text: 'Cantidad', value: 'cantidad', sortable: false },
        { text: 'Precio unitario', value: 'precio unitario', sortable: false },
        { text: 'Precio total', value: 'total', sortable: false },
      ],
      materiales: [
        {
          categoria_id: null,
          material: {
            precio: 0
          },
          cantidad: 0,
          materiales: [],
        }
      ],
      vBtnSave: {
        loading: false
      },
      vSnackBar: {
        visible: false,
        text: null
      },
      rules: [
        v => !!v || 'Este campo es requerido',
      ],
      valid: false,
    }
  },
  props: {
    show: Boolean,
    materialId: Number
  },
  methods: {
    updateRequisicion: function(){
      this.vBtnSave.loading = true;
      axios.patch('/requisiciones/' + this.materialId, {
        nombre_requisicion: this.requisiciones.nombre_requisicion,
        solicitante: this.requisiciones.solicitante,
        proveedor: this.requisiciones.proveedor,
        descripcion: this.requisiciones.descripcion
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.vBtnSave.loading = false;
        this.localShow = false;
      })
      .catch(error => {
        this.vBtnSave.loading = false;
        errorHandling(error)
      });
    },
    getRequisicionAlmacen: function(){
      axios.get(laravelRoute('requisiciones.show', this.materialId))
      .then(response => this.requisiciones = response.data)
      .catch(error => errorHandling(error));
    },
    getCategories: function () {
      axios.get(laravelRoute('categorias.index',{
        query: "Materiales de maquinaría"
      }))
      .then(response => {
        this.categories = response.data.data;

      })
      .catch(error => errorHandling(error));
    },
    getCurrentMateriales: function(category_id, index) {
      var categories = [];
      this.categories.map(function(category) {
        categories.push(category);
      });
      this.materiales[index].materiales = categories.find(function(category) {
        return category.id == category_id;
      }).materiales;
    },
    addMateriales: function() {
      this.vBtnSave.loading = true;
      axios.post(laravelRoute('requisiciones.materiales'),{
        materiales: this.materiales,
        requisicion_id: this.materialId
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.getRequisicionAlmacen();
        this.vTabs = "listado-materiales-tab";
        this.vBtnSave.loading = false;
        this.materiales = [
          {
            categoria_id: null,
            material: {
              precio: 0
            },
            cantidad: 0,
            materiales: [],
          }
        ]
      })
      .catch(error => errorHandling(error));
    },
    getStatus: function(){
    axios.get('/requisiciones/estados').then(response => {
        this.estado = response.data;
      });
    },
  },
  watch:{
    show: function (value) {
      this.localShow = value
    },
    materialId: function (value) {
      this.getRequisicionAlmacen();
    },
    localShow: function (value) {
      this.$emit('update:show', value)
    }
  },
  created(){
    this.getCategories();
    this.getStatus();
  }
}
</script>y