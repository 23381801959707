var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Crear salida de almacén")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: "Nombre de la orden",
                                  readonly: ""
                                },
                                model: {
                                  value: _vm.salidas.nombre_de_orden,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.salidas,
                                      "nombre_de_orden",
                                      $$v
                                    )
                                  },
                                  expression: "salidas.nombre_de_orden"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  required: "",
                                  label: "Fecha de solicitud",
                                  type: "date"
                                },
                                model: {
                                  value: _vm.salidas.fecha_solicitud,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.salidas,
                                      "fecha_solicitud",
                                      $$v
                                    )
                                  },
                                  expression: "salidas.fecha_solicitud"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.solicitante,
                                  required: "",
                                  label: "Seleccione solicitante"
                                },
                                model: {
                                  value: _vm.salidas.solicitante,
                                  callback: function($$v) {
                                    _vm.$set(_vm.salidas, "solicitante", $$v)
                                  },
                                  expression: "salidas.solicitante"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md6: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.estado,
                                  "item-value": "id",
                                  "item-text": "estado",
                                  label: "Seleccione estado",
                                  required: ""
                                },
                                model: {
                                  value: _vm.salidas.estado,
                                  callback: function($$v) {
                                    _vm.$set(_vm.salidas, "estado", $$v)
                                  },
                                  expression: "salidas.estado"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-switch", {
                    attrs: { color: "verdeFayal", label: "Seguir registrando" },
                    model: {
                      value: _vm.keepStoring,
                      callback: function($$v) {
                        _vm.keepStoring = $$v
                      },
                      expression: "keepStoring"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: { click: _vm.storeMaterial }
                    },
                    [_vm._v(" Guardar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }