var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Nuevo usuario")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  chips: "",
                                  items: _vm.vAutocompleteWorker.workers,
                                  loading: _vm.vAutocompleteWorker.isLoading,
                                  "search-input":
                                    _vm.vAutocompleteWorker.search,
                                  label: "Trabajador",
                                  "item-text": "nombre_completo",
                                  "item-value": "id",
                                  "browser-autocomplete": "off",
                                  hint: "Ingresa por lo menos 3 caracteres",
                                  "return-object": ""
                                },
                                on: {
                                  "update:searchInput": function($event) {
                                    return _vm.$set(
                                      _vm.vAutocompleteWorker,
                                      "search",
                                      $event
                                    )
                                  },
                                  "update:search-input": function($event) {
                                    return _vm.$set(
                                      _vm.vAutocompleteWorker,
                                      "search",
                                      $event
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.vAutocompleteWorker.select,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.vAutocompleteWorker,
                                      "select",
                                      $$v
                                    )
                                  },
                                  expression: "vAutocompleteWorker.select"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.roles,
                                  "item-text": "name",
                                  "item-value": "name",
                                  chips: "",
                                  label: "Rol",
                                  "single-line": ""
                                },
                                model: {
                                  value: _vm.rolesSelected,
                                  callback: function($$v) {
                                    _vm.rolesSelected = $$v
                                  },
                                  expression: "rolesSelected"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "email",
                                  required: "",
                                  label: "Correo electrónico"
                                },
                                model: {
                                  value: _vm.usuario.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.usuario, "email", $$v)
                                  },
                                  expression: "usuario.email"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "password",
                                  required: "",
                                  label: "Contraseña"
                                },
                                model: {
                                  value: _vm.usuario.contraseña,
                                  callback: function($$v) {
                                    _vm.$set(_vm.usuario, "contraseña", $$v)
                                  },
                                  expression: "usuario.contraseña"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { md12: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  type: "password",
                                  required: "",
                                  label: "Confirmar contraseña"
                                },
                                model: {
                                  value: _vm.usuario.confirmar_contraseña,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.usuario,
                                      "confirmar_contraseña",
                                      $$v
                                    )
                                  },
                                  expression: "usuario.confirmar_contraseña"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-switch", {
                    attrs: { color: "verdeFayal", label: "Seguir registrando" },
                    model: {
                      value: _vm.keepStoring,
                      callback: function($$v) {
                        _vm.keepStoring = $$v
                      },
                      expression: "keepStoring"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: { click: _vm.storeUser }
                    },
                    [_vm._v(" Guardar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }