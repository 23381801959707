<template>
    <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn flat icon href="/fraccionamientos">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>

        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-toolbar card prominent>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="body-2 grey--text">Crear etapa para el fraccionamiento {{fraccionamiento.nombre_proyecto}} </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout row wrap>
                                <v-flex md6>
                                    <v-text-field
                                        label="Nombre de la etapa"
                                        v-model="etapa.nombre"
                                    ></v-text-field>
                                </v-flex>
                                <v-flex md6>
                                    <v-autocomplete
                                        hint="Buscar manzana, calle o área verde a trabajar"
                                        @keyup.enter.native="addDistribucion"
                                        :search-input.sync="search"
                                        prepend-inner-icon="search"
                                        :items="distribuciones"
                                        :loading="isLoading"
                                        item-text="nombre"
                                        v-model="selected"
                                        item-value="id"
                                        persistent-hint
                                        label="Buscar"
                                        return-object
                                        hide-selected
                                        hide-no-data
                                    >
                                        <template v-slot:append-outer>
                                            <v-slide-x-reverse-transition mode="out-in">
                                                <v-icon color="success" :disabled="!selected" @click="addDistribucion">add</v-icon>
                                            </v-slide-x-reverse-transition>
                                        </template>
                                    </v-autocomplete>
                                </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                            <v-layout row wrap>
                                <v-flex>
                                    <v-tabs v-model="vTabs" fixed-tabs centered color="white" slider-color="#1aba24">
                                        <v-tab href="#tab-manzanas">Manzanas</v-tab>
                                        <v-tab href="#tab-calles">Calles</v-tab>
                                        <v-tab href="#tab-areas-verdes">Áreas verdes</v-tab>
                                    </v-tabs>
                                    <v-tabs-items v-model="vTabs">
                                        <v-tab-item value="tab-manzanas">
                                            <v-card flat>
                                                <v-container>
                                                    <v-card-text>
                                                        <table class="table table-striped table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Manzana</th>
                                                                    <th>Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(manzana, index) in selectManzanas" :key="index">
                                                                    <td>{{ manzana.nombre }}</td>
                                                                    <td class="text-right">
                                                                        <v-layout>
                                                                            <v-btn flat icon color="red" @click="selectManzanas.splice(index, 1)">
                                                                                <v-icon>delete</v-icon>
                                                                            </v-btn>
                                                                        </v-layout>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </v-card-text>
                                                </v-container>
                                            </v-card>
                                        </v-tab-item>
                                        <v-tab-item value="tab-calles">
                                            <v-card flat>
                                                <v-container>
                                                    <v-card-text>
                                                        <table class="table table-striped table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Calle</th>
                                                                    <th>Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(calle, index) in selectCalles" :key="index">
                                                                    <td>{{ calle.nombre }}</td>
                                                                    <td>
                                                                        <v-layout>
                                                                            <v-btn flat icon color="red" @click="selectCalles.splice(index, 1)">
                                                                                <v-icon>delete</v-icon>
                                                                            </v-btn>
                                                                        </v-layout>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </v-card-text>
                                                </v-container>
                                            </v-card>
                                        </v-tab-item>
                                        <v-tab-item value="tab-areas-verdes">
                                            <v-card flat>
                                                <v-container>
                                                    <v-card-text>
                                                        <table class="table table-striped table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Área verde</th>
                                                                    <th>Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="(area, index) in selectAreas" :key="index">
                                                                    <td>{{ area.nombre }}</td>
                                                                    <td>
                                                                        <v-layout>
                                                                            <v-btn flat icon color="red" @click="selectAreas.splice(index, 1)">
                                                                                <v-icon>delete</v-icon>
                                                                            </v-btn>
                                                                        </v-layout>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </v-card-text>
                                                </v-container>
                                            </v-card>
                                        </v-tab-item>
                                    </v-tabs-items>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn dark outline color="verdeFayal" flat @click="storeEtapa" :loading="vBtnSave.loading">
                            Continuar y guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<script>
    import moment from "moment";
    import { errorHandling, laravelRoute } from "../../lib/utilities";

    export default {
        data: () => ({
            fraccionamiento: {},
            search: null,
            etapa: {
                nombre: null,
            },
            selected: null,
            isLoading: false,
            distribuciones: [],
            selectManzanas: [],
            selectAreas: [],
            selectCalles: [],
            vTabs: 'tab-manzanas',
            vBtnSave: {
                loading: false
            },
            vSnackBar: {
                visible: false,
                text: null
            },
        }),
        props: {
            fraccionamientoId: String
        },
        watch: {
            search: function(){
                this.getDistribuciones();
            },
            vTabs: function(){
                this.search = null;
                this.selected = null;
            }
        },
        computed: {
            currentDistribucion: function() {
                if(this.vTabs == 'tab-manzanas') {
                    return 'manzanas';
                }
                else if(this.vTabs == 'tab-calles') {
                    return 'calles';
                }
                else if(this.vTabs == 'tab-areas-verdes') {
                    return 'areas-verdes';
                }
            }
        },
        methods: {
            getFraccionamiento: function() {
                axios.get(`/fraccionamientos/${this.fraccionamientoId}/refactor`)
                .then(response => this.fraccionamiento = response.data)
                .catch(error => errorHandling(error));
            },
            getDistribuciones: function() {
                this.isLoading = true;
                axios.get(`/etapas/${this.fraccionamientoId}/distribuciones?tipo_distribucion=${this.currentDistribucion}&search=${this.search}`)
                .then(response => this.distribuciones = response.data.data)
                .catch(error => errorHandling(error))
                .finally(() => this.isLoading = false);
            },
            storeEtapa: function() {
                this.vBtnSave.loading = true;
                axios.post(laravelRoute('etapas.urbanizacion.store'),{
                    etapa: this.etapa,
                    manzanas: this.selectManzanas,
                    calles: this.selectCalles,
                    areas_verdes: this.selectAreas,
                    fraccionamiento_id: this.fraccionamientoId
                })
                .then(response => {
                    this.vSnackBar.text = response.data.message_text;
                    this.vSnackBar.visible = true;
                    setTimeout(() => window.location.href = `/etapas/${response.data.etapa_id}/edit`, 1500);
                })
                .finally(() => this.vBtnSave.loading = false)
                .catch(error => errorHandling(error));
            },
            addDistribucion: function() {
                if(this.vTabs == 'tab-manzanas') {
                    if(!this.selectManzanas.some(e => e.id === this.selected.id)) {
                        this.selectManzanas.push(this.selected);
                        this.selected = null;
                    }
                    else {
                        alertify.error('Este elemento ya se habia agregado anteriormente');
                    }
                }
                else if(this.vTabs == 'tab-calles') {
                    if(!this.selectCalles.some(e => e.id === this.selected.id)) {
                        this.selectCalles.push(this.selected);
                        this.selected = null;
                    }
                    else {
                        alertify.error('Este elemento ya se habia agregado anteriormente');
                    }
                }
                else if(this.vTabs == 'tab-areas-verdes') {
                    if(!this.selectAreas.some(e => e.id === this.selected.id)) {
                        this.selectAreas.push(this.selected);
                        this.selected = null;
                    }
                    else {
                        alertify.error('Este elemento ya se habia agregado anteriormente');
                    }
                }
            }
        },
        created() {
            this.getFraccionamiento();
        }
    }
</script>
<style>
    .card--flex-toolbar {
        margin-top: -64px;
    }
</style>
