<template>
  <div class="text-xs-center" v-if="compMantenimiento">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Editar mantenimiento</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md6>
              <v-text-field v-model="compMantenimiento.nombre" required label="Nombre del mantenimiento"></v-text-field>
            </v-flex>
            <v-flex md6>
              <v-text-field v-model="compMantenimiento.periodo" required label="Periodo (en días)" type="number"></v-text-field>
            </v-flex>
            <v-flex md6>
              <v-text-field v-model="compMantenimiento.duracion" required label="Duración (en horas)" type="number"></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="updateMantenimiento" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
  </div>
</template>

<script>
  export default {
    data(){
      return{
        localShow: false,
        mantenimiento: {
          materiales:{
            id: null
          }
        },
        categoria: [],
        material: [],
        vBtnSave: {
          loading: false
        },
        vSnackBar: {
          visible: false,
          text: null
        }
      }
    },
    props: {
      show: Boolean,
      mantenimientoId: Number
    },
    watch: {
      show: function (value) {
        this.localShow = value
      },
      localShow: function (value) {
        this.$emit('update:show', value)
        if(!value) return;
        if(!this.mantenimientoId) return;
        this.getMantenimiento();
      }
    },
    computed: {
      compMantenimiento: function(){
        return this.mantenimiento;
      }
    },
    methods: {
      getMantenimiento: function () {
        axios.get(`/catalogo_mantenimientos/${this.mantenimientoId}`)
          .then(response => {
            return this.mantenimiento = response.data
            })
          .catch(error => errorHandling(error));
      },
      getCategorias: function(){
        axios.get(laravelRoute("categorias.index",{
            query: "Materiales de maquinaria"
        })).then(response => {
          this.categoria = response.data.data;
        });
      },
      getMateriales: function (page = 1) {
        axios.get(laravelRoute('materiales.index'))
        .then(response => {
          this.material = response.data.data;
        })
        .catch(error => errorHandling(error));
      },
      updateMantenimiento: function () {
        this.vBtnSave.loading = true;
        axios.patch('/catalogo_mantenimientos/' + this.mantenimientoId, {
          nombre: this.mantenimiento.nombre,
          periodo: this.mantenimiento.periodo,
          duracion: this.mantenimiento.duracion
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.vBtnSave.loading = false;
          this.localShow = false;
          // setTimeout(()=>{
          //   window.location.href = "/maquinaria";
          // }, 1000)
        })
        .catch(error => {
          this.vBtnSave.loading = false;
          errorHandling(error)
        });
      },
    },
    created(){
      this.getCategorias();
      this.getMateriales();
    }
  }
</script>