<template>
  <v-flex>
    <v-toolbar color="grey lighten-3" app :clipped-left="$vuetify.breakpoint.mdAndUp" fixed>
      <v-toolbar-side-icon @click.stop="$store.commit('showOrHiddenSidebar')"></v-toolbar-side-icon>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-badge left color="#ff5617" v-on="on">
            <template v-slot:badge v-if="notifications.length">
              <span>{{notifications.length}}</span>
            </template>
            <v-icon @click="drawer = true">notifications</v-icon>
          </v-badge>
        </template>
        <span>Notificaciones</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon href="/perfil" v-on="on">
            <v-icon>person</v-icon>
          </v-btn>
        </template>
        <span>Administrar perfil</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="logout" v-on="on">
            <v-icon>exit_to_app</v-icon>
          </v-btn>
        </template>
        <span>Cerrar sesión</span>
      </v-tooltip>
      <div style="display: none;">
        <form id="logout-form" action="/logout" method="POST">
          <input type="hidden" name="_token" :value="csrf">
        </form>
      </div>
    </v-toolbar>
    <v-navigation-drawer right v-model="drawer" temporary absolute>
      <v-list class="pa-1">
        <v-list-tile avatar>
          <v-list-tile-avatar>
            <v-icon color="#ff5617">notifications</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>Notificaciones</v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-avatar>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                  <v-btn icon href="/notificaciones">
                    <v-icon color="#1aba24" dark v-on="on">all_inbox</v-icon>
                </v-btn>
              </template>
              <span>Ver todas las notificaciones</span>
            </v-tooltip>
          </v-list-tile-avatar>
        </v-list-tile>
      </v-list>

      <v-list two-line>
        <template v-for="item in notifications">
          <v-list-tile avatar :key="item.data.id" @click="markAsRead(item)">
            <v-list-tile-content>
              {{item.data.msg}}
            </v-list-tile-content>
          </v-list-tile>
        </template>
      </v-list>

      <v-list class="pa-1" v-if="notifications.length">
        <v-list-tile style="background-color: #1aba24; color: #fff;" avatar @click="markAllAsRead">
          <v-list-tile-avatar>
            <v-icon color="#fff">mark_chat_read</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>Marcar todas como vistas</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
  </v-flex>
</template>

<style>
</style>

<script>
  import axios from 'axios';
  import swal from 'sweetalert';
import { errorHandling, laravelRoute } from '../../lib/utilities';

  export default {
    data() {
      return {
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        drawer: false,
        notifications: [],
        vPaginator: {
          limit: 10
        },
      }
    },
    props: ['user', 'rol'],
    watch: {
      drawer: function (value) {
        if(value == true) {
          this.getNotifications();
        }
      },
    },
    methods: {
      logout: function() {
        swal({
          title: "Cerrar sesión",
          text: "¿Estás seguro?",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(result => {
          if (result) {
            document.getElementById('logout-form').submit();
          }
        })
      },
      markAsRead: function(notification) {
        axios.post(`/notificaciones/${notification.id}/markasread`)
        .then(response => {
          if (typeof notification.data.data['url'] !== 'undefined') {
            window.location.replace(`${notification.data.data.url}`);
          }
          else if (typeof notification.data['nombre_proyecto'] !== 'undefined') {
            window.location.replace(`/prospectos/${notification.data.data.id}`);
          }
          else if (typeof notification.data.data['nombre_tramite'] !== 'undefined') {
            window.location.replace(`/tramites_dependencias/${notification.data.data.id}`);
          }
          else if (typeof notification.data.data['nombre'] !== 'undefined') {
            window.location.replace(`/proyectos/${notification.data.data.id}`);
          }
          else if (typeof notification.data.data['proveedor'] !== 'undefined') {
            window.location.replace(`/facturas/${notification.data.data.id}`);
          }
          else if (typeof notification.data.data['maquinaria_id'] !== 'undefined') {
            window.location.replace(`/mantenimientos/${notification.data.data.id}`);
          }
        })
        .catch(error => errorHandling(error))
        .finally(() => this.getNotifications());
      },
      markAllAsRead: function() {
        axios.post(`/notificaciones/mark_all_as_read`)
        .then(response => {
          this.getNotifications();
        })
      },
      getNotifications: function() {
        axios.get(`/notificaciones/unread`)
        .then(response => {
          this.notifications = response.data;
        })
        .catch(error => errorHandling(error));
      },
      searchContractsForExpire(){
        axios.post(laravelRoute('contratos_trabajadores.searchForExpire'))
        .then(response => {})
        .catch(error => errorHandling(error));
      }
    },
    created() {
      this.searchContractsForExpire();
      this.getNotifications();
    }
  }
</script>
