<template>
  <v-layout>
    <v-container>
      <v-card>
        <v-flex>
        <!--<v-flex :xs12="!showFiltersModal" :xs9="showFiltersModal">-->
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Maquinaria</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <v-btn class="fyl-btnCreate" @click="openCreateModal" v-if="$root.can('new', permissions) ? true : false">Crear</v-btn>

            <v-btn dark color="verdeFayal" outline @click="showFiltersModal = !showFiltersModal">
              <v-icon dark>filter_alt</v-icon>
            </v-btn>
            <v-btn dark color="verdeFayal" outline @click="exportToExcel">
              <v-icon dark>import_export</v-icon>
            </v-btn>

          </v-toolbar>    
          
          <v-data-table :headers="headers" disable-initial-sort :items="maquinaria.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td>{{ props.item.nombre }}</td>
              <td>{{ !!props.item.descripcion && props.item.descripcion.length > 15 ? `${props.item.descripcion.substring(0,15)}...` : props.item.descripcion }}</td>
              <td>{{ props.item.estado ? props.item.estado.information.estado : 'No tiene estado' }}</td>
              <td>{{ props.item.proyecto_actual ? props.item.proyecto_actual.proyecto.nombre_proyecto : 'Sin proyecto actual'  }}</td>
              <td>{{ props.item.created_at }}</td>
              <td>
                <v-layout>
                  <v-tooltip bottom>
                    <v-btn @click="ManageProjectMaquinariaModal(props.item.id)" icon flat dark small slot="activator" color="info" :disabled="!$root.can('update', permissions)" >
                      <v-icon color="verdeFayal">book</v-icon>
                    </v-btn>
                    <span>Proyecto</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn @click="EditMaquinariaModal(props.item.id)" icon flat dark small slot="activator" color="info" :disabled="!$root.can('update', permissions)" >
                      <v-icon color="verdeFayal">edit</v-icon>
                    </v-btn>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn @click="deleteMaquinaria(props.item.id)" icon flat dark small slot="activator" color="error" :disabled="!$root.can('remove', permissions)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <span>Eliminar</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="maquinaria" @pagination-change-page="getMaquinaria"></pagination>
          </v-card-actions>
        </v-flex>
      </v-card>
      
      <!-- modals -->
      <maquinaria-modal-create
        :show.sync="showCreateModal"
        :prospectos="this.prospectos"
        :mantenimientoslist="mantenimientoslist"
      ></maquinaria-modal-create>
      <maquinaria-modal-update 
        :show.sync="showEditModal"
        :prospectos="prospectos"
        :maquinaria-id.sync="currentMaquinariaId"
        :mantenimientoslist="mantenimientoslist"
      ></maquinaria-modal-update>
      <manage-project-maquinaria-modal 
        :show.sync="showManageProjectModal"
        :prospectos="prospectos"
        :maquinaria-id.sync="currentMaquinariaId"
        :mantenimientoslist="mantenimientoslist"
      ></manage-project-maquinaria-modal>
    </v-container>

    <v-flex xs3 v-if="showFiltersModal">
      <v-card>
        <v-toolbar color="white" flat>
          <v-toolbar-title>Filtros</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="showFiltersModal = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex>
                <v-select
                  color="verdeFayal"
                  :items="estados"
                  item-value="id"
                  item-text="estado"
                  label="Status actual"
                  v-model="filters.filterEstado"
                  clearable
                ></v-select>
              </v-flex>
              <v-flex>
                <v-select
                  color="verdeFayal"
                  :items="proyectoItems"
                  item-value="id"
                  item-text="nombre_proyecto"
                  label="Proyecto asignado"
                  v-model="filters.filterProyecto"                    
                  clearable
                ></v-select>
              </v-flex>                
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn dark color="verdeFayal" small flat outline @click="limpiar">Limpiar</v-btn>
          <v-spacer></v-spacer>
          <v-btn dark color="verdeFayal" small @click="filterMaquinaria">Filtrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
  import axios from 'axios';
  import swal from 'sweetalert';
  import { errorHandling, laravelRoute } from '../../../lib/utilities';

  export default {
    data(){
      return{
        search: null,
        estados: [],
        headers: [
          { text: 'Nombre de la máquina', value: 'name' },
          { text: 'Descripción', value: 'descripcion' },
          { text: 'Estatus', value: 'estatus' },
          { text: 'Proyecto', value: 'proyecto' },
          { text: 'Fecha de registro', value: 'created_at' },
          { text: 'Acciones', sortable: false }
        ],
        showCreateModal: false,
        showEditModal: false,
        showManageProjectModal: false,
        currentMaquinariaId: null,
        vPaginator: {
          limit: 10
        },
        maquinaria:{
          data: []
        },


        showFiltersModal: false,       
        filters: {
          filterEstado: null,      
          filterProyecto: null,      
        },
        
        proyectoItems: Array.from(this.prospectos),

      }
    },
    props: ['prospectos', 'permissions', 'mantenimientoslist'],
    watch: {
      search: function (value) {
        this.getMaquinaria();
      },
      showCreateModal: function (value) {
        if (!value) {
          this.getMaquinaria();
        }
      },
      showEditModal: function (value) {
        if (!value) {
          this.getMaquinaria();
        }
      },
      showManageProjectModal: function (value) {
        this.getMaquinaria();
      }
    },
    methods: {
      getMaquinaria: function (page = 1) {
        axios.get(`/maquinaria?page=${page}&query=${this.search}`)
        .then(response => {
          this.maquinaria = response.data;
        })
        .catch(error => errorHandling(error));
      },
      getEstados: function(){
        axios
        .get(laravelRoute('maquinaria.estados'))
        .then(response => {
          this.estados = response.data;
        })
        .catch(error => errorHandling(error));
      },
      deleteMaquinaria: function(maquinariaId){
        swal({
          title: "¿Estás seguro?",
          text: "Una vez eliminado no se podrá recuperar",
          icon: "warning",
          buttons: true,
          dangerMode: true
          }).then(result => {
            if (result) {
              axios
              .delete(laravelRoute('maquinaria.destroy', maquinariaId))
              .then(response => {
                this.getMaquinaria();
                alertify.success(response.data.message_text);
              })
              .catch(error => {
                errorHandling(error);
              });
            }
        })
      },
      openCreateModal: function () {
        this.showCreateModal = true;
      },
      EditMaquinariaModal: function (maquinariaId) {
        this.showEditModal = true;
        this.currentMaquinariaId = maquinariaId;
      },
      ManageProjectMaquinariaModal: function(maquinariaId) {
        this.showManageProjectModal = true;
        this.currentMaquinariaId = maquinariaId;
      },
      filterMaquinaria: function() {
        axios.post('/maquinaria/filtrarMaquinas',this.filters)
        .then(response => this.maquinaria = response.data)
        .catch(error => errorHandling(error));
      },
      limpiar: function () {        
          this.filters.filterEstado = null;
          this.filters.filterProyecto = null;
          this.getMaquinaria();          
      },
      exportToExcel: function() {
      axios.post('/maquinaria/filtrarMaquinas', {
        ...this.filters,
        to_excel: true
      }, {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'maquinas.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
    },

    },
    created(){
      this.getMaquinaria();
      this.getEstados();
      this.proyectoItems.push({id:'Cero', nombre_proyecto: 'Sin proyecto actual'});
    },
}
</script>

<style>

</style>