var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Ventas")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.vHeaders,
                      "disable-initial-sort": "",
                      items: _vm.lots.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(
                                _vm._s(
                                  props.item.manzana.lotificacion.prospecto
                                    .nombre_proyecto
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(_vm._s(props.item.nombre))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(
                                "$ " +
                                  _vm._s(_vm._f("currency")(props.item.precio))
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(_vm._s(props.item.modelo))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(
                                _vm._s(
                                  props.item.cliente
                                    ? props.item.cliente
                                    : "Sin cliente aún"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(
                                _vm._s(
                                  props.item.vendedor
                                    ? props.item.vendedor
                                    : "Sin vendedor aún"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("td")
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: { limit: _vm.vPaginator.limit, data: _vm.lots },
                        on: { "pagination-change-page": _vm.getLots }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }