var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            flat: "",
                            icon: "",
                            href:
                              "/etapas/fraccionamiento/" +
                              _vm.etapa.fraccionamiento_id
                          }
                        },
                        [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_vm._v(" "), _c("span", [_vm._v("Regresar")])]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "pb-2": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs10: "", "offset-xs1": "" } },
            [
              _c(
                "v-card",
                { staticClass: "card--flex-toolbar" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", prominent: "" } },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-title",
                        { staticClass: "body-2 grey--text" },
                        [
                          _vm._v(
                            "Iniciar actividades para la etapa " +
                              _vm._s(_vm.etapa.nombre) +
                              " "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs5: "" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { dark: "", color: "#75d57b" } },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs8: "" } },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  attrs: { "primary-title": "" }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "headline total-title"
                                                      },
                                                      [_vm._v("Iniciadas")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        " Progreso de actividades Iniciadas"
                                                      )
                                                    ])
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs4: "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    "align-center": "",
                                                    "justify-center": "",
                                                    row: "",
                                                    "fill-height": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            rotate: 180,
                                                            size: 75,
                                                            width: 10,
                                                            value:
                                                              _vm.localEtapa
                                                                .porcentaje_de_avance
                                                                .iniciado,
                                                            color: "white"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                        " +
                                                              _vm._s(
                                                                _vm.localEtapa.porcentaje_de_avance.iniciado.toFixed(
                                                                  1
                                                                ) == "100.0"
                                                                  ? "100"
                                                                  : _vm.localEtapa.porcentaje_de_avance.iniciado.toFixed(
                                                                      1
                                                                    )
                                                              ) +
                                                              "%\n                                                    "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-flex", { attrs: { xs2: "" } }),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs5: "" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { dark: "", color: "#75d57b" } },
                                    [
                                      _c(
                                        "v-layout",
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs8: "" } },
                                            [
                                              _c(
                                                "v-card-title",
                                                {
                                                  attrs: { "primary-title": "" }
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "headline total-title"
                                                      },
                                                      [_vm._v("Finalizadas")]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        " Progreso de actividades finalizadas"
                                                      )
                                                    ])
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs4: "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    "align-center": "",
                                                    "justify-center": "",
                                                    row: "",
                                                    "fill-height": ""
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    [
                                                      _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            rotate: 180,
                                                            size: 75,
                                                            width: 10,
                                                            value:
                                                              _vm.localEtapa
                                                                .porcentaje_de_avance
                                                                .finalizado,
                                                            color: "white"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                        " +
                                                              _vm._s(
                                                                _vm.localEtapa.porcentaje_de_avance.finalizado.toFixed(
                                                                  1
                                                                ) == "100.0"
                                                                  ? "100"
                                                                  : _vm.localEtapa.porcentaje_de_avance.finalizado.toFixed(
                                                                      1
                                                                    )
                                                              ) +
                                                              "%\n                                                    "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "v-tabs",
                                    {
                                      attrs: {
                                        "fixed-tabs": "",
                                        centered: "",
                                        color: "white",
                                        "slider-color": "#1aba24"
                                      },
                                      model: {
                                        value: _vm.vTabs,
                                        callback: function($$v) {
                                          _vm.vTabs = $$v
                                        },
                                        expression: "vTabs"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-tab",
                                        { attrs: { href: "#tab-manzanas" } },
                                        [_c("span", [_vm._v("Manzanas")])]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tab",
                                        { attrs: { href: "#tab-calles" } },
                                        [_c("span", [_vm._v("Calles")])]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tab",
                                        {
                                          attrs: { href: "#tab-areas-verdes" }
                                        },
                                        [_c("span", [_vm._v("Áreas verdes")])]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-tabs-items",
                                    {
                                      model: {
                                        value: _vm.vTabs,
                                        callback: function($$v) {
                                          _vm.vTabs = $$v
                                        },
                                        expression: "vTabs"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-tab-item",
                                        { attrs: { value: "tab-manzanas" } },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _c(
                                                "v-container",
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c("v-data-table", {
                                                        attrs: {
                                                          items:
                                                            _vm.manzanas.data,
                                                          expand:
                                                            _vm.expandManzana,
                                                          "hide-actions": "",
                                                          "item-key": "id"
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "headers",
                                                            fn: function(
                                                              props
                                                            ) {
                                                              return [
                                                                _c("tr", [
                                                                  _c("th", [
                                                                    _vm._v(
                                                                      "Manzana"
                                                                    )
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c("th")
                                                                ])
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key: "items",
                                                            fn: function(
                                                              props
                                                            ) {
                                                              return [
                                                                _c("tr", [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        props
                                                                          .item
                                                                          .nombre
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-xs-right"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                            icon:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              props.expanded = !props.expanded
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "expand_more"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key: "expand",
                                                            fn: function(
                                                              props
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    attrs: {
                                                                      flat: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-data-table",
                                                                      {
                                                                        attrs: {
                                                                          items:
                                                                            props
                                                                              .item
                                                                              .actividad,
                                                                          "hide-actions":
                                                                            "",
                                                                          "item-key":
                                                                            "id"
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "headers",
                                                                              fn: function() {
                                                                                return [
                                                                                  _c(
                                                                                    "tr",
                                                                                    [
                                                                                      _c(
                                                                                        "th"
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Rubro"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Concepto"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Tiempo esperado"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Fecha de incio"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Fecha de finalización"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-xs-right"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Acciones"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              },
                                                                              proxy: true
                                                                            },
                                                                            {
                                                                              key:
                                                                                "items",
                                                                              fn: function(
                                                                                propsRubrosManzana
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "tr",
                                                                                    [
                                                                                      _c(
                                                                                        "td"
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              propsRubrosManzana
                                                                                                .item
                                                                                                .rubro
                                                                                                .nombre
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              propsRubrosManzana
                                                                                                .item
                                                                                                .rubro
                                                                                                .conceptos
                                                                                                .nombre
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              propsRubrosManzana.item.tiempo_estimado.toFixed(
                                                                                                2
                                                                                              )
                                                                                            ) +
                                                                                              " horas"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.formatDate(
                                                                                                propsRubrosManzana
                                                                                                  .item
                                                                                                  .fecha_de_inicio
                                                                                              )
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.formatDate(
                                                                                                propsRubrosManzana
                                                                                                  .item
                                                                                                  .fecha_de_finalizacion
                                                                                              )
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-xs-right"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                bottom:
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    slot:
                                                                                                      "activator",
                                                                                                    icon:
                                                                                                      "",
                                                                                                    flat:
                                                                                                      "",
                                                                                                    dark:
                                                                                                      "",
                                                                                                    small:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "verdeFayal",
                                                                                                    disabled: propsRubrosManzana
                                                                                                      .item
                                                                                                      .iniciado
                                                                                                      ? true
                                                                                                      : false
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.initDistribucionById(
                                                                                                        propsRubrosManzana.item
                                                                                                      )
                                                                                                    }
                                                                                                  },
                                                                                                  slot:
                                                                                                    "activator"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "play_arrow"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Iniciar"
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                bottom:
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    slot:
                                                                                                      "activator",
                                                                                                    icon:
                                                                                                      "",
                                                                                                    flat:
                                                                                                      "",
                                                                                                    dark:
                                                                                                      "",
                                                                                                    small:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "red",
                                                                                                    disabled:
                                                                                                      !propsRubrosManzana
                                                                                                        .item
                                                                                                        .iniciado ||
                                                                                                      propsRubrosManzana
                                                                                                        .item
                                                                                                        .finalizado
                                                                                                        ? true
                                                                                                        : false
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.finishDistribucionById(
                                                                                                        propsRubrosManzana.item
                                                                                                      )
                                                                                                    }
                                                                                                  },
                                                                                                  slot:
                                                                                                    "activator"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "stop"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Finalizar"
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ])
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _vm._v(" "),
                                                      _c("pagination", {
                                                        attrs: {
                                                          limit: 10,
                                                          data: _vm.manzanas
                                                        },
                                                        on: {
                                                          "pagination-change-page":
                                                            _vm.getManzanas
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("v-spacer")
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tab-item",
                                        { attrs: { value: "tab-calles" } },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _c(
                                                "v-container",
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c("v-data-table", {
                                                        attrs: {
                                                          items:
                                                            _vm.calles.data,
                                                          expand:
                                                            _vm.expandCalles,
                                                          "hide-actions": "",
                                                          "item-key": "id"
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "headers",
                                                            fn: function(
                                                              props
                                                            ) {
                                                              return [
                                                                _c("tr", [
                                                                  _c("th", [
                                                                    _vm._v(
                                                                      "Calle"
                                                                    )
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticClass:
                                                                        "text-xs-right"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Acciones"
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key: "items",
                                                            fn: function(
                                                              props
                                                            ) {
                                                              return [
                                                                _c("tr", [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        props
                                                                          .item
                                                                          .nombre
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-xs-right"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                            icon:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              props.expanded = !props.expanded
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "expand_more"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key: "expand",
                                                            fn: function(
                                                              props
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    attrs: {
                                                                      flat: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-data-table",
                                                                      {
                                                                        attrs: {
                                                                          items:
                                                                            props
                                                                              .item
                                                                              .actividad,
                                                                          "hide-actions":
                                                                            "",
                                                                          "item-key":
                                                                            "id"
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "headers",
                                                                              fn: function() {
                                                                                return [
                                                                                  _c(
                                                                                    "tr",
                                                                                    [
                                                                                      _c(
                                                                                        "th"
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Rubro"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Concepto"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Tiempo esperado"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Fecha de incio"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Fecha de finalización"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-xs-right"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Acciones"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              },
                                                                              proxy: true
                                                                            },
                                                                            {
                                                                              key:
                                                                                "items",
                                                                              fn: function(
                                                                                propsRubrosCalles
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "tr",
                                                                                    [
                                                                                      _c(
                                                                                        "td"
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              propsRubrosCalles
                                                                                                .item
                                                                                                .rubro
                                                                                                .nombre
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              propsRubrosCalles
                                                                                                .item
                                                                                                .rubro
                                                                                                .conceptos
                                                                                                .nombre
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              propsRubrosCalles.item.tiempo_estimado.toFixed(
                                                                                                2
                                                                                              )
                                                                                            ) +
                                                                                              " horas"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.formatDate(
                                                                                                propsRubrosCalles
                                                                                                  .item
                                                                                                  .fecha_de_inicio
                                                                                              )
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.formatDate(
                                                                                                propsRubrosCalles
                                                                                                  .item
                                                                                                  .fecha_de_finalizacion
                                                                                              )
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-xs-right"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                bottom:
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    slot:
                                                                                                      "activator",
                                                                                                    icon:
                                                                                                      "",
                                                                                                    flat:
                                                                                                      "",
                                                                                                    dark:
                                                                                                      "",
                                                                                                    small:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "verdeFayal",
                                                                                                    disabled: propsRubrosCalles
                                                                                                      .item
                                                                                                      .iniciado
                                                                                                      ? true
                                                                                                      : false
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.initDistribucionById(
                                                                                                        propsRubrosCalles.item
                                                                                                      )
                                                                                                    }
                                                                                                  },
                                                                                                  slot:
                                                                                                    "activator"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "play_arrow"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Iniciar"
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                bottom:
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    slot:
                                                                                                      "activator",
                                                                                                    icon:
                                                                                                      "",
                                                                                                    flat:
                                                                                                      "",
                                                                                                    dark:
                                                                                                      "",
                                                                                                    small:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "red",
                                                                                                    disabled:
                                                                                                      !propsRubrosCalles
                                                                                                        .item
                                                                                                        .iniciado ||
                                                                                                      propsRubrosCalles
                                                                                                        .item
                                                                                                        .finalizado
                                                                                                        ? true
                                                                                                        : false
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.finishDistribucionById(
                                                                                                        propsRubrosCalles.item
                                                                                                      )
                                                                                                    }
                                                                                                  },
                                                                                                  slot:
                                                                                                    "activator"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "stop"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Finalizar"
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ])
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _vm._v(" "),
                                                      _c("pagination", {
                                                        attrs: {
                                                          limit: 10,
                                                          data: _vm.calles
                                                        },
                                                        on: {
                                                          "pagination-change-page":
                                                            _vm.getCalles
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("v-spacer")
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tab-item",
                                        {
                                          attrs: { value: "tab-areas-verdes" }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { flat: "" } },
                                            [
                                              _c(
                                                "v-container",
                                                [
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c("v-data-table", {
                                                        attrs: {
                                                          items: _vm.areas.data,
                                                          expand:
                                                            _vm.expandAreas,
                                                          "hide-actions": "",
                                                          "item-key": "id"
                                                        },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "headers",
                                                            fn: function(
                                                              props
                                                            ) {
                                                              return [
                                                                _c("tr", [
                                                                  _c("th", [
                                                                    _vm._v(
                                                                      "Calle"
                                                                    )
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "th",
                                                                    {
                                                                      staticClass:
                                                                        "text-xs-right"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Acciones"
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key: "items",
                                                            fn: function(
                                                              props
                                                            ) {
                                                              return [
                                                                _c("tr", [
                                                                  _c("td", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        props
                                                                          .item
                                                                          .nombre
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "text-xs-right"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                            icon:
                                                                              ""
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              props.expanded = !props.expanded
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "expand_more"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ])
                                                              ]
                                                            }
                                                          },
                                                          {
                                                            key: "expand",
                                                            fn: function(
                                                              props
                                                            ) {
                                                              return [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    attrs: {
                                                                      flat: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-data-table",
                                                                      {
                                                                        attrs: {
                                                                          items:
                                                                            props
                                                                              .item
                                                                              .actividad,
                                                                          "hide-actions":
                                                                            "",
                                                                          "item-key":
                                                                            "id"
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "headers",
                                                                              fn: function() {
                                                                                return [
                                                                                  _c(
                                                                                    "tr",
                                                                                    [
                                                                                      _c(
                                                                                        "th"
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Rubro"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Concepto"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Tiempo esperado"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Fecha de incio"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Fecha de finalización"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "th",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-xs-right"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Acciones"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              },
                                                                              proxy: true
                                                                            },
                                                                            {
                                                                              key:
                                                                                "items",
                                                                              fn: function(
                                                                                propsRubrosAreas
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "tr",
                                                                                    [
                                                                                      _c(
                                                                                        "td"
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              propsRubrosAreas
                                                                                                .item
                                                                                                .rubro
                                                                                                .nombre
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              propsRubrosAreas
                                                                                                .item
                                                                                                .rubro
                                                                                                .conceptos
                                                                                                .nombre
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              propsRubrosAreas.item.tiempo_estimado.toFixed(
                                                                                                2
                                                                                              )
                                                                                            ) +
                                                                                              " horas"
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.formatDate(
                                                                                                propsRubrosAreas
                                                                                                  .item
                                                                                                  .fecha_de_inicio
                                                                                              )
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.formatDate(
                                                                                                propsRubrosAreas
                                                                                                  .item
                                                                                                  .fecha_de_finalizacion
                                                                                              )
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "td",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-xs-right"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                bottom:
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    slot:
                                                                                                      "activator",
                                                                                                    icon:
                                                                                                      "",
                                                                                                    flat:
                                                                                                      "",
                                                                                                    dark:
                                                                                                      "",
                                                                                                    small:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "verdeFayal",
                                                                                                    disabled: propsRubrosAreas
                                                                                                      .item
                                                                                                      .iniciado
                                                                                                      ? true
                                                                                                      : false
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.initDistribucionById(
                                                                                                        propsRubrosAreas.item
                                                                                                      )
                                                                                                    }
                                                                                                  },
                                                                                                  slot:
                                                                                                    "activator"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "play_arrow"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Iniciar"
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                bottom:
                                                                                                  ""
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    slot:
                                                                                                      "activator",
                                                                                                    icon:
                                                                                                      "",
                                                                                                    flat:
                                                                                                      "",
                                                                                                    dark:
                                                                                                      "",
                                                                                                    small:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "red",
                                                                                                    disabled:
                                                                                                      !propsRubrosAreas
                                                                                                        .item
                                                                                                        .iniciado ||
                                                                                                      propsRubrosAreas
                                                                                                        .item
                                                                                                        .finalizado
                                                                                                        ? true
                                                                                                        : false
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.finishDistribucionById(
                                                                                                        propsRubrosAreas.item
                                                                                                      )
                                                                                                    }
                                                                                                  },
                                                                                                  slot:
                                                                                                    "activator"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-icon",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "stop"
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Finalizar"
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ])
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _vm._v(" "),
                                                      _c("pagination", {
                                                        attrs: {
                                                          limit: 10,
                                                          data: _vm.areas
                                                        },
                                                        on: {
                                                          "pagination-change-page":
                                                            _vm.getAreas
                                                        }
                                                      }),
                                                      _vm._v(" "),
                                                      _c("v-spacer")
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.vSnackBar.text) + "\n        "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }