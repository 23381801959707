<template>
  <v-layout row justify-center>
    <v-dialog :value="dialog" persistent max-width="700px">
      <template v-slot:activator="{ on }">
        <v-btn class="fyl-btnCreate" v-on:click="$emit('open')">editar</v-btn>

      </template>
      <v-card>
        <form action v-on:submit.prevent.once="savePermissions()">
          <v-card-title>
            <span class="headline">Editar roles</span>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap >
                <v-flex xs12 sm6 md6>
                  <v-text-field color="#1ABA24" label="ID del Rol" v-model="getRoleData.id" disabled></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md6>
                  <v-text-field color="#1ABA24"
                    label="Nombre"
                    v-model="getRoleData.name"
                  ></v-text-field>
                </v-flex>
                  <v-switch v-model="allRoles" color="verdeFayal" :label="`Dar todos los permisos.`" @change="toggleAll"></v-switch>
                  <v-flex xs12 lg12 sm12 style="background: white;" v-for="(permission, index) in permisosComputated" :key="index">
                    <v-expansion-panel popout style="background: white !important;">
                      <v-expansion-panel-content style="background: white;">
                        <template v-slot:header><div color="#1ABA24" style="background: white;" slot="header">{{Object.keys(permission)[0].charAt(0).toUpperCase() + Object.keys(permission)[0].slice(1)}}</div></template>
                        <v-card style="background: white;">
                          <v-card-text color="#1ABA24" style="background: white;">
                            <td v-for="(permissio, index) in permission" :key="index" style="background: #fff;">
                              <li v-for="(item, index) in permissio" :key="index">
                                <td>
                                  <v-checkbox
                                    v-model="selected"
                                    :value="item.name"
                                    item-value="name"
                                    primary
                                    hide-details
                                    :label="`${item.description}`"
                                  ></v-checkbox>
                                </td>
                              </li>
                            </td>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="fyl-btnClose" flat @click="$emit('close')">Cerrar</v-btn>
            <v-btn class="fyl-btnSave" type="submit" flat>Guardar</v-btn>
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from "axios";
  import swal from 'sweetalert';

  export default {
    props: ["roles", "role", "dialog", "permissions"],

    data() {
      return {
        allRoles: false,
        selected: [],
        permisosProps: this.permissions,
        selectedToshow: [],
        headers:[
          {text: 'Nombre', value: 'name'}
        ],
        pagination: {
          sortBy: 'name'
        },
      }
    },
    watch: {
      role: function(value){
        this.selected = value.permissions ? value.permissions.map(element => element.name) : [];
        this.allRoles =  this.permissions.length == value.permissions.length ? true : false;
      },
    },
    computed:{
      getRoleData: function(){
        if(this.role) return this.role;
        return {
          id: 'No disponible',
          name: 'No disponible'
        }
      },
      permisosComputated: function() {
        let categoriasFiltered = [];
        let categoriasWithPermissions = [];
        this.permisosProps.forEach(element => {
          if(categoriasFiltered.includes(element.categoria)) return;
          categoriasFiltered.push(element.categoria);
        });

        categoriasFiltered.forEach(elemento => {
          let objectElement = {};
          objectElement[elemento] = this.permisosProps.filter(element => {
            return element.categoria == elemento;
          });
          categoriasWithPermissions.push(objectElement);
        });
        return categoriasWithPermissions;
      },
    },

    methods: {
      toggleAll: function() {
      if (!this.allRoles) {
        this.selected = []
      }else{
        this.selected = this.permisosProps.slice().map(element => element.name)
      }
    },
      savePermissions: function(){
        axios
        .patch(route('roles.update', this.getRoleData.id ), {permissions: this.selected})
        .then(response => {
          if(response.data.message_text){
            alertify.success(response.data.message_text);
            this.$emit('close');
          }
        })
        .catch(error => errorHandling(error))
      }
    }
  };
</script>

<style>

</style>
