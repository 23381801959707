<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" width="500" persistent>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Archivos personales</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container
            fluid
            grid-list-lg
          >
            <v-layout row wrap>
              <v-flex xs12>
                <v-card flat>
                  <v-layout row>
                    <v-flex xs9>
                      <div class="font-weight-bold green--text">
                        Archivo
                      </div>
                    </v-flex>
                    <v-flex xs3>
                      <div class="font-weight-bold green--text">
                        Acciones
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
                <v-divider></v-divider>
              </v-flex>

              <v-flex xs12>
                <v-card flat>
                  <v-layout row>
                    <v-flex style="align-self: center;" xs8>
                      <div>
                        <div class="font-weight-bold">
                          Acta de Nacimiento
                        </div>
                      </div> 
                    </v-flex>
                    <v-flex xs2>
                      <v-tooltip bottom v-if="personal_files.find(file => file.type == 'Acta de Nacimiento') == null">
                          <v-btn
                          icon
                          :disabled="!$root.can('files', permissions)"
                          flat
                          slot="activator"
                          color="warning"
                          @click="type = 'Acta de Nacimiento'; dialogFile2 = true;"
                        >
                          
                            <v-icon color="orange">contact_mail</v-icon>
                          </v-btn>
                          <span>Click Para Subir Acta de Nacimiento</span>
                      </v-tooltip>
                      <v-tooltip bottom v-else>
                          <v-btn target="_blank" icon color="green" flat slot="activator" :href="personal_files.find(file => file.type == 'Acta de Nacimiento').url">
                          
                          <v-icon dark>contact_mail</v-icon>
                          </v-btn>
                          <span>Click Para Ver Archivo</span>
                      </v-tooltip>
                    </v-flex>
                    <v-flex xs2>
                      <v-tooltip bottom>
                        <v-btn
                        :disabled="!$root.can('files', permissions) || personal_files.find(file => file.type == 'Acta de Nacimiento') == null"
                        icon
                        slot="activator"
                        @click="deleteFile(personal_files.find(file => file.type == 'Acta de Nacimiento'))"
                        >
                        <v-icon color="red">delete</v-icon>
                        </v-btn>
                        <span>Eliminar Archivo</span>
                    </v-tooltip>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12>
                <v-card flat>
                  <v-layout row wrap>
                    <v-flex style="align-self: center;" xs8>
                      <div>
                        <div class="font-weight-bold">
                          INE
                        </div>
                      </div> 
                    </v-flex>
                    <v-flex xs2>
                      <v-tooltip bottom v-if="personal_files.find(file => file.type == 'INE') == null">
                          <v-btn
                          icon
                          :disabled="!$root.can('files', permissions)"
                          flat
                          slot="activator"
                          color="warning"
                          @click="type = 'INE'; dialogFile2 = true;"
                          >
                          <v-icon color="orange">contact_mail</v-icon>
                          </v-btn>
                          <span>Click Para Subir INE</span>
                      </v-tooltip>

                      <v-tooltip bottom v-else>
                          <v-btn target="_blank" icon color="green" flat slot="activator" :href="personal_files.find(file => file.type == 'INE').url">
                          
                          <v-icon dark>contact_mail</v-icon>
                          </v-btn>
                          <span>Click Para Ver Archivo</span>
                      </v-tooltip>
                    </v-flex>
                    <v-flex xs2>
                      <v-tooltip bottom>
                        <v-btn
                        :disabled="!$root.can('files', permissions) || personal_files.find(file => file.type == 'INE') == null"
                        icon
                        slot="activator"
                        @click="deleteFile(personal_files.find(file => file.type == 'INE'))"
                        >
                        <v-icon color="red">delete</v-icon>
                        </v-btn>
                        <span>Eliminar Archivo</span>
                    </v-tooltip>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12>
                <v-card flat>
                  <v-layout row wrap>
                    <v-flex style="align-self: center;" xs8>
                      <div>
                        <div class="font-weight-bold">
                          CURP
                        </div>
                      </div> 
                    </v-flex>
                    <v-flex xs2>
                      <v-tooltip bottom v-if="personal_files.find(file => file.type == 'CURP') == null">
                          <v-btn
                          icon
                          :disabled="!$root.can('files', permissions)"
                          flat
                          slot="activator"
                          color="warning"
                          @click="type = 'CURP'; dialogFile2 = true;"
                          >
                          
                          <v-icon color="orange">contact_mail</v-icon>
                          </v-btn>
                          <span>Click Para Subir CURP</span>
                      </v-tooltip>

                      <v-tooltip bottom v-else>
                          <v-btn target="_blank" icon color="green" flat slot="activator" :href="personal_files.find(file => file.type == 'CURP').url">
                          
                          <v-icon dark>contact_mail</v-icon>
                          </v-btn>
                          <span>Click Para Ver Archivo</span>
                      </v-tooltip>
                    </v-flex>
                    <v-flex xs2>
                      <v-tooltip bottom>
                        <v-btn
                        :disabled="!$root.can('files', permissions) || personal_files.find(file => file.type == 'CURP') == null"
                        icon
                        slot="activator"
                        @click="deleteFile(personal_files.find(file => file.type == 'CURP'))"
                        >
                        <v-icon color="red">delete</v-icon>
                        </v-btn>
                        <span>Eliminar Archivo</span>
                      </v-tooltip>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12>
                <v-card flat>
                  <v-layout row wrap>
                    <v-flex style="align-self: center;" xs8>
                      <div>
                        <div class="font-weight-bold">
                          RFC
                        </div>
                      </div> 
                    </v-flex>
                    <v-flex xs2>
                      <v-tooltip bottom v-if="personal_files.find(file => file.type == 'RFC') == null">
                        <v-btn
                        icon
                        :disabled="!$root.can('files', permissions)"
                        flat
                        slot="activator"
                        color="warning"
                        @click="type = 'RFC'; dialogFile2 = true;"
                        >
                        
                        <v-icon color="orange">contact_mail</v-icon>
                        </v-btn>
                        <span>Click Para Subir RFC</span>
                      </v-tooltip>

                      <v-tooltip bottom v-else>
                        <v-btn target="_blank" icon color="green" flat slot="activator" :href="personal_files.find(file => file.type == 'RFC').url">
                        <v-icon dark>contact_mail</v-icon>
                        </v-btn>
                        <span>Click Para Ver Archivo</span>
                      </v-tooltip>
                    </v-flex>
                    <v-flex xs2>
                      <v-tooltip bottom>
                        <v-btn
                        :disabled="!$root.can('files', permissions) || personal_files.find(file => file.type == 'RFC') == null"
                        icon
                        slot="activator"
                        @click="deleteFile(personal_files.find(file => file.type == 'RFC'))"
                        >
                        <v-icon color="red">delete</v-icon>
                        </v-btn>
                        <span>Eliminar Archivo</span>
                      </v-tooltip>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>

              <v-flex xs12>
                <v-card flat>
                  <v-layout row wrap>
                    <v-flex style="align-self: center;" xs8>
                      <div>
                        <span class="font-weight-bold">
                          Comprobante de Domicilio
                        </span>
                      </div> 
                    </v-flex>
                    <v-flex xs2>
                      <v-tooltip bottom v-if="personal_files.find(file => file.type == 'Comprobante de Domicilio') == null">
                        <v-btn
                        icon
                        :disabled="!$root.can('files', permissions)"
                        flat
                        slot="activator"
                        color="warning"
                        @click="type = 'Comprobante de Domicilio'; dialogFile2 = true;"
                        >
                        
                        <v-icon color="orange">contact_mail</v-icon>
                        </v-btn>
                        <span>Click Para Subir Comprobante de Domicilio</span>
                      </v-tooltip>

                      <v-tooltip bottom v-else>
                        <v-btn target="_blank" icon color="green" flat slot="activator" :href="personal_files.find(file => file.type == 'Comprobante de Domicilio').url">
                        
                        <v-icon dark>contact_mail</v-icon>
                        </v-btn>
                        <span>Click Para Ver Archivo</span>
                      </v-tooltip>
                    </v-flex>
                    <v-flex xs2>
                      <v-tooltip bottom>
                        <v-btn
                        :disabled="!$root.can('files', permissions) || personal_files.find(file => file.type == 'Comprobante de Domicilio') == null"
                        icon
                        slot="activator"
                        @click="deleteFile(personal_files.find(file => file.type == 'Comprobante de Domicilio'))"
                        >
                        <v-icon color="red">delete</v-icon>
                        </v-btn>
                        <span>Eliminar Archivo</span>
                      </v-tooltip>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
                
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>

      <upload-client-files-modal
        @close="dialogFile2 = false"
        :element_type="element_type"
        :element_id="element_id"
        :dialog="dialogFile2"
        :type="type"
      ></upload-client-files-modal>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["show", "element_type", "element_id", "edit_disabled", "permissions", "personal_files"],

  data() {
    return {
      dialogFile2: false,  
      type: "",
      saveDisabled: true,
      closeDisabled: false,
      archivo: {},
      archivos: [],
      archivosOld: [],
      localShow: false
    };
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    }
  },
  methods: {
    deleteFile: function(file) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
          this.$root.awseraser(file);
          if (file.id) {
            axios
              .delete(route("files.destroy", file.id))
              .then(response => {
                  setTimeout(() => {
                window.location.href = "/clientes";
              }, 300);
              })
              .catch(error => errorHandling(error));
            this.archivosOld = this.archivosOld.filter(function(ele) {
              return ele.id != file.id;
            });
          } else {
            this.archivos = this.archivos.filter(function(ele) {
              return ele.aws_uuid != file.aws_uuid;
            });
            this.archivosOld = this.archivosOld.filter(function(ele) {
              return ele.aws_uuid != file.aws_uuid;
            });
            this.archivos.length == 0
              ? ((this.saveDisabled = true), (this.closeDisabled = false))
              : ((this.saveDisabled = false), (this.closeDisabled = true));
          }
          swal("El Registro ha sido eliminado", {
            icon: "success"
          });
        } else {
          swal("El Registro no se eliminó");
        }
      });
    }
  },
};
</script>


