<template>
  <v-layout>
    <v-container>
      <v-card>
        <v-flex xs12>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Catálogo de mantenimientos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <v-btn class="fyl-btnCreate" @click="openCreateModal" v-if="$root.can('new', permissions) ? true : false">Crear</v-btn>
          </v-toolbar>
          <v-data-table :headers="headers" disable-initial-sort :items="mantenimiento.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td>{{ props.item.nombre }}</td>
              <td>{{ props.item.periodo }}</td>
              <td>{{ props.item.duracion }}</td>
              <td>
                <v-tooltip bottom>
                  <v-btn :disabled="!$root.can('update', permissions)" @click="EditMantenimientoModal(props.item.id)" icon flat dark small slot="activator" color="info">
                    <v-icon color="verdeFayal">edit</v-icon>
                  </v-btn>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn :disabled="!$root.can('materials', permissions)" @click="openAddMaterialsValuesModal(props.item.id,props.item.nombre)"  icon flat dark small slot="activator" color="verdeFayal">
                      <!--  -->
                      <v-icon>vertical_split</v-icon>
                  </v-btn>
                  <span>Materiales</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <v-btn :disabled="!$root.can('remove', permissions)" @click="deleteMantenimiento(props.item.id)" icon flat dark small slot="activator" color="error" >
                    <v-icon>delete</v-icon>
                  </v-btn>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="mantenimiento" @pagination-change-page="getMantenimientos"></pagination>
          </v-card-actions> -->
        </v-flex>
      </v-card>
      <!-- modals -->
      <catalogo-mantenimientos-modal-create
        :show.sync="showCreateModal"
      ></catalogo-mantenimientos-modal-create>
      <catalogo-mantenimientos-modal-update
        :show.sync="showEditModal"
        :mantenimientoId.sync="currentMantenimientoId"
      ></catalogo-mantenimientos-modal-update>
      <manage-materials-to-mantenimiento-modal
        v-bind:show.sync="showMaterialsToValuesModal"
        v-bind:item-id.sync="currentItemId"
        v-bind:modelType.sync="currentModel"
        v-bind:name.sync="currentItemName"
      ></manage-materials-to-mantenimiento-modal>
    </v-container>
  </v-layout>
</template>

<script>
  import axios from 'axios';
  import swal from 'sweetalert';
  import { errorHandling, laravelRoute } from '../../../lib/utilities';

  export default {
    data(){
      return{
        data:{},
        search: null,
        showCreateModal: false,
        showEditModal: false,
        currentMantenimientoId: null,
        showMaterialsToValuesModal: false,
        currentModel: 'App\\Mantenimiento',
        currentItemId: null,
        currentItemName: null,
        headers: [
          { text: 'Nombre del mantenimiento', value: 'name' },
          { text: 'Periodo del mantenimiento (días)', value: 'categorias' },
          { text: 'Duración del mantenimiento (horas)', value: 'material' },
          { text: 'Acciones', value: 'cantidad' },
        ],
        vPaginator: {
          limit: 10
        },
        mantenimiento: {
          data: []
        }
      }
    },
    props:['permissions'],
    watch:{
      showCreateModal: function (value) {
        if (!value) {
          this.getMantenimientos();
        }
      },
      showEditModal: function (value) {
        if (!value) {
          this.getMantenimientos();
        }
      },
      showMaterialsToValuesModal: function(value) {
            if(!value) {
                this.getValues()
            }
        }
    },
    methods:{
      getValues: function() {
        axios.get(`/catalogo_mantenimientos`)
        .then(response => {
            this.data = response.data
          })
        .catch( error => errorHandling(error))
      },
      getMantenimientos: function (page = 1) {
        axios.get(`/catalogo_mantenimientos?page=${page}&query=${this.search}`)
        .then(response => {
          this.mantenimiento = response.data;
        })
        .catch(error => errorHandling(error));
      },
      deleteMantenimiento: function(mantenimientoId){
        swal({
          title: "¿Estás seguro?",
          text: "Una vez eliminado no se podrá recuperar",
          icon: "warning",
          buttons: true,
          dangerMode: true
          }).then(result => {
            if (result) {
              axios
              .delete(laravelRoute('catalogo_mantenimientos.destroy', mantenimientoId))
              .then(response => {
                this.getMantenimientos();
                alertify.success(response.data.message_text);
              })
              .catch(error => {
                errorHandling(error);
              });
            }
          })
        },
      openCreateModal: function () {
        this.showCreateModal = true;
      },
      EditMantenimientoModal: function (mantenimientoId) {
        this.showEditModal = true;
        this.currentMantenimientoId = mantenimientoId;
      },
      openAddMaterialsValuesModal: function(currentItemId, currentItemName) {
        this.showMaterialsToValuesModal = true;
        this.currentItemId = currentItemId;
        this.currentItemName = currentItemName;
      },
    },
    created(){
      this.getMantenimientos();
    }
  }
</script>