<template>
  <v-container>
    <v-layout row wrap>          
      <v-flex :xs12="!showFiltersForm" :xs9="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Propuestas de lotificación</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <v-btn 
              v-if="$root.can('new', permissions) ? true : false"
              v-show="$root.can('readonly', permissions) ? false : true"
              class="fyl-btnCreate" 
              @click="openCreateModal" 
            >
              Crear
            </v-btn>
            <v-btn dark color="verdeFayal" outline @click="showFiltersForm = !showFiltersForm">
              <v-icon dark>filter_alt</v-icon>
            </v-btn>
            <v-btn dark color="verdeFayal" outline  @click="exportToExcel">
              <v-icon dark>import_export</v-icon>
            </v-btn>            
          </v-toolbar>
          <v-data-table :headers="headers" disable-initial-sort :items="proyectosProps.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td>{{ props.item.nombre }}</td>
              <td>{{ props.item.lotes_regulares }}</td>
              <td>{{ props.item.lotes_irregulares }}</td>
              <td>{{ props.item.superficie.toFixed(2) }}</td>
              <td>{{ props.item.estado.information.estado }}</td>
              <td>
                <v-layout>
                  <v-tooltip bottom>
                    <v-btn 
                      :disabled="!$root.can('update', permissions) || (props.item.estado.information.estado == 'Descartado') && true" 
                      flat 
                      icon 
                      slot="activator" 
                      color="verdeFayal" 
                      @click="openStatusModal(props.item.id)"
                    >
                      <v-icon>low_priority</v-icon>
                    </v-btn>
                    <span>Estados</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      :disabled="!$root.can('update', permissions) || (props.item.estado.information.estado == 'Descartado') && true"
                      icon
                      flat
                      dark
                      slot="activator"
                      color="info"
                      :href="`/proyectos/${props.item.id}`"
                    >
                      <v-icon color="#1ABA24">edit</v-icon>
                    </v-btn>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      flat
                      icon
                      slot="activator"
                      color="warning"
                      @click="elementId = props.item.id; dialogFile = true;"
                    >
                      <v-icon>folder</v-icon>
                    </v-btn>
                    <span>Archivos</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      :disabled="!$root.can('remove', permissions) || (props.item.estado.information.estado == 'Seleccionado') && true"
                      icon
                      flat
                      dark
                      slot="activator"
                      color="error"
                      @click="sweetAlert(props.item)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <span>Eliminar</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="proyectosProps" @pagination-change-page="getPropuestasDeLotificacion"></pagination>
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex xs3 v-if="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat>
            <v-toolbar-title>Filtros</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="showFiltersForm = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex>
                  <v-select 
                    v-model="filters.filterEstado" 
                    :items="this.itemsEstados"
                    item-value="id"
                    item-text="estado"
                    label="Estado"
                    clearable                                 
                  />
                </v-flex>   
                <v-select
                  color="verdeFayal"
                  :items="proyectoItems"                    
                  item-value="id"
                  item-text="nombre_proyecto"
                  label="Proyecto al que pertenece"
                  v-model="filters.filterProyecto"                    
                  clearable
                ></v-select>             
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn dark color="verdeFayal" small flat outline @click="limpiar">Limpiar</v-btn>
            <v-spacer></v-spacer>
            <v-btn dark color="verdeFayal" small @click="filterProyectos">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>

    </v-layout>
      

    <!-- Modals -->
    <upload-files-modal
      @close="dialogFile = false"
      element_type="App\Lotificacion"
      :element_id="elementId"
      :dialog="dialogFile"
    ></upload-files-modal>
    
    <manage-estados-modal-component 
      :show.sync="showStatusModal"
      :model-id.sync="currentLotificationId"
      model-type="App\Lotificacion"
    ></manage-estados-modal-component>

    <create-prospectos-modal-form
      :show.sync="showCreateModal"
    />
  </v-container>
</template>

<script>
import { errorHandling, laravelRoute } from "../../lib/utilities";
import axios from "axios";
import swal from "sweetalert";

export default {
  props: ["proyectos", "concretados", "permissions"],
  data() {
    return {
      dialogFile: false,
      elementId: "",
      search: '',
      dialog: false,
      vPaginator: {
        limit: 10
      },
      editPL: false,
      showStatusModal: false,
      showCreateModal: false,
      currentLotificationId: null,
      proyecto: {
        manzanas: [],
        areas_verdes: [],
        calles: [],
        lotes_regulares: 0,
        lotes_irregulares: 0,
        superficie: 0
      },
      proyectosProps: {
        data: []
      },
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Regulares", value: "lotes_regulares" },
        { text: "Irregulares", value: "lotes_irregulares" },
        { text: "Superficie", value: "superficie" },
        { text: "Estado", value: "estado" },
        { text: "Acciones", value: "", sortable: false }
      ],
      campoActualizado: { id: "", name: "" },
      pagination: {
        sortBy: "created_at",
        descending: true
      },

      showFiltersForm: false,
      filters: {               
        filterEstado: null,        
        filterProyecto: null,
      },
      itemsEstados: [],
      proyectoItems: [],

    };
  },
  watch: {
    showStatusModal: function() {
      this.getPropuestasDeLotificacion();
    },
    search: function() {
      this.getPropuestasDeLotificacion();
    }
  },
  methods: {
    openStatusModal: function(lotificacionId) {
      this.showStatusModal = true;
      this.currentLotificationId = lotificacionId;
    },
    cleanForm() {
      this.proyecto = {
        manzanas: [],
        areas_verdes: [],
        calles: [],
        lotes_regulares: 0,
        lotes_irregulares: 0,
        superficie: 0
      };
      this.dialog = false;
    },
    sweetAlert: function(proyecto) {
      swal({
        title: "¡Advertencia!",
        title: "¿estás seguro?",
        text: "Esta propuesta de lotificación se eliminará.",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
          // this.removingRole = true;
          this.eliminar(proyecto.id);
          this.getPropuestasDeLotificacion();
          swal("El Registro ha sido eliminado", {
            icon: "success"
          });
        } else {
          swal("El Registro no se eliminó");
        }
      });
    },
    eliminar: proyectoId => {
      var IDproyecto = proyectoId;
      axios
        .delete(route("proyectos.destroy", IDproyecto))
        .then(response => {
          if (response.data.message_text) {
            alertify.success(response.data.message_text);
          }
        })
        .catch(error => errorHandling(error));
      // .finally(() => this.removingRole = false)
    },
    aceptar: proyecto => {
      var IDproyecto = proyecto.id;
      axios
        .post(laravelRoute("proyectos.vistobueno.gerencia"), { id: IDproyecto })
        .then(response => {
          if (response.data.message_text) {
            alertify.success(response.data.message_text);
          }
        })
        .catch(error => {
          errorHandling(error);
          //proyectosProps.item.aprobado_planeacion - es el v-model del checkbox ha que setearlo a false
          this.proyectosProps[
            this.proyectosProps.indexOF(proyecto)
          ].aprobado_gerencia = false;
        });
    },
    acceptarplaneacion: proyecto => {
      var IDproyecto = proyecto.id;
      axios
        .post(laravelRoute("proyectos.vistobueno.planeacion"), {
          id: IDproyecto
        })
        .then(response => {
          if (response.data.message_text) {
            alertify.success(response.data.message_text);
          }
        })
        .catch(error => {
          errorHandling(error);
          this.proyectosProps[
            this.proyectosProps.indexOF(proyecto)
          ].aprobado_planeacion = false;
        });
    },
    editproyecto: function(proyectoId) {
      this.dialog = true;
      this.proyecto.id = proyectoId;
    },
    acceptProyecto: function(proyecto) {
      swal({
        title:
          "¿Esta seguro que quiere dar visto bueno a esta propuesta de lotificación?",
        text:
          "Tenga en cuenta que, por el momento, esta acción será irrevocable",
        icon: "warning",
        buttons: true
      }).then(response => {
        if (response) {
          this.aceptar(proyecto);
          swal("La propuesta se aprobó satisfactoriamente", {
            icon: "success"
          }).then(response => {
            setTimeout(() => {
              window.location.href = "/proyectos";
            });
          });
        } else {
          swal("La propuesta no se aprobó");
        }
      });
    },
    vistobuenoPlaneacion: function(proyecto) {
      swal({
        title:
          "¿Está seguro que quiere dar visto bueno a esta propuesta de lotificación?",
        text:
          "Tenga en cuenta que, por el momento, esta acción será irrevocable",
        icon: "warning",
        buttons: true
      }).then(response => {
        if (response) {
          this.acceptarplaneacion(proyecto);
          swal("La propuesta se aprobó satisfactoriamente", {
            icon: "success"
          }).then(response => {
            setTimeout(() => {
              window.location.href = "/proyectos";
            });
          });
        } else {
          swal("La propuesta no se aprobó");
        }
      });
    },
    getPropuestasDeLotificacion: function(page = 1) {
      axios.get(`/proyectos?page=${page}&query=${this.search}`)
      .then(response => {
        this.proyectosProps = response.data
      })
      .catch(error => errorHandling(error));
    },
    openCreateModal: function() {
      this.showCreateModal = true;
    },
    
    getEstados: function() {
        axios.get(`/proyectos/estados`)
        .then(response => this.itemsEstados = response.data)
        .catch(error => errorHandling(error));
    },
    getFraccionamientos: function(){
      axios.post('/tramites_dependencias/getFraccionamientos').then(response => {
        this.proyectoItems = response.data;
      }).catch(error => errorHandling(error));
    },
    filterProyectos: function() {
      axios.post('/proyectos/filterProyectos',this.filters)
      .then(response => this.proyectosProps = response.data)
      .catch(error => errorHandling(error));
    },
    limpiar: function () {
        this.filters.filterEstado = null;
        this.filters.filterProyecto =  null;
        this.getPropuestasDeLotificacion();
    },
    exportToExcel: function() {
      axios.post('/proyectos/filterProyectos', {
        ...this.filters,
        to_excel: true
      }, {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'propuestasLotificacion.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
    },
  },
  created() {
    this.getEstados();
    this.getFraccionamientos();
    this.getPropuestasDeLotificacion();
  }
};
</script>