<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="850px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Nueva máquina</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex md6>
                <v-text-field v-model="maquinaria.nombre" required label="Nombre de la máquina"></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
              <v-select
                color="verdeFayal"
                :items="estados"
                item-value="id"
                item-text="estado"
                label="Seleccione status"
                v-model="maquinaria.estado"
              ></v-select>
              </v-flex>
              <v-flex md12>
                <v-text-field v-model="maquinaria.descripcion" label="Descripción" counter="250" type="text" min="0" max="250"></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm12 md12>
                <v-switch label="Agregar mantenimiento" style="margin-top: 0;" color="verdeFayal" v-model="createMantenimiento">
                  <!--  :disabled="!$root.can('new', permissions)" -->
                </v-switch>
              </v-flex>
            </v-layout>
            <v-layout grid-list-md>
              <v-form ref="form" lazy-validation>
                <v-container grid-list-md v-for="(mantenimiento, index) in mantenimientos" :key="index">
                  <v-flex sm12 xs12 md12>
                    <v-layout wrap v-if="createMantenimiento">
                      <v-flex xs12 sm4 md4>
                        <v-select
                          color="verdeFayal"
                          :items="mantenimientoslistProps"
                          v-model="mantenimiento.mantenimiento_id"
                          item-value="id"
                          item-text="nombre"
                          label="Seleccione mantenimiento"
                        ></v-select>
                      </v-flex>
                      <v-flex xs12 sm3 md3>
                        <v-select
                          color="verdeFayal"
                          :items="statusMantenimiento"
                          v-model="mantenimiento.estado"
                          item-value="id"
                          item-text="estado"
                          label="Seleccione status"
                        ></v-select>
                      </v-flex>
                      <v-flex xs10 sm3 md3>
                        <v-text-field color="#1ABA24" v-model="mantenimiento.fecha" label="Fecha de inicio de mantenimiento" type="date"></v-text-field>
                      </v-flex>
                      <v-flex xs1 sm1 md1>
                        <v-btn v-if="index != 0" @click="mantenimientos.splice(index, 1)" fab flat dark small color="verdeFayal">
                            <v-icon dark>remove</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-container>
              </v-form>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="storeMaquinaria" :loading="vBtnSave.loading" dark color="verdeFayal" flat> Guardar </v-btn>
          <v-btn v-if="createMantenimiento" dark color="verdeFayal" @click="addMateriales">
            <v-icon>add</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>  
</template>

<script>
  import {errorHandling, laravelRoute} from '../../../lib/utilities';

  export default {
    data(){
      return{
        localShow: false,
        createMantenimiento: false,
        mantenimientoslistProps: this.mantenimientoslist,
        keepStoring: true,
        estados: [
          {id:5, estado:'Disponible'},
          {id:6, estado: 'Averiado'},
          {id:7, estado: 'Mantenimiento'}
        ],
        maquinaria: {
          nombre: null,
          operador: null,
          descripcion: null,
          estado: null,
          proyecto_perteneciente: null,
          gasto_diesel: null,
          costo_operador: null,
        },
        mantenimientos: [{
          mantenimiento_id: null,
          estado: null,
          fecha: null
        }],
        status: [],
        statusMantenimiento: [],
        vBtnSave: {
          loading: false
        },
        vSnackBar: {
          visible: false,
          text: null
        }
      }
    },
    props: {
      show: Boolean,
      prospectos: Array,
      mantenimientoslist: Array
    },
    methods:{
      storeMaquinaria: function () {
        this.vBtnSave.loading = true;
        axios.post(laravelRoute('maquinaria.store'), {
          nombre: this.maquinaria.nombre,
          proyecto_perteneciente: this.maquinaria.proyecto_perteneciente,
          descripcion: this.maquinaria.descripcion,
          operador: this.maquinaria.operador,
          horas_trabajadas: this.maquinaria.horas_trabajadas,
          costo_operador: this.maquinaria.costo_operador,
          gasto_diesel: this.maquinaria.gasto_diesel,
          fecha_de_termino: this.maquinaria.fecha_de_termino,
          estado: this.maquinaria.estado,
          mantenimientos: this.mantenimientos.filter(mantenimiento => mantenimiento.mantenimiento_id &&  mantenimiento.estado && mantenimiento.fecha)
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.maquinaria = {};
          if(this.mantenimientos){
            this.createMantenimiento = false;
            this.mantenimientos = [{}];
          }
          this.localShow = false;
        })
        .catch(error => {
          errorHandling(error)
        })
        .finally(()=>this.vBtnSave.loading = false);
      },
      getStatus: function(){
        axios.get("/maquinaria/estados").then(response => {
            this.status = response.data;
          });
      },
      getStatusMantenimiento: function(){
        axios
        .get(laravelRoute('mantenimientos.estados'))
        .then(response => {
          this.statusMantenimiento = response.data;
        })
        .catch(error => errorHandling(error));
      },
      addMateriales: function() {
        this.mantenimientos.push({
          mantenimiento_id: null,
          estado: null,
          fecha: null
        });
      },
    },
    watch: {
      show: function (value) {
        this.localShow = value
      },
      localShow: function (value) {
        this.$emit('update:show', value);
        this.getStatus();
        this.getStatusMantenimiento();
      }
    }
  }
</script>