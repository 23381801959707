<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" max-width="1250px" persistent>
      <v-card>
        <v-toolbar color="white" flat tabs>
            <v-spacer></v-spacer>
            <v-toolbar-title class="primary-title">Administrar recepción</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-data-table :headers="vHeaders" :items="requisicion.materiales_requisicion" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
                <td>{{ (props.item.proveedor) ? props.item.proveedor.nombre_completo : 'Aún sin proveedor' }}</td>
                <td>{{ props.item.material.categoria_asociada }}</td>
                <td>{{ props.item.material.nombre}}</td>
                <td>{{ props.item.cantidad}}</td>
                <td style="width: 10px;">
                  <v-text-field
                    type="number"
                    min="1"
                    full-width
                    :disabled="(props.item.estado_surtido != 'Pendiente de surtir')"
                    v-model="props.item.cantidad_a_surtir"
                    :max="props.item.cantidad"
                  ></v-text-field>
                </td>
                <td>{{ props.item.material.precio | currency}}</td>
                <td>{{ props.item.estado_surtido }}</td>
                <td>
                  <v-layout>
                    <v-tooltip bottom>
                      <v-btn 
                        v-if="props.item.estado_surtido == 'Surtido parcialmente'"
                        icon 
                        flat 
                        dark 
                        small 
                        color="verdeFayal" 
                        slot="activator"
                        @click="openAddMissingMaterial(props.item.material.id, props.item.requisicion_id)"
                      >
                      <!--requisicion.id-->
                        <v-icon>add</v-icon>
                      </v-btn>
                      <span>Recibir material faltante</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <v-btn icon flat dark small 
                      :disabled="(props.item.estado_surtido != 'Pendiente de surtir') || (requisicion.estado.information.estado == 'Espera de aprobacion')" 
                      color="verdeFayal" 
                      slot="activator" 
                      @click="surtirMaterial(props.item.id, props.item.cantidad_a_surtir)">
                        <v-icon>compare_arrows</v-icon>
                      </v-btn>
                      <span>Surtir material</span>
                    </v-tooltip>
                  </v-layout>
                </td>
            </template>
        </v-data-table>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
        {{ vSnackBar.text }}
        <v-btn color="pink" flat @click="vSnackBar.visible = false">
          <v-icon>close</v-icon>
        </v-btn>
    </v-snackbar>

    <add-missing-material-modal 
    :show.sync="showAddMissingMaterial" 
    :requisicion-material-id.sync="currentRequisicionMaterial"
    :requisicion-id.sync="currentRequisicionId"/>
  </div>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  export default {
   data(){
      return{
        categories: [],
        materials: [],
        requisicion: {
          materiales: [],
          estado: {
              information: {}
          }
        },
        materiales: [
          {
            categoria_id: null,
            material: {
                precio: 0
            },
            cantidad: 0,
            materiales: []
          }
        ],
        estado: [],
        solicitante: ['solicitante 1','solicitante 2'],
        proveedores: ['proveedor 1', 'proveedor 2'],
        solicitante:['Almacén'],
        currentRequisicionMaterial: null,

        currentRequisicionId: null,

        showAddProvideModal: false,
        showAddMissingMaterial: false,
        vPaginator: {
            limit: 10
        },
        vHeaders: [
            { text: 'Proveedor', value: 'proveedor' },
            { text: 'Categoria', value: 'categoria' },
            { text: 'Material', value: 'material' },
            { text: 'Cantidad requerida', value: 'cantidad' },
            { text: 'Cantidad a surtir', value: 'cantidad_a_surtir' },
            { text: 'Precio unitario', value: 'precio' },
            { text: 'Surtido', value: 'surtido' },
            { text: 'Acciones', value: 'acciones', sortable: false }
        ],
        localSearch: null,
        localShow: false,
        vTabs: "tab-edit-requisicion",
        reload: false,
        localItemId: null,
        valid: false,
        rules: [
          v => !!v || 'Este campo es requerido',
        ],
        vBtnSave: {
            loading: false
        },
        vSnackBar: {
          visible: false,
            text: null
          }
        }
        },
        props:{
          show: Boolean,
          itemId: Number,
          modelType: String
      },
    watch: {
      show: function (value) {
        this.localShow = value
      },
      localShow: function (value) {
          this.$emit('update:show', value)
      },
      itemId: function(value) {
        this.localItemId = value;
        this.getRequisicion();
      },
      vTabs: function(value) {
        this.reload = (value == "tab-index-material") ? true : false;
      },
      showAddProvideModal: function() {
        this.getRequisicion();
      },
      showAddMissingMaterial: function() {
        this.getRequisicion();
      }
    },
    methods:
    {
        getRequisicion: function(page = 1) {
            axios.get(`/requisiciones/${this.itemId}`)
            .then(response => {
                this.requisicion = response.data;
            }) 
            .catch( error => errorHandling(error));
        },
        deleteMaterialValue: function(materialRequisicionId) {
          swal({
            title: "¿Estás seguro?",
            text: "Una vez eliminado no se podrá recuperar",
            icon: "warning",
            buttons: true,
            dangerMode: true
          }).then(result => {
            if (result) {
              axios.delete(`/requisiciones/materiales/${materialRequisicionId}`)
              .then(response => {
                this.getRequisicion();
                alertify.success(response.data.message_text);
              })
              .catch(error => {
                errorHandling(error);
              });
            }
          })
        },
        getCategories: function() {
            axios.get(laravelRoute("categorias.index", {
                query: "Materiales de maquinaría"
            }))
            .then(response => {
                this.categories = response.data.data;
            })
            .catch(error => errorHandling(error));
        },
        getCurrentMateriales: function(category_id, index) {
            var categories = [];
            this.categories.map(function(category) {
                categories.push(category);
            });
            this.materiales[index].materiales = categories.find(function(category) {
                return category.id == category_id;
            }).materiales;
        },
        addMateriales: function(){
            this.materiales.push({
                categoria_id: null,
                material: {
                    precio: 0
                },
                cantidad: 0,
                materiales: []
            });
        }, 
        storeAddMaterial: function() {
            if (this.$refs.form.validate()) {
                this.vBtnSave.loading = true;
                axios.post(laravelRoute('requisiciones.materiales'), {
                    materiales: this.materiales,
                    requisicion_id: this.itemId
                })
                .then(response => {
                    this.getRequisicion();
                    this.materiales = [
                        {
                            categoria_id: null,
                            material: {
                                precio: 0
                            },
                            cantidad: 0,
                            materiales: []
                        }
                    ]
                    alertify.success(response.data.message_text);
                })
                .catch(error => errorHandling(error))
                .finally(()=>this.vBtnSave.loading = false);
            }
        },
        updateRequisicion: function(){
          this.vBtnSave.loading = true;
          axios.patch('/requisiciones/'+this.itemId, {
            nombre_requisicion: this.requisicion.nombre_requisicion,
            solicitante: this.requisicion.solicitante,
            proveedor: this.requisicion.proveedor_id,
            descripcion: this.requisicion.descripcion,
            estado: this.requisicion.estado.information.id
          })
          .then(response => {
            alertify.success(response.data.message_text);
            this.getRequisicion();
          })
          .catch(error => {
            errorHandling(error)
          })
          .finally(()=>this.vBtnSave.loading = false);
        },
        getStatus: function(){
            axios.get('/requisiciones/estados').then(response => {
                this.estado = response.data;
            });
        },
        getProveedores: function(){
            axios.get('/proveedores').then(response => {
                this.proveedores = response.data;
            });
        },
        surtirMaterial: function(materialRequisicionId, cantidadASurtir) {
          axios.patch(`/materiales/surtir/${materialRequisicionId}?cantidad_a_surtir=${cantidadASurtir}`)
          .then(response => {
              this.getRequisicion();
              alertify.success(response.data.message_text);
          })
          .catch(error => errorHandling(error));
        },
        openAddProvider: function(materialRequisicionId) {
          this.currentRequisicionMaterial = materialRequisicionId;
          this.showAddProvideModal = true;
        },
        surtirRequisicion: function() {
          axios.patch('/requisiciones/surtir/'+this.requisicion.id).then(response => {
            this.getRequisicion();
            alertify.success(response.data.message_text);
          })
          .catch(error => errorHandling(error));
        },
        openAddMissingMaterial: function(materialRequisicionId,requisicionId) {
            this.currentRequisicionMaterial = materialRequisicionId;
            this.currentRequisicionId = requisicionId,
            this.showAddMissingMaterial = true;
        }
    },
    created(){
        this.getCategories();
        this.getStatus();
        this.getProveedores();
    }
  }
  </script>