<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Editar material {{ material.nombre }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md6>
              <searcher
                :configuration="searchCategories"
                :itemSelected.sync="material.categoria_id"
              ></searcher>
            </v-flex>
            <v-flex md6>
              <v-text-field v-model="material.nombre" required label="Nombre"></v-text-field>
            </v-flex>
            <v-flex md6>
              <v-select :items="unidades" v-model="material.unidad" label="Unidad" single-line></v-select>
            </v-flex>
            <v-flex md6>
              <v-text-field step=".01" prepend-icon="attach_money" v-model="material.precio" required label="Precio unitario sin IVA" type="number" min="0"></v-text-field>
            </v-flex>
            <v-flex md6>
              <v-text-field step=".01" v-model="material.minimo" required label="Mínimo" type="number" min="0"></v-text-field>
            </v-flex>
            <v-flex md6>
              <v-text-field step=".01" type="number" v-model="material.existencia" label="Existencia"></v-text-field>
            </v-flex>
            <v-flex md12>
              <v-textarea v-model="material.especificaciones" label="Especificación" placeholder="Número de cantidad, unidades..."></v-textarea>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outline dark color="verdeFayal" @click="updateMaterial" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    categories: [],
    material: {},
    unidades: [
      "Metros",
      "Metros cuadrados",
      "Metros cúbicos",
      "Bultos",
      "Piezas",
      "Rollo",
      "Litros",
      "Juego",
      "Kilogramos"
    ],
    searchCategories: {
      url: "/categorias",
      paginatedContainer: 'data',
      noDataText: "No se encontraron resultados.",
      label: "Categoria",
      itemText: "nombre",
      itemValue: "id",
      descriptionLimit: 60,
      disabled: false,
      useHeaders: true,
    },
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    materialId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    materialId: function (value) {
      this.getMaterial();
    },
    localShow: function (value) {
      this.$emit('update:show', value)
    }
  },
  methods: {
    getMaterial: function () {
      axios.get(laravelRoute('materiales.show', this.materialId))
        .then(response => this.material = response.data)
        .catch(error => errorHandling(error));
    },
    updateMaterial: function () {
      this.vBtnSave.loading = true;
      axios.patch('/materiales/' + this.materialId, {
          nombre: this.material.nombre,
          unidad: this.material.unidad,
          precio: this.material.precio,
          existencia: this.material.existencia,
          categoria: (typeof this.material.categoria_id === 'object') ? this.material.categoria_id.id : this.material.categoria_id,
          minimo: this.material.minimo,
          especificaciones: this.material.especificaciones
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.vBtnSave.loading = false;
          this.localShow = false;
        })
        .catch(error => {
          this.vBtnSave.loading = false;
          errorHandling(error)
        });
    },
    getCategories: function () {
      axios.get(laravelRoute('categorias.index'))
        .then(response => {
          this.categories = response.data.data;
        })
        .catch(error => errorHandling(error));
    }
  },
  mounted: function () {
    this.getCategories();
  }
}
</script>
