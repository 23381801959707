<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Administrar cliente del lote</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-flex md12>
          <v-tabs v-model="vTabs" centered slider-color="verdeFayal">
            <v-tab href="#datos-cliente-tab">Datos cliente y vendedor</v-tab>
            <v-tab href="#datos-compra-tab" v-if="ventas">Datos de venta</v-tab>
          </v-tabs>
          <v-tabs-items v-model="vTabs">
            <v-tab-item value="datos-cliente-tab">
              <v-card flat>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-autocomplete 
                      v-model="vAutocompleteCustomer.select" 
                      :items="vAutocompleteCustomer.customers" 
                      :loading="vAutocompleteCustomer.isLoading" 
                      v-bind:search-input.sync="vAutocompleteCustomer.search" 
                      label="Buscar cliente" 
                      item-text="nombre_completo" 
                      item-value="id" 
                      browser-autocomplete="off" 
                      hint="Ingresa por lo menos 3 caracteres" 
                      return-object
                    ></v-autocomplete>
                  </v-layout>
                  <v-layout wrap v-if="vAutocompleteCustomer.select.nombre_completo">
                      <v-flex md6>
                          <v-text-field v-model="vAutocompleteCustomer.select.nombre_completo" required label="Nombre" readonly></v-text-field>                   
                      </v-flex>
                      <v-flex md6>
                          <v-text-field v-model="vAutocompleteCustomer.select.telefono" required label="Teléfono" readonly></v-text-field>                   
                      </v-flex>
                      <v-flex md6>
                          <v-text-field v-model="vAutocompleteCustomer.select.email" required label="Correo electrónico" readonly></v-text-field>                   
                      </v-flex>
                      <v-flex md6>
                          <v-text-field v-model="vAutocompleteCustomer.select.credito" required label="Método de pago" readonly></v-text-field>
                      </v-flex>
                  </v-layout>
                  <v-layout wrap>
                      <v-flex md6>
                          <v-switch
                              v-model="isThereAnSalesman"
                              label="¿Añadir vendedor?"
                          ></v-switch>
                      </v-flex>
                  </v-layout>
                  <v-layout wrap v-if="isThereAnSalesman">
                      <v-flex md12>
                          <v-autocomplete 
                              v-model="vAutocompleteSalesman.select" 
                              :items="vAutocompleteSalesman.salesman" 
                              :loading="vAutocompleteSalesman.isLoading" 
                              v-bind:search-input.sync="vAutocompleteSalesman.search" 
                              label="Buscar vendedor" 
                              item-text="nombre_completo"
                              item-value="id" 
                              browser-autocomplete="off" 
                              hint="Ingresa por lo menos 3 caracteres" 
                              return-object
                          ></v-autocomplete> 
                      </v-flex>
                      <template v-if="vAutocompleteSalesman.select">
                          <v-flex md6>
                              <v-text-field v-model="vAutocompleteSalesman.select.nombre_completo" required label="Nombre" readonly></v-text-field>                   
                          </v-flex>
                          <v-flex md6>
                              <v-text-field v-model="vAutocompleteSalesman.select.telefono" required label="Teléfono" readonly></v-text-field>                   
                          </v-flex>
                          <v-flex md6>
                              <v-text-field v-model="vAutocompleteSalesman.select.email" required label="Correo electrónico" readonly></v-text-field>                   
                          </v-flex>
                      </template>
                  </v-layout>
                </v-container>
              </v-card> 
            </v-tab-item>
            <v-tab-item value="datos-compra-tab" v-if="ventas">
              <v-card flat>
                <v-container grid-list-md>    
                  <v-layout wrap>
                    <v-flex md6>
                      <v-text-field type="number" min="0" label="Valor del avaluo" v-model="ventas.valor_de_avaluo"></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field type="number" min="0" label="Valor de saldo" v-model="ventas.valor_de_saldo"></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field type="number" min="0" label="Anticipo" v-model="ventas.anticipo"></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md6 v-if="diferencia > 0">
                      <v-text-field label="Diferencia" v-model="diferencia" readonly></v-text-field>
                    </v-flex>
                    <v-flex md6 v-if="monto_a_devolver > 0">
                      <v-text-field label="Monto a devolver" v-model="monto_a_devolver" readonly></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-text-field type="date" label="Fecha màxima a pagar" v-model="ventas.fecha_maxima_a_pagar"></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-checkbox :disabled="!esEntregable" v-model="ventas.pagado" label="Pagado"></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap v-if="esEntregable">
                    <v-flex md4>
                     <v-text-field type="date" label="Fecha de firma" v-model="ventas.fecha_de_firma"></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field type="date" label="Fecha de entrega" v-model="ventas.fecha_de_entrega"></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-checkbox v-model="ventas.cliente_conforme" label="Cliente conforme"></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex md6>               
                      <v-btn v-if="ventas.liberar_lote == true" dark color="warning" @click="realeseLot(ventas.id)" :loading="vBtnSave.loading">Liberar lote</v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card> 
            </v-tab-item>
          </v-tabs-items>                    
        </v-flex>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" flat @click="clickingButton" :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
export default {
  data: () => ({
    ventas: {},
    metodo: null,
    isThereAnSalesman: false,
    vAutocompleteCustomer: {
      isLoading: false,
      customers: [],
      search: null,
      select: {},
    },
    vAutocompleteSalesman: {
      isLoading: false,
      salesman: [],
      search: null,
      select: {},
    },
    cliente: {},
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    },
    vTabs: "datos-cliente-tab",
  }),
  props: {
    show: Boolean,
    loteId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
      this.getInfo();
    },
    loteId: function() {
      this.getInfo();
    },
    searchCustomers(input) {
      this.debouncedGetCustomer()
    },
    searchSalesman(input) {
      this.debouncedGetSalesman()
    },
    vTabs: function() {
      this.getInfo();
    }
  },
  computed: {
    searchCustomers: function () {
      return this.vAutocompleteCustomer.search
    },
    searchSalesman: function () {
      return this.vAutocompleteSalesman.search
    },
    monto_a_devolver: function() {
      var total = 0;
      var saldo = parseInt(this.ventas.valor_de_avaluo);
      var valor = parseInt(this.ventas.valor_de_saldo);
      if(valor > saldo) {
        total = valor - saldo;
      }
      return total;
    },
    diferencia: function() {
      var total = 0;
      var saldo = parseInt(this.ventas.valor_de_avaluo);
      var valor = parseInt(this.ventas.valor_de_saldo);
      if(saldo > valor) {
        total = saldo - valor;
      }
      return total;
    },
    esEntregable: function() {
      var esEntregable = false;
      var saldo = parseInt(this.ventas.valor_de_avaluo);
      var valor = parseInt(this.ventas.valor_de_saldo);
      if(valor >= saldo) {
        esEntregable = true;
      }
      return esEntregable;
    }
  },
  methods: {
        getInfo: function() {
            axios.get('/ventas/'+this.loteId)
            .then(response => {
                this.ventas = response.data;
                this.metodo = response.data.metodo_de_pago;
                this.vAutocompleteCustomer.select = (response.data.cliente) ? response.data.cliente : {};
                this.isThereAnSalesman = (response.data.vendedor) ? true: false;
                this.vAutocompleteSalesman.select = (response.data.vendedor) ? response.data.vendedor : {};
            })
            .catch(error => errorHandling(error));
        },
        getCustomers: function() {
            if (!this.vAutocompleteCustomer.search || this.vAutocompleteCustomer.search.length < 3) return
            this.vAutocompleteCustomer.isLoading = true
            let queryParam = {
                query: this.vAutocompleteCustomer.search
            }
            return axios.get(laravelRoute('clientes.index', queryParam))
            .then(response => {
                this.vAutocompleteCustomer.customers = response.data.data ? response.data.data : response.data
                this.vAutocompleteCustomer.isLoading = false
            })
            .catch(error => errorHandling(error))
        },
        getSalesman: function() {
            if (!this.vAutocompleteSalesman.search || this.vAutocompleteSalesman.search.length < 3) return
            this.vAutocompleteSalesman.isLoading = true
            let queryParam = {
                query: this.vAutocompleteSalesman.search
            }
            return axios.get(laravelRoute('vendedores.index', queryParam))
            .then(response => {
                this.vAutocompleteSalesman.salesman = response.data.data ? response.data.data : response.data
                this.vAutocompleteSalesman.isLoading = false
            })
            .catch(error => errorHandling(error))
        },
        clickingButton: function() {
            if(this.ventas.id) {
                this.updateSale();
                console.log("update")
            }
            else {
                this.storeSale();
                console.log("store")
            }
        },
        storeSale: function() {
            this.vBtnSave.loading = true;
            axios.post('/ventas', {
                lote_id: this.loteId,
                vendedor_id: this.vAutocompleteSalesman.select.id,
                cliente_id: this.vAutocompleteCustomer.select.id,
                is_there_an_salesman: this.isThereAnSalesman
            })
            .then(response => {
                this.vSnackBar.visible = true;
                this.vSnackBar.text = response.data.message_text;
                this.getInfo();
            })
            .catch(error => {
                errorHandling(error)
            })
            .finally(()=>this.vBtnSave.loading = false);
        },
        updateSale: function() {
            this.vBtnSave.loading = true;
            axios.patch('/ventas/'+this.ventas.id, {
                lote_id: this.loteId,
                vendedor_id: this.vAutocompleteSalesman.select.id,
                cliente_id: this.vAutocompleteCustomer.select.id,
                is_there_an_salesman: this.isThereAnSalesman,
                valor_de_avaluo: this.ventas.valor_de_avaluo,
                valor_de_saldo: this.ventas.valor_de_saldo,
                anticipo: this.ventas.anticipo,
                fecha_maxima_a_pagar: this.ventas.fecha_maxima_a_pagar,
                pagado: this.ventas.pagado,
                fecha_de_firma: this.ventas.fecha_de_firma,
                fecha_de_entrega: this.ventas.fecha_de_entrega,
                cliente_conforme: this.ventas.cliente_conforme,
                diferencia: this.diferencia,
                monto_a_devolver: this.monto_a_devolver
            })
            .then(response => {
                this.vSnackBar.visible = true;
                this.vSnackBar.text = response.data.message_text;
                this.getInfo();
            })
            .catch(error => {
                errorHandling(error)
            })
            .finally(()=>this.vBtnSave.loading = false);
        },
        realeseLot: function(ventaId) {
          swal({
            title: "¿Estás seguro?",
            text: "Estás a punto de liberar este lote",
            icon: "warning",
            buttons: true,
            dangerMode: true
          }).then(result => {
            if (result) {
              axios.delete(laravelRoute('ventas.destroy', ventaId))
                .then(response => {
                  this.vSnackBar.visible = true;
                  this.vSnackBar.text = response.data.message_text;
                  this.localShow = false;
                })
                .catch(error => {
                  errorHandling(error);
                });
            }
          })
        }
    },
    created() {
        this.debouncedGetCustomer = _.debounce(() => this.getCustomers(), 500)
        this.debouncedGetSalesman = _.debounce(() => this.getSalesman(), 500)
    }
}
</script>
