<template>
  <div>
    <v-card>
      <v-card-title class="fyl-headerTable">
        <v-flex class="card-blog" md6>
          <h2 class="card-title">Lista de Roles</h2>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex md3>
          <v-text-field
            color="#1ABA24"
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            sortable="true"
          ></v-text-field>
        </v-flex>
        <edit-role
          :roles="roles"
          :role="selectedRole"
          :permissions="permissions"
          :dialog="dialog"
          @open="dialog = true"
          @close="cleanForm()"
          style="display: none;"
        ></edit-role>
      </v-card-title>
      <v-data-table :headers="headers" :items="rolesProps" :search="search">
        <template v-slot:items="rolesProps">
          <td>{{ rolesProps.item.id }}</td>
          <td class="text-xs-left">{{ rolesProps.item.name }}</td>
          <td class="text-xs-left">
            <v-layout>
              <v-tooltip bottom>
                <v-btn
                  icon
                  flat
                  dark
                  small
                  slot="activator"
                  color="info"
                  @click="editRole(rolesProps.item)"
                >
                  <v-icon>edit</v-icon>
                </v-btn>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <v-btn
                  icon
                  flat
                  dark
                  small
                  slot="activator"
                  color="error"
                  @click="sweetAlert(rolesProps.item)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
                <span>Eliminar</span>
              </v-tooltip>
            </v-layout>
          </td>
        </template>
        <template v-slot:no-results>
          <v-alert
            :value="true"
            color="error"
            icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { errorHandling, laravelRoute } from "../../lib/utilities";
import axios from "axios";
import swal from "sweetalert";

export default {
  name: 'rolesList',
  data() {
    return {
      // showModal: false,
      selectedRole: null,
      search: "",
      dialog: false,
      role: {},
      rolesProps: this.roles,
      headers: [
        {
          text: "ID del Rol",
          align: "left",
          sortable: true,
          value: "id"
        },
        { text: "Nombre", value: "name", sortable: true },
        { text: "Acciones", value: "", sortable: false }
      ],
      campoActualizado: { 'id': '', 'name': ''},

    };
  },
  props: ["roles", "permissions"],
  methods: {
    cleanForm() {
      this.dialog = false;
    },
    // showText: function (textValue){
    //   alertify.success(textValue);
    // },
    sweetAlert: function(role) {
      swal({
        title: "¿estás seguro?",
        text: "Una vez eliminado el Rol no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
          // this.removingRole = true;
          this.eliminar(role);
        } else {
          swal("Tu Rol no se eliminó");
        }
      });
    },

    eliminar: function(role) {
      axios
        .delete(route("roles.destroy", role.id))
        .then(response => {
          if (response.data.message_text) {
            alertify.success(response.data.message_text);
            swal("El Rol ha sido eliminado", {
              icon: "success"
            });
            this.rolesProps.splice(this.rolesProps.indexOf(role), 1);            
          }
        })
        .catch(error => errorHandling(error));
      // .finally(() => this.removingRole = false)
    },
    getPermissions: function(){
      axios
      .get(route("roles.edit")
      )
    },

    editRole: function(role) {
      this.selectedRole = role;
      axios.get(route("roles.edit", role)).then(response => {
        this.role = response.data;
        this.dialog = true;
      });
    }
  }
}
</script>
<style>
  .fyl-headerTable{
    background: #F9FBFC;
  }
</style>

<!--<style scoped>
.v-sheet {
  width: 100%;
}
.rolesIndexList-Title {
  text-align: left;
  font-size: 1.5em;
  font-weight: bold;
  margin: 2.5% 0;
  text-transform: uppercase;
}

@media only screen and (max-width: 989px) and (min-width: 10px) {
  .v-sheet {
    width: 85%;
  }
}
</style>-->