<template>
    <v-layout>
    <v-container>
      <v-card>
        <v-flex xs12>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Mantenimientos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search"></v-text-field>
            <v-btn v-if="$root.can('new', permissions) ? true : false" class="fyl-btnCreate" @click="openCreateModal">Crear</v-btn>
          </v-toolbar>
          <v-data-table :headers="headers" disable-initial-sort :items="mantenimiento.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td>{{ props.item.maquinaria ? props.item.maquinaria.nombre : 'Sin nombre' }}</td>
              <td>{{ props.item.mantenimiento ? props.item.mantenimiento.nombre : 'No tiene mantenimiento' }}</td>
              <td>{{ props.item.estado ? props.item.estado.information.estado : 'No tiene estado' }}</td>
              <td>{{ props.item.fecha }}</td>
              <td>
                <v-tooltip bottom>
                  <v-btn :disabled="!$root.can('update', permissions)" @click="EditMantenimientoModal(props.item.id)" icon flat dark small slot="activator" color="info">
                    <v-icon color="verdeFayal">edit</v-icon>
                  </v-btn>
                  <span>Editar</span>
                </v-tooltip>
                <!--<v-tooltip bottom>
                  <v-btn @click="openAddMaterialsValuesModal(props.item.mantenimiento_id,props.item.nombre)"  icon flat dark small slot="activator" color="verdeFayal">
                      :disabled="!$root.can('valores-materiales-index', permissions)"
                      <v-icon>list</v-icon>
                  </v-btn>
                  <span>Requisición</span>
                </v-tooltip>-->
                <v-tooltip bottom>
                  <v-btn :disabled="!$root.can('remove', permissions)" @click="deleteMantenimiento(props.item.id)" icon flat dark small slot="activator" color="error" >
                    <v-icon>delete</v-icon>
                  </v-btn>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="mantenimiento" @pagination-change-page="getMantenimientos"></pagination>
          </v-card-actions>
        </v-flex>
      </v-card>
      <!-- modals -->
      <mantenimientos-modal-create
        :show.sync="showCreateModal"
        :maquinas="mantenimiento.data"
        :maquinariaslist="maquinariaslist"
        :mantenimientoslist="mantenimientoslist"
      ></mantenimientos-modal-create>
      <mantenimientos-modal-update
        :show.sync="showEditModal"
        :mantenimiento-id.sync="currentMantenimientoId"
        :mantenimientoslist="mantenimientoslist"
      ></mantenimientos-modal-update>
      <manage-requisitions-to-mantenimiento-modal
        v-bind:show.sync="showMaterialsToValuesModal"
        v-bind:item-id.sync="currentItemId"
        v-bind:modelType.sync="currentModel"
        v-bind:name.sync="currentItemName"
      ></manage-requisitions-to-mantenimiento-modal>
    </v-container>
  </v-layout>
</template>
<script>
export default {
  data(){
    return{
      search: null,
      showCreateModal: false,
      showEditModal: false,
      currentMantenimientoId: null,
      showMaterialsToValuesModal: false,
      currentItemId: null,
      currentItemName: null,
      currentModel: 'App\\MantenimientoRelacion',
      vPaginator: {
        limit: 10
      },
      estados: [],
      headers:[
        { value: 'nombre', text: 'Nombre de máquina' },
        { value: 'mantenimiento', text: 'Mantenimiento' },
        { value: 'estado', text: 'Estatus' },
        { value: 'fecha', text: 'Fecha de ejecución' },
        { value: '', text: 'Acciones' },
      ],
      mantenimiento: {
        data: []
      }
    }
  },
  props: ['mantenimientoslist', 'maquinariaslist', 'permissions'],
  watch:{
    search: function (value) {
      this.getMantenimientos();
    },
    showCreateModal: function (value) {
      if (!value) {
        this.getMantenimientos();
      }
    },
    showEditModal: function (value) {
      if (!value) {
        this.getMantenimientos();
      }
    },
    showMaterialsToValuesModal: function(value) {
      if(!value) {
        this.getValues()
      }
    }
  },
  methods:{
    getMantenimientos: function (page = 1) {
        axios.get(`/mantenimientos?page=${page}&query=${this.search}`)
        .then(response => {
          this.mantenimiento = response.data;
        })
        .catch(error => errorHandling(error));
      },
    deleteMantenimiento: function(mantenimientoId){
      swal({
      title: "¿Estás seguro?",
      text: "Una vez eliminado no se podrá recuperar",
      icon: "warning",
      buttons: true,
      dangerMode: true
      }).then(result => {
        if (result) {
          axios
          .delete(laravelRoute('mantenimientos.destroy', mantenimientoId))
          .then(response => {
            this.getMantenimientos();
            alertify.success(response.data.message_text);
            // setTimeout(()=>{
            //   window.location.href = "/catalogo_mantenimientos";
            // }, 300)
          })
          .catch(error => {
            errorHandling(error);
          });
        }
      })
    },
    getEstados: function(){
      axios
      .get(laravelRoute('mantenimientos.estados'))
      .then(response => {
        this.estados = response.data;
      })
      .catch(error => errorHandling(error));
    },
    openCreateModal: function () {
      this.showCreateModal = true;
    },
    EditMantenimientoModal: function (mantenimientoId) {
      this.showEditModal = true;
      this.currentMantenimientoId = mantenimientoId;
    },
    openAddMaterialsValuesModal: function(currentItemId, currentItemName) {
      this.showMaterialsToValuesModal = true;
      this.currentItemId = currentItemId;
      this.currentItemName = currentItemName;
    },
  },
  created(){
    this.getMantenimientos();
    this.getEstados();
  }
}
</script>