var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v(_vm._s(_vm.mode))
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-container",
                    { attrs: { "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", md6: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.creadosProps,
                                  label: "Nombre del proveedor",
                                  color: "#1ABA24",
                                  "item-text": "nombre_completo",
                                  "item-value": "id",
                                  "return-object": ""
                                },
                                on: { change: _vm.setPlazo },
                                model: {
                                  value: _vm.factura.proveedor,
                                  callback: function($$v) {
                                    _vm.$set(_vm.factura, "proveedor", $$v)
                                  },
                                  expression: "factura.proveedor"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  color: "#1ABA24",
                                  label: "Número de la factura"
                                },
                                model: {
                                  value: _vm.factura.numero_factura,
                                  callback: function($$v) {
                                    _vm.$set(_vm.factura, "numero_factura", $$v)
                                  },
                                  expression: "factura.numero_factura"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  color: "#1ABA24",
                                  label: "Monto",
                                  type: "number",
                                  min: "1"
                                },
                                model: {
                                  value: _vm.factura.monto,
                                  callback: function($$v) {
                                    _vm.$set(_vm.factura, "monto", $$v)
                                  },
                                  expression: "factura.monto"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  color: "#1ABA24",
                                  label: "Fecha de emisión",
                                  type: "date",
                                  id: "fechaEmision"
                                },
                                model: {
                                  value: _vm.factura.fecha_emision,
                                  callback: function($$v) {
                                    _vm.$set(_vm.factura, "fecha_emision", $$v)
                                  },
                                  expression: "factura.fecha_emision"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            {
                              staticClass: "tooltp",
                              attrs: { xs12: "", sm6: "", md6: "" }
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.plazos,
                                  "item-text": "nombre",
                                  "item-value": "dias",
                                  label: "Plazo",
                                  color: "#1ABA24",
                                  readonly: _vm.checkPlazo
                                },
                                on: { change: _vm.changePlazo },
                                model: {
                                  value: _vm.factura.plazo,
                                  callback: function($$v) {
                                    _vm.$set(_vm.factura, "plazo", $$v)
                                  },
                                  expression: "factura.plazo"
                                }
                              }),
                              _vm._v(" "),
                              _vm.factura.fecha_emision == "" || _vm.checkPlazo
                                ? _c("span", { staticClass: "tooltiptext" }, [
                                    _vm._v("Introducir Fecha de Emisión")
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm6: "", md6: "" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  color: "#1ABA24",
                                  label: "Fecha de vencimiento",
                                  type: "date",
                                  readonly: "readonly"
                                },
                                model: {
                                  value: _vm.dateEnd,
                                  callback: function($$v) {
                                    _vm.dateEnd = $$v
                                  },
                                  expression: "dateEnd"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm12: "", md12: "" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  color: "#1ABA24",
                                  label: "Comentarios",
                                  counter: "250",
                                  maxlength: _vm.max
                                },
                                model: {
                                  value: _vm.factura.comentario,
                                  callback: function($$v) {
                                    _vm.$set(_vm.factura, "comentario", $$v)
                                  },
                                  expression: "factura.comentario"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: { click: _vm.createInvoice }
                    },
                    [_vm._v(" Guardar ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }