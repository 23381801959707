var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "850px", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                {
                  attrs: { color: "white", tabs: "", flat: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "extension",
                      fn: function() {
                        return [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                centered: "",
                                color: "white",
                                "slider-color": "verdeFayal"
                              },
                              model: {
                                value: _vm.model,
                                callback: function($$v) {
                                  _vm.model = $$v
                                },
                                expression: "model"
                              }
                            },
                            [
                              _c(
                                "v-tab",
                                { attrs: { href: "#tab-concepto-datos" } },
                                [_vm._v("Datos del concepto")]
                              ),
                              _vm._v(" "),
                              _c("v-tab", { attrs: { href: "#tab-rubros" } }, [
                                _vm._v("Rubros asociados")
                              ])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v("Crear concepto")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.model,
                    callback: function($$v) {
                      _vm.model = $$v
                    },
                    expression: "model"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    { attrs: { value: "tab-concepto-datos" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          type: "text",
                                          required: "",
                                          label: "Nombre"
                                        },
                                        model: {
                                          value: _vm.concepto.nombre,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.concepto,
                                              "nombre",
                                              $$v
                                            )
                                          },
                                          expression: "concepto.nombre"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md6: "" } },
                                    [
                                      _vm.type == "urbanizacion"
                                        ? _c("v-select", {
                                            attrs: {
                                              items: [
                                                "Metros lineales",
                                                "Metros cuadrados",
                                                "Metros cúbicos",
                                                "Kilogramos",
                                                "Litros",
                                                "Unidades"
                                              ],
                                              label: "Unidad"
                                            },
                                            model: {
                                              value: _vm.concepto.unidad,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.concepto,
                                                  "unidad",
                                                  $$v
                                                )
                                              },
                                              expression: "concepto.unidad"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "tab-rubros" } },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { md12: "" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Nombre del rubro",
                                          "append-outer-icon": "add",
                                          hint:
                                            "Da enter o click en el botón para añadir el rubro"
                                        },
                                        nativeOn: {
                                          keyup: function($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.addRubro($event)
                                          }
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "append-outer",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-slide-x-reverse-transition",
                                                  { attrs: { mode: "out-in" } },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "success"
                                                        },
                                                        on: {
                                                          click: _vm.addRubro
                                                        }
                                                      },
                                                      [_vm._v("add")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ]),
                                        model: {
                                          value: _vm.rubro,
                                          callback: function($$v) {
                                            _vm.rubro = $$v
                                          },
                                          expression: "rubro"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    { attrs: { md12: "" } },
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          items: _vm.rubros,
                                          "hide-actions": "",
                                          flat: ""
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "headers",
                                            fn: function(props) {
                                              return [
                                                _c("tr", [
                                                  _c("th", [_vm._v("Nombre")]),
                                                  _vm._v(" "),
                                                  _c("th")
                                                ])
                                              ]
                                            }
                                          },
                                          {
                                            key: "items",
                                            fn: function(props) {
                                              return [
                                                _c("tr", [
                                                  _c("td", [
                                                    _vm._v(_vm._s(props.item))
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-xs-right"
                                                    },
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                            icon:
                                                                              "",
                                                                            color:
                                                                              "red"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.rubros.splice(
                                                                                props.index,
                                                                                1
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "delete"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "Eliminar rubro"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ])
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-switch", {
                    attrs: { color: "verdeFayal", label: "Seguir registrando" },
                    model: {
                      value: _vm.keepStoring,
                      callback: function($$v) {
                        _vm.keepStoring = $$v
                      },
                      expression: "keepStoring"
                    }
                  }),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.vBtnSave.loading,
                        color: "verdeFayal",
                        outline: "",
                        dark: "",
                        flat: ""
                      },
                      on: { click: _vm.storeConcept }
                    },
                    [_vm._v("Guardar")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }