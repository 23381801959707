<template>
  <div class="text-xs-center">
    <v-dialog flat v-model="localShow" max-width="700px" persistent>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Agregar comentarios</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <form v-on:submit.prevent="storeComentario()">
            <v-container style="max-width: 600px;">
              <v-timeline dense clipped>
                <v-timeline-item v-for="comentario in comentarios" :key="comentario.id" class="mb-3" color="verdeFayal" small>
                  <v-layout justify-space-between>
                    <v-flex xs7 v-text="comentario.comentario"></v-flex>
                    <v-flex xs5 text-xs-right v-text="comentario.created_at"></v-flex>
                  </v-layout>
                </v-timeline-item>
                <v-timeline-item fill-dot class="white--text mb-2" color="orange">
                  <v-text-field 
                    v-model="comentario"
                    hide-details 
                    flat 
                    label="Dejar un comentario..." 
                    solo
                    style="padding: 0;"
                  >
                  </v-text-field>
                </v-timeline-item>
              </v-timeline>
            </v-container>
          </form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark color="verdeFayal"  flat :loading="vBtnSave.loading" @click="storeComentario"> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import { errorHandling } from '../../lib/utilities';
  export default {
    props: {
      show: Boolean,
      tramiteId: Number
    },
    data(){
      return{
        comentario: null,
        comentarios: [],
        localShow: false,
        isInfiniteScrollLoading: false,
        data: [],
        currentPage: 0,
        vBtnSave: {
          loading: false
        },
        vSnackBar: {
          visible: false,
          text: null
        }
      }
    },
    watch: {
      show: function (value) {
        this.localShow = value
      },
      localShow: function (value) {
        this.$emit('update:show', value);
      },
      tramiteId: function() {
        this.getComentaries();
      }
    },
    methods:{
      getComentaries: function() {
        axios.get(`/comentarios?tramite_id=${this.tramiteId}`)
        .then(response => this.comentarios = response.data)
        .catch(error => errorHandling(error));
      },
      storeComentario: function() {
        axios.post(laravelRoute('comentarios.store'), {
            comentario: this.comentario,
            tramite_id: this.tramiteId
        })
        .then(response => {
            this.comentario = null; 
            this.vSnackBar.text = response.data.message_text;
            this.vSnackBar.visible = true;
            this.getComentaries();
        })
        .catch(error => errorHandling(error))
        .finally(()=>this.vBtnSave.loading = false);
      }
    }
  }
</script>