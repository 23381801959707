<template>
  <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn flat icon :href="`/etapas/${etapa.id}/edit`">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>

        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-toolbar card prominent>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="body-2 grey--text">Resumen de {{distribucion.nombre}} </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout>
                                <v-flex xs3>
                                    <v-card dark color='#75d57b'>
                                        <v-layout>
                                            <v-flex xs5>
                                                <v-layout  align-center justify-center row fill-height>
                                                    <v-img
                                                        src="/fonts/local/watch.svg"
                                                        height="50px"
                                                        contain
                                                    ></v-img>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs7>
                                                <v-card-title primary-title>
                                                    <div>
                                                        <div class="headline total-title">{{ resumen.horas.toFixed(2) }}</div>
                                                        <div>Horas</div>
                                                    </div>
                                                </v-card-title>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex xs3>
                                    <v-card dark color='#75d57b'>
                                        <v-layout>
                                            <v-flex xs5>
                                                <v-layout  align-center justify-center row fill-height>
                                                    <v-img
                                                        src="/fonts/local/construction.svg"
                                                        height="50px"
                                                        contain
                                                    ></v-img>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs7>
                                                <v-card-title primary-title>
                                                    <div>
                                                        <div class="headline total-title">$ {{ resumen.total_materiales | currency}}</div>
                                                        <div>Material</div>
                                                    </div>
                                                </v-card-title>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex xs3>
                                    <v-card dark color='#75d57b'>
                                        <v-layout>
                                            <v-flex xs5>
                                                <v-layout  align-center justify-center row fill-height>
                                                    <v-img
                                                        src="/fonts/local/manpower.svg"
                                                        height="50px"
                                                        contain
                                                    ></v-img>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs7>
                                                <v-card-title primary-title>
                                                    <div>
                                                        <div class="headline total-title">$ {{ resumen.total_valores | currency}}</div>
                                                        <div>Mano de obra</div>
                                                    </div>
                                                </v-card-title>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex xs3>
                                    <v-card dark color='#75d57b'>
                                        <v-layout>
                                            <v-flex xs5>
                                                <v-layout  align-center justify-center row fill-height>
                                                    <v-img
                                                        src="/fonts/local/total.svg"
                                                        height="50px"
                                                        contain
                                                    ></v-img>
                                                </v-layout>
                                            </v-flex>
                                            <v-flex xs7>
                                                <v-card-title primary-title>
                                                    <div>
                                                        <div  class="headline total-title">$ {{ resumen.total_materiales + resumen.total_valores | currency}}</div>
                                                        <div>Total</div>
                                                    </div>
                                                </v-card-title>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                            </v-layout>

                            <v-divider></v-divider>

                            <v-layout>
                                <v-flex xs12>
                                    <v-data-table
                                        :items="conceptos.data"
                                        :expand="expand"
                                        item-key="id"
                                        hide-actions
                                        ref="datatable"
                                    >
                                        <template v-slot:headers="props">
                                            <tr>
                                                <th>Concepto</th>
                                                <th>Cantidad</th>
                                                <th>Unidad</th>
                                                <th></th>
                                            </tr>
                                        </template>
                                        <template v-slot:items="props">
                                            <tr>
                                                <td>{{ props.item.concepto.nombre }}</td>
                                                <td>{{ props.item.cantidad }}</td>
                                                <td>{{ props.item.concepto.unidad }}</td>
                                                <td class="text-xs-right border-expanded-td">
                                                    <v-btn flat icon @click="props.expanded = !props.expanded">
                                                        <v-icon>expand_more</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-slot:expand="props">
                                            <v-card flat>
                                                <table class="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">Rubro</th>
                                                            <th scope="col">Horas de ejecución</th>
                                                            <th scope="col">Subtotal de material</th>
                                                            <th scope="col">Subtotal de mano de obra</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="item in props.item.rubros" :key="item.id">
                                                            <td></td>
                                                            <td>{{ item.rubro.nombre }}</td>
                                                            <td>{{ getSubtotalHoras(item.rubro.horas, props.item.cantidad) }}</td>
                                                            <td>$ {{ (item.rubro.materiales_valores.length) ? getSubtotalMateriales(item.rubro.materiales_valores, props.item.cantidad) : 0 | currency}} </td>
                                                            <td>$ {{ (item.rubro.valores) ? getSubtotalManoDeObra(item.rubro.valores, props.item.cantidad) : 0 | currency }}</td>
                                                        </tr>
                                                        <tr class="table-dark">
                                                            <td></td>
                                                            <td>Totales</td>
                                                            <td>{{ (!props.item.rubros.length) ? 0: getTotalHoras(props.item.rubros, props.item.cantidad).toFixed(2) }}</td>
                                                            <td>$ {{ getTotalMaterial(props.item.rubros, props.item.cantidad) | currency}} </td>
                                                            <td>$ {{ getTotalMano(props.item.rubros, props.item.cantidad) | currency }}</td>
                                                            <td>$ {{ getTotalMano(props.item.rubros, props.item.cantidad) + getTotalMaterial(props.item.rubros, props.item.cantidad) | currency }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </v-card>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <pagination :limit="10" :data="conceptos" @pagination-change-page="getConceptos"></pagination>
                                                <v-spacer></v-spacer>  
                                            </v-card-actions>
                                        </template>
                                    </v-data-table>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    data() {
        return {
            currentConceptosPage: 0,
            conceptos: {
                data: []
            },
            resumen: {
                horas: 0
            },
            expand: false,
            vBtnSave: {
                loading: false
            },
            vSnackBar: {
                visible: false,
                text: null
            },
        }
    },
    props: {
            etapa: Object,
            distribucion: Object,
            tipo: String
        },
        watch: {
            
        },
        computed: {
            
        },
        methods: {
            getConceptos: function(page = 1) {
                axios.get(`/conceptos/distribucion?tipo=${this.tipo}&page=${this.page}&distribucion_id=${this.distribucion.id}`)
                .then(response => this.conceptos = response.data)
                .catch(error => errorHandling(error));
            },
            getSubtotalMateriales: function(materiales, cantidad) {
                var total = 0;
                materiales.forEach(item => total = total + item.precio_total)
                return total * cantidad;
            },
            getSubtotalHoras: function(horas, cantidad) {
                let total = horas * cantidad;
                return total.toFixed(2)
            },
            getSubtotalManoDeObra: function(valores, cantidad) {
                var total = 0;
                valores.forEach(item => total = total + item.total_impuesto);
                return total * cantidad;
            },
            getTotalMaterial: function(rubros = [], cantidad) {
                let totales = rubros.map(item => {
                    let total = 0;
                    if(item.rubro.materiales_valores.length) {
                        item.rubro.materiales_valores.forEach(i => total = total + i.precio_total *  cantidad)
                    }
                    return total
                });

                return totales.reduce((a, b) => a+b);
            },
            getTotalMano: function(rubros, cantidad) {
                let total = 0;

                rubros = rubros.map(rubro => rubro.rubro.valores).flat().forEach(item => total = total + item.total_impuesto);

                return total * cantidad;
            },
            getTotales: function() {
                axios.get(`/conceptos/distribucion/totales?tipo=${this.tipo}&distribucion_id=${this.distribucion.id}&etapa_id=${this.etapa.id}`)
                .then(response => this.resumen = response.data)
                .catch(error => errorHandling(error));
            },
            getTotalHoras: function(rubros, cantidad) {
                var total = 0;
                rubros.forEach(item => total = total + item.rubro.horas);
                return total * cantidad;
            }
        },
        created() {
            this.getConceptos();
            this.getTotales();
        }
  }
</script>

<style>
    .border-expanded-td {
        border-left: 0.3px solid #e3e3e3;
    }

    .badge-secondary {
        background-color: #1aba24;
    }
</style>