<template>
    <div class="text-xs-center">
        <v-dialog v-model="localShow" width="1050" persistent>
            <v-card>
                <v-toolbar color="white" flat>
                    <v-spacer></v-spacer>
                    <v-toolbar-title class="primary-title">Añadir lotes a la Manzana {{manzana.nombre}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-form @keyup.enter.native="addLotes">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>                               
                                <span class="grey--text">Adición rápida de lotes</span>
                            </v-flex>
                            <v-flex xs3>
                              <v-select
                                label="Tipo de lote"
                                v-model="addingLote.tipo"
                                :items="['Regular', 'Irregular']"
                                :rules="[(v) => !!v || 'Campo requerido']"
                              ></v-select>
                            </v-flex>
                            <v-flex xs3>
                              <searcher
                                :configuration="searchModelo"
                                :itemSelected.sync="addingLote.modelo"
                              ></searcher>
                            </v-flex>
                            <v-flex xs3>
                              <v-text-field
                                v-model="addingLote.superficie"
                                color="verdeFayal"
                                label="Superficie en metros cuadrados"
                                type="number"
                                min="1"
                                :rules="[(v) => !!v || 'Campo requerido']"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs2>
                              <v-text-field
                                v-model="addingLote.cantidad"
                                color="verdeFayal"
                                label="Cantidad a agregar"
                                type="number"
                                min="1"
                                :rules="[(v) => !!v || 'Campo requerido']"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs1>
                              <v-tooltip bottom>
                                <v-btn
                                  slot="activator"
                                  color="verdeFayal"
                                  fab 
                                  dark 
                                  small
                                  @click="addLotes"
                                >
                                  <v-icon>add</v-icon>
                                </v-btn>
                                <span>Añadir lotes</span>
                              </v-tooltip>
                            </v-flex>
                        </v-layout>

                        <v-divider></v-divider>

                        <v-layout wrap
                          v-if="lotes.length"
                        >
                          <v-flex 
                            :xs12="editing ? false : true"
                            :xs6="editing ? true : false"
                          >
                            <v-data-table
                              hide-actions
                              :items="lotes"
                            >
                              <template v-slot:headers="props">
                                <tr>
                                  <th>Nombre</th>
                                  <th>Tipo</th>
                                  <th>Modelo</th>
                                  <th>Superficie (M2)</th>
                                  <th class="text-xs-right">Acciones</th>
                                </tr>
                              </template>
                              <template v-slot:items="props">
                                <tr :class="(props.item.nombre == editingLote.nombre) ? 'table-active' : ''">
                                  <td>{{props.item.nombre}}</td>
                                  <td>{{props.item.tipo}}</td>
                                  <td>{{props.item.modelo.nombre}}</td>
                                  <td>{{props.item.superficie}}</td>
                                  <td class="text-xs-right">
                                    <v-layout>
                                      <v-tooltip bottom>
                                        <v-btn
                                          slot="activator"
                                          icon
                                          color="verdeFayal"
                                          flat
                                          @click="editItem(props.item, props.index)"
                                        >
                                          <v-icon>edit</v-icon>
                                        </v-btn>
                                        <span>Editar</span>
                                      </v-tooltip>
                                      <v-tooltip bottom>
                                        <v-btn
                                          slot="activator"
                                          icon
                                          color="verdeFayal"
                                          flat
                                          @click="duplicateItem(props.item)"
                                        >
                                          <v-icon>content_copy</v-icon>
                                        </v-btn>
                                        <span>Duplicar lote</span>
                                      </v-tooltip>
                                      <v-tooltip bottom>
                                        <v-btn
                                          slot="activator"
                                          icon
                                          color="red"
                                          flat
                                          @click="deleteItem(props.index)"
                                        >
                                          <v-icon>delete</v-icon>
                                        </v-btn>
                                        <span>Eliminar lote</span>
                                      </v-tooltip>
                                    </v-layout>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-flex>

                          <v-flex 
                            class="conceptos-form"
                            v-if="editing"
                            xs6
                          >
                            <v-toolbar flat color="white">
                              <div>
                                <div class="body-2 grey--text">Editar lote seleccionado</div>
                                <span class="grey--text">Modifica los datos asociados al lote {{editingLote.nombre}}</span>
                              </div>
                              <v-spacer></v-spacer>
                              <v-btn flat icon color="verdeFayal" dark @click="editing = false">
                                <v-icon>close</v-icon>
                              </v-btn>
                            </v-toolbar>
                            <v-card-text>
                              <v-container>
                                <v-layout>
                                  <v-flex xs12>
                                    <v-select
                                      label="Tipo de lote"
                                      v-model="editingLote.tipo"
                                      :items="['Regular', 'Irregular']"
                                    ></v-select>
                                  </v-flex>

                                  <v-flex xs12>
                                    <searcher
                                      :configuration="searchModelo"
                                      :itemSelected.sync="editingLote.modelo"
                                    ></searcher>
                                  </v-flex>

                                  <v-flex xs12>
                                    <v-text-field
                                      v-model="editingLote.superficie"
                                      color="verdeFayal"
                                      label="Superficie en metros cuadrados"
                                      type="number"
                                      min="1"
                                    ></v-text-field>
                                  </v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn 
                                  dark 
                                  color="verdeFayal" 
                                  outline 
                                  flat 
                                  :loading="vBtnSave.loading"
                                  @click="updateLote"
                                >
                                  Actualizar
                                </v-btn>
                              </v-card-actions>
                          </v-flex>
                        </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn 
                    dark 
                    color="verdeFayal" 
                    outline 
                    flat 
                    :loading="vBtnSave.loading"
                    @click="storeLotes()"
                  >
                    Guardar
                  </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
          {{ vSnackBar.text }}
          <v-btn color="pink" flat @click="vSnackBar.visible = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-snackbar>
    </div>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";

  export default {
    mounted() {
    },
    data() {
      return {
        addingLote: {
          modelo: {},
          cantidad: 1
        },
        editingLote: {
          modelo: {},
          cantidad: 1
        },
        lotes: [],
        editing: false,
        searchModelo: {
          url: "/prototipos_vivienda/with_rubros",
          paginatedContainer: 'data',
          noDataText: "No se encontraron resultados.",
          label: "Modelo",
          itemText: "nombre",
          itemValue: "id",
          descriptionLimit: 60,
          disabled: false,
          useHeaders: true,
          rules: [
            v => !!v || 'Este campo es requerido',
          ]
        },
        localShow: false,
        vBtnSave: {
          loading: false
        },
        vSnackBar: {
          visible: false,
          text: null
        }
      }
    },
    props: {
      show : Boolean,
      manzana: Object 
    },
    watch: {
      show: function (value) {
        this.localShow = value
      },
      localShow: function (value) {
        this.$emit('update:show', value);
      },
      editing: function(value) {
        if(value == false)
          this.editingLote = {
            modelo: {},
            cantidad: 1
          } 
      }
    },
    methods: {
      addLotes: function() {
        if(this.addingLote.tipo && this.addingLote.modelo && this.addingLote.superficie && this.addingLote.cantidad) {
          for (let i=0; i < this.addingLote.cantidad; i++) {
            this.manzana.lotes_count++;
            this.lotes.push({
              ...this.addingLote,
              "modelo": {
                id: this.addingLote.modelo.id,
                nombre: this.addingLote.modelo.nombre
              },
              "nombre": `${this.manzana.nombre}${this.manzana.lotes_count}`,
            });
          }
        }
        else {
          alertify.error('Faltan campos por llenar');
        }
      },
      duplicateItem: function(item) {
        this.lotes.push({
          ...item,
          "nombre": `${this.manzana.nombre}${this.manzana.lotes_count++}`,
        });
      },
      deleteItem: function(index) {
        this.lotes.splice(index, 1);
        this.manzana.lotes_count--;
        this.manzana.lotes_count = this.manzana.lotes_count - this.lotes.length;
        this.lotes.forEach((item, i) => {
          this.manzana.lotes_count++;
          item.nombre = `${this.manzana.nombre}${this.manzana.lotes_count}`
        });
      },
      storeLotes: function() {
        this.vBtnSave.loading = true;
        axios.post(laravelRoute('proyectos.manzanas.lotes.store', this.manzana.lotificacion_id), {
          "lotes": this.lotes,
          "manzana_id": this.manzana.id
        })
        .then(response => {
          this.vSnackBar.text = response.data.message_text;
          this.vSnackBar.visible = true;
          this.localShow = false;
          setTimeout(function () {
              location.reload();
          }, 1500);
        })
        .catch(error => errorHandling(error))
        .finally(() => this.vBtnSave.loading = false);
      },
      editItem: function(item, index) {
        this.editing = true;
        this.editingLote = {
          ...item
        };
      },
      updateLote: function() {
        let itemIndex = this.lotes.findIndex(item => item.nombre == this.editingLote.nombre);
        console.log(itemIndex);
        this.lotes.splice(itemIndex, 1, this.editingLote);
      }
    }
  }
</script>

<style scoped>
  .conceptos-form {
    border-left: solid 1px #18b822;
  }
</style>
