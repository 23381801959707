<template>
  <v-layout row justify-center>
      <v-card>
        <v-flex xs12>
          <div class="">
            <a href="../proyectos">
              <v-btn color="#1ABA24" class="white--text">Ir a propuestas de lotificacion</v-btn>
            </a>
          </div>
        </v-flex>
        <v-card-title>
          <span class="headline">Ver Propuesta de lotificación</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap v-for="(project, index) in this.proyecto" :key="index">
              <v-flex xs12 sm6 md6>
                <v-text-field
                  color="#1ABA24"
                  label="Nombre del Proyecto"
                  v-model="project.prospecto.nombre_proyecto"
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field color="#1ABA24" label="Descripción" v-model="project.descripcion" disabled></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field color="#1ABA24" label="Fecha" type="date" v-model="project.fecha" disabled></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <a :href="project.planos" target="_blank">
                  <v-text-field color="#1ABA24" label="Ver planos" v-model="project.planos" disabled></v-text-field>
                </a>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
  </v-layout>
</template>

<script>
  export default {
    props:['proyecto'],
    data(){
      return{
        prospectosProps: this.prospecto
      }
    }  
  }
</script>
