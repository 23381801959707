var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "700px" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass: "fyl-btnCreate",
                      on: {
                        click: function($event) {
                          return _vm.$emit("open")
                        }
                      }
                    },
                    [_vm._v("Crear")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _vm._v(" "),
          _c("v-card", [
            _c(
              "form",
              {
                attrs: { action: "" },
                on: {
                  "~submit": function($event) {
                    $event.preventDefault()
                    return _vm.createObra()
                  }
                }
              },
              [
                _c("v-card-title", [
                  _c("span", { staticClass: "headline" }, [
                    _vm._v(_vm._s(this.obra.id ? "Editar" : "Crear") + " Obra")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-container",
                      { attrs: { "grid-list-md": "" } },
                      [
                        _c(
                          "v-layout",
                          { attrs: { wrap: "" } },
                          [
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", sm6: "", md6: "" } },
                              [
                                _c("v-text-field", {
                                  attrs: { label: "Etapa", color: "#1ABA24" },
                                  model: {
                                    value: _vm.obra.etapa,
                                    callback: function($$v) {
                                      _vm.$set(_vm.obra, "etapa", $$v)
                                    },
                                    expression: "obra.etapa"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", sm6: "", md6: "" } },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    color: "#1ABA24",
                                    label: "Tiempo de Realización"
                                  },
                                  model: {
                                    value: _vm.obra.tiempo_realizacion,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.obra,
                                        "tiempo_realizacion",
                                        $$v
                                      )
                                    },
                                    expression: "obra.tiempo_realizacion"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-flex",
                              { attrs: { xs12: "", sm12: "", md12: "" } },
                              [
                                _c("v-textarea", {
                                  attrs: {
                                    color: "#1ABA24",
                                    label: "Descripción",
                                    counter: "250",
                                    maxlength: _vm.max
                                  },
                                  model: {
                                    value: _vm.obra.descripcion,
                                    callback: function($$v) {
                                      _vm.$set(_vm.obra, "descripcion", $$v)
                                    },
                                    expression: "obra.descripcion"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "fyl-btnClose",
                        attrs: { flat: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("close")
                          }
                        }
                      },
                      [_vm._v("Cerrar")]
                    ),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "fyl-btnSave",
                        attrs: { type: "submit", flat: "" }
                      },
                      [_vm._v("Guardar")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }