<template>
  <v-layout>
    <v-container>
      <v-card>
        <v-flex xs12>
          <v-toolbar color="white" flat>
            <v-btn fab small flat href="/fraccionamientos">
              <v-icon>chevron_left</v-icon>
            </v-btn>
            <v-toolbar-title>Propuestas de lotificación</v-toolbar-title>
            <v-spacer></v-spacer>
            <proyectos-modal-form
              v-if="$root.can('new', permissions) ? true : false"
              v-show="$root.can('readonly', permissions) ? false : true"
              :proyectos="proyectosProps"
              :proyecto-id="proyecto.id"
              :concretados="[]"
              :readonly="$root.can('readonly', permissions) ? true : false"
              :dialog="dialog"
              @open="dialog = true"
              @close="cleanForm()"
            ></proyectos-modal-form>
          </v-toolbar>
          <v-data-table :headers="headers" disable-initial-sort :items="proyectosProps.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td>{{ props.item.nombre }}</td>
              <td>{{ props.item.lotes_regulares }}</td>
              <td>{{ props.item.lotes_irregulares }}</td>
              <td>{{ props.item.superficie.toFixed(2) }}</td>
              <td>{{ props.item.estado.information.estado }}</td>
              <td>
                <v-layout>
                  <v-tooltip bottom>
                    <v-btn :disabled="!$root.can('update', permissions) || (props.item.estado.information.estado == 'Descartado') && true" flat icon slot="activator" color="verdeFayal" @click="openStatusModal(props.item.id)">
                      <v-icon>low_priority</v-icon>
                    </v-btn>
                    <span>Estados</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      :disabled="!$root.can('update', permissions) || (props.item.estado.information.estado == 'Descartado') && true"
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="info"
                      @click="editproyecto(props.item.id)"
                    >
                      <v-icon color="#1ABA24">edit</v-icon>
                    </v-btn>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      flat
                      icon
                      slot="activator"
                      color="warning"
                      @click="elementId = props.item.id; dialogFile = true;"
                    >
                      <v-icon>folder</v-icon>
                    </v-btn>
                    <span>Archivos</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn
                      :disabled="!$root.can('remove', permissions) || (props.item.estado.information.estado == 'Seleccionado') && true"
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="error"
                      @click="sweetAlert(props.item)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <span>Eliminar</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="proyectosProps" @pagination-change-page="getPropuestasDeLotificacion"></pagination>
          </v-card-actions>
        </v-flex>
      </v-card>

      <!-- Modals -->
      <upload-files-modal
        @close="dialogFile = false"
        element_type="App\Lotificacion"
        :element_id="elementId"
        :dialog="dialogFile"
      ></upload-files-modal>
      
      <manage-estados-modal-component 
        :show.sync="showStatusModal"
        :model-id.sync="currentLotificationId"
        model-type="App\Lotificacion"
      ></manage-estados-modal-component>
    </v-container>
  </v-layout>
</template>

<script>
import { errorHandling, laravelRoute } from "../../../lib/utilities";
import axios from "axios";
import swal from "sweetalert";

export default {
  props: ["propuestaId", "permissions"],
  data() {
    return {
      dialogFile: false,
      elementId: "",
      search: "",
      dialog: false,
      vPaginator: {
        limit: 10
      },
      editPL: false,
      search: null,
      showStatusModal: false,
      currentLotificationId: null,
      proyecto: {
        manzanas: [],
        areas_verdes: [],
        calles: [],
        lotes_regulares: 0,
        lotes_irregulares: 0,
        superficie: 0
      },
      proyectosProps: {
        data: []
      },
      headers: [
        { text: "Nombre", value: "nombre" },
        { text: "Regulares", value: "lotes_regulares" },
        { text: "Irregulares", value: "lotes_irregulares" },
        { text: "Superficie", value: "superficie" },
        { text: "Status", value: "estado" },
        { text: "Acciones", value: "", sortable: false }
      ],
      campoActualizado: { id: "", name: "" },
      pagination: {
        sortBy: "created_at",
        descending: true
      }
    };
  },
  watch: {
    showStatusModal: function() {
      this.getPropuestasDeLotificacion();
    }
  },
  methods: {
    openStatusModal: function(lotificacionId) {
      this.showStatusModal = true;
      this.currentLotificationId = lotificacionId;
    },
    cleanForm() {
      this.proyecto = {
        manzanas: [],
        areas_verdes: [],
        calles: [],
        lotes_regulares: 0,
        lotes_irregulares: 0,
        superficie: 0
      };
      this.dialog = false;
    },
    sweetAlert: function(proyecto) {
      swal({
        title: "¡Advertencia!",
        title: "¿estás seguro?",
        text: "Esta propuesta de lotificación se eliminará.",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
          // this.removingRole = true;
          this.eliminar(proyecto.id);
          this.getPropuestasDeLotificacion();
          swal("El Registro ha sido eliminado", {
            icon: "success"
          });
        } else {
          swal("El Registro no se eliminó");
        }
      });
    },
    eliminar: proyectoId => {
      var IDproyecto = proyectoId;
      axios
        .delete(route("proyectos.destroy", IDproyecto))
        .then(response => {
          if (response.data.message_text) {
            alertify.success(response.data.message_text);
          }
        })
        .catch(error => errorHandling(error));
      // .finally(() => this.removingRole = false)
    },
    aceptar: proyecto => {
      var IDproyecto = proyecto.id;
      axios
        .post(laravelRoute("proyectos.vistobueno.gerencia"), { id: IDproyecto })
        .then(response => {
          if (response.data.message_text) {
            alertify.success(response.data.message_text);
          }
        })
        .catch(error => {
          errorHandling(error);
          //proyectosProps.item.aprobado_planeacion - es el v-model del checkbox ha que setearlo a false
          this.proyectosProps[
            this.proyectosProps.indexOF(proyecto)
          ].aprobado_gerencia = false;
        });
    },
    acceptarplaneacion: proyecto => {
      var IDproyecto = proyecto.id;
      axios
        .post(laravelRoute("proyectos.vistobueno.planeacion"), {
          id: IDproyecto
        })
        .then(response => {
          if (response.data.message_text) {
            alertify.success(response.data.message_text);
          }
        })
        .catch(error => {
          errorHandling(error);
          this.proyectosProps[
            this.proyectosProps.indexOF(proyecto)
          ].aprobado_planeacion = false;
        });
    },
    editproyecto: function(proyectoId) {
      this.dialog = true;
      this.proyecto.id = proyectoId;
    },
    acceptProyecto: function(proyecto) {
      swal({
        title:
          "¿Esta seguro que quiere dar visto bueno a esta propuesta de lotificación?",
        text:
          "Tenga en cuenta que, por el momento, esta acción será irrevocable",
        icon: "warning",
        buttons: true
      }).then(response => {
        if (response) {
          this.aceptar(proyecto);
          swal("La propuesta se aprobó satisfactoriamente", {
            icon: "success"
          }).then(response => {
            setTimeout(() => {
              window.location.href = "/proyectos";
            });
          });
        } else {
          swal("La propuesta no se aprobó");
        }
      });
    },
    vistobuenoPlaneacion: function(proyecto) {
      swal({
        title:
          "¿Está seguro que quiere dar visto bueno a esta propuesta de lotificación?",
        text:
          "Tenga en cuenta que, por el momento, esta acción será irrevocable",
        icon: "warning",
        buttons: true
      }).then(response => {
        if (response) {
          this.acceptarplaneacion(proyecto);
          swal("La propuesta se aprobó satisfactoriamente", {
            icon: "success"
          }).then(response => {
            setTimeout(() => {
              window.location.href = "/proyectos";
            });
          });
        } else {
          swal("La propuesta no se aprobó");
        }
      });
    },
    getPropuestasDeLotificacion: function(page = 1) {
      axios.get(`/proyectos/lotificaciones/${this.propuestaId}?page=${page}&query=${this.search}`)
      .then(response => {
        this.proyectosProps = response.data
      })
      .catch(error => errorHandling(error));
    }
  },
  created() {
    this.getPropuestasDeLotificacion();
  }
};
</script>