var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Prospectos")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      (_vm.$root.can("new", _vm.permissions)
                      ? true
                      : false)
                        ? _c("prospectos-create-modal", {
                            attrs: {
                              prospectos: _vm.prospectos.data,
                              prospecto: _vm.prospecto,
                              dialog: _vm.dialog
                            },
                            on: {
                              open: function($event) {
                                _vm.dialog = true
                              },
                              close: function($event) {
                                return _vm.cleanForm()
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.vHeaders,
                      "disable-initial-sort": "",
                      items: _vm.prospectos.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", [
                              _vm._v(_vm._s(props.item.nombre_proyecto))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(_vm._s(props.item.direccion))
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.fecha))]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.descripcion))]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              flat: "",
                                              icon: "",
                                              color: "warning"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.elementId = props.item.id
                                                _vm.dialogFile = true
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("folder")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Archivos")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: !_vm.$root.can(
                                                "update",
                                                _vm.permissions
                                              ),
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "info"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.editprospecto(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("edit")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Editar")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            slot: "activator",
                                            flat: "",
                                            disabled: !_vm.$root.can(
                                              "accept",
                                              _vm.permissions
                                            )
                                              ? true
                                              : props.item.aprobado
                                              ? true
                                              : false,
                                            color: "indigo"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.sweetAlertAprovadoGerencia(
                                                props.item
                                              )
                                            }
                                          },
                                          slot: "activator",
                                          model: {
                                            value: props.item.aprobado,
                                            callback: function($$v) {
                                              _vm.$set(
                                                props.item,
                                                "aprobado",
                                                $$v
                                              )
                                            },
                                            expression: "props.item.aprobado"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              props.item.aprobado
                                                ? "Prospecto aprobado - gerencia"
                                                : "Aprobar Prospecto - gerencia"
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      {
                                        staticClass: "fyl-check-prospectos2",
                                        attrs: { bottom: "" }
                                      },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            slot: "activator",
                                            disabled: !_vm.$root.can(
                                              "buy",
                                              _vm.permissions
                                            )
                                              ? true
                                              : props.item.comprado
                                              ? true
                                              : false,
                                            color: "orange darken-3"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.sweetAlertAprovadoPlaneacion(
                                                props.item
                                              )
                                            }
                                          },
                                          slot: "activator",
                                          model: {
                                            value: props.item.comprado,
                                            callback: function($$v) {
                                              _vm.$set(
                                                props.item,
                                                "comprado",
                                                $$v
                                              )
                                            },
                                            expression: "props.item.comprado"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              props.item.comprado
                                                ? "Prospecto aprobado - planeación"
                                                : "Aprobar Prospecto - planeación"
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: !_vm.$root.can(
                                                "remove",
                                                _vm.permissions
                                              )
                                                ? true
                                                : props.item.comprado
                                                ? true
                                                : false,
                                              color: props.item.comprado
                                                ? ""
                                                : (_vm.color = "error"),
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sweetAlert(
                                                  props.item
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("delete")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              props.item.comprado
                                                ? "No se puede eliminar"
                                                : "Eliminar"
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.prospectos
                        },
                        on: { "pagination-change-page": _vm.getProspectos }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("upload-files-modal", {
        attrs: {
          element_type: "App\\Prospecto",
          element_id: _vm.elementId,
          dialog: _vm.dialogFile
        },
        on: {
          close: function($event) {
            _vm.dialogFile = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }