<template>
  <v-container grid-list-md id="container-supplier">
    <v-layout row wrap>
      <v-flex :xs12="!showFiltersModal" :xs9="showFiltersModal">
        <v-card>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Trámites en dependencias</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search">
            </v-text-field>
            <v-btn class="fyl-btnCreate" @click="openCreateModal" v-if="$root.can('new', permissions) ? true : false">
              Crear</v-btn>

            <v-btn dark color="verdeFayal" outline @click="showFiltersModal = !showFiltersModal">
              <v-icon dark>filter_alt</v-icon>
            </v-btn>
            <v-btn dark color="verdeFayal" outline @click="exportToExcel">
              <v-icon dark>import_export</v-icon>
            </v-btn>
          </v-toolbar>
          <v-data-table :headers="headers" disable-initial-sort :items="tramites.data" hide-actions class="elevation-1">
            <template slot="items" slot-scope="props">
              <td>{{ props.item.nombre_tramite }}</td>
              <td>{{ props.item.descripcion }}</td>
              <td>{{ props.item.fraccionamiento.nombre_proyecto }}</td>
              <td>{{ props.item.fecha_resolucion }}</td>
              <td>{{ props.item.estado ? props.item.estado.information.estado : 'No disponible' }}</td>
              <td>
                <v-layout>
                  <v-tooltip bottom>
                    <v-btn :disabled="!$root.can('update', permissions)" icon flat dark x-small slot="activator"
                      color="info" @click="editTramite(props.item.id)">
                      <v-icon x-small color="#1ABA24">edit</v-icon>
                    </v-btn>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn :disabled="!$root.can('files', permissions)" flat icon slot="activator" color="warning"
                      @click="OpenFilesModal(props.item.id)">
                      <v-icon x-small>folder</v-icon>
                    </v-btn>
                    <span>Archivos</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn :disabled="!$root.can('states', permissions)" icon flat slot="activator" color="verdeFayal"
                      @click="openStatusModal(props.item.id)">
                      <v-icon x-small>low_priority</v-icon>
                    </v-btn>
                    <span>Ver estados</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn :disabled="!$root.can('comments', permissions)" icon flat dark x-small slot="activator"
                      color="success" @click="openComment(props.item.id)" style="">
                      <v-icon x-small>chat</v-icon>
                    </v-btn>
                    <span>Hacer comentarios</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn :disabled="!$root.can('remove', permissions)" icon flat dark x-small slot="activator"
                      color="error" @click="deleteTramite(props.item.id)">
                      <v-icon x-small>delete</v-icon>
                    </v-btn>
                    <span>Eliminar</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="tramites" @pagination-change-page="getTramites"></pagination>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs3 v-if="showFiltersModal">
        <v-card>
          <v-toolbar color="white" flat>
            <v-toolbar-title>Filtros</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="showFiltersModal = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex>
                  <v-select color="verdeFayal" :items="proyectoItems" item-value="id" item-text="nombre_proyecto"
                    label="Proyecto al que pertenece" v-model="filters.filterProyecto" clearable></v-select>
                </v-flex>
                <v-flex>
                  <v-select color="verdeFayal" :items="estadosTramite" item-value="id" item-text="estado" label="Estado"
                    v-model="filters.filterEstado" clearable></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn dark color="verdeFayal" small flat outline @click="limpiar">Limpiar</v-btn>
            <v-spacer></v-spacer>
            <v-btn dark color="verdeFayal" small @click="filterTramites">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- Modal -->
    <upload-files-modal @close="dialogFile = false" element_type="App\Tramite" :element_id="currentElementId"
      :dialog="dialogFile"></upload-files-modal>
    <manage-estados-modal-component :show.sync="showStatusModal" :model-id.sync="currentTramiteId"
      model-type="App\Tramite"></manage-estados-modal-component>
    <tramites-dependencias-form :show.sync="showCreateTramiteModal" :dependencia="dependencia">
    </tramites-dependencias-form>
    <manage-comments-modal :show.sync="showComentariosModal" modelable-type="App\Tramite"
      :modelable-id="currentTramiteId" />
    <edit-tramites-dependencias-modal :show.sync="showEditTramiteModal" :tramite-id.sync="currentTramiteId">
    </edit-tramites-dependencias-modal>
  </v-container>
</template>

<script>
  export default {
    props: ['concretados', 'proyectos', 'comentarios', 'permissions', 'estados', 'fraccionamientoId','dependencia'],
    data(){
      return{
        tramites: {
          data: []
        },
        dialogFile: false,
        dialogStatus: false,
        elementId: '',
        search: '',
        dialog: false,
        dialogo: false,
        tramite: {},
        comentario: {},
        estado: {},
        dad_id: null,
        showStatusModal: false,
        comentariosProps: this.comentarios,
        concretadosProps: this.concretados,
        proyectosProps: this.proyectos,
        currentElementId: null,
        showComentariosModal: false,
        currentTramiteId: null,
        showCreateTramiteModal: false,
        showEditTramiteModal: false,
        headers: [
          { text: 'Nombre del tramite', value: 'nombre_tramite' },
          { text: 'Descripcion', value: 'descripcion' },
          { text: 'Proyecto al que pertenece', value: 'proyecto_perteneciente' },
          { text: 'Fecha aprox. de resolución', value: 'fecha_resolucion' },
          { text: 'Estado', value: 'estado' },
          { text: 'Acciones', sortable: false }
        ],
        vPaginator: {
          limit: 10
        },

        showFiltersModal: false,       
        filters: {
          filterProyecto: null,
          filterEstado: null,          
        },
        estadosTramite: [],
        proyectoItems: [],

      }
    },
    watch: {
      search: function (value) {
        this.getTramites();
      },
      showStatusModal: function() {
        this.getTramites();
      },
      showCreateTramiteModal: function() {
        this.getTramites();
      }
    },
    methods:{
      getTramites: function(page = 1) {
        axios.get(`/tramites_dependencias?page=${page}&query=${this.search}&fraccionamiento_id=${this.fraccionamientoId}`)
        .then(response => this.tramites = response.data)
        .catch(error => errorHandling(error));
      },
      openCreateModal: function() {
        this.showCreateTramiteModal = true;
      },
      cleanForm:function() {
        this.tramite = {};
        this.dialog = false;
      },
      openStatusModal: function(currentTramiteId) {
        this.currentTramiteId = currentTramiteId;
        this.showStatusModal = true;
      },
      cleanComment: function() {
        this.comentario = {};
        this.dialogo = false;
      },
      deleteTramite: function(tramiteId) {
        swal({
          title: "¿estás seguro?",
          text: "Una vez eliminado no se podrá recuperar",
          icon: "warning",
          buttons: true,
          dangerMode: true
        }).then(result => {
          if (result) {
            axios.delete(laravelRoute('tramites_dependencias.destroy', tramiteId))
            .then(response => {
              this.getTramites();
              alertify.success(response.data.message_text);
            })
            .catch(error => errorHandling(error));
          }
        })
      },
      editTramite: function(tramiteId) {
        this.showEditTramiteModal = true;
        this.currentTramiteId = tramiteId;
      },

      openComment: function(tramiteId) {
        this.currentTramiteId = tramiteId;
        this.showComentariosModal = true;
      },

      openState: function(tramite) {
        console.log(tramite.id);
        this.comentario = {
          tramite_id: tramite.id,
          estado: ''
        };
        this.dialogStatus = true;
        this.dad_id= tramite.id;
        this.currentElementId = tramite.id
      },
      OpenFilesModal: function (tramiteId) {
        this.dialogFile = true;
        this.currentElementId = tramiteId;
      },

      getEstados: function(){
        axios
        .get('/tramites_dependencias/estados')
        .then(response => {
          this.estadosTramite = response.data;
        })
        .catch(error => errorHandling(error));
      },
      getFraccionamientos: function(){
        axios.post('/tramites_dependencias/getFraccionamientos').then(response => {
          this.proyectoItems = response.data;
        }).catch(error => errorHandling(error));
      },     
      filterTramites: function() {
        axios.post('/tramites_dependencias/filterTramites',this.filters)
        .then(response => this.tramites = response.data)
        .catch(error => errorHandling(error));
      },
      limpiar: function () {
          this.filters.filterProyecto = null;
          this.filters.filterEstado = null;
          this.getTramites();
      },
      exportToExcel: function() {
      axios.post('/tramites_dependencias/filterTramites', {
        ...this.filters,
        to_excel: true
      }, {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'tramites.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
      },
      
    },
    created() {      
      this.getFraccionamientos();
      this.getEstados();
      this.getTramites();      
    }
  }
</script>