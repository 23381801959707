<template>
  <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn flat icon :href="`/etapas/${etapa.id}/edit`">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>

        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-toolbar card prominent>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="body-2 grey--text">Agregar conceptos y rubros a {{ distribucion.nombre }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout row wrap>
                                <v-flex md12>
                                    <v-data-table
                                        :items="localData"
                                        :expand="expand"
                                        item-key="id"
                                        hide-actions
                                    >
                                        <template v-slot:headers="props">
                                            <tr>
                                                <td></td>
                                                <td>Nombre</td>
                                                <td>Cantidad</td>
                                                <td>Unidad</td>
                                                <td></td>
                                            </tr>
                                        </template>
                                        <template v-slot:items="props">
                                            <tr>
                                                <td>
                                                    <v-checkbox
                                                        v-model="props.item.selected"
                                                        @change="selectedConcepto(props.index)"
                                                    ></v-checkbox>
                                                </td>
                                                <td>{{ props.item.nombre }}</td>
                                                <td>
                                                    <v-text-field
                                                        v-model="props.item.cantidad"
                                                        min="1"
                                                        type="number"
                                                        :disabled="props.item.selected == false"
                                                    ></v-text-field>
                                                </td>
                                                <td> {{ props.item.unidad }} </td>
                                                <td class="border-expanded-td">
                                                    <v-btn flat icon @click="props.expanded = !props.expanded">
                                                        <v-icon>expand_more</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </template>
                                        <template v-slot:expand="props">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Nombre del rubro</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(rubro, index) in props.item.rubros" :key="index">
                                                        <td></td>
                                                        <td>
                                                            <v-checkbox
                                                                v-model="rubro.selected"
                                                                :disabled="!props.item.selected"
                                                            ></v-checkbox>
                                                        </td>
                                                        <td>{{ rubro.nombre}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </template>
                                    </v-data-table>
                                </v-flex>
                            </v-layout>
                            <v-divider></v-divider>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            fab 
                            small 
                            outline 
                            color="verdeFayal" 
                            @click="getConceptos" 
                            :loading="isInfiniteScrollLoading"
                            v-if="currentConceptosPage < conceptos.last_page"
                        >
                            <v-icon>expand_more</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn
                            dark
                            flat
                            outline
                            color="verdeFayal"
                            @click="storeConceptos"
                            :loading="vBtnSave.loading"
                        >
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-card>
</template>

<style>
</style>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    data() {
        return {
            conceptos: {
                last_page: 0
            },
            localData: [],
            conceptosSelected: [],
            scrolledToBottom: false,
            isInfiniteScrollLoading: false,
            currentConceptosPage: 0,
            selectAllConceptos: true,
            headers: [
                { text: '', value: 'checkbox', sortable: false },
                { text: 'Concepto', value: 'nombre', sortable: false },
                { text: 'Cantidad', value: 'cantidad', sortable: false },
                { text: '', value: 'actions', sortable: false },
            ],
            expand: false,
            vBtnSave: {
                loading: false
            },
            vSnackBar: {
                visible: false,
                text: null
            },
        }
    },
    props: {
        distribucion: Object,
        etapa: Object,
        tipo: String
    },
    methods: {
        getConceptos: function() {
            this.isInfiniteScrollLoading = true
            this.currentConceptosPage++;
            axios.get(`/conceptos/index_refactor?page=${this.currentConceptosPage}&tipo=urbanizacion`)
            .then(response => {
                this.conceptos = response.data;
                if(response.data.data.length) {
                    this.localData = this.localData.concat(response.data.data);
                    this.localData.map(item => {
                        if(this.distribucion.conceptos.length) {
                            if( !this.distribucion.conceptos.some(concepto => concepto.id == item.id)) {
                                item.selected = false;
                            }
                            else {
                                item.cantidad = this.distribucion.conceptos.find(concepto => concepto.id == item.id).pivot.cantidad
                                item.selected = true;
                            }
                        }
                        
                        item.rubros.map(rubro => {
                            if(this.distribucion.conceptos.length) {
                                rubro.selected = (this.distribucion.rubros.some(i => i.id == rubro.id) ? true : false)
                            }
                        });
                    });
                }
            })
            .catch(error => {
              errorHandling(error);
            })
            .finally(() => this.isInfiniteScrollLoading = false);
        },
        storeConceptos: function() {
            this.vBtnSave.loading = true;
            axios.post(laravelRoute('etapas.store.distribucion.conceptos', this.etapa.id), {
                conceptos: this.localData.filter(item => item.selected == true),
                distribucion_id: this.distribucion.id,
                tipo: this.tipo,
                etapa_id: this.etapa.id,
                rubros: this.localData.filter(item => item.selected == true)
                    .map(concepto => concepto.rubros.filter(i => i.selected == true)).flat(),
            })
            .then(response => {
                this.vSnackBar.text = response.data.message_text;
                this.vSnackBar.visible = true;
            })
            .catch(error => {
              errorHandling(error);
            })
            .finally(() => this.vBtnSave.loading = false);
        },
        selectedConcepto: function(index) {
            let oldData = this.localData[index];
            let localRubros = this.localData[index].rubros.map((item) => item.selected = this.localData[index].selected)
            this.localData.splice(index, 1, {
                ...oldData,
                lotes: localRubros
            });
        },
        selectedAllRubros: function(indexConcepto) {
            this.localData[indexConcepto].rubros.map((item) => item.selected = this.localData[indexConcepto].rubrosSelected);
        },
        selectedAllConceptos: function() {
            if(this.selectAllConceptos == true) {
                this.localData.map(item => item.selected = true);
            }
            else {
                this.localData.map(item => item.selected = false);
            }
        }
    },
    created() {
        this.getConceptos();
    }
  }
</script>

<style>
    .border-expanded-td {
        border-left: 0.3px solid #e3e3e3;
    }
</style>