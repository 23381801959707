var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            attrs: {
                              flat: "",
                              icon: "",
                              href: "/etapas/" + _vm.etapa.id + "/edit"
                            }
                          },
                          on
                        ),
                        [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_vm._v(" "), _c("span", [_vm._v("Regresar")])]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "pb-2": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs10: "", "offset-xs1": "" } },
            [
              _c(
                "v-card",
                { staticClass: "card--flex-toolbar" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", prominent: "" } },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-title",
                        { staticClass: "body-2 grey--text" },
                        [
                          _vm._v(
                            "Administrar lotes de la " +
                              _vm._s(_vm.manzanaEtapa.nombre)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            [
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    md5: _vm.isVisibleConceptosForm
                                      ? true
                                      : false,
                                    md12: !_vm.isVisibleConceptosForm
                                      ? true
                                      : false
                                  }
                                },
                                [
                                  _c("v-autocomplete", {
                                    attrs: {
                                      hint: "Buscar lote a trabajar",
                                      "prepend-inner-icon": "search",
                                      "search-input": _vm.query,
                                      items: _vm.searchLotes.data,
                                      loading: _vm.searchLoading,
                                      "item-text": "nombre",
                                      "persistent-hint": "",
                                      "item-value": "id",
                                      label: "Buscar",
                                      "return-object": "",
                                      "hide-selected": "",
                                      "hide-no-data": ""
                                    },
                                    on: {
                                      "update:searchInput": function($event) {
                                        _vm.query = $event
                                      },
                                      "update:search-input": function($event) {
                                        _vm.query = $event
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.addLote($event)
                                      }
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "append-outer",
                                        fn: function() {
                                          return [
                                            _c(
                                              "v-slide-x-reverse-transition",
                                              { attrs: { mode: "out-in" } },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "success",
                                                      disabled: !_vm.selected
                                                    },
                                                    on: { click: _vm.addLote }
                                                  },
                                                  [_vm._v("add")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ]),
                                    model: {
                                      value: _vm.selected,
                                      callback: function($$v) {
                                        _vm.selected = $$v
                                      },
                                      expression: "selected"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "table",
                                    { staticClass: "table table-hover" },
                                    [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { attrs: { scope: "col" } },
                                            [_vm._v("Lote")]
                                          ),
                                          _vm._v(" "),
                                          _c("th", [_vm._v("Acciones")])
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        _vm._l(_vm.lotes.data, function(
                                          lote,
                                          index
                                        ) {
                                          return _c(
                                            "tr",
                                            {
                                              key: index,
                                              class:
                                                lote.id == _vm.currentLote.id
                                                  ? "table-active"
                                                  : ""
                                            },
                                            [
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    lote.lote_lotificacion
                                                      .nombre
                                                  )
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "td",
                                                [
                                                  _c(
                                                    "v-layout",
                                                    [
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                            icon:
                                                                              "",
                                                                            color:
                                                                              "verdeFayal"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.openConceptosForm(
                                                                                lote
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "edit"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "Administrar conceptos y rubros"
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: { bottom: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var on =
                                                                    ref.on
                                                                  return [
                                                                    _c(
                                                                      "v-btn",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                            icon:
                                                                              "",
                                                                            color:
                                                                              "red"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteLoteEtapa(
                                                                                lote.id
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          "v-icon",
                                                                          [
                                                                            _vm._v(
                                                                              "delete"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _vm._v(" "),
                                                          _c("span", [
                                                            _vm._v(
                                                              "Eliminar lote"
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isVisibleConceptosForm
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass: "conceptos-form",
                                      attrs: { md7: "" }
                                    },
                                    [
                                      _c(
                                        "v-toolbar",
                                        { attrs: { flat: "", color: "white" } },
                                        [
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "body-2 grey--text"
                                              },
                                              [_vm._v("Conceptos y rubros")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "grey--text" },
                                              [
                                                _vm._v(
                                                  "Selecciona los conceptos y rubros correspondientes al lote " +
                                                    _vm._s(
                                                      _vm.currentLote
                                                        .lote_lotificacion
                                                        .nombre
                                                    )
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                flat: "",
                                                icon: "",
                                                color: "verdeFayal",
                                                dark: ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.isVisibleConceptosForm = false
                                                }
                                              }
                                            },
                                            [_c("v-icon", [_vm._v("close")])],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-card-text",
                                        [
                                          _vm.vLoadingConceptos
                                            ? _c("v-progress-linear", {
                                                attrs: {
                                                  color: "verdeFayal",
                                                  indeterminate: true
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("v-data-table", {
                                            attrs: {
                                              items: _vm.conceptosGet,
                                              expand: _vm.expand,
                                              "item-key": "id",
                                              "hide-actions": ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "headers",
                                                  fn: function(props) {
                                                    return [
                                                      _c("tr", [
                                                        _c("th"),
                                                        _vm._v(" "),
                                                        _c("th", [
                                                          _vm._v(
                                                            "Nombre del concepto"
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("th")
                                                      ])
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "items",
                                                  fn: function(props) {
                                                    return [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          [
                                                            _c("v-checkbox", {
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.selectedConcepto(
                                                                    props.index
                                                                  )
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  props.item
                                                                    .selected,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    props.item,
                                                                    "selected",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "props.item.selected"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _vm._v(
                                                            _vm._s(
                                                              props.item
                                                                .concepto.nombre
                                                            )
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticClass:
                                                              "border-expanded-td"
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  flat: "",
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    props.expanded = !props.expanded
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "expand_more"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ])
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "expand",
                                                  fn: function(props) {
                                                    return [
                                                      _c(
                                                        "v-card",
                                                        { attrs: { flat: "" } },
                                                        [
                                                          _c("v-data-table", {
                                                            attrs: {
                                                              items:
                                                                props.item
                                                                  .rubros,
                                                              "hide-actions": ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "headers",
                                                                  fn: function(
                                                                    propsRubro
                                                                  ) {
                                                                    return [
                                                                      _c("tr", [
                                                                        _c(
                                                                          "th"
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "th",
                                                                          {
                                                                            attrs: {
                                                                              colspan:
                                                                                "2"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Nombre del rubro"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ])
                                                                    ]
                                                                  }
                                                                },
                                                                {
                                                                  key: "items",
                                                                  fn: function(
                                                                    propsRubro
                                                                  ) {
                                                                    return [
                                                                      _c("tr", [
                                                                        _c(
                                                                          "td"
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _c(
                                                                              "v-checkbox",
                                                                              {
                                                                                model: {
                                                                                  value:
                                                                                    propsRubro
                                                                                      .item
                                                                                      .selected,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      propsRubro.item,
                                                                                      "selected",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "propsRubro.item.selected"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "td",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                propsRubro
                                                                                  .item
                                                                                  .nombre
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ])
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              2906726089
                                            )
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _vm.isVisibleConceptosForm
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        outline: "",
                                                        dark: "",
                                                        color: "verdeFayal",
                                                        flat: ""
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.storeConceptosLotes
                                                      }
                                                    },
                                                    [_vm._v("Guardar")]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: { limit: 10, data: _vm.lotes },
                        on: { "pagination-change-page": _vm.getLotesToManzana }
                      }),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n          " + _vm._s(_vm.vSnackBar.text) + "\n          "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }