<template>
  <v-container>
    <v-card >
      <v-card-text>Si Funciona</v-card-text>
    </v-card>
  </v-container>
</template>
<script>
export default {
  
}
</script>
<style>

</style>
