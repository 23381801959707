<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Nuevo trabajador</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
            <v-layout wrap>
              <v-flex md12>
                  <v-subheader>Datos personales</v-subheader>
              </v-flex>
              <v-flex md4>
                  <v-text-field v-model="trabajador.nombre" required label="Nombre"></v-text-field>
              </v-flex>
              <v-flex md4>
                  <v-text-field v-model="trabajador.apellido_paterno" required label="Apellido paterno"></v-text-field>
              </v-flex>
              <v-flex md4>
                  <v-text-field v-model="trabajador.apellido_materno" required label="Apellido materno"></v-text-field>
              </v-flex>
              <v-flex md6>
                    <v-text-field type="date" v-model="trabajador.fecha_de_nacimiento" label="Fecha de nacimiento"></v-text-field>
              </v-flex>
              <v-flex md6>
                  <v-select v-model="trabajador.estado_civil" :items="['Soltero/a', 'Casado/a', 'Viudo/a', 'Divorciado/a']" label="Estado civil"></v-select>
              </v-flex>
              <v-flex md6>
                  <v-select v-model="trabajador.grado_de_estudios" :items="['Primaria', 'Secundaria', 'Preparatoria', 'Universidad', 'Posgrado']" label="Grado de estudios"></v-select>
              </v-flex>
              <v-flex md6>
                  <v-text-field v-model="trabajador.curp" minlength="18" maxlength="18" :counter="18" required label="CURP"></v-text-field>
              </v-flex>
          </v-layout>
          <v-layout wrap>
              <v-flex md12>
                  <v-subheader>Datos de contacto</v-subheader>
              </v-flex>
              <v-flex md6>
                <v-text-field mask="(###)-###-##-##" v-model="trabajador.telefono_fijo" label="Teléfono fijo"></v-text-field>
              </v-flex>
              <v-flex md6>
                <v-text-field mask="(###)-###-##-##" v-model="trabajador.telefono_celular" label="Teléfono celular"></v-text-field>
              </v-flex>
              <v-flex md6>
                <v-text-field type="email" v-model="trabajador.email" label="Email"></v-text-field>
              </v-flex>
          </v-layout>
          <v-layout wrap>
              <v-flex md12>
                  <v-subheader>Dirección</v-subheader>
              </v-flex>
              <v-flex md4>
                  <v-text-field v-model="trabajador.calle" label="Calle"></v-text-field>
              </v-flex>
              <v-flex md4>
                  <v-text-field v-model="trabajador.numero" label="Número"></v-text-field>
              </v-flex>
              <v-flex md4>
                  <v-text-field v-model="trabajador.colonia" label="Colonia"></v-text-field>
              </v-flex>
              <v-flex md4>
                  <v-text-field v-model="trabajador.municipio" label="Municipio"></v-text-field>
              </v-flex>
              <v-flex md4>
                  <v-text-field v-model="trabajador.estado" label="Estado"></v-text-field>
              </v-flex>
              <v-flex md4>
                <v-text-field v-model="trabajador.codigo_postal" minlegth="5" maxlength="5" :counter="5" label="Código postal"></v-text-field>
              </v-flex>
          </v-layout>
          <v-layout wrap>
              <v-flex md12>
                  <v-subheader>Datos de trabjador </v-subheader>
              </v-flex>
              <v-flex md6>
                  <v-text-field v-model="trabajador.rfc" minlegth="12" maxlength="13" :counter="13" label="RFC"></v-text-field>
              </v-flex>
              <v-flex md6>
                  <v-text-field v-model="trabajador.nss" minlegth="11" maxlength="11" :counter="11" label="Número de seguro social (NSS)"></v-text-field>
              </v-flex>
              <v-flex md6>
                <v-select v-model="trabajador.puesto" :items="['Destajo', 'Raya']" label="Puesto"></v-select>
              </v-flex>
              <v-flex md6 v-if="trabajador.puesto == 'Destajo'">
                <v-select v-model="trabajador.tipo" :items="['Sindicalizados', 'De confianza']" label="Tipo"></v-select>
              </v-flex>
              <v-flex md6>
                  <v-select 
                    v-model="trabajador.area_id" 
                    :items="areas" 
                    label="Área de trabajo"
                    item-text="nombre"
                    item-value="id"
                  />
              </v-flex>
              <v-flex md6 v-if="trabajador.puesto == 'Raya'">
                  <v-text-field type="number" v-model="trabajador.salario" label="Salario"></v-text-field>
              </v-flex>
              <v-flex md6>
                  <v-autocomplete
                    hint="Ocupación"
                    :search-input.sync="query"
                    :items="ocupaciones.data"
                    :loading="isLoading"
                    item-text="nombre"
                    clearable
                    color="verdeFayal"
                    v-model="trabajador.ocupacion"
                    persistent-hint
                    item-value="id"
                    label="Ocupación"
                    
                    hide-selected
                    hide-no-data
                    
                >
                  <template v-slot:no-data>
                    <v-list-tile>
                      <v-list-tile-title>
                        Busca la 
                        <strong>Ocupación</strong>
                        del trabajador
                      </v-list-tile-title>
                    </v-list-tile>
                  </template>
                </v-autocomplete>
                  <!-- <v-autocomplete v-model="trabajador.ocupacion" :items="ocupaciones" item-text="nombre" item-value="id" label="Ocupación"></v-autocomplete> -->
                  <!-- <v-text-field v-model="trabajador.ocupacion" label="Ocupación"></v-text-field> -->
              </v-flex>
          </v-layout>
          <v-layout wrap>
              <v-flex md12>
                  <v-subheader>Contrato</v-subheader>
              </v-flex>
              <v-flex md12>
                  <v-select 
                    v-model="contratoTrabajador.contrato_id" :items="tiposDeContratos" 
                    item-text="periodo"
                    item-value="id"
                    label="Tipo de contrato" />
              </v-flex>
              <v-flex md6>
                  <v-text-field 
                    type="date"
                    v-model="contratoTrabajador.fecha_inicio"
                    label="Fecha Inicio" />
              </v-flex>
              <v-flex md6>
                  <v-text-field 
                  type="date"
                  v-model="contratoTrabajador.fecha_fin"
                  label="Fecha fin (opcional)" />
              </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-switch color="verdeFayal" label="Seguir registrando" v-model="keepStoring"></v-switch>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="storeMaterial" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    isLoading: false,
    query: null,
    categories: [],
    trabajador: {
      nombre: null,
      apellido_paterno: null,
      apellido_materno: null,
      calle: null,
      numero: null,
      colonia: null,
      municipio: null,
      estado: null,
      codigo_postal: null,
      telefono_fijo: null,
      telefono_celular: null,
      tipo: null,
      salario: null,
      activo: false,
      area_id: null,
      ocupacion: null,
    },
    tiposDeContratos: [],
    contratoTrabajador: {
      contrato_id: null,
      fecha_inicio: null,
      fecha_fin: null
    },
    areas: [],
    keepStoring: true,
    localShow: false,
    datePickers: {
      fechaNacimiento: false,
      fechaContratacion: false
    },
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    },
    ocupaciones: []
  }),
  props: {
    show: Boolean
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    query: function (value){
      this.getOcupaciones();
    }
  },
  methods: {
    validarFechaContrato(){
      if(this.contratoTrabajador.contrato_id != 2) {
        if(this.contratoTrabajador.fecha_inicio < this.contratoTrabajador.fecha_fin) return true;
        else{
          alertify.error('La fecha de finalizacion del contrato debe ser mayor a la de inicio');
          return false;
        }
      }
      else {
        return true;
      }
    },
    storeMaterial: function () {
      if(!this.validarFechaContrato()) return;
      this.vBtnSave.loading = true;
      axios.post(laravelRoute('trabajadores.store'), {
        nombre: this.trabajador.nombre,
        apellido_paterno: this.trabajador.apellido_paterno,
        apellido_materno: this.trabajador.apellido_materno,
        fecha_de_nacimiento: this.trabajador.fecha_de_nacimiento,
        estado_civil: this.trabajador.estado_civil,
        grado_de_estudios: this.trabajador.grado_de_estudios,
        curp: this.trabajador.curp,
        telefono_fijo: this.trabajador.telefono_fijo,
        telefono_celular: this.trabajador.telefono_celular,
        email: this.trabajador.email,
        calle: this.trabajador.calle,
        numero: this.trabajador.numero,
        colonia: this.trabajador.colonia,
        municipio: this.trabajador.municipio,
        estado: this.trabajador.estado,
        codigo_postal: this.trabajador.codigo_postal,
        rfc: this.trabajador.rfc,
        nss: this.trabajador.nss,
        puesto: this.trabajador.puesto,
        tipo: this.trabajador.tipo,
        area_id: this.trabajador.area_id,
        salario: this.trabajador.salario,
        activo: this.trabajador.activo,
        tipo_contrato: this.contratoTrabajador.contrato_id,
        ocupacion_id: this.trabajador.ocupacion,
        fecha_inicio_contrato: this.contratoTrabajador.fecha_inicio,
        fecha_fin_contrato: this.contratoTrabajador.fecha_fin
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.trabajador = {
          tipo: null,
          salario: null,
          activo: true
        };
        this.contratoTrabajador = {
          contrato_id: null,
          fecha_inicio: null,
          fecha_fin: null
        };
        this.localShow = (!this.keepStoring) ? false : true;
      })
      .catch(error => {
        errorHandling(error)
      })
      .finally(()=>this.vBtnSave.loading = false);
    },
    getContratos: function(){
      axios.get(laravelRoute('contratos.index'))
      .then(response => this.tiposDeContratos = response.data)
      .catch(error => errorHandling(error));
    },
    getAreas: function () {
      axios.get(laravelRoute('areas.index'))
      .then(response => this.areas = response.data)
      .catch(error => errorHandling(error));
    },
    getOcupaciones: function (page = 1) {
      axios.get(`/get_trabajadores_ocupaciones?page=${page}&query=${this.trabajador.area_id}`)
      .then(response => this.ocupaciones = response.data)
      .catch(error => errorHandling(error));
    }
  },
  created(){
    this.getAreas();
    this.getContratos();
  },
}
</script>
