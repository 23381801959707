var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { attrs: { xs12: "", sm6: "", md3: "" } },
    [
      _c("v-text-field", {
        attrs: { label: "componente de ejemplo de vuetify" }
      }),
      _vm._v(" "),
      _c("v-icon", { staticClass: "black--text" }, [_vm._v("more_vert")]),
      _vm._v(" Icono con material design\n\n  "),
      _c(
        "v-btn",
        {
          staticClass: "mx-0",
          attrs: {
            slot: "activator",
            outline: "",
            small: "",
            color: "green darken-2"
          },
          on: { click: _vm.sweetAlert },
          slot: "activator"
        },
        [_vm._v("\n    ejemplo sweet alert\n  ")]
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "mx-0",
          attrs: {
            slot: "activator",
            outline: "",
            small: "",
            color: "green darken-2"
          },
          on: { click: _vm.exampleOk },
          slot: "activator"
        },
        [_vm._v("\n    ejemplo success alertify \n  ")]
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "mx-0",
          attrs: {
            slot: "activator",
            outline: "",
            small: "",
            color: "red darken-2"
          },
          on: { click: _vm.exampleError },
          slot: "activator"
        },
        [_vm._v("\n    ejemplo error alertify \n  ")]
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "mx-0",
          attrs: {
            slot: "activator",
            outline: "",
            small: "",
            color: "red darken-2"
          },
          on: {
            click: function($event) {
              _vm.showExampleModal = true
            }
          },
          slot: "activator"
        },
        [_vm._v("\n    ejemplo modal\n  ")]
      ),
      _vm._v(" "),
      _c("example-modal-component", {
        attrs: { show: _vm.showExampleModal },
        on: {
          "update:show": function($event) {
            _vm.showExampleModal = $event
          },
          dataSended: _vm.showText
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }