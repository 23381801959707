var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        [
          _c("v-flex", [_c("h2", [_vm._v("Viviendas")])]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-flex",
            [
              _c("v-text-field", {
                attrs: {
                  color: "verdeFayal",
                  "append-icon": "search",
                  label: "Search",
                  "single-line": "",
                  "hide-details": ""
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        attrs: {
          "no-no-data-text": "No se encontraron fraccionamientos",
          "no-results-text": "No se encontraron fraccionamientos",
          headers: _vm.headers,
          items: _vm.viviendasProps,
          search: _vm.search,
          pagination: _vm.pagination
        },
        on: {
          "update:pagination": function($event) {
            _vm.pagination = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function(viviendasProps) {
              return [
                _c("td", { staticClass: "text-xs-left" }, [
                  _vm._v(
                    _vm._s(
                      viviendasProps.item.lote.manzana.lotificacion.prospecto
                        .nombre_proyecto
                    )
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-xs-left" }, [
                  _vm._v(_vm._s(viviendasProps.item.lote.nombre))
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-xs-left" }, [
                  _vm._v(_vm._s(viviendasProps.item.lote.modelo))
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-xs-left fyl-fraccionamientos-td" },
                  [
                    _vm._v(
                      _vm._s(viviendasProps.item.venta.cliente.nombre_completo)
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-xs-left fyl-fraccionamientos-td" },
                  [
                    _vm._v(
                      _vm._s(
                        viviendasProps.item.venta.fecha_de_entrega ||
                          "No disponible"
                      )
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "text-xs-left" },
                  [
                    _c(
                      "v-tooltip",
                      { attrs: { bottom: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              slot: "activator",
                              flat: "",
                              icon: "",
                              color: "verdeFayal"
                            },
                            on: {
                              click: function($event) {
                                _vm.dialog = true
                                _vm.vivienda = viviendasProps.item
                              }
                            },
                            slot: "activator"
                          },
                          [_c("v-icon", [_vm._v("create_new_folder")])],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("Editar Garantias")])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-tooltip",
                      { attrs: { bottom: "" } },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              slot: "activator",
                              flat: "",
                              icon: "",
                              color: "red"
                            },
                            on: {
                              click: function($event) {
                                _vm.dialogAdministrar = true
                                _vm.vivienda = viviendasProps.item
                              }
                            },
                            slot: "activator"
                          },
                          [_c("v-icon", [_vm._v("emoji_people")])],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("Aplicar Garantias")])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("viviendas-garantias-modal", {
        attrs: { dialog: _vm.dialog, vivienda: _vm.vivienda },
        on: {
          close: function($event) {
            _vm.dialog = false
          }
        }
      }),
      _vm._v(" "),
      _c("viviendas-garantias-modal-estados", {
        attrs: { dialog: _vm.dialogAdministrar, vivienda: _vm.vivienda },
        on: {
          close: function($event) {
            _vm.dialogAdministrar = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }