<template>
    <div class="text-xs-center">
        <v-dialog v-model="localShow" max-width="950px" persistent>
            <v-card>
                <v-toolbar color="white" flat>
                    <v-spacer></v-spacer>
                    <v-toolbar-title class="primary-title">Seleccionar lotes de la manzana {{ manzana.nombre }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-container grid-list-md>
                        <v-layout row wrap>
                            <v-flex xs6>
                                <v-autocomplete
                                    @keyup.enter.native="addLote"
                                    hint="Buscar lote a trabajar"
                                    prepend-inner-icon="search"
                                    :search-input.sync="query"
                                    :items="lotes.data"
                                    item-text="nombre"
                                    v-model="selected"
                                    persistent-hint
                                    item-value="id"
                                    label="Buscar"
                                    return-object
                                    hide-selected
                                    hide-no-data
                                >
                                    <template v-slot:append-outer>
                                    <v-slide-x-reverse-transition mode="out-in">
                                        <v-icon color="success" :disabled="!selected" @click="addLote">add</v-icon>
                                    </v-slide-x-reverse-transition>
                                    </template>
                                </v-autocomplete>
                            </v-flex>

                            <v-flex xs12>
                                <table class="table table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Manzana</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(lote, index) in manzana.lotes" :key="index">
                                            <td>{{ lote.nombre }}</td>
                                            <td>
                                                <v-layout>
                                                    <v-btn flat icon color="red" @click="manzana.lotes.splice(index, 1)">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </v-layout>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outline dark color="verdeFayal" flat @click="saveLotes">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
            <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    lotes: [],
    selected: [], 
    localShow: false,
    query: null,
    localLotesSelected: {},
    searchLoading: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    manzana: Object,
    lotesManzanas: Object
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
      this.getLotesManzana();
    },
    query: function() {
      this.getLotesManzana();
    }
  },
  methods: {
        getLotesManzana: function() {
            this.searchLoading = true;
            axios.get(`/manzanas/${this.manzana.id}/lotes?query=${this.query}`)
            .then(response => this.lotes = response.data)
            .catch(error => errorHandling(error))
            .finally(() => this.searchLoading = false);
        },
        addLote: function() {
            if(this.selected) {
                if(!this.manzana.lotes.some(e => e.id === this.selected.id)) {
                    this.manzana.lotes.push(this.selected);
                    this.selected = null;
                }
                else {
                    alertify.error('Este elemento ya se habia agregado anteriormente');
                }
            }
        },
        saveLotes: function() {
            this.$emit('update:lotesManzanas', this.manzana);
            this.localShow = false;
        }
  }
}
</script>
