<template>
  <v-layout>
    <v-container>
      <v-card>
        <v-flex xs12>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Inmobiliarias</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              color="verdeFayal"
              single-line
              label="Buscar"
              append-icon="search"
              v-model="search"
            ></v-text-field>
            <inmobiliaria-modal :inmobiliaria="inmobiliaria" :dialog="dialog" @close="cleanForm()" @open="dialog = true"></inmobiliaria-modal>
          </v-toolbar>
          <v-data-table
            :headers="headers_inmobiliaria"
            disable-initial-sort
            :items="inmobiliarias.data"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.inmobiliaria }}</td>
              <td>{{ props.item.telefono }}</td>
              <td>{{ props.item.direccion }}</td>
              <td>
                <v-layout>

                  <v-tooltip bottom>
                    <v-btn
                      @click="EditInmobiliaria(props.item.id)"
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="info"
                    >
                      <v-icon color="verdeFayal">edit</v-icon>
                    </v-btn>
                    <span>Editar</span>
                  </v-tooltip>
        
                  <v-tooltip bottom>
                    <v-btn
                      @click="deleteInmobiliaria(props.item.id)"
                      icon
                      flat
                      dark
                      small
                      slot="activator"
                      color="error"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <span>Eliminar</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination
              :limit="vPaginator.limit"
              :data="inmobiliarias"
              @pagination-change-page="getInmobiliarias"
            ></pagination>
          </v-card-actions>
        </v-flex>
      </v-card>
      <!-- modals -->
    </v-container>
  </v-layout>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import { errorHandling, laravelRoute } from "../../lib/utilities";

export default {
  data() {
    return {
      search: null,
      dialog: false,
      elementId: "",
      headers_inmobiliaria: [
        { text: "Inmobiliaria", value: "inmobiliaria" },
        { text: "Telefono", value: "telefono" },
        { text: "Dirección", value: "direccion" },
        { text: "Acciones" }
      ],
      currentInmobiliariaId: null,
      vPaginator: {
        limit: 10
      },
      inmobiliaria: {},
      inmobiliarias: {
        data: []
      }
    };
  },

  mounted() {
    this.getInmobiliarias();
  },

  methods: {
    cleanForm() {
      this.inmobiliaria = {};
      this.dialog = false;
    },
    getInmobiliarias: function(page = 1) {
      axios
        .get(`/inmobiliarias?page=${page}&query=${this.search}`)
        .then(response => {
          this.inmobiliarias = response.data;
        })
        .catch(error => errorHandling(error));
    },
    deleteInmobiliaria: function(inmobiliariaId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios
            .delete(laravelRoute("inmobiliarias.destroy", inmobiliariaId))
            .then(response => {
              this.getInmobiliarias();
              alertify.success(response.data.message_text);
              setTimeout(() => {
                window.location.href = "/inmobiliarias";
              }, 300);
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      });
    },

    EditInmobiliaria: function(inmobiliariaId) {
      axios.get(route("inmobiliarias.edit", inmobiliariaId)).then(response => {
        this.inmobiliaria = response.data;
        this.dialog = true;
      });
    }
  }
};
</script>
