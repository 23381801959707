<template>
  <v-container grid-list-md id="container-supplier">
    <v-layout row wrap>
      <v-flex :xs12="!showFiltersForm" :xs9="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat tabs>
            <v-toolbar-title>Proveedores</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field color="verdeFayal" single-line label="Buscar" append-icon="search" v-model="search">
            </v-text-field>
            <proveedores-create :proveedor="proveedor" :proveedores="providers" @close="cleanForm()"
              :dialog="ShowCreateModal" @open="ShowCreateModal = true"
              v-if="$root.can('new', permissions) ? true : false"></proveedores-create>
            <v-btn dark color="verdeFayal" outline @click="showFiltersForm = !showFiltersForm">
              <v-icon dark>filter_alt</v-icon>
            </v-btn>
            <v-btn dark color="verdeFayal" outline @click="exportToExcel">
              <v-icon dark>import_export</v-icon>
            </v-btn>
          </v-toolbar>

          <v-data-table :headers="vHeaders" disable-initial-sort :items="providers.data" hide-actions>
            <template slot="items" slot-scope="props">
              <td class="td-ellipsis">{{ props.item.nombre_completo }}</td>
              <td class="td-ellipsis">{{ props.item.nombre_vendedor }}</td>
              <td class="td-ellipsis">{{ props.item.telefono_oficina }}</td>
              <td>
                <span v-for="categoria in props.item.categorias" :key="categoria.id"
                  class="badge badge-pills badge-light badge-categories" flat>
                  {{ categoria.nombre }}
                </span>
              </td>
              <td class="td-ellipsis">{{ props.item.tipo_credito == '0' ? 'Contado' : `${props.item.tipo_credito} días`
                }}</td>
              <td class="text-xs-left">
                <v-layout>
                  <v-tooltip bottom>
                    <v-btn :disabled="!$root.can('update', permissions)" icon flat dark small slot="activator"
                      color="verdeFayal" @click="editproveedor(props.item.id)">
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <span>Editar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <v-btn :disabled="!$root.can('remove', permissions)" icon flat dark small slot="activator"
                      color="error" @click="sweetAlert(props.item)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <span>Eliminar</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>

          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="vPaginator.limit" :data="providers" @pagination-change-page="getProviders"></pagination>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex xs3 v-if="showFiltersForm">
        <v-card>
          <v-toolbar color="white" flat>
            <v-toolbar-title>Filtros</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn flat icon color="verdeFayal" dark @click="showFiltersForm = false">
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex>
                  <v-select multiple chips v-model="filters.filterCategoria" :items="categorias" label="Categoria" item-text="nombre"
                    item-value="id" clearable hint="Los elementos seleccionados son incluyentes"/>
                </v-flex>
                <v-flex>
                  <v-select v-model="filters.filterTipoCredito" :items="this.creditos" item-text="nombre"
                    item-value="dias" label="Tipo de crédito" clearable />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-btn dark color="verdeFayal" small flat outline @click="limpiar">Limpiar</v-btn>
            <v-spacer></v-spacer>
            <v-btn dark color="verdeFayal" small @click="filterProveedores">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { errorHandling, laravelRoute } from "../../lib/utilities";
import axios from "axios";
import swal from "sweetalert";

export default {
  data() {
    return {
      search: null,
      providers: {
        current_page: 1,
        data: []
      },
      currentProviderId: null,
      showFiltersForm: false,
      vPaginator: {
        limit: 10
      },
      vHeaders: [{
        text: 'Nombre completo',
        value: 'nombre_completo'
      },
      {
        text: 'Nombre del vendedor',
        value: 'nombre_vendedor'
      },
      {
        text: 'Teléfono de oficina',
        value: 'telefono_oficina'
      },
      {
        text: 'Categoria',
        value: 'nombre'
      },
      {
        text: 'Tipo de credito',
        value: 'tipo_credito'
      },
      {
        text: 'Acciones',
        value: 'acciones',
        sortable: false
      }
    ],

      ShowCreateModal: false,
      proveedor: {},      
      getproveedoresProps: this.providers,

      pagination: {
        sortBy: "id",
        descending: true
      },
      filters: {
        filterCategoria: null,
        filterTipoCredito: null,

      },
      categorias: [], 
      creditos: [
        { nombre: "de Contado", dias: "Cero" },
        { nombre: "9 días", dias: "9" },
        { nombre: "30 días", dias: "30" },
        { nombre: "45 días", dias: "45" },
        { nombre: "60 días", dias: "60" },
        { nombre: "90 días", dias: "90" }
      ],

    };
  },
  props: ["permissions"],
  watch: {
    search: function (value) {
      this.getProviders();
    },
  },
  methods: {
    getProviders: function (page = 1) {
      axios.get(`/proveedores?page=${page}&query=${this.search}`)
        .then(response => {
          this.providers = response.data;
        })
        .catch(error => errorHandling(error));
    },

    sweetAlert: function(proveedor) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez eliminado no se podrá recuperar",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
          // this.removingRole = true;
          this.eliminar(proveedor.id);
          this.getProviders(this.providers.current_page);
          swal("El Registro ha sido eliminado", {
            icon: "success"
          });
        } else {
          swal("El Registro no se eliminó");
        }
      });
    },

    eliminar: proveedorID => {
      var IDproveedor = proveedorID;
      axios
        .delete(route("proveedores.destroy", IDproveedor))
        .then(response => {
          if (response.data.message_text) {
            alertify.success(response.data.message_text);
          }
        })
        .catch(error => errorHandling(error));
    },

    editproveedor: function(proveedorId) {
      axios.get(route("proveedores.edit", proveedorId)).then(response => {
        this.proveedor = response.data;
        this.ShowCreateModal = true;
      });
    },

    cleanForm() {
      this.proveedor = {};
      this.ShowCreateModal = false;
    },
    
    getCategorias: function(){
      axios
      .get(laravelRoute('categorias.filtro'))
      .then(response => {
        this.categorias = response.data;
      })
      .catch(error => errorHandling(error));
    },
    filterProveedores: function() {
      axios.post('/proveedores/filterProveedores',this.filters)
      .then(response => this.providers = response.data)
      .catch(error => errorHandling(error));
    },
    limpiar: function () {             
        this.filters.filterCategoria = null;
        this.filters.filterTipoCredito = null;
        this.getProviders(this.providers.current_page);        
    },

    exportToExcel: function() {
      axios.post('/proveedores/filterProveedores', {
        ...this.filters,
        to_excel: true
      }, {responseType: 'arraybuffer'})
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'proveedores.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => errorHandling(error));
    }
  },
  created() {
    this.getProviders(this.providers.current_page);
    this.getCategorias();
  }
};
</script>

<style scoped>

  .badge-categories {
    background-color: '#ff5617' !important;
    margin: 1px;
  }
  #provider-modal-button {
    display: none;
  }
</style>
