<template>
    <v-layout>
        <v-container>
            <v-flex xs12>
                    <v-data-table :headers="vHeaders" :items="materials.data" hide-actions class="elevation-1">
                        <template slot="items" slot-scope="props">                            
                            <td>{{ props.item.material.categoria.nombre }}</td>
                            <td>{{ props.item.material.nombre }}</td>
                            <td>{{ props.item.cantidad }}</td>
                            <td>{{ props.item.material.precio }}</td>
                            <td>{{ props.item.impuesto }}%</td>
                            <td>$ {{ props.item.total_impuesto | currency}}</td>
                            <td>$ {{ props.item.precio_total | currency}}</td>
                            <td>
                                <v-tooltip bottom>
                                    <v-btn :disabled="!$root.can('valores-materiales-remove', permissions)" icon flat dark small slot="activator" color="error" @click="deleteMaterialValue(props.item.id)">
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                    <span>Eliminar</span>
                                </v-tooltip>
                            </td>
                        </template>
                    </v-data-table>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <pagination :limit="vPaginator.limit" :data="materials" @pagination-change-page="getMaterialsValue"></pagination>
                </v-card-actions>
            </v-flex>
        </v-container>
    </v-layout>
</template>

<script>
    import swal from "sweetalert";

    export default {
        data: () => ({
            materials: {
                data: []
            },
            vPaginator: {
                limit: 10
            },
            vHeaders: [
                { text: 'Categoria', value: 'categoria' },
                { text: 'Material', value: 'material' },
                { text: 'Cantidad', value: 'cantidad' },
                { text: 'Precio unitario', value: 'precio' },
                { text: 'Impuesto', value: 'impuesto' },
                { text: 'Precio antes de impuesto', value: 'antesImpuesto'},
                { text: 'Precio total', value: 'total'},
                { text: 'Acciones', value: 'acciones', sortable: false }
            ],
            localSearch: null,
            showEditHouseholdModal: false,
            editHouseoldId: null
        }),
        props: {
            itemId: Number,
            reload: Boolean,
            householdId: String,
            permissions: Object,
            modelType: String
        },
        watch: {
            itemId: function() {
                this.getMaterialsValue();
            },
            reload: function(value) {
                if(value == true) {
                    this.getMaterialsValue();
                }
            }
        },
        methods: {
            getMaterialsValue: function(page = 1) {
                axios.get(`/materiales_valores?page=${page}&query=${this.localSearch}&itemId=${this.itemId}&householdId=${this.householdId}&model=${this.modelType}`)
                .then(response => {
                    this.materials = response.data;
                })
                .catch( error => errorHandling(error));
            },
            deleteMaterialValue: function(materialValueId) {
                swal({
                    title: "¿Estás seguro?",
                    text: "Una vez eliminado no se podrá recuperar",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true
                }).then(result => {
                    if(result)  {
                        axios.delete(laravelRoute('materiales_valores.destroy', materialValueId))
                        .then( response => {
                            this.getMaterialsValue();
                            alertify.success(response.data.message_text);
                        })
                        .catch( error => {         
                            errorHandling(error);
                        });
                    }
                })
            }
        }
    }
</script>
