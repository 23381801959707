<template>
    <div>
        <v-card flat>
            <v-toolbar color="verdeFayal" dark extended flat>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn flat icon :href="`/fraccionamientos`">
                            <v-icon>keyboard_arrow_left</v-icon>
                        </v-btn>
                    </template>
                    <span>Regresar</span>
                </v-tooltip>
            </v-toolbar>

            <v-layout row pb-2>
                <v-flex xs10 offset-xs1>
                    <v-card class="card--flex-toolbar">
                        <v-toolbar card prominent>
                            <v-spacer></v-spacer>
                            <v-toolbar-title class="body-2 grey--text">Viviendas de {{ fraccionamiento.nombre_proyecto }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>

                        <v-card-text>
                            <v-card-text>
                                <v-data-table
                                    hide-actions
                                    :items="manzanas.data"
                                    :expand="expandManzana"
                                    ref="manzanasTable"
                                >
                                    <template v-slot:headers="props">
                                        <tr>
                                            <th>Nombre</th>
                                            <th class="text-xs-right"></th>
                                        </tr>
                                    </template>
                                    <template v-slot:items="props">
                                        <tr>
                                            <td>Manzana {{props.item.nombre}}</td>
                                            <td class="text-xs-right">
                                                <v-btn flat icon @click="openLotesExpand(props.item.id, props.index)">
                                                    <v-icon>{{(props.expanded) ? 'expand_less' : 'expand_more'}}</v-icon>
                                                </v-btn>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-slot:expand="props">
                                        <table class="table table-striped table-hover table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Lote</th>
                                                    <th>Modelo</th>
                                                    <th>Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(lote, index) in manzanas.data[props.index].lotes" :key="index">
                                                    <td>{{ lote.nombre }}</td>
                                                    <td>{{ lote.prototipo.nombre }}</td>
                                                    <td>
                                                        <v-layout>
                                                             <v-tooltip bottom>
                                                                <v-btn
                                                                    :href="`/viviendas/${lote.vivienda.id}/administrar_garantias`"
                                                                    slot="activator"
                                                                    color="verdeFayal"
                                                                    flat
                                                                    icon
                                                                >
                                                                    <v-icon>policy</v-icon>
                                                                </v-btn>
                                                                <span>Administrar garantias</span>
                                                            </v-tooltip>
                                                        </v-layout>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <pagination 
                                    :limit="10" 
                                    :data="manzanas" 
                                    @pagination-change-page="getManzanas"
                                ></pagination>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-card>
        <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
            {{ vSnackBar.text }}
            <v-btn color="pink" flat @click="vSnackBar.visible = false">
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
  import { errorHandling, laravelRoute } from "../../lib/utilities";
  import axios from 'axios';
  import swal from 'sweetalert';

  export default {
    data() {
        return {
            dialogFile: false,
            lotesFiles: "",
            dialog: false,
            elementId: "",
            currentSaleId: null,
            showFinishPurchaseModal: false,
            showDeliverLotModal: false,
            fraccionamiento: {},
                manzanas: {
                data:[]
            },
            expandManzana: false,
            showCustomersModal: false,
            currentLoteId: null,
            vSnackBar: {
                visible: false,
                text: null
            }
        }
    },
    props: {
        fraccionamientoId: String
    },
    watch: {

    },
    methods: {
      getFraccionamiento: function() {
        axios.get(laravelRoute('fraccionamientos.show.refactor', this.fraccionamientoId))
        .then(response => this.fraccionamiento = response.data)
        .catch(error => errorHandling(error));
      },
      getManzanas: function(page = 1){
        axios.get(`/fraccionamientos/${this.fraccionamientoId}/manzanas?page=${page}`)
        .then(response => this.manzanas = response.data)
        .catch(error => errorHandling(error));
      },
      openLotesExpand: function(id, index, page = 1) {
        let expand = !this.$refs.manzanasTable.expanded[id];
        axios.get(`/fraccionamientos/${this.fraccionamientoId}/manzanas/${id}/lotes_con_vivienda?page=${page}`)
        .then(response => {
            this.manzanas.data[index].lotes = response.data;
        })
        .catch(error => errorHandling(error))
        .finally(() => this.$set(this.$refs.manzanasTable.expanded, id, expand));
      }
    },
    created() {
        this.getFraccionamiento();
        this.getManzanas();
    }
  }
</script>
