var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v("Conceptos de urbanización")
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "", color: "verdeFayal" },
                          on: { click: _vm.openCreateModal }
                        },
                        [
                          _c("v-icon", { attrs: { right: "", dark: "" } }, [
                            _vm._v("add")
                          ]),
                          _vm._v("Crear\n          ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", outline: "" },
                          on: { click: _vm.exportToExcel }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("import_export")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("values-housing-development-index", {
                    attrs: {
                      reload: _vm.showCreateModal,
                      permissions_rubro: _vm.permissions_rubro,
                      permissions: _vm.permissions_valor
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.planes
                        },
                        on: { "pagination-change-page": _vm.getPlanes }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("create-concepts-modal", {
            attrs: {
              type: "urbanizacion",
              permissions: _vm.permissions_rubro,
              show: _vm.showCreateModal
            },
            on: {
              "update:show": function($event) {
                _vm.showCreateModal = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }