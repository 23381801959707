var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-toolbar",
                        {
                          attrs: { color: "white", flat: "", tabs: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "extension",
                              fn: function() {
                                return [
                                  _c(
                                    "v-tabs",
                                    {
                                      attrs: {
                                        centered: "",
                                        "slider-color": "verdeFayal"
                                      },
                                      model: {
                                        value: _vm.vTabs,
                                        callback: function($$v) {
                                          _vm.vTabs = $$v
                                        },
                                        expression: "vTabs"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-tab",
                                        {
                                          attrs: {
                                            href: "#datos-personales-tab"
                                          }
                                        },
                                        [_vm._v("Datos personales")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tab",
                                        {
                                          attrs: {
                                            href: "#datos-beneficiario-tab"
                                          }
                                        },
                                        [_vm._v("Beneficiario")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tab",
                                        {
                                          attrs: { href: "#datos-nomina-tab" }
                                        },
                                        [_vm._v("Deducciones")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tab",
                                        {
                                          attrs: {
                                            href: "#datos-contratos-tab"
                                          }
                                        },
                                        [_vm._v("Contratos")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tab",
                                        { attrs: { href: "#pagos-tab" } },
                                        [_vm._v("Pagos")]
                                      ),
                                      _vm._v(" "),
                                      _vm.trabajador.puesto === "Raya"
                                        ? _c(
                                            "v-tab",
                                            {
                                              attrs: {
                                                href: "#datos-vacaciones-tab"
                                              }
                                            },
                                            [_vm._v("Vacaciones")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                icon: "",
                                href: "/trabajadores"
                              }
                            },
                            [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-toolbar-title", [
                            _vm._v(
                              _vm._s(
                                _vm.trabajador.nombre_completo.toUpperCase()
                              )
                            )
                          ]),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              class:
                                "badge badge-" +
                                (_vm.trabajador.activo ? "success" : "warning")
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.trabajador.activo ? "ACTIVO" : "INACTIVO"
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-spacer")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.vTabs,
                        callback: function($$v) {
                          _vm.vTabs = $$v
                        },
                        expression: "vTabs"
                      }
                    },
                    [
                      _c(
                        "v-tab-item",
                        { attrs: { value: "datos-personales-tab" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-container",
                                { attrs: { "grid-list-md": "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              required: "",
                                              label: "Nombre"
                                            },
                                            model: {
                                              value: _vm.trabajador.nombre,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "nombre",
                                                  $$v
                                                )
                                              },
                                              expression: "trabajador.nombre"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              required: "",
                                              label: "Apellido paterno"
                                            },
                                            model: {
                                              value:
                                                _vm.trabajador.apellido_paterno,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "apellido_paterno",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "trabajador.apellido_paterno"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              required: "",
                                              label: "Apellido materno"
                                            },
                                            model: {
                                              value:
                                                _vm.trabajador.apellido_materno,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "apellido_materno",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "trabajador.apellido_materno"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "prepend-icon": "calendar_today",
                                              type: "date",
                                              label: "Fecha de nacimiento"
                                            },
                                            model: {
                                              value:
                                                _vm.trabajador
                                                  .fecha_de_nacimiento,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "fecha_de_nacimiento",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "trabajador.fecha_de_nacimiento"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: [
                                                "Soltero/a",
                                                "Casado/a",
                                                "Viudo/a",
                                                "Divorciado/a"
                                              ],
                                              label: "Estado civil"
                                            },
                                            model: {
                                              value:
                                                _vm.trabajador.estado_civil,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "estado_civil",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "trabajador.estado_civil"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: [
                                                "Primaria",
                                                "Secundaria",
                                                "Preparatoria",
                                                "Universidad",
                                                "Posgrado"
                                              ],
                                              label: "Grado de estudios"
                                            },
                                            model: {
                                              value:
                                                _vm.trabajador
                                                  .grado_de_estudios,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "grado_de_estudios",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "trabajador.grado_de_estudios"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              minlength: "18",
                                              maxlength: "18",
                                              required: "",
                                              label: "CURP"
                                            },
                                            model: {
                                              value: _vm.trabajador.curp,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "curp",
                                                  $$v
                                                )
                                              },
                                              expression: "trabajador.curp"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { md12: "" } },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("Datos de contacto")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              mask: "(###)-###-##-##",
                                              label: "Teléfono fijo"
                                            },
                                            model: {
                                              value:
                                                _vm.trabajador.telefono_fijo,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "telefono_fijo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "trabajador.telefono_fijo"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              mask: "(###)-###-##-##",
                                              label: "Teléfono celular"
                                            },
                                            model: {
                                              value:
                                                _vm.trabajador.telefono_celular,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "telefono_celular",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "trabajador.telefono_celular"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              type: "email",
                                              label: "Email"
                                            },
                                            model: {
                                              value: _vm.trabajador.correo,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "correo",
                                                  $$v
                                                )
                                              },
                                              expression: "trabajador.correo"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { md12: "" } },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("Dirección")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: { label: "Calle" },
                                            model: {
                                              value: _vm.trabajador.calle,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "calle",
                                                  $$v
                                                )
                                              },
                                              expression: "trabajador.calle"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: { label: "Número" },
                                            model: {
                                              value: _vm.trabajador.numero,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "numero",
                                                  $$v
                                                )
                                              },
                                              expression: "trabajador.numero"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: { label: "Colonia" },
                                            model: {
                                              value: _vm.trabajador.colonia,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "colonia",
                                                  $$v
                                                )
                                              },
                                              expression: "trabajador.colonia"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: { label: "Municipio" },
                                            model: {
                                              value: _vm.trabajador.municipio,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "municipio",
                                                  $$v
                                                )
                                              },
                                              expression: "trabajador.municipio"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: { label: "Estado" },
                                            model: {
                                              value: _vm.trabajador.estado,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "estado",
                                                  $$v
                                                )
                                              },
                                              expression: "trabajador.estado"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md4: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              minlength: "5",
                                              maxlength: "5",
                                              label: "Código postal"
                                            },
                                            model: {
                                              value:
                                                _vm.trabajador.codigo_postal,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "codigo_postal",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "trabajador.codigo_postal"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { md12: "" } },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("Datos de trabajador ")
                                          ])
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              minlegth: "12",
                                              maxlength: "13",
                                              label: "RFC"
                                            },
                                            model: {
                                              value: _vm.trabajador.rfc,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "rfc",
                                                  $$v
                                                )
                                              },
                                              expression: "trabajador.rfc"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              minlegth: "11",
                                              maxlength: "11",
                                              label:
                                                "Número de seguro social (NSS)"
                                            },
                                            model: {
                                              value: _vm.trabajador.nss,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "nss",
                                                  $$v
                                                )
                                              },
                                              expression: "trabajador.nss"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              "prepend-icon": "calendar_today",
                                              type: "date",
                                              label: "Fecha de contratación",
                                              readonly: ""
                                            },
                                            model: {
                                              value:
                                                _vm.trabajador
                                                  .fecha_de_contratacion,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "fecha_de_contratacion",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "trabajador.fecha_de_contratacion"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: ["Destajo", "Raya"],
                                              label: "Puesto"
                                            },
                                            model: {
                                              value: _vm.trabajador.puesto,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "puesto",
                                                  $$v
                                                )
                                              },
                                              expression: "trabajador.puesto"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.trabajador.puesto == "Destajo"
                                        ? _c(
                                            "v-flex",
                                            { attrs: { md6: "" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: [
                                                    "Sindicalizados",
                                                    "De confianza"
                                                  ],
                                                  label: "Tipo"
                                                },
                                                model: {
                                                  value:
                                                    _vm.trabajador
                                                      .tipo_de_puesto,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.trabajador,
                                                      "tipo_de_puesto",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "trabajador.tipo_de_puesto"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              items: _vm.areas,
                                              label: "Área de trabajo",
                                              "item-text": "nombre",
                                              "item-value": "id"
                                            },
                                            model: {
                                              value: _vm.trabajador.area_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "area_id",
                                                  $$v
                                                )
                                              },
                                              expression: "trabajador.area_id"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.trabajador.puesto == "Raya"
                                        ? _c(
                                            "v-flex",
                                            { attrs: { md6: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  type: "number",
                                                  label: "Salario"
                                                },
                                                model: {
                                                  value: _vm.trabajador.salario,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.trabajador,
                                                      "salario",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "trabajador.salario"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-flex",
                                        { attrs: { md6: "" } },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              hint: "Ocupación",
                                              "search-input": _vm.query,
                                              items: _vm.ocupaciones.data,
                                              loading: _vm.isLoading,
                                              "item-text": "nombre",
                                              clearable: "",
                                              color: "verdeFayal",
                                              "persistent-hint": "",
                                              "item-value": "id",
                                              label: "Ocupación",
                                              "hide-selected": "",
                                              "hide-no-data": ""
                                            },
                                            on: {
                                              "update:searchInput": function(
                                                $event
                                              ) {
                                                _vm.query = $event
                                              },
                                              "update:search-input": function(
                                                $event
                                              ) {
                                                _vm.query = $event
                                              }
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "no-data",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-list-tile",
                                                      [
                                                        _c(
                                                          "v-list-tile-title",
                                                          [
                                                            _vm._v(
                                                              "\n                      Busca la \n                      "
                                                            ),
                                                            _c("strong", [
                                                              _vm._v(
                                                                "Ocupación"
                                                              )
                                                            ]),
                                                            _vm._v(
                                                              "\n                      del trabajador\n                    "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ]),
                                            model: {
                                              value:
                                                _vm.trabajador.ocupacion_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.trabajador,
                                                  "ocupacion_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "trabajador.ocupacion_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "datos-beneficiario-tab" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-container",
                                { attrs: { "grid-list-md": "" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { md4: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  label: "Nombre"
                                                },
                                                model: {
                                                  value:
                                                    _vm.beneficiario.nombre,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.beneficiario,
                                                      "nombre",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "beneficiario.nombre"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md4: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  label: "Apellido paterno"
                                                },
                                                model: {
                                                  value:
                                                    _vm.beneficiario
                                                      .apellido_paterno,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.beneficiario,
                                                      "apellido_paterno",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "beneficiario.apellido_paterno"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md4: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  label: "Apellido materno"
                                                },
                                                model: {
                                                  value:
                                                    _vm.beneficiario
                                                      .apellido_materno,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.beneficiario,
                                                      "apellido_materno",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "beneficiario.apellido_materno"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md6: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  "prepend-icon":
                                                    "calendar_today",
                                                  type: "date",
                                                  label: "Fecha de nacimiento"
                                                },
                                                model: {
                                                  value:
                                                    _vm.beneficiario
                                                      .fecha_de_nacimiento,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.beneficiario,
                                                      "fecha_de_nacimiento",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "beneficiario.fecha_de_nacimiento"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md6: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  counter: 13,
                                                  label: "RFC"
                                                },
                                                model: {
                                                  value: _vm.beneficiario.rfc,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.beneficiario,
                                                      "rfc",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "beneficiario.rfc"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md6: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  label: "Dirección"
                                                },
                                                model: {
                                                  value:
                                                    _vm.beneficiario.direccion,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.beneficiario,
                                                      "direccion",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "beneficiario.direccion"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md6: "" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: [
                                                    "Esposo/a",
                                                    "Hijo/a",
                                                    "Padre/madre",
                                                    "Hermano/a",
                                                    "Abuelo/a",
                                                    "Nieto/a",
                                                    "Tío/a",
                                                    "Sobrino/a",
                                                    "Otro"
                                                  ],
                                                  label: "Parentesco"
                                                },
                                                model: {
                                                  value:
                                                    _vm.beneficiario.parentesco,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.beneficiario,
                                                      "parentesco",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "beneficiario.parentesco"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "datos-nomina-tab" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { flat: "" } },
                            [
                              _c(
                                "v-container",
                                { attrs: { "grid-list-md": "" } },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { md4: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  type: "number",
                                                  "prepend-icon":
                                                    "attach_money",
                                                  required: "",
                                                  label: "Crédito"
                                                },
                                                model: {
                                                  value: _vm.trabajador.credito,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.trabajador,
                                                      "credito",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "trabajador.credito"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md4: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  type: "number",
                                                  "prepend-icon":
                                                    "attach_money",
                                                  required: "",
                                                  label: "Pensión alimenticia"
                                                },
                                                model: {
                                                  value:
                                                    _vm.trabajador
                                                      .pension_alimenticia,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.trabajador,
                                                      "pension_alimenticia",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "trabajador.pension_alimenticia"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-flex",
                                            { attrs: { md4: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  type: "number",
                                                  "prepend-icon":
                                                    "attach_money",
                                                  required: "",
                                                  label: "Fondo de ahorro"
                                                },
                                                model: {
                                                  value:
                                                    _vm.trabajador
                                                      .fondo_de_ahorro,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.trabajador,
                                                      "fondo_de_ahorro",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "trabajador.fondo_de_ahorro"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "pagos-tab" } },
                        [
                          _c("payments-index", {
                            attrs: {
                              workerId: _vm.workerId,
                              puesto: _vm.trabajador.puesto,
                              salary: _vm.trabajador.salario
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "datos-contratos-tab" } },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-layout",
                                {
                                  staticClass: "pl-4 pr-2",
                                  attrs: { row: "", wrap: "" }
                                },
                                [
                                  _c("v-toolbar-title", [
                                    _vm._v("Historial de contratos")
                                  ]),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _vm.$root.can("new", _vm.permissions)
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "success", dark: "" },
                                          on: {
                                            click: _vm.openCreateContractModal
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v(" post_add ")]
                                          ),
                                          _vm._v(
                                            "\n                  Nuevo contrato \n                "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-data-table", {
                                staticClass: "mt-4 elevation-1",
                                attrs: {
                                  headers: _vm.headers,
                                  items: _vm.trabajador.contratos,
                                  "items-per-page": 20,
                                  "disable-initial-sort": "",
                                  "hide-actions": "",
                                  sortBy: "item.pivot.created_at",
                                  "item-key": "item.pivot.id"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "items",
                                    fn: function(props) {
                                      return [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(props.item.pivot.estatus)
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(props.item.periodo))
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              props.item.pivot.fecha_inicio
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(props.item.pivot.fecha_fin)
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "v-layout",
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  { attrs: { bottom: "" } },
                                                  [
                                                    _c("v-checkbox", {
                                                      attrs: {
                                                        slot: "activator",
                                                        disabled: !(
                                                          _vm.$root.can(
                                                            "accept",
                                                            _vm.permissions
                                                          ) &&
                                                          props.item.pivot
                                                            .estatus ==
                                                            "Pendiente"
                                                        )
                                                          ? true
                                                          : false,
                                                        "input-value":
                                                          props.item.pivot
                                                            .estatus ===
                                                          "Pendiente"
                                                            ? "false"
                                                            : "true",
                                                        "true-value": "true",
                                                        "false-value": "false",
                                                        flat: "",
                                                        color: "orange darken-3"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.sweetAlertAprobadoGerencia(
                                                            props.item.pivot
                                                          )
                                                        }
                                                      },
                                                      slot: "activator"
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          color: "orange"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              props.item.pivot
                                                                .estatus ===
                                                                "Pendiente"
                                                                ? "Aprobar contrato - Gerencia Administrativa"
                                                                : "Contrato aprobado"
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  { attrs: { bottom: "" } },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          slot: "activator",
                                                          icon: "",
                                                          flat: "",
                                                          dark: "",
                                                          small: "",
                                                          color: "verdeFayal"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openEditContractModal(
                                                              props.item.pivot
                                                            )
                                                          }
                                                        },
                                                        slot: "activator"
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("visibility")
                                                        ])
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        "Ver/editar contrato"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  { attrs: { bottom: "" } },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          slot: "activator",
                                                          disabled: !(
                                                            _vm.$root.can(
                                                              "remove",
                                                              _vm.permissions
                                                            ) &&
                                                            props.item.pivot
                                                              .estatus ==
                                                              "Pendiente"
                                                          )
                                                            ? true
                                                            : false,
                                                          icon: "",
                                                          flat: "",
                                                          dark: "",
                                                          small: "",
                                                          color: "error"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.deleteContract(
                                                              props.item.pivot
                                                                .id
                                                            )
                                                          }
                                                        },
                                                        slot: "activator"
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v("delete")
                                                        ])
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v("Eliminar")
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  { attrs: { bottom: "" } },
                                                  [
                                                    _vm.$root.can(
                                                      "capture_folio",
                                                      _vm.permissions
                                                    ) &&
                                                    props.item.pivot.estatus ==
                                                      "Aprobado"
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              slot: "activator",
                                                              disabled:
                                                                props.item.pivot
                                                                  .estatus ==
                                                                "finalizado",
                                                              icon: "",
                                                              flat: "",
                                                              dark: "",
                                                              small: "",
                                                              color: !(
                                                                _vm.trabajador
                                                                  .activo == 1
                                                              )
                                                                ? "verdeFayal"
                                                                : "error"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openActiveWorkerModal(
                                                                  props.item
                                                                    .pivot
                                                                )
                                                              }
                                                            },
                                                            slot: "activator"
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "settings_power"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !(
                                                      _vm.trabajador.activo == 1
                                                    )
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "Activar trabajador"
                                                          )
                                                        ])
                                                      : _c("span", [
                                                          _vm._v(
                                                            "Desactivar trabajador"
                                                          )
                                                        ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  { attrs: { bottom: "" } },
                                                  [
                                                    _vm.$root.can(
                                                      "expire",
                                                      _vm.permissions
                                                    ) &&
                                                    props.item.pivot.estatus ===
                                                      "Aprobado"
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              slot: "activator",
                                                              disabled:
                                                                props.item.pivot
                                                                  .estatus ===
                                                                "finalizado",
                                                              icon: "",
                                                              flat: "",
                                                              dark: "",
                                                              small: "",
                                                              color: "primary"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openRenovateWorkerModal(
                                                                  props.item
                                                                    .pivot
                                                                )
                                                              }
                                                            },
                                                            slot: "activator"
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("refresh")
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        "Renovar/Cambiar contrato"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-tooltip",
                                                  { attrs: { bottom: "" } },
                                                  [
                                                    _vm.$root.can(
                                                      "expire",
                                                      _vm.permissions
                                                    ) &&
                                                    props.item.pivot.estatus ==
                                                      "Aprobado"
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              slot: "activator",
                                                              disabled:
                                                                props.item.pivot
                                                                  .estatus ==
                                                                "finalizado",
                                                              icon: "",
                                                              flat: "",
                                                              dark: "",
                                                              small: "",
                                                              color: "error"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.sweetAlertFinalizarContrato(
                                                                  props.item
                                                                    .pivot
                                                                )
                                                              }
                                                            },
                                                            slot: "activator"
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v("check")
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        "Finalizar contrato"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.trabajador.puesto === "Raya"
                        ? _c(
                            "v-tab-item",
                            { attrs: { value: "datos-vacaciones-tab" } },
                            [
                              _c(
                                "v-card",
                                { attrs: { flat: "" } },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { "grid-list-md": "" } },
                                    [
                                      _c("v-select", {
                                        staticClass: "pt-4",
                                        attrs: {
                                          items: _vm.getVacationsFiltered,
                                          "item-text": function(item) {
                                            return (
                                              item.antiguedad_years + " años"
                                            )
                                          },
                                          "item-value": function(item) {
                                            return item
                                          },
                                          solo: "",
                                          label: "Años de antiguedad"
                                        },
                                        model: {
                                          value: _vm.selected_vacation,
                                          callback: function($$v) {
                                            _vm.selected_vacation = $$v
                                          },
                                          expression: "selected_vacation"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-container",
                                            { attrs: { "grid-list-md": "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                { attrs: { wrap: "" } },
                                                [
                                                  _c("v-spacer"),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "h4 weight pr-2"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Días de vacaciones por año: " +
                                                          _vm._s(
                                                            _vm
                                                              .selected_vacation
                                                              .dias_totales
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-layout",
                                                { attrs: { wrap: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { md6: "" } },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          type: "number",
                                                          max:
                                                            _vm
                                                              .selected_vacation
                                                              .dias_totales,
                                                          disabled:
                                                            _vm
                                                              .selected_vacation
                                                              .antiguedad_years ===
                                                            "0 años",
                                                          label: "Días tomados"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .selected_vacation
                                                              .pivot
                                                              .dias_tomados,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .selected_vacation
                                                                .pivot,
                                                              "dias_tomados",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "selected_vacation.pivot.dias_tomados"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { md6: "" } },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          type: "number",
                                                          label:
                                                            "Días restantes",
                                                          readonly: ""
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .selected_vacation
                                                              .pivot
                                                              .dias_restantes,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm
                                                                .selected_vacation
                                                                .pivot,
                                                              "dias_restantes",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "selected_vacation.pivot.dias_restantes"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _vm.vTabs != "pagos-tab"
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                color: "verdeFayal",
                                flat: "",
                                loading: _vm.vBtnSave.loading
                              },
                              on: { click: _vm.updateInfo }
                            },
                            [_vm._v(" Guardar ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("save-contracts-workers-modal", {
        attrs: {
          show: _vm.showSaveContractModal,
          contrato_trabajador: _vm.current_contratoTrabajador,
          permissions: _vm.permissions
        },
        on: {
          "update:show": function($event) {
            _vm.showSaveContractModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("active-worker-modal", {
        attrs: {
          show: _vm.showActiveWorkerModal,
          contrato_trabajador: _vm.current_contratoTrabajador,
          permissions: _vm.permissions,
          trabajador_is_active: _vm.trabajador.activo == 1 ? true : false
        },
        on: {
          "update:show": function($event) {
            _vm.showActiveWorkerModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c("renovate-worker-modal", {
        attrs: {
          show: _vm.showRenovateWorkerModal,
          contrato_trabajador_id: _vm.current_contratoTrabajador.id,
          trabajador_is_active: _vm.trabajador.activo == 1 ? true : false
        },
        on: {
          "update:show": function($event) {
            _vm.showRenovateWorkerModal = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.vSnackBar.text) + "\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }