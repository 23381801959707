var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "verdeFayal", dark: "", extended: "", flat: "" } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            flat: "",
                            icon: "",
                            href: "/prototipos_vivienda"
                          }
                        },
                        [_c("v-icon", [_vm._v("keyboard_arrow_left")])],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [_vm._v(" "), _c("span", [_vm._v("Regresar")])]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-layout",
        { attrs: { row: "", "pb-2": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs10: "", "offset-xs1": "" } },
            [
              _c(
                "v-card",
                { staticClass: "card--flex-toolbar" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { card: "", prominent: "" } },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-toolbar-title",
                        { staticClass: "body-2 grey--text" },
                        [
                          _vm._v(
                            "Materiales asociados a " +
                              _vm._s(_vm.household.nombre)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        { attrs: { bottom: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                slot: "activator",
                                href:
                                  "/prototipos_vivienda/" +
                                  _vm.householdId +
                                  "/materiales/exportar?sort_by=" +
                                  _vm.sortBy,
                                color: "#ff5617",
                                target: "_blank",
                                small: "",
                                icon: "",
                                flat: "",
                                dark: ""
                              },
                              slot: "activator"
                            },
                            [_c("v-icon", [_vm._v("picture_as_pdf")])],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v("Exportar a PDF")])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn-toggle",
                        {
                          model: {
                            value: _vm.sortBy,
                            callback: function($$v) {
                              _vm.sortBy = $$v
                            },
                            expression: "sortBy"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            { attrs: { flat: "", value: "conceptos" } },
                            [
                              _c("span", [_vm._v("Conceptos")]),
                              _vm._v(" "),
                              _c("v-icon", [_vm._v("format_align_center")])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            { attrs: { flat: "", value: "materiales" } },
                            [
                              _c("span", [_vm._v("Materiales")]),
                              _vm._v(" "),
                              _c("v-icon", [_vm._v("format_align_left")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _vm.sortBy == "conceptos"
                        ? _c("v-data-table", {
                            attrs: {
                              items: _vm.materials.data,
                              "hide-actions": "",
                              "item-key": "id"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "headers",
                                  fn: function(props) {
                                    return [
                                      _c("tr", [
                                        _c("th", [_vm._v("Material")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Categoria")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Concepto")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Rubro")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Cantidad")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Unidad de medida")]),
                                        _vm._v(" "),
                                        _c("th", [
                                          _vm._v("Total antes de impuesto")
                                        ]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Precio total")])
                                      ])
                                    ]
                                  }
                                },
                                {
                                  key: "items",
                                  fn: function(props) {
                                    return [
                                      _c("tr", [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(props.item.material.nombre)
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              props.item.material
                                                .categoria_asociada
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              props.item.rubro
                                                ? props.item.rubro.conceptos
                                                    .nombre
                                                : null
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              props.item.rubro
                                                ? props.item.rubro.nombre
                                                : null
                                            )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(props.item.cantidad))
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(props.item.material.unidad)
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "$ " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  props.item.total_impuesto
                                                )
                                              )
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "$ " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  props.item.precio_total
                                                )
                                              )
                                          )
                                        ])
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3438433904
                            )
                          })
                        : _c("v-data-table", {
                            attrs: {
                              items: _vm.sortMaterials.data,
                              "hide-actions": "",
                              "item-key": "id"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "headers",
                                fn: function(props) {
                                  return [
                                    _c("tr", [
                                      _c("th", [_vm._v("Categoria")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Material")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Cantidad")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Unidad de medida")]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Precio unitario")]),
                                      _vm._v(" "),
                                      _c("th", [
                                        _vm._v("Subtotal antes de impuesto")
                                      ]),
                                      _vm._v(" "),
                                      _c("th", [_vm._v("Precio total")])
                                    ])
                                  ]
                                }
                              },
                              {
                                key: "items",
                                fn: function(props) {
                                  return [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.categoria))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.nombre))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          _vm._s(props.item.cantidad.toFixed(2))
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(props.item.unidad))
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "$ " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.getPrecioPorUnidad(
                                                  props.item.precio
                                                )
                                              )
                                            )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "$ " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.getPrecioAntesImpuestos(
                                                  props.item.precio,
                                                  props.item.cantidad
                                                )
                                              )
                                            )
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "$ " +
                                            _vm._s(
                                              _vm._f("currency")(
                                                _vm.getPrecioTotal(
                                                  props.item.precio,
                                                  props.item.cantidad
                                                )
                                              )
                                            )
                                        )
                                      ])
                                    ])
                                  ]
                                }
                              }
                            ])
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _vm.sortBy == "conceptos"
                        ? _c("pagination", {
                            attrs: { limit: 10, data: _vm.materials },
                            on: { "pagination-change-page": _vm.getMaterials }
                          })
                        : _c("pagination", {
                            attrs: { limit: 10, data: _vm.sortMaterials },
                            on: {
                              "pagination-change-page": _vm.getSortMaterials
                            }
                          }),
                      _vm._v(" "),
                      _c("v-spacer")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }