var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.vHeaders,
                  items: _vm.materials.data,
                  "hide-actions": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c("td", [
                          _vm._v(_vm._s(props.item.material.categoria.nombre))
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(props.item.material.nombre))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(props.item.cantidad))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(props.item.material.precio))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(props.item.impuesto) + "%")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "$ " +
                              _vm._s(
                                _vm._f("currency")(props.item.total_impuesto)
                              )
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "$ " +
                              _vm._s(
                                _vm._f("currency")(props.item.precio_total)
                              )
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "v-tooltip",
                              { attrs: { bottom: "" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      slot: "activator",
                                      disabled: !_vm.$root.can(
                                        "valores-materiales-remove",
                                        _vm.permissions
                                      ),
                                      icon: "",
                                      flat: "",
                                      dark: "",
                                      small: "",
                                      color: "error"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteMaterialValue(
                                          props.item.id
                                        )
                                      }
                                    },
                                    slot: "activator"
                                  },
                                  [_c("v-icon", [_vm._v("delete")])],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Eliminar")])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("pagination", {
                    attrs: { limit: _vm.vPaginator.limit, data: _vm.materials },
                    on: { "pagination-change-page": _vm.getMaterialsValue }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }