<template>
  <div class="text-xs-center">
    <v-dialog v-model="localShow" width="500" persistent>
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Archivos asociados a {{ venta.lote.nombre }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <template >
            <v-layout row>
              <v-flex xs12>
                <v-list-tile
                  v-for="(archivo, index) in listado"
                  :key="index"
                  avatar
                >
                  <v-list-tile-content>
                    <v-list-tile-title>{{ archivo.nombre }}</v-list-tile-title>
                  </v-list-tile-content>

                  <v-list-tile-action>
                    <v-layout>
                      <v-tooltip v-if="!fileExist(archivo.nombre)">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            @click="openFileModal(archivo.nombre)"
                            v-on="on" 
                            icon 
                            ripple
                          >
                            <v-icon color="warning">insert_drive_file</v-icon>
                          </v-btn>
                        </template>
                        <span>Subir</span>
                      </v-tooltip>

                      <v-tooltip v-if="fileExist(archivo.nombre)">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :href="getUrl(archivo.nombre).url"
                            v-on="on" 
                            icon 
                            ripple
                            target="_blank"
                          >
                            <v-icon color="verdeFayal">task</v-icon>
                          </v-btn>
                        </template>
                        <span>Ver archivo</span>
                      </v-tooltip>
                    </v-layout>
                  </v-list-tile-action>
                </v-list-tile>
              </v-flex>
            </v-layout>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <upload-lotes-files
      element_type="App\Venta"
      :element_id="ventaId"
      :show.sync="showFileModal"
      :type="type"
    ></upload-lotes-files>
  </div>
</template>

<script>
import { laravelRoute } from '../../../lib/utilities';
export default {
  props: {
    show: Boolean,
    ventaId: Number
  },
  data() {
    return {
      localShow: false,
      venta: {
        lote: {}
      },
      listado: [],
      archivos: [],
      showFileModal: false,
      type: null
    };
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    },
    ventaId: function() {
      this.getVenta();
      this.getFileList();
      this.getFileSale();
    },
    showFileModal: function() {
      this.getFileSale();
    }
  },

  methods: {
    getVenta: function() {
      axios.get(laravelRoute('ventas.show.refactor', this.ventaId))
      .then(response => this.venta = response.data)
      .catch(error => errorHandling(error))
    },
    getFileList: function() {
      axios.get(laravelRoute('metodos_de_pago.archivos', this.ventaId))
      .then(response => this.listado = response.data)
      .catch(error => errorHandling(error))
    },
    getFileSale: function() {
      axios.get(laravelRoute('ventas.archivos', this.ventaId))
      .then(response => this.archivos = response.data)
      .catch(error => errorHandling(error))
    },
    openFileModal: function(type) {
      this.showFileModal = true;
      this.type = type;
    },
    fileExist: function(type) {
      return this.archivos.some(archivo => archivo.type == type);
    },
    getUrl: function(type) {
      return this.archivos.find(archivo => archivo.type == type);
    }
  },
};
</script>


