<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Administrar material: {{ material.nombre }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md4>
              <v-text-field v-model="totalExistencia" readonly required label="Existencia"></v-text-field>
            </v-flex>
            <v-flex md4>
              <v-text-field v-model="material.salida" required label="Salida" type="number" min="0"></v-text-field>
            </v-flex>
            <v-flex md4>
              <v-text-field v-model="material.minimo" required label="Mínimo" type="number" min="0"></v-text-field>
            </v-flex>
            <v-flex md4>
              <v-text-field v-model="extra" required label="Añadir material" type="number" min="0"></v-text-field>
            </v-flex>            
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="updateMaterial" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    material: {
      existencia: 0,
      salida: 0,
      minimo: 0
    },
    extra: 0,
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    materialId: Number
  },
  computed: {
    totalExistencia: function() {
      return parseInt(this.extra) + parseInt(this.material.existencia);
    }
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    materialId: function (value) {
      this.getMaterial();
    },
    localShow: function (value) {
      this.$emit('update:show', value);
      this.getMaterial();
    }
  },
  methods: {
    getMaterial: function () {
      axios.get(laravelRoute('materiales.show', this.materialId))
      .then(response => {
          console.log(response.data)
        this.material = response.data;
      })
      .catch(error => errorHandling(error));
    },
    updateMaterial: function () {
      this.vBtnSave.loading = true;
      axios.patch('/materiales/' + this.materialId, {
          nombre: this.material.nombre,
          unidad: this.material.unidad,
          precio: this.material.precio,
          categoria: this.material.categoria.id,
          existencia: this.totalExistencia,
          salida: this.material.salida,
          minimo: this.material.minimo,
          especificaciones: this.material.especificaciones
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.vBtnSave.loading = false;
          this.localShow = false;
        })
        .catch(error => {
          this.vBtnSave.loading = false;
          errorHandling(error)
        });
    },
  }
}
</script>
