<template>
<div class="text-xs-center">
  <v-dialog v-model="localShow" max-width="850px" persistent>
    <v-card>
      <v-toolbar color="white" flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="primary-title">Editar categoría</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex md12>
              <v-text-field v-model="category.nombre" required label="Nombre"></v-text-field>
            </v-flex>
            <v-flex md12>
              <v-select :items="categories_types" v-model="category.tipo" label="Tipo de categoría"></v-select>
            </v-flex>
            <v-flex md12>
              <v-text-field v-model="category.descripcion" required label="Descripcion"></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="verdeFayal" @click="updateCategory" flat :loading="vBtnSave.loading"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
    {{ vSnackBar.text }}
    <v-btn color="pink" flat @click="vSnackBar.visible = false">
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>
</div>
</template>

<script>
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";
export default {
  data: () => ({
    category: {},
    categories_types: ['Materiales de construccíon', 'Materiales de maquinaría'],
    localShow: false,
    vBtnSave: {
      loading: false
    },
    vSnackBar: {
      visible: false,
      text: null
    }
  }),
  props: {
    show: Boolean,
    categoryId: Number
  },
  watch: {
    show: function (value) {
      this.localShow = value
    },
    categoryId: function (value) {
      this.getCategory();
    },
    localShow: function (value) {
      this.$emit('update:show', value)
    }
  },
  methods: {
    getCategory: function () {
      axios.get(laravelRoute('categorias.show', this.categoryId))
        .then(response => this.category = response.data)
        .catch(error => errorHandling(error));
    },
    updateCategory: function () {
      this.vBtnSave.loading = true;
      axios.patch('/categorias/' + this.categoryId, {
          nombre: this.category.nombre,
          descripcion: this.category.descripcion,
          tipo: this.category.tipo
        })
        .then(response => {
          alertify.success(response.data.message_text);
          this.vBtnSave.loading = false;
          this.localShow = false;
        })
        .catch(error => {
          this.vBtnSave.loading = false;
          errorHandling(error)
        });
    }
  }
}
</script>
