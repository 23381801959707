<template>
    <div class="text-xs-center">
    <!-- <v-dialog v-model="localShow" max-width="850px" persistent> -->
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Cambiar estado al mantenimiento</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field color="#1ABA24" v-model="mantenimiento.maquinaria.nombre" label="Nombre de la máquina" disabled></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field color="#1ABA24" v-model="mantenimiento.mantenimiento.nombre" label="Fecha de inicio de mantenimiento" disabled></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-select
                  v-model="mantenimiento.estado.information.id"
                  color="verdeFayal"
                  :items="status"
                  item-value="id"
                  item-text="estado"
                  label="Seleccione status"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field color="#1ABA24" v-model="mantenimiento.fecha" label="Fecha de inicio de mantenimiento" type="date"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="updateMantenimiento" :loading="vBtnSave.loading" dark color="verdeFayal" flat> Guardar </v-btn>
        </v-card-actions>
      </v-card>
    <!-- </v-dialog> -->
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>  
</template>

<script>
import { laravelRoute, errorHandling } from '../../../lib/utilities';
export default {
  data(){
    return{
      keepStoring: true,
      mantenimientoData:{
        estado:{
          information:{
            estado:''
          }
        }
      },
      vBtnSave: {
        loading: false
      },
      vSnackBar: {
        visible: false,
        text: null
      },
      status: [],
    }
  },
  props: {
      mantenimiento: Object,
  },
  methods:{
    getMantenimientos: function (page = 1) {
        axios.get(`/mantenimientos?page=${page}&query=${this.search}`)
        .then(response => {
          this.mantenimiento = response.data;
        })
        .catch(error => errorHandling(error));
      },
    updateMantenimiento: function () {
      this.vBtnSave.loading = true;
      axios.patch('/mantenimientos/' + this.mantenimiento.id, {
        estado: this.mantenimiento.estado.information.id,
        fecha: this.mantenimiento.fecha
      })
      .then(response => {
        alertify.success(response.data.message_text);
        this.vBtnSave.loading = false;
        this.localShow = false;
      })
      .catch(error => {
        this.vBtnSave.loading = false;
        errorHandling(error)
      });
    },
    getStatus: function(){
      axios
      .get(laravelRoute('mantenimientos.estados'))
      .then(response => {
        this.status = response.data;
      })
      .catch(error => errorHandling(error));
    },
  },
  created(){
    this.getStatus();
  }
}
</script>