var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.compMantenimiento
    ? _c(
        "div",
        { staticClass: "text-xs-center" },
        [
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "850px", persistent: "" },
              model: {
                value: _vm.localShow,
                callback: function($$v) {
                  _vm.localShow = $$v
                },
                expression: "localShow"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "" } },
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-toolbar-title", { staticClass: "primary-title" }, [
                        _vm._v("Editar mantenimiento")
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            flat: "",
                            icon: "",
                            color: "verdeFayal",
                            dark: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.localShow = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { md6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Nombre del mantenimiento"
                                    },
                                    model: {
                                      value: _vm.compMantenimiento.nombre,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.compMantenimiento,
                                          "nombre",
                                          $$v
                                        )
                                      },
                                      expression: "compMantenimiento.nombre"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { md6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Periodo (en días)",
                                      type: "number"
                                    },
                                    model: {
                                      value: _vm.compMantenimiento.periodo,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.compMantenimiento,
                                          "periodo",
                                          $$v
                                        )
                                      },
                                      expression: "compMantenimiento.periodo"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { md6: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Duración (en horas)",
                                      type: "number"
                                    },
                                    model: {
                                      value: _vm.compMantenimiento.duracion,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.compMantenimiento,
                                          "duracion",
                                          $$v
                                        )
                                      },
                                      expression: "compMantenimiento.duracion"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            dark: "",
                            color: "verdeFayal",
                            flat: "",
                            loading: _vm.vBtnSave.loading
                          },
                          on: { click: _vm.updateMantenimiento }
                        },
                        [_vm._v(" Guardar ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-snackbar",
            {
              attrs: { bottom: "bottom", right: "right" },
              model: {
                value: _vm.vSnackBar.visible,
                callback: function($$v) {
                  _vm.$set(_vm.vSnackBar, "visible", $$v)
                },
                expression: "vSnackBar.visible"
              }
            },
            [
              _vm._v("\n  " + _vm._s(_vm.vSnackBar.text) + "\n  "),
              _c(
                "v-btn",
                {
                  attrs: { color: "pink", flat: "" },
                  on: {
                    click: function($event) {
                      _vm.vSnackBar.visible = false
                    }
                  }
                },
                [_c("v-icon", [_vm._v("close")])],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }