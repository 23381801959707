var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-xs-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "1050", persistent: "" },
          model: {
            value: _vm.localShow,
            callback: function($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "white", flat: "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "primary-title" }, [
                    _vm._v(
                      "Añadir lotes a la Manzana " + _vm._s(_vm.manzana.nombre)
                    )
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        flat: "",
                        icon: "",
                        color: "verdeFayal",
                        dark: ""
                      },
                      on: {
                        click: function($event) {
                          _vm.localShow = false
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-form",
                    {
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.addLotes($event)
                        }
                      }
                    },
                    [
                      _c(
                        "v-container",
                        { attrs: { "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs12: "" } }, [
                                _c("span", { staticClass: "grey--text" }, [
                                  _vm._v("Adición rápida de lotes")
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      label: "Tipo de lote",
                                      items: ["Regular", "Irregular"],
                                      rules: [
                                        function(v) {
                                          return !!v || "Campo requerido"
                                        }
                                      ]
                                    },
                                    model: {
                                      value: _vm.addingLote.tipo,
                                      callback: function($$v) {
                                        _vm.$set(_vm.addingLote, "tipo", $$v)
                                      },
                                      expression: "addingLote.tipo"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "" } },
                                [
                                  _c("searcher", {
                                    attrs: {
                                      configuration: _vm.searchModelo,
                                      itemSelected: _vm.addingLote.modelo
                                    },
                                    on: {
                                      "update:itemSelected": function($event) {
                                        return _vm.$set(
                                          _vm.addingLote,
                                          "modelo",
                                          $event
                                        )
                                      },
                                      "update:item-selected": function($event) {
                                        return _vm.$set(
                                          _vm.addingLote,
                                          "modelo",
                                          $event
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs3: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "verdeFayal",
                                      label: "Superficie en metros cuadrados",
                                      type: "number",
                                      min: "1",
                                      rules: [
                                        function(v) {
                                          return !!v || "Campo requerido"
                                        }
                                      ]
                                    },
                                    model: {
                                      value: _vm.addingLote.superficie,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addingLote,
                                          "superficie",
                                          $$v
                                        )
                                      },
                                      expression: "addingLote.superficie"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs2: "" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      color: "verdeFayal",
                                      label: "Cantidad a agregar",
                                      type: "number",
                                      min: "1",
                                      rules: [
                                        function(v) {
                                          return !!v || "Campo requerido"
                                        }
                                      ]
                                    },
                                    model: {
                                      value: _vm.addingLote.cantidad,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addingLote,
                                          "cantidad",
                                          $$v
                                        )
                                      },
                                      expression: "addingLote.cantidad"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-flex",
                                { attrs: { xs1: "" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    { attrs: { bottom: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            slot: "activator",
                                            color: "verdeFayal",
                                            fab: "",
                                            dark: "",
                                            small: ""
                                          },
                                          on: { click: _vm.addLotes },
                                          slot: "activator"
                                        },
                                        [_c("v-icon", [_vm._v("add")])],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Añadir lotes")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-divider"),
                          _vm._v(" "),
                          _vm.lotes.length
                            ? _c(
                                "v-layout",
                                { attrs: { wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    {
                                      attrs: {
                                        xs12: _vm.editing ? false : true,
                                        xs6: _vm.editing ? true : false
                                      }
                                    },
                                    [
                                      _c("v-data-table", {
                                        attrs: {
                                          "hide-actions": "",
                                          items: _vm.lotes
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "headers",
                                              fn: function(props) {
                                                return [
                                                  _c("tr", [
                                                    _c("th", [
                                                      _vm._v("Nombre")
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("th", [_vm._v("Tipo")]),
                                                    _vm._v(" "),
                                                    _c("th", [
                                                      _vm._v("Modelo")
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("th", [
                                                      _vm._v("Superficie (M2)")
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "th",
                                                      {
                                                        staticClass:
                                                          "text-xs-right"
                                                      },
                                                      [_vm._v("Acciones")]
                                                    )
                                                  ])
                                                ]
                                              }
                                            },
                                            {
                                              key: "items",
                                              fn: function(props) {
                                                return [
                                                  _c(
                                                    "tr",
                                                    {
                                                      class:
                                                        props.item.nombre ==
                                                        _vm.editingLote.nombre
                                                          ? "table-active"
                                                          : ""
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item.nombre
                                                          )
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item.tipo
                                                          )
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item.modelo
                                                              .nombre
                                                          )
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            props.item
                                                              .superficie
                                                          )
                                                        )
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-xs-right"
                                                        },
                                                        [
                                                          _c(
                                                            "v-layout",
                                                            [
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        slot:
                                                                          "activator",
                                                                        icon:
                                                                          "",
                                                                        color:
                                                                          "verdeFayal",
                                                                        flat: ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.editItem(
                                                                            props.item,
                                                                            props.index
                                                                          )
                                                                        }
                                                                      },
                                                                      slot:
                                                                        "activator"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "edit"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Editar"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        slot:
                                                                          "activator",
                                                                        icon:
                                                                          "",
                                                                        color:
                                                                          "verdeFayal",
                                                                        flat: ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.duplicateItem(
                                                                            props.item
                                                                          )
                                                                        }
                                                                      },
                                                                      slot:
                                                                        "activator"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "content_copy"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Duplicar lote"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-tooltip",
                                                                {
                                                                  attrs: {
                                                                    bottom: ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        slot:
                                                                          "activator",
                                                                        icon:
                                                                          "",
                                                                        color:
                                                                          "red",
                                                                        flat: ""
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.deleteItem(
                                                                            props.index
                                                                          )
                                                                        }
                                                                      },
                                                                      slot:
                                                                        "activator"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "delete"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Eliminar lote"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1796077193
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.editing
                                    ? _c(
                                        "v-flex",
                                        {
                                          staticClass: "conceptos-form",
                                          attrs: { xs6: "" }
                                        },
                                        [
                                          _c(
                                            "v-toolbar",
                                            {
                                              attrs: {
                                                flat: "",
                                                color: "white"
                                              }
                                            },
                                            [
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "body-2 grey--text"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Editar lote seleccionado"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "grey--text" },
                                                  [
                                                    _vm._v(
                                                      "Modifica los datos asociados al lote " +
                                                        _vm._s(
                                                          _vm.editingLote.nombre
                                                        )
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    flat: "",
                                                    icon: "",
                                                    color: "verdeFayal",
                                                    dark: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.editing = false
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("close")
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-container",
                                                [
                                                  _c(
                                                    "v-layout",
                                                    [
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _c("v-select", {
                                                            attrs: {
                                                              label:
                                                                "Tipo de lote",
                                                              items: [
                                                                "Regular",
                                                                "Irregular"
                                                              ]
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editingLote
                                                                  .tipo,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editingLote,
                                                                  "tipo",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editingLote.tipo"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _c("searcher", {
                                                            attrs: {
                                                              configuration:
                                                                _vm.searchModelo,
                                                              itemSelected:
                                                                _vm.editingLote
                                                                  .modelo
                                                            },
                                                            on: {
                                                              "update:itemSelected": function(
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.editingLote,
                                                                  "modelo",
                                                                  $event
                                                                )
                                                              },
                                                              "update:item-selected": function(
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.editingLote,
                                                                  "modelo",
                                                                  $event
                                                                )
                                                              }
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-flex",
                                                        { attrs: { xs12: "" } },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              color:
                                                                "verdeFayal",
                                                              label:
                                                                "Superficie en metros cuadrados",
                                                              type: "number",
                                                              min: "1"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.editingLote
                                                                  .superficie,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.editingLote,
                                                                  "superficie",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "editingLote.superficie"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-divider"),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _vm._v(" "),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    dark: "",
                                                    color: "verdeFayal",
                                                    outline: "",
                                                    flat: "",
                                                    loading:
                                                      _vm.vBtnSave.loading
                                                  },
                                                  on: { click: _vm.updateLote }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                              Actualizar\n                            "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        dark: "",
                        color: "verdeFayal",
                        outline: "",
                        flat: "",
                        loading: _vm.vBtnSave.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.storeLotes()
                        }
                      }
                    },
                    [_vm._v("\n                Guardar\n              ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: "bottom", right: "right" },
          model: {
            value: _vm.vSnackBar.visible,
            callback: function($$v) {
              _vm.$set(_vm.vSnackBar, "visible", $$v)
            },
            expression: "vSnackBar.visible"
          }
        },
        [
          _vm._v("\n      " + _vm._s(_vm.vSnackBar.text) + "\n      "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", flat: "" },
              on: {
                click: function($event) {
                  _vm.vSnackBar.visible = false
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }