<template>
    <v-flex xs12>
        <v-container>
            <v-expansion-panel>
                <v-expansion-panel-content class="white" v-for="(concepto, i) in data.conceptos" :key="i" >
                    <template v-slot:header>
                        <h4 class="primary-title">{{concepto.nombre}}</h4>
                    </template>
                    <v-card class="grey lighten-3">
                        <table class="table table-hover table-borderless">
                            <thead>
                                <tr>
                                    <th>Rubro</th>
                                    <th>Tiempo de ejecución</th>
                                    <th>Subtotal de material</th>
                                    <th>Subtotal de mano de obra</th>
                                    <th> 
                                        <v-tooltip bottom>
                                            <v-btn icon flat dark small slot="activator" color="info" @click="openEditConceptoModal(concepto.id)">
                                                <v-icon color="verdeFayal">edit</v-icon>
                                            </v-btn>
                                            <span>Editar concepto</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <v-btn icon flat dark small slot="activator" color="error" @click="deleteConcept(concepto.id)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(rubro, index) in concepto.rubros" :key="index">
                                    <td>{{ rubro.nombre }}</td>
                                    <td>{{ rubro.horas }}</td>
                                    <td>$ {{getSubtotalMateriales(rubro.materiales_valores) | currency }} </td>
                                    <td>$ {{ (rubro.valores.length) ? getSubtotalManoDeObra(rubro.valores) : 0 | currency }}</td>
                                    <td colspan="2">
                                        <v-tooltip bottom>
                                            <v-btn  
                                                @click="openExecutionTimeModal(rubro.id,rubro.nombre)"  
                                                icon flat dark small slot="activator" color="verdeFayal"
                                            >
                                                <v-icon>timer</v-icon>
                                            </v-btn>
                                            <span>Tiempo de ejecución</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <v-btn @click="openAddMaterialsValuesModal(rubro.id,rubro.nombre,`App\\Urbanizacion`)"  icon flat dark small slot="activator" color="verdeFayal">
                                                <img src="/fonts/local/construction_icon.svg" class="customIcon">
                                            </v-btn>
                                            <span>Materiales</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <v-btn @click="setValues(valueID = (rubro.valores.length) ? rubro.valores[0].id : null, rubro.id, rubro.nombre, `App\\Urbanizacion`)" icon flat dark small slot="activator" color="verdeFayal">
                                                <img src="/fonts/local/manpower_icon.svg" class="customIcon">
                                            </v-btn>
                                            <span>Costos de mano de obra</span>
                                        </v-tooltip>
                                    </td>
                                </tr>
                                <tr style="background-color: #757575; color: #fff;">
                                    <td>Totales</td>
                                    <td>{{ getTotalHoras(concepto.rubros).toFixed(2) }}</td>
                                    <td>${{ getTotalMateriales(concepto.rubros) | currency }}</td>
                                    <td>${{ getTotalManoDeObra(concepto.rubros) | currency }}</td>
                                    <td>${{ getTotalMateriales(concepto.rubros) + getTotalManoDeObra(concepto.rubros) | currency }}</td>
                                    <td colspan="2"></td>
                                </tr>
                            </tbody>
                        </table>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>        
        </v-container>
        <!-- Modals -->
        <set-values-modal
            v-bind:show.sync="showSetValuesModal"
            v-bind:value-id.sync="currentValueId"
            v-bind:household-id.sync="housingId"
            v-bind:tax="parseInt(impuesto.valor)"
            v-bind:item-id.sync="currentItemId"
            v-bind:name.sync="currentItemName"
            v-bind:model-type.sync="currentModel"
        ></set-values-modal>
        <manage-materials-to-value-modal
            v-bind:show.sync="showMaterialsToValuesModal"
            v-bind:item-id.sync="currentItemId"
            v-bind:household-id.sync="housingId"
            v-bind:name.sync="currentItemName"
            v-bind:model-type.sync="currentModel"
            :permissions="permissions"
        ></manage-materials-to-value-modal>
        <edit-concepts-modal 
            :show.sync="showEditConceptModal" 
            :conceptId.sync="editConceptId" 
            :permissions="permissions_rubro"
            type="urbanizacion"
        ></edit-concepts-modal>
        <add-execution-time-urbanizacion-modal
            :show.sync="showExecutionTimeModal" 
            :rubro-id.sync="currentItemId"
        />
    </v-flex>
</template>

<style>
</style>

<script>
  export default {
    data: () => ({
        data: {
            conceptos: [],
        },
        impuesto: {},
        concept: 1,
        housingId: "1",
        vTable: {
            headers: [
                { text: 'Rubro', value: 'rubro' },
                { text: 'Precio unitario', value: 'unitario' },
                { text: 'Cantidad', value: 'cantidad' },
                { text: 'Impuesto', value: 'impuesto' },
                { text: 'Total antes de impuesto', value: 'impuesto' },
                { text: 'Total neto', value: 'neto' },
                { text: 'Acciones', value: 'acciones', sortable: false }
            ]
        },
        showEditConceptModal: false,
        showExecutionTimeModal: false,
        editConceptId: null,
        showSetValuesModal: false,
        showMaterialsToValuesModal: false,
        currentValueId: null,
        currentItemName: null,
        currentValueMaterialId: null,
        currentItemId: null,
        currentModel: null
    }),
    props: {
        permissions: Object,
        permissions_rubro: Object, 
        reload: Boolean
    },
    watch: {
        showSetValuesModal: function(value) {
            if(!value) {
                this.getValues()
            }
        },
        reload: function(value) {
            if(!value) {
                this.getValues();
            }
        },
        showMaterialsToValuesModal: function(value) {
            if(!value) {
                this.getValues()
            }
        },
        showEditConceptModal: function(value) {
            if(!value) {
                this.getValues()
            }
        }, 
        showExecutionTimeModal: function(value) {
            if(!value) {
                this.getValues()
            }
        }
    },
    methods: {
        getValues: function() {
            axios.get(`/urbanizacion/valores`)
            .then(response => this.data = response.data)
            .catch( error => errorHandling(error))
        },
        setValues: function(valorId, itemId, currentItemName, model) {
            this.showSetValuesModal = true;
            this.currentValueId = valorId;
            this.currentItemId = itemId;
            this.currentItemName = currentItemName;
            this.currentModel = model;
        },
        openAddMaterialsValuesModal: function(currentItemId, currentItemName, model) {
            this.showMaterialsToValuesModal = true;
            this.currentItemId = currentItemId;
            this.currentItemName = currentItemName;
            this.currentModel = model;
        },
        openExecutionTimeModal: function(rubroId) {
            this.currentItemId = rubroId;
            this.showExecutionTimeModal = true;
        },
        goBack: function () {
            window.history.back();
        },
        getSubtotalMateriales: function(materiales) {
            var total = 0;
            if(materiales.length) {
                materiales.forEach((item) => {
                    total = total + item.precio_total;
                });
            }
            return total;
        },
        getTotalMateriales: function(rubros) {
            var total = 0;
            if(rubros.length) {
                rubros.forEach((item) => {
                    total = total + this.getSubtotalMateriales(item.materiales_valores);
                });
            }
            return total;
        },
        getTotalManoDeObra: function(rubros) {
            var total = 0;
            if(rubros.length) {
                rubros.forEach((rubro) => {
                    rubro.valores.forEach((valor) => {
                        total = total + valor.precio_total;
                    });
                });
            }

            return total;
        },
        getTotalHoras: function(rubros) {
            var total = 0;
            rubros.map(item => total = total + item.horas)
            return total;
        },
        getImpuesto: function() {
            axios.get(`/configuraciones/${1}`)
            .then(response => this.impuesto = response.data)
            .catch( error => errorHandling(error))
        },
        openEditConceptoModal: function(id) {
            this.showEditConceptModal = true;
            this.editConceptId = id;
        },
        deleteConcept: function (id) {
            swal({
                title: "¿Estás seguro?",
                text: "Una vez eliminado no se podrá recuperar",
                icon: "warning",
                buttons: true,
                dangerMode: true
            }).then(result => {
                if (result) {
                axios.delete(laravelRoute('conceptos.destroy', id))
                    .then(response => {
                        this.getValues();
                        alertify.success(response.data.message_text);
                    })
                    .catch(error => {
                        errorHandling(error);
                    });
                }
            })
        },
        getSubtotalManoDeObra: function(valores) {
            var subtotal = 0;
            valores.forEach(valor => subtotal = subtotal + valor.precio_total);
            return subtotal;
        }
    },
    mounted() {
        this.getValues();
        this.getImpuesto();
    }
  }
</script>

<style scoped>
    .customIcon {
        width: 100%;
    }
</style>