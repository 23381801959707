<template>
  <v-layout>
    <v-dialog :value="dialog" persistent max-width="1000px" hide-overlay>
      <v-card>
        <v-toolbar dark color="verdeFayal">
          <v-btn
            icon
            dark
            @click="$emit('close');"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Aplicar Garantías</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table
              v-model="garantias.selected"
              :hide-actions="true"
              :disable-initial-sort="true"
              :headers="garantias.headers"
              :items="garantias.items"
              class="elevation-1"
            > 
              <template v-slot:headers="props">
                <tr>
                  <th
                    v-for="header in props.headers"
                    :key="header.text"
                    :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                    @click="changeSort(header.value)"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </template>
              <template v-slot:items="props">
                <tr :active="props.selected" @click="props.selected = !props.selected">
                  <td>{{ props.item.information.nombre }}</td>
                  <td>{{ props.item.information.descripcion }}</td>
                  <td>{{ props.item.information.meses }}</td>
                  <td>{{ props.item.created_at }}</td>
                  <td>{{ props.item.estado.information.estado }}</td>
                  <td>{{ props.item.estado.created_at }}</td>
                  <td>
                    <v-tooltip bottom>
                      <v-btn :disabled="props.item.estado.information.id != 40" flat icon slot="activator" color="verdeFayal" @click="acceptGaranty(props.item.id)" >
                        <v-icon>done</v-icon>
                      </v-btn>
                      <span>Aplica</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <v-btn :disabled="props.item.estado.information.id != 40" flat icon slot="activator" color="red" @click="denyGaranty(props.item.id)" >
                        <v-icon>clear</v-icon>
                      </v-btn>
                      <span>No aplica</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>

        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { errorHandling } from "../../lib/utilities";
import swal from "sweetalert";

export default {
  props: ["dialog", 'vivienda'],

  data() {
    return {
      pagination: {
        sortBy: 'name'
      },
      garantias:{
        items: [],
        selected: [],
        headers:[
          {
            text: 'Nombre',
            sortable: false,
          },
          {
            text: 'Descripción',
            sortable: false,
          },
          {
            text: 'Duración en meses',
            sortable: false,
          },
          {
            text: 'Fecha de registro',
            sortable: false,
          },
          {
            text: 'Estado',
            sortable: false,
          },
          {
            text: 'Fecha de estado',
            sortable: false,
          },
          {
            text: 'Acciones',
            sortable: false,
          },
        ],
      }
    };
  },
  watch:{
    dialog: function(value){
      if(!value){
      }else{
        this.getGarantias();
      }
    },
  },
  methods: {
    acceptGaranty: function (garantyId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez aceptada no se podrá deshacer.",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.post(laravelRoute('garantias.accept'), { id: garantyId })
            .then(response => {
              alertify.success(response.data.message_text);
              this.getGarantias();
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      })
    },
    denyGaranty: function (garantyId) {
      swal({
        title: "¿Estás seguro?",
        text: "Una vez rechazada no se podrá deshacer.",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(result => {
        if (result) {
          axios.post(laravelRoute('garantias.deny'), { id: garantyId })
            .then(response => {
              alertify.success(response.data.message_text);
              this.getGarantias();
            })
            .catch(error => {
              errorHandling(error);
            });
        }
      })
    },
    getGarantias: function () {
      axios.get(laravelRoute('viviendas.show', this.vivienda.id))
      .then(response => {
        this.garantias.items = response.data.garantias;
      })
      .catch(error => {
        errorHandling(error);
      });
    },
    toggleAll () {
      if (this.garantias.selected.length) this.garantias.selected = []
      else this.garantias.selected = this.garantias.items.slice()
    },
    changeSort (column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    }
  }
};
</script>S