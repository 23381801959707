<template>
    <v-card flat>
        <v-toolbar color="verdeFayal" dark extended flat>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn flat icon :href="`/prototipos_vivienda/${householdId}/valores`">
                        <v-icon>keyboard_arrow_left</v-icon>
                    </v-btn>
                </template>
                <span>Regresar</span>
            </v-tooltip>
        </v-toolbar>

        <v-layout row pb-2>
            <v-flex xs10 offset-xs1>
                <v-card class="card--flex-toolbar">
                    <v-toolbar card prominent>
                        <v-spacer></v-spacer>
                        <v-toolbar-title class="body-2 grey--text">Rubros asociados a {{ concepto.nombre }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text>
                        <v-container grid-list-md>
                            <v-layout>
                                    <v-flex xs3>
                                        <v-card dark color='#75d57b'>
                                            <v-layout>
                                                <v-flex xs5>
                                                    <v-layout  align-center justify-center row fill-height>
                                                        <v-img
                                                            src="/fonts/local/watch.svg"
                                                            height="50px"
                                                            contain
                                                        ></v-img>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs7>
                                                    <v-card-title primary-title>
                                                        <div>
                                                            <div class="headline total-title">{{ statics.horas }}</div>
                                                            <div>Horas de ejecución</div>
                                                        </div>
                                                    </v-card-title>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs3>
                                        <v-card dark color='#75d57b'>
                                            <v-layout>
                                                <v-flex xs5>
                                                    <v-layout  align-center justify-center row fill-height>
                                                        <v-img
                                                            src="/fonts/local/construction.svg"
                                                            height="50px"
                                                            contain
                                                        ></v-img>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs7>
                                                    <v-card-title primary-title>
                                                        <div>
                                                            <div class="headline total-title">$ {{ statics.materiales | currency }}</div>
                                                            <div>Materiales</div>
                                                        </div>
                                                    </v-card-title>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs3>
                                        <v-card dark color='#75d57b'>
                                            <v-layout>
                                                <v-flex xs5>
                                                    <v-layout  align-center justify-center row fill-height>
                                                        <v-img
                                                            src="/fonts/local/manpower.svg"
                                                            height="50px"
                                                            contain
                                                        ></v-img>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs7>
                                                    <v-card-title primary-title>
                                                        <div>
                                                            <div class="headline total-title">$ {{ statics.obra | currency }}</div>
                                                            <div>Mano de obra</div>
                                                        </div>
                                                    </v-card-title>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                                    <v-flex xs3>
                                        <v-card dark color='#75d57b'>
                                            <v-layout>
                                                <v-flex xs5>
                                                    <v-layout  align-center justify-center row fill-height>
                                                        <v-img
                                                            src="/fonts/local/total.svg"
                                                            height="50px"
                                                            contain
                                                        ></v-img>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs7>
                                                    <v-card-title primary-title>
                                                        <div>
                                                            <div class="headline total-title">$ {{ statics.total | currency }}</div>
                                                            <div>Total</div>
                                                        </div>
                                                    </v-card-title>
                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                            </v-layout>

                            <v-divider></v-divider>

                            <v-card-text>
                                <v-data-table
                                    :items="items.data"
                                    hide-actions
                                    item-key="id"
                                >
                                    <template v-slot:headers="props">
                                        <tr>
                                            <th>Rubro</th>
                                            <th>Tiempo de ejecución</th>
                                            <th>Subtotal de material</th>
                                            <th>Subtotal de mano de obra</th>
                                            <th>Total</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </template>

                                    <template v-slot:items="props">
                                        <tr>
                                            <td>{{ props.item.rubro.nombre }}</td>
                                            <td>{{ props.item.horas }}</td>
                                            <td>$ {{ props.item.total_materiales | currency }}</td>
                                            <td>$ {{ props.item.total_valores | currency }}</td>
                                            <td>$ {{ props.item.total_valores + props.item.total_materiales | currency }}</td>
                                            <td>
                                                <v-layout>
                                                    <v-tooltip bottom>
                                                        <v-btn
                                                            @click="openExecutionTimeModal(props.item.rubro_id, props.item.rubro.nombre, props.index)"
                                                            icon flat dark small slot="activator" color="verdeFayal"
                                                        >
                                                            <v-icon>timer</v-icon>
                                                        </v-btn>
                                                        <span>Tiempo de ejecución</span>
                                                    </v-tooltip>

                                                    <v-tooltip bottom>
                                                        <v-btn  
                                                            @click="openAddMaterialsValuesModal(props.item.rubro_id, props.item.rubro.nombre, props.index)"  
                                                            icon flat dark 
                                                            small slot="activator" color="verdeFayal"
                                                        >
                                                            <img src="/fonts/local/construction_icon.svg" class="customIcon">
                                                        </v-btn>
                                                        <span>Materiales</span>
                                                    </v-tooltip>
                                                    
                                                    <v-tooltip bottom>
                                                        <v-btn 
                                                            @click="setValues(valueID = null, props.item.rubro_id, props.item.rubro.nombre, `App\\PrototipoVivienda`, props.index)"
                                                            color="verdeFayal"
                                                            slot="activator" 
                                                            small 
                                                            dark 
                                                            icon 
                                                            flat 
                                                        >
                                                            <img src="/fonts/local/manpower_icon.svg" class="customIcon">
                                                        </v-btn>
                                                        <span>Costos de mano de obra</span>
                                                    </v-tooltip>
                                                </v-layout>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <pagination :limit="10" :data="items" @pagination-change-page="getItems"></pagination>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>

        <!-- Modals -->
        <add-execution-time-modal 
            :show.sync="showExecutionTimeModal" 
            :rubro-id.sync="currentItemId"
            :item-id.sync="householdId"
            :name.sync="currentItemName"
            :modelType="currentModel"
        />
        <manage-materials-to-value-modal
            :show.sync="showMaterialsToValuesModal"
            :item-id.sync="currentItemId"
            :household-id.sync="householdId"
            :name.sync="currentItemName"
            :modelType.sync="currentModel"
            :permissions="permissions"
        ></manage-materials-to-value-modal>
        <set-values-modal
            :show.sync="showSetValuesModal"
            :value-id.sync="currentValueId"
            :household-id.sync="householdId"
            :item-id.sync="currentItemId"
            :name.sync="currentItemName"
            :model-type.sync="currentModel"
        ></set-values-modal>
    </v-card>
</template>

<style>
</style>

<script>
import { errorHandling, laravelRoute } from '../../lib/utilities'
  export default {
    data: () => ({
        household: {},
        items: {},
        showExecutionTimeModal: false,
        showMaterialsToValuesModal: false,
        showSetValuesModal: false,
        currentItemId: null,
        currentItemName: null,
        currentValueId: null,
        currentModel: 'App\\PrototipoVivienda',
        loadingData: false,
        statics: {},
        concepto: {}
    }),
    props: {
        householdId: String,
        conceptId: String,
        permissions: Object
    },
    watch: {
        showSetValuesModal: function(value) {
            if(!value) {
                this.getItems();
                this.getInfo();
            }       
        },
        showMaterialsToValuesModal: function(value) {
            if(!value) {
                this.getItems();
                this.getInfo();
            }
        },
        showExecutionTimeModal: function name(value) {
            if(!value) {
                this.getItems();
                this.getInfo();
            }
        }
    },
    methods: {
        getHousehold: function() {
            axios.get(laravelRoute('prototipos_vivienda.show.refactor', this.householdId))
            .then(response => this.household = response.data)
            .catch(error => errorHandling(error))
        },
        getItems: function(page = 1) {
            axios.get(`/prototipos_vivienda/${this.householdId}/conceptos/${this.conceptId}?page=${page}`)
            .then(response => this.items = response.data)
            .catch(error => errorHandling(error))
        },
        getConcepto: function() {
            axios.get(laravelRoute('conceptos.show', this.conceptId))
            .then(response => this.concepto = response.data)
            .catch(error => errorHandling(error))
        },
        openExecutionTimeModal: function(rubroId, name, index) {
            this.currentItemName = name;
            this.showExecutionTimeModal = true;
            this.currentItemId = rubroId;
            this.currentIndex = index
        },
        getInfo: function() {
            this.loadingData = true;
            axios.get(`/prototipos_vivienda/${this.householdId}/estadisticas/${this.conceptId}/concepto`)
            .then(response => this.statics = response.data)
            .catch( error => errorHandling(error))
            .finally(() => this.loadingData = false);
        },
        openAddMaterialsValuesModal: function(currentItemId, currentItemName, index) {
            this.showMaterialsToValuesModal = true;
            this.currentItemId = currentItemId;
            this.currentItemName = currentItemName;
            this.currentIndex = index;
        },
        setValues: function(valorId, itemId, currentItemName, model, index) {
            this.showSetValuesModal = true;
            this.currentValueId = valorId;
            this.currentItemId = itemId;
            this.currentItemName = currentItemName;
            this.currentModel = model;
            this.currentIndex = index;
        },
    },
    mounted() {
        this.getHousehold();
        this.getConcepto();
        this.getItems();
        this.getInfo();
    }
  }
</script>

<style scoped>
    .customIcon {
        width: 100%;
    }

    .card--flex-toolbar {
        margin-top: -64px;
    }
</style>