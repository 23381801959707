var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    [
      _c(
        "v-container",
        [
          _c(
            "v-card",
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            small: "",
                            flat: "",
                            href: "/fraccionamientos"
                          }
                        },
                        [_c("v-icon", [_vm._v("chevron_left")])],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-toolbar-title", [
                        _vm._v("Propuestas de lotificación")
                      ]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      (_vm.$root.can("new", _vm.permissions)
                      ? true
                      : false)
                        ? _c("proyectos-modal-form", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$root.can(
                                  "readonly",
                                  _vm.permissions
                                )
                                  ? false
                                  : true,
                                expression:
                                  "$root.can('readonly', permissions) ? false : true"
                              }
                            ],
                            attrs: {
                              proyectos: _vm.proyectosProps,
                              "proyecto-id": _vm.proyecto.id,
                              concretados: [],
                              readonly: _vm.$root.can(
                                "readonly",
                                _vm.permissions
                              )
                                ? true
                                : false,
                              dialog: _vm.dialog
                            },
                            on: {
                              open: function($event) {
                                _vm.dialog = true
                              },
                              close: function($event) {
                                return _vm.cleanForm()
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      "disable-initial-sort": "",
                      items: _vm.proyectosProps.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", [_vm._v(_vm._s(props.item.nombre))]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(props.item.lotes_regulares))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(props.item.lotes_irregulares))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(props.item.superficie.toFixed(2)))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(props.item.estado.information.estado)
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled:
                                                !_vm.$root.can(
                                                  "update",
                                                  _vm.permissions
                                                ) ||
                                                (props.item.estado.information
                                                  .estado == "Descartado" &&
                                                  true),
                                              flat: "",
                                              icon: "",
                                              color: "verdeFayal"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openStatusModal(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("low_priority")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Estados")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled:
                                                !_vm.$root.can(
                                                  "update",
                                                  _vm.permissions
                                                ) ||
                                                (props.item.estado.information
                                                  .estado == "Descartado" &&
                                                  true),
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "info"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.editproyecto(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#1ABA24" } },
                                              [_vm._v("edit")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Editar")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              flat: "",
                                              icon: "",
                                              color: "warning"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.elementId = props.item.id
                                                _vm.dialogFile = true
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("folder")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Archivos")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled:
                                                !_vm.$root.can(
                                                  "remove",
                                                  _vm.permissions
                                                ) ||
                                                (props.item.estado.information
                                                  .estado == "Seleccionado" &&
                                                  true),
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "error"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.sweetAlert(
                                                  props.item
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("delete")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Eliminar")])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.proyectosProps
                        },
                        on: {
                          "pagination-change-page":
                            _vm.getPropuestasDeLotificacion
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("upload-files-modal", {
            attrs: {
              element_type: "App\\Lotificacion",
              element_id: _vm.elementId,
              dialog: _vm.dialogFile
            },
            on: {
              close: function($event) {
                _vm.dialogFile = false
              }
            }
          }),
          _vm._v(" "),
          _c("manage-estados-modal-component", {
            attrs: {
              show: _vm.showStatusModal,
              "model-id": _vm.currentLotificationId,
              "model-type": "App\\Lotificacion"
            },
            on: {
              "update:show": function($event) {
                _vm.showStatusModal = $event
              },
              "update:modelId": function($event) {
                _vm.currentLotificationId = $event
              },
              "update:model-id": function($event) {
                _vm.currentLotificationId = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }