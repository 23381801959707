<template>
  <v-layout>
    <v-container>
      <v-flex xs12>
        <v-card flat>
          <v-toolbar color="white" flat>
            <v-btn flat icon href="/nominas">
              <v-icon>keyboard_arrow_left</v-icon>
            </v-btn>
            <v-breadcrumbs style="margin: 1%;" :items="breadcrums" divider=">"></v-breadcrumbs>
          </v-toolbar>
        </v-card>
      </v-flex>
      <v-flex xs12>
        <v-card flat>
          <v-toolbar color="white" flat>
            <v-toolbar-title>{{ nomina.nombre }}</v-toolbar-title>
          </v-toolbar>
          <v-data-table disable-initial-sort :items="areas.data" hide-actions class="elevation-1">
            <template v-slot:headers="props">
              <tr>
                <th>Departamento</th>
                <th>Fecha de captura</th>
                <th>Acciones</th>
              </tr>
            </template>
            <template slot="items" slot-scope="props">
              <td>{{ props.item.nombre_area }}</td>
              <td>{{ props.item.fecha_captura ? props.item.fecha_captura : 'Aún sin especificar'}}</td>
              <td class="text-xs-right">
                <v-layout>
                  <v-tooltip bottom>
                    <v-btn icon flat dark small slot="activator" color="verdeFayal" :href="`/areas_nominas/${props.item.id}/edit`">
                      <v-icon>visibility</v-icon>
                    </v-btn>
                    <span>Ver/editar datos</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <pagination :limit="10" :data="areas" @pagination-change-page="getAreas"></pagination>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-container>
  </v-layout>
</template>

<script>
import swal from 'sweetalert';
import {
  errorHandling,
  laravelRoute
} from "../../lib/utilities";

export default {
  props: ['nomina_id', 'permissions'],
  data() {
    return {
      nomina: {
        nombre: '',
        estatus: '',
      },
      areas: {
        data: []
      },
      search: null,
    }
  },
  computed: {
    breadcrums: function() {
      return [
        {
          text: 'Nóminas',
          disabled: false,
          href: '/nominas'
        },
        {
          text: `${this.nomina.nombre}`,
          disabled: true,
          href: `/nominas/${this.nomina.id}`
        } 
      ]
    }
  },
  methods: {
    getNomina: function (){
      axios(laravelRoute('nominas.show', this.nomina_id))
      .then(response => this.nomina = response.data)
      .catch(error => errorHandling(error));
    }, 
    getAreas: function (page = 1) {
      axios.get(`/nominas/${this.nomina_id}/areas?page=${page}&query=${this.search}`)
      .then(response => this.areas = response.data)
      .catch(error => errorHandling(error));
    },
  },
  created() {
    this.getNomina();
    this.getAreas();
  },
}
</script>