var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: !_vm.showFiltersForm, xs9: _vm.showFiltersForm } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "white", flat: "", tabs: "" } },
                    [
                      _c("v-toolbar-title", [_vm._v("Requisiciones")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-text-field", {
                        attrs: {
                          color: "verdeFayal",
                          "single-line": "",
                          label: "Buscar",
                          "append-icon": "search"
                        },
                        model: {
                          value: _vm.search,
                          callback: function($$v) {
                            _vm.search = $$v
                          },
                          expression: "search"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", outline: "" },
                          on: {
                            click: function($event) {
                              _vm.showFiltersForm = !_vm.showFiltersForm
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("filter_alt")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { dark: "", color: "verdeFayal", outline: "" },
                          on: { click: _vm.exportToExcel }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "" } }, [
                            _vm._v("import_export")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.vHeaders,
                      items: _vm.requisiciones.data,
                      "hide-actions": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "items",
                        fn: function(props) {
                          return [
                            _c("td", [_vm._v(_vm._s(props.item.folio))]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(_vm._s(props.item.nombre_requisicion))
                            ]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(props.item.solicitante))]),
                            _vm._v(" "),
                            _c("td", { staticClass: "td-ellipsis" }, [
                              _vm._v(_vm._s(props.item.descripcion))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(props.item.estado.information.estado)
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  props.item.fecha_de_llegada
                                    ? props.item.fecha_de_llegada
                                    : "Aún sin especificar"
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c(
                                  "v-layout",
                                  [
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            slot: "activator",
                                            disabled: !_vm.$root.can(
                                              "visto-bueno",
                                              _vm.permissions
                                            )
                                              ? true
                                              : props.item.visto_bueno
                                              ? true
                                              : false,
                                            flat: "",
                                            color: "indigo"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.AprobadoGerencia(
                                                props.item
                                              )
                                            }
                                          },
                                          slot: "activator",
                                          model: {
                                            value: props.item.visto_bueno,
                                            callback: function($$v) {
                                              _vm.$set(
                                                props.item,
                                                "visto_bueno",
                                                $$v
                                              )
                                            },
                                            expression: "props.item.visto_bueno"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("Visto bueno por Gerencia")
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c("v-checkbox", {
                                          attrs: {
                                            slot: "activator",
                                            disabled: !_vm.$root.can(
                                              "visto_bueno_operaciones",
                                              _vm.permissions
                                            )
                                              ? true
                                              : props.item
                                                  .visto_bueno_operaciones
                                              ? true
                                              : false,
                                            flat: "",
                                            color: "indigo"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.aprobadoOperaciones(
                                                props.item
                                              )
                                            }
                                          },
                                          slot: "activator",
                                          model: {
                                            value:
                                              props.item
                                                .visto_bueno_operaciones,
                                            callback: function($$v) {
                                              _vm.$set(
                                                props.item,
                                                "visto_bueno_operaciones",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "props.item.visto_bueno_operaciones"
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("Visto bueno por Operación")
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "verdeFayal"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openFilesModal(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("folder")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Archivos")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: !_vm.$root.can(
                                                "materials",
                                                _vm.permissions
                                              ),
                                              href:
                                                "/requisiciones/" +
                                                props.item.id +
                                                "/edit",
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "verdeFayal"
                                            },
                                            slot: "activator"
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("remove_red_eye")
                                            ])
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Editar")])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-tooltip",
                                      { attrs: { bottom: "" } },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              slot: "activator",
                                              disabled: !_vm.$root.can(
                                                "remove",
                                                _vm.permissions
                                              ),
                                              icon: "",
                                              flat: "",
                                              dark: "",
                                              small: "",
                                              color: "error"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteRequisicion(
                                                  props.item.id
                                                )
                                              }
                                            },
                                            slot: "activator"
                                          },
                                          [_c("v-icon", [_vm._v("delete")])],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Eliminar")])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("pagination", {
                        attrs: {
                          limit: _vm.vPaginator.limit,
                          data: _vm.requisiciones
                        },
                        on: { "pagination-change-page": _vm.getRequisiciones }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.showFiltersForm
            ? _c(
                "v-flex",
                { attrs: { xs3: "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { color: "white", flat: "" } },
                        [
                          _c("v-toolbar-title", [_vm._v("Filtros")]),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                flat: "",
                                icon: "",
                                color: "verdeFayal",
                                dark: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.showFiltersForm = false
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("close")])],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-container",
                            { attrs: { "grid-list-md": "" } },
                            [
                              _c(
                                "v-layout",
                                { attrs: { row: "", wrap: "" } },
                                [
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: ["Almacén"],
                                          label: "Solicitante",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.filters.filterSolicitante,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "filterSolicitante",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.filterSolicitante"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-flex",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: this.itemsEstados,
                                          "item-value": "id",
                                          "item-text": "estado",
                                          label: "Estado",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.filters.filterEstado,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filters,
                                              "filterEstado",
                                              $$v
                                            )
                                          },
                                          expression: "filters.filterEstado"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                color: "verdeFayal",
                                small: "",
                                flat: "",
                                outline: ""
                              },
                              on: { click: _vm.limpiar }
                            },
                            [_vm._v("Limpiar")]
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                dark: "",
                                color: "verdeFayal",
                                small: ""
                              },
                              on: { click: _vm.filterRequisiciones }
                            },
                            [_vm._v("Filtrar")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("requisiciones-modal-create", {
            attrs: {
              show: _vm.showCreateModal,
              "item-id": _vm.currentItemId,
              modelType: _vm.currentModel
            },
            on: {
              "update:show": function($event) {
                _vm.showCreateModal = $event
              },
              "update:itemId": function($event) {
                _vm.currentItemId = $event
              },
              "update:item-id": function($event) {
                _vm.currentItemId = $event
              },
              "update:modelType": function($event) {
                _vm.currentModel = $event
              },
              "update:model-type": function($event) {
                _vm.currentModel = $event
              }
            }
          }),
          _vm._v(" "),
          _c("manage-requisitions-to-requisitions-modal", {
            attrs: {
              show: _vm.showMaterialsToValuesModal,
              "item-id": _vm.currentRequisicionId,
              modelType: _vm.currentModel,
              name: _vm.currentItemName
            },
            on: {
              "update:show": function($event) {
                _vm.showMaterialsToValuesModal = $event
              },
              "update:itemId": function($event) {
                _vm.currentRequisicionId = $event
              },
              "update:item-id": function($event) {
                _vm.currentRequisicionId = $event
              },
              "update:modelType": function($event) {
                _vm.currentModel = $event
              },
              "update:model-type": function($event) {
                _vm.currentModel = $event
              },
              "update:name": function($event) {
                _vm.currentItemName = $event
              }
            }
          }),
          _vm._v(" "),
          _c("upload-files-modal", {
            attrs: {
              element_type: "App\\Requisicion",
              element_id: _vm.currentRequisicionId,
              dialog: _vm.showFilesModal
            },
            on: {
              close: function($event) {
                _vm.showFilesModal = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }