<template>
  <v-layout>
    <v-dialog :value="localShow" persistent max-width="700px">
      <v-card>
        <v-toolbar color="white" flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="primary-title">Editar trámite</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn flat icon color="verdeFayal" dark @click="localShow = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    color="verdeFayal"
                    label="Dependencia"
                    v-model="tramite.dependencia_information.dependencia.nombre_dependencia"
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-if="tramite.dependencia_information.departamento"
                    color="verdeFayal"
                    label="Departamento"
                    v-model="tramite.dependencia_information.departamento.nombre_departamento"
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    color="verdeFayal"
                    label="Trámites"
                    v-model="tramite.nombre_tramite"
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    color="verdeFayal"
                    label="Proyecto al que pertenece"
                    v-model="tramite.fraccionamiento.nombre_proyecto"
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    color="verdeFayal"
                    label="Descripción"
                    v-model="tramite.descripcion"
                    disabled
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    color="verdeFayal"
                    type="date"
                    label="Fecha de resolución"
                    v-model="tramite.fecha_resolucion"
                    :rules="rules"
                  ></v-text-field>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    color="verdeFayal"
                    label="Trámites"
                    v-model="tramite.estado.information.estado"
                    disabled
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="$store.state.saveDisabled"
            @click="tramiteEdit"
            color="verdeFayal"
            flat
            dark
            outline
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="vSnackBar.visible" bottom="bottom" right="right">
      {{ vSnackBar.text }}
      <v-btn color="pink" flat @click="vSnackBar.visible = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-layout>
</template>

<script>
import { errorHandling } from "../../lib/utilities";
export default {
  props: ["show", "tramiteId"],

  data() {
    return {
      placeholder: "Subir archivo...",
      items: [],
      tramite: {
        estado: {
            information: {}
        },
        fraccionamiento: {},
        dependencia_information: {
            dependencia: {}
        }
      },
      localShow: false,
      dependencias: [],
      departamentos_list: [],
      tramites_list: [],
      dependencia_id: "",
      departamento_id: "",
      concretadosProps: this.concretados,
      max: 250,
      rules: [v => !!v || "Este campo es requerido"],
      valid: false,
      searchComponent: {
        url: "/prospectos",
        paginatedContainer: 'data',
        noDataText: "No se encontraron resultados.",
        label: "Proyecto al que pertenece",
        itemText: "nombre_proyecto",
        itemValue: "id",
        descriptionLimit: 60,
        disabled: false,
        useHeaders: true,
      },
        vBtnSave: {
        loading: false
      },
      vSnackBar: {
        visible: false,
        text: null
      }
    };
  },

  methods: {
    tramiteEdit() {
      if (this.$refs.form.validate()) {
        this.vBtnSave.loading = true;
        axios.patch(`/tramites_dependencias/${this.tramiteId}`, {
            fecha_resolucion: this.tramite.fecha_resolucion,
            estado: this.tramite.estado.information.estado
        })
        .then(response => {
            this.vSnackBar.text = response.data.message_text;
            this.vSnackBar.visible = true;
            this.$root.cleanStore();
        })
        .catch(error => errorHandling(error))
        .finally(() => this.vBtnSave.loading = false);
      }
    }, 

    getTramite: function() {
        axios.get(route("tramites_dependencias.edit", this.tramiteId))
        .then(response => {
          this.tramite = response.data;
        });
    }
  },

  watch: {
    show: function (value) {
      this.localShow = value
    },
    localShow: function (value) {
      this.$emit('update:show', value);
    }, 
    tramiteId: function() {
        this.getTramite();
    }
  }, 

  mounted() {
    axios.get("/tramites_dependencias/estados").then(response => {
      this.items = response.data;
    });
  },
};
</script>