<template>
  <v-card flat>
    <v-toolbar color="verdeFayal" dark extended flat>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn flat icon :href="`/prototipos_vivienda`">
            <v-icon>keyboard_arrow_left</v-icon>
          </v-btn>
        </template>
        <span>Regresar</span>
      </v-tooltip>
    </v-toolbar>

    <v-layout row pb-2>
      <v-flex xs10 offset-xs1>
        <v-card class="card--flex-toolbar">
          <v-toolbar card prominent>
            <v-spacer></v-spacer>
            <v-toolbar-title class="body-2 grey--text">Materiales asociados a {{ household.nombre }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <v-btn :href="`/prototipos_vivienda/${householdId}/materiales/exportar?sort_by=${sortBy}`" slot="activator" color="#ff5617"
                target="_blank" small icon flat dark>
                <v-icon>picture_as_pdf</v-icon>
              </v-btn>
              <span>Exportar a PDF</span>
            </v-tooltip>
            <v-btn-toggle v-model="sortBy">
              <v-btn flat value="conceptos">
                <span>Conceptos</span>
                <v-icon>format_align_center</v-icon>
              </v-btn>
              <v-btn flat value="materiales">
                <span>Materiales</span>
                <v-icon>format_align_left</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-toolbar>

          <v-card-text>
            <v-data-table v-if="sortBy == 'conceptos'" :items="materials.data" hide-actions item-key="id">
              <template v-slot:headers="props">
                <tr>
                  <th>Material</th>
                  <th>Categoria</th>
                  <th>Concepto</th>
                  <th>Rubro</th>
                  <th>Cantidad</th>
                  <th>Unidad de medida</th>
                  <th>Total antes de impuesto</th>
                  <th>Precio total</th>
                </tr>
              </template>

              <template v-slot:items="props">
                <tr>
                  <td>{{ props.item.material.nombre }}</td>
                  <td>{{ props.item.material.categoria_asociada }}</td>
                  <td>{{ (props.item.rubro) ? props.item.rubro.conceptos.nombre : null }}</td>
                  <td>{{ (props.item.rubro) ? props.item.rubro.nombre : null }}</td>
                  <td>{{ props.item.cantidad }}</td>
                  <td>{{ props.item.material.unidad }}</td>
                  <td>$ {{ props.item.total_impuesto | currency }}</td>
                  <td>$ {{ props.item.precio_total | currency }}</td>
                </tr>
              </template>
            </v-data-table>

            <v-data-table v-else :items="sortMaterials.data" hide-actions item-key="id">
              <template v-slot:headers="props">
                <tr>
                  <th>Categoria</th>
                  <th>Material</th>
                  <th>Cantidad</th>
                  <th>Unidad de medida</th>
                  <th>Precio unitario</th>
                  <th>Subtotal antes de impuesto</th>
                  <th>Precio total</th>
                </tr>
              </template>

              <template v-slot:items="props">
                <tr>
                  <td>{{ props.item.categoria }}</td>
                  <td>{{ props.item.nombre }}</td>
                  <td>{{ props.item.cantidad.toFixed(2) }}</td>
                  <td>{{ props.item.unidad }}</td>
                  <td>$ {{ getPrecioPorUnidad(props.item.precio) | currency }}</td>
                  <td>$ {{ getPrecioAntesImpuestos(props.item.precio, props.item.cantidad) | currency }}</td>
                  <td>$ {{ getPrecioTotal(props.item.precio, props.item.cantidad) | currency}}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- Conceptos -->
            <pagination v-if="sortBy == 'conceptos'" :limit="10" :data="materials"
              @pagination-change-page="getMaterials"></pagination>
            <!-- Materiales -->
            <pagination v-else :limit="10" :data="sortMaterials" @pagination-change-page="getSortMaterials">
            </pagination>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
import { errorHandling, laravelRoute } from '../../lib/utilities'

export default {
  data: () => ({
    household: {},
    materials: {
      data: []
    },
    sortMaterials: {
      data: []
    },
    sortBy: 'conceptos',
    configuraciones: {}
  }),
  props: {
    householdId: Number,
  },
  watch: {
    sortBy: function(value) {
      if (value == 'conceptos') {
        this.getMaterials();
      }
      else {
        this.getSortMaterials();
      }
    }
  },
  methods: {
    getHousehold: function() {
      axios.get(laravelRoute('prototipos_vivienda.show.refactor', this.householdId))
      .then(response => this.household = response.data)
      .catch(error =>  errorHandling(error))
    },
    getMaterials: function(page = 1) {
      axios.get(`/prototipos_vivienda/${this.householdId}/materiales?page=${page}&sort_by=${this.sortBy}`)
      .then(response => this.materials = response.data)
      .catch(error =>  errorHandling(error))
    },
    getSortMaterials: function (page = 1) {
      axios.get(`/prototipos_vivienda/${this.householdId}/materiales?page=${page}&sort_by=${this.sortBy}`)
      .then(response => this.sortMaterials = response.data)
      .catch(error => errorHandling(error))
    },
    getPrecioPorUnidad: function(precio) {
      let iva = this.configuraciones.find(item => item.slug == 'iva');
      return (((precio) / 100) * iva.valor) + precio;
    },
    getPrecioAntesImpuestos: function(precio, cantidad) {
      return precio * cantidad;
    },
    getPrecioTotal: function(precio, cantidad) {
      let subtotal = precio * cantidad;
      let iva = this.configuraciones.find(item => item.slug == 'iva')
      return (((subtotal) / 100) * iva.valor) + subtotal;
    },
    getConfiguraciones: function() {
      axios.get(laravelRoute('configuraciones.index'))
      .then(response => this.configuraciones = response.data.data)
      .catch(error => errorHandling(error))
    }
  },
  mounted() {
    this.getHousehold();
    this.getMaterials();
    this.getConfiguraciones();
  }
}
</script>

<style scoped>
  .card--flex-toolbar {
    margin-top: -64px;
  }
</style>